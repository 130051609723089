import React, { useState, useEffect } from 'react'
import { IonSpinner, IonItem, IonLabel, IonList, IonItemDivider } from '@ionic/react'
import { IonInput, IonIcon, IonButton, IonTextarea, IonText } from '@ionic/react'
import { add, trash } from 'ionicons/icons'
import Timer from 'react-compound-timer'
import { MobileIonInput, CurrencyIonInput, useToast, ApiService, WebOTP } from '../../ayria'
import { NumberIonInput, MobileInvite, Modal } from '../../ayria'

interface OwnProps {
  acceptorCode: string
  templateId?: number
  onSuccess?: Function
}
interface GroupTransferInstanceFormProps extends OwnProps {}

const GroupTransferInstanceForm: React.FC<GroupTransferInstanceFormProps> = ({
  templateId,
  onSuccess,
  acceptorCode,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [name, setName] = useState<string>()
  const [mobile, setMobile] = useState<string>()
  const [mobiles, setMobiles] = useState([])
  const [amounts, setAmounts] = useState({})
  const [senderDescription, setSenderDescription] = useState()
  const [receiverDescription, setReceiverDescription] = useState()
  const [saveInprogress, setSaveInprogress] = useState(false)
  const [otpInputReady, setOtpInputReady] = useState(false)
  const [oneTimeCode, setOneTimeCode] = useState()
  const [oneTimeCodeExpired, setOneTimeCodeExpired] = useState(true)
  const [members, setMembers] = useState([])
  const [customers, setCustomers] = useState([])
  const [persons, setPersons] = useState([])
  const [showMobileInviteModal, setShowMobileInviteModal] = useState(false)
  const [invitee, setInvitee] = useState<string>()
  const [checkInprogress, setCheckInprogress] = useState(false)

  const getTemplate = async () => {
    setInprogress(true)
    const res: any = await api.get(`/customer/group-transfers/templates/${acceptorCode}/${templateId}`)
    if (res.ok) {
      setName(res.data.name)
      setSenderDescription(res.data.senderDescription)
      setReceiverDescription(res.data.receiverDescription)
      setMobiles(res.data.members.map((m) => m.mobile))
      setMembers(res.data.members)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const store = async () => {
    setSaveInprogress(true)
    const res: any = await api.post('/customer/group-transfers/instance', {
      id: templateId,
      name,
      acceptorCode: !!acceptorCode ? acceptorCode : null,
      senderDescription,
      receiverDescription,
      members: mobiles.map((m) => ({ mobile: m, amount: amounts[m] })),
      oneTimeCode,
      totalAmount: mobiles.map((m) => +amounts[m]).reduce((a, b) => +a + +b, 0),
    })
    if (res.ok) {
      toast.success(
        hasAnyAmount() ? 'انتقال وجه گروهی با موفقیت بروزرسانی و ارسال شد' : 'انتقال وجه گروهی با موفقیت بروز رسانی شد'
      )
      if (onSuccess) {
        onSuccess()
      }
    } else {
      toast.error(res)
    }
    setSaveInprogress(false)
  }
  const requestOneTimeCode = async () => {
    if (oneTimeCodeExpired) {
      setOneTimeCode('')
      setOneTimeCodeExpired(false)
      setInprogress(true)
      const totalAmount = mobiles
        .map((m) => +amounts[m])
        .filter((a) => !isNaN(a))
        .reduce((a, b) => +a + +b, 0)
      const res: any = await api.post(`/wallet/transfer/requestOneTimeCode/${totalAmount}`, {})
      if (res.ok) {
        toast.success('رمز یکبار مصرف به شماره همراه شما پیامک شد')
      } else {
        toast.error(res)
        setOneTimeCodeExpired(true)
      }
      setInprogress(false)
    }
  }
  const checkIsMember = async (mobs) => {
    for (let i = 0; i < mobs.length; i++) {
      const m = mobs[i]
      if (!isMember(m)) {
        setCheckInprogress(true)
        const res: any = await api.post(`/wallet/is-member`, { mobile: m })
        if (res.ok) {
          customers.push(m)
          setCustomers(customers)
        } else {
          persons.push(m)
          setPersons(persons)
        }
        setCheckInprogress(false)
      }
    }
  }

  const addMobile = () => {
    const tSet = new Set()
    mobiles.map((m) => tSet.add(m))
    tSet.add(mobile)
    const tArr = []
    tSet.forEach((m) => tArr.push(m))
    setMobiles(tArr)
    setMobile('')
  }
  const addAmount = (amount, mobile) => {
    setAmounts({ ...amounts, [mobile]: amount })
  }
  const removeMobile = (mobile: string) => {
    setMobiles(mobiles.filter((m) => m !== mobile))
  }
  const hasAnyAmount: boolean = () => {
    return mobiles.filter((m) => !!amounts[m]).length !== 0
  }
  const isMember: boolean = (mobile: string) => {
    if (persons.indexOf(mobile) !== -1) {
      return false
    }
    if (customers.indexOf(mobile) !== -1) {
      return true
    }
    if (members.filter((m) => m.mobile === mobile && !!m.memberLogin).length > 0) {
      return true
    }
    return false
  }
  const memberName: string = (mobile: string) => {
    if (isMember(mobile)) {
      return members.filter((m) => m.mobile === mobile)?.[0]?.memberName
    }
  }

  useEffect(() => {
    checkIsMember(mobiles)
  }, [mobiles]) // eslint-disable-line

  useEffect(() => {
    if (!!templateId) {
      getTemplate()
    }
  }, [templateId]) // eslint-disable-line

  return (
    <IonList>
      <IonItem>
        <IonInput
          value={name}
          onIonChange={(e) => setName(e.detail.value)}
          placeholder='نام گروه'
          disabled={saveInprogress}
        />
        {inprogress && <IonSpinner slot='end' />}
      </IonItem>
      <IonItemDivider>
        <IonLabel>{'لیست مخاطبین'}</IonLabel>
      </IonItemDivider>
      {mobiles.map((m) => (
        <div key={m}>
          <IonItem lines='none'>
            <IonText slot='start'>{memberName(m)}</IonText>
            <IonText color={isMember(m) ? 'dark' : 'danger'} slot='end' className='ion-text-wrap'>
              {m}
            </IonText>
            <IonButton
              slot='end'
              color='danger'
              fill='clear'
              size='small'
              onClick={() => removeMobile(m)}
              disabled={saveInprogress || !oneTimeCodeExpired}>
              <IonIcon icon={trash} slot='icon-only' />
            </IonButton>
          </IonItem>
          <IonItem>
            {checkInprogress && <IonSpinner name='crescent' color='dark' />}
            {!isMember(m) && !checkInprogress && (
              <IonButton
                slot='start'
                color='tertiary'
                onClick={() => {
                  setInvitee(m)
                  setShowMobileInviteModal(true)
                }}>
                {'ارسال پیامک دعوت'}
              </IonButton>
            )}
            {isMember(m) && !checkInprogress && (
              <>
                <IonLabel position='stacked' color={!!amounts[m] ? 'dark' : 'danger'}>
                  {`مبلغ مورد نظر (${(+amounts[m] || 0)?.toLocaleString(navigator.language)} ریال)`}
                </IonLabel>
                <CurrencyIonInput
                  onValueChange={(value) => addAmount(value, m)}
                  defaultValue={amounts[m]}
                  placeholder='مبلغ مورد نظر (ریال)'
                  disabled={saveInprogress || !oneTimeCodeExpired}
                />
              </>
            )}
          </IonItem>
        </div>
      ))}
      <IonItem>
        <MobileIonInput
          value={mobile}
          onValueChange={(value) => setMobile(value)}
          disabled={saveInprogress}
          placeholder='شماره همراه دریافت کننده وجه'
        />
        <IonButton color='primary' slot='start' onClick={addMobile} disabled={saveInprogress || mobile?.length !== 11}>
          <IonIcon icon={add} />
        </IonButton>
      </IonItem>
      {mobiles.length > 0 && (
        <>
          {hasAnyAmount() && (
            <>
              <IonItem>
                <IonTextarea
                  onIonChange={(e) => setSenderDescription(e.detail.value)}
                  value={senderDescription}
                  placeholder={'توضیحات مبدا'}
                  disabled={saveInprogress}></IonTextarea>
              </IonItem>
              <IonItem>
                <IonTextarea
                  onIonChange={(e) => setReceiverDescription(e.detail.value)}
                  value={receiverDescription}
                  placeholder={'توضیحات مقصد'}
                  disabled={saveInprogress}></IonTextarea>
              </IonItem>
              <IonItem>
                <NumberIonInput
                  value={oneTimeCode}
                  onValueChange={setOneTimeCode}
                  placeholder={'رمز پیامک شده'}
                  autocomplete='one-time-code'
                  componentDidMount={() => setOtpInputReady(true)}
                />
                {otpInputReady && <WebOTP onReceive={(code: any) => setOneTimeCode(code)} />}
                <IonButton slot='start' onClick={requestOneTimeCode}>
                  {oneTimeCodeExpired ? (
                    'درخواست رمز'
                  ) : (
                    <Timer
                      initialTime={120000}
                      direction='backward'
                      checkpoints={[
                        {
                          time: 0,
                          callback: () => {
                            setOneTimeCodeExpired(true)
                            setOneTimeCode('')
                          },
                        },
                      ]}>
                      <Timer.Seconds /> : <Timer.Minutes />
                    </Timer>
                  )}
                </IonButton>
              </IonItem>
            </>
          )}
          <IonButton
            onClick={store}
            disabled={saveInprogress || (!oneTimeCode && hasAnyAmount())}
            className='ion-padding-start ion-margin-top'>
            {saveInprogress && <IonSpinner name='crescent' color='dark' slot='start' />}
            {hasAnyAmount() ? 'ذخیره و ارسال' : 'ذخیره'}
          </IonButton>
        </>
      )}
      <Modal
        showModal={showMobileInviteModal}
        toggle={setShowMobileInviteModal}
        title={`دعوت از ${invitee}`}
        content={
          <MobileInvite
            mobile={invitee}
            onCancel={() => setShowMobileInviteModal(false)}
            onSuccess={() => setShowMobileInviteModal(false)}
          />
        }
      />
    </IonList>
  )
}

export default GroupTransferInstanceForm
