import React, { FC } from 'react'
import { IonText, IonIcon } from '@ionic/react'
import { glasses } from 'ionicons/icons'

interface PayerNameProps {
  item: any
}

const PayerName: FC<PayerNameProps> = ({ item }) => {
  return (
    <IonText dir='ltr'>
      {item.payerName} {!item.payerMember && <IonIcon icon={glasses} />}
    </IonText>
  )
}

export default PayerName
