import React, { useEffect, useState } from 'react'
import { IonText } from '@ionic/react'
import ApiService from '../../../api/ApiService'
import { Cashdesk } from '../../../models'
import { connect } from '../../../data'
import { setWithTimeout } from '../../../utility'

interface OwnProps {
  acceptorCode: string
  onChange?: Function
}
interface StateProps {
  currentCashdesk?: CashDesk
}
interface CashdeskSelectProps extends OwnProps, StateProps {}

const CashdeskSelect: React.FC<CashdeskSelectProps> = ({ acceptorCode, currentCashdesk, onChange }) => {
  const api = new ApiService()
  const [items, setItems] = useState([])

  const getItems = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/cashdesks`)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    }
  }

  const cashdeskIdentifier = () => {
    if (acceptorCode === currentCashdesk?.acceptorCode) {
      return currentCashdesk?.identifier
    }
    return null
  }
  const [cd, setCd] = useState(cashdeskIdentifier() || null)
  useEffect(() => {
    if (onChange) {
      onChange(cd)
    }
  }, [cd]) // eslint-disable-line

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <div className='row py-3 mx-3 px-0 customCardTitleSeparator'>
      {items.map((cad: Cashdesk) => (
        <div
          key={cad.identifier}
          // className={'report-tab  '}
          className={'report-tab ml-3 ' + (cd === cad.identifier ? 'active' : '')}
          onClick={(e) => {
            setCd(cad.identifier)
          }}>
          <IonText color='tertiary'>{cad.identifier}</IonText>» {cad.name}
        </div>
      ))}
    </div>
  )

  // return (
  //   <IonSelect
  //     value={cd}
  //     onIonChange={(e) => setCd(e.detail.value)}
  //     okText='انتخاب'
  //     cancelText='انصراف'
  //     style={{ minWidth: '100%' }}>
  //     <IonSelectOption value={null}>{'همه'}</IonSelectOption>
  //     {items.map((cd: Cashdesk) => (
  //       <IonSelectOption key={cd.identifier} value={cd.identifier}>
  //         <IonText color='tertiary'>{cd.identifier}</IonText>» {cd.name}
  //       </IonSelectOption>
  //     ))}
  //   </IonSelect>
  // )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    currentCashdesk: state.shop.currentCashdesk,
  }),
  component: CashdeskSelect,
})
