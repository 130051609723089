import { lazy } from 'react'
import { AcceptorContext, AcceptorProvider } from './structures/contexts/AcceptorContext'
import { ProfileContext, ProfileProvider } from './structures/contexts/ProfileContext'
import { NotificationContext, NotificationProvider } from './structures/contexts/NotificationContext'
import { ScoreProvider, ScoreContext } from './structures/contexts/ScoreContext'
import { WalletProvider, WalletContext } from './structures/contexts/WalletContext'
import { LoginContext, LoginProvider } from './structures/contexts/LoginContext'

const AyriaPaymentPayerReceipt = lazy(() => import('./elements/AyriaPayment/AyriaPaymentPayerReceipt'))
const CashDeskDaily = lazy(() => import('./elements/CashDeskDaily/CashDeskDaily'))
const PurchaseAcceptorRefund = lazy(() => import('./elements/PurchaseAcceptorRefund/PurchaseAcceptorRefund'))
const PurchaseAcceptorReceipt = lazy(() => import('./elements/PurchaseAcceptorReceipt/PurchaseAcceptorReceipt'))
const CustomCard = lazy(() => import('./elements/CustomCard/CustomCard'))
const CustomModal = lazy(() => import('./elements/CustomModal/CustomModal'))
const AcceptorKala = lazy(() => import('./elements/acceptor/AcceptorKala'))
const AcceptorSetting = lazy(() => import('./elements/acceptor/AcceptorSetting'))
const AcceptorWaiters = lazy(() => import('./elements/acceptor/AcceptorWaiters'))
const AcceptorManagers = lazy(() => import('./elements/acceptor/AcceptorManagers'))
const AcceptorDeliveries = lazy(() => import('./elements/acceptor/AcceptorDeliveries'))
const AcceptorSecretaries = lazy(() => import('./elements/acceptor/AcceptorSecretaries'))
const AcceptorCashdesks = lazy(() => import('./elements/acceptor/AcceptorCashdesks'))
const AcceptorTables = lazy(() => import('./elements/acceptor/AcceptorTables'))
const AcceptorAttachWaiter = lazy(() => import('./elements/acceptor/AcceptorAttachWaiter'))
const KalaDisplayOrder = lazy(() => import('./elements/KalaDisplayOrder/KalaDisplayOrder'))
const KalaFastList = lazy(() => import('./elements/KalaDisplayOrder/KalaFastList'))
const AcceptorAttachDelivery = lazy(() => import('./elements/acceptor/AcceptorAttachDelivery'))
const WalletBalance = lazy(() => import('./elements/WalletBalance/WalletBalance'))
const WalletDeposit = lazy(() => import('./elements/WalletDeposit/WalletDeposit'))
const AcceptorSuggest = lazy(() => import('./units/suggest/acceptor-suggest'))
const AcceptorSelect = lazy(() => import('./elements/AcceptorSelect/AcceptorSelect'))
const AcceptorIonSelect = lazy(() => import('./elements/AcceptorSelect/AcceptorIonSelect'))
const MobileInvite = lazy(() => import('./elements/WalletTransfer/MobileInvite'))
const WalletIdentity = lazy(() => import('./elements/WalletIdentity/WalletIdentity'))
const Factors = lazy(() => import('./elements/Factors/Factors'))
const ClubManagers = lazy(() => import('./elements/club/ClubManagers'))
const PaymentTools = lazy(() => import('./elements/acceptor/PaymentTools'))

export {
  Factors,
  CashDeskDaily,
  PurchaseAcceptorRefund,
  PurchaseAcceptorReceipt,
  CustomModal,
  CustomCard,
  AcceptorContext,
  AcceptorProvider,
  AcceptorKala,
  AcceptorSetting,
  AcceptorWaiters,
  AcceptorManagers,
  AcceptorDeliveries,
  AcceptorSecretaries,
  AcceptorCashdesks,
  AcceptorTables,
  AcceptorAttachWaiter,
  AcceptorAttachDelivery,
  KalaDisplayOrder,
  KalaFastList,
  WalletDeposit,
  WalletBalance,
  AyriaPaymentPayerReceipt,
  AcceptorSuggest,
  AcceptorSelect,
  AcceptorIonSelect,
  ProfileContext,
  ProfileProvider,
  MobileInvite,
  WalletIdentity,
  NotificationContext,
  NotificationProvider,
  ScoreProvider,
  ScoreContext,
  WalletProvider,
  WalletContext,
  LoginContext,
  LoginProvider,
  ClubManagers,
  PaymentTools,
}
