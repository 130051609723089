import React, { useState, useEffect } from 'react'
import { IonCard, IonItem, IonThumbnail, IonLabel, IonIcon, IonCardContent } from '@ionic/react'
import { IonButton, IonInfiniteScroll, IonInfiniteScrollContent, IonChip, IonSpinner } from '@ionic/react'
import { IonSegment, IonSegmentButton, IonProgressBar } from '@ionic/react'
import { IonRow, IonCol } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { calendarClear, time, location, checkmarkCircle, bagCheck, refresh, storefront } from 'ionicons/icons'
import WalletResponse from '../../components/screens/wallet/wallet-response/wallet-response'
import { loadMoreDataWhenScrolled } from '../../utility/pagination'
import { ApiService, API_BASE_URL, useToast, CustomModal, Modal, AcceptorContact } from '../../ayria'
import { setActiveOrderCount, connect, parseHeaderForLinks } from '../../ayria'
import OnlineOrderReceipt from './OnlineOrderReceipt'
import { setWithTimeout } from '../../utility'

interface StateProps {
  activeOrderCount?: number
}
interface DispatchProps {
  setActiveOrderCount: typeof setActiveOrderCount
}
interface OnlineOrderThreadProps extends StateProps, DispatchProps {}

const OnlineOrderThread: React.FC<OnlineOrderThreadProps> = ({ activeOrderCount, setActiveOrderCount }) => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  const [item, setItem] = useState()
  const [items, setItems] = useState([])
  const [actives, setActives] = useState([])
  const [showWalletResponseModal, setShowWalletResponseModal] = useState(false)
  const [showAyriaPaymentModal, setShowAyriaPaymentModal] = useState(false)
  const [showConfirmDelivery, setShowConfirmDelivery] = useState(false)
  const [pagination, setPagination] = useState({ itemsPerPage: 5, sort: 'createdDate', order: 'desc', activePage: 1 })
  const [fetch, setFetch] = useState(false)
  const [reset, setReset] = useState(false)
  const [links, setLinks] = useState<any>({})
  const [inprogress, setInprogress] = useState(false)
  const [segment, setSegment] = useState('active')
  const [checkInprogress, setCheckInprogress] = useState(false)

  const getItems = async (page: number, size: number, sort: string) => {
    setInprogress(true)
    setFetch(false)
    const res: any = await api.get(
      `/user/ayria-payments/online-orders?page=${
        reset ? 0 : page
      }&sort=${sort}&size=${size}&shippingStatus.in=DELIVERED&shippingStatus.in=REFUNDED`
    )
    if (res.ok) {
      setLinks(parseHeaderForLinks(res.headers.link))
      setWithTimeout(setItems, reset ? res.data : loadMoreDataWhenScrolled(items, res.data))
    } else {
      toast.error(res)
    }
    setReset(false)
    setInprogress(false)
  }
  const getActives = async () => {
    setInprogress(true)
    const res: any = await api.get(
      `/user/ayria-payments/online-orders?page=0&sort=createdDate,desc&size=1000&shippingStatus.in=REGISTERED&shippingStatus.in=PICKED&shippingStatus.in=CONSIDERED&shippingStatus.in=ON_MOVE`
    )
    if (res.ok) {
      setActives(res.data)
      if (res.data.length > 0) {
        setSegment('active')
      } else {
        setReset(true)
        setFetch(true)
        setSegment('previous')
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getItem = async (ap: any) => {
    setCheckInprogress(true)
    const res: any = await api.get(`/user/ayria-payments/payer-response/${ap.trackingNumber}`)
    if (res.ok) {
      await getActives()
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }
  const markAsDelivered = async (ap: any) => {
    setCheckInprogress(true)
    const res: any = await api.put(`/user/ayria-payments/order-received/${ap.trackingNumber}`)
    if (res.ok) {
      toast.success('وضعیت کالا با موفقیت تغییر کرد')
      setReset(true)
      setFetch(true)
      await getActives()
      setShowConfirmDelivery(false)
      await setActiveOrderCount(+activeOrderCount > 0 ? +activeOrderCount - 1 : +activeOrderCount)
      history.push('/tabs/rate')
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }

  const handleLoadMore = () => {
    if (!fetch) {
      if (reset) {
        setPagination({ ...pagination, activePage: 1 })
      } else {
        setPagination({ ...pagination, activePage: pagination.activePage + 1 })
      }
    }
  }
  const pay = async (ayriaPayment: any) => {
    setItem(ayriaPayment)
    setShowWalletResponseModal(true)
  }
  const show = async (ayriaPayment: any) => {
    setItem(ayriaPayment)
    setShowAyriaPaymentModal(true)
  }
  const confirmDelivery = async (ayriaPayment: any) => {
    setItem(ayriaPayment)
    setShowConfirmDelivery(true)
  }
  const isPassed: boolean = (factor: any, status: string) => {
    if (factor.shippingStatus === status) {
      return true
    } else if (factor.shippingStatus === 'PICKED' && (status === 'REGISTERED' || status === 'PICKED')) {
      return true
    } else if (
      factor.shippingStatus === 'CONSIDERED' &&
      (status === 'REGISTERED' || status === 'PICKED' || status === 'CONSIDERED')
    ) {
      return true
    } else if (
      factor.shippingStatus === 'ON_MOVE' &&
      (status === 'REGISTERED' || status === 'PICKED' || status === 'CONSIDERED' || status === 'ON_MOVE')
    ) {
      return true
    } else if (
      factor.shippingStatus === 'DELIVERED' &&
      (status === 'REGISTERED' ||
        status === 'PICKED' ||
        status === 'CONSIDERED' ||
        status === 'ON_MOVE' ||
        status === 'DELIVERED')
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (segment === 'previous') {
      setReset(true)
      setFetch(true)
    }
  }, [segment])

  useEffect(() => {
    if (reset) {
      setPagination({ ...pagination, activePage: 1 })
    }
  }, [reset]) //eslint-disable-line

  useEffect(() => {
    if (segment === 'previous') {
      setFetch(true)
    }
  }, [pagination.activePage]) // eslint-disable-line

  useEffect(() => {
    if (fetch) {
      getItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
    }
  }, [fetch]) // eslint-disable-line

  useEffect(() => {
    getActives()
  }, []) // eslint-disable-line

  return (
    <>
      <IonSegment
        value={segment}
        onIonChange={(e) => {
          setSegment(e.detail.value)
        }}>
        <IonSegmentButton value='previous'>
          <IonLabel>{'سفارش‌های قبلی'}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='active'>
          <IonLabel>{'سفارش‌های فعال'}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {segment === 'previous' && (
        <>
          {items.map((item: any) => (
            <IonCard key={item.trackingNumber + new Date()} className='ion-padding'>
              <IonItem>
                <IonThumbnail style={{ width: '80px', height: '80px' }} slot='start'>
                  <img src={`${API_BASE_URL}/public/acceptors/logo/${item.payeeReferralCode}`} alt='' />
                </IonThumbnail>
                <IonLabel>
                  {item.payeeName}
                  <p>
                    <IonIcon icon={time} />
                    {item.createdTime}
                    <IonIcon icon={calendarClear} className='ion-padding-start' />
                    {item.createdDate.replaceAll('-', '/')}
                    <br />
                    {item.deliveryAddress && (
                      <>
                        <IonIcon icon={location} />
                        {item.deliveryAddress}
                      </>
                    )}
                  </p>
                </IonLabel>
              </IonItem>
              <IonCardContent>
                <strong>{'مجموع'}</strong>
                <strong className='float-left'>
                  {item.unitedAmount.toLocaleString(navigator.language)} {'ریال'}
                </strong>
                <br />
                {item.paid && (
                  <IonButton
                    className='float-left pt-2'
                    color={item.shippingStatus === 'REFUNDED' ? 'danger' : 'dark'}
                    fill='outline'
                    slot='end'
                    onClick={(e) => show(item)}>
                    {item.shippingStatus === 'REFUNDED' ? 'عودت شده' : 'مشاهده فاکتور'}
                  </IonButton>
                )}
                {!item.paid && (
                  <IonButton
                    className='float-left pt-2'
                    color='success'
                    fill='outline'
                    slot='end'
                    onClick={() => pay(item)}>
                    {'پرداخت'}
                  </IonButton>
                )}
              </IonCardContent>
            </IonCard>
          ))}
          {!!inprogress && <IonSpinner name='crescent' color='dark' />}
          {items.length === 0 && !inprogress && (
            <div className='ion-margin-top'>
              <IonChip style={{ width: '100%' }}>{'رکوردی وجود ندارد'}</IonChip>
            </div>
          )}
          <IonInfiniteScroll
            threshold='100px'
            disabled={pagination.activePage - 1 >= links?.last || reset || fetch}
            onIonInfinite={() => handleLoadMore()}>
            <IonInfiniteScrollContent loadingText={'سفارشات بیشتر'}></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      )}
      {segment === 'active' && (
        <>
          {actives.map((item: any) => (
            <IonCard key={item.trackingNumber + new Date()} className='ion-padding'>
              <IonItem>
                <IonThumbnail style={{ width: '80px', height: '80px' }} slot='start'>
                  <img src={`${API_BASE_URL}/public/acceptors/logo/${item.payeeReferralCode}`} alt='' />
                </IonThumbnail>
                <IonLabel>
                  {item.payeeName}
                  <p>
                    <IonIcon icon={time} />
                    {item.createdTime}
                    <IonIcon icon={calendarClear} className='ion-padding-start' />
                    {item.createdDate.replaceAll('-', '/')}
                    <br />
                    {item.deliveryAddress && (
                      <>
                        <IonIcon icon={location} />
                        {item.deliveryAddress}
                      </>
                    )}
                  </p>
                </IonLabel>
              </IonItem>
              <IonCardContent style={{ paddingRight: 0, paddingLeft: 0 }}>
                <strong className='ion-padding-start'>{'مجموع'}</strong>
                <strong className='ion-padding-end float-left'>
                  {item.unitedAmount.toLocaleString(navigator.language)} {'ریال'}
                </strong>
                <br />
                {item.paid && (
                  <IonButton
                    className='float-left pt-2'
                    color='dark'
                    fill='outline'
                    slot='end'
                    onClick={(e) => show(item)}>
                    {'مشاهده فاکتور'}
                  </IonButton>
                )}
                {!item.paid && (
                  <IonButton
                    className='float-left pt-2'
                    color='success'
                    fill='outline'
                    slot='end'
                    onClick={() => pay(item)}>
                    {'پرداخت'}
                  </IonButton>
                )}
                {item.paid && (
                  <>
                    <IonItem style={{ paddingRight: 0, marginTop: '70px' }}>
                      <IonLabel>
                        {t(`Order ${item.shippingStatus}`)}
                        <p>{t(`Order ${item.shippingStatus} detail`)}</p>
                      </IonLabel>
                      <IonButton slot='start' fill='clear' onClick={() => getItem(item)} disabled={checkInprogress}>
                        {!checkInprogress && <IonIcon icon={refresh} slot='icon-only' />}
                        {checkInprogress && <IonSpinner name='crescent' color='dark' />}
                      </IonButton>
                    </IonItem>
                    <IonRow className='ion-padding-top'>
                      <IonCol size={1} className='ion-no-padding ion-text-center' style={{ paddingTop: '5px' }}>
                        <IonIcon color='primary' icon={checkmarkCircle} />
                      </IonCol>
                      <IonCol className='ion-no-padding' style={{ paddingTop: '10px' }}>
                        <IonProgressBar
                          value={isPassed(item, 'PICKED') ? 1 : 0}
                          color={isPassed(item, 'PICKED') ? 'primary' : 'medium'}></IonProgressBar>
                      </IonCol>
                      <IonCol className='ion-no-padding ion-text-center' style={{ paddingTop: '5px' }} size={1}>
                        <IonIcon color={isPassed(item, 'PICKED') ? 'primary' : 'medium'} icon={storefront} />
                      </IonCol>
                      <IonCol className='ion-no-padding' style={{ paddingTop: '10px' }}>
                        <IonProgressBar
                          value={isPassed(item, 'DELIVERED') ? 1 : 0}
                          color={isPassed(item, 'ON_MOVE') ? 'primary' : 'medium'}
                          type={item.shippingStatus === 'ON_MOVE' ? 'indeterminate' : 'determinate'}></IonProgressBar>
                      </IonCol>
                      <IonCol className='ion-no-padding ion-text-center' style={{ paddingTop: '5px' }} size={1}>
                        <IonIcon icon={bagCheck} color={isPassed(item, 'DELIVERED') ? 'primary' : 'medium'} />
                      </IonCol>
                    </IonRow>
                    {(item.shippingStatus === 'CONSIDERED' || item.shippingStatus === 'ON_MOVE') && (
                      <>
                        <IonRow>
                          <IonCol>{'آیا سفارشتان را تحویل گرفتید؟'}</IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol className='ion-text-end'>
                            <IonButton color='success' onClick={() => confirmDelivery(item)} disabled={checkInprogress}>
                              {'تحویل گرفتم'}
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </>
                    )}
                    <IonRow className='pt-4'>
                      <IonCol>
                        <AcceptorContact acceptorCode={item.payeeReferralCode} showLabel={true} />
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </IonCardContent>
            </IonCard>
          ))}
          {!!inprogress && <IonSpinner name='crescent' color='dark' />}
          {actives.length === 0 && !inprogress && (
            <div className='ion-padding-top'>
              <IonChip style={{ width: '100%' }}>{'رکوردی وجود ندارد'}</IonChip>
            </div>
          )}
        </>
      )}
      <CustomModal
        showModal={showWalletResponseModal}
        toggle={setShowWalletResponseModal}
        title={t('wallet.doPay')}
        content={
          <WalletResponse
            trackingNumberProp={item?.trackingNumber}
            onSuccess={async () => {
              await getActives()
              setShowWalletResponseModal(false)
            }}
          />
        }
      />
      <Modal
        showModal={showAyriaPaymentModal}
        toggle={setShowAyriaPaymentModal}
        onClosed={() => setShowAyriaPaymentModal(false)}
        title={`سفارش ${item?.trackingNumber}`}
        content={<OnlineOrderReceipt payment={item} />}
      />
      <Modal
        showModal={showConfirmDelivery}
        toggle={setShowConfirmDelivery}
        onClosed={() => setShowConfirmDelivery(false)}
        title={`سفارش ‌${item?.trackingNumber}`}
        content={
          <>
            <IonLabel>{'آیا کالای خود را تحویل گرفتید؟'}</IonLabel>
          </>
        }
        actionButtons={
          <>
            <IonButton color='primary' onClick={async () => markAsDelivered(item)} disabled={checkInprogress}>
              {'بله'}
            </IonButton>
            <IonButton color='dark' onClick={() => setShowConfirmDelivery(false)}>
              {'انصراف'}
            </IonButton>
          </>
        }
      />
    </>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    activeOrderCount: state.user.activeOrderCount,
  }),
  mapDispatchToProps: {
    setActiveOrderCount,
  },
  component: OnlineOrderThread,
})
