import React, { useState, useRef } from 'react'
import { IonPage, IonContent, IonCard, IonButton, IonRow, IonCol, IonSpinner } from '@ionic/react'
import { useIonViewDidEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { CustomModal, WalletBalance, WalletDeposit } from '../../ayria'
import { isDesktop, toLocaleString } from '../../utility'
import { setScoreBalance, setWalletBalance } from '../../data/user/user.actions'
import { connect } from '../../data/connect'
import api from '../../api/Api'
import { Header, useToast } from '../../ayria'
import { IPG_MIN_ACCEPTED_AMOUNT } from '../../config'
import './PurchaseScoreTab.scss'
import smallCoins from './images/small-coins.png'
import mediumCoins from './images/medium-coins.png'
import bigCoins from './images/big-coins.png'
import fifty from './images/50.png'
import fiveHundred from './images/500.png'
import fiveThousend from './images/5000.png'

interface StateProps {
  walletBalance: number
}

interface DispatchProps {
  setScoreBalance: typeof setScoreBalance
  setWalletBalance: typeof setWalletBalance
}

interface PurchaseScoreTabProps extends StateProps, DispatchProps {}

const PurchaseScoreTab: React.FC<PurchaseScoreTabProps> = ({ setScoreBalance, walletBalance, setWalletBalance }) => {
  const { t } = useTranslation()
  const toaster = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [scorePackage, setScorePackage] = useState('')
  const [originalCommand, setOriginalCommand] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [neededAmount, setNeededAmount] = useState(0)
  const ionContent = useRef() as React.MutableRefObject<HTMLIonContentElement>

  const purchase = (pack: string) => {
    setScorePackage(pack)
    setShowConfirmModal(true)
  }

  const price = () => {
    return scorePackage === 'SMALL' ? 15000 : scorePackage === 'MEDIUM' ? 150000 : 1500000
  }

  const purchasePackageForReal = async () => {
    setInprogress(true)
    const res: any = await api.post('/wallet/purchase/score', { scorePackage })
    if (res.ok) {
      await setScoreBalance(res.data)
      toaster.success('افزایش امتیاز با موفقیت انجام شد')
      setShowConfirmModal(false)
      getWalletBalance()
    } else if (res.status === 417) {
      const debt: number = price() - walletBalance
      setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
      setOriginalCommand(JSON.stringify({ scorePackage }))
      setShowWalletDepositModal(true)
    } else {
      toaster.error(res)
    }
    setInprogress(false)
  }

  const getWalletBalance = async () => {
    const res: any = await api.post('user/wallet-snapshots/current', {})
    if (res.ok) {
      await setWalletBalance(res.data.balance)
    }
  }

  useIonViewDidEnter(() => {
    ionContent.current.scrollToBottom(500)
  })

  return (
    <IonPage id='purchase-score-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent fullscreen ref={ionContent}>
        <Header />
        <IonCard className='titr'>{'با افزایش امتیاز احتمال برنده شدنت توی قرعه‌کشی رو بالا ببر...'}</IonCard>
        <div className='purchase-score ion-align-items-end'>
          <IonRow className='coin-row ion-align-items-end'>
            <IonCol onClick={() => purchase('BIG')} size='4' className='ion-justify-content-center'>
              <img src={bigCoins} alt='coin' />
              <img src={fiveThousend} alt='5000' />
            </IonCol>
            <IonCol onClick={() => purchase('MEDIUM')} size='4' className='ion-justify-content-center'>
              <img src={mediumCoins} alt='coin' />
              <img src={fiveHundred} alt='500' />
            </IonCol>
            <IonCol onClick={() => purchase('SMALL')} size='4' className='ion-justify-content-center'>
              <img src={smallCoins} alt='coin' />
              <img src={fifty} alt='50' />
            </IonCol>
          </IonRow>
          {inprogress && <IonSpinner name='crescent' color='primary' />}
        </div>
        <CustomModal
          showModal={showConfirmModal}
          toggle={setShowConfirmModal}
          title={'تایید پرداخت'}
          content={
            <div>
              <WalletBalance showScore={false} />
              <div>
                <IonRow>
                  <IonCol>امتیاز مورد نظر شما برای افزایش</IonCol>
                  <IonCol>{scorePackage === 'SMALL' ? 50 : scorePackage === 'MEDIUM' ? 500 : 5000}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>{'مبلغ پرداختی'}</IonCol>
                  <IonCol>
                    {toLocaleString(price())} {t('label.riyal')}
                  </IonCol>
                </IonRow>
              </div>
              <div className='border-full p-2 mt-2'>
                <div className='ion-text-center'>
                  <IonButton onClick={() => purchasePackageForReal()}>{'کیف پول'}</IonButton>
                </div>
              </div>
            </div>
          }
        />
        <CustomModal
          showModal={showWalletDepositModal}
          toggle={setShowWalletDepositModal}
          title={'افزایش موجودی'}
          content={
            <WalletDeposit
              amount={neededAmount + ''}
              originalCommand={originalCommand}
              originalCommandType={'PURCHASE_SCORE'}
              targetAmount={price()}
            />
          }
        />
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    walletBalance: state.user.walletBalance,
  }),
  mapDispatchToProps: {
    setScoreBalance,
    setWalletBalance,
  },
  component: PurchaseScoreTab,
})
