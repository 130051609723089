import React, { useState, useEffect, useContext } from 'react'
import { IonInput, IonItem, IonLabel, IonButton } from '@ionic/react'
import { withTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import { useToast } from '../../../ayria'

const BankShebaTransferService = ({ t, onSuccess, onCancel }) => {
  const toaster = useToast()
  const [step, setStep] = useState(1)
  const [shebaLogin, setShebaLogin] = useState()
  const [form, setForm] = useState({})
  const [shebaResult, setShebaResult] = useState()
  const [inprogress, setInprogress] = useState(false)
  const [profileContext] = useContext(ProfileContext)
  const handleChange = (e) => {
    setForm({ ...form, [e.detail.id]: e.detail.value })
    if (e.detail.id === 'iban_number' && e.detail.value.length === 24) {
      fetchOwnerName('IR' + e.detail.value)
    }
  }

  async function fetchOwnerName(ibanNumber) {
    setForm({ ...form, owner_name: null })
    const res = await api.fn.ibanNumberInfo({ ibanNumber: ibanNumber })
    setForm({ ...form, owner_name: res.data.ownerName })
  }
  async function handleOAuthLogout() {
    api.fn.boomOAuthLogout().then(() => {
      onCancel()
    })
  }
  async function handleTransfer() {
    setInprogress(true)
    api.fn
      .shebaTransfer({
        sourceDepositNumber: document.getElementById('source_deposit_number').value,
        ibanNumber: 'IR' + document.getElementById('iban_number').value,
        amount: document.getElementById('amount').value,
        ownerName: form.owner_name,
      })
      .then((res) => {
        if (res.ok) {
          setStep(3)
          setShebaResult(res.data)
        } else {
          toaster.error(res)
        }
        setInprogress(false)
      })
  }
  async function getLoginInfo() {
    try {
      const res = await api.fn.getBoomOAuthLogin()
      setShebaLogin(res.data)
      if (res.data.hasRefreshToken) {
        setStep(2)
      }
    } catch (e) {
      onCancel()
    }
  }

  useEffect(() => {
    getLoginInfo()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {step === 1 && shebaLogin && (
        <div>
          <form action={shebaLogin.action} method='get'>
            {shebaLogin.hiddens.map((hidden) => {
              return <input type='hidden' name={hidden.name} value={hidden.value} key={hidden.name} />
            })}
            <IonButton>ورود</IonButton>
            <IonButton color='light' onClick={(e) => onCancel()}>
              انصراف
            </IonButton>
          </form>
        </div>
      )}
      {step === 2 && (
        <div>
          <IonItem>
            <IonLabel for='source_deposit_number'>شماره حساب مبدا</IonLabel>
            <IonInput
              id='source_deposit_number'
              value={profileContext.profile.bankAccountNumber}
              onIonIonChange={handleChange}
              className='dir-ltr'></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel for='iban_number'>شبا مقصد</IonLabel>
            <div className='row'>
              <div className='col-11'>
                <IonInput type='number' id='iban_number' onIonChange={handleChange} className='dir-ltr'></IonInput>
              </div>
              <div className='valign-main'>
                <br />
                <br />
                <div className='valign-inner text-right' style={{ valign: 'center' }}>
                  IR
                </div>
              </div>
            </div>
          </IonItem>
          {form.owner_name && (
            <IonItem>
              <IonLabel for='owner_name'>نام صاحب سپرده مقصد</IonLabel>
              <IonInput id='owner_name' value={form.owner_name} readOnly={true}></IonInput>
            </IonItem>
          )}
          <IonItem>
            <IonLabel for='amount'>مبلغ (به ریال)</IonLabel>
            <IonInput type='number' className='dir-ltr' id='amount' onIonChange={handleChange}></IonInput>
          </IonItem>
          <IonButton className='btn btn-theme' onClick={handleTransfer} disabled={inprogress}>
            انتقال
          </IonButton>
          <IonButton className='btn' onClick={handleOAuthLogout}>
            خروج
          </IonButton>
          <IonButton className='btn' onClick={(e) => onCancel()}>
            انصراف
          </IonButton>
        </div>
      )}
      {step === 3 && shebaResult && (
        <div>
          <IonItem>
            <IonLabel>شماره حساب مبدا</IonLabel>
            <span>{shebaResult.sourceIbanNumber}</span>
          </IonItem>
          <IonItem>
            <IonLabel>شماره شبا مقصد</IonLabel>
            <span>{shebaResult.ibanNumber}</span>
          </IonItem>
          <IonItem>
            <IonLabel>نام صاحب حساب مقصد</IonLabel>
            <span>{shebaResult.ownerName}</span>
          </IonItem>
          <IonItem>
            <IonLabel>شماره پیگیری</IonLabel>
            <span>{shebaResult.referenceId}</span>
          </IonItem>
          <IonItem>
            <IonLabel>وضعیت تراکنش</IonLabel>
            <span>{t('sheba.transactionStatus.' + shebaResult.transactionStatus)}</span>
          </IonItem>
          <IonItem>
            <IonLabel>وضعیت انتقال</IonLabel>
            <span>{t('sheba.transferStatus.' + shebaResult.transferStatus)}</span>
          </IonItem>
          <IonItem>
            <IonLabel>مبلغ</IonLabel>
            <span>{shebaResult.amount}</span>
          </IonItem>
          <IonButton className='btn' onClick={handleOAuthLogout}>
            خروج
          </IonButton>
          <IonButton className='btn' onClick={(e) => onCancel()}>
            بستن
          </IonButton>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(BankShebaTransferService)
