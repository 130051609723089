import { useContext, useEffect, useCallback } from 'react'
import { useMapEvents } from 'react-leaflet'
import { LeafletMouseEvent } from 'leaflet'
import { LayerContext } from './LayerContext'
import ApiService from '../../../api/ApiService'
import useDebounce from '../../../utility/use-debounce'

const geoApi = new ApiService()

function useAddMarker(selected: boolean) {
  const {
    center,
    zoom,
    ccenter,
    setZoom,
    setPoint,
    activate,
    setActivate,
    setCcenter,
    setAddress,
    setReverseSearchInprogress,
    bounds,
  } = useContext(LayerContext)
  const ccenterChange = useDebounce(ccenter?.lat + ccenter?.lng, 1000)

  const mapEvents = useMapEvents({
    click: (e) => {
      //setPoint(e.latlng)
      //console.log(e.latlng)
    },
    // dbclick: (e: any) => {
    //setPoint(e.latlng)
    // },
    moveend: async (e) => {
      setCcenter(mapEvents.getCenter())
    },
    zoomend: (e) => {
      const czoom = e.target.getZoom()
      setZoom(!!czoom ? (+czoom).toFixed(0) : +zoom)
      setCcenter(mapEvents.getCenter())
    },
  })

  const markerEvent = useCallback(
    (e: LeafletMouseEvent) => {
      e.originalEvent.preventDefault()
      //setPoint(e.latlng)
      e.originalEvent.stopPropagation()
    },
    [setPoint] // eslint-disable-line
  )

  const reverseSearch = async () => {
    setReverseSearchInprogress(true)
    const res: any = await geoApi.geocodingReverse(ccenter, zoom)
    if (res.ok && res.data.address) {
      setAddress(res.data.address)
    }
    setReverseSearchInprogress(false)
  }

  useEffect(() => {
    if (!!ccenterChange) {
      reverseSearch()
    }
  }, [ccenterChange]) // eslint-disable-line

  useEffect(() => {
    mapEvents?.doubleClickZoom.disable()
    if (activate === true) {
      // mapEvents?.on('dbclick', markerEvent)
    }
    return () => {
      // mapEvents?.off('dbclick', markerEvent)
    }
  }, [mapEvents, activate, markerEvent])

  useEffect(() => {
    mapEvents.panTo(center, zoom)
  }, [center]) // eslint-disable-line

  useEffect(() => {
    if (!!bounds) {
      mapEvents.fitBounds(bounds)
    }
  }, [bounds]) // eslint-disable-line

  return { activate, setActivate }
}

export default useAddMarker
