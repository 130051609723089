import React, { useState, useEffect, useContext, lazy } from 'react'
import { IonIcon, IonItem, IonSearchbar, IonSpinner } from '@ionic/react'
import { IonLabel, IonToggle } from '@ionic/react'
import { arrowUndo, cash, wallet, card, qrCode, cart, link, checkmark, ban } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { toGregorian } from '../../../utility/calendar'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../utility/pagination'
import { ItemCount, Modal, setWithTimeout, useDebounce } from '../../../utility'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import EventEmitter from '../../../utility/event-emitter'
import { ApiService, AyriaIcon, Acceptor, connect, isDesktop } from '../../../ayria'
import PayerName from '../AyriaPayment/PayerName'
import { NumberIonInput } from '../../units'

const AyriaPayment = lazy(() => import('../../elements/AyriaPayment/AyriaPayment'))

interface OwnProps {
  acceptor: Acceptor
  todayOnly?: boolean
  onlineOrderOnly?: boolean
  onlineOrderTable?: boolean
  onlineOrderPickup?: boolean
  onlineOrderDelivery?: boolean
  onRowClickHandler?: Function
  perPage?: number
}
interface StateProps {
  settings: any
}
interface FactorsProps extends OwnProps, StateProps {}

const Factors: React.FC<FactorsProps> = ({
  acceptor,
  todayOnly = false,
  onlineOrderOnly = false,
  onlineOrderTable = false,
  onlineOrderPickup = false,
  onlineOrderDelivery = false,
  settings,
  onRowClickHandler,
  perPage = ITEMS_PER_PAGE,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [items, setItems] = useState<any>([])
  const [pagination, setPagination] = useState({
    itemsPerPage: perPage,
    sort: 'createdDate',
    order: 'desc',
    activePage: 1,
  })
  const [totalItems, setTotalItems] = useState(0)
  const [fromDate] = useState(null)
  const [toDate] = useState(null)
  const [item, setItem] = useState<any>({ amount: 0 })
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [paid, setPaid] = useState<any>(onlineOrderOnly ? true : undefined)
  const [onlineOrder, setOnlineOrder] = useState<any>(onlineOrderOnly)
  const [searchText, setSearchText] = useState<string>()
  const [payerMobile, setPayerMobile] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [notDoneCases, setNotDoneCases] = useState(onlineOrderOnly)
  const [pickupOnly, setPickupOnly] = useState(onlineOrderPickup)
  const [tablesOnly, setTablesOnly] = useState(onlineOrderTable)
  const [deliveryOnly, setDeliveryOnly] = useState(onlineOrderDelivery)
  const debouncedSearchTerm = useDebounce(`${payerMobile} ${searchText}`, 500)

  const fetchItems = async (page: number, size: number, sort: string) => {
    setInprogress(true)
    try {
      const url = todayOnly ? `/acceptor-roles/factors/${acceptor?.code}/today` : `/acceptor/factors/${acceptor?.code}`
      const res: any = await api.get(
        `${url}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${
          fromDate ? `&fromDate=${toGregorian(fromDate)}` : ''
        }${toDate ? `&toDate=${toGregorian(toDate)}` : ''}${paid !== undefined ? `&paid.equals=${paid}` : ''}${
          onlineOrder ? `&onlineOrder.equals=true` : ''
        }${searchText ? `&paymentTrackingCode.contains=${searchText}` : ''}${
          payerMobile ? `&paymentPayerMobile.contains=${payerMobile}` : ''
        }${notDoneCases ? `&orderDone.equals=false` : ''}${pickupOnly ? `&receiveType.equals=PICKUP` : ''}${
          tablesOnly ? `&receiveType.equals=TABLES` : ''
        }${deliveryOnly ? `&receiveType.equals=DELIVERY` : ''}`
      )
      if (res.ok) {
        setWithTimeout(setItems, res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
    setInprogress(false)
  }

  const getAllItems = () =>
    fetchItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllItems()

  useEffect(() => {
    EventEmitter.subscribe('NEW_PAYEE_REQUEST', () => {
      sortEvents()
    })
    EventEmitter.subscribe('FACTOR_PAID_STATUS_NEED_UPDATE', () => {
      sortEvents()
    })
    EventEmitter.subscribe('ONLINE_ORDER_COUNT_NEED_UPDATE', () => {
      sortEvents()
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [
    pagination.activePage,
    pagination.order,
    pagination.sort,
    acceptorContext.currentIndex,
    paid,
    onlineOrder,
    notDoneCases,
    pickupOnly,
    tablesOnly,
    debouncedSearchTerm,
  ]) // eslint-disable-line

  const handlePagination = (currentPage: number) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  const handleRowLick = (item: any) => {
    if (!!onRowClickHandler) {
      onRowClickHandler(item)
    } else {
      setItem(item)
      setShowReceiptModal(true)
    }
  }

  return (
    <>
      {isDesktop() ? (
        <div>
          {!onlineOrderOnly && (
            <div className='row p-3  justify-content-between'>
              <div>
                <div
                  className={'report-tab  ' + (paid === undefined && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(undefined)
                    setOnlineOrder(undefined)
                  }}>
                  {'همه'}
                </div>
                <div
                  className={'report-tab mr-2 ' + (paid === true && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(true)
                    setOnlineOrder(undefined)
                  }}>
                  {'پرداخت شده'}
                </div>
                <div
                  className={'report-tab mr-2 ' + (paid === false && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(false)
                    setOnlineOrder(undefined)
                  }}>
                  {'پرداخت نشده'}
                </div>
              </div>

              <div className='dir-ltr'>
                {inprogress && <IonSpinner slot='start' />}
                <NumberIonInput
                  onValueChange={setSearchText}
                  style={{ overflow: 'hidden' }}
                  className={'col-5 dir-ltr report-tab disableIonBackground ion-text-start font-roboto'}
                  placeholder={'شماره پیگیری'}
                  value={searchText}
                />
                <NumberIonInput
                  onValueChange={setPayerMobile}
                  style={{ overflow: 'hidden' }}
                  className={'col-5 dir-ltr report-tab disableIonBackground ion-text-start font-roboto ion-margin-end'}
                  placeholder={'شماره همراه'}
                  value={payerMobile}
                />
              </div>
            </div>
          )}

          {onlineOrder && (
            <>
              <IonItem className='disableIonBackground'>
                <IonLabel onClick={() => setNotDoneCases(!notDoneCases)}>{'فقط نمایش موارد در انتظار رسیدگی'}</IonLabel>
                <IonToggle checked={notDoneCases} onIonChange={(e) => setNotDoneCases(e.detail.checked!)}></IonToggle>
              </IonItem>
              {(settings?.delivery || deliveryOnly) && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setDeliveryOnly(!deliveryOnly)
                      setTablesOnly(false)
                      setPickupOnly(false)
                    }}>
                    {'فقط نمایش موارد تحویل پیک'}
                  </IonLabel>
                  <IonToggle
                    checked={deliveryOnly}
                    onIonChange={(e) => {
                      setDeliveryOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
              {(settings?.pickup || pickupOnly) && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setPickupOnly(!pickupOnly)
                      setTablesOnly(false)
                      setDeliveryOnly(false)
                    }}>
                    {'فقط نمایش موارد تحویل حضوری'}
                  </IonLabel>
                  <IonToggle
                    checked={pickupOnly}
                    onIonChange={(e) => {
                      setPickupOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
              {(settings?.tables || tablesOnly) && acceptor?.department === 'RESTAURANT' && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setTablesOnly(!tablesOnly)
                      setPickupOnly(false)
                      setDeliveryOnly(false)
                    }}>
                    {'فقط نمایش موارد داخل سالن'}
                  </IonLabel>
                  <IonToggle
                    checked={tablesOnly}
                    onIonChange={(e) => {
                      setTablesOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
            </>
          )}

          <div className='col-12' style={{ overflowX: 'auto' }}>
            <table className='customTableTwo'>
              <thead>
                <tr>
                  <th>شماره پیگیری</th>
                  <th>شماره همراه</th>
                  <th>زمان ثبت</th>
                  <th>تاریخ ثبت</th>
                  <th>مبلغ</th>
                  <th>عملیات</th>
                </tr>
              </thead>

              <tbody>
                {items.map((item: any, index: number) => {
                  return (
                    <tr
                      className={
                        (item.paid ? ' alert-success' : '') +
                        (item.canceled ? ' alert-warning' : '') +
                        (item.refunded ? ' alert-danger' : '')
                      }
                      key={item.trackingNumber + ' ' + index}
                      onClick={() => {
                        handleRowLick(item)
                      }}>
                      <td>{item.trackingNumber ? item.trackingNumber : item.purchaseId}</td>
                      <td>
                        <PayerName item={item} />
                      </td>
                      <td>{item?.createdTime}</td>
                      <td>{item?.createdDate}</td>
                      <td>{item.unitedAmount.toLocaleString(navigator.language)}</td>

                      <td className='d-flex flex-row align-items-center justify-content-around'>
                        {item.paid && !item.refunded && (
                          <span className='p-1'>
                            <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                          </span>
                        )}
                        {item.paid && item.refunded && (
                          <span className='p-1 text-danger'>
                            <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />
                          </span>
                        )}
                        {item.canceled && (
                          <span className='p-1'>
                            <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                          </span>
                        )}
                        {!!item.onlineOrder && (
                          <>
                            <IonIcon
                              icon={cart}
                              color={!!item.orderDone ? '' : !!item.cashier ? 'warning' : 'danger'}
                            />
                          </>
                        )}
                        {!item.kalas && item.paymentMethod !== 'POS' && <IonIcon icon={qrCode} />}
                        {item.paymentMethod === 'WALLET' && <IonIcon icon={wallet} />}
                        {item.paymentMethod === 'CASH' && <IonIcon icon={cash} />}
                        {item.paymentMethod === 'POS' && item.posConnected && <IonIcon icon={link} />}
                        {item.paymentMethod === 'POS' && <IonIcon icon={card} />}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {/*{items.map((item: any, index: number) => {*/}
          {/*  return (*/}
          {/*    <div*/}
          {/*      key={item.trackingNumber + ' ' + index}*/}
          {/*      onClick={() => {*/}
          {/*        handleRowLick(item)*/}
          {/*      }}*/}
          {/*      className={*/}
          {/*        'flex-container border-bottom pointer' +*/}
          {/*        (item.paid ? ' alert-success' : '') +*/}
          {/*        (item.canceled ? ' alert-warning' : '') +*/}
          {/*        (item.refunded ? ' alert-danger' : '')*/}
          {/*      }>*/}
          {/*            <span className='flex'>*/}
          {/*        <p className='ellipsis m-0' style={{ maxWidth: isDesktop() ? '450px' : '200px' }}>*/}
          {/*          <span className='ion-padding-end'>{item.trackingNumber ? item.trackingNumber : item.purchaseId}</span>{' '}*/}
          {/*          <PayerName item={item} />*/}
          {/*          <br />*/}
          {/*          <span className='ion-padding-end'>{item.number}</span>{' '}*/}
          {/*          <span className='ion-padding-start'>{item.createdDate}</span>*/}
          {/*          {!!item.receiveTime && (*/}
          {/*            <span className='ion-padding-start'>*/}
          {/*              (<span className='text-bold'>{item.receiveTime}</span>)*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {!!item.tableIdentifier && (*/}
          {/*            <span className='ion-adding-start'>*/}
          {/*              {' '}*/}
          {/*              ({'میز'}*/}
          {/*              <span className='text-bold' style={{ padding: '5px' }}>*/}
          {/*                {item.tableIdentifier}*/}
          {/*              </span>*/}
          {/*              )*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*        </p>*/}
          {/*      </span>*/}
          {/*      <span className='flex text-left'>*/}
          {/*        <span>{item.unitedAmount.toLocaleString(navigator.language)}</span>*/}
          {/*        <span style={{ width: '100px', display: 'inline-block' }} className='text-left'>*/}
          {/*          {item.paid && !item.refunded && (*/}
          {/*            <span className='p-1'>*/}
          {/*              <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {item.paid && item.refunded && (*/}
          {/*            <span className='p-1 text-danger'>*/}
          {/*              <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {item.canceled && (*/}
          {/*            <span className='p-1'>*/}
          {/*              <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {!!item.onlineOrder && (*/}
          {/*            <>*/}
          {/*              <IonIcon icon={cart} color={!!item.orderDone ? '' : !!item.cashier ? 'warning' : 'danger'} />*/}
          {/*            </>*/}
          {/*          )}*/}
          {/*          {!item.kalas && item.paymentMethod !== 'POS' && <IonIcon icon={qrCode} />}*/}
          {/*          {item.paymentMethod === 'WALLET' && <IonIcon icon={wallet} />}*/}
          {/*          {item.paymentMethod === 'CASH' && <IonIcon icon={cash} />}*/}
          {/*          {item.paymentMethod === 'POS' && item.posConnected && <IonIcon icon={link} />}*/}
          {/*          {item.paymentMethod === 'POS' && <IonIcon icon={card} />}*/}
          {/*        </span>*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  )*/}
          {/*})}*/}

          {!todayOnly && (
            <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
              <div className='row text-center'>
                <div className='col'>
                  <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
                </div>
              </div>

              <AyriaPagination
                activePage={pagination.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          )}

          <Modal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title={' صورتحساب ' + (item.trackingNumber ? item.trackingNumber : item.purchaseId)}
            isPlatformTheme={true}
            contentUsePadding={false}
            onClosed={() => {
              setShowReceiptModal(false)
              sortEvents()
            }}
            id='ayriaPaymentModalAcceptor'
            content={
              <AyriaPayment
                payment={item}
                acceptorCode={acceptor?.code}
                onSuccess={() => {
                  setShowReceiptModal(false)
                  sortEvents()
                }}
                showCashierActions={true}
              />
            }
          />
        </div>
      ) : (
        <div>
          {!onlineOrderOnly && (
            <div className='row mr-2'>
              <>
                <div
                  className={'report-tab ' + (paid === undefined && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(undefined)
                    setOnlineOrder(undefined)
                  }}>
                  {'همه'}
                </div>
                <div
                  className={'report-tab ' + (paid === true && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(true)
                    setOnlineOrder(undefined)
                  }}>
                  {'پرداخت شده'}
                </div>
                <div
                  className={'report-tab ' + (paid === false && !onlineOrder ? 'active' : '')}
                  onClick={(e) => {
                    setPaid(false)
                    setOnlineOrder(undefined)
                  }}>
                  {'پرداخت نشده'}
                </div>
              </>
              <br />
            </div>
          )}
          {onlineOrder && (
            <>
              <IonItem>
                <IonLabel onClick={() => setNotDoneCases(!notDoneCases)}>{'فقط نمایش موارد در انتظار رسیدگی'}</IonLabel>
                <IonToggle checked={notDoneCases} onIonChange={(e) => setNotDoneCases(e.detail.checked!)}></IonToggle>
              </IonItem>
              {(settings?.delivery || deliveryOnly) && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setDeliveryOnly(!deliveryOnly)
                      setTablesOnly(false)
                      setPickupOnly(false)
                    }}>
                    {'فقط نمایش موارد تحویل پیک'}
                  </IonLabel>
                  <IonToggle
                    checked={deliveryOnly}
                    onIonChange={(e) => {
                      setDeliveryOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
              {(settings?.pickup || pickupOnly) && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setPickupOnly(!pickupOnly)
                      setTablesOnly(false)
                      setDeliveryOnly(false)
                    }}>
                    {'فقط نمایش موارد تحویل حضوری'}
                  </IonLabel>
                  <IonToggle
                    checked={pickupOnly}
                    onIonChange={(e) => {
                      setPickupOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
              {(settings?.tables || tablesOnly) && acceptor?.department === 'RESTAURANT' && (
                <IonItem>
                  <IonLabel
                    onClick={() => {
                      setTablesOnly(!tablesOnly)
                      setPickupOnly(false)
                      setDeliveryOnly(false)
                    }}>
                    {'فقط نمایش موارد داخل سالن'}
                  </IonLabel>
                  <IonToggle
                    checked={tablesOnly}
                    onIonChange={(e) => {
                      setTablesOnly(e.detail.checked!)
                    }}></IonToggle>
                </IonItem>
              )}
            </>
          )}
          <IonItem>
            <IonSearchbar
              className={'col-12 dir-ltr'}
              placeholder={'شماره پیگیری'}
              onIonChange={(e) => setSearchText(e.detail.value)}
            />
            {inprogress && <IonSpinner slot='start' />}
          </IonItem>
          {items.map((item: any, index: number) => {
            return (
              <div
                key={item.trackingNumber + ' ' + index}
                onClick={() => {
                  handleRowLick(item)
                }}
                className={
                  'flex-container border-bottom pointer' +
                  (item.paid ? ' alert-success' : '') +
                  (item.canceled ? ' alert-warning' : '') +
                  (item.refunded ? ' alert-danger' : '')
                }>
                <span className='flex'>
                  <p className='ellipsis m-0' style={{ maxWidth: isDesktop() ? '450px' : '200px' }}>
                    <span className='ion-padding-end'>
                      {item.trackingNumber ? item.trackingNumber : item.purchaseId}
                    </span>{' '}
                    <PayerName item={item} />
                    <br />
                    <span className='ion-padding-end'>{item.number}</span>{' '}
                    <span className='ion-padding-start'>{item.createdDate}</span>
                    {!!item.receiveTime && (
                      <span className='ion-padding-start'>
                        (<span className='text-bold'>{item.receiveTime}</span>)
                      </span>
                    )}
                    {!!item.tableIdentifier && (
                      <span className='ion-adding-start'>
                        {' '}
                        ({'میز'}
                        <span className='text-bold' style={{ padding: '5px' }}>
                          {item.tableIdentifier}
                        </span>
                        )
                      </span>
                    )}
                  </p>
                </span>
                <span className='flex text-left'>
                  <span>{item.unitedAmount.toLocaleString(navigator.language)}</span>
                  <span style={{ width: '100px', display: 'inline-block' }} className='text-left'>
                    {item.paid && !item.refunded && (
                      <span className='p-1'>
                        <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                      </span>
                    )}
                    {item.paid && item.refunded && (
                      <span className='p-1 text-danger'>
                        <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />
                      </span>
                    )}
                    {item.canceled && (
                      <span className='p-1'>
                        <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                      </span>
                    )}
                    {!!item.onlineOrder && (
                      <>
                        <IonIcon icon={cart} color={!!item.orderDone ? '' : !!item.cashier ? 'warning' : 'danger'} />
                      </>
                    )}
                    {!item.kalas && item.paymentMethod !== 'POS' && <IonIcon icon={qrCode} />}
                    {item.paymentMethod === 'WALLET' && <IonIcon icon={wallet} />}
                    {item.paymentMethod === 'CASH' && <IonIcon icon={cash} />}
                    {item.paymentMethod === 'POS' && item.posConnected && <IonIcon icon={link} />}
                    {item.paymentMethod === 'POS' && <IonIcon icon={card} />}
                  </span>
                </span>
              </div>
            )
          })}
          {!todayOnly && (
            <div className='row text-center'>
              <div className='col'>
                <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
              </div>
            </div>
          )}
          {!todayOnly && (
            <div className='row text-center'>
              <div className='col'></div>
              <div className='col text-center'>
                <AyriaPagination
                  activePage={pagination.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={pagination.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
              <div className='col'></div>
            </div>
          )}
          <Modal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title={' صورتحساب ' + (item.trackingNumber ? item.trackingNumber : item.purchaseId)}
            isPlatformTheme={true}
            contentUsePadding={false}
            onClosed={() => {
              setShowReceiptModal(false)
              sortEvents()
            }}
            id='ayriaPaymentModalAcceptor'
            content={
              <AyriaPayment
                payment={item}
                acceptorCode={acceptor?.code}
                onSuccess={() => {
                  setShowReceiptModal(false)
                  sortEvents()
                }}
                showCashierActions={true}
              />
            }
          />
        </div>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    settings: state.shop.settings,
  }),
  component: Factors,
})
