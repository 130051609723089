import React, { FC, useEffect, useState } from 'react'
import { IonBackButton } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'

interface AyriaBackButtonProps {
  defaultHref?: string
  text?: string
  disabled?: boolean
  style?: any
}

const AyriaBackButton: FC<AyriaBackButtonProps> = ({ defaultHref, text = '', disabled = false, style }) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    setRender(true)
  }, [])

  return (
    <>{render && <IonBackButton defaultHref={defaultHref} text={text} icon={chevronForward} disabled={disabled} />}</>
  )
}

export default AyriaBackButton
