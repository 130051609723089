import axios from 'axios'
import { API_BASE_URL } from '../../config'
import ApiService from '../../api/ApiService'

export default class Auth {
  async login(username, password, rememberMe = false, captchaToken) {
    try {
      const response = await axios.post(API_BASE_URL + '/authenticate', {
        username: username?.trim(),
        password,
        rememberMe,
        captchaToken,
      })
      localStorage.setItem('id_token', response.data.id_token)
      localStorage.setItem('access_token', response.data.id_token)
      return 'OK'
    } catch (err) {
      return err
    }
  }

  async logout() {
    const res = await new ApiService().post('/logout')
    if (res.ok) {
      localStorage.removeItem('id_token')
      localStorage.removeItem('access_token')
      localStorage.removeItem('LOGGED_IN_USER')
      return true
    }
    return false
  }

  setLoggedInUser(data) {
    if (data) {
      localStorage.setItem('LOGGED_IN_USER', JSON.stringify(data))
    }
  }

  getLoggedInUser() {
    return JSON.parse(localStorage.getItem('LOGGED_IN_USER'))
  }
}
