import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import RateSinglePurchase from '../rate-single-purchase/rate-single-purchase'
import api from '../../../api/Api'
import { API_BASE_URL } from '../../../config'
import { CustomModal } from '../../../ayria'

const RatePurchases = ({ t }) => {
  const [selectedItem, setSelectedItem] = useState({})
  const [data, setData] = useState([])
  const [isShowRateModal, setIsShowRateModal] = useState(false)

  const handleRateSingleActivityOnCancel = () => {
    setIsShowRateModal(false)
    setSelectedItem({})
  }
  const handleRateSingleActivityOnSuccess = (updatedElement) => {
    setIsShowRateModal(false)
    setSelectedItem({})
    getData()
  }
  const handleOnRowClick = (element, index) => {
    setSelectedItem(element)
    setIsShowRateModal(true)
  }
  async function getData() {
    const dataRes = await api.fn.getPurchasesForRate()
    setData(dataRes.ok ? dataRes.data : [])
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div>
        {data &&
          data.map((element, index) => (
            <div
              key={element.transactionId + element.id}
              onClick={() => handleOnRowClick(element, index)}
              className='flex-container border-bottom pointer'>
              <span className='flex-1'>
                <img
                  alt={'logo'}
                  src={`${API_BASE_URL}/public/acceptors/logo/${element.acceptorCode}`}
                  style={{ height: '50px' }}
                />
              </span>
              <span className='flex-2 p-1'>
                <p className='ellipsis m-0' style={{ width: '40vw' }}>
                  {element.acceptorName}
                  <br />
                  {element.purchaseDatePersian}
                </p>
              </span>
              <span className='flex-1 text-left'>{element.totalAmount.toLocaleString(navigator.language)}</span>
            </div>
          ))}
      </div>
      <CustomModal
        title={t('title.rate_acceptor')}
        showModal={isShowRateModal}
        toggle={setIsShowRateModal}
        content={
          <RateSinglePurchase
            onCancel={handleRateSingleActivityOnCancel}
            onSuccess={handleRateSingleActivityOnSuccess}
            activityItem={selectedItem}
          />
        }
      />
    </>
  )
}

export default withTranslation()(RatePurchases)
