import React, { useEffect, useContext } from 'react'
import { IonButton } from '@ionic/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { WalletContext } from '../../structures/contexts/WalletContext'
import { ProfileContext } from '../../structures/contexts/ProfileContext'

interface IdentityVerifierProps {
  onIsOk: () => void
}

const IdentityVerifier: React.FC<IdentityVerifierProps> = ({ onIsOk }) => {
  const { t } = useTranslation()
  const MAX_ALLOWED_TRANSFER_AMOUNT = 10000000
  const [wallet] = useContext<any>(WalletContext)
  const [profile] = useContext<any>(ProfileContext)

  useEffect(() => {
    if (wallet.balance > MAX_ALLOWED_TRANSFER_AMOUNT) {
      if (!profile.profile.nationalCodeVerified) {
        // Oops
      } else {
        onIsOk()
      }
    } else {
      onIsOk()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <p className='text-danger'>{t('error.identiyIsNotVerified')}</p>
      <Link to={'/profile'}>
        <IonButton>{'رفتن به پروفایل'}</IonButton>
      </Link>
    </div>
  )
}

export default IdentityVerifier
