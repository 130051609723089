import React, { lazy } from 'react'
import { Route } from 'react-router'
import PageContainerAcceptor from '../components/structures/pagecontainer-acceptor/pagecontainer-acceptor'
import Panel from '../components/screens/panel/panel'
import Desk from '../pages/acceptor/Desk'
import '../App-deprecated.scss'
import FoodCourt from './home/FoodCourt'

const KalaSearch = lazy(() => import('./home/KalaSearch'))
const AcceptorSearch = lazy(() => import('./home/AcceptorSearch'))
const AcceptorKalaSearch = lazy(() => import('./home/AcceptorKalaSearch'))
const Profile = lazy(() => import('../components/screens/profile/profile'))
const ProfileAcceptor = lazy(() => import('../components/screens/profile-acceptor/profile-acceptor'))
const ActivateForm = lazy(() => import('../components/modules/signup-form/Activate'))
const SignupFormAcceptor = lazy(() => import('../components/modules/signup-form-acceptor/signup-form-acceptor'))
const ActivateAcceptor = lazy(() => import('../components/modules/signup-form-acceptor/Activate'))
const AcceptorRequest = lazy(() => import('../components/modules/acceptor-request/acceptor-request'))
const ScreenPasswordReset = lazy(() => import('../components/screens/password_reset/passwordreset'))
const PasswordResetFinish = lazy(() => import('../components/screens/password_reset/passwordReset-finish'))
const MobileResetFinish = lazy(() => import('../components/screens/mobile-reset/mobile-reset-finish'))
const ScreenPasswordResetAcceptor = lazy(() => import('../components/screens/password_reset_acceptor/passwordreset'))
const PasswordResetFinishAcceptor = lazy(
  () => import('../components/screens/password_reset_acceptor/passwordReset-finish')
)
const PageContainer = lazy(() => import('./PageContainer'))
const ScreenPasswordChange = lazy(() => import('../components/screens/change_password/changepassword'))
const ScreenPasswordChangeAcceptor = lazy(
  () => import('../components/screens/change_password-acceptor/changepassword-acceptor')
)
const ScreenWalletIdentityAcceptor = lazy(
  () => import('../components/screens/wallet-identity-acceptor/wallet-identity-acceptor')
)
const SettingsAcceptor = lazy(() => import('../components/screens/settings-acceptor/SettingsAcceptor'))
const ScreenInvoices = lazy(() => import('../components/screens/invoices/invoices'))
const PlanSelect = lazy(() => import('../components/screens/plan-select/plan-select'))
const Login = lazy(() => import('./Login'))
const PanelSettings = lazy(() => import('../components/screens/acceptor/PanelSettings'))
const Bill = lazy(() => import('../pages/acceptor/Bill'))
const PaymentServices = lazy(() => import('./acceptor/PaymentServices'))
const AcceptorList = lazy(() => import('./home/AcceptorList'))
const Sellect = lazy(() => import('../components/screens/sellect/sellect'))
const SecretarySellect = lazy(() => import('../components/screens/secretary-sellect/secretary-sellect'))
const DeliverySelect = lazy(() => import('../components/screens/delivery/DeliverySelect'))
const Delivery = lazy(() => import('../components/screens/delivery/Delivery'))
const SecretarySell = lazy(() => import('../components/screens/secretary-sell/secretary-sell'))
const Buy = lazy(() => import('../components/screens/buy/buy'))
const WalletReport = lazy(() => import('../components/screens/wallet/wallet-report/wallet-report'))
const WalletEvents = lazy(() => import('../components/screens/wallet/wallet-events/wallet-events'))
const BuyUnauthorized = lazy(() => import('../components/screens/buy/buyUnAuthorized'))
const ScoreEvents = lazy(() => import('../components/screens/score-events/score-events'))
const Notifications = lazy(() => import('../components/screens/notifications/notifications'))
const Purchases = lazy(() => import('../components/screens/purchases/purchases'))
const BoomOAuth2LoginCallback = lazy(
  () => import('../components/screens/boom-oauth2-login-callback/boom-oauth2-login-callback')
)
const BankServices = lazy(() => import('../components/screens/bank-services/bank-services'))
const PaymentServiceReport = lazy(() => import('../components/screens/payment-service-report/payment-service-report'))
const WalletResponse = lazy(() => import('../components/screens/wallet/wallet-response/wallet-response'))
const DeliveryMap = lazy(() => import('./acceptor/delivery/DeliveryMap'))

const Routes = () => {
  return (
    <>
      <Route
        path='/search'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='search-page' showAddressCombo={true} showCopyRight={false}>
            <AcceptorKalaSearch {...props} />
          </PageContainer>
        )}
      />
      <Route path='/search/center' exact={true} render={(props) => <AcceptorSearch {...props} />} />
      <Route path='/search/kala' exact={true} render={(props) => <KalaSearch {...props} />} />
      <Route
        path='/activate'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='activate-page'>
            <ActivateForm {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/acceptors-signup'
        exact={true}
        render={(props) => {
          return <SignupFormAcceptor {...props} />
        }}
      />
      <Route
        path='/acceptor-activate'
        exact={true}
        render={(props) => {
          return <ActivateAcceptor {...props} />
        }}
      />

      <Route
        path='/password-reset'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='password-reset-page'>
            <ScreenPasswordReset {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/password-reset-acceptor'
        exact={true}
        render={(props) => {
          return <ScreenPasswordResetAcceptor {...props} />
        }}
      />
      <Route
        path='/password-reset-finish'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='password-reset-finish-page'>
            <PasswordResetFinish {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/mobile-reset-finish'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='mobile-reset-finish-page'>
            <MobileResetFinish {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/password-reset-finish-acceptor'
        exact={true}
        render={(props) => {
          return <PasswordResetFinishAcceptor {...props} />
        }}
      />
      <Route
        path='/allPlan'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='all-plan-page' hideMenu={true}>
            <PlanSelect {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/change-password'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='change-password-page' isPrivate={true}>
            <ScreenPasswordChange {...props}></ScreenPasswordChange>
          </PageContainer>
        )}
      />
      <Route
        path='/change-password-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <ScreenPasswordChangeAcceptor {...props}></ScreenPasswordChangeAcceptor>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/wallet-identity-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <ScreenWalletIdentityAcceptor {...props}></ScreenWalletIdentityAcceptor>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/settings-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <SettingsAcceptor {...props}></SettingsAcceptor>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/invoices'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='invoices-page' isPrivate={true}>
            <ScreenInvoices {...props}></ScreenInvoices>
          </PageContainer>
        )}
      />
      <Route
        path='/profile'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='profile-page' isPrivate={true}>
            <Profile {...props}></Profile>
          </PageContainer>
        )}
      />
      <Route
        path='/profile-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <ProfileAcceptor {...props}></ProfileAcceptor>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/acceptors'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='acceptors-page' isPrivate={true}>
            <AcceptorList {...props}></AcceptorList>
          </PageContainer>
        )}
      />
      <Route
        path='/foodcourt/:court'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='foodcourt-page' isPrivate={false}>
            <FoodCourt {...props}></FoodCourt>
          </PageContainer>
        )}
      />
      <Route
        path='/acceptors-request'
        exact={true}
        render={(props) => {
          return <AcceptorRequest {...props} />
        }}
      />
      <Route
        path='/login-acceptor'
        exact={true}
        render={(props) => {
          return <Login isAcceptor={true} {...props} />
        }}
      />
      <Route
        path='/panel'
        exact={true}
        render={(props) => {
          return (
            <PageContainerAcceptor {...props} pageId='panel'>
              <Panel {...props} />
            </PageContainerAcceptor>
          )
        }}
      />
      <Route
        path='/panel-settings'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='panel-settings'>
            <PanelSettings {...props}></PanelSettings>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/desk'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='desk' showHeader={false}>
            <Desk {...props}></Desk>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/desk/:acceptorCodeParam'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='desk-acceptor-code' showHeader={false}>
            <Desk {...props}></Desk>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/payment-services/:acceptorCode'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='payment-services'>
            <PaymentServices {...props}></PaymentServices>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/bill/:acceptorCode'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='bill'>
            <Bill {...props}></Bill>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/sellect'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <Sellect {...props}></Sellect>
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/secretary-sellect'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='secretary-sellect-page' isPrivate={true}>
            <SecretarySellect {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/secretary-sell/:acceptorCode'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props} pageId='secretary-sell' isPrivate={true} showHeader={false}>
            <SecretarySell {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/delivery-select'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='delivery-select' isPrivate={true}>
            <DeliverySelect {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/delivery/:acceptorCode'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='delivery-page' isPrivate={true}>
            <Delivery {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/buy/:id'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='buy-page' isPrivate={true}>
            <Buy {...props}></Buy>
          </PageContainer>
        )}
      />
      <Route path='/landing/:referalId' exact={true} render={(props) => <BuyUnauthorized {...props} />} />
      <Route
        path='/wallet-report'
        exact={true}
        render={(props) => (
          <PageContainer {...props} id='wallet-report-page' hasPadding={true} isPrivate={true}>
            <WalletReport {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/wallet-report-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <WalletReport isAcceptor={true} {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/wallet-events-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <WalletEvents isAcceptor={true} type='acceptor' {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/wallet-events-club'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <WalletEvents isAcceptor={true} type='club' {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/boomcallback'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='boomcallback-page' isPrivate={true}>
            <BoomOAuth2LoginCallback {...props}></BoomOAuth2LoginCallback>
          </PageContainer>
        )}
      />
      <Route
        path='/score-events'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='score-events-page' hasPadding={true} isPrivate={true}>
            <ScoreEvents {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/score-events-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <ScoreEvents isAcceptor={true} {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/notifications-acceptor'
        exact={true}
        render={(props) => (
          <PageContainerAcceptor {...props}>
            <Notifications {...props} />
          </PageContainerAcceptor>
        )}
      />
      <Route
        path='/purchases/:type'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='purchases' isPrivate={true}>
            <Purchases {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/bank-services'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='bank-services-page' isPrivate={true}>
            <BankServices {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/payment-service-report'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='payment-service-report-page' isPrivate={true}>
            <PaymentServiceReport {...props} />
          </PageContainer>
        )}
      />
      <Route
        path='/wallet-response/:trackingNumber'
        exact={true}
        render={(props) => (
          <PageContainer {...props} pageId='wallet-response-page' isPrivate={true}>
            <WalletResponse {...props} />
          </PageContainer>
        )}
      />
      <Route path='/delivery-map/:acceptorCode' exact={true} render={(props) => <DeliveryMap {...props} />} />
    </>
  )
}

export default Routes
