import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header } from '../ayria'
import RatePurchases from '../components/modules/rate-purchases/rate-purchases'

interface RatePurchaseTabProps {}

const RatePurchaseTab: React.FC<RatePurchaseTabProps> = () => {
  return (
    <IonPage id='rate-purchase-tab-page'>
      <IonContent className='ion-padding'>
        <Header />
        <div>
          <br />
          <div>
            <h4>امتیازدهی به فروشگاه</h4>
            <p>{'با ثبت نظر خود در مورد فروشگاه امتیاز بگیرید.'}</p>
          </div>
          <RatePurchases />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default RatePurchaseTab
