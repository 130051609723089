import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLUB_LOWER_CASE } from '../../ayria';

declare var document: any;

interface MenuItemProps {
  title: string;
  to: string;
  hasOffset?: boolean;
  scrollTo: (id: string, hasOffset: boolean) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, to, hasOffset = true, scrollTo }) =>
  <li className='item pointer'>
    <button onClick={() => scrollTo(to, hasOffset)}>{title}</button>
  </li>

const Logo = () => <div className={'landing__header_logo'} />

interface LandingHeaderProps {
  content: any;
}

const LandingHeader: React.FC<LandingHeaderProps> = ({ content }) => {

  const { t } = useTranslation()

  const scrollTo = (id: string, hasOffset: boolean) => {
    const y: number = document.getElementById(id).offsetTop;
    content.current.scrollToPoint(0, hasOffset ? y - 80 : y, 1000)
  }

  return (
    <div className='landing__header'>
      <Logo />
      <ul className='landing__header_menu'>
        <MenuItem title={t(`club.${CLUB_LOWER_CASE}.landing-menu1`)} to={'main-section'} hasOffset={false} scrollTo={scrollTo} />
        <MenuItem title={t(`club.${CLUB_LOWER_CASE}.landing-menu2`)} to={'signup-teaser'} scrollTo={scrollTo}/>
        <MenuItem title={'طرح ها'} to={'plans-info'} scrollTo={scrollTo}/>
        <MenuItem title={t(`club.${CLUB_LOWER_CASE}.landing-menu4`)} to={'join'} scrollTo={scrollTo}/>
      </ul>
    </div>
  )
}

export default LandingHeader
