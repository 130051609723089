import React, { useState, useEffect } from 'react'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import SellChart from '../chart/SellChart'
import SellChartDaily from '../chart/SellChartDaily'
import SellChartDailyTable from '../chart/SellChartDailyTable'
import { isMobile } from '../../../ayria'

const SellReport = () => {
  const [tab, setTab] = useState('daily')
  const [daily] = useState(true)
  const [weekly, setWeekly] = useState(false)
  const [total, setTotal] = useState(true)
  const [weeklyOrTotal, setWeeklyOrTotal] = useState('weekly')

  useEffect(() => {
    if (!isMobile()) {
      if (weeklyOrTotal === 'weekly') {
        setWeekly(true)
        setTotal(false)
      } else if (weeklyOrTotal === 'total') {
        setTotal(true)
        setWeekly(false)
      }
    }
  }, [weeklyOrTotal]) // eslint-disable-line

  return (
    <>
      {isMobile() && (
        <>
          <div className='report-tabs'>
            <div className={'report-tab ' + (tab === 'daily' ? 'active' : '')} onClick={(e) => setTab('daily')}>
              {'فروش روزانه'}
            </div>
            <div className={'report-tab ' + (tab === 'weekly' ? 'active' : '')} onClick={(e) => setTab('weekly')}>
              {'فروش هفتگی'}
            </div>
            <div className={'report-tab ' + (tab === 'total' ? 'active' : '')} onClick={(e) => setTab('total')}>
              {'فروش کل'}
            </div>
          </div>
          <div className='clear-both'>
            {tab === 'daily' && <SellChartDailyTable />}
            {tab === 'weekly' && <SellChart />}
            {tab === 'total' && <SellChartDaily />}
          </div>
        </>
      )}
      {!isMobile() && (
        <IonGrid>
          <div className='d-flex flex-row col-xs-12 report-tabs overflow-auto justify-content-start pb-4 '>
            <div
              className={'report-tab ' + (weeklyOrTotal === 'daily' ? 'active' : '')}
              onClick={() => setWeeklyOrTotal('daily')}>
              فروش روزانه
            </div>
            <div
              className={'report-tab mr-2 ' + (weeklyOrTotal === 'weekly' ? 'active' : '')}
              onClick={() => setWeeklyOrTotal('weekly')}>
              فروش هفتگی
            </div>
            <div
              className={'report-tab mr-2 ' + (weeklyOrTotal === 'total' ? 'active' : '')}
              onClick={() => setWeeklyOrTotal('total')}>
              فروش کل
            </div>
          </div>

          {/*<IonRadioGroup value={weeklyOrTotal} onIonChange={(e) => setWeeklyOrTotal(e.detail.value)}>*/}
          {/*  <IonRow>*/}
          {/*    <IonCol>*/}
          {/*      <IonItem>*/}
          {/*        <IonLabel>{'فروش روزانه'}</IonLabel>*/}
          {/*        /!*<IonCheckbox checked={daily} onIonChange={(e) => setDaily(e.detail.checked)} />*!/*/}
          {/*      </IonItem>*/}
          {/*    </IonCol>*/}
          {/*    <IonCol>*/}
          {/*      <IonItem>*/}
          {/*        <IonLabel>{'فروش هفتگی'}</IonLabel>*/}
          {/*        <IonRadio value={'weekly'} />*/}
          {/*      </IonItem>*/}
          {/*    </IonCol>*/}
          {/*    <IonCol>*/}
          {/*      <IonItem>*/}
          {/*        <IonLabel>{'فروش کل'}</IonLabel>*/}
          {/*        <IonRadio value={'total'} />*/}
          {/*      </IonItem>*/}
          {/*    </IonCol>*/}
          {/*  </IonRow>*/}
          {/*</IonRadioGroup>*/}

          <IonRow>
            {daily && (
              <IonCol size='4'>
                <SellChartDailyTable />
              </IonCol>
            )}
            {weekly && (
              <IonCol>
                <SellChart />
              </IonCol>
            )}
            {total && (
              <IonCol>
                <SellChartDaily />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      )}
    </>
  )
}

export default SellReport
