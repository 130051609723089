import React, { FC } from 'react'
import { ClubManagers } from '../../components'
import Wallet from '../../components/screens/wallet/wallet'
import { Club, User } from '../../models'
import VoucherEvents from '../voucher/VoucherEvents'
import MyClubAcceptors from './MyClubAcceptors'
import MyClubBanner from './MyClubBanner'
import MyClubEdit from './MyClubEdit'
import MyClubGroup from './MyClubGroup'
import MyClubLogo from './MyClubLogo'
import MyClubMembers from './MyClubMembers'
import MyClubScore from './MyClubScore'
import MyClubSpecs from './MyClubSpecs'

interface OwnProps {
  code: string
  loggedUser: User | any
  club: Club
}
const MyClubTabMobile: FC<OwnProps> = ({ code, loggedUser, club }) => {
  return (
    <div className='ion-padding'>
      <MyClubEdit />
      <br />
      <br />
      <MyClubSpecs code={code} />
      <br />
      <br />
      <Wallet type={'club'} acceptorCode={code} />
      <br />
      <br />
      <VoucherEvents code={code} type='club' />
      <br />
      <br />
      <MyClubScore code={code} />
      <br />
      <br />
      <MyClubAcceptors code={code} />
      <br />
      <br />
      <MyClubGroup />
      <br />
      <br />
      <MyClubMembers code={code} />
      <br />
      <br />
      <MyClubLogo code={code} />
      <br />
      <br />
      <MyClubBanner code={code} />
      <br />
      <br />
      {(loggedUser?.authorities?.includes('ROLE_CLUB_OWNER') ||
        loggedUser?.authorities?.includes('ROLE_ACCEPTOR_PARTNER')) &&
        club?.owner && (
          <>
            <ClubManagers clubCode={code} />
            <br />
            <br />
          </>
        )}
    </div>
  )
}

export default MyClubTabMobile
