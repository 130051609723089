import React from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import PurchasePlan from '../components/elements/PurchasePlan/PurchasePlan';
import CopyRight from './CopyRight';
import { setMenuEnabled } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { CLUB_LOWER_CASE } from '../config';

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled;
}

interface PurchasePlanPageProps extends DispatchProps { };

const PurchasePlanPage: React.FC<PurchasePlanPageProps> = ({ setMenuEnabled }) => {

  useIonViewWillEnter(() => {
    setMenuEnabled(false);
  });

  useIonViewWillLeave(() => {
    setMenuEnabled(true);
  });

  return (
    <IonPage id='purchase-plan-page'>
      <IonContent className='ion-padding' fullscreen>
        <IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonButtons slot='end'>
              <img src={`/assets/${CLUB_LOWER_CASE}/logo.png`} width='50px' alt='logo' />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <PurchasePlan />
        <br />
        <br />
        <CopyRight />
      </IonContent>
    </IonPage>
  )

}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {
    setMenuEnabled,
  },
  component: PurchasePlanPage
});
