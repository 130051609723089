import React from 'react'
import { connect } from '../data/connect'
import { Redirect } from 'react-router'
import User from '../models/User'
import { CLUB_HAS_LANDING } from '../config'

interface StateProps {
  hasSeenLanding: boolean
  loggedUser?: User
}

const HomeOrLanding: React.FC<StateProps> = ({ hasSeenLanding, loggedUser }) => {
  return hasSeenLanding || !CLUB_HAS_LANDING ? (
    <Redirect to={`/tabs${window.location.search}`} />
  ) : (
    <Redirect to={`/start${window.location.search}`} />
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenLanding: state.user.hasSeenLanding,
    loggedUser: state.user.loggedUser,
  }),
  component: HomeOrLanding,
})
