import { UserActions } from './user.actions'
import { UserState } from './user.state'

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case 'set-user-loading':
      return { ...state, loading: action.isLoading }
    case 'set-user-data':
      return { ...state, ...action.data }
    case 'set-username':
      return { ...state, username: action.username }
    case 'set-has-seen-landing':
      return { ...state, hasSeenLanding: action.hasSeenLanding }
    case 'set-theme':
      return { ...state, theme: action.theme }
    case 'set-dark-mode':
      return { ...state, darkMode: action.darkMode }
    case 'set-is-logged-in':
      return { ...state, isLoggedIn: action.isLoggedIn }
    case 'set-menu-enabled':
      return { ...state, menuEnabled: action.menuEnabled }
    case 'set-wallet-balance':
      return { ...state, walletBalance: action.walletBalance }
    case 'set-logged-user':
      return { ...state, loggedUser: action.loggedUser }
    case 'set-saved-club':
      return { ...state, savedClub: action.savedClub }
    case 'set-score-balance':
      return { ...state, scoreBalance: action.scoreBalance }
    case 'set-pin-checked':
      return { ...state, pinChecked: action.pinChecked }
    case 'set-has-seen-register-center':
      return { ...state, hasSeenRegisterCenter: action.hasSeenRegisterCenter }
    case 'set-has-seen-register-club':
      return { ...state, hasSeenRegisterClub: action.hasSeenRegisterClub }
    case 'set-has-seen-club-list':
      return { ...state, hasSeenClubList: action.hasSeenClubList }
    case 'set-unpaid-factor-count':
      return { ...state, unpaidFactorCount: action.unpaidFactorCount }
    case 'set-unpaid-person-count':
      return { ...state, unpaidPersonCount: action.unpaidPersonCount }
    case 'set-referral-code':
      return { ...state, referralCode: action.referralCode }
    case 'set-club-code':
      return { ...state, clubCode: action.clubCode }
    case 'set-kala-list':
      return { ...state, kalaList: action.kalaList }
    case 'add-kala':
      return { ...state, addKala: action.addKala }
    case 'remove-kala':
      return { ...state, removeKala: action.removeKala }
    case 'set-shipping-address':
      return { ...state, shippingAddress: action.address }
    case 'set-active-order-count':
      return { ...state, activeOrderCount: action.activeOrderCount }
    case 'set-receive-type':
      return { ...state, receiveType: action.receiveType }
    case 'set-pickup-delay':
      return { ...state, pickupDelay: action.pickupDelay }
    case 'set-table-identifier':
      return { ...state, tableIdentifier: action.tableIdentifier }
    case 'set-gift-token':
      return { ...state, giftToken: action.giftToken }
    case 'set-last-search':
      return { ...state, lastSearch: action.lastSearch }
    case 'set-failure-count': {
      return { ...state, failureCount: action.failureCount }
    }
  }
}
