import React from 'react'
import { IonIcon } from '@ionic/react'
import { heart, heartHalfOutline, heartDislikeOutline } from 'ionicons/icons'

interface RateIconProps {
  rate: number
}

const RateIcon: React.FC<RateIconProps> = ({ rate }) => {
  return (
    <>
      {rate === 1 && <IonIcon icon={heartDislikeOutline} />}
      {rate === 2 && <IonIcon icon={heartHalfOutline} />}
      {rate === 3 && <IonIcon icon={heart} />}
    </>
  )
}

export default RateIcon
