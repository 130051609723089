import React, { Suspense, useState, useEffect, useRef, useContext } from 'react'
import { URLSearchParams } from 'whatwg-url'
import { withRouter, RouteComponentProps } from 'react-router'
import { IonSearchbar, IonSpinner } from '@ionic/react'
import useDebounce from '../../utility/use-debounce'
import { Club } from '../../models'
import { connect } from '../../data'
import { LayerContext } from '../shared/map'
import './acceptorListHomePage.scss'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import AcceptorVitrin from './AcceptorVitrin'
import DepartmentSelector from './DepartmentSelector'

interface OwnProps extends RouteComponentProps {
  savedClub?: Club
  rowClicked?: Function
  hideDepartments?: boolean
  scrollTop: number
  homeMode?: boolean
}

interface StateProps {
  shippingAddress: any
}

interface AcceptorListProps extends OwnProps, StateProps {}

const AcceptorListHomePage: React.FC<AcceptorListProps> = ({
  savedClub,
  hideDepartments = false,
  shippingAddress,
  scrollTop,
  homeMode = false,
  history,
}) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [searchText, setSearchText] = useState(urlSearchParams.get('name') ? urlSearchParams.get('name') : undefined)
  const debouncedSearchText = useDebounce(searchText, 1000)
  const [slideIndex, setSlideIndex] = useState(0)
  const debouncedSlideIndex = useDebounce(slideIndex, 500)
  const [pagination, setPagination] = useState({ itemsPerPage: 10, sort: 'soldSum', order: 'desc', activePage: 1 })
  const [reset, setReset] = useState(false)
  const searchbar = useRef<any>()
  const { currentLocation } = useContext(LayerContext)
  const [oldScrollTop, setOldScrollTop] = useState(0)
  const [isScrollToTop, setIsScrollToTop] = useState(true)
  const deps = [
    'ALL',
    'RESTAURANT',
    'STORE',
    'CLOTHING',
    'CONSULTING',
    'ART',
    'BEAUTY',
    'CULTURE',
    'DIGITAL',
    'FORMALITY',
    'HEALTH',
    'HOME_APPLIANCE',
    'JEWELLERY',
    'SCIENCE',
    'SPORT',
    'TRAVEL',
  ]

  useEffect(() => {
    searchbar?.current?.getInputElement().then((searchinput: any) => {
      if (!!searchinput && searchinput.setAttribute) {
        searchinput.setAttribute('name', 'search')
        searchinput.setAttribute('id', 'search')
      }
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (reset) {
      setPagination({ ...pagination, activePage: 1 })
    }
  }, [reset]) //eslint-disable-line

  useEffect(() => {
    setReset(true)
  }, [debouncedSearchText, debouncedSlideIndex, shippingAddress?.id, currentLocation, hideDepartments]) // eslint-disable-line

  useEffect(() => {
    setOldScrollTop(scrollTop)
    setIsScrollToTop(oldScrollTop > scrollTop)
  }, [scrollTop]) // eslint-disable-line

  return (
    <Suspense fallback={<IonSpinner name='crescent' color='primary' />}>
      <div
        className='row headerAnimation'
        style={{ position: 'sticky', top: isScrollToTop ? '44px' : '-50px', zIndex: 999, backgroundColor: 'white' }}>
        <div className={'col-12'}>
          <IonSearchbar
            value={!homeMode ? searchText : undefined}
            placeholder={'جستجو'}
            onIonChange={(e) => {
              if (homeMode && !!e.detail.value) {
                history.push('/search')
              } else {
                setSearchText(e.detail.value)
              }
            }}
            autocomplete='off'
            onClick={() => {
              if (homeMode) {
                history.push('/search')
              }
            }}
            ref={searchbar}
          />
        </div>
      </div>

      {!hideDepartments && (
        <DepartmentSelector
          onSelectDept={(id: number) => {
            setSlideIndex(id)
          }}
        />
      )}

      <div className='clear-both mt-3'>
        <AcceptorVitrin title={'فروشگاه های جدید'} department={deps[slideIndex]} sortByNewCenters={true} />

        <div
          className='position-relative '
          style={{ margin: 10 }}
          onClick={() => {
            history.push('/tabs/mycenternew')
          }}>
          <img
            className='overflow-hidden cursor-pointer'
            style={{
              // margin: 10,
              borderRadius: '15px',
            }}
            alt=''
            src='/assets/images/ad-banner-acceptor.jpg'
          />
          <div className='custom-btn-one position-absolute ' style={{ bottom: 15, right: 25 }}>
            <span>ثبت نام پذیرنده</span>
          </div>
        </div>

        <AcceptorVitrin title={'فروشگاه های پرطرفدار'} department={deps[slideIndex]} sortByRate={true} />

        <ImageGallery
          showNav={false}
          additionalClass='originalClassImageGallery'
          autoPlay={true}
          slideInterval={4000}
          disableSwipe={true}
          showFullscreenButton={false}
          showBullets={false}
          showThumbnails={false}
          showPlayButton={false}
          showIndex={false}
          items={[
            {
              original: '/assets/images/ad-banner-2.png',
              thumbnail: '/assets/images/ad-banner-2.png',
            },
          ]}
        />

        <AcceptorVitrin title={'فروشگاه های برتر'} department={deps[slideIndex]} sortByRank={true} />
      </div>
    </Suspense>
  )
}
export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    shippingAddress: state.user.shippingAddress,
  }),
  component: withRouter(AcceptorListHomePage),
})
