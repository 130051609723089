import React from 'react'
import { CLUB_LOWER_CASE } from '../../../config'
import { useTranslation } from 'react-i18next'

const Rules = () => {
  const { t } = useTranslation()

  return (
    <div className=' ruleContainer'>
      <div className='row'>
        <div className='col-md-12'>
          <div className=' style-5'>
            <div id='rules' className='expand'>
              <div className='card-body'>
                <span>{t(`club.${CLUB_LOWER_CASE}.rules-first`)}</span>
                <ul>
                  <li className='pb-3 pt-3'>
                    {' '}
                    کلیه اطلاعات هویتی کاربران آیریا اعم از نام و نام خانوادگی، کد ملی، شماره های تماس، آدرس محل سکونت،
                    پست الکترونیکی و به صورت کلی هر گونه اطلاعات هویتی و شخصی که از جانب کاربران جهت ارائه خدمات آیریا
                    در اختیار ما قرار می گیرد به صورت محرمانه و شخصی تلقی شده و این اطلاعات در اختیار افراد ثالث قرار
                    نخواهند گرفت. ارائه این اطلاعات تنها در موارد خاص و بنا به حکم مراجع ذیصلاح قضایی و با حکم رسمی مرجع
                    قضایی قابل ارائه به افراد ذیصلاح خواهد بود و آیریا تمامی تلاش خود را در راستای حفظ و صیانت از
                    اطلاعات شخصی کاربران خود به عمل خواهد آورد.{' '}
                  </li>
                  <li className='pb-3'>
                    {' '}
                    صحت اطلاعات ارائه شده توسط کاربران در وبسایت به عهده کاربران می باشد و آیریا مختار است در صورت
                    صلاحدید نسبت به بررسی و تایید این اطلاعات با کاربر ارتباط برقرار نموده و مراتب را بررسی نماید.
                  </li>
                  <li className='pb-3'>
                    با توجه به الزام بانک مرکزی و سازمان مبارزه با پولشویی مبالغ بیش از ۵،۰۰۰،۰۰۰ ریال در هر حساب ریالی
                    نیاز به تایید و تشخیص هویت داشته ، لذا چنانچه موجودی کیف پول شما از این سقف تجاوز نماید تا تکمیل و
                    تائید هویت شما توسط سامانه آیریا امکان برداشت و یا انتقال برای شما وجود نخواهد داشت . لازم به توضیح
                    اینکه اطلاعات شما از طریق صفحه کاربری و در قسمت پروفایل ثبت و در همین قسمت به صورت سیستمی تائید می
                    گردد و به هیچ عنوان نیاز به مراجعه حضوری به شرکت و یا نمایندگیها وجود ندارد.
                  </li>
                  <li className='pb-3'>پرداخت به صورت تسهیم</li>
                  <li className='pb-3'>
                    {' '}
                    اطلاعات تماس کاربران اعم از شماره همراه و پست الکترونیکی کاربران تنها جهت مقاصد غیر تبلیغاتی و صرفاً
                    برای اطلاع رسانی ها از خدمات استفاده شده کاربران و یا ارائه اطلاعات در خصوص امکانات و طرح های آیریا
                    مورد استفاده قرار می گیرد و این اطلاعات در اختیار افراد ثالث قرار داده نخواهد شد و همچنین افشا
                    نخواهند گردید. کاربران وبسایت و همچنین اعضای گرامی آیریا به صورت پیش فرض پذیرفته اند که جهت ارتباط
                    با ایشان، بهینه سازی محتوای وبسایت، انجام تحقیقات بازاریابی، اطلاع رسانی اخبار و خدمات باشگاه
                    وفاداری آیریا، سرویس های ویژه و پروموشن ها، برای ایشان از جانب آیریا ایمیل و پیامک ارسال شود. در
                    صورت عدم تمایل کابران و اعضا مبنی بر دریافت اینگونه ایمیل ها و پیامک ها، کاربران می توانند با مراجعه
                    به پروفایل کاربری خود، عضویت در خبرنامه آیریا را لغو نمایند. در غیر این صورت مراتب به منزله موافقت
                    ضمنی با دریافت ایمیل ها و پیامک ها و سلب حق اعتراض می باشد.
                  </li>
                  <li className='pb-3'>
                    {' '}
                    نقد ها و نظرات ارسالی کاربران و اعضای آیریا در صورت صلاحدید ممکن است در راستای قوانین کلی و خط مشی
                    آیریا ویرایش گردد. همچنین نظرات و یا پیام های ارسالی کاربران و اعضا در صورت شمول مصادیق محتوای
                    مجرمانه مطابق با قوانین کشور، می تواند توسط آیریا جهت پیگیری قانونی مورد استفاده و استناد قرار گیرد.
                    کابران و اعضای باشگاه ضمن استفاده از خدمات آیریا و همچنین امکانات وبسایت باشگاه، حق ویرایش اطلاعات و
                    استفاده از آنها را در چارچوب فوق الذکر به آیریا اعطا نموده و حق هر گونه اعتراض را از خود سلب می
                    نمایند.
                  </li>
                  <li className='pb-3'>
                     تاکید مؤکد می گردد که حفظ و نگهداری رمز عبور و نام کاربری بر عهده کاربران و اعضا می باشد و جهت
                    جلوگیری از هر گونه سو استفاده احتمالی، کاربر یا عضو نباید اطلاعات حساس و کاربری خود را برای شخص
                    دیگری فاش نماید. در صورت واگذاری شماره همراه ثبت شده در پروفایل کاربری به شخص دیگری، کاربر و یا عضو
                    می بایست بلافاصله نسبت به تغییر شماره تلفن همراه خود و ثبت و احراز شماره جدید اقدام نماید و در صورت
                    عدم رعایت هر یک از موارد فوق الذکر توسط کاربر یا عضو، هیچگونه ادعایی متوجه آیریا از جانب کاربر و یا
                    عضو نمی باشد.
                  </li>
                  <li className='pb-3'>
                    با توجه به امکان استفاده از اعتبار برای طرح های ستاره و کهکشان ، مبلغ استفاده شده از اعتبار می بایست
                    حداکثر ظرف مدت 45 روز به واسطه شارژ کیف پول مسترد گردد.در غیر اینصورت سامانه مختار خواهد بود به هر
                    طریق که صلاح بداند نسبت به وصول این مبلغ اقدام نماید و همچنین مجاز به مسدودی حساب کاربری شما می
                    باشد.
                  </li>
                  <li className='pb-3'>
                    آیریا همانند سایر وب سایت ها از جمع آوری اطلاعات آی پی و کوکی ها جهت شخصی سازی خدمات برای کاربران و
                    اعضای خود استفاده می نماید. آیریا حداکثر تلاش خود را با توجه به پروتکل ها، سرورها و لایه های امنیتی
                    و ملاحضات فنی و امنیتی در راستای محافظت از اطلاعات کاربران و جلوگیری از دسترسی های غیر قانونی به عمل
                    می آورد. بدیهی است مسئولیت هر گونه سو استفاده و اختلال متوجه و مربوط به شخص یا اشخاص متخلف بوده و
                    آیریا حق اعتراض و پیگیری را از طریق قانونی و جبران خسارات مادی و معنوی مرتبطه را برای خود محفوظ می
                    دارد.{' '}
                  </li>
                </ul>
                <p>
                  {t(`club.${CLUB_LOWER_CASE}.title`)} همواره تمامی تلاش و توان خود را در جهت حفاظت و صیانت از اطلاعات و
                  حریم شخصی کاربران و اعضای خود را به کار میگیرد و امیدواریم که تجربه هایی خوشایند و منحصر بفردی برای
                  کاربران و اعضای خود رقم بزند.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rules
