import React, { useState, useEffect, useContext } from 'react'
import { add, closeCircle } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IonButton, IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react'
import { IonCheckbox, IonCol, IonContent, IonIcon, IonInput } from '@ionic/react'
import { IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonTextarea } from '@ionic/react'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import { ApiService, useToast, Modal, CurrencyIonInput, BrandSuggest, isMobile } from '../../../ayria'
import { SingleUploader, TagSuggest, NumberIonInput, Header, CDN_BASE_URL } from '../../../ayria'
import './online-shop-managment.css'
import { setWithTimeout } from '../../../utility'

interface ProductManagementProps {}
const ProductManagement: React.FC<ProductManagementProps> = () => {
  const history = useHistory()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const toaster = useToast()
  const api = new ApiService()
  const { t } = useTranslation()
  const service = false
  const label = service ? 'خدمات' : 'کالا'
  const acceptor = acceptorContext.current
  const acceptorCode = acceptorContext.current.code
  const [inprogress, setInprogress] = useState(false)
  const [item, setItem] = useState<any>()
  const [items, setItems] = useState<any>([])
  const [name, setName] = useState<any>(undefined)
  const [showInStore, setShowInStore] = useState<boolean>(false)
  const [inventoryCount, setInventoryCount] = useState<any>()
  const [kalaOrder, setKalaOrder] = useState<any>()
  const [description, setDescription] = useState<any>(undefined)
  const [price, setPrice] = useState<any>(undefined)
  const [brand, setBrand] = useState<any>()
  const [brandName, setBrandName] = useState<string>()
  const [showBrandEmptyWarning, setShowBrandEmptyWarning] = useState(false)
  const [tag, setTag] = useState<any>()
  const [tagName, setTagName] = useState<string>()
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [photos, setPhotos] = useState<any>([])
  const [tags, setTags] = useState<any>([])

  const getItems = async () => {
    const res: any = await api.get(`/acceptor-kala/list/${acceptorContext.current.code}/false`)
    setWithTimeout(setItems, res.ok ? res.data : [])
  }
  const getItem = async (kalaId: number) => {
    const res: any = await api.get(`/acceptor-kala/get/${kalaId}?${Math.random() * 10000000}`)
    if (res.ok) {
      setItem(res.data)
      setName(res.data.name)
      setPrice(res.data.price)
      setDescription(res.data.description)
      setShowInStore(res.data.showInStore)
      setInventoryCount(res.data.inventoryCount)
      setKalaOrder(res.data.kalaOrder)
      setShowAddModal(true)
    }
  }
  const handleAdd = async () => {
    if (!service && !brand && !brandName && !showBrandEmptyWarning && isBrandNeccessary()) {
      setShowBrandEmptyWarning(true)
      return
    }
    const res: any = await api.post(`/acceptor-kala/add`, {
      acceptorCode,
      price,
      name,
      service,
      showInStore,
      inventoryCount,
      brand,
      brandName,
      description,
      kalaOrder,
    })
    if (res.ok) {
      setShowBrandEmptyWarning(false)
      setShowAddModal(false)
      setTimeout(() => {
        getItems()
      }, 300)
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : t('label.operationFailed'))
    }
  }
  const handleEdit = async () => {
    if (!service && !brand && !brandName && !showBrandEmptyWarning && isBrandNeccessary()) {
      setShowBrandEmptyWarning(true)
      return
    }
    const res: any = await api.put(`/acceptor-kala/update`, {
      id: item.id,
      acceptorCode,
      price,
      name,
      service,
      showInStore,
      inventoryCount,
      brand,
      brandName,
      description,
      kalaOrder,
    })
    if (res.ok) {
      setShowBrandEmptyWarning(false)
      setShowAddModal(false)
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : t('label.operationFailed'))
    }
  }
  const handleDelete = async () => {
    const res: any = await api.delete(`/acceptor-kala/delete/${acceptorCode}/${item.id}`)
    if (res.ok) {
      setShowDeleteModal(false)
      setShowAddModal(false)
      getItems()
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : t('label.operationFailed'))
    }
  }
  const isBrandNeccessary = () => {
    return acceptor.department !== 'RESTAURANT'
  }
  const getTags = async () => {
    setTags([])
    const res: any = await api.get(`/acceptor/kala/${item?.id}/tags`)
    setTags(res.ok ? res.data : [])
  }
  const getPhotos = async () => {
    setPhotos([])
    const res: any = await api.get(`/user/file/kala/${item?.id}/images`)
    setPhotos(res.ok ? res.data : [])
  }
  const sendImage = async (meta: any, byteArray: any) => {
    setInprogress(true)
    const res: any = await api.post(`/user/file/kala/${item.id}/`, {
      type: 'PHOTO',
      content: byteArray,
      contentType: meta.type,
    })
    if (res.ok) {
      toaster.success(`آپلود ${meta.name} موفقیت آمیز بود.`)
      getPhotos()
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : `آپلود ${meta.name} موفقیت آمیز نبود`)
    }
    setInprogress(false)
  }
  const uploadImage = (meta: any, file: File) => {
    if (meta.size > 2097152) {
      toaster.error(`حجم فایل ${meta.name} باید کمتر از ۲ مگابایت باشد`)
      return
    }
    const reader = new FileReader()
    const fileByteArray: any = []
    reader.readAsArrayBuffer(file)
    reader.onloadend = function (evt: any) {
      if (evt.target.readyState === FileReader.DONE) {
        let arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer)
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])
        }
        sendImage(meta, fileByteArray)
      }
    }
  }
  const deleteImage = async (kalaId: number, publicId: string) => {
    const res: any = await api.delete(`/user/file/kala/${kalaId}/${publicId}`)
    if (res.ok) {
      toaster.success('حذف تصویر موفقیت آمیز بود')
      getPhotos()
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : `حذف تصویر موفق آمیز نبود`)
    }
  }
  const addTag = async (tag: any) => {
    const name = tag ? tag.name : tagName
    if (tags.filter((tag: any) => tag.name === name).length > 0) {
      toaster.success('برچسب قبلا اضافه شده است')
      return
    }
    const res: any = await api.post(`/acceptor/kala/${item.id}/tag`, { name })
    if (res.ok) {
      toaster.success('برچسب با موفقیت اضافه شد')
      getTags()
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : 'اضافه کردن برچسب موفقیت آمیز نبود')
    }
  }
  const deleteTag = async (tag: any) => {
    const res: any = await api.delete(`/acceptor/kala/${item.id}/tag/${tag.id}`)
    if (res.ok) {
      toaster.success('حذف برچسب موفقیت آمیز بود')
      getTags()
    } else {
      toaster.error(res.data && res.data.title ? res.data.title : `حذف برچسب موفقیت آمیز نبود`)
    }
  }

  useEffect(() => {
    getItems()
  }, [acceptorContext.current]) // eslint-disable-line

  useEffect(() => {
    if (showAddModal && item?.id) {
      getPhotos()
      getTags()
    }
  }, [showAddModal]) // eslint-disable-line

  return (
    <IonPage id='product-managment-page'>
      <IonContent fullscreen={true}>
        {/*<Header title={kala?.name}/>*/}
        <Header title='مدیریت کالا و خدمات' />
        <hr />
        <IonRow className='ion-align-items-center'>
          {items.map((kala: any) => (
            <IonCol key={kala.id} size={isMobile() ? '12' : '3'}>
              <IonCard className='product' onClick={() => getItem(kala.id)}>
                {/*{kala.photos && kala.photos.length > 0 ? (*/}
                {/*  <img src={`${CDN_BASE_URL}/${kala.photos[0]}`} alt='' crossOrigin='anonymous' />*/}
                {/*) : (*/}
                <img src='https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y' alt='' />
                {/*)}*/}
                <IonCardHeader>
                  <hr />
                  <IonCardSubtitle>{kala?.name}</IonCardSubtitle>
                  {/*<IonCardTitle>{kala?.name}</IonCardTitle>*/}
                  <hr />
                  <IonRow>
                    <IonCol size='6' className='ion-text-right'>
                      فروش{kala?.order}
                    </IonCol>
                    <IonCol size='6' className='ion-text-left'>
                      <span>{kala?.price?.toLocaleString(navigator.language)} تومان </span>{' '}
                    </IonCol>
                  </IonRow>
                </IonCardHeader>
                {/*<IonCardContent>*/}
                {/*</IonCardContent>*/}
              </IonCard>
            </IonCol>
          ))}
        </IonRow>

        <Modal
          showModal={showAddModal}
          toggle={setShowAddModal}
          title={(item?.id ? ' ویرایش ' : 'اضافه کردن ') + `${label} `}
          isPlatformTheme={true}
          id='acceptorKalaModal'
          onClosed={() => {
            setItem(undefined)
            setName(undefined)
            setPrice(undefined)
            setDescription(undefined)
            setInventoryCount(undefined)
            setKalaOrder(undefined)
            setShowInStore(false)
            setShowAddModal(false)
            setShowBrandEmptyWarning(false)
          }}
          content={
            <div className='md-5'>
              <IonItem>
                <IonLabel position='stacked'>{'نام'}</IonLabel>
                <IonInput onIonChange={(e: any) => setName(e.target.value)} value={name} />
              </IonItem>
              {!service && isBrandNeccessary() && (
                <div className='form-group'>
                  <IonLabel position='stacked' style={{ fontSize: 'small', paddingRight: '15px' }}>
                    {'برند'}
                  </IonLabel>
                  <BrandSuggest
                    onSelect={(brand: any) => setBrand(brand)}
                    defaultValue={item?.brand}
                    onChange={(value: string) => {
                      setBrandName(value)
                      setBrand(undefined)
                      setShowBrandEmptyWarning(false)
                    }}
                  />
                  {showBrandEmptyWarning && (
                    <div className='text-danger'>{'لطفا برند کالا خود ر وارد کنید (یا دکمه ثبت را دوباره بزنید)'}</div>
                  )}
                </div>
              )}
              <IonItem>
                <IonLabel position='stacked'>{'قیمت(ریال)'}</IonLabel>
                <CurrencyIonInput onValueChange={setPrice} defaultValue={price} />
              </IonItem>
              <div className='form-group'>
                <IonItem>
                  <IonLabel position='stacked'>{'نمایش در فروشگاه'}</IonLabel>
                  <IonCheckbox
                    checked={showInStore}
                    slot='start'
                    onIonChange={(e) => setShowInStore(e.detail.checked)}
                  />
                </IonItem>
              </div>
              <IonItem>
                <IonLabel position='stacked'>{'شماره ترتیب نمایش در لیست'}</IonLabel>
                <NumberIonInput onValueChange={setKalaOrder} defaultValue={kalaOrder} />
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>{'تعداد و موجودی'}</IonLabel>
                <NumberIonInput onValueChange={setInventoryCount} defaultValue={inventoryCount} />
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>{'توضیحات'}</IonLabel>
                <IonTextarea onIonChange={(e: any) => setDescription(e.target.value)} value={description} />
              </IonItem>
              {item?.id && (
                <div>
                  <div className='form-group'>
                    <label className={'ion-no-margin'}>{'برچسب‌ها (حداکثر ۱۰ برچسب)'}</label>
                    <IonRow className={'ion-align-items-center'}>
                      <IonCol size='10'>
                        <TagSuggest
                          onSelect={(tag: any) => {
                            setTag(tag)
                            addTag(tag)
                          }}
                          onChange={(value: string) => {
                            setTagName(value)
                            setTag(undefined)
                          }}
                        />
                      </IonCol>
                      <IonCol size='2'>
                        <IonButton
                          color='primary'
                          onClick={() => addTag(tag)}
                          disabled={tags.length >= 10 || (!tag && !tagName)}>
                          <IonIcon icon={add} slot='icon-only' />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </div>
                  {tags.length > 0 && (
                    <div>
                      {tags.map((tag: any) => (
                        <IonButton key={tag.id} color={'light'} onClick={() => deleteTag(tag)}>
                          {tag.name}
                          <IonIcon icon={closeCircle} slot='end' color='danger' />
                        </IonButton>
                      ))}
                    </div>
                  )}
                  <div className='form-group'>
                    <label>
                      {`تصاویر ${label} (حداکثر ۵ تصویر)`} {inprogress && <IonSpinner color='primary' />}
                    </label>
                    <SingleUploader onFileUploaded={uploadImage} maxFiles={5 - photos.length} accept={'image/*'} />
                  </div>
                  {photos.length > 0 && (
                    <div>
                      {photos.map((photo: any) => (
                        <span key={photo.publicId} className='ion-padding'>
                          <img
                            width='60px'
                            src={`${CDN_BASE_URL}/${photo.path}/${photo.publicId}`}
                            alt={photo.publicId}
                            crossOrigin='anonymous'
                          />
                          <IonButton color='light' onClick={() => deleteImage(item.id, photo.publicId)}>
                            <IonIcon icon={closeCircle} slot='icon-only' color='danger' />
                          </IonButton>
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          }
          actionButtons={
            <>
              {!item?.id && (
                <IonButton color='primary' onClick={handleAdd}>
                  {'ثبت'}
                </IonButton>
              )}
              {item?.id && (
                <IonButton color='primary' onClick={handleEdit}>
                  {'بروزرسانی'}
                </IonButton>
              )}
              {item?.id && (
                <IonButton
                  color='dark'
                  onClick={() =>
                    history.push(`/kala/${acceptor.referralCode}/${item.id}`, {
                      merchant: null,
                    })
                  }>
                  {'مشاهده'}
                </IonButton>
              )}
              {item?.id && (
                <IonButton color='danger' onClick={() => setShowDeleteModal(true)} className='ion-padding-start'>
                  {'حذف'}
                </IonButton>
              )}
            </>
          }
        />
        {item?.name && (
          <Modal
            showModal={showDeleteModal}
            toggle={setShowDeleteModal}
            title={`حذف کردن ${label}`}
            isPlatformTheme={true}
            onClosed={() => setShowDeleteModal(false)}
            content={
              <div className='md-5'>
                <div>
                  آیا از حذف کردن {label} با نام <strong>{item.name}</strong> اطمینان دارید؟
                </div>
                <br />
              </div>
            }
            actionButtons={
              <>
                <IonButton color='danger' onClick={handleDelete}>
                  {'بله'}
                </IonButton>
                <IonButton className='ion-padding-start' onClick={(e) => setShowDeleteModal(false)}>
                  {t('label.cancel')}
                </IonButton>
              </>
            }
          />
        )}
      </IonContent>
    </IonPage>
  )
}

export default ProductManagement
