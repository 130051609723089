import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header } from '../../ayria'
import WalletSettings from './WalletSettings'

interface SettingsTabProps {}

const SettingsTab: React.FC<SettingsTabProps> = () => {
  return (
    <IonPage id='settings-tab-page'>
      <IonContent>
        <Header />
        <WalletSettings />
      </IonContent>
    </IonPage>
  )
}

export default SettingsTab
