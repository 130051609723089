import { IonButton, IonInput, IonItem, IonLabel, IonListHeader, IonRange, IonSpinner, IonTextarea } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ApiService } from '../../ayria'
import { useToast } from '../../utility'

const MyClubEdit = () => {
  const api = new ApiService()
  const toast = useToast()
  const { code } = useParams<any>()
  const [name, setName] = useState<any>()
  const [voucher, setVoucher] = useState<any>(50)
  const [discount, setDiscount] = useState<any>(50)
  const [description, setDescription] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const update = async () => {
    setInprogress(true)
    const res: any = await api.userUpdateClub({
      code,
      name,
      voucher,
      discount,
      description,
    })
    if (res.ok) {
      setName(res.data.name)
      setDescription(res.data.description)
      setDiscount(res.data.discount)
      setVoucher(res.data.voucher)
      toast.success('باشگاه با موفقیت بروزرسانی شد')
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const getItem = async () => {
    const res: any = await api.getMyClub(code)
    if (res.ok) {
      setName(res.data.name)
      setDescription(res.data.description)
      setDiscount(res.data.discount)
      setVoucher(res.data.voucher)
    } else {
      toast.error(res)
    }
  }

  const isValid = () => {
    return name && description && discount + voucher === 100
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  useEffect(() => {
    setVoucher(100 - discount)
  }, [discount]) // eslint-disable-line

  return (
    <>
      <IonListHeader>
        <IonLabel>{'ویرایش باشگاه'}</IonLabel>
      </IonListHeader>
      <IonItem>
        <IonLabel position='stacked'>{'نام باشگاه'}</IonLabel>
        <IonInput
          value={name}
          onIonChange={(e) => setName(e.detail.value)}
          required={true}
          readonly={true}
          color='success'
        />
      </IonItem>
      <IonItem>
        <IonLabel position='stacked'>{'توضیحات باشگاه'}</IonLabel>
        <IonTextarea value={description} onIonChange={(e) => setDescription(e.detail.value)} required={true} />
      </IonItem>
      <IonLabel position='stacked' className='ion-padding-start'>
        {'نسبت بین اسپوند و تخفیف'}
      </IonLabel>
      <IonItem>
        <IonRange
          pin={true}
          min={0}
          max={100}
          value={discount}
          color='secondary'
          onIonChange={(e: any) => setDiscount(e.detail.value)}>
          <IonLabel slot='start'>
            <span className='font-50'>{'اسپوند'}</span> {voucher}
          </IonLabel>
          <IonLabel slot='end'>
            <span className='font-50'>{'تخفیف'}</span> {discount}
          </IonLabel>
        </IonRange>
      </IonItem>
      <br />
      <IonButton disabled={!isValid() || inprogress} onClick={update}>
        {inprogress && <IonSpinner />}
        {'بروزرسانی تغییرات'}
      </IonButton>
    </>
  )
}

export default MyClubEdit
