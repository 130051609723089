const EventEmitter = {
  events: {} as any,
  dispatch: function (event: string, data?: any) {
    if (!this.events[event]) return
    this.events[event].forEach((callback: (data: any) => void) => callback(data))
  },
  subscribe: function (event: string, callback: () => void) {
    if (!this.events[event]) this.events[event] = []
    this.events[event].push(callback)
  },
}

export default EventEmitter
