import React, { useState, lazy, useContext } from 'react'
import { IonPage, IonContent, IonFab, isPlatform } from '@ionic/react'
import { IonIcon, IonGrid, IonRow, IonCol, IonText, IonButton, useIonViewWillLeave } from '@ionic/react'
import { qrCode, scan } from 'ionicons/icons'
import { connect, ProfileContext } from '../../ayria'
import './QrTabs.scss'
import { isDesktop } from '../../utility'
const QrCodeReader = lazy(() => import('../../components/elements/QrCodeReader/QrCodeReader'))
const QrReadTab = lazy(() => import('./QrReadTab'))
const Header = lazy(() => import('../shared/Header'))

declare var window: any

interface StateProps {
  isAuthenticated: boolean
}
interface QrTabsProps extends StateProps {}

const QrTabs: React.FC<QrTabsProps> = ({ isAuthenticated }) => {
  const [tab, setTab] = useState('read')
  const [profileContext] = useContext<any>(ProfileContext)

  useIonViewWillLeave(() => {
    setTab('read')
  })

  const handleQrCodeScan = (data: string) => {
    if (data && data.includes('?')) {
      window.location = `${data}&m=${profileContext?.profile?.mobile}&n=${profileContext?.profile?.firstName} ${profileContext?.profile?.lastName}`
    } else {
      window.location = `${data}?m=${profileContext?.profile?.mobile}&n=${profileContext?.profile?.firstName} ${profileContext?.profile?.lastName}`
    }
  }

  return (
    <IonPage id='qr-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header showBackButton={false} />
        <IonGrid>
          <IonRow>
            <IonCol>
              {tab === 'scan' && <QrCodeReader onScan={handleQrCodeScan} />}
              {tab === 'read' && <QrReadTab />}
            </IonCol>
          </IonRow>
          {tab === 'read' && (
            <IonRow>
              <IonCol>
                <IonText className='ion-margin'>
                  {
                    'شما میتوانید با این QR code از دیگران پول دریافت کنید، کافیست شخصی که میخواهد به شما پول پرداخت کند این تصویر را اسکن نماید.'
                  }
                </IonText>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonFab
          slot='fixed'
          horizontal='center'
          vertical='bottom'
          style={{ left: isPlatform('iphone') ? '43vw' : '40vw' }}>
          {tab === 'read' && (
            <div className='ion-text-center' onClick={() => setTab('scan')}>
              <div className='ion-button-icon-top'>
                <IonButton fill='outline' color='dark' size='large'>
                  <IonIcon icon={scan} slot='start' />
                  <IonText>{'اسکن QR Code'}</IonText>
                </IonButton>
              </div>
            </div>
          )}
          {tab === 'scan' && (
            <div className='ion-text-center' onClick={() => setTab('read')}>
              <div className='ion-button-icon-top'>
                <IonButton fill='outline' color='dark' size='large'>
                  <IonIcon icon={qrCode} slot='start' />
                  <IonText>{'QR Code  من'}</IonText>
                </IonButton>
              </div>
            </div>
          )}
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedIn,
  }),
  component: QrTabs,
})
