import React, { useState, useEffect } from 'react'
import { trash } from 'ionicons/icons'
import { IonItem, IonLabel, IonItemDivider, IonIcon, IonSpinner, IonList, IonBadge } from '@ionic/react'
import { IonText } from '@ionic/react'
import { ApiService, useToast, SingleUploader, CDN_BASE_URL } from '../../ayria'

interface OwnProps {
  code: string
}
interface MyClubLogoProps extends OwnProps {}

const MyClubLogo: React.FC<MyClubLogoProps> = ({ code }) => {
  const api = new ApiService()
  const toast = useToast()
  const [item, setItem] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const updateLogo = async (meta: any, content: any, identifier: string) => {
    setInprogress(true)
    const res: any = await api.userClubUpdateLogo({
      code,
      content,
      contentType: meta.type,
      small: identifier !== 'LOGO' ? true : false,
    })
    if (res.ok) {
      setItem(res.data)
      toast.success('لوگو با موفقیت آپلود شد')
      await api.get(`/public/club/manifest/${code}`) // refresh club manifest
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const getItem = async () => {
    const res: any = await api.getMyClub(code)
    if (res.ok) {
      setItem(res.data)
    } else {
      toast.error(res)
    }
  }

  const uploadImage = (meta: any, file: File, identifier: string) => {
    if (meta.size > 1050000) {
      toast.error(`حجم فایل ${meta.name} باید کمتر از ۱ مگابایت باشد`)
      return
    }
    const reader = new FileReader()
    const fileByteArray: any = []
    reader.readAsArrayBuffer(file)
    reader.onloadend = function (evt: any) {
      if (evt.target.readyState === FileReader.DONE) {
        let arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer)
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])
        }
        updateLogo(meta, fileByteArray, identifier)
      }
    }
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  return (
    <div id='my-club-logo'>
      <IonList>
        <IonItemDivider>
          <IonLabel>
            {'بارگذاری لوگو'}
            {inprogress && <IonSpinner slot='end' color='secondary' />}
          </IonLabel>
        </IonItemDivider>
        <IonItem>
          <IonLabel style={{ fontSize: '62%' }}>
            {'جهت بارگذاری لوگو صرفا از فرمت '}
            <IonText color='danger' style={{ fontSize: '150%' }}>
              PNG
            </IonText>
            {' استفاده نمایید.'}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            {'اصلی'}
            <p slot='start' style={{ fontSize: '60%' }}>
              {'512x512'}
              <br />
              {'transparent'}
            </p>
          </IonLabel>
          {!item?.logoPath && (
            <div style={{ width: '50%' }}>
              <SingleUploader onFileUploaded={uploadImage} accept={'image/png'} identifier='LOGO' />
            </div>
          )}
          {item?.logoPath && (
            <div className='main-logo'>
              <img src={`${CDN_BASE_URL}/${item.logoPath}`} alt='logo' crossOrigin='anonymous' />
              <IonBadge onClick={() => setItem({ ...item, logoPath: undefined })} slot='end' color='danger'>
                <IonIcon icon={trash} />
              </IonBadge>
            </div>
          )}
        </IonItem>
        <IonItem>
          <IonLabel>{'کوچک'}</IonLabel>
          {!item?.smallLogoPath && (
            <div style={{ width: '50%' }}>
              <SingleUploader onFileUploaded={uploadImage} accept={'image/png'} identifier='SMALL_LOGO' />
            </div>
          )}
          {item?.smallLogoPath && (
            <div>
              <img src={`${CDN_BASE_URL}/${item.smallLogoPath}`} alt='logo' height='45px' crossOrigin='anonymous' />
              <IonBadge onClick={() => setItem({ ...item, smallLogoPath: undefined })} slot='end' color='danger'>
                <IonIcon icon={trash} />
              </IonBadge>
            </div>
          )}
        </IonItem>
      </IonList>
    </div>
  )
}

export default MyClubLogo
