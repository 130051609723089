import React, { useState, useEffect, lazy } from 'react'
import { IonList, IonItem, IonItemDivider, IonButton, IonIcon, IonItemSliding } from '@ionic/react'
import { IonText, IonItemOptions, IonItemOption, IonSpinner, IonAvatar, IonInput } from '@ionic/react'
import { isPlatform, IonLabel } from '@ionic/react'
import { add, trash, remove, qrCode } from 'ionicons/icons'
import { ApiService, useDebounce, useToast, Modal, AcceptorSuggest, AcceptorLogo } from '../../ayria'
import { AyriaPagination, ItemCount } from '../../ayria'
import ClubAcceptorTableQrCodeList from './ClubAcceptorTableQrCodeList'
import './MyClub.scss'
import { isDesktop, setWithTimeout } from '../../utility'
const ClubVirtualShopQRCode = lazy(() => import('../../components/units/ClubVirtualShopQRCode'))

interface OwnProps {
  code: string
  itemsPerPage?: number
}
interface MyClubAcceptorsProps extends OwnProps {}

const MyClubAcceptors: React.FC<MyClubAcceptorsProps> = ({ code, itemsPerPage = 5 }) => {
  const api = new ApiService()
  const toast = useToast()
  const [item, setItem] = useState<any>()
  const [items, setItems] = useState([])
  const [discount, setDiscount] = useState<any>(0)
  const debouncedDiscount = useDebounce(discount, 2000)
  const [inprogress, setInprogress] = useState(false)
  const [delinprogress, setDelinprogress] = useState(false)
  const [addinprogress, setAddinprogress] = useState(false)
  const [updinprogress, setUpdinprogress] = useState(false)
  const [showAddAcceptorModal, setShowAddAcceptorModal] = useState(false)
  const [showQrCodeModal, setShowQrCodeModal] = useState(false)
  const [showTableQrCodeModal, setShowTableQrCodeModal] = useState(false)
  const [pagination, setPagination] = useState({ itemsPerPage, sort: 'id', order: 'desc', activePage: 1 })
  const [total, setTotal] = useState(0)
  const [acceptorName, setAcceptorName] = useState()

  const getItems = async (page: number, size: number, sort: string) => {
    setInprogress(true)
    const res: any = await api.getMyClubAcceptors(
      code,
      `?page=${page}&size=${size}&sort=${pagination.sort},${pagination.order}${
        !!acceptorName ? `&acceptorName.contains=${acceptorName}` : ''
      }`
    )
    if (res.ok) {
      setWithTimeout(setItems, res.data)
      setTotal(res.headers['x-total-count'])
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const sortItems = async () => {
    await getItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  }

  const isActive = (item: any) => {
    return item.discountPercentage > 0 && item.discountPercentage > (item.discountMinimum || 0)
  }

  const updateItem = async () => {
    if (item?.clubCode && discount >= (item?.discountMinimum || 0)) {
      setUpdinprogress(true)
      const res: any = await api.updateClubAcceptorDiscount({
        clubCode: item.clubCode,
        acceptorCode: item.acceptorCode,
        discount,
      })
      if (res.ok) {
        toast.success(`درصد تخفیف فروشگاه به باشگاه به ${res.data.discountPercentage} درصد تغییر کرد`)
        setItem(undefined)
        await sortItems()
      } else {
        toast.error(res)
      }
      setUpdinprogress(false)
    }
  }

  const deleteItem = async (acceptorCode: string) => {
    setDelinprogress(true)
    const res: any = await api.userClubDeleteAcceptor(code, acceptorCode)
    if (res.ok) {
      toast.success(`فروشگاه با موفقیت حذف  شد`)
      await sortItems()
    } else {
      toast.error(res)
    }
    setDelinprogress(false)
  }

  const addItem = async (acceptorCode: string) => {
    if (!acceptorCode) {
      toast.error(`فروشگاه فعال نیست`)
      return
    }
    setAddinprogress(true)
    const res: any = await api.userClubAddAcceptor(code, acceptorCode)
    if (res.ok) {
      toast.success(
        res.status === 201 ? `فروشگاه با موفقیت به باشگاه اضافه شد` : `فروشگاه قبلا به باشگاه اضافه شده بود`
      )
      await sortItems()
    } else {
      toast.error(res)
    }
    setAddinprogress(false)
  }

  const increment = (item: any) => {
    setItem(item)
    if (!discount) {
      setDiscount((item.discountPercentage || item.discountMinimum) + 1)
    } else {
      setDiscount(+discount + 1)
    }
  }
  const decrement = (item: any) => {
    setItem(item)
    if (discount <= (item.discountMinimum || 0)) {
      setDiscount(item.discountMinimum || 0)
    } else {
      setDiscount(+discount - 1)
    }
  }
  const handlePagination = (currentPage) => {
    setPagination({ ...pagination, activePage: currentPage })
  }

  useEffect(() => {
    updateItem()
  }, [debouncedDiscount]) // eslint-disable-line

  useEffect(() => {
    sortItems()
  }, [pagination.activePage, pagination.order, pagination.sort, acceptorName]) // eslint-disable-line

  return (
    <div id='my-club-acceptors'>
      <IonList>
        <IonItemDivider>
          {inprogress && <IonSpinner />}
          {'فروشگاهی که میتوانید به باشگاه خود اضافه کنید'}{' '}
          <IonText className='ion-padding-start' color='success'>
            {total}
          </IonText>
          <IonButton slot='end' onClick={() => setShowAddAcceptorModal(true)}>
            {!addinprogress && <IonIcon slot='start' icon={add} />}
            {addinprogress && <IonSpinner />}
            اضافه کردن فروشگاه جدید
          </IonButton>
        </IonItemDivider>
        <IonItem>
          <IonInput
            value={acceptorName}
            onIonChange={(e) => setAcceptorName(e.detail.value)}
            debounce={1000}
            placeholder={'جستجو با نام فروشگاه'}
          />
        </IonItem>
        {!inprogress &&
          items.map((ca: any, index: number) => (
            <IonItemSliding key={index}>
              <IonItem>
                <IonAvatar slot='start'>
                  <AcceptorLogo referralCode={ca.acceptorCode} />
                </IonAvatar>
                <IonText
                  className='ion-text-wrap'
                  style={{ maxWidth: isPlatform('ios') ? '140px' : '440px' }}
                  slot='start'>
                  {ca.acceptorName}
                </IonText>
                {ca.owner && !isActive(ca) && <IonText color='danger'>{ca.discountPercentage}</IonText>}
                {ca.owner && isActive(ca) && <IonText color='success'>{ca.discountPercentage}</IonText>}
                {!ca.owner && <IonText>{ca.discountPercentage}</IonText>}
                {ca.owner && (
                  <>
                    <span className='box ion-padding-start'>
                      <IonIcon
                        icon={add}
                        disabled={updinprogress}
                        onClick={() => increment(ca)}
                        style={{ fontSize: '24px' }}
                      />
                      {updinprogress && <IonSpinner />}
                      <span>{discount}</span>
                      <IonIcon
                        icon={remove}
                        disabled={updinprogress}
                        onClick={() => decrement(ca)}
                        style={{ fontSize: '24px' }}
                      />
                    </span>
                    {ca.department === 'RESTAURANT' && (
                      <span slot='end' style={{ marginRight: '5px' }}>
                        <IonIcon
                          src={'/assets/images/svg/table-sharp.svg'}
                          size='large'
                          color='primary'
                          className='pointer'
                          style={{ paddingLeft: '10px' }}
                          onClick={() => {
                            setItem(ca)
                            setShowTableQrCodeModal(true)
                          }}></IonIcon>
                        <IonIcon
                          icon={qrCode}
                          className='pointer'
                          size='large'
                          color='primary'
                          onClick={() => {
                            setItem(ca)
                            setShowQrCodeModal(true)
                          }}></IonIcon>
                      </span>
                    )}
                  </>
                )}
              </IonItem>
              <IonItemOptions slot='end' style={{ width: '40px' }}>
                <IonItemOption color='danger' onClick={() => deleteItem(ca.acceptorCode)}>
                  {delinprogress && <IonSpinner />}
                  <IonIcon icon={trash} />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
      </IonList>
      <div className='row text-center ion-padding'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={total} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col' align='center'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={isDesktop() ? 5 : 3}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={total}
          />
        </div>
      </div>
      <Modal
        showModal={showAddAcceptorModal}
        toggle={setShowAddAcceptorModal}
        onClosed={() => setShowAddAcceptorModal(false)}
        title={'اضافه کردن فروشگاه'}
        content={
          <AcceptorSuggest
            prop='code'
            onSelect={(ac: any) => {
              setShowAddAcceptorModal(false)
              addItem(ac)
            }}
            onChange={() => {}}
          />
        }
      />
      <Modal
        showModal={showQrCodeModal}
        toggle={setShowQrCodeModal}
        onClosed={() => setShowQrCodeModal(false)}
        title={'فروشگاه در باشگاه QrCode'}
        content={
          <div className='ion-text-center'>
            <IonLabel className=' ion-text-center ion-text-justify'>
              <p className='ion-margin ion-padding'>
                با اسکن این QR CODE افراد می توانند لیست کالا و خدمات شما را مشاهده نمایند . چنانچه سرویس فروش آنلاین
                شما فعال باشد از همینجا امکان فروش کالا و خدمات را نیز خواهید داشت.
              </p>
            </IonLabel>
            <ClubVirtualShopQRCode clubCode={code} acceptorCode={item?.acceptorCode} showLink={true} />
          </div>
        }
      />
      <Modal
        showModal={showTableQrCodeModal}
        toggle={setShowTableQrCodeModal}
        onClosed={() => setShowTableQrCodeModal(false)}
        title={'میز های فروشگاه در باشگاه QrCode'}
        content={
          <>
            <ClubAcceptorTableQrCodeList clubCode={code} acceptorCode={item?.acceptorCode} />
          </>
        }
      />
    </div>
  )
}

export default MyClubAcceptors
