import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonCard, IonCardHeader } from '@ionic/react'
import { IonCardTitle, IonCardSubtitle, IonCardContent, IonText } from '@ionic/react'
import { WalletIdentity, Header } from '../../ayria'

interface WalletIdentityTabProps {}

const WalletIdentityTab: React.FC<WalletIdentityTabProps> = () => {
  const [walletIdentity, setWalletIdentity] = useState<number>()

  useEffect(() => {}, [walletIdentity])

  return (
    <IonPage id='wallet-identity-tab-page'>
      <IonContent>
        <Header />
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>{'شناسه پرداخت'}</IonCardSubtitle>
            <IonCardTitle>
              <WalletIdentity onWalletIdentityLoaded={(value: number) => setWalletIdentity(value)} />
            </IonCardTitle>
            <IonCardContent>
              <p className='pb-5'>
                {
                  'با داشتن این شناسه ضمن مراجعه به بانک یا استفاده از نرم افزارهای موبایل بانک و اینترنت بانک  میتوانید بدون محدودیت موجودی کیف پول خود را افزایش دهید.'
                }
              </p>
              <p style={{ width: '300px' }}>
                {'شماره حساب: '}
                <strong style={{ fontSize: '1.2em' }}>0203450815004</strong>
                <img
                  alt='ba'
                  className='position-absolute'
                  src='/assets/images/banks/ba-300-c.png'
                  style={{ top: '-50px', left: 0 }}
                  width='60px'
                />
              </p>
              <p>
                {'نام حساب: '}
                <strong>{'شرکت زندگی سازان هوشمند آیریا'}</strong>
              </p>
              <p>
                {'شماره شبا: '}
                <strong style={{ fontSize: '1.1em' }}>IR200620000000203450815004</strong>
              </p>
              <p>
                {'شناسه پرداخت: '}
                <strong style={{ fontSize: '1.1em' }}>{walletIdentity}</strong> (
                <IonText color='danger'>{'وارد کردن این شماره در زمان واریز وجه ضروری است'}</IonText>)
              </p>
              <p>{'بانک: آینده'}</p>
            </IonCardContent>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </IonPage>
  )
}

export default WalletIdentityTab
