import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import WalletEvents from '../wallet-events/wallet-events'
import WalletRequests from '../wallet-requests/wallet-requests'
import WalletResponses from '../wallet-responses/wallet-responses'
import { AcceptorContext } from '../../../structures/contexts/AcceptorContext'
import { isDesktop } from '../../../../utility'

const WalletReport = ({ isAcceptor = false }) => {
  const { t } = useTranslation()
  const [acceptorContext] = useContext(AcceptorContext)
  const [report, setReport] = useState('events')

  return (
    <>
      {isDesktop() ? (
        <>
          <div
            className='d-flex flex-row align-items-center justify-content-start report-tabs flex-container mr-3 mb-2'
            style={{ justifyContent: 'center' }}>
            <div
              className={'report-tab ml-2 ' + (report === 'events' ? 'active' : '')}
              onClick={(e) => setReport('events')}>
              {t('walletReport.events')}
            </div>
            <div
              className={'report-tab ml-2 ' + (report === 'requests' ? 'active' : '')}
              onClick={(e) => setReport('requests')}>
              {t('walletReport.requests')}
            </div>
            <div
              className={'report-tab ml-2 ' + (report === 'responses' ? 'active' : '')}
              onClick={(e) => setReport('responses')}>
              {t('walletReport.responses')}
            </div>
          </div>
          {report === 'events' && <WalletEvents isAcceptor={isAcceptor} />}
          {report === 'requests' && (
            <WalletRequests acceptorCode={isAcceptor ? acceptorContext.current.code : undefined} />
          )}
          {report === 'responses' && <WalletResponses isAcceptor={isAcceptor} />}
        </>
      ) : (
        <div className='p-2 pt-0'>
          <div className='text-center flex-container m-2' style={{ justifyContent: 'center' }}>
            <div className={'dashboard-tab'}>
              <span className={report === 'events' ? 'text-info' : 'pointer'} onClick={(e) => setReport('events')}>
                {t('walletReport.events')}
              </span>
            </div>
            <div className={'dashboard-tab'}>
              <span className={report === 'requests' ? 'text-info' : 'pointer'} onClick={(e) => setReport('requests')}>
                {t('walletReport.requests')}
              </span>
            </div>
            <div className={'dashboard-tab'}>
              <span
                className={report === 'responses' ? 'text-info' : 'pointer'}
                onClick={(e) => setReport('responses')}>
                {t('walletReport.responses')}
              </span>
            </div>
          </div>

          {report === 'events' && <WalletEvents isAcceptor={isAcceptor} type='acceptor' />}
          {report === 'requests' && (
            <WalletRequests acceptorCode={isAcceptor ? acceptorContext.current.code : undefined} />
          )}
          {report === 'responses' && <WalletResponses isAcceptor={isAcceptor} />}
        </div>
      )}
    </>
  )
}

export default WalletReport
