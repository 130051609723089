import React, { useEffect, useState, useContext } from 'react'
import { IonToggle, IonButton } from '@ionic/react'
import api from '../../../api/Api'
import PurchaseAcceptorReceipt from '../../elements/PurchaseAcceptorReceipt/PurchaseAcceptorReceipt'
import PurchaseAcceptorRefund from '../../elements/PurchaseAcceptorRefund/PurchaseAcceptorRefund'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../utility/pagination'
import ItemCount from '../../../utility/item-count'
import useDebounce from '../../../utility/use-debounce'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import NumberInput from '../../units/NumberInput/NumberInput'
import { CustomModal } from '../../../ayria'

const Sell = ({ refunded, isAcceptor }) => {
  const [purchases, setPurchases] = useState()
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [purchaseId, setPurchaseId] = useState()
  const [pagination, setPagination] = useState({
    itemsPerPage: ITEMS_PER_PAGE,
    sort: 'createdDate',
    order: 'desc',
    activePage: 1,
  })
  const [totalItems, setTotalItems] = useState(0)
  const [searchId, setSearchId] = useState()
  const [filterRefunded, setFilterRefunded] = useState(refunded)
  const debouncedSearchId = useDebounce(searchId, 500)
  const [acceptorContext] = useContext(AcceptorContext)

  const getPurchases = async (page, size, sort) => {
    try {
      const res = await api.get(
        `/acceptor/purchases/${acceptorContext.current.code}/list?page=${page}&size=${size}&sort=${sort}${
          debouncedSearchId ? `&id.equals=${debouncedSearchId}` : ''
        }${filterRefunded ? '&refunded.equals=true' : ''}`
      )
      if (res.ok) {
        setPurchases(res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
  }

  const sortPurchases = () =>
    getPurchases(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)

  useEffect(() => {
    sortPurchases()
    // eslint-disable-next-line
  }, [
    pagination.activePage,
    pagination.order,
    pagination.sort,
    debouncedSearchId,
    acceptorContext.currentIndex,
    filterRefunded,
  ])

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <>
      <div className='reportHolder-style'>
        <div>
          <NumberInput
            onValueChange={(value) => setSearchId(value)}
            placeholder={'شماره پیگیری'}
            className={'form-control mb-2 text-center'}
          />
        </div>
        <div className='d-flex flex-row align-items-center'>
          <span className='pl-2'>عودت داده شده ها</span>
          <IonToggle onIonChange={(e) => setFilterRefunded(e.detail.checked)} />
        </div>

        {purchases && (
          <>
            <div className='col-12' style={{ overflowX: 'auto' }}>
              <table className='customTableTwo'>
                <thead>
                  <tr>
                    <th>نام</th>
                    <th>تاریخ </th>
                    <th>مبلغ</th>
                    <th>عملیات</th>
                  </tr>
                </thead>
                <tbody>
                  {purchases.map((purchase) => {
                    return (
                      <tr
                        key={purchase.id}
                        onClick={(e) => {
                          setPurchaseId(purchase.id)
                          setShowReceiptModal(true)
                        }}>
                        <td>{purchase.customerName}</td>
                        <td>{purchase.purchaseDatePersian}</td>
                        <td>{purchase.unitedAmount.toLocaleString(navigator.language)}</td>
                        <td>
                          {!purchase.refunded && (
                            <IonButton
                              size='small'
                              fill='outline'
                              color='danger'
                              onClick={(e) => {
                                setPurchaseId(purchase.id)
                                setShowRefundModal(true)
                              }}>
                              عودت
                            </IonButton>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            {/*<div className='row text-center'>*/}

            {/*  <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />*/}

            {/*  <AyriaPagination*/}
            {/*    activePage={pagination.activePage}*/}
            {/*    onSelect={handlePagination}*/}
            {/*    maxButtons={5}*/}
            {/*    itemsPerPage={pagination.itemsPerPage}*/}
            {/*    totalItems={totalItems}*/}
            {/*  />*/}
            {/*  */}
            {/*</div>*/}

            <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
              <div className='row text-center'>
                <div className='col'>
                  <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
                </div>
              </div>

              <AyriaPagination
                activePage={pagination.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </>
        )}

        {/*{purchases && (*/}
        {/*  <div>*/}
        {/*    {purchases.map((purchase) => {*/}
        {/*      return (*/}
        {/*        <div*/}
        {/*          key={purchase.id}*/}
        {/*          className={'flex-container border-bottom' + (purchase.refunded ? ' alert-danger' : '')}>*/}
        {/*          <span*/}
        {/*            onClick={(e) => {*/}
        {/*              setPurchaseId(purchase.id)*/}
        {/*              setShowReceiptModal(true)*/}
        {/*            }}*/}
        {/*            className='pointer'>*/}
        {/*            <p className='ellipsis m-0' style={{ width: isMobile() ? '50vw' : '500px' }}>*/}
        {/*              {purchase.customerName} / {purchase.unitedAmount.toLocaleString(navigator.language)}*/}
        {/*              <br />*/}
        {/*              {purchase.purchaseDatePersian}*/}
        {/*            </p>*/}
        {/*          </span>*/}
        {/*          <span>*/}
        {/*            {!purchase.refunded && (*/}
        {/*              <IonButton*/}
        {/*                size='small'*/}
        {/*                fill='outline'*/}
        {/*                color='danger'*/}
        {/*                onClick={(e) => {*/}
        {/*                  setPurchaseId(purchase.id)*/}
        {/*                  setShowRefundModal(true)*/}
        {/*                }}>*/}
        {/*                عودت*/}
        {/*              </IonButton>*/}
        {/*            )}*/}
        {/*            <span className='ion-padding'>*/}
        {/*              {purchase.purchasePayType === 'WALLET' && <IonIcon icon={wallet} />}*/}
        {/*              {purchase.purchasePayType === 'CASH' && <IonIcon icon={cash} />}*/}
        {/*              {purchase.purchasePayType === 'POS' && <IonIcon icon={card} />}*/}
        {/*            </span>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      )*/}
        {/*    })}*/}
        {/*    <div className='row text-center'>*/}
        {/*      <div className='col'>*/}
        {/*        <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className='row text-center'>*/}
        {/*      <div className='col' align='center'>*/}
        {/*        <AyriaPagination*/}
        {/*          activePage={pagination.activePage}*/}
        {/*          onSelect={handlePagination}*/}
        {/*          maxButtons={5}*/}
        {/*          itemsPerPage={pagination.itemsPerPage}*/}
        {/*          totalItems={totalItems}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <CustomModal
          showModal={showReceiptModal}
          toggle={setShowReceiptModal}
          title='رسید فروش'
          content={<PurchaseAcceptorReceipt purchaseId={purchaseId} acceptorCode={acceptorContext.current.code} />}
        />
        <CustomModal
          showModal={showRefundModal}
          toggle={setShowRefundModal}
          title={'عودت فروش'}
          content={
            <PurchaseAcceptorRefund
              purchaseId={purchaseId}
              acceptorCode={acceptorContext.current.code}
              isCashier={false}
              onCancel={(e) => setShowRefundModal(false)}
              onSuccess={(e) => {
                setShowRefundModal(false)
                sortPurchases()
              }}
            />
          }
        />
      </div>
    </>
  )
}

export default Sell
