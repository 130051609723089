import React from 'react'
import { IonText } from '@ionic/react'
import { IS_AYRIA, IS_PERSEPOLIS, IS_KARAFARIN } from '../../ayria'

const LandingPlansInfo = () => {
  return (
    <>
      <div className='landing_plans-main-text-container'>
        {IS_AYRIA && (
          <p>
            {
              'اگر شما الان دارید این پیام رو میخونید پس شما هم مشتری خاص هستین. قراره که کلی اتفاق های هیجان انگیز بیفته و کلی حق انتخاب داشته باشین...!'
            }
          </p>
        )}
        {IS_PERSEPOLIS && (
          <p>
            {
              'اگر داری این پیام رو می‌خونی یعنی عاشقی پس برای نشون دادن عشقت به جمع هوادارها بپیوند و منتظر اتفاق های جذاب باش.'
            }
          </p>
        )}
        {IS_KARAFARIN && (
          <IonText color='secondary' className='ion-padding'>
            {'برای بهترین تجربه زندگی با ما همراه شوید.'}
          </IonText>
        )}
      </div>
      <div className='landing_plans-banner' />
    </>
  )
}

export default LandingPlansInfo
