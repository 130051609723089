import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setWalletBalance } from '../../../data/user/user.actions'
import { connect } from '../../../data/connect'
import api from '../../../api/Api'

interface OwnProps {
  showImg?: boolean
}

interface StateProps {
  walletBalance: number
  isAuthenticated: boolean
}

interface DispatchProps {
  setWalletBalance: typeof setWalletBalance
}

interface WalletBalanceProps extends OwnProps, StateProps, DispatchProps {}

const WalletBalance: React.FC<WalletBalanceProps> = ({
  setWalletBalance,
  walletBalance,
  isAuthenticated,
  showImg = false,
}) => {
  const history = useHistory()

  const getBalance = async () => {
    const res: any = await api.post('user/wallet-snapshots/current', {})
    if (res.ok) {
      await setWalletBalance(res.data.balance)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getBalance()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isAuthenticated && (
        <span onClick={() => history.push('/wallet-report')} className='pointer'>
          <span style={{ fontSize: 22 }} className={'pointer' + (showImg ? ' ion-padding-end' : '')}>
            {(walletBalance || 0).toLocaleString(navigator.language)}
          </span>
          {showImg && <img src='/assets/images/wallet.png' alt='wallet' width='40px' />}
        </span>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setWalletBalance,
  },
  mapStateToProps: (state) => ({
    walletBalance: state.user.walletBalance,
    isAuthenticated: state.user.isLoggedIn,
  }),
  component: WalletBalance,
})
