import React from 'react'
import CurrencyInput from '../CurrencyInput/CurrencyInput'

const NumberInput = ({ onValueChange, componentDidMount = () => {}, ...inputProps }) => {
  return (
    <CurrencyInput
      maskOptions={{ includeThousandsSeparator: false, allowLeadingZeroes: true, allowNegative: false }}
      placeholder={''}
      onValueChange={onValueChange}
      componentDidMount={componentDidMount}
      {...inputProps}
    />
  )
}

export default NumberInput
