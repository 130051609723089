import React, { useRef } from 'react'
import { IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { setHasSeenLanding, setMenuEnabled } from '../../data/user/user.actions'
import { connect } from '../../data/connect'
import LandingHeader from './LandingHeader'
import LandingMain from './LandingMain'
import LandingTeaser from './LandingTeaser'
import LandingPlansInfo from './LandingPlansInfo'
import LandingJoin from './LandingJoin'
import CopyRight from '../CopyRight'
import './Landing.scss'

interface OwnProps {}

interface StateProps {
  isLoggedIn: boolean
}

interface DispatchProps {
  setHasSeenLanding: typeof setHasSeenLanding
  setMenuEnabled: typeof setMenuEnabled
}

interface LandingProps extends OwnProps, DispatchProps, StateProps {}

const Landing: React.FC<LandingProps> = ({ setHasSeenLanding, setMenuEnabled, isLoggedIn }) => {
  const content = useRef() as React.MutableRefObject<HTMLIonContentElement>

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
    // if (isLoggedIn) {  // TODO FIXME inconsistence result!
    //  history.push('/')
    // }
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  return (
    <IonPage id='landing' className='landing'>
      <IonContent fullscreen ref={content}>
        <LandingHeader content={content} />
        <section id='main-section'>
          <LandingMain />
        </section>
        <section id='signup-teaser'>
          <LandingTeaser />
        </section>
        <section id='plans-info'>
          <LandingPlansInfo />
        </section>
        <section id='join'>
          <LandingJoin />
        </section>
        <CopyRight />
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoggedIn: state.user.isLoggedIn,
  }),
  mapDispatchToProps: {
    setHasSeenLanding,
    setMenuEnabled,
  },
  component: Landing,
})
