import React, { FC, useState } from 'react'
import { IonButton, IonFooter, IonGrid, IonInput, IonItem, IonLabel } from '@ionic/react'
import { connect } from '../../data'
import { ApiService } from '../../ayria'
import { useToast } from '../../utility'

interface OwnProps {
  onSuccess: Function
}
interface StateProps {
  myClubCode: string
}
interface MyClugGroupEditProps extends OwnProps, StateProps {}

const MyClugGroupEdit: FC<MyClugGroupEditProps> = ({ myClubCode, onSuccess }) => {
  const api = new ApiService()
  const toast = useToast()
  const [name, setName] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const createGroup = async () => {
    setInprogress(true)
    const res: any = await api.post('/club/club-group/create', { name, clubCode: myClubCode })
    if (res.ok) {
      if (res.status === 201) {
        toast.success('گروه با موفقیت ایجاد شد')
      }
      onSuccess(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  return (
    <IonGrid>
      <IonItem>
        <IonLabel>{'نام'}</IonLabel>
        <IonInput onIonChange={(e) => setName(e.detail.value)} />
      </IonItem>
      <IonFooter className='modal-footer'>
        <IonButton onClick={createGroup} disabled={!name || inprogress}>
          {'ایجاد'}
        </IonButton>
      </IonFooter>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    myClubCode: state.shop.myClubCode,
  }),
  component: MyClugGroupEdit,
})
