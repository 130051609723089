import React, { useState, useEffect, useContext, FC } from 'react'
import { WalletContext } from '../../structures/contexts/WalletContext'
import { ScoreContext } from '../../structures/contexts/ScoreContext'
import Balance from '../../units/Balance'
import { ApiService } from '../../../ayria'

interface WalletBalanceProps {
  acceptorCode?: string
  showScore?: boolean
  showCredit?: boolean
  showBorder?: boolean
  showBalanceText?: boolean
  balanceText?: string
}

const WalletBalance: FC<WalletBalanceProps> = ({
  acceptorCode = '',
  showScore = true,
  showCredit = true,
  showBorder = true,
  showBalanceText = true,
  balanceText = 'موجودی:',
}) => {
  const api = new ApiService()
  const [walletContext, setWalletContext] = useContext<any>(WalletContext)
  const [walletBalance, setWalletBalance] = useState(0)
  const [scoreContext, setScoreContext] = useContext<any>(ScoreContext)

  const getBalance = async () => {
    const res: any = await api.post('/user/wallet-snapshots/current', { acceptorCode: acceptorCode })
    setWalletBalance(res?.data?.balance)
    if (!acceptorCode) {
      setWalletContext((state: any) => ({ ...state, balance: res.data.balance }))
    }
  }
  const getScoreBalance = async () => {
    const scoreRes: any = await api.get(`/customer/scores/total/${acceptorCode ? acceptorCode : '0'}`)
    if (!acceptorCode) {
      setScoreContext((state: any) => ({ ...state, balance: scoreRes.data }))
    } else {
      setScoreContext((state: any) => ({ ...state, [acceptorCode]: scoreRes.data }))
    }
  }
  const getCreditBalance = async () => {
    const res: any = await api.get(`/customer/purchases/credit`)
    setWalletContext((state: any) => ({ ...state, credit: res.data }))
  }

  useEffect(() => {
    getBalance()
    getScoreBalance()
    if (!walletContext.credit) {
      getCreditBalance()
    }
  }, []) // eslint-disable-line

  return (
    <div className={`p-2 mb-2 ${showBorder ? ' border-no-top' : ''}`}>
      {showBalanceText && (
        <div className={'row'}>
          <span
            className={'col text-bold'}
            onClick={() => {
              getBalance()
              getScoreBalance()
            }}>
            {balanceText}
          </span>
        </div>
      )}
      <div className='row text-center'>
        {!acceptorCode && showCredit && (
          <div className={'col text-center'}>
            <span>
              <Balance amount={walletContext.credit} />
            </span>
            <br />
            <img alt='' src='/assets/images/credit.png' width='40px' />
            <br />
            {'اعتبار'}
          </div>
        )}
        {showScore && (
          <div className={'col'}>
            <span>
              <Balance amount={scoreContext.balance} />
            </span>
            <br />
            <img alt='' src='/assets/images/coin.png' width='40px' />
            <br />
            {'امتیاز'}
          </div>
        )}
        <div className={'col'}>
          <span>
            <Balance amount={walletBalance} />
          </span>
          <br />
          <img alt='' src='/assets/images/wallet.png' width='40px' />
          <br />
          {'کیف پول'}
        </div>
      </div>
    </div>
  )
}

export default WalletBalance
