import React from 'react'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MerchantProfileMap from './merchantProfileMap'

interface MerchantProfileInfoProps {
  merchant: any
}

const MerchantProfileInfo: React.FC<MerchantProfileInfoProps> = ({ merchant }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { address, tradingHours, facilities, contacts } = merchant
  const phone = contacts.find((element: any) => element.contactType === 'TELEPHONE')
  const mobile = contacts.find((element: any) => element.contactType === 'MOBILE')
  const instagram = contacts.find((element: any) => element.contactType === 'INSTAGRAM')
  const website = contacts.find((element: any) => element.contactType === 'WEBSITE')

  return (
    <div className={'merchant-profile-info'}>
      <div>
        <section style={{ width: '100vw' }}>
          <IonButton expand='block' fill='outline' onClick={() => history.push(`/buy/${merchant.code}`)}>
            {`پرداخت به ${merchant.acceptorName}`}
          </IonButton>
        </section>
        <section>
          <h5>{t('merchantProfile.labels.address')}</h5>
          {merchant?.storeType === 'VIRTUAL' ? 'فروشگاه اینترنتی' : address}
        </section>

        {tradingHours && tradingHours.name && (
          <section>
            <h5>{t('merchantProfile.labels.openingHours')}</h5>
            {tradingHours.name}
          </section>
        )}
        {(phone || mobile) && (
          <section>
            <h5>{'تلفن تماس'}</h5>
            <a
              href={`tel:${
                phone
                  ? merchant?.phonePrefix && !phone?.value?.startsWith('0')
                    ? `0${merchant?.phonePrefix}${phone?.value}`
                    : phone?.value
                  : mobile
                  ? mobile?.value
                  : '-'
              }`}>
              {phone
                ? merchant?.phonePrefix && !phone?.value?.startsWith('0')
                  ? `0${merchant?.phonePrefix}${phone?.value}`
                  : phone?.value
                : mobile
                ? mobile?.value
                : '-'}
            </a>
          </section>
        )}
        {website && (
          <section>
            <h5>{'وب سایت'}</h5>
            <a
              href={website?.value?.startsWith('http') ? website?.value : `http://${website?.value}`}
              target='_blank'
              rel='noopener noreferrer'>
              {website?.value}
            </a>
          </section>
        )}
        {instagram && (
          <section>
            <h5>{'اینستاگرام'}</h5>
            <a
              href={`https://instagram.com/${instagram.value}`}
              target='_blank'
              rel='noopener noreferrer'
              className='dir-ltr'>
              <span className='uni-ltr'>{`${instagram.value}`}</span>
            </a>
          </section>
        )}
        {facilities && facilities.length > 0 && (
          <section>
            <h5>{t('merchantProfile.labels.facilities')}</h5>
            {facilities.map((element: any) => (
              <span key={element.name} className={'badge badge-info m-1'}>
                {element.name}
              </span>
            ))}
          </section>
        )}
      </div>
      <div className={'map-container'}>
        <MerchantProfileMap merchant={merchant} />
      </div>
    </div>
  )
}

export default MerchantProfileInfo
