import React, { FC, useState } from 'react'
import { IonItem, IonLabel, IonAvatar, IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { BillType, BillTypeImage, WalletBalance, ApiService, useToast } from '../../../ayria'

interface OwnProps {
  acceptorCode?: string
  amount?: number
  billId?: number
  payId?: number
  billType?: BillType
  billIdentifier?: string
  onSuccess: Function
}
interface BillPaymentWalletProps extends OwnProps {}

const BillPaymentWallet: FC<BillPaymentWalletProps> = ({
  acceptorCode,
  amount,
  billId,
  payId,
  billType,
  billIdentifier,
  onSuccess,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [cardInprogress, setCardInprogress] = useState(false)
  const [showAlternative, setShowAlternative] = useState(false)

  const payForReal = async () => {
    setInprogress(true)
    const res: any = await api.post(`/wallet/pay/bill`, {
      acceptorCode,
      billId,
      payId,
      amount,
    })
    if (res.ok) {
      toast.success('قبض با موفقیت پرداخت شد')
      onSuccess()
    } else {
      toast.error(res)
      setShowAlternative(true)
    }
    setInprogress(false)
  }

  const payWithBankCard = async () => {
    setCardInprogress(true)
    const res: any = await api.post(`/user/bill/pay/step1`, {
      billId,
      payId,
      description: `${t(`Bill ${billType}`)} (${billIdentifier})`,
    })
    if (res.ok) {
      if (res.status === 201) {
        toast.success('این قبض قبلا پرداخت شده است')
        return
      }
      if (res.data.ok) {
        window.location = res.data.paymentLink
      } else {
        toast.error(res.data.statusDescription)
      }
    } else {
      toast.error(res)
    }
    setCardInprogress(false)
  }

  return (
    <>
      <IonItem>
        <IonAvatar slot='start'>
          <BillTypeImage billType={billType} />
        </IonAvatar>
        <IonLabel>
          {t(`Bill ${billType}`)} ({billIdentifier})
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          {'شناسه قبض: '} <b>{billId}</b>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          {'شناسه پرداخت: '} <b>{payId}</b>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          {'مبلغ: '} <b>{amount?.toLocaleString(navigator.language)}</b> {'ریال'}
        </IonLabel>
      </IonItem>
      <WalletBalance acceptorCode={acceptorCode} showScore={false} showBorder={false} showBalanceText={false} />
      <div className='ion-text-center p-2 mt-2'>
        <button
          disabled={inprogress}
          className='btn btn-wallet'
          onClick={(e) => {
            payForReal()
          }}>
          <div>کیف پول</div>
        </button>
        {showAlternative && (
          <>
            <IonButton
              onClick={payWithBankCard}
              disabled={cardInprogress}
              className='ion-padding-start'
              style={{ marginTop: '-5px' }}>
              {'کارت بانکی'}
            </IonButton>
          </>
        )}
      </div>
    </>
  )
}

export default BillPaymentWallet
