import React, { useState } from 'react';

const NotificationContext = React.createContext([{}, () => {}]);

const NotificationProvider = (props) => {
  const [state, setState] = useState({green: false, red: false, badge: 0})
  return (
    <NotificationContext.Provider value={[state, setState]}>
      {props.children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider };
