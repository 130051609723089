import React, { useState } from 'react';

const WalletContext = React.createContext([{}, () => {}]);

const WalletProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <WalletContext.Provider value={[state, setState]}>
      {props.children}
    </WalletContext.Provider>
  );
}

export { WalletContext, WalletProvider };