export const IS_AYRIA = true
export const IS_PERSEPOLIS = false
export const IS_KARAFARIN = false
export const IS_PAYMENT_FIRST = false
export const THEME = 'ayria'
export const CLUB = 'AYRIA'
export const CLUB_CODE = 1013748
export const CLUB_AYRIA_CODE = 1013748
export const CLUB_LOWER_CASE = CLUB.toLowerCase()
export const CLUB_SUB_DOMAIN = 'dashboard'
export const CLUB_INSTAGRAM = 'AYRIACLUB'
export const CLUB_SHOW_GOLD = true
export const CLUB_HAS_CLUB_NUMBER = false
export const CLUB_HAS_LOTTERY = false
export const CLUB_HAS_LANDING = false
export const CLUB_HAS_SCORE_CASHOUT = true
export const CLUB_LOGO_WIDTH = '100px'
export const IPG_MIN_ACCEPTED_AMOUNT = 15000
export const CASHOUT_OPERATION_COST_MIN_VALUE = 5000
export const CASHOUT_OPERATION_COST_MAX_VALUE = 100000
export const TRANSFER_OPERATION_COST_VALUE = 2000
export const MINIMUM_MANUAL_CASHOUT_AMOUNT = 10000000000
export const APG_COLOR_HEX = '#4399f0'
export const MAIN_SITE_URL = 'https://ayria.club'
export const DASHBOARD_URL = 'https://dashboard.ayria.club'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URI
export const CDN_BASE_URL = 'https://cdn.ayriaclub.ir'
export const GEOCODING_BASE_URL = 'https://geocoding.ayriaclub.ir'
