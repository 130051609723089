import React from 'react'
import MerchantProfileRatingStatistics from './merchantProfileRatingStatistics'
import MerchantDescription from './merchantDescription'
import MerchantProfileImages from './merchantProfileImages'
import MerchantProfileInfo from './merchantProfileInfo'
import { isMobile } from '../../../utility'

interface MerchantProfileHighlightsProps {
  merchant: any
}

const MerchantProfileHighlights: React.FC<MerchantProfileHighlightsProps> = ({ merchant }) => {
  const { documents, documentPrefixUrl } = merchant
  const imagesIndoor = documents.filter((element: any) => element.type === 'PHOTO').slice(0, 4)
  const imagesOuttoor = documents.filter((element: any) => element.type === 'PHOTO_BUILDING').slice(0, 4)

  return (
    <>
      <MerchantProfileRatingStatistics merchant={merchant} />
      <MerchantDescription description={merchant.shortDescription} />
      <MerchantProfileImages
        documentPrefixUrl={documentPrefixUrl}
        images={imagesIndoor}
        numOfCols={isMobile() ? 2 : 5}
      />
      <MerchantDescription description={merchant.description} />
      <MerchantProfileImages
        documentPrefixUrl={documentPrefixUrl}
        images={imagesOuttoor}
        numOfCols={isMobile() ? 2 : 5}
      />
      <MerchantProfileInfo merchant={merchant} />
    </>
  )
}

export default MerchantProfileHighlights
