import React, { useState, useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomSummeryCard from '../components/elements/CustomSummeryCard/CustomSummeryCard'
import api from '../api/Api'
import { Auth, isDesktop } from '../utility'
import { connect } from '../data/connect'
import { Header, CustomModal } from '../ayria'

interface StateProps {
  walletBalance: number
  scoreBalance: number
}

interface TotalsTabProps extends StateProps {}

const TotalsTab: React.FC<TotalsTabProps> = ({ walletBalance, scoreBalance }) => {
  const auth = new Auth()
  const history = useHistory()
  const { t } = useTranslation()
  const [totals, setTotals] = useState<any>({})
  const [showUpgradeToStarPlan, setShowUpgradeToStarPlan] = useState(false)
  const [showUpgradeToGalaxyPlan, setShowUpgradeToGalaxyPlan] = useState(false)

  const handleCreditSelect = () => {
    if (auth.getLoggedInUser().plan === 'GOLD' || auth.getLoggedInUser().plan === 'BASIC') {
      setShowUpgradeToStarPlan(true)
    }
  }
  const handleInsuranceSelect = () => {
    if (
      auth.getLoggedInUser().plan === 'GOLD' ||
      auth.getLoggedInUser().plan === 'STAR' ||
      auth.getLoggedInUser().plan === 'BASIC'
    ) {
      setShowUpgradeToGalaxyPlan(true)
    }
  }

  const getTotals = async () => {
    const res: any = await api.get('/customer/purchases/totals')
    setTotals(res.data)
  }

  useEffect(() => {
    getTotals()
  }, [])

  return (
    <IonPage id='wallet-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header showBackButton={true} />
        <div className='ion-padding'>
          <h6>{'جزییات گزارشات خود را به صورت تفکیک شده با انتخاب هر گزینه مشاهده نمایید'}</h6>
          <div className='row ion-text-center ion-justify-content-center'>
            <CustomSummeryCard
              title={'جمع خریدها'}
              value={(totals?.customerAmount || 0).toLocaleString(navigator.language)}
              currency={t('label.riyal')}
              borderColor={'#FF9909'}
              containerClass={'col-11 pointer mb-2'}
              onClick={() => history.push('/purchases/all')}
            />
            <CustomSummeryCard
              title={'سقف اعتبار'}
              value={(totals?.creditAmount || 0).toLocaleString(navigator.language)}
              currency={t('label.riyal')}
              borderColor={'#5E5CD7'}
              containerClass={'col-11 pointer mb-2'}
              onClick={() => handleCreditSelect()}
            />
            <CustomSummeryCard
              title={'صندوق بیمه'}
              value={(totals?.insuranceAmount || 0).toLocaleString(navigator.language)}
              currency={t('label.riyal')}
              borderColor={'#929297'}
              containerClass={'col-11 pointer mb-2'}
              onClick={() => handleInsuranceSelect()}
            />
            <CustomSummeryCard
              title={'جمع تخفیفات'}
              value={(totals?.discountAmount || 0).toLocaleString(navigator.language)}
              currency={t('label.riyal')}
              borderColor={'#60CAFA'}
              color={'#000'}
              containerClass={'col-11 pointer mb-2'}
              onClick={() => history.push('/purchases/discounts')}
            />
            <CustomSummeryCard
              title={' کیف پول'}
              value={(walletBalance || 0).toLocaleString(navigator.language)}
              currency={t('label.riyal')}
              borderColor={'#097FFF'}
              containerClass={'col-11 pointer mb-2'}
              onClick={() => history.push('/wallet-report')}
            />
            <CustomSummeryCard
              title={'امتیاز'}
              value={(scoreBalance || 0).toLocaleString(navigator.language)}
              borderColor={'#FF9909'}
              containerClass='col-11 pointer mb-2'
              onClick={() => history.push('/tabs/scores')}
            />
            <CustomSummeryCard
              title={'تراکنش‌های بانکی'}
              value={''}
              borderColor={'#051094'}
              containerClass='col-11 pointer mb-2'
              onClick={() => history.push('/invoices')}
            />
            <CustomSummeryCard
              title={'شارژها و قبوض'}
              value={''}
              borderColor={'#FF9909'}
              containerClass='col-11 pointer mb-2'
              onClick={() => history.push('/payment-service-report')}
            />
          </div>
          <CustomModal
            title={'ارتقاء به طرح ستاره'}
            showModal={showUpgradeToStarPlan}
            toggle={setShowUpgradeToStarPlan}
            content={
              <div>
                <span>
                  برای بهرمندی از این امکان طرح خود را به{' '}
                  <b>
                    <span className='btn-outline-danger'>ستاره</span>
                  </b>{' '}
                  ارتقاء دهید.
                </span>
                <br />
                <br />
                <button className='btn' onClick={(e) => setShowUpgradeToStarPlan(false)}>
                  متوجه شدم
                </button>
                <button
                  className='btn btn-theme'
                  onClick={(e) => {
                    setShowUpgradeToStarPlan(false)
                    history.push('/purchase-plan?plan=STAR')
                  }}>
                  ارتقاء
                </button>
              </div>
            }
          />
          <CustomModal
            title={'ارتقاء به طرح کهکشان'}
            showModal={showUpgradeToGalaxyPlan}
            toggle={setShowUpgradeToGalaxyPlan}
            content={
              <div>
                <span>
                  برای بهرمندی از این امکان طرح خود را به{' '}
                  <b>
                    <span className='btn-outline-danger'>کهکشان</span>
                  </b>{' '}
                  ارتقاء دهید.
                </span>
                <br />
                <br />
                <button className='btn' onClick={(e) => setShowUpgradeToGalaxyPlan(false)}>
                  متوجه شدم
                </button>
                <button
                  className='btn btn-theme'
                  onClick={(e) => {
                    setShowUpgradeToGalaxyPlan(false)
                    history.push('/purchase-plan?plan=GALAXY')
                  }}>
                  ارتقاء
                </button>
              </div>
            }
          />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    walletBalance: state.user.walletBalance,
    scoreBalance: state.user.scoreBalance,
  }),
  component: TotalsTab,
})
