import React from 'react'
import { connect } from '../../data'
import { CLUB_LOWER_CASE, CDN_BASE_URL } from '../../config'
import { Club } from '../../models'

interface OwnProps {
  override?: any
}
interface StateProps {
  savedClub?: Club
}
interface LogoProps extends OwnProps, StateProps {}

const Logo: React.FC<LogoProps> = ({ savedClub, override = false }) => {
  return (
    <>
      {override && <img src={`${CDN_BASE_URL}/${override}`} className='main-logo' alt='' crossOrigin='anonymous' />}
      {!override && savedClub?.logo && (
        <img src={`${CDN_BASE_URL}/${savedClub.logo}`} className='main-logo' alt='' crossOrigin='anonymous' />
      )}
      {!override && !savedClub?.logo && (
        <img src={`/assets/${CLUB_LOWER_CASE}/logo.png`} className='main-logo' alt='' />
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    savedClub: state.user.savedClub,
  }),
  component: Logo,
})
