import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setScoreBalance } from '../../../data/user/user.actions'
import { connect } from '../../../data/connect'
import api from '../../../api/Api'

interface OwnProps {
  showImg?: boolean
  usePadding?: boolean
}

interface StateProps {
  scoreBalance: number
  isAuthenticated: boolean
}

interface DispatchProps {
  setScoreBalance: typeof setScoreBalance
}

interface ScoreBalanceProps extends OwnProps, StateProps, DispatchProps {}

const ScoreBalance: React.FC<ScoreBalanceProps> = ({
  setScoreBalance,
  scoreBalance,
  isAuthenticated,
  showImg = false,
  usePadding = true,
}) => {
  const history = useHistory()

  const getBalance = async () => {
    const res: any = await api.get('/customer/scores/total/0', {})
    if (res.ok && res.data) {
      await setScoreBalance(res.data)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getBalance()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isAuthenticated && (
        <span onClick={() => history.push('/tabs/scores')} className='pointer'>
          <span className={usePadding ? 'ion-padding-end' : ''}>
            {(scoreBalance || 0).toLocaleString(navigator.language)}
          </span>
          <span className='ion-padding'>{'امتیاز'}</span>
          {/*<img src='/assets/images/coin.png' alt='score' width='40px' />*/}
        </span>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setScoreBalance,
  },
  mapStateToProps: (state) => ({
    scoreBalance: state.user.scoreBalance,
    isAuthenticated: state.user.isLoggedIn,
  }),
  component: ScoreBalance,
})
