import React, { useEffect } from 'react'
import { IonBadge } from '@ionic/react'
import { ApiService, connect, setUnpaidFactorCount, setUnpaidPersonCount } from '../../../ayria'

interface OwnProps {
  raw?: boolean
  receiverType?: string
  marginLeft?: string
  ionic?: boolean
}
interface StateProps {
  unpaidFactorCount?: number
  unpaidPersonCount?: number
  isLoggedIn?: boolean
}
interface DispatchProps {
  setUnpaidFactorCount: typeof setUnpaidFactorCount
  setUnpaidPersonCount: typeof setUnpaidPersonCount
}
interface PayerResponseCountProps extends OwnProps, StateProps, DispatchProps {}

const PayerResponseCount: React.FC<PayerResponseCountProps> = ({
  raw = false,
  receiverType = '',
  marginLeft = '',
  ionic = false,
  unpaidFactorCount,
  unpaidPersonCount,
  setUnpaidFactorCount,
  setUnpaidPersonCount,
  isLoggedIn,
}) => {
  const api = new ApiService()

  const getPayerResponseCount = async (receiverType: string) => {
    const res: any = await api.get(
      `/user/ayria-payments/payer-responses/count${receiverType ? '/' + receiverType : ''}`
    )
    if (res.ok) {
      if (receiverType === 'personal') {
        await setUnpaidPersonCount(res.data)
      } else if (receiverType === 'company') {
        await setUnpaidFactorCount(res.data)
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (receiverType) {
        getPayerResponseCount(receiverType)
      } else {
        getPayerResponseCount('company')
        getPayerResponseCount('personal')
      }
    }
  }, [receiverType]) // eslint-disable-line

  return (
    <>
      {ionic && (unpaidFactorCount || unpaidPersonCount) && (
        <IonBadge color='danger'>{(unpaidPersonCount || 0) + (unpaidFactorCount || 0)}</IonBadge>
      )}
      {!ionic && (
        <span>
          {receiverType === 'personal' && unpaidPersonCount && (
            <span className={raw ? '' : 'badge badge-danger'} style={{ marginLeft: marginLeft }}>
              {unpaidPersonCount}
            </span>
          )}
          {receiverType === 'company' && unpaidFactorCount && (
            <span className={raw ? '' : 'badge badge-danger'} style={{ marginLeft: marginLeft }}>
              {unpaidFactorCount}
            </span>
          )}
          {!receiverType && (unpaidFactorCount || unpaidPersonCount) && (
            <span className={raw ? '' : 'badge badge-danger'} style={{ marginLeft: marginLeft }}>
              {(unpaidPersonCount || 0) + (unpaidFactorCount || 0)}
            </span>
          )}
        </span>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setUnpaidFactorCount,
    setUnpaidPersonCount,
  },
  mapStateToProps: (state) => ({
    unpaidFactorCount: state.user.unpaidFactorCount,
    unpaidPersonCount: state.user.unpaidPersonCount,
    isLoggedIn: state.user.isLoggedIn,
  }),
  component: PayerResponseCount,
})
