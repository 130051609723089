import React, { useEffect, useState } from 'react'
import ApiService from '../../../api/ApiService'

interface OwnProps {
  acceptorCode: string
  showLabel?: boolean
}
interface AcceptorContactProps extends OwnProps {}

const AcceptorContact: React.FC<AcceptorContactProps> = ({ acceptorCode, showLabel }) => {
  const api = new ApiService()
  const [phone, setPhone] = useState<strong>()

  const getContact = async () => {
    const res: any = await api.get(`/public/acceptors/contact/${acceptorCode}`)
    if (res.ok) {
      setPhone(res.data)
    }
  }

  useEffect(() => {
    getContact()
  }, []) // eslint-disable-line

  return (
    <>
      {showLabel && !!phone && 'تماس با فروشگاه: '}
      {!!phone && <a href={`tel:${'0' + +phone}`}>{'0' + +phone}</a>}
    </>
  )
}

export default AcceptorContact
