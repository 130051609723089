import React, { useEffect, useState, useContext } from 'react'
import ProfileForm from '../../modules/profile-form/profile-form'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import api from '../../../api/Api'
import './profile.css'

const Profile = () => {
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const [avatarSrc, setAvatarSrc] = useState('')

  const getProfile = async () => {
    const res = await api.get('/account/profile')
    setProfileContext((state: any) => ({ ...state, profile: res.data }))
  }

  useEffect(() => {
    if (profileContext.profile) {
      if (profileContext.profile.gender === 'MALE') {
        setAvatarSrc('/assets/images/user_m.jpeg')
      } else {
        setAvatarSrc('/assets/images/user_f.jpeg')
      }
    } else {
      getProfile()
    }
    // eslint-disable-next-line
  }, [profileContext.profile])

  return (
    <>
      {profileContext.profile && (
        <div className={'row'}>
          <div className={' col-md-3 col-xs-12 mb-3'}>
            <div className={'page'}>
              <div className={'profile-image-container text-center'}>
                <div className={'img-circle profile-image'}>
                  {/* the actual size should be bigger than display size for retina screens */}
                  <img alt='' style={{ width: '150px' }} src={avatarSrc}></img>
                </div>
                <div className={'profile-name-container'}>
                  <span
                    className={
                      'profile-name'
                    }>{`${profileContext.profile.firstName} ${profileContext.profile.lastName}`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={' col-md-9 col-xs-12'}>
            <div className={'page'}>
              <div className={'profile-form-container '}>
                <ProfileForm user={profileContext.profile} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Profile
