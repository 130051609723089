import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router'
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react'
import { statsChart, journal, receipt, storefront, cart } from 'ionicons/icons'
import ApiService from '../api/ApiService'
import { connect } from '../data/connect'
import { setActiveOrderCount } from '../data/user/user.actions'
import '../App-deprecated.scss'

import AcceptorListTab from './home/AcceptorListTab'
import HomePage from './home/HomePage'
import NotificationsTab from './NotificationsTab'
import PaymentServiceTab from './payment/PaymentServiceTab'
import WalletTab from './wallet/WalletTab'
import ScoreTab from './ScoreTab'
import TotalsTab from './TotalsTab'
import RatePurchaseTab from './RatePurchaseTab'
import MerchantProfilePage from './MerchantProfilePage'
import ProfileTab from './ProfileTab'
import ChangePasswordTab from './ChangePasswordTab'
import PurchaseScoreTab from './score/PurchaseScoreTab'
import SettingsTab from './settings/SettingsTab'
import WalletIdentityTab from './wallet/WalletIdentityTab'
import LotteryTab from './lottery/LotteryTab'
import MyCenterListTab from './mycenter/MyCenterListTab'
import MyCenterRegisterTab from './mycenter/MyCenterRegisterTab'
import MyCenterUpdateTab from './mycenter/MyCenterUpdateTab'
import PayerResponseCount from '../components/elements/PaymentService/PayerResponseCount'
import QrTabs from './qr/QrTabs'
import WalletThreadTab from './wallet/WalletThreadTab'
import OnlineOrderThreadTab from './onlineorder/OnlineOrderThreadTab'
import ClubList from './club/ClubList'
import GroupPaymentRequestList from './payment/GroupPaymentRequestList'
import GroupTransferInstanceList from './transfer/GroupTransferInstanceList'

interface StateProps {
  isAuthenticated: boolean
  activeOrderCount?: number
  kalaList: any[]
}

interface DispatchProps {
  setActiveOrderCount: typeof setActiveOrderCount
}

interface MainTabsProps extends StateProps, DispatchProps {}

const MainTabs: React.FC<MainTabsProps> = ({ kalaList, isAuthenticated, activeOrderCount, setActiveOrderCount }) => {
  const api = new ApiService()

  const getActiveOrderCount = async () => {
    const res: any = await api.get('/user/ayria-payments/active-order-count')
    if (res.ok) {
      await setActiveOrderCount(res.data)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getActiveOrderCount()
    }
  }, [isAuthenticated]) // eslint-disable-line

  return (
    <IonTabs>
      <IonRouterOutlet id='tabs'>
        <Redirect exact path='/tabs' to={`/tabs/home${window.location.search}`} />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route
          path='/tabs/home'
          render={() => (isAuthenticated ? <HomePage /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/receipt'
          render={() => (isAuthenticated ? <WalletThreadTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/orders'
          render={() =>
            isAuthenticated ? <OnlineOrderThreadTab /> : <Redirect to={`/login${window.location.search}`} />
          }
          exact={true}
        />
        <Route
          path='/tabs/payment'
          render={() => (isAuthenticated ? <PaymentServiceTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/notifications'
          render={() => (isAuthenticated ? <NotificationsTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/payment/qr'
          render={() => (isAuthenticated ? <QrTabs /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/wallet'
          render={() => (isAuthenticated ? <WalletTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/scores'
          render={() => (isAuthenticated ? <ScoreTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/totals'
          render={() => (isAuthenticated ? <TotalsTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/rate'
          render={() => (isAuthenticated ? <RatePurchaseTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route path='/tabs/centers' render={() => <AcceptorListTab />} exact={true} />
        <Route path='/tabs/center/:merchantId' component={MerchantProfilePage} />
        <Route
          path='/tabs/profile'
          render={() => (isAuthenticated ? <ProfileTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/change-password'
          render={() => (isAuthenticated ? <ChangePasswordTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/wallet-identity'
          render={() => (isAuthenticated ? <WalletIdentityTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/score'
          render={() => (isAuthenticated ? <PurchaseScoreTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/settings'
          render={() => (isAuthenticated ? <SettingsTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route path='/tabs/lottery' render={() => <LotteryTab />} exact={true} />
        <Route
          path='/tabs/mycenter'
          render={() => (isAuthenticated ? <MyCenterListTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/mycenternew'
          render={() =>
            isAuthenticated ? <MyCenterRegisterTab /> : <Redirect to={`/login${window.location.search}`} />
          }
          exact={true}
        />
        <Route
          path='/tabs/mycenter/:acceptorId'
          render={() => (isAuthenticated ? <MyCenterUpdateTab /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/clubs'
          render={() => (isAuthenticated ? <ClubList /> : <Redirect to={`/login${window.location.search}`} />)}
          exact={true}
        />
        <Route
          path='/tabs/group-payment-request'
          render={() =>
            isAuthenticated ? <GroupPaymentRequestList /> : <Redirect to={`/login${window.location.search}`} />
          }
          exact={true}
        />
        <Route
          path='/tabs/group-transfer-instance/:acceptorCode'
          render={() =>
            isAuthenticated ? <GroupTransferInstanceList /> : <Redirect to={`/login${window.location.search}`} />
          }
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar slot='bottom'>
        <IonTabButton tab='home' href='/tabs/home'>
          <IonIcon icon={storefront} />
          <IonLabel>{'خرید'}</IonLabel>
        </IonTabButton>

        <IonTabButton tab='cart' href={`/shopping-cart${window.location.search}`}>
          <IonIcon icon={cart} />
          <IonLabel>{'سبدخرید'}</IonLabel>

          {kalaList?.length > 0 && (
            <IonBadge color='danger'>{kalaList.reduce((a: number, b: any) => +a + +b.qty, 0)}</IonBadge>
          )}
        </IonTabButton>

        <IonTabButton tab='payment' href='/tabs/payment'>
          <IonIcon icon={journal} />
          <IonLabel>{'پرداخت'}</IonLabel>
          <PayerResponseCount ionic={true} />
        </IonTabButton>

        <IonTabButton tab='orders' href='/tabs/orders'>
          <IonIcon icon={receipt} />
          <IonLabel>{'سفارش‌ها'}</IonLabel>
          {!!activeOrderCount && <IonBadge color='danger'>{activeOrderCount}</IonBadge>}
        </IonTabButton>

        <IonTabButton tab='totals' href='/tabs/totals'>
          <IonIcon icon={statsChart} />
          <IonLabel>{'گزارشات'}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedIn,
    activeOrderCount: state.user.activeOrderCount,
    kalaList: state.user.kalaList,
  }),
  mapDispatchToProps: {
    setActiveOrderCount,
  },
  component: MainTabs,
})
