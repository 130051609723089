import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header } from '../ayria'
import Profile from '../components/screens/profile/profile'

interface ProfileTabProps {}

const ProfileTab: React.FC<ProfileTabProps> = () => {
  return (
    <IonPage id='profile-tab-page'>
      <IonContent>
        <Header />
        <Profile />
      </IonContent>
    </IonPage>
  )
}

export default ProfileTab
