import React, { FC } from 'react'
import ScoreEvents from '../../components/screens/score-events/score-events'

interface OwnProps {
  code: string
}
interface MyClubScoreProps extends OwnProps {}
const MyClubScore: FC<MyClubScoreProps> = ({ code }) => {
  return (
    <>
      <ScoreEvents code={code} type='club' />
    </>
  )
}

export default MyClubScore
