import React, { useEffect, useState } from 'react'
import { IonSpinner, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react'
import { useParams } from 'react-router'
import Modal from '../../../utility/modal/Modal'
import { useToast } from '../../../utility/toaster/useToast'
import CurrencyIonInput from '../../../components/units/CurrencyInput/CurrencyIonInput'
import ApiService from '../../../api/ApiService'
import usePointManager from './usePointManager'

declare var L: any

interface PointManagerProps {
  onAreaAdded: Function
  showDrawTools?: boolean
  showCurrentLocation?: boolean
}

const PointManager: React.FC<PointManagerProps> = ({
  onAreaAdded,
  showDrawTools = false,
  showCurrentLocation = false,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const map = usePointManager()
  const { acceptorCode } = useParams<any>()
  const [name, setName] = useState<string>()
  const [price, setPrice] = useState<any>()
  const [points, setPoints] = useState()
  const [showModal, setShowModal] = useState(false)
  const [inprogress, setInprogress] = useState(false)
  const [currentLocationAdded, setCurrentLocationAdded] = useState(false)

  const createDeliveryPrice = async () => {
    setInprogress(true)
    const res: any = await api.acceptorDeliveryMapCreatePrice({
      acceptorCode,
      name,
      price,
      points,
    })
    if (res.ok) {
      toast.success('محدوده با موفقیت اضافه شد')
      resetForm()
      onAreaAdded(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const resetForm = () => {
    setShowModal(false)
    setName('')
    setPrice('')
    setPoints([])
  }

  const isInvalid: boolean = () => {
    return !(!!name && !!price && !!points)
  }

  useEffect(() => {
    if (showDrawTools) {
      map?.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawRectangle: false,
        drawMarker: false,
        drawCircleMarker: false,
        drawPolyline: false,
        dragMode: false,
        cutPolygon: false,
        oneBlock: true,
        editMode: false,
      })
      map?.pm.disableGlobalRotateMode()
      map?.pm.setGlobalOptions({ measurements: { measurement: true, displayFormat: 'metric' } })
      map?.pm.setLang('fa')
      map?.on('pm:create', function (poly) {
        setPoints(poly?.layer?._latlngs[0].map((ll) => ({ x: ll?.lat.toFixed(10), y: ll?.lng.toFixed(10) })))
        setShowModal(true)
      })
    }
    if (showCurrentLocation && !currentLocationAdded) {
      L.control
        .locate({
          position: 'bottomright',
          locateOptions: {
            enableHighAccuracy: true,
          },
          icon: 'leaflet-locate-icon',
          iconLoading: 'leaflet-locate-loading-icon',
          strings: {
            title: 'موقعیت جاری',
          },
        })
        .addTo(map)
      setCurrentLocationAdded(true)
    }
    map.attributionControl.setPrefix('Ayria')
  }, [map, showDrawTools, showCurrentLocation]) // eslint-disable-line

  return (
    <>
      <Modal
        title={'ثبت محدوده پیک'}
        showModal={showModal}
        toggle={setShowModal}
        onClosed={() => setShowModal(false)}
        content={
          <>
            <IonItem>
              <IonLabel position='floating'>{'نام محله'}</IonLabel>
              <IonInput onIonChange={(e: any) => setName(e.detail.value)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position='floating'>{'قیمت (ریال)'}</IonLabel>
              <CurrencyIonInput onValueChange={setPrice}></CurrencyIonInput>
            </IonItem>
          </>
        }
        actionButtons={
          <>
            <IonButton disabled={isInvalid() || inprogress} onClick={createDeliveryPrice}>
              {'ثبت'}
              {inprogress && <IonSpinner slot='start' />}
            </IonButton>
            <IonButton color='light' onClick={() => showModal(false)}>
              {'انصراف'}
            </IonButton>
          </>
        }
      />
    </>
  )
}

export default PointManager
