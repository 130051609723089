import React, { useContext, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileContext } from '../../ayria'
const ReferralLink = lazy(() => import('../../components/modules/referral-link/referral-link'))

const ReferralModal = () => {
  const { t } = useTranslation()
  const [profileContext] = useContext<any>(ProfileContext)

  return (
    <div className={'referralLinkHolder'}>
      {profileContext.profile && (
        <div title={`${t('profile.referralCode')} : ${profileContext.profile.referralCode}`}>
          <div className={'mb-2 text-justify'}>
            شما با استفاده از این کد میتونید خیلی راحت هر کسی رو که بخواهید به جمع آیریا دعوت کنید تا هم خودتون و هم کسی
            که دعوت میکنید کلی امتیاز بگیره ،پس همین الان بفرست برای همه
          </div>
          <ReferralLink profile={profileContext.profile} />
        </div>
      )}
    </div>
  )
}

export default ReferralModal
