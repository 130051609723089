import React, { useState, useEffect } from 'react'
import { IonItem, IonLabel, IonButton, IonInput, IonTextarea } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import Nickname from '../../elements/Nickname/Nickname'
import api from '../../../api/Api'
import './AcceptorComment.css'
import { Acceptor, Modal, useToast } from '../../../ayria'

declare var document: any

interface AcceptorCommentProps {
  acceptor: Acceptor
  onSuccess(comment: any): void
}

const AcceptorComment: React.FC<AcceptorCommentProps> = ({ acceptor, onSuccess }) => {
  const { t } = useTranslation()
  const toaster = useToast()
  const [title, setTitle] = useState('')
  const [comment, setComment] = useState('')
  const [commentCounter, setCommentCounter] = useState(0)
  const [inprogress, setInprogress] = useState(false)
  const [hasNickname, setHasNickname] = useState(true)
  const [showNicknameModal, setShowNicknameModal] = useState(false)
  const [enableContinueButton, setEnableContinueButton] = useState(false)

  const sendComment = async () => {
    setInprogress(true)
    try {
      const response = await api.post(`/user/comments/create`, {
        title,
        text: comment,
        referralCode: acceptor.referralCode,
      })
      toaster.success(t('label.successfulOperation'))
      setComment('')
      document.getElementById('comment').value = ''
      onSuccess(response.data)
    } catch (error) {
      toaster.error(t('label.operationFailed'))
    } finally {
      setInprogress(false)
    }
  }

  const handleSendComment = () => {
    if (hasNickname) {
      sendComment()
    } else {
      setShowNicknameModal(true)
    }
  }

  const updateComment = (text: string) => {
    if (text) {
      setComment(text)
      setCommentCounter(text.length)
      if (text.length >= 25 && !title) {
        document.getElementById('title').value = text.substring(0, 25)
        setTitle(text.substring(0, 25))
      }
    } else {
      setComment('')
      setCommentCounter(0)
    }
  }

  const getNickname = async () => {
    const res = await api.get('/account/profile/nickname')
    if (res.ok && !res.data) {
      setHasNickname(false)
    }
  }

  useEffect(() => {
    getNickname()
  }, [])

  return (
    <>
      <div>
        <IonItem>
          <IonLabel position='stacked'>{t('write_your_opinion')}: </IonLabel>
          <IonTextarea
            onIonChange={(e) => updateComment(e.detail.value)}
            id='comment'
            placeholder={'نظر شما در مورد ' + acceptor.acceptorName}
            tabIndex={0}
          />
        </IonItem>
        <div>
          <span className={'left ' + (commentCounter < 25 ? 'text-danger' : 'text-success')}>{commentCounter}</span>
          <span className='left fa-sm pl-2'>{t('min_char_x', { x: 25 })}</span>
        </div>
        <IonItem className='mt-3'>
          <IonLabel position='stacked'>{t('write_title_of_your_opinion')}: </IonLabel>
          <IonInput onIonChange={(e) => setTitle(e.detail.value)} id='title' placeholder={t('label.title')} />
        </IonItem>
        <IonButton onClick={handleSendComment} className='mt-3' disabled={commentCounter < 25 || inprogress}>
          {'ارسال'}
        </IonButton>
      </div>
      <Modal
        showModal={showNicknameModal}
        toggle={setShowNicknameModal}
        title={t('label.nickname')}
        onClosed={() => setShowNicknameModal(false)}
        content={
          <div>
            <p>{t('your_nickname_is_not_set')}</p>
            <IonItem>
              <Nickname onValidation={(value) => setEnableContinueButton(value)} />
            </IonItem>
            <IonButton
              disabled={!enableContinueButton}
              className='right mt-3'
              onClick={(e) => {
                setShowNicknameModal(false)
                sendComment()
              }}>
              {t('label.continue')}
            </IonButton>
          </div>
        }
      />
    </>
  )
}

export default AcceptorComment
