import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import api from '../../../api/Api'
import './autosuggest.scss'

const AcceptorSuggest = ({ onChange, onSelect, prop = 'id' }) => {
  const [value, setValue] = useState('')
  const [acceptors, setAcceptors] = useState()
  const [suggestions, setSuggestions] = useState()

  async function getAllAcceptors() {
    const res = await api.fn.getAllAcceptors()
    setAcceptors(res.ok ? res.data : [])
    setSuggestions(res.ok ? res.data : [])
  }

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? acceptors
      : acceptors.filter((acc) => acc.acceptorName && acc.acceptorName.toLowerCase().includes(inputValue))
  }
  const getSuggestionValue = (suggestion) => suggestion.acceptorName
  const renderSuggestion = (suggestion, { query }) => {
    const matches = match(suggestion.acceptorName, query)
    const parts = parse(suggestion.acceptorName, matches)
    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight ? 'react-autosuggest__suggestion-match' : null
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          )
        })}
      </span>
    )
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }
  const handleOnSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setValue(suggestionValue)
    onSelect(suggestion[prop])
    setSuggestions([])
  }
  const handleOnSuggestionChanged = (event, { newValue, method }) => {
    setValue(newValue)
    onChange(newValue)
  }
  const inputProps = {
    placeholder: 'نام فروشگاه',
    value,
    onChange: handleOnSuggestionChanged,
  }
  useEffect(() => {
    getAllAcceptors()
  }, [])
  return (
    <>
      {suggestions && (
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={handleOnSuggestionSelected}
        />
      )}
    </>
  )
}

export default AcceptorSuggest
