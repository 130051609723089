import React from 'react'

interface BalanceProps {
  amount: number;
}

const Balance: React.FC<BalanceProps> = ({ amount }) => {
  return <span className={'dir-ltr' + (amount < 0 ? ' text-danger' : '')}>{(amount || 0).toLocaleString(navigator.language)}</span>
}

export default Balance
