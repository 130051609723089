import React, { useState, useEffect } from 'react'
import { IonButton } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './plan-selection.css'
import Auth from '../../../utility/auth/Auth'
import { useToast, ApiService, ReferralCode, connect } from '../../../ayria'

interface OwnProps {
  isNewMember: boolean
}
interface StateProps {
  referralCode?: string
}
interface PlanSelectionProps extends OwnProps, StateProps {}

const PlanSelection: React.FC<PlanSelectionProps> = ({ isNewMember, referralCode }) => {
  const api = new ApiService()
  const auth = new Auth()
  const { t } = useTranslation()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const history = useHistory()
  const toast = useToast()
  const [plan, setPlan] = useState<any>()

  const handleClick_more = (event: any) => {
    event.target.nextSibling.classList.remove('hidden')
    event.target.nextSibling.nextSibling.classList.remove('hidden')
    event.target.classList.add('hidden')
  }
  const handleClick_less = (event: any) => {
    event.target.previousElementSibling.classList.remove('hidden')
    event.target.nextSibling.classList.add('hidden')
    event.target.classList.add('hidden')
  }
  const handleSelect = (plan: any) => {
    history.push(`/purchase-plan?plan=${plan.type}${referralCode ? `&ref=${referralCode}` : ''}`)
  }
  const enterWithBasicPlan = async () => {
    const res: any = await api.post('/account/plan/basic', { referralCode })
    if (res.ok) {
      auth.setLoggedInUser(res.data)
      history.push('/tabs')
    } else {
      if (res.status === 401) {
        history.push('/login')
      }
      toast.error(res)
    }
  }
  const goToOtherPlan = () => {
    history.push({ pathname: '/allPlan', search: window.location.search })
  }
  const getCustomerInfo = async () => {
    try {
      const planRes: any = await api.accountPlan()
      setPlan(planRes.ok ? planRes.data : {})
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (urlSearchParams.get('plan')) {
      handleSelect({ type: urlSearchParams.get('plan') })
    }
    getCustomerInfo()
  }, []) // eslint-disable-line

  return (
    <>
      {plan && !isNewMember && (
        <div>
          <div className={'col-sm-12 ' + plan?.plan}>
            {plan?.hasPlan && (
              <div className='row'>
                <div className={'container'}>
                  <div className={'right  mt-2 '}>
                    {plan.plan !== 'BASIC' && (
                      <span>
                        <span className={'planHeader font14 '}>{t('plan.currentPlanMessage')}</span>
                        <span className={'font-20'}> {t('plan.' + plan?.plan)}</span>
                      </span>
                    )}
                    {plan.plan === 'BASIC' && <span>{t('plan.purchase')}</span>}
                  </div>
                  <img
                    alt=''
                    style={{ width: '20px' }}
                    onClick={handleClick_more}
                    src={'/assets/images/append.png'}
                    className={'left m-2'}></img>
                  <img
                    alt=''
                    style={{ width: '20px' }}
                    onClick={handleClick_less}
                    src={'/assets/images/omit.png'}
                    className={'left hidden pt-2 m-2'}></img>
                  <div className={'more-detail hidden col-12 clear-both'}>
                    {plan.plan !== 'GALAXY' && (
                      <span className={'padding padding-left  mt-2 col-3 font14 right'}>
                        {plan.plan === 'BASIC' ? t('plan.purchase') : t('plan.upgradeTo')}:
                      </span>
                    )}
                    {plan.avaiablePlans.map((value: any, index: number) => {
                      return (
                        <div className={'col-9 row'} key={value.type}>
                          <div className={'padding'}>
                            <button className={'btn btn-theme btn-sm'} onClick={() => handleSelect(value)}>
                              {' '}
                              {t('plan.' + value.type)}
                            </button>
                          </div>
                          <div className='vl'></div>
                          <span className={'mt-2 mr-3'}> {value.price.toLocaleString(navigator.language)}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
            {!plan.hasPlan && (
              <div className={'text-center'}>
                {plan.avaiablePlans.map((value: any, index: number) => {
                  return (
                    <div className={'row '} key={value.type}>
                      <div className={'padding'}>
                        <button className={'btn btn-theme btn-sm'} onClick={() => handleSelect(value)}>
                          {' '}
                          {t('plan.' + value.type)}
                        </button>
                      </div>
                      <div className='vl'></div>
                      <span className={'mt-2 '}> {value.price.toLocaleString(navigator.language)}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}

      {isNewMember && (
        <div>
          <div>
            {
              'عضویت شما در باشگاه آیریا رایگان است و شما به ازای هر ۱۰،۰۰۰ تومان خرید یک امتیاز می‌گیرید. به منظور بهره مندی از مزایای بیشتر مانند تخفیف، اعتبار و حتی بیمه می‌توانید طرح مورد نظر خود را خریداری نمایید.'
            }
          </div>
          <div>
            <IonButton onClick={goToOtherPlan}>{'خرید طرح'}</IonButton>
            <IonButton onClick={enterWithBasicPlan}>{'ورود به صفحه کاربری'}</IonButton>
            <ReferralCode />
          </div>
        </div>
      )}
    </>
  )
}
export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    referralCode: state.user.referralCode,
  }),
  component: PlanSelection,
})
