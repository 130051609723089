import React from 'react'

const CustomSummeryCard = ({
  title,
  borderColor,
  color = 'black',
  value,
  containerClass = 'width_n20 col',
  currency = '',
  onClick,
}) => {
  return (
    <div className={`statisticsSummery ${containerClass}`} style={{ borderColor: ` ${borderColor}` }} onClick={onClick}>
      <span className='right statistic-item' style={{ color: ` ${color}` }}>
        {title}
      </span>

      <span className='ltr left h3 pl-2 d-flex flex-row align-items-center ' style={{ color: ` ${color}` }}>
        {!!value && <span className='text-currency left statistic-item'>{currency}</span>}
        {value}
      </span>
    </div>
  )
}

export default CustomSummeryCard
