import React, { useEffect } from 'react'
import { setAcceptorCode, connect } from '../../../data'
import ApiService from '../../../api/ApiService'

interface StateProps {
  loggedUser: any
}
interface DispatchProps {
  setAcceptorCode: typeof setAcceptorCode
}
interface AcceptorCurrentResolverProps extends StateProps, DispatchProps {}

const AcceptorCurrentResolver: React.FC<AcceptorCurrentResolverProps> = ({ loggedUser, setAcceptorCode }) => {
  const api = new ApiService()

  const initLoad = async () => {
    const res: any = await api.get('/acceptor/current')
    if (res.ok) {
      const currentAcceptorIndex = localStorage.getItem(`CURRENT_ACCEPTOR_INDEX_${loggedUser.login}`) || 0
      await setAcceptorCode(
        currentAcceptorIndex < res.data.length ? res.data[currentAcceptorIndex].code : res.data[0].code
      )
    }
  }

  useEffect(() => {
    if (loggedUser?.login) {
      initLoad()
    }
  }, [loggedUser]) // eslint-disable-line

  return <></>
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
  }),
  mapDispatchToProps: {
    setAcceptorCode,
  },
  component: AcceptorCurrentResolver,
})
