import React, { FC } from 'react'
import { BillType } from '../../../models'

interface BillTypeImageProps {
  billType?: BillType
  width?: string
}

const BillTypeImage: FC<BillTypeImageProps> = ({ billType = BillType.MOBILE, width = '20px' }) => {
  const imageFor = (bt: BillType) => {
    switch (bt) {
      case BillType.WATER:
        return 'water'
      case BillType.ELECTRICITY:
      case BillType.ELECTRICITY_STANDARD:
        return 'electric'
      case BillType.GAS_BILL:
      case BillType.GAS:
        return 'gaz'
      case BillType.TEL:
      case BillType.TEL_NOW:
        return 'phone'
      case BillType.MOBILE:
      case BillType.MOBILE_NOW:
        return 'mobile'
      default:
        return 'municipality'
    }
  }

  return (
    <img
      src={`/assets/images/bill/${imageFor(BillType[billType as keyof typeof BillType])}.png`}
      alt={BillType[billType]}
      width={width}
    />
  )
}

export default BillTypeImage
