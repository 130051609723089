import React, { FC, useEffect, useState } from 'react'
import { IonButton, IonIcon, IonItem, IonItemDivider, IonItemOption } from '@ionic/react'
import { IonItemOptions, IonItemSliding, IonLabel, IonSpinner } from '@ionic/react'
import { trash } from 'ionicons/icons'
import { connect } from '../../data'
import { Modal, setWithTimeout, useToast } from '../../utility'
import MyClubGroupEdit from './MyClubGroupEdit'
import { ApiService } from '../../ayria'
import MyClubGroupMember from './MyClubGroupMember'

interface StateProps {
  myClubCode: string
}
interface MyClubGroupProps extends StateProps {}

const MyClubGroup: FC<MyClubGroupProps> = ({ myClubCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [inprogress, setInprogress] = useState(true)
  const [groupName, setGroupName] = useState<any>()
  const [groupId, setGroupId] = useState<any>()

  const fetchItems = async () => {
    setInprogress(true)
    const res: any = await api.get(`/club/club-group/list/${myClubCode}`)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    }
    setInprogress(false)
  }

  const deleteGroup = async (itm: any) => {
    setInprogress(true)
    const res: any = await api.delete(`/club/club-group/delete/${myClubCode}/${itm.id}`)
    if (res.ok) {
      toast.success('گروه با موفقیت حذف شد')
      await fetchItems()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    if (!!myClubCode) {
      fetchItems()
    }
  }, [myClubCode]) // eslint-disable-line

  return (
    <>
      <IonItemDivider>
        <IonLabel>{'گروهای اعضای باشگاه'}</IonLabel>
        {inprogress && <IonSpinner slot='end' />}
        <IonButton slot='end' onClick={() => setShowModal(true)}>
          {'گروه جدید'}
        </IonButton>
      </IonItemDivider>
      {items?.map((itm: any, i) => (
        <IonItemSliding key={`${itm.publicId}-${i}`}>
          <IonItem>
            <IonLabel>{itm.name}</IonLabel>
            <IonButton
              slot='end'
              color='medium'
              onClick={() => {
                setGroupId(itm.id)
                setGroupName(itm.name)
                setShowMemberModal(true)
              }}>
              {'اعضا'}
            </IonButton>
          </IonItem>
          <IonItemOptions>
            <IonItemOption
              color='danger'
              style={{ width: '30px' }}
              onClick={() => deleteGroup(itm)}
              disabled={inprogress}>
              <IonIcon icon={trash}></IonIcon>
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
      <Modal
        showModal={showModal}
        toggle={setShowModal}
        onClosed={() => setShowModal(false)}
        title={'گروه جدید'}
        content={
          <MyClubGroupEdit
            onSuccess={async () => {
              setShowModal(false)
              await fetchItems()
            }}
          />
        }
      />
      <Modal
        showModal={showMemberModal}
        toggle={setShowMemberModal}
        onClosed={() => setShowMemberModal(false)}
        title={`اعضای گروه ${groupName}`}
        content={<MyClubGroupMember groupId={groupId} />}
      />
    </>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    myClubCode: state.shop.myClubCode,
  }),
  component: MyClubGroup,
})
