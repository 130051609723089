import React, { useState } from 'react';

const ProfileContext = React.createContext([{}, () => {}]);

const ProfileProvider = (props) => {
  const [state, setState] = useState({})
  return (
    <ProfileContext.Provider value={[state, setState]}>
      {props.children}
    </ProfileContext.Provider>
  );
}

export { ProfileContext, ProfileProvider };
