import React, { createContext, useContext, FC, useCallback, useMemo, useState, useRef } from 'react'
import { ToastOptions, IonToast } from '@ionic/react'
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent'
import { useTranslation } from 'react-i18next'
import './useToast.scss'

type ReactToastOptions = ToastOptions & Partial<ReactControllerProps>

type ToastInstance = {
  present: (options?: ReactToastOptions) => void
  dismiss: () => void
}

type ToastProviderOptions = {
  create: (options: ReactToastOptions) => ToastInstance
  success: (message: string) => ToastInstance
  error: (message: string) => ToastInstance
  warning: (message: string) => ToastInstance
  info: (message: string) => ToastInstance
}

const ToastContext = createContext<ToastProviderOptions | null>(null)
const { Provider } = ToastContext

interface Props {
  value?: ToastOptions
}

export const useToast = () => useContext(ToastContext) as ToastProviderOptions

export const ToastProvider: FC<Props> = ({ value, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<ReactToastOptions>()
  const ref = useRef<HTMLIonToastElement | null>(null)
  const { t } = useTranslation()

  const create = useCallback(
    (options: ReactToastOptions) => {
      const present = (options: ReactToastOptions) => () => {
        setOptions({
          ...value,
          ...options,
        })
        setIsOpen(true)
      }

      const dismiss = () => {
        ref.current?.dismiss()
      }

      return {
        present: present(options),
        dismiss,
      }
    },
    [value]
  )

  const contextValue = useMemo(() => {
    const resolveMessageFromResponse = (res: any) => {
      if (res?.data?.fieldErrors?.length > 0) {
        return t(`${res.data.fieldErrors[0].message}`, { field: t(`${res.data.fieldErrors[0].field}`) })
      }
      if (res?.data?.title) {
        return res.data.title
      }
      return t('label.operationFailed', { status: res?.status })
    }
    const translateToOptions = (color: 'success' | 'warning' | 'danger') => (rawMessage: any, duration = 3000) => {
      const message = typeof rawMessage === 'string' ? rawMessage : resolveMessageFromResponse(rawMessage)
      const toast = create({ message, color, duration })
      toast.present()
      return toast
    }

    return {
      create,
      success: translateToOptions('success'),
      error: translateToOptions('danger'),
      info: translateToOptions('tertiary'),
      warning: translateToOptions('warning'),
    }
  }, [create, t])

  return (
    <Provider value={contextValue}>
      {children}
      <IonToast ref={ref} isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} {...options} />
    </Provider>
  )
}
