import React, { useEffect, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { chevronDown, chevronBack } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { RateIcon, useToast } from '../../../ayria'

const PurchaseAcceptorReceipt = ({ purchaseId, acceptorCode }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [purchase, setPurchase] = useState()
  const [rate, setRate] = useState()
  const [isRateOpen, setIsRateOpen] = useState(true)

  const getPurchase = async () => {
    api.get(`/acceptor-roles/${acceptorCode}/purchase/${purchaseId}`).then((res) => {
      if (res.ok) {
        setPurchase(res.data)
      } else {
        toast.error(res)
      }
    })
  }

  const getRate = async () => {
    const res = await api.get(`/acceptor-roles/${acceptorCode}/purchase/${purchaseId}/comment`)
    setRate(res.ok ? res.data : null)
  }

  useEffect(() => {
    getPurchase()
    getRate()
  }, []) // eslint-disable-line

  return (
    <>
      {purchase && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr onClick={() => setIsRateOpen(!isRateOpen)}>
                <td className='text-right'>نام مشتری</td>
                <td>
                  {purchase.customerName}
                  {purchase.customerName === 'کاربر ثبت نشده' ? ` (${purchase.customerMobile}) ` : ''}
                  {rate && <IonIcon slot='end' icon={isRateOpen ? chevronDown : chevronBack} className='left' />}
                </td>
              </tr>
              {rate && isRateOpen && (
                <tr>
                  <td colSpan={2} className='no-padding'>
                    <div className='flex-container'>
                      <span className='flex-1'>
                        <RateIcon rate={rate.rate} />
                      </span>
                    </div>
                    <div className='flex-container'>
                      <span className='flex-1'>{'عنوان'}</span>
                      <span className='flex-3'>{rate.title}</span>
                    </div>
                    <div className='flex-container'>
                      <span className='flex-1'>{'متن'}</span>
                      <span className='flex-3'>{rate.text}</span>
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td className='text-right'>تاریخ و زمان</td>
                <td>
                  {purchase.purchaseTime} {purchase.purchaseDatePersian}
                </td>
              </tr>
              <tr>
                <td className='text-right'>مبلغ</td>
                <td>{purchase.totalAmount.toLocaleString(navigator.language)} ریال </td>
              </tr>
              <tr>
                <td className='text-right'>مبلغ دریافتی</td>
                <td>
                  {purchase?.acceptorAmountActual?.toLocaleString(navigator.language) ||
                    purchase.acceptorAmount.toLocaleString(navigator.language)}{' '}
                  ریال
                </td>
              </tr>
              <tr>
                <td>نحوه خرید</td>
                <td>{t('purchasePayType.' + purchase.purchasePayType)}</td>
              </tr>
              <tr>
                <td className='text-right'>نام فروشگاه</td>
                <td>{purchase.acceptorName}</td>
              </tr>
              <tr>
                <td className='text-right'>شماره پیگیری</td>
                <td>{purchase.id}</td>
              </tr>
              {purchase.trackingNumber && (
                <tr>
                  <td className='text-right'>شماره پیگیری مرجع</td>
                  <td>{purchase.trackingNumber}</td>
                </tr>
              )}
              {purchase.refunded && (
                <tr className='text-danger'>
                  <td className='text-right'>وضعیت</td>
                  <td>عودت داده شده</td>
                </tr>
              )}
              {purchase.refundReason && (
                <tr className='text-danger'>
                  <td className='text-right'>دلیل عودت</td>
                  <td>{t(`refundReason.${purchase.refundReason}`)}</td>
                </tr>
              )}
              {purchase.refundDate && (
                <tr className='text-danger'>
                  <td className='text-right'>تاریخ عودت</td>
                  <td>{purchase.refundDate}</td>
                </tr>
              )}
              {purchase.refunder && (
                <tr>
                  <td className='text-right'>عودت دهنده</td>
                  <td>{purchase.refunder}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default PurchaseAcceptorReceipt
