import React, { useState, FC, useEffect } from 'react'
import { IonButton, IonGrid, IonIcon, IonItem, IonLabel, IonSpinner, IonText } from '@ionic/react'
import { add } from 'ionicons/icons'
import { CurrencyIonInput, MobileIonInput, useToast, ApiService } from '../../ayria'
import { AcceptorSettings } from '../../models'
import { connect } from '../../data'

interface OwnProps {
  onSuccess: Function
}
interface StateProps {
  settings: AcceptorSettings
  acceptorCode: string
}
interface PurchaseLinkFormProps extends OwnProps, StateProps {}

const PurchaseLinkForm: FC<PurchaseLinkFormProps> = ({ settings, acceptorCode, onSuccess }) => {
  const [mobile, setMobile] = useState()
  const [amount, setAmount] = useState(0)
  const [amounts, setAmounts] = useState([{ amount: 0 }])
  const [render, setRender] = useState(false)
  const [inprogress, setInprogress] = useState(false)
  const toast = useToast()
  const api = new ApiService()

  const send = async () => {
    setInprogress(true)
    if (!mobile || (mobile + '').toString().length !== 11) {
      setInprogress(false)
      toast.error('شماره همراه درست نیست')
      return
    }
    if (!amount) {
      setInprogress(false)
      toast.error('مبلغ را وارد کنید')
      return
    }
    const res: any = await api.post(`/acceptor-roles/${acceptorCode}/send-purchase-link`, { mobile, amount })
    if (res.ok) {
      toast.success('لینک پرداخت با موفقیت ارسال شد')
      onSuccess()
    } else {
      toast.error(`ارسال لینک پرداخت با خطا مواجه شد ${res.status}`)
    }
    setInprogress(false)
  }

  const addAmount = () => {
    setAmounts([...amounts, { amount: 0 }])
  }
  const setAmountIndex = (value: any, idx: number) => {
    setRender(false)
    amounts[idx].amount = value
    setTimeout(() => {
      setRender(true)
    }, 100)
  }

  useEffect(() => {
    setAmount(amounts.reduce((a: number, b: any) => +a + +b.amount, 0))
  }, [amounts, render])

  return (
    <IonGrid>
      <IonItem>
        <IonLabel position='stacked'>{'شماره همراه'}</IonLabel>
        <MobileIonInput value={mobile} onValueChange={(value: any) => setMobile(value)} />
      </IonItem>
      {amounts.map((a: any, idx: number) => (
        <IonItem key={a + ' ' + idx}>
          {idx !== amounts.length - 1 && (
            <IonButton
              slot='end'
              fill='clear'
              style={{ marginTop: '30px', minWidth: '50px' }}
              tabIndex={-1}></IonButton>
          )}
          {idx === amounts.length - 1 && (
            <IonButton slot='end' fill='clear' style={{ marginTop: '30px' }} onClick={addAmount}>
              <IonIcon slot='icon-only' icon={add}></IonIcon>
            </IonButton>
          )}
          <IonLabel position='stacked'>{'مبلغ'}</IonLabel>
          <CurrencyIonInput onValueChange={(value: any) => setAmountIndex(value, idx)} />
        </IonItem>
      ))}
      <br />
      <div style={{ minHeight: '30px' }}>
        {!!amount && (
          <>
            <IonText color='medium'>{'جمع پرداختی '}</IonText>{' '}
            <strong>{(+amount).toLocaleString(navigator.language)}</strong> <IonText color='medium'>{' ریال '}</IonText>
          </>
        )}
      </div>
      <br />
      <IonButton onClick={send} disabled={inprogress}>
        {'ارسال لینک'}
        {inprogress && <IonSpinner slot='start' />}
      </IonButton>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    settings: state.shop.settings,
    acceptorCode: state.shop.acceptorCode,
  }),
  component: PurchaseLinkForm,
})
