import React, { useEffect } from 'react'
// import { IonRouterContext } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { Auth, connect, setIsLoggedIn, setUsername, setLoggedUser } from '../ayria'

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn
  setUsername: typeof setUsername
  setLoggedUser: typeof setLoggedUser
}

interface OwnProps {
  withLogout?: boolean
}

interface RedirectToLoginProps extends DispatchProps, OwnProps {}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ setIsLoggedIn, setUsername, setLoggedUser, withLogout }) => {
  const auth = new Auth()
  // const ionRouterContext = useContext(IonRouterContext)
  const history = useHistory()

  const logout = async () => {
    await auth.logout()
    await setIsLoggedIn(false)
    await setUsername(undefined)
    await setLoggedUser(undefined)
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  useEffect(() => {
    if (withLogout) {
      logout()
    } else {
      //if (ionRouterContext.routeInfo.pathname !== '/login') {
      if (history?.location?.pathname !== '/login') {
        history.push('/login')
      }
    }
    console.log('RedirectToLogin')
  }, []) // eslint-disable-line
  return null
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    setLoggedUser,
  },
  component: RedirectToLogin,
})
