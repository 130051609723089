import React, { useState } from 'react'
import { LatLng, LatLngTuple, LatLngBounds } from 'leaflet'

const LayerContext: any = React.createContext({})

const LayerContextProvider = ({ children }: any) => {
  const [center, setCenter] = useState<LatLngTuple>([35.7, 51.4])
  const [ccenter, setCcenter] = useState<LatLngTuple>()
  const [currentLocation, setCurrentLocation] = useState<LatLngTuple>()
  const [address, setAddress] = useState<any>()
  const [zoom, setZoom] = useState(12)
  const [point, setPoint] = useState<LatLng>(undefined)
  const [areas, setAreas] = useState<any>([])
  const [activate, setActivate] = useState(false)
  const [reverseSearchInprogress, setReverseSearchInprogress] = useState(false)
  const [bounds, setBounds] = useState<LatLngBounds>()

  const defaultValue = {
    center,
    setCenter,
    ccenter,
    setCcenter,
    zoom,
    setZoom,
    point,
    setPoint,
    areas,
    setAreas,
    activate,
    setActivate,
    address,
    setAddress,
    reverseSearchInprogress,
    setReverseSearchInprogress,
    currentLocation,
    setCurrentLocation,
    bounds,
    setBounds,
  }

  return <LayerContext.Provider value={defaultValue}>{children}</LayerContext.Provider>
}

export { LayerContext, LayerContextProvider }
