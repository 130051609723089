import React, { useState, useEffect, useContext, lazy } from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { withTranslation } from 'react-i18next'
import { getSortState } from '../../../../utility/jhipster'
import api from '../../../../api/Api'
import { toGregorian } from '../../../../utility/calendar'
import useDebounce from '../../../../utility/use-debounce'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../../utility/pagination'
import ItemCount from '../../../../utility/item-count'
import './wallet-events.css'
import WalletReceipt from '../../../elements/WalletReceipt/WalletReceipt'
import { AcceptorContext } from '../../../structures/contexts/AcceptorContext'
import WalletEventRefType from '../../../units/wallet/WalletEventRefType'
import { CustomModal, isDesktop, isMobile } from '../../../../ayria'

const DatePicker = lazy(() => import('react-modern-calendar-datepicker'))

const AmountView = ({ type, amount }) => (
  <>
    <span className={type === 'DEPOSIT' ? 'text-success' : ''}>{amount.toLocaleString(navigator.language)}</span>
    {type === 'WITHDRAW' && <span className={'text-danger'}>-</span>}
  </>
)

const WalletEvents = ({ isAcceptor, t }) => {
  const [walletEvents, setWalletEvents] = useState([])
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))
  const [totalItems, setTotalItems] = useState(0)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [walletEvent, setWalletEvent] = useState()
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [acceptorContext] = useContext(AcceptorContext)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 500)

  async function fetchWalletEvents(page, size, sort, t) {
    try {
      const res = await api.get(
        `/user/wallet-events/${isAcceptor ? acceptorContext.current.code : '0'}${
          sort ? `?page=${page}&size=${size}&sort=${sort}` : ''
        }${fromDate ? `&fromDate=${toGregorian(fromDate)}` : ''}${toDate ? `&toDate=${toGregorian(toDate)}` : ''}${
          searchText ? `&search.contains=${searchText}` : ''
        }`
      )
      if (res.ok) {
        setWalletEvents(res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
  }

  const getAllEvents = () =>
    fetchWalletEvents(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllEvents()

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [
    pagination.activePage,
    pagination.order,
    pagination.sort,
    fromDate,
    toDate,
    acceptorContext.currentIndex,
    debouncedSearchText,
  ])

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <>
      {isDesktop() ? (
        <div>
          <div className='row mt-4 align-items-center'>
            <div className='col-3'>
              <input onChange={(e) => setSearchText(e.target.value)} placeholder={'جستجو'} className='form-control' />
            </div>
            <div className='form-group col-3 text-left'>
              <DatePicker
                value={fromDate}
                onChange={setFromDate}
                inputPlaceholder='از تاریخ'
                shouldHighlightWeekends
                locale='fa'
                calendarPopperPosition={'bottom'}
              />
            </div>
            <div className='form-group col-3'>
              <DatePicker
                value={toDate}
                onChange={setToDate}
                inputPlaceholder='تا تاریخ'
                shouldHighlightWeekends
                locale='fa'
                calendarPopperPosition={'bottom'}
              />
            </div>
          </div>
          <div>
            {/*{isDesktop() && (*/}
            {/*  <IonRow>*/}
            {/*    <IonCol size='1'></IonCol>*/}
            {/*    <IonCol>*/}
            {/*      <b>{'توضیحات'}</b>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol size='2'>*/}
            {/*      <b>{'تاریخ'}</b>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol size='2' className='ion-text-left'>*/}
            {/*      <b>{'واریز'}</b>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol size='2' className='ion-text-left'>*/}
            {/*      <b>{'برداشت'}</b>*/}
            {/*    </IonCol>*/}
            {/*    <IonCol size='2' className='ion-text-left'>*/}
            {/*      <b>{'مانده'}</b>*/}
            {/*    </IonCol>*/}
            {/*  </IonRow>*/}
            {/*)}*/}

            <div className='col-12 mt-4' style={{ overflowX: 'auto' }}>
              <table className='customTableTwo'>
                <thead>
                  <tr>
                    <th>توضیحات</th>
                    <th>تاریخ</th>
                    <th>واریز</th>
                    <th>برداشت</th>
                    <th>مانده</th>
                  </tr>
                </thead>
                <tbody>
                  {walletEvents.map((walletEvent, index) => {
                    return (
                      <tr
                        key={walletEvent.amount + '-' + index}
                        onClick={(e) => {
                          setWalletEvent(walletEvent)
                          setShowReceiptModal(true)
                        }}>
                        {isMobile() && (
                          <div
                            key={walletEvent.createdAt + '-' + index}
                            onClick={(e) => {
                              setWalletEvent(walletEvent)
                              setShowReceiptModal(true)
                            }}
                            className='flex-container border-bottom pointer'>
                            <span className='flex-1'>
                              <WalletEventRefType walletEvent={walletEvent} />
                            </span>
                            <span className='flex-3'>
                              <p className='ellipsis m-0' style={{ width: isMobile() ? '160px' : '500px' }}>
                                {walletEvent.actionDescription}
                                <br />
                                {walletEvent.createdAt}
                              </p>
                            </span>
                            <span className={'flex-2 text-left'}>
                              <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                              <br />
                              <span style={{ fontSize: '80%' }}>
                                {walletEvent.balance.toLocaleString(navigator.language)}
                              </span>
                            </span>
                          </div>
                        )}
                        {isDesktop() && (
                          <>
                            <td>{walletEvent.actionDescription}</td>
                            <td>{walletEvent.createdAt}</td>
                            <td>
                              {' '}
                              {walletEvent.actionType === 'DEPOSIT' && (
                                <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                              )}
                            </td>
                            <td>
                              {walletEvent.actionType === 'WITHDRAW' && (
                                <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                              )}
                            </td>

                            <td>{walletEvent.balance.toLocaleString(navigator.language)}</td>
                          </>
                          // <IonRow
                          //   className='pb-1'
                          //   style={{ borderBottom: '0.5px solid #cccccc' }}
                          //   onClick={(e) => {
                          //     setWalletEvent(walletEvent)
                          //     setShowReceiptModal(true)
                          //   }}>
                          //   <IonCol size='1'>
                          //     <WalletEventRefType walletEvent={walletEvent} />
                          //   </IonCol>
                          //   <IonCol>{walletEvent.actionDescription}</IonCol>
                          //   <IonCol size='2'>{walletEvent.createdAt}</IonCol>
                          //   <IonCol size='2' className='ion-text-left'>
                          //     {walletEvent.actionType === 'DEPOSIT' && (
                          //       <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                          //     )}
                          //   </IonCol>
                          //   <IonCol size='2' className='ion-text-left'>
                          //     {walletEvent.actionType === 'WITHDRAW' && (
                          //       <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                          //     )}
                          //   </IonCol>
                          //   <IonCol size='2' className='ion-text-left'>
                          //     {walletEvent.balance.toLocaleString(navigator.language)}
                          //   </IonCol>
                          // </IonRow>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/*<div className='row text-center'>*/}
          {/*  <div className='col'>*/}
          {/*    <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='row text-center'>*/}
          {/*  <div className='col'></div>*/}
          {/*  <div className='col' align='center'>*/}
          {/*    <AyriaPagination*/}
          {/*      activePage={pagination.activePage}*/}
          {/*      onSelect={handlePagination}*/}
          {/*      maxButtons={5}*/}
          {/*      itemsPerPage={pagination.itemsPerPage}*/}
          {/*      totalItems={totalItems}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className='col'></div>*/}
          {/*</div>*/}

          <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
            <div className='row text-center'>
              <div className='col'>
                <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
              </div>
            </div>

            <AyriaPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={totalItems}
            />
          </div>

          <CustomModal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title='رسید تراکنش'
            content={<WalletReceipt walletEvent={walletEvent} />}
          />
        </div>
      ) : (
        <div>
          <div className='row'>
            <div className='col-12'>
              <input onChange={(e) => setSearchText(e.target.value)} placeholder={'جستجو'} className='form-control' />
            </div>
            <div className='form-group col-6 text-left'>
              <DatePicker
                value={fromDate}
                onChange={setFromDate}
                inputPlaceholder='از تاریخ'
                shouldHighlightWeekends
                locale='fa'
                calendarPopperPosition={'bottom'}
              />
            </div>
            <div className='form-group col-6'>
              <DatePicker
                value={toDate}
                onChange={setToDate}
                inputPlaceholder='تا تاریخ'
                shouldHighlightWeekends
                locale='fa'
                calendarPopperPosition={'bottom'}
              />
            </div>
          </div>
          <div>
            {isDesktop() && (
              <IonRow>
                <IonCol size='1'></IonCol>
                <IonCol>
                  <b>{'توضیحات'}</b>
                </IonCol>
                <IonCol size='2'>
                  <b>{'تاریخ'}</b>
                </IonCol>
                <IonCol size='2' className='ion-text-left'>
                  <b>{'واریز'}</b>
                </IonCol>
                <IonCol size='2' className='ion-text-left'>
                  <b>{'برداشت'}</b>
                </IonCol>
                <IonCol size='2' className='ion-text-left'>
                  <b>{'مانده'}</b>
                </IonCol>
              </IonRow>
            )}
            {walletEvents.map((walletEvent: any, index: number) => {
              return (
                <div key={walletEvent.amount + '-' + index}>
                  {isMobile() && (
                    <div
                      key={walletEvent.createdAt + '-' + index}
                      onClick={(e) => {
                        setWalletEvent(walletEvent)
                        setShowReceiptModal(true)
                      }}
                      className='flex-container border-bottom pointer'>
                      <span className='flex-1'>
                        <WalletEventRefType walletEvent={walletEvent} />
                      </span>
                      <span className='flex-3'>
                        <p className='ellipsis m-0' style={{ width: isMobile() ? '160px' : '500px' }}>
                          {walletEvent.actionDescription}
                          <br />
                          {walletEvent.createdAt}
                        </p>
                      </span>
                      <span className={'flex-2 text-left'}>
                        <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                        <br />
                        <span style={{ fontSize: '80%' }}>
                          {walletEvent.balance.toLocaleString(navigator.language)}
                        </span>
                      </span>
                    </div>
                  )}
                  {isDesktop() && (
                    <IonRow
                      key={walletEvent.amount + '-' + index}
                      className='pb-1'
                      style={{ borderBottom: '0.5px solid #cccccc' }}
                      onClick={(e) => {
                        setWalletEvent(walletEvent)
                        setShowReceiptModal(true)
                      }}>
                      <IonCol size='1'>
                        <WalletEventRefType walletEvent={walletEvent} />
                      </IonCol>
                      <IonCol>{walletEvent.actionDescription}</IonCol>
                      <IonCol size='2'>{walletEvent.createdAt}</IonCol>
                      <IonCol size='2' className='ion-text-left'>
                        {walletEvent.actionType === 'DEPOSIT' && (
                          <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                        )}
                      </IonCol>
                      <IonCol size='2' className='ion-text-left'>
                        {walletEvent.actionType === 'WITHDRAW' && (
                          <AmountView amount={walletEvent.amount} type={walletEvent.actionType} />
                        )}
                      </IonCol>
                      <IonCol size='2' className='ion-text-left'>
                        {walletEvent.balance.toLocaleString(navigator.language)}
                      </IonCol>
                    </IonRow>
                  )}
                </div>
              )
            })}
          </div>
          <div className='row text-center'>
            <div className='col'>
              <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
            </div>
          </div>
          <div className='row text-center'>
            <div className='col'></div>
            <div className='col ion-text-center'>
              <AyriaPagination
                activePage={pagination.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
            <div className='col'></div>
          </div>
          <CustomModal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title='رسید تراکنش'
            content={<WalletReceipt walletEvent={walletEvent} />}
          />
        </div>
      )}
    </>
  )
}

export default withTranslation()(WalletEvents)
