import React, { useState, useEffect, lazy } from 'react'
import { print } from 'ionicons/icons'
import { IonGrid, IonCol, IonRow, IonButton, IonIcon } from '@ionic/react'
import { Modal } from '../../ayria'

const AyriaPayment = lazy(() => import('../../components/elements/AyriaPayment/AyriaPayment'))

interface OwnProps {
  factor: any
}
interface SimpleFactorProps extends OwnProps {}

const SimpleFactor: React.FC<SimpleFactorProps> = ({ factor }) => {
  const [printType, setPrintType] = useState(undefined)
  const [item] = useState(factor)
  const [showReceiptModal, setShowReceiptModal] = useState(false)

  useEffect(() => {
    if (!!printType) {
      setShowReceiptModal(true)
      setTimeout(() => {
        setPrintType(null)
      }, 300)
    }
  }, [printType])

  return (
    <>
      {!!item.trackingNumber && (
        <div className='non-printable'>
          <IonGrid>
            <IonRow>
              <IonCol className='ion-text-center'>
                <span>{`«صورت حساب `}</span>
                <strong>{item?.trackingNumber}</strong>
                {'»'}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {item?.department === 'RESTAURANT' && (
                  <IonButton onClick={() => setPrintType('simple')}>
                    <IonIcon icon={print} slot='start' />
                    {'چاپ آشپزخانه'}
                  </IonButton>
                )}
                <IonButton onClick={() => setPrintType('advance')}>
                  <IonIcon icon={print} slot='start' />
                  {'چاپ'}
                </IonButton>
                {false && item?.department === 'RESTAURANT' && (
                  <IonButton onClick={() => setPrintType('both')}>
                    <IonIcon icon={print} slot='start' />
                    {'چاپ هردو'}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{'پرداخت کننده'}</IonCol>
              <IonCol>{item.payerMobile}</IonCol>
              <IonCol>{item.payerName}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12'>
                <IonRow style={{ borderBottom: '1px solid', borderTop: '1px solid' }}>
                  <IonCol>
                    <strong>{'نام محصول'}</strong>
                  </IonCol>
                  <IonCol size='3'>
                    <strong>{'تعداد'}</strong>
                  </IonCol>
                  <IonCol className='ion-text-end'>
                    <strong>{'مبلغ'}</strong>(ریال)
                  </IonCol>
                </IonRow>
                {item?.kalas?.map((k, i) => (
                  <IonRow key={k.id + ' ' + i}>
                    <IonCol>{k.name}</IonCol>
                    <IonCol size='2'>{k.count}</IonCol>
                    <IonCol className='ion-text-end'>{k.price.toLocaleString(navigator.language)}</IonCol>
                  </IonRow>
                ))}
                <IonRow>
                  <IonCol className='ion-text-end'>{'جمع'}</IonCol>
                  <IonCol className='ion-text-end'>{item?.unitedAmount?.toLocaleString(navigator.language)}</IonCol>
                </IonRow>
                {item?.shippingFee > 0 && (
                  <IonRow>
                    <IonCol className='ion-text-end'>{'هزینه پیک'}</IonCol>
                    <IonCol className='ion-text-end'>{item?.shippingFee.toLocaleString(navigator.language)}</IonCol>
                  </IonRow>
                )}
                {item?.tax > 0 && (
                  <IonRow>
                    <IonCol className='ion-text-end'>{'مالیات بر ارزش افزوده'}</IonCol>
                    <IonCol className='ion-text-end'>{item?.tax.toLocaleString(navigator.language)}</IonCol>
                  </IonRow>
                )}
                {item?.tip > 0 && (
                  <IonRow>
                    <IonCol className='ion-text-end'>{'انعام'}</IonCol>
                    <IonCol className='ion-text-end'>
                      {item.tip.toLocaleString(navigator.language)} {'ریال'} {!!item?.waiter ? `(${item.waiter})` : ''}
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol className='ion-text-end'>
                    <strong>{'قابل پرداخت'}</strong>
                  </IonCol>
                  <IonCol className='ion-text-end'>
                    <strong>
                      {item.customerAmountActual.toLocaleString(navigator.language)} {'ریال'}
                    </strong>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton onClick={() => setShowReceiptModal(true)}>{'ویرایش'}</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
      <Modal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        title={' صورتحساب ' + (item.trackingNumber ? item.trackingNumber : item.purchaseId)}
        isPlatformTheme={true}
        contentUsePadding={false}
        onClosed={() => {
          setShowReceiptModal(false)
        }}
        id='ayriaPaymentModalAcceptor'
        content={
          <AyriaPayment
            payment={item}
            acceptorCode={item.payeeReferralCode}
            onSuccess={() => {
              setShowReceiptModal(false)
            }}
            showCashierActions={true}
            autoPrint={printType}
          />
        }
      />
    </>
  )
}

export default SimpleFactor
