import React, { useEffect, useState } from 'react'
import { IonAlert } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BillPaymentGhabzino from './bill-payment-ghabzino'
import BillPayment from '../../screens/building-service/BillPayment'
import MobileCharge from './mobile-charge'
import InternetCharge from './internet-charge'
import WalletBalance from '../../units/wallet/WalletBalance'
import { Modal, CustomModal } from '../../../ayria'
import './payment-service.css'

interface OwnProps {
  ScrollTop: number
  ShowWalletBalance?: boolean
}

interface PaymentServiceProps extends OwnProps {}
const PaymentService: React.FC<PaymentServiceProps> = ({ ScrollTop = 0, ShowWalletBalance = true }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [showBillPaymentModal1, setShowBillPaymentModal1] = useState(false)
  const [showBillPaymentModal2, setShowBillPaymentModal2] = useState(false)
  const [showMobileChargeModal, setShowMobileChargeModal] = useState(false)
  const [showInternetChargeModal, setShowInternetChargeModal] = useState(false)
  const [showBillConfirm, setShowBillConfirm] = useState(false)
  const [oldScrollTop, setOldScrollTop] = useState(0)
  const [isScrollToTop, setIsScrollToTop] = useState(true)

  useEffect(() => {
    setOldScrollTop(ScrollTop)
    setIsScrollToTop(ScrollTop < 10 || oldScrollTop > ScrollTop)
    console.log(ScrollTop)
  }, [ScrollTop]) // eslint-disable-line

  return (
    <div className='text-center'>
      {ShowWalletBalance && (
        <div
          className='display-inline mt-0 payment-service-row align-items-center'
          onClick={() => history.push('/tabs/wallet')}>
          <div className={'col-md-6 pointer'}>
            <div className={'m-2'}>{' موجودی کیف پول(ریال)'}</div>
            <h3>
              <WalletBalance showImg={false} />
            </h3>
          </div>
          <div className={'col-md-6 mr-2 pointer'}>
            <img alt='' src='/assets/images/payment-wallet.png' className='payment-image' />
          </div>
        </div>
      )}
      {isScrollToTop && (
        <div className={` display-inline mt-2`}>
          <div className={'col-md-4 payment-service-row pointer'} onClick={() => setShowMobileChargeModal(true)}>
            <img alt='' src='/assets/images/mobile.png' className='payment-image' />
            <div className={'m-2 '}>{t('payments.mobileCharge')}</div>
          </div>
          <div className={'col-md-4 payment-service-row mx-2 pointer'} onClick={() => setShowInternetChargeModal(true)}>
            <img alt='' src='/assets/images/internet.png' className='payment-image' />
            <div className={'m-2 '}>{t('payments.internetCharge')}</div>
          </div>
          <div className={'col-md-4 payment-service-row pointer'} onClick={() => setShowBillConfirm(true)}>
            <img alt='' src='/assets/images/bill.png' className='payment-image' />
            <div className={'m-2 '}>{t('payments.payReceipt')}</div>
            <IonAlert
              isOpen={showBillConfirm}
              onDidDismiss={() => setShowBillConfirm(false)}
              header={'روش پرداخت قبض'}
              subHeader={''}
              message={'لطفا روش پرداخت قبض را انتخاب کنید'}
              buttons={[
                {
                  text: 'کارت بانکی',
                  cssClass: 'light',
                  handler: (blah) => {
                    setShowBillPaymentModal1(true)
                  },
                },
                {
                  text: 'کیف پول',
                  handler: () => {
                    setShowBillPaymentModal2(true)
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
      <CustomModal
        title='پرداخت قبض'
        showModal={showBillPaymentModal1}
        toggle={setShowBillPaymentModal1}
        content={<BillPaymentGhabzino />}
      />
      <Modal
        title='مدیریت پرداخت قبض'
        showModal={showBillPaymentModal2}
        toggle={setShowBillPaymentModal2}
        onClosed={() => setShowBillPaymentModal2(false)}
        content={<BillPayment />}
      />
      <CustomModal
        title='شارژ شماره همراه'
        showModal={showMobileChargeModal}
        toggle={setShowMobileChargeModal}
        onClosed={() => setShowMobileChargeModal(false)}
        content={<MobileCharge />}
      />
      <CustomModal
        title={t('payments.internetCharge')}
        showModal={showInternetChargeModal}
        toggle={setShowInternetChargeModal}
        onClosed={() => setShowInternetChargeModal(false)}
        content={<InternetCharge />}
      />
    </div>
  )
}

export default PaymentService
