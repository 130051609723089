import React, { useState, useEffect, useContext } from 'react'
import { IonToggle, IonButton, IonItem, IonLabel, IonPopover, IonSpinner } from '@ionic/react'
import { IonInput } from '@ionic/react'
import { mailOutline, mailOpenOutline, ellipsisVertical } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { getSortState } from '../../../utility/jhipster'
import { useTranslation } from 'react-i18next'
import { AyriaPagination, CustomModal, ItemCount, ApiService, AyriaIcon } from '../../../ayria'
import { useToast, NotificationContext } from '../../../ayria'

const Notifications = () => {
  const api = new ApiService()
  const { t } = useTranslation()
  const history = useHistory()
  const toast = useToast()
  const [notifications, setNotifications] = useState([])
  const [pagination, setPagination] = useState(getSortState(window.location, 5))
  const [totalItems, setTotalItems] = useState(0)
  const [notificationContext, setNotificationContext] = useContext(NotificationContext)
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [item, setItem] = useState({})
  const [unreadStatus, setUnreadStatus] = useState(false)
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })
  const [inprogress, setInprogress] = useState(false)
  const [body, setBody] = useState()
  const [bodyChanged] = useDebounce(body, 1000)

  async function fetchNotifications(page, size, sort) {
    const res = await api.get(
      `/user/notifications?page=${page}&size=${size}&sort=${sort}${
        unreadStatus ? `&readed.equals=${!unreadStatus}` : ''
      }${!!body ? `&body.contains=${body}` : ''}`
    )
    if (res.ok) {
      setNotifications(res.data)
      setTotalItems(res.headers['x-total-count'])
    }
  }
  async function markAsRead(notification) {
    const res = await api.post(`/user/notifications/${notification.id}/read`)
    if (res.ok) {
      setNotificationContext((state) => ({
        ...state,
        badge: notificationContext.badge ? notificationContext.badge - 1 : 0,
      }))
    }
    notification.readed = true
  }
  async function markAsUnread(notification) {
    const res = await api.post(`/user/notifications/${notification.id}/unread`)
    if (res.ok) {
      setNotificationContext((state) => ({
        ...state,
        badge: notificationContext.badge ? notificationContext.badge + 1 : 1,
      }))
    }
    notification.readed = false
  }
  const markAllAsRead = async () => {
    setInprogress(true)
    const res = await api.post(`/user/notifications/markAllAsRead`)
    if (res.ok) {
      setShowPopover({ showPopover: false, event: undefined })
      sortNotifications()
      toast.success('همه پیام ها به وضعیت خوانده شده درآمدند')
      setNotificationContext((state) => ({ ...state, badge: 0 }))
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const sortNotifications = () =>
    fetchNotifications(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)

  const navigateTo = (path) => {
    history.push(path)
    setShowReceiptModal(false)
  }

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
  }, []) // eslint-disable-line

  useEffect(() => {
    sortNotifications()
    // eslint-disable-next-line
  }, [pagination.activePage, pagination.order, pagination.sort, notificationContext.badge, unreadStatus, bodyChanged])

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <div>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        <IonButton fill='clear' disabled={inprogress} onClick={markAllAsRead}>
          {'همه را خواندم'}
          {inprogress && <IonSpinner slot='start' />}
        </IonButton>
      </IonPopover>
      <div>
        <IonItem>
          <IonLabel>{t('notification.view_unread')}</IonLabel>
          <IonToggle slot='start' checked={unreadStatus} onClick={() => setUnreadStatus(!unreadStatus)} />
          <IonButton
            fill='clear'
            onClick={(e: any) => {
              e.persist()
              setShowPopover({ showPopover: true, event: e })
            }}>
            <AyriaIcon icon={ellipsisVertical} />
          </IonButton>
        </IonItem>
        <IonItem>
          <IonInput value={body} onIonChange={(e) => setBody(e.detail.value)} placeholder='جستجو' />
        </IonItem>
      </div>
      {notifications.map((notification) => {
        return (
          <div
            key={notification.id}
            onClick={(e) => {
              markAsRead(notification)
              setItem(notification)
              setShowReceiptModal(true)
            }}
            className={'flex-container border-bottom pointer ion-padding' + (notification.readed ? '' : ' text-bold')}>
            <p className='ellipsis m-0' style={{ width: '90vw', whiteSpace: 'pre' }}>
              {notification.body}
              <br />
              {notification.createdDate}
            </p>
          </div>
        )
      })}
      <div className='row text-center'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col' align='center'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
      <CustomModal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        title={'پیام'}
        content={
          <div>
            <table className='table'>
              <thead></thead>
              <tbody>
                <tr>
                  <td>{t('notification.body')}</td>
                  <td>
                    <div className='pre-wrap'>{item.body}</div>
                  </td>
                </tr>
                <tr>
                  <td>{t('notification.createdDate')}</td>
                  <td>
                    {item.createdTime} {item.createdDate}
                  </td>
                </tr>
                <tr>
                  <td>{t('notification.link')}</td>
                  <td>{item.path && <IonButton onClick={() => navigateTo(item.path)}>{'لینک'}</IonButton>}</td>
                </tr>
                <tr>
                  <td>{t('notification.read_status')}</td>
                  <td>
                    <span onClick={(e) => markAsRead(item)} className='pointer'>
                      {' '}
                      {!item.readed && <AyriaIcon icon={mailOutline} size='large' title={t('notification.unread')} />}
                    </span>
                    <span onClick={(e) => markAsUnread(item)} className='pointer'>
                      {' '}
                      {item.readed && (
                        <AyriaIcon icon={mailOpenOutline} size='large' title={t('notification.readed')} />
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      />
    </div>
  )
}
export default Notifications
