import React, { useState } from 'react'
import { business, settings } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { IonIcon, IonButton } from '@ionic/react'
import GroupPaymentRequestList from '../../../pages/payment/GroupPaymentRequestList'
import BillPayment from './BillPayment'
import { Modal } from '../../../ayria'

interface OwnProps {
  acceptorCode: string
}
interface BuildingServiceProps extends OwnProps {}

const BuildingService: React.FC<BuildingServiceProps> = ({ acceptorCode }) => {
  const [showGroupPaymentModal, setShowGroupPaymentModal] = useState(false)
  const [showBillPaymentModal, setShowBillPaymentModal] = useState(false)
  const history = useHistory()

  return (
    <div className='ion-text-center'>
      <div className='col-md-12 payment-service-row pointer' onClick={() => setShowGroupPaymentModal(true)}>
        <IonIcon size='large' icon={business}></IonIcon>
        <div className='m-2'>{' شارژ ساختمان'}</div>
      </div>
      <div className='display-inline mt-2'>
        <div className='col-md-6 payment-service-row pointer' onClick={() => setShowBillPaymentModal(true)}>
          <img alt='' src='/assets/images/bill.png' width='40px' />
          <div className='m-2'>{'پراخت قبض'}</div>
        </div>
        <div className={'col-md-6 payment-service-row mr-2 pointer'} onClick={() => history.push('/panel-settings')}>
          <IonIcon size='large' icon={settings} className='pointer-events-none'></IonIcon>
          <div className={'m-2 '}>{'تنظیمات'}</div>
        </div>
      </div>
      <Modal
        showModal={showGroupPaymentModal}
        toggle={setShowGroupPaymentModal}
        onClosed={() => setShowGroupPaymentModal(false)}
        content={<GroupPaymentRequestList acceptorCode={acceptorCode} showHeader={false} />}
        actionButtons={<IonButton onClick={() => setShowGroupPaymentModal(false)}>{'بستن'}</IonButton>}
      />
      <Modal
        showModal={showBillPaymentModal}
        toggle={setShowBillPaymentModal}
        onClosed={() => setShowBillPaymentModal(false)}
        content={<BillPayment acceptorCode={acceptorCode} />}
        actionButtons={<IonButton onClick={() => setShowBillPaymentModal(false)}>{'بستن'}</IonButton>}
      />
    </div>
  )
}

export default BuildingService
