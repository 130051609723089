import React from 'react'
import { API_BASE_URL } from '../../../config'

interface AcceptorLogoProps {
  referralCode: number
  width?: string
}

const AcceptorLogo: React.FC<AcceptorLogoProps> = ({ referralCode, width = '50px' }) => {
  return (
    <>
      {!!referralCode && (
        <img alt='logo' src={`${API_BASE_URL}/public/acceptors/logo/${referralCode}/referralCode`} width={width} />
      )}
    </>
  )
}

export default AcceptorLogo
