import React from 'react'
import { useEffect, useState } from 'react'
import api from '../../../api/Api'
import { useTranslation } from 'react-i18next'
import RatingStatitsticsProgressBars from './ratingStatisticsProgressBar'
import OveralScore from './overalScore'

export const calculateClassIdentifier = (rate: number) => {
  if (rate >= 0 && rate < 0.4) return 'danger'
  if (rate >= 0.4 && rate <= 0.6) return 'warning'
  if (rate > 0.6) return 'success'
}

interface MerchantProfileRatingStatisticsProps {
  merchant: any
}

const MerchantProfileRatingStatistics: React.FC<MerchantProfileRatingStatisticsProps> = ({ merchant }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [classIdentifier, setClassIdentifier] = useState<any>('')

  const calculateClassIdentifier = (rate: number) => {
    if (rate > 0.5) return 'success'
    if (rate < 0.5) return 'warning'
    if (rate < 0.5 && rate > 0) return 'danger'
  }

  const getData = async () => {
    setHasError(false)
    setIsLoading(true)
    try {
      const response: any = await api.get(`/public/acceptors/rate/${merchant.referralCode}`)

      setData(response.data)
      setClassIdentifier(calculateClassIdentifier(response.data.rate))
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {data && !hasError && !isLoading && (
        <div className={'p-2'}>
          {t('merchantProfile.rateDescription', {
            rate: data.rate * 10,
            maxScore: 10,
            total: data.total,
          })}
          <div className={'merchant-profile-statistics'}>
            <div className={'rating-statistics-progress-bar'}>
              <RatingStatitsticsProgressBars statistics={data} classIdentifier={classIdentifier} />
            </div>
            <div className={'overal-score'}>
              <OveralScore statistics={data} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MerchantProfileRatingStatistics
