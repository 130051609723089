import React, { useState, useEffect } from 'react'
import { IonButton, IonInput, IonLabel, IonTextarea, IonItem } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import Nickname from '../../elements/Nickname/Nickname'
import RateInput from './rate-input'
import QualityRateInput from './quality-rate-input'
import PriceRateInput from './price-rate-input'
import { useToast, CustomModal } from '../../../ayria'

const RateSinglePurchase = ({ activityItem, onCancel, onSuccess }) => {
  const { t } = useTranslation()
  const toaster = useToast()
  const [newRating, setNewRating] = useState({
    rateValue: activityItem.rate ? activityItem.rate : 0,
    rateReason: activityItem.rateComplaints ? activityItem.rateComplaints.split(',') : [],
  })
  const [selectedRate, setSelectedRate] = useState(0)
  const [hasNickname, setHasNickname] = useState(true)
  const [commentCounter, setCommentCounter] = useState(0)
  const [commentIsValid, setCommentIsValid] = useState(false)
  const [showNicknameModal, setShowNicknameModal] = useState(false)
  const [enableContinueButton, setEnableContinueButton] = useState(false)
  const reasons = [
    { key: 'BAD_SERVICE', label: 'سرویس دهی نامناسب ' },
    { key: 'BAD_PAYMENT_EXPERIANCE', label: 'مشکل دستگاه کارت خوان و سیستم پرداخت' },
  ]

  const handleOnChangeRateValue = (rate) => {
    newRating.rateValue = parseInt(rate)
    if (rate === 1) document.getElementById('RateReason').classList.remove('hidden')
    else document.getElementById('RateReason').classList.add('hidden')
    setSelectedRate(parseInt(rate))
  }

  const handleOnSelectReason = (event, value) => {
    let list = newRating.rateReason
    if (event.target.checked) {
      list.push(value)
    } else {
      list.splice(list.indexOf(value), 1)
    }
    setNewRating({ ...newRating, rateReason: list })
  }

  const submit = () => {
    api.fn
      .ratePurchase({
        id: activityItem.id,
        transactionId: activityItem.transactionId,
        referenceNumber: activityItem.referenceNumber,
        rate: newRating.rateValue,
        complaints: newRating.rateReason,
        comment: newRating.rateComment,
        title: newRating.rateTitle,
        qualityRate: newRating.qualityRate,
        priceRate: newRating.priceRate,
      })
      .then((res) => {
        if (res.ok) {
          onSuccess(res.data)
          toaster.success('ثبت نظر با موفقیت انجام شد')
        } else {
          toaster.error(res)
        }
      })
  }

  const handleSubmit = () => {
    if (hasNickname) {
      submit()
    } else {
      setShowNicknameModal(true)
    }
  }

  const isChecked = (complain) => {
    return newRating.rateReason.indexOf(complain) > -1
  }

  const updateComment = (text) => {
    setNewRating({ ...newRating, rateComment: text })
    setCommentIsValid(false)
    if (text) {
      setCommentCounter(text.length)
      if (text.length >= 25) {
        if (!newRating.rateTitle) {
          document.getElementById('title').value = text.substring(0, 25)
          setNewRating({ ...newRating, rateTitle: text.substring(0, 25) })
        }
        setCommentIsValid(true)
      }
    } else {
      setCommentCounter(0)
    }
  }

  const getNickname = async () => {
    const res = await api.get('/account/profile/nickname')
    if (res.ok && !res.data) {
      setHasNickname(false)
    }
  }

  useEffect(() => {
    getNickname()
  }, [])

  return (
    <div className='ion-padding'>
      <RateInput onRateChange={(rate) => handleOnChangeRateValue(rate)} />
      <div id='RateReason' className={'hidden'}>
        <span className={'text-center text-style1'}> چه مشکلی پیش آمد؟ </span>
        <ul className={'list-unstyled'}>
          {reasons.map((element, index) => (
            <li key={index}>
              <div className='form-check'>
                <input
                  type='checkbox'
                  id={element.key}
                  onClick={(event) => handleOnSelectReason(event, element.key)}
                  className={'form-check-input'}
                  defaultChecked={isChecked(element.key)}
                />
                <label className='form-check-label pr-4' htmlFor={element.key}>
                  {' '}
                  {element.label}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {!activityItem.rate && (
        <div>
          <IonItem>
            <IonLabel position='floating'>{t('write_your_opinion')}: </IonLabel>
            <IonTextarea
              onIonChange={(e) => updateComment(e.detail.value)}
              placeholder={t('your_tips_of_x_in_y', {
                x: activityItem.acceptorName,
                y: activityItem.purchaseDatePersian,
              })}
            />
          </IonItem>
          <span className={'left ' + (commentCounter < 25 ? 'text-danger' : 'text-success')}>{commentCounter}</span>
          <span className='left fa-xs pl-2'>{t('min_char_x', { x: 25 })}</span>
          <br />
          <IonItem>
            <IonLabel position='floating'>{t('write_title_of_your_opinion')}: </IonLabel>
            <IonInput
              onIonChange={(e) => setNewRating({ ...newRating, rateTitle: e.detail.value })}
              id='title'
              placeholder={t('label.title')}
            />
          </IonItem>
          <div>
            <QualityRateInput onRateChange={(rate) => setNewRating({ ...newRating, qualityRate: rate })} />
          </div>
          <div>
            <PriceRateInput onRateChange={(rate) => setNewRating({ ...newRating, priceRate: rate })} />
          </div>
        </div>
      )}
      {!activityItem.rate && (
        <div>
          <br />
          <IonButton
            disabled={!selectedRate || !commentIsValid}
            color='primary'
            className={'right'}
            onClick={handleSubmit}>
            {' '}
            {t('label.submit')}
          </IonButton>
          <br />
          <br />
        </div>
      )}
      <CustomModal
        showModal={showNicknameModal}
        toggle={setShowNicknameModal}
        title={t('label.nickname')}
        content={
          <div>
            <p>{t('your_nickname_is_not_set')}</p>
            <div className='form-group'>
              <Nickname onValidation={(value) => setEnableContinueButton(value)} />
            </div>
            <IonButton
              disabled={!enableContinueButton}
              className='right'
              onClick={(e) => {
                setShowNicknameModal(false)
                submit()
              }}>
              {t('label.continue')}
            </IonButton>
          </div>
        }
      />
    </div>
  )
}

export default RateSinglePurchase
