import React, { FC, useEffect, useState, useRef } from 'react'
import { IonGrid, IonRow, IonCol, IonAlert, IonButton } from '@ionic/react'
import { useParams } from 'react-router'
import { connect, KalaFastList, addDeskKala, AcceptorHasFeatureLoader } from '../../ayria'
import { ApiService, setAcceptorCode, AcceptorCurrentResolver, CustomCard } from '../../ayria'
import { setLastAyriaPayment, EventEmitter } from '../../ayria'
import WalletPayeeRequest from '../../components/elements/WalletPayeeRequest/WalletPayeeRequest'
import Factors from '../../components/elements/Factors/Factors'
import StatBar from './StatBar'
import SimpleFactor from './SimpleFactor'
import PurchaseLinkForm from './PurchaseLinkForm'
import '../../components/screens/panel/panel.scss'
import { Modal } from '../../utility'

interface OwnProps {}
interface StateProps {
  acceptorCode: string
  lastAyriaPayment: any
}
interface DispatchProps {
  addDeskKala: typeof addDeskKala
  setAcceptorCode: typeof setAcceptorCode
  setLastAyriaPayment: typeof setLastAyriaPayment
}
interface DeskProps extends OwnProps, StateProps, DispatchProps {}
const Desk: FC<DeskProps> = ({ addDeskKala, acceptorCode, setLastAyriaPayment, setAcceptorCode, lastAyriaPayment }) => {
  const { acceptorCodeParam } = useParams<any>()
  const api = new ApiService()
  const [acceptor, setAcceptor] = useState()
  const [reRender, setReRender] = useState(false)
  const [reRender2, setReRender2] = useState(false)
  const [initAcceptorResolver, setInitAcceptorResolver] = useState(false)
  const [showPriceAlert, setShowPriceAlert] = useState(false)
  const [tempKala, setTempKala] = useState<any>()
  const [pricePreview, setPricePreview] = useState<number | undefined>(0)
  const priceAlert = useRef<HTMLIonAlertElement>(null)
  const [showPurchaseLinkModal, setShowPurchaseLinkModal] = useState(false)

  const getAcceptor = async () => {
    const res: any = await api.get(`/acceptor-roles/current/${acceptorCode}`)
    setAcceptor(res.ok ? res.data : null)
  }
  const handleOnSelectKala = async (kala: any) => {
    if (!kala?.price) {
      setTempKala(kala)
      setShowPriceAlert(true)
    } else {
      await handleOnSelectKalaForReal(kala)
    }
  }
  const handleOnSelectKalaForReal = async (kala: any) => {
    await addDeskKala(kala)
  }
  const handleFactorSelect = async (factor: any) => {
    setReRender2(true)
    await setLastAyriaPayment(factor)
  }

  useEffect(() => {
    if (!!acceptorCode) {
      getAcceptor()
    }
  }, [acceptorCode]) // eslint-disable-line

  useEffect(() => {
    if (reRender) {
      setTimeout(() => {
        setReRender(false)
      }, 300)
    }
    if (reRender2) {
      setTimeout(() => {
        setReRender2(false)
      }, 300)
    }
  }, [reRender, reRender2])

  useEffect(() => {
    if (!!acceptorCodeParam) {
      setAcceptorCode(acceptorCodeParam)
    } else if (!acceptorCode) {
      setInitAcceptorResolver(true)
    }
  }, [acceptorCodeParam]) // eslint-disable-line

  useEffect(() => {
    EventEmitter.subscribe('NEW_PAYEE_REQUEST', () => {
      setReRender2(true)
    })
  }, []) // eslint-disable-line

  return (
    <IonGrid>
      {initAcceptorResolver && <AcceptorCurrentResolver />}
      {!!acceptorCode && <AcceptorHasFeatureLoader acceptorCode={acceptorCode} />}
      <StatBar isPanel={false} />
      <IonRow>
        <IonCol
          size-xl='7'
          size-lg='6'
          size-md='6'
          size-sm='6'
          size-xs='6'
          style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <CustomCard
            noMargin={true}
            noPadding={true}
            noTitleMarginBottom={true}
            html={!!acceptorCode && <KalaFastList acceptorCode={acceptorCode} onSelect={handleOnSelectKala} />}
          />
        </IonCol>
        <IonCol size-xl='5' size-lg='6' size-md='6' size-sm='6' size-xs='6'>
          {!reRender && !!acceptorCode && (
            <CustomCard
              noMargin={true}
              noPadding={true}
              noTitleMarginBottom={true}
              cssClass='border-color-07'
              html={
                <>
                  <div className='ion-text-center'>
                    <span
                      className='flex'
                      style={{ borderBottom: '1px solid', width: '200px', display: 'inline-block' }}>
                      {'صورتحساب'}
                    </span>
                  </div>
                  <WalletPayeeRequest
                    acceptorCode={acceptorCode}
                    onSuccess={() => setReRender(true)}
                    showPreviousRequests={false}
                    showAddKalaSuggest={true}
                  />
                </>
              }
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='5'>
          <IonRow>
            <IonCol>
              <CustomCard
                noMargin={true}
                noPadding={true}
                noTitleMarginBottom={true}
                html={
                  <IonButton color='success' onClick={() => setShowPurchaseLinkModal(true)}>
                    {'ارسال لینک پرداخت سریع'}
                  </IonButton>
                }
              />
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='7'>
          <IonRow>
            <IonCol size='6'>
              <CustomCard
                noMargin={true}
                noPadding={true}
                noTitleMarginBottom={true}
                html={
                  <>
                    <div className='ion-text-center'>{'«صورتحساب ها»'}</div>
                    {!!acceptor && <Factors acceptor={acceptor} onRowClickHandler={handleFactorSelect} perPage={5} />}
                  </>
                }
              />
            </IonCol>
            <IonCol size='6'>
              <CustomCard
                noMargin={true}
                noPadding={true}
                noTitleMarginBottom={true}
                html={!reRender2 && !!lastAyriaPayment && <SimpleFactor factor={lastAyriaPayment} />}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow></IonRow>
      <IonAlert
        ref={priceAlert}
        isOpen={showPriceAlert}
        onDidDismiss={() => setShowPriceAlert(false)}
        cssClass='dir-ltr'
        header={!!pricePreview ? `قیمت کالا ${(+pricePreview).toLocaleString(navigator.language)} ریال` : 'قیمت کالا'}
        subHeader={!!pricePreview ? `قیمت کالا ${(+pricePreview / 10).toLocaleString(navigator.language)} تومان` : ''}
        inputs={[
          {
            name: 'price',
            type: 'number',
            value: pricePreview,
            placeholder: 'قیمت',
            min: 0,
          },
        ]}
        buttons={[
          {
            text: 'نمایش مبلغ',
            cssClass: 'secondary',
            handler: (data) => {
              setPricePreview(data.price)
              setShowPriceAlert(false)
              setTimeout(() => {
                setShowPriceAlert(true)
              }, 200)
            },
          },
          {
            text: 'تایید',
            handler: (data) => {
              handleOnSelectKalaForReal({ ...tempKala, price: data.price })
              setTempKala(undefined)
              setPricePreview(undefined)
            },
          },
        ]}
      />
      <Modal
        showModal={showPurchaseLinkModal}
        toggle={setShowPurchaseLinkModal}
        onClosed={() => setShowPurchaseLinkModal(false)}
        title='ارسال لینک پرداخت'
        isPlatformTheme={true}
        content={<PurchaseLinkForm onSuccess={() => setShowPurchaseLinkModal(false)} />}></Modal>
    </IonGrid>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    acceptorCode: state.shop.acceptorCode,
    lastAyriaPayment: state.shop.lastAyriaPayment,
  }),
  mapDispatchToProps: {
    addDeskKala,
    setAcceptorCode,
    setLastAyriaPayment,
  },
  component: Desk,
})
