import React, { useContext, useState, useEffect } from 'react'
import { IonList, IonItem, IonLabel, IonItemDivider, IonToggle } from '@ionic/react'
import { ProfileContext } from '../../components/structures/contexts/ProfileContext'
import ApiService from '../../api/ApiService'
import PinModal from './PinModal'
import { useToast } from '../../ayria'

interface WalletSettingsProps {}

const WalletSettings: React.FC<WalletSettingsProps> = () => {
  const api = new ApiService()
  const toaster = useToast()
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const [showPinModal, setShowPinModal] = useState(false)
  const [firstTimePin, setFirstTimePin] = useState(false)
  const [turnPinOff, setTurnPinOff] = useState(false)
  const [changePin, setChangePin] = useState(false)

  const setPin = async (value: any) => {
    if (!value) {
      setTurnPinOff(true)
      setShowPinModal(true)
      return
    }
    const res: any = await api.post('/account/profile/pin/on', {})
    if (res.ok) {
      setProfileContext((state: any) => ({ ...state, profile: res.data }))
      if (!res.data.pinInit) {
        setFirstTimePin(true)
        setShowPinModal(true)
      }
    } else {
      toaster.error(res)
    }
  }

  useEffect(() => {
    if (profileContext.profile && profileContext.profile.pinOn && !profileContext.profile.pinInit) {
      setFirstTimePin(true)
      setShowPinModal(true)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <IonList>
        <IonItemDivider>{'تنظیمات کیف پول'}</IonItemDivider>
        <IonItem>
          <IonLabel>{'قفل کیف پول'}</IonLabel>
          <IonToggle
            checked={profileContext.profile && profileContext.profile.pinOn}
            onIonChange={(e) => setPin(e.detail.checked)}
          />
        </IonItem>
        {profileContext.profile && profileContext.profile.pinInit && (
          <IonItem
            onClick={() => {
              setChangePin(true)
              setShowPinModal(true)
            }}>
            <IonLabel>{'تغییر رمز عبور کیف پول'}</IonLabel>
          </IonItem>
        )}
      </IonList>
      <PinModal
        isOpen={showPinModal}
        firstTimePin={firstTimePin}
        turnPinOff={turnPinOff}
        changePin={changePin}
        onCancel={() => {
          setShowPinModal(false)
          setFirstTimePin(false)
          setTurnPinOff(false)
          setChangePin(false)
        }}
        onSuccess={() => {
          setShowPinModal(false)
          setFirstTimePin(false)
          setTurnPinOff(false)
          setChangePin(false)
        }}
      />
    </>
  )
}

export default WalletSettings
