import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Header } from '../../ayria'
import WalletThread from './WalletThread'
import { isDesktop } from '../../utility'

interface WalletThreadTabProps {}

const WalletThreadTab: React.FC<WalletThreadTabProps> = () => {
  return (
    <IonPage id='wallet-thread-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header showBackButton={false} />
        <div className='ion-padding'>
          <WalletThread />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default WalletThreadTab
