import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import Sell from '../../screens/sell/sell'
import Customers from '../../elements/Customers/Customers'
import Rates from '../../elements/Rates/Rates'
import Factors from '../../elements/Factors/Factors'
import WalletReport from '../../screens/wallet/wallet-report/wallet-report'
import SellReport from './sellReport'
import PaymentToolsReport from './PaymentToolsReport'
import { isDesktop } from '../../../utility'

const AcceptorReports = () => {
  const [acceptorContext] = useContext(AcceptorContext)
  const [render, setRender] = useState(false)
  const [report, setReport] = useState('sellReport')
  const { t } = useTranslation()

  useEffect(() => {
    setRender(false)
    setTimeout(() => {
      setRender(true)
    }, 300)
  }, [acceptorContext.currentIndex])

  return (
    <>
      {isDesktop() ? (
        <div
          className='p-4  '
          // style={{backgroundColor:'blue',overflow:'hidden'}}
        >
          <div className='position-relative d-flex flex-row col-xs-12 report-tabs overflow-auto justify-content-between pb-4 customCardTitleSeparator'>
            <div
              className={'report-tab ' + (report === 'sellReport' ? 'active' : '')}
              onClick={() => setReport('sellReport')}>
              {t('acceptorReports.sellReport')}
            </div>
            <div
              className={'report-tab ' + (report === 'factors' ? 'active' : '')}
              onClick={() => setReport('factors')}>
              {t('acceptorReports.factors')}
            </div>
            <div
              className={'report-tab ' + (report === 'online-orders' ? 'active' : '')}
              onClick={() => setReport('online-orders')}>
              {'سفارشات'}
            </div>
            <div
              className={'report-tab ' + (report === 'refunded' ? 'active' : '')}
              onClick={() => setReport('refunded')}>
              {t('acceptorReports.refund')}
            </div>
            <div
              className={'report-tab ' + (report === 'customers' ? 'active' : '')}
              onClick={() => setReport('customers')}>
              {t('acceptorReports.customerList')}
            </div>
            <div className={'report-tab ' + (report === 'rates' ? 'active' : '')} onClick={() => setReport('rates')}>
              {t('acceptorReports.survey')}
            </div>
            <div
              className={'report-tab ' + (report === 'payment-tools' ? 'active' : '')}
              onClick={() => setReport('payment-tools')}>
              {'تراکنشهای کارتخوان'}
            </div>
            <div className={'report-tab ' + (report === 'wallet' ? 'active' : '')} onClick={() => setReport('wallet')}>
              {'کیف پول'}
            </div>
          </div>

          {render && (
            <div className='w-100'>
              {/*<div style={{backgroundColor:'red',maxHeight:'100%',overflow:'hidden'}}>*/}
              {report === 'sellReport' && <SellReport />}
              {report === 'factors' && <Factors acceptor={acceptorContext.current} />}
              {report === 'online-orders' && <Factors acceptor={acceptorContext.current} onlineOrderOnly={true} />}
              {report === 'refunded' && <Sell isAcceptor={true} />}
              {report === 'customers' && <Customers isAcceptor={true} />}
              {report === 'rates' && <Rates isAcceptor={true} />}
              {report === 'payment-tools' && <PaymentToolsReport acceptorCode={acceptorContext.current.code} />}
              {report === 'wallet' && <WalletReport isAcceptor={true} />}
              {/*</div>*/}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className='col-xs-12 report-tabs overflow-auto'>
            <div
              className={'report-tab ' + (report === 'sellReport' ? 'active' : '')}
              onClick={() => setReport('sellReport')}>
              {t('acceptorReports.sellReport')}
            </div>
            <div
              className={'report-tab ' + (report === 'factors' ? 'active' : '')}
              onClick={() => setReport('factors')}>
              {t('acceptorReports.factors')}
            </div>
            <div
              className={'report-tab ' + (report === 'online-orders' ? 'active' : '')}
              onClick={() => setReport('online-orders')}>
              {'سفارشات'}
            </div>
            <div
              className={'report-tab ' + (report === 'refuneded' ? 'active' : '')}
              onClick={() => setReport('refunded')}>
              {t('acceptorReports.refund')}
            </div>
            <div
              className={'report-tab ' + (report === 'customers' ? 'active' : '')}
              onClick={() => setReport('customers')}>
              {t('acceptorReports.customerList')}
            </div>
            <div className={'report-tab ' + (report === 'rates' ? 'active' : '')} onClick={() => setReport('rates')}>
              {t('acceptorReports.survey')}
            </div>
            <div
              className={'report-tab ' + (report === 'payment-tools' ? 'active' : '')}
              onClick={() => setReport('payment-tools')}>
              {'تراکنشهای کارتخوان'}
            </div>
            <div className={'report-tab ' + (report === 'wallet' ? 'active' : '')} onClick={() => setReport('wallet')}>
              {'کیف پول'}
            </div>
          </div>
          {render && (
            <div className='clear-both'>
              {report === 'sellReport' && <SellReport />}
              {report === 'factors' && <Factors acceptor={acceptorContext.current} />}
              {report === 'online-orders' && <Factors acceptor={acceptorContext.current} onlineOrderOnly={true} />}
              {report === 'refunded' && <Sell isAcceptor={true} />}
              {report === 'customers' && <Customers isAcceptor={true} />}
              {report === 'rates' && <Rates isAcceptor={true} />}
              {report === 'payment-tools' && <PaymentToolsReport acceptorCode={acceptorContext.current.code} />}
              {report === 'wallet' && <WalletReport isAcceptor={true} />}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default AcceptorReports
