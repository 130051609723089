import React from 'react';

const LandingTeaser = () => {

  const signupLink = <a href={'/signup'}>ثبت نام</a>

  return (
    <>
      <div className='landing__signup_teaser-banner' />
      <div className='landing__signup_teaser-banner-footer'>
        کافیه با انتخاب این گزینه {signupLink} کنید و از اونجا به بعد ما هم در کنارتون هستیم و ماجراجویی های جذابمون تازه شروع میشه...
      </div>
    </>
  )
}

export default LandingTeaser;
