import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header } from '../ayria'
import ScreenPasswordChange from '../components/screens/change_password/changepassword'

interface ChangePasswordTabProps {}

const ChangePasswordTab: React.FC<ChangePasswordTabProps> = () => {
  return (
    <IonPage id='change-password-tab-page'>
      <IonContent>
        <Header />
        <ScreenPasswordChange />
      </IonContent>
    </IonPage>
  )
}

export default ChangePasswordTab
