import React, { useContext, useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { IonItem, IonLabel, IonToggle, IonList, IonItemDivider, IonSpinner } from '@ionic/react'
import { AcceptorContext, ApiService, useToast, AcceptorSettings, setSettings, connect } from '../../../ayria'
import DeliveryHoursEdit from './DeliveryHoursEdit'

interface OwnProps {}
interface StateProps {
  settings: AcceptorSettings
}
interface DispatchProps {
  setSettings: typeof setSettings
}
interface AcceptorSettingProps extends OwnProps, StateProps, DispatchProps {}

const AcceptorSetting: React.FC<AcceptorSettingProps> = ({ settings, setSettings }) => {
  const api = new ApiService()
  const toast = useToast()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [firstTime, setFirstTime] = useState(true)
  const [cmdChanged] = useDebounce(`${settings?.tax} tax ${settings?.taxRaw} taxRaw`, 500)
  const [inprogress, setInprogress] = useState(true)

  const update = async () => {
    setInprogress(true)
    const res: any = await api.put(`/acceptor/online-setting/${acceptorContext?.current?.code}`, settings)
    if (res.ok) {
      toast.success('تنظیمات با موفقیت بروز رسانی شد')
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const load = async () => {
    setInprogress(true)
    const res: any = await api.get(`/acceptor/online-setting/${acceptorContext?.current?.code}`)
    if (res.ok) {
      await setSettings(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    if (!inprogress && acceptorContext?.current?.code) {
      if (!firstTime) {
        update()
      } else {
        setFirstTime(false)
      }
    }
  }, [cmdChanged]) // eslint-disable-line

  useEffect(() => {
    if (acceptorContext?.current?.code) {
      load()
    }
  }, [acceptorContext?.current?.code]) // eslint-disable-line

  return (
    <>
      {!!settings && (
        <IonList>
          <IonItemDivider>
            <IonLabel>{'تنظیمات'}</IonLabel>
            {inprogress && <IonSpinner slot='end' />}
          </IonItemDivider>
          <IonItem>
            <IonLabel>وضعیت مالیات بر ارزش افزوده</IonLabel>
            <IonToggle
              checked={settings?.tax}
              onIonChange={(e) => setSettings({ ...settings, tax: e.detail.checked })}></IonToggle>
          </IonItem>
          {settings.tax && (
            <IonItem>
              <IonLabel>محاسبه مالیات برای صورتحساب های بدون کالا</IonLabel>
              <IonToggle
                checked={settings?.taxRaw}
                onIonChange={(e) => setSettings({ ...settings, taxRaw: e.detail.checked })}></IonToggle>
            </IonItem>
          )}
          <IonItemDivider>
            <IonLabel>{'تعیین بازه ثبت سفارش آنلاین'}</IonLabel>
          </IonItemDivider>
          <DeliveryHoursEdit acceptorCode={acceptorContext.current.code} />
        </IonList>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    settings: state.shop.settings,
  }),
  mapDispatchToProps: {
    setSettings,
  },
  component: AcceptorSetting,
})
