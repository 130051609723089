import React from "react";

interface MerchantDescriptionProps {
  description: string;
  showLess?: boolean;
  limit?: number;
}

const MerchantDescription: React.FC<MerchantDescriptionProps> = ({ description, showLess, limit = 25 }) => {
    return (
        <>
            {description && (
                <p className={'m-0 p-2'}>
                    {showLess
                        ? `${description.substring(0, limit)}...`
                        : description}
                </p>
            )}
        </>
    );
};

export default MerchantDescription;
