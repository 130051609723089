import React, { useState, useEffect, lazy } from 'react'
import { IonIcon, IonButton, IonSpinner, IonCheckbox, IonItem, IonItemDivider } from '@ionic/react'
import { IonList, IonLabel, IonText } from '@ionic/react'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { trash, add } from 'ionicons/icons'
import './profile-form-acceptor.css'
import { SingleUploader, ApiService, useToast, CDN_BASE_URL, connect, Modal } from '../../../ayria'
import { isDesktop, isMobile } from '../../../utility'

const BankAccountForm = lazy(() => import('./bankAccountForm'))

interface OwnProps {
  user: any
}
interface StateProps {
  loggedUser?: any
}
interface ProfileFormAcceptorProps extends OwnProps, StateProps {
  user: any
}

const ProfileFormAcceptor: React.FC<ProfileFormAcceptorProps> = ({ user, loggedUser }) => {
  const toast = useToast()
  const api = new ApiService()
  const { t } = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const [acceptor, setAcceptor] = useState(user)
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(query.get('tab') || 'acceptor')
  const [inprogress, setInprogress] = useState(false)
  const [showBankAccountForm, setShowBankAccountForm] = useState(false)
  const [partners, setPartners] = useState([])

  const handleSubmit = async () => {
    setIsLoading(true)
    const response: any = await api.updateAcceptorProfile(acceptor)
    if (response.ok) {
      setAcceptor(response.data)
      toast.success('اطلاعات با موفقیت بروز رسانی شد')
    } else {
      toast.error(t('label.operationFailed'))
    }
    setIsFormTouched(false)
    setIsLoading(false)
  }

  const confirmInitialRequest = async () => {
    setIsLoading(true)
    const res: any = await api.updateAcceptorStatus({ id: acceptor.id, status: 'CONFIRMED' })
    if (res.ok) {
      setAcceptor({ ...acceptor, status: res.data.status })
      toast.success('وضعیت فروشگاه با موفقیت تغییر کرد')
    } else {
      toast.error(res)
    }
    setIsLoading(false)
  }

  const deleteImage = async (type: string) => {
    setIsLoading(true)
    const res: any = await api.deleteAcceptorDocument(type, acceptor.id)
    if (res.ok) {
      setAcceptor(res.data)
    } else {
      toast.error(res)
    }
    setIsLoading(false)
  }

  const handleChange = (event: any) => {
    setIsFormTouched(true)
    setAcceptor({ ...acceptor, [event.target.id]: event.target.value })
  }

  const disabled = () => {
    if (acceptor && acceptor.status) {
      if (
        acceptor.status === 'REQUESTED' ||
        acceptor.status === 'CONFIRMED' ||
        acceptor.status === 'DOCUMENT_SENT' ||
        acceptor.status === 'DOCUMENT_INCOMPLETE'
      ) {
        return false
      }
    }
    return true
  }

  const uploadImage = (meta: any, file: File, identifier: string) => {
    if (meta.size > 2097152) {
      toast.error(`حجم فایل ${meta.name} باید کمتر از ۲ مگابایت باشد`)
      return
    }
    const reader = new FileReader()
    const fileByteArray: any = []
    reader.readAsArrayBuffer(file)
    reader.onloadend = function (evt: any) {
      if (evt.target.readyState === FileReader.DONE) {
        let arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer)
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])
        }
        sendImage(meta, fileByteArray, identifier)
      }
    }
  }

  const sendImage = async (meta: any, byteArray: any, identifier: string) => {
    acceptor.documents = acceptor.documents.filter((d: any) => d.documentType !== identifier)
    acceptor.documents.push({
      documentType: identifier,
      content: byteArray,
      contentContentType: meta.type,
      acceptorId: acceptor.id,
    })
    setIsFormTouched(true)
  }

  const id = (type: string) => {
    return acceptor?.documents.find((d: any) => d.documentType === type)
  }

  const isOld = (type: string) => {
    return id(type) && id(type).id
  }

  const isNew = (type: string) => {
    return !isOld(type)
  }

  const color = (type: string) => {
    if (id(type)) {
      return id(type).documentStatus === 'CONFIRMED'
        ? 'success'
        : id(type).documentStatus === 'REJECTED'
        ? 'danger'
        : 'warning'
    }
  }

  const rejectedText = (type: string) => {
    if (id(type)) {
      return id(type).documentStatus === 'REJECTED' ? id(type).statusDescription : ''
    }
  }
  const isNotVerified = (type: string) => {
    return !isVerified(type)
  }
  const isVerified = (type: string) => {
    return id(type).documentStatus === 'CONFIRMED'
  }
  const ownerInfo = (prop: string) => {
    if (partners.filter((p) => p.mobile === loggedUser?.mobile).length > 0) {
      return partners.filter((p) => p.mobile === loggedUser?.mobile)[0][prop]
    }
  }

  const handleNextTab = () => {
    handleSubmit()
    if (activeTab === 'acceptor') {
      setActiveTab('agent')
    } else if (activeTab === 'agent') {
      setActiveTab('bank')
    } else if (activeTab === 'bank') {
      setActiveTab('contact')
    } else if (activeTab === 'contact') {
      setActiveTab('services')
    } else if (activeTab === 'services') {
      setActiveTab('documents')
    } else if (activeTab === 'documents') {
      setActiveTab('documents')
    }
  }

  const bankAccountChange = async (bankAccount: any, checked: boolean) => {
    setInprogress(true)
    const res: any = await api.selectAcceptorBank(acceptor.id, bankAccount.id, JSON.stringify(checked ? true : false))
    if (res.ok) {
      setAcceptor(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const fetchAcceptor = async () => {
    setInprogress(true)
    const res: any = await api.getAcceptorById(acceptor.id)
    if (res.ok) {
      setAcceptor(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const deleteBankAccount = async (ba: any) => {
    setInprogress(true)
    const res: any = await api.deleteAcceptorBank(acceptor.id, ba.id)
    if (res.ok) {
      await fetchAcceptor()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getPartners = async () => {
    const res: any = await api.get(`/acceptor/partners/${acceptor?.code}`)
    setPartners(res.ok ? res.data : [])
  }

  const onNewBankAccountSuccess = async () => {
    setShowBankAccountForm(false)
    await fetchAcceptor()
  }

  useEffect(() => {
    getPartners()
  }, []) // eslint-disable-line

  return (
    <div>
      <IonSegment value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>
        <IonSegmentButton value='acceptor'>{'اطلاعات اولیه'}</IonSegmentButton>
        <IonSegmentButton value='agent'>{'اطلاعات مالک | نماینده'}</IonSegmentButton>
        {isDesktop() && <IonSegmentButton value='bank'>{'اطلاعات بانکی'}</IonSegmentButton>}
        {isDesktop() && <IonSegmentButton value='contact'>{'اطلاعات تماس'}</IonSegmentButton>}
        {isDesktop() && <IonSegmentButton value='services'>{'شرایط خدمات'}</IonSegmentButton>}
        {isDesktop() && <IonSegmentButton value='documents'>{'اسناد و مدارک'}</IonSegmentButton>}
      </IonSegment>
      {isMobile() && (
        <IonSegment value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>
          <IonSegmentButton value='bank'>{'اطلاعات بانکی'}</IonSegmentButton>
          <IonSegmentButton value='contact'>{'اطلاعات تماس'}</IonSegmentButton>
        </IonSegment>
      )}
      {isMobile() && (
        <IonSegment value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>
          <IonSegmentButton value='services'>{'شرایط خدمات'}</IonSegmentButton>
          <IonSegmentButton value='documents'>{'اسناد و مدارک'}</IonSegmentButton>
        </IonSegment>
      )}

      {activeTab === 'acceptor' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام فروشگاه</label>
              <input
                id={'acceptorName'}
                value={acceptor.acceptorName}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام انگلیسی فروشگاه</label>
              <input
                id={'acceptorNameEn'}
                value={acceptor.acceptorNameEn}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
                disabled={disabled()}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>{t('acceptor.department')}</label>
              <div>{t(`department.${acceptor.department}`)}</div>
            </div>
          </div>
        </div>
      )}
      {activeTab === 'agent' && (
        <div className='mt-3'>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام</label>
              <input
                id={'givenName'}
                value={ownerInfo('givenName')}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام خانوادگی</label>
              <input
                id={'familyName'}
                value={ownerInfo('familyName')}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام پدر </label>
              <input
                id={'fatherName'}
                value={ownerInfo('fatherName')}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>تاریخ تولد (شمسی) </label>
              <input
                id='dateOfBirth'
                value={ownerInfo('dateOfBirth')}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>کد ملی </label>
              <input
                id={'nationalIdNumber'}
                value={ownerInfo('nationalIdNumber')}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
                disabled={disabled()}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>شماره شناسنامه </label>
              <input
                id={'birthCertNumber'}
                value={ownerInfo('birthCertNumber')}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
                disabled={disabled()}
              />
            </div>
          </div>
          {partners.length >= 2 && (
            <div>
              <IonItemDivider>{'لیست شرکا'}</IonItemDivider>
              {partners
                .filter((p) => p.mobile !== loggedUser?.mobile)
                .map((p) => (
                  <IonItem key={p.mobile}>
                    <IonLabel>
                      {p.givenName} {p.familyName} {p.mobile}
                    </IonLabel>
                  </IonItem>
                ))}
            </div>
          )}
        </div>
      )}
      {activeTab === 'bank' && (
        <IonList>
          <IonItemDivider>
            {'حساب پیشفرض'}
            <IonButton onClick={() => setShowBankAccountForm(true)} slot='end'>
              {'تعریف شبای جدید'}
              <IonIcon slot='start' icon={add} />
            </IonButton>
          </IonItemDivider>
          {acceptor?.shebas.map((s: any, index: number) => (
            <IonItem key={s.accountSheba + ' ' + index}>
              <IonCheckbox
                slot='start'
                disabled={!s.confirmed || inprogress}
                checked={s.picked}
                onClick={() => bankAccountChange(s, !s.picked)}
              />
              <IonLabel>
                {s.accountBankImg && (
                  <img
                    className='bankImg'
                    alt={s.accountBank}
                    src={`/assets/images/banks/${s.accountBankImg}-300-c.png`}
                  />
                )}
                <IonText onClick={() => console.log('row selected', s)}>{s.accountSheba}</IonText>
              </IonLabel>
              {!s.confirmed && (
                <IonButton onClick={() => deleteBankAccount(s)} fill='clear' color='danger' slot='end'>
                  <IonIcon icon={trash} />
                </IonButton>
              )}
            </IonItem>
          ))}
        </IonList>
      )}

      {!inprogress && activeTab === 'bank' && (
        <div className='mt-3'>
          <div className='row'>
            <div className='form-group col-md-4 col-xs-12'>
              <label>بانک</label>
              <input
                id='bank'
                value={acceptor.bank}
                onChange={(e) => handleChange(e)}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className='form-group col-md-4 col-xs-12'>
              <label>شعبه</label>
              <input
                id='branch'
                value={acceptor.branch}
                onChange={(e) => handleChange(e)}
                type={'text'}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className='form-group col-md-4 col-xs-12'>
              <label>{'کد شعبه'}</label>
              <input
                id='branchCode'
                value={acceptor.branchCode}
                onChange={(e) => handleChange(e)}
                type={'text'}
                disabled={disabled()}
                className='form-control'
              />
            </div>
          </div>
          <div className='row'>
            <div className='form-group col-md-4 col-xs-12'>
              <label>{'نام صاحب حساب'}</label>
              <input
                id='accountNumber'
                value={acceptor.accountNumber}
                onChange={(e) => handleChange(e)}
                type={'number'}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className='form-group col-md-4 col-xs-12'>
              <label>شماره حساب</label>
              <input
                id='accountNumber'
                value={acceptor.accountNumber}
                onChange={(e) => handleChange(e)}
                type={'number'}
                disabled={disabled()}
                className='form-control'
              />
            </div>
            <div className='form-group col-md-4 col-xs-12'>
              <label>شماره شبا</label>
              <input
                id='shebaCode'
                value={acceptor.shebaCode}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
                disabled={disabled()}
              />
            </div>
          </div>
          <div className='row'>
            <div className='form-group col-md-4 col-xs-12'>
              <label>{'نام دلخواه'}</label>
              <input
                id='accountNumber'
                value={acceptor.accountNumber}
                onChange={(e) => handleChange(e)}
                type={'number'}
                disabled={disabled()}
                className='form-control'
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'contact' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>تلفن </label>
              <input
                id={'phone'}
                value={ownerInfo('phone')}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>شماره همراه</label>
              <input
                id={'mobile'}
                value={ownerInfo('mobile')}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>کد پستی </label>
              <input
                id={'postcode'}
                value={ownerInfo('postcode')}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>آدرس </label>
              <textarea
                id={'address'}
                value={ownerInfo('address')}
                onChange={(e) => handleChange(e)}
                className={'form-control'}></textarea>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'services' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>درصد تخفیف </label>
              <input
                id={'discountPercentage'}
                value={acceptor.discountPercentage}
                onChange={(e) => handleChange(e)}
                type={'number'}
                min={'0'}
                max={'100'}
                className='dir-ltr form-control'
                disabled={true}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>{'کارمزد سامانه'}</label>
              <input
                id={'platformDiscountPercentage'}
                value={acceptor.platformDiscountPercentage}
                onChange={(e) => handleChange(e)}
                type={'number'}
                min={'0'}
                max={'100'}
                className='dir-ltr form-control'
                disabled={true}
              />
            </div>
          </div>
          <div className='row'>
            <div className={'form-group col-md-12 col-xs-12'}>
              <label> توضیحات کالا یا خدمات (کوتاه) </label>
              <input
                className={'form-control'}
                id={'shortDescription'}
                value={acceptor.shortDescription}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className='row'>
            <div className={'form-group col-md-12 col-xs-12'}>
              <label>توضیحات کالا یا خدمات (کامل)</label>
              <textarea
                className={'form-control'}
                id={'description'}
                value={acceptor.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'documents' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>تصویر روی کارت ملی </label>
              {isOld('ID_NATIONAL_CODE') && (
                <div className={`alert alert-${color('ID_NATIONAL_CODE')}`}>
                  {rejectedText('ID_NATIONAL_CODE') && <div>{rejectedText('ID_NATIONAL_CODE')}</div>}
                  <img
                    alt={id('ID_NATIONAL_CODE').documentIdentifier}
                    src={`${CDN_BASE_URL}/private/${id('ID_NATIONAL_CODE').documentIdentifier}`}
                    crossOrigin='anonymous'
                  />
                  {isNotVerified('ID_NATIONAL_CODE') && (
                    <IonButton color='danger' className='left' onClick={() => deleteImage('ID_NATIONAL_CODE')}>
                      <IonIcon icon={trash} />
                    </IonButton>
                  )}
                </div>
              )}
              {isNew('ID_NATIONAL_CODE') && (
                <SingleUploader onFileUploaded={uploadImage} accept={'image/*'} identifier='ID_NATIONAL_CODE' />
              )}
            </div>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>{'صفحه اول شناسنامه'}</label>
              {isOld('ID_IDENTITY_NUMBER') && (
                <div className={`alert alert-${color('ID_IDENTITY_NUMBER')}`}>
                  {rejectedText('ID_IDENTITY_NUMBER') && <div>{rejectedText('ID_IDENTITY_NUMBER')}</div>}
                  <img
                    alt={id('ID_IDENTITY_NUMBER').documentIdentifier}
                    src={`${CDN_BASE_URL}/private/${id('ID_IDENTITY_NUMBER').documentIdentifier}`}
                    crossOrigin='anonymous'
                  />
                  {isNotVerified('ID_IDENTITY_NUMBER') && (
                    <IonButton color='danger' className='left' onClick={() => deleteImage('ID_IDENTITY_NUMBER')}>
                      <IonIcon icon={trash} />
                    </IonButton>
                  )}
                </div>
              )}
              {isNew('ID_IDENTITY_NUMBER') && (
                <SingleUploader onFileUploaded={uploadImage} accept={'image/*'} identifier='ID_IDENTITY_NUMBER' />
              )}
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>{'جواز کسب یا اجاره نامه'}</label>
              {isOld('ID_BUSINESS_LICENSE') && (
                <div className={`alert alert-${color('ID_BUSINESS_LICENSE')}`}>
                  {rejectedText('ID_BUSINESS_LICENSE') && <div>{rejectedText('ID_BUSINESS_LICENSE')}</div>}
                  <img
                    alt={id('ID_BUSINESS_LICENSE').documentIdentifier}
                    src={`${CDN_BASE_URL}/private/${id('ID_BUSINESS_LICENSE').documentIdentifier}`}
                    crossOrigin='anonymous'
                  />
                  {isNotVerified('ID_BUSINESS_LICENSE') && (
                    <IonButton color='danger' className='left' onClick={() => deleteImage('ID_BUSINESS_LICENSE')}>
                      <IonIcon icon={trash} />
                    </IonButton>
                  )}
                </div>
              )}
              {isNew('ID_BUSINESS_LICENSE') && (
                <SingleUploader onFileUploaded={uploadImage} accept={'image/*'} identifier='ID_BUSINESS_LICENSE' />
              )}
            </div>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>{'تاییده کد پستی'}</label>
              {isOld('ID_POSTAL_CODE') && (
                <div className={`alert alert-${color('ID_POSTAL_CODE')}`}>
                  {rejectedText('ID_POSTAL_CODE') && <div>{rejectedText('ID_POSTAL_CODE')}</div>}
                  <img
                    alt={id('ID_POSTAL_CODE').documentIdentifier}
                    src={`${CDN_BASE_URL}/private/${id('ID_POSTAL_CODE').documentIdentifier}`}
                    crossOrigin='anonymous'
                  />
                  {isNotVerified('ID_POSTAL_CODE') && (
                    <IonButton color='danger' className='left' onClick={() => deleteImage('ID_POSTAL_CODE')}>
                      <IonIcon icon={trash} />
                    </IonButton>
                  )}
                </div>
              )}
              {isNew('ID_POSTAL_CODE') && (
                <SingleUploader onFileUploaded={uploadImage} accept={'image/*'} identifier='ID_POSTAL_CODE' />
              )}
            </div>
          </div>
        </div>
      )}

      <hr></hr>
      <div className='form-group text-left'>
        <input type={'hidden'} value={acceptor.langKey} />
        <input type={'hidden'} value={acceptor.imageUrl} />
        <IonButton disabled={isLoading || !isFormTouched} onClick={handleSubmit} color='primary'>
          {isLoading && <IonSpinner name='crescent' color='dark' />}
          ذخیره
        </IonButton>
        {activeTab !== 'documents' && (
          <IonButton onClick={handleNextTab} color='light'>
            {isLoading && <IonSpinner name='crescent' color='dark' />}
            بعدی
          </IonButton>
        )}
        {activeTab === 'documents' && (
          <Link to='panel' className='btn btn-sm' onClick={handleSubmit}>
            بستن
          </Link>
        )}
        {acceptor.status === 'REQUESTED' &&
          activeTab === 'documents' &&
          loggedUser?.authorities?.includes('ROLE_KARGOZAR') && (
            <IonButton
              className='ion-padding-start'
              color='success'
              onClick={confirmInitialRequest}
              disabled={
                isNew('ID_NATIONAL_CODE') ||
                isNew('ID_IDENTITY_NUMBER') ||
                isNew('ID_BUSINESS_LICENSE') ||
                isNew('ID_POSTAL_CODE')
              }>
              {isLoading && <IonSpinner name='crescent' color='dark' />}
              تایید درخواست اولیه
            </IonButton>
          )}
      </div>

      <Modal
        showModal={showBankAccountForm}
        toggle={setShowBankAccountForm}
        onClosed={() => setShowBankAccountForm(false)}
        isPlatformTheme={true}
        title={'تعریف شبای جدید'}
        content={<BankAccountForm acceptor={acceptor} onSuccess={onNewBankAccountSuccess} />}
      />
    </div>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
  }),
  component: ProfileFormAcceptor,
})
