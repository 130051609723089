import React, { useEffect, useState } from 'react'
import { IonItem, IonText, IonIcon, IonButton } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { useToast, ApiService, Modal } from '../../ayria'
import AyriaPayment from '../../components/elements/AyriaPayment/AyriaPayment'
import { setWithTimeout } from '../../utility'

interface GroupPaymentRequestViewProps {
  requestId: number
  acceptorCode?: string
}

const GroupPaymentRequestView: React.FC<GroupPaymentRequestViewProps> = ({ requestId, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [showViewModal, setShowViewModal] = useState(false)
  const [ayriaPayment, setAyriaPayment] = useState()

  const getItems = async () => {
    const res: any = await api.get(
      `/${!!acceptorCode ? 'acceptor' : 'customer'}/group-payments/requests/${requestId}${
        !!acceptorCode ? `/${acceptorCode}` : ''
      }`
    )
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    } else {
      toast.error(res)
    }
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      {items?.payments?.map((itm: any) => (
        <IonItem
          onClick={() => {
            setAyriaPayment(itm)
            setShowViewModal(true)
          }}
          key={itm.trackingNumber}>
          <IonText slot='end'>
            {itm.payerName} {itm.payerName !== itm.payerMobile ? `${itm.payerMobile}` : ''}
          </IonText>
          <IonText className='ion-text-wrap' slot='start' color={itm.paid ? 'success' : 'danger'}>
            {itm.amount.toLocaleString(navigator.language)} {'ریال'}
          </IonText>
          {itm.paid && <IonIcon slot='start' icon={checkmark} color='success' />}
        </IonItem>
      ))}
      <Modal
        showModal={showViewModal}
        toggle={setShowViewModal}
        title={'درخواست وجه'}
        onClosed={() => setShowViewModal(false)}
        content={<AyriaPayment payment={ayriaPayment} />}
        actionButtons={<IonButton onClick={() => setShowViewModal(false)}>{'بستن'}</IonButton>}
      />
    </>
  )
}

export default GroupPaymentRequestView
