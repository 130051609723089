import React, { useEffect, useState } from 'react'
import { IonSpinner } from '@ionic/react'
import { useParams } from 'react-router'
import MerchantProfileBanner from './merchantProfileBanner'
import MerchantProfileTabs from './merchantProfileTabs'
import MerchantProfileTabContent from './merchantProfileTabContent'
import { ApiService, connect, Header } from '../../../ayria'
import './merchantProfile.scss'

interface StateProps {
  kalaList: any
  ionContentRef?: any
  scrollTop?: number
}

interface MerchantProfileProps extends StateProps {}

const MerchantProfile: React.FC<MerchantProfileProps> = ({ kalaList, ionContentRef, scrollTop }) => {
  const api = new ApiService()
  const [activeTab, setActiveTab] = useState('shop')
  const { merchantId } = useParams<any>()
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasKala, setHasKala] = useState(false)

  const getData = async () => {
    setHasError(false)
    setIsLoading(true)
    try {
      const response: any = await api.get(`/public/acceptors/ref-code/${merchantId}`)
      setData(response.data)
      const res: any = await api.get(`/public/acceptors/kala/${merchantId}/count`)
      setHasKala(!!res.data && res.data > 0 ? true : false)
      setActiveTab(!!res.data && res.data > 0 ? 'shop' : 'merchantInfo')
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnTabSelect = (selectedTab: string) => setActiveTab(selectedTab)

  useEffect(() => {
    getData()
  }, []) // eslint-disable-line

  return (
    <>
      <Header />
      {data && !hasError && !isLoading && (
        <div className={'merchant-profile'}>
          <div>
            <MerchantProfileBanner merchant={data} />
          </div>
          <MerchantProfileTabs haskala={hasKala} newtab={activeTab} onTabSelect={handleOnTabSelect} merchant={data} />
          <div className={'merchant-profile-tab-content'}>
            <MerchantProfileTabContent
              merchant={data}
              activeTab={activeTab}
              ionContentRef={ionContentRef}
              scrollTop={scrollTop}
            />
          </div>
        </div>
      )}
      {isLoading && !hasError && (
        <div className='ion-padding ion-text-center'>
          <IonSpinner name='crescent' color='dark' />
        </div>
      )}
    </>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
  }),
  component: MerchantProfile,
})
