const banks = [
  { value: 'EGHTESAD_NOVID', label: 'اقتصاد نوین' },
  { value: 'ANSAR', label: 'انصار' },
  { value: 'IRAN_ZAMIN', label: 'ایران زمین' },
  { value: 'PARSIAN', label: 'پارسیان' },
  { value: 'PASARGAD', label: 'پاسارگاد' },
  { value: 'TAT', label: 'آینده' },
  { value: 'TEJARAT', label: 'تجارت' },
  { value: 'TOSAE_TAVON', label: 'توسعه تعاون' },
  { value: 'TOSAE_SADERAT', label: 'توسعه صادرات' },
  { value: 'HEKMAT_IRANIAN', label: 'حکمت ایرانیان' },
  { value: 'DAY', label: 'دی' },
  { value: 'REFAH', label: 'رفاه کارگران' },
  { value: 'SAMAN', label: 'سامان' },
  { value: 'SEPAH', label: 'سپه' },
  { value: 'SARMAYEH', label: 'سرمایه' },
  { value: 'SINA', label: 'سینا' },
  { value: 'SHAHR', label: 'شهر' },
  { value: 'SADERAT', label: 'صادرات' },
  { value: 'SANAT_O_MADAN', label: 'صنعت و معدن' },
  { value: 'MEHR', label: 'مهر' },
  { value: 'GHAVAMIN', label: 'قوامین' },
  { value: 'KAR_AFARIN', label: 'کارآفرین' },
  { value: 'KESHAVARZI', label: 'کشاورزی' },
  { value: 'GARDESHGARI', label: 'گردشگری' },
  { value: 'MARKAZI', label: 'فروشگاهی' },
  { value: 'MASKAN', label: 'مسکن' },
  { value: 'MELLAT', label: 'ملت' },
  { value: 'MELLI', label: 'ملی' },
  { value: 'MEHR_EGHTESAD', label: 'مهر اقتصاد' },
  { value: 'POST', label: 'پست' },
  { value: 'TOSAE', label: 'توسعه' },
  { value: 'KOSAR', label: 'کوثر' },
  { value: 'MIDDLE_EAST', label: 'خاورمیانه' },
]

export default banks
