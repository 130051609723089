import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import WalletPayerResponse from '../WalletPayerResponse/WalletPayerResponse'
import { useToast } from '../../../ayria'
import AcceptorSuggest from '../../units/suggest/acceptor-suggest'
import { withRouter } from 'react-router'

const WalletPurchase = ({ onSelect, onCancel, history }) => {
  const toast = useToast()
  const [acceptorCode, setAcceptorCode] = useState()
  const [disabled, setDisabled] = useState(true)

  const handleOnSelect = (item) => {
    if (item) {
      setAcceptorCode(item)
      setDisabled(false)
    } else {
      toast.error('فروشگاه فعال نیست')
    }
  }

  return (
    <div>
      <div>
        <label>{'به منظور پرداخت صورت حساب خود، لطفا نام فروشگاه مورد نظر را درج نمایید.'}</label>
        <AcceptorSuggest
          onChange={(value) => setDisabled(true)}
          onSelect={(item) => handleOnSelect(item)}
          prop='code'
        />
      </div>
      <br />
      <IonButton disabled={disabled} onClick={(e) => onSelect({ referralCode: acceptorCode })}>
        شروع
      </IonButton>
      <br />
      <br />
      <WalletPayerResponse receiverType={'company'} />
    </div>
  )
}

export default withRouter(WalletPurchase)
