import React, { useState, useEffect } from 'react'
import './pagination.scss'
import { IonIcon } from '@ionic/react'
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'

export const ITEMS_PER_PAGE = 10

/**
 * Retrieve new data when infinite scrolling
 * @param currentData
 * @param incomingData
 */
export const loadMoreDataWhenScrolled = (currentData: any, incomingData: any): any => {
  if (!currentData?.length) {
    return incomingData
  }
  if (!incomingData?.length) {
    return currentData
  }
  incomingData.map((elem: any) => currentData.push(elem))
  return currentData
}

export interface IAyriaPaginationProps {
  activePage: number
  onSelect: (page: number) => void
  maxButtons: number
  totalItems: number
  itemsPerPage: number
}

export interface IAyriaPaginationState {
  currentPage: number
}

export interface AyriaPaginationProps extends IAyriaPaginationProps {}

const AyriaPagination: React.FC<AyriaPaginationProps> = ({
  activePage,
  onSelect,
  maxButtons,
  totalItems,
  itemsPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(activePage)
  const [maxPage, setMaxPage] = useState<number>(0)

  const updateActivePage = (currentPage: number) => () => {
    setCurrentPage(currentPage)
    onSelect(currentPage)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
    onSelect(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
    onSelect(currentPage + 1)
  }

  const itemsToDisplay = (activePage: number) => {
    const items = []
    let item: any = {}
    let previousItem: any = {}
    const maxPage = getMaxPage()
    const padSup = Math.floor((maxButtons - 1) / 2)
    const modulo = (maxButtons - 1) % 2
    const padInf = padSup + modulo
    for (let j = 0; j < maxPage; j++) {
      item = {}
      if (
        j === 0 ||
        j === maxPage - 1 ||
        j === activePage - 1 ||
        j === activePage - 2 ||
        (activePage === 1 && j === 1) ||
        (activePage - padInf < j && j < activePage + padSup)
      ) {
        item.display = 'display'
      } else if (previousItem.display === 'disabled') {
        item.display = 'hidden'
      } else {
        item.display = 'disabled'
      }
      items.push(item)
      previousItem = { ...item }
      if (item.display === 'hidden') {
        previousItem.display = 'disabled'
      }
    }
    return items
  }

  // const displayPaginationItem = (i: number) => (
  //   <li className={`page-item ${currentPage === i + 1 ? 'active' : ''}`} key={i}>
  //     <span className='page-link' onClick={updateActivePage(i + 1)}>
  //       {i + 1}
  //     </span>
  //   </li>
  // )
  const displayPaginationItem = (i: number) => (
    <span
      key={i}
      onClick={updateActivePage(i + 1)}
      className='px-1 cursor-pointer'
      style={{ color: currentPage === i + 1 ? '#0019E9' : '' }}>
      {i + 1}
    </span>
  )

  const cleanActivePage = () => {
    const cleanActivePage = totalItems === 0 ? 1 : Math.min(activePage, Math.ceil(totalItems / itemsPerPage))

    if (cleanActivePage !== activePage) {
      updateActivePage(cleanActivePage)()
    }
  }

  const getMaxPage = () => {
    const division = Math.floor(totalItems / itemsPerPage)
    const modulo = totalItems % itemsPerPage
    return division + (modulo !== 0 ? 1 : 0)
  }

  useEffect(() => {
    cleanActivePage()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setMaxPage(getMaxPage())
    // eslint-disable-next-line
  }, [totalItems, itemsPerPage])

  return (
    <nav>
      <div className='pagination'>
        {/*<li className={`page-item`}>*/}
        {/*  <span className='page-link' onClick={updateActivePage(1)} {...(activePage === 1 && { disabled: true })}>*/}
        {/*    ««*/}
        {/*  </span>*/}
        {/*</li>*/}
        {/*<li className='page-item'>*/}
        {/*  <span className='page-link' onClick={previousPage} {...(activePage === 1 && { disabled: true })}>*/}
        {/*    «*/}
        {/*  </span>*/}
        {/*</li>*/}
        <div
          onClick={() => activePage !== 1 && previousPage}
          className='d-flex flex-row align-items-center cursor-pointer'>
          <IonIcon icon={chevronForwardOutline} slot='icon-only' />
          <span style={{ color: activePage !== 1 && '#0019E9' }} className='px-2'>
            قبلی
          </span>
        </div>

        {itemsToDisplay(activePage).map((paginationItem, i) =>
          paginationItem.display === 'display' ? (
            displayPaginationItem(i)
          ) : paginationItem.display === 'disabled' ? (
            <li className='page-item' key={`${i}-`}>
              <span className='page-link' disabled={true}>
                ...
              </span>
            </li>
          ) : null
        )}
        {/*<li className='page-item'>*/}
        {/*  <span className='page-link' onClick={nextPage} {...(activePage === maxPage && { disabled: true })}>*/}
        {/*    »*/}
        {/*  </span>*/}
        {/*</li>*/}

        <div
          onClick={() => activePage !== maxPage && nextPage}
          className='d-flex flex-row align-items-center cursor-pointer'>
          <span style={{ color: activePage !== maxPage && '#0019E9' }} className='px-2'>
            بعدی
          </span>
          <IonIcon icon={chevronBackOutline} slot='icon-only' />
        </div>

        {/*<li className={`page-item`}>*/}
        {/*  <span*/}
        {/*    className='page-link'*/}
        {/*    onClick={updateActivePage(maxPage)}*/}
        {/*    {...(activePage === maxPage && { disabled: true })}>*/}
        {/*    »»*/}
        {/*  </span>*/}
        {/*</li>*/}
      </div>
    </nav>
  )
}

export { AyriaPagination }
