import React from "react";
import likeIcon from "./icons/1.png";
import indifferentIcon from "./icons/3.png";
import dislikeIcon from "./icons/2.png";
import { useTranslation } from "react-i18next";

interface ProgressBarItemProps {
  percentage: number;
  classIdentifier: string;
}

const ProgressBarItem: React.FC<ProgressBarItemProps> = ({ percentage, classIdentifier }) => (
    <div className="progress">
        <div
            className={`progress-bar bg-${classIdentifier}`}
            role="progressbar"
            style={{
                width: `${percentage}%`
            }}
            aria-valuemin={0}
            aria-valuemax={100}
        ></div>
    </div>
);

interface RatingStatitsticsProgressBarsProps {
  statistics: any;
  classIdentifier: string;
}

const RatingStatitsticsProgressBars: React.FC<RatingStatitsticsProgressBarsProps> = ({ statistics, classIdentifier }) => {
    const {t} = useTranslation()
    return (
        <>
            <div className={"progress-bar-item"}>
                <div className={"descrption"}>
                    <span role="img" aria-label={"likes"}>
                        <img src={likeIcon} alt={"likeicon"} width={20} />
                    </span>
                    {`${statistics.likesPercentage}%`} {t('merchantProfile.labels.likes')}
                </div>
                <ProgressBarItem
                    percentage={statistics.likesPercentage}
                    classIdentifier={classIdentifier}
                />
            </div>
            <div className={"progress-bar-item"}>
                <div className={"descrption"}>
                    <span role="img" aria-label={"indifferent"}>
                        <img
                            src={indifferentIcon}
                            alt={"indifferenticon"}
                            width={20}
                        />{" "}
                        {`${statistics.indifferencePercentage}%`} {t('merchantProfile.labels.indifferent')}
                    </span>
                </div>
                <ProgressBarItem
                    percentage={statistics.indifferencePercentage}
                    classIdentifier={classIdentifier}
                />
            </div>
            <div className={"progress-bar-item"}>
                <div className={"descrption"}>
                    <span role="img" aria-label={"dislike"}>
                        <img src={dislikeIcon} alt={"dislikeicon"} width={20} />
                        {`${statistics.dislikesPercentage}%`} {t('merchantProfile.labels.dislikes')}
                    </span>
                </div>
                <div className="progress">
                    <div
                        className={`progress-bar bg-${classIdentifier}`}
                        role="progressbar"
                        style={{
                            width: `${statistics.dislikesPercentage}%`
                        }}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    ></div>
                </div>
            </div>
        </>
    );
};

export default RatingStatitsticsProgressBars;
