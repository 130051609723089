import React from 'react';
import heart from '../../screens/merchant-profile/icons/1.png';
import broken_heart from '../../screens/merchant-profile/icons/2.png';
import indifference from '../../screens/merchant-profile/icons/3.png';

interface GivenRateProps {
  rate: number;
  width?: string;
  className: string;
}

const GivenRate: React.FC<GivenRateProps> = ({ rate, width = '50px', className }) => {
  return (
    <>
      {rate === 1 && <img alt='broken_heart' src={broken_heart} width={width} className={className} />}
      {rate === 2 && <img alt='indifference' src={indifference} width={width} className={className} />}
      {rate === 3 && <img alt='heart' src={heart} width={width} className={className} />}
    </>
  )
}

export default GivenRate;
