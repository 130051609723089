import React from 'react'
import { IonIcon } from '@ionic/react'
import { cafe } from 'ionicons/icons'
import { API_BASE_URL } from '../../../config'
import apgLogo from '../../../assets/images/apg.png'

interface WalletEventRefTypeProps {
  walletEvent: any
  width?: string
}

const WalletEventRefType: React.FC<WalletEventRefTypeProps> = ({ walletEvent, width = '20px' }) => {
  return (
    <>
      {walletEvent.refType === 'TRANSFER' && (
        <img src='/assets/images/transaction.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'GROUP_TRANSFER_OPERATION_COST' && (
        <img src='/assets/images/transaction.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'CASHOUT' && (
        <img src='/assets/images/wallet_cashout.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'CASHIN' && (
        <img src='/assets/images/wallet_deposit.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'CASHIN_IDENTITY' && (
        <img src='/assets/images/wallet_deposit.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'CONVERTED_SCORE' && (
        <img src='/assets/images/coin.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'PURCHASE' && (
        <img
          src={`${API_BASE_URL}/public/purchases/department/${walletEvent.refPayment}/logo`}
          alt={walletEvent.refType}
          width={width}
        />
      )}
      {walletEvent.refType === 'MOBILE_CHARGE' && (
        <img src='/assets/images/mobile_charge.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'INTERNET_CHARGE' && (
        <img src='/assets/images/internet.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'REFUND_CASHOUT' && (
        <img src='/assets/images/wallet_deposit.png' alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'AYRIA_PAYMENT' && <img src={apgLogo} alt={walletEvent.refType} width={width} />}
      {walletEvent.refType === 'PURCHASE_SCORE' && (
        <img src={'/assets/images/coin.png'} alt={walletEvent.refType} width={width} />
      )}
      {walletEvent.refType === 'TIP' && <IonIcon icon={cafe} size='small' slot='icon-only' color='success' />}
      {!walletEvent.refType && <img src='/assets/images/wallet.png' alt={walletEvent.refType} width={width} />}
    </>
  )
}

export default WalletEventRefType
