import React, { useState, useEffect, useContext } from 'react'
import { AcceptorContext, AcceptorKala, AcceptorSetting, AcceptorSecretaries } from '../../../ayria'
import { AcceptorDeliveries, AcceptorWaiters, AcceptorManagers, KalaDisplayOrder } from '../../../ayria'
import { PaymentTools, AcceptorCashdesks, AcceptorTables } from '../../../ayria'

const Definitions = ({ isUsers = true }) => {
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [render, setRender] = useState(false)
  const [report, setReport] = useState(isUsers ? 'user' : 'kala')

  useEffect(() => {
    setRender(false)
    setTimeout(() => {
      setRender(true)
    }, 300)
  }, [acceptorContext?.currentIndex])

  return (
    <div>
      <div className='col-xs-12 report-tabs mb-2 overflow-auto'>
        {!isUsers && (
          <>
            <div className={'report-tab ' + (report === 'kala' ? 'active' : '')} onClick={() => setReport('kala')}>
              {'کالا'}
            </div>
            <div
              className={'report-tab ' + (report === 'service' ? 'active' : '')}
              onClick={() => setReport('service')}>
              {'خدمات'}
            </div>
          </>
        )}
        {isUsers && (
          <>
            <div className={'report-tab ' + (report === 'user' ? 'active' : '')} onClick={() => setReport('user')}>
              {'کاربر'}
            </div>
            <div
              className={'report-tab ' + (report === 'cashdesk' ? 'active' : '')}
              onClick={() => setReport('cashdesk')}>
              {'صندوق'}
            </div>
            {acceptorContext?.current?.department === 'RESTAURANT' && (
              <div className={'report-tab ' + (report === 'table' ? 'active' : '')} onClick={() => setReport('table')}>
                {'میز'}
              </div>
            )}
            <div
              className={'report-tab ' + (report === 'payment-tool' ? 'active' : '')}
              onClick={() => setReport('payment-tool')}>
              {'دستگاه های کارتخوان'}
            </div>
          </>
        )}
      </div>
      {render && (
        <div className='clear-both mt-3'>
          {report === 'kala' && <AcceptorKala acceptor={acceptorContext?.current} service={false} />}
          {report === 'service' && <AcceptorKala acceptor={acceptorContext?.current} service={true} />}
          {report === 'kala-display' && <KalaDisplayOrder acceptorCode={acceptorContext?.current?.code} />}
          {report === 'tax' && <AcceptorSetting />}
          {report === 'payment-tool' && <PaymentTools acceptorCode={acceptorContext?.current?.code} />}
          {report === 'cashdesk' && <AcceptorCashdesks acceptorCode={acceptorContext?.current?.code} />}
          {report === 'table' && <AcceptorTables acceptorCode={acceptorContext?.current?.code} />}
          {report === 'user' && (
            <>
              <AcceptorManagers acceptorCode={acceptorContext?.current?.code} />
              <AcceptorSecretaries acceptorCode={acceptorContext?.current?.code} />
              <AcceptorDeliveries acceptorCode={acceptorContext?.current?.code} />
              {acceptorContext?.current.department === 'RESTAURANT' && (
                <AcceptorWaiters acceptorCode={acceptorContext?.current?.code} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Definitions
