import React from 'react'
import { isMobile } from '../../../utility'
import MerchantDescription from './merchantDescription'

interface MerchantProfileBannerProps {
  merchant: any
}

const MerchantProfileBanner: React.FC<MerchantProfileBannerProps> = ({ merchant }) => {
  const { documents, documentPrefixUrl } = merchant
  const bannerItems = documents.filter(
    (element: any) => element.type === (isMobile() ? 'BANNER_LANDING' : 'BANNER_LANDING_DESKTOP')
  )

  const imageUrl = bannerItems.length && documentPrefixUrl + bannerItems[0].identifier

  return (
    <div
      className={'merchant-profile-banner'}
      style={{
        backgroundImage: `url('${imageUrl}')`,
        backgroundPosition: 'center center',
        backgroundSize: '100% auto',
      }}>
      <div className={'merchant-profile-banner_footer'}>
        <div className={'p-2'}>
          <div>
            <span className='h2' style={{ width: '80%' }}>
              {merchant.acceptorName}
            </span>
            <span className='left ion-padding'>
              {merchant?.delivery && (
                <span className='span-click-title' title='پیک'>
                  <img src='/assets/images/delivery.png' alt='' width='25' height='25' />
                </span>
              )}
              {merchant?.pickup && (
                <span className='span-click-title' title='حضوری'>
                  <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
                </span>
              )}
              {merchant?.tables && (
                <span className='span-click-title' title='سالن'>
                  <img src='/assets/images/tables.png' alt='' width='25' height='25' />
                </span>
              )}
            </span>
          </div>
          <MerchantDescription description={merchant.shortDescription} showLess={true} />
        </div>
      </div>
    </div>
  )
}

export default MerchantProfileBanner
