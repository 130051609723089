import React, { useState } from 'react'
import { IonPage, useIonViewWillEnter } from '@ionic/react'
import { Header, ScrollContent } from '../../ayria'
import OnlineOrderThread from './OnlineOrderThread'
import { isDesktop } from '../../utility'

const OnlineOrderThreadTab = () => {
  const [reRender, setReRender] = useState(true)

  useIonViewWillEnter(() => {
    setReRender(false)
    setTimeout(() => {
      setReRender(true)
    }, 300)
  })

  return (
    <IonPage id='online-order-thread-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <ScrollContent>
        <Header showBackButton={false} />
        <div className='ion-padding'>{reRender && <OnlineOrderThread />}</div>
      </ScrollContent>
    </IonPage>
  )
}

export default OnlineOrderThreadTab
