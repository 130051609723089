import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { ClubManagers } from '../../components'
import Wallet from '../../components/screens/wallet/wallet'
import { Club, User } from '../../models'
import VoucherEvents from '../voucher/VoucherEvents'
import MyClubAcceptors from './MyClubAcceptors'
import MyClubBanner from './MyClubBanner'
import MyClubEdit from './MyClubEdit'
import MyClubGroup from './MyClubGroup'
import MyClubLogo from './MyClubLogo'
import MyClubMembers from './MyClubMembers'
import MyClubScore from './MyClubScore'
import MyClubSpecs from './MyClubSpecs'

interface OwnProps {
  code: string
  loggedUser: User | any
  club: Club
}
interface MyClubTabDesktopProps extends OwnProps {}
const MyClubTabDesktop: FC<MyClubTabDesktopProps> = ({ code, loggedUser, club }) => {
  return (
    <div className='ion-padding'>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard style={{ height: '435px' }}>
              <IonCardContent>
                <MyClubEdit />
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard style={{ height: '435px' }}>
              <IonCardContent>
                <MyClubAcceptors code={code} itemsPerPage={3} />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardContent>
                <MyClubSpecs code={code} />
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <Wallet type={'club'} acceptorCode={code} />
              </IonCardContent>
            </IonCard>
            <IonCard style={{ minHeight: '500px' }}>
              <IonCardContent>
                <VoucherEvents code={code} type='club' />
              </IonCardContent>
            </IonCard>
            <IonCard style={{ minHeight: '500px' }}>
              <IonCardContent>
                <MyClubScore code={code} />
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard>
              <IonCardContent>
                <MyClubGroup />
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <MyClubMembers code={code} />
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                {(loggedUser?.authorities?.includes('ROLE_CLUB_OWNER') ||
                  loggedUser?.authorities?.includes('ROLE_ACCEPTOR_PARTNER')) &&
                  club?.owner && (
                    <>
                      <ClubManagers clubCode={code} />
                    </>
                  )}
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <MyClubLogo code={code} />
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <MyClubBanner code={code} />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default MyClubTabDesktop
