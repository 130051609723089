import React, { FC } from 'react'
import CurrencyIonInput from '../CurrencyInput/CurrencyIonInput'

interface NumberIonInputProps {
  onValueChange: React.Dispatch<React.SetStateAction<string | undefined>>
  componentDidMount?: Function
  debounce?: number
}

const NumberIonInput: FC<NumberIonInputProps> = ({
  onValueChange,
  componentDidMount = () => {},
  debounce,
  ...inputProps
}) => {
  return (
    <CurrencyIonInput
      maskOptions={{ includeThousandsSeparator: false, allowLeadingZeroes: true, allowNegative: false }}
      placeholder={'0'}
      componentDidMount={componentDidMount}
      onValueChange={onValueChange}
      debounce={debounce}
      {...inputProps}
    />
  )
}

export default NumberIonInput
