import React from 'react'
import { IonText } from '@ionic/react'

interface CopyRightProps {
  fixed?: boolean
}

const CopyRight: React.FC<CopyRightProps> = ({ fixed = false }) => {
  return (
    <div className={(fixed ? 'copy-right' : '') + ' ion-text-center'}>
      <IonText>
        تمامی حقوق این سایت برای شرکت زندگی سازان هوشمند آیریا محفوظ، و این باشگاه قدرت گرفته از{' '}
        <a href='https://ayriaplatform.com' target='_blank' rel='noopener noreferrer'>
          سامانه آیریا
        </a>{' '}
        می‌باشد.
      </IonText>
      <br />
      <IonText className='dir-ltr'>{process.env.REACT_APP_VERSION} &copy; Ayria </IonText>
    </div>
  )
}

export default CopyRight
