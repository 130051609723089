import React from 'react'
import CurrencyIonInput from '../CurrencyInput/CurrencyIonInput'

const MobileIonInput = ({ onValueChange, componentDidMount = () => {}, ...inputProps }) => {
  const onValueChangeFormatted = (value) => {
    if (value && !value?.startsWith('0')) {
      onValueChange('0' + value)
    } else {
      onValueChange(value)
    }
  }

  return (
    <CurrencyIonInput
      maskOptions={{ includeThousandsSeparator: false, allowLeadingZeroes: true, allowNegative: false }}
      placeholder={'09000000000'}
      onValueChange={onValueChangeFormatted}
      componentDidMount={componentDidMount}
      {...inputProps}
    />
  )
}

export default MobileIonInput
