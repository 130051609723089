import React, { FC, useState, useEffect } from 'react'
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonButton } from '@ionic/react'
import { IonItemDivider, IonAvatar, IonToggle, IonItemSliding, IonItemOptions } from '@ionic/react'
import { IonChip } from '@ionic/react'
import { IonItemOption, IonIcon, IonSpinner } from '@ionic/react'
import { trash, chevronForward } from 'ionicons/icons'
import { connect, NumberIonInput, ApiService, useToast, Modal, BillType } from '../../../ayria'
import { BillTypeImage } from '../../../ayria'
import BillPaymentWallet from './BillPaymentWallet'

interface OwnProps {
  acceptorCode?: string
}
interface BillPaymentProps extends OwnProps {}

const BillPayment: FC<BillPaymentProps> = ({ acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [billType, setBillType] = useState<string | undefined>('MOBILE')
  const [billId, setBillId] = useState<number>()
  const [paymentId, setPaymentId] = useState<number>()
  const [inprogress, setInprogress] = useState(false)
  const [templates, setTemplates] = useState([])
  const [amount, setAmount] = useState<number>()
  const [billIdentifier, setBillIdentifier] = useState<number>()
  const [paymentIdentifier, setPaymentIdentifier] = useState<number>()
  const [paymentBillType, setPaymentBillType] = useState<BillType>()
  const [paymentBillIdentifier, setPaymentBillIdentifier] = useState<string>()
  const [showPayModal, setShowPayModal] = useState(false)
  const [autoInprogress, setAutoInprogress] = useState(false)

  const getTemplates = async () => {
    setInprogress(true)
    const res: any = await api.get(
      `/customer/bill-templates/list/${!!acceptorCode ? acceptorCode : '0'}?sort=lastModifiedDate,desc`
    )
    if (res.ok) {
      setTemplates(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const create = async () => {
    setInprogress(true)
    const res: any = await api.post(`/customer/bill-templates/create`, {
      billType,
      billIdentifier: billId,
      acceptorCode,
    })
    if (res.ok) {
      const billTypeTemp = billType
      setBillType(undefined)
      setBillId(undefined)
      setTimeout(() => {
        setBillType(billTypeTemp)
      }, 100)
      await getTemplates()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const deleteTemplate = async (billTemplate: any) => {
    const res: any = await api.delete(`/customer/bill-templates/delete/${billTemplate.id}`)
    if (res.ok) {
      await getTemplates()
    } else {
      toast.error(res)
    }
  }

  const markAuto = async (billTemplate: any, auto: boolean) => {
    setAutoInprogress(true)
    const res: any = await api.put(`/customer/bill-templates/automate/${billTemplate.id}/${auto}`, {})
    if (res.ok) {
      if (res.data) {
        toast.success(auto ? 'پرداخت اتوماتیک قبض فعال شد' : 'پرداخت اتوماتیک قبض غیر فعال شد')
      }
      await getTemplates()
    } else {
      toast.error(res)
    }
    setAutoInprogress(false)
  }

  const inquiry = async () => {
    const res: any = await api.post(`/user/bill/pay/step1`, {
      billId,
      paymentId,
    })
    if (res.ok) {
      if (res.data.ok) {
        window.location = res.data.paymentLink
      } else {
        toast.error(res.data.statusDescription)
      }
    } else {
      toast.error(res)
    }
  }

  const inquiry2 = async (billTemplate: any) => {
    setInprogress(true)
    const res: any = await api.post(`/user/bill/inquiry`, {
      billType: billTemplate.billType,
      billId: billTemplate.billIdentifier,
    })
    if (res.ok) {
      if (res.status === 201) {
        toast.success('این قبض قبلا پرداخت شده است')
        return
      }
      if (res.data.ok && !!res.data.result.Amount) {
        setAmount(res.data.result.Amount)
        setBillIdentifier(res.data.result.BillId)
        setPaymentIdentifier(res.data.result.PayId)
        setPaymentBillType(billTemplate.billType)
        setPaymentBillIdentifier(billTemplate.billIdentifier)
        setShowPayModal(true)
      } else {
        toast.error(res.data.error)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const labelFor = (bt: BillType) => {
    switch (bt) {
      case BillType.WATER:
      case BillType.ELECTRICITY:
      case BillType.ELECTRICITY_STANDARD:
      case BillType.GAS_BILL:
        return 'شناسه قبض'
      case BillType.GAS:
        return 'شماره اشتراک'
      case BillType.TEL:
      case BillType.TEL_NOW:
        return 'شماره تلفن به همراه کد'
      case BillType.MOBILE:
      case BillType.MOBILE_NOW:
        return 'شماره همراه اول'
      default:
        return 'unknown'
    }
  }

  useEffect(() => {
    getTemplates()
  }, []) // eslint-disable-line

  return (
    <div id='bill-payment'>
      <IonItem>
        <IonLabel position='floating'>{'نوع قبض'}</IonLabel>
        <IonSelect
          value={billType}
          onIonChange={(e) => setBillType(e.detail.value)}
          okText='انتخاب'
          cancelText='انصراف'>
          <IonSelectOption value='WATER'>{'آب'}</IonSelectOption>
          <IonSelectOption value='TEL'>{'تلفن ثابت، پایان دوره'}</IonSelectOption>
          <IonSelectOption value='TEL_NOW'>{'تلفن ثابت، بروز'}</IonSelectOption>
          <IonSelectOption value='MOBILE'>{'تلفن همراه، پایان دوره'}</IonSelectOption>
          <IonSelectOption value='MOBILE_NOW'>{'تلفن همراه، بروز'}</IonSelectOption>
          <IonSelectOption value='ELECTRICITY'>{'برق'}</IonSelectOption>
          <IonSelectOption value='ELECTRICITY_STANDARD'>{'برق (پشتیبان)'}</IonSelectOption>
          <IonSelectOption value='GAS'>{'گاز'}</IonSelectOption>
          <IonSelectOption value='GAS_BILL'>{'گاز (شماره قبض)'}</IonSelectOption>
          <IonSelectOption value={null}>{'متفرقه'}</IonSelectOption>
        </IonSelect>
      </IonItem>
      {!billType && (
        <>
          <IonItem>
            <IonLabel>{'شناسه قبض'}</IonLabel>
            <NumberIonInput onValueChange={(value: any) => setBillId(value)} />
          </IonItem>
          <IonItem>
            <IonLabel>{'شناسه پرداخت'}</IonLabel>
            <NumberIonInput onValueChange={(value: any) => setPaymentId(value)} />
          </IonItem>
          <IonButton expand='full' className='ion-margin-top' onClick={() => inquiry()} disabled={inprogress}>
            {'استعلام'}
          </IonButton>
        </>
      )}
      {!!billType && (
        <>
          <IonItem>
            <IonLabel position='floating'>{labelFor(BillType[billType as keyof typeof BillType])}</IonLabel>
            <NumberIonInput onValueChange={(value: any) => setBillId(value)} />
          </IonItem>
          <IonButton
            expand='full'
            className='ion-margin-top'
            disabled={!billType || !billId || inprogress}
            onClick={() => create()}>
            {'ذخیره'}
          </IonButton>
        </>
      )}
      <IonItem style={{ marginTop: '100px' }}>
        <IonChip slot='end'>{'کارمزد قبض اتوماتیک ۱۰۰۰ تومان است'}</IonChip>
      </IonItem>
      <IonItemDivider>
        <IonLabel>{'قبض‌های ذخیره شده'}</IonLabel>
      </IonItemDivider>
      {autoInprogress && <IonSpinner name='crescent' color='dark' />}
      {!autoInprogress &&
        templates.map((t: any, index: number) => (
          <IonItemSliding key={t.billIdentifier + ' ' + index}>
            <IonItem>
              <IonAvatar slot='start'>
                <BillTypeImage billType={BillType[t.billType as keyof typeof BillType]} />
              </IonAvatar>
              <IonLabel>{t.billIdentifier}</IonLabel>
              {!autoInprogress && (!t.auto || t.billType === 'TEL_NOW' || t.billType === 'MOBILE_NOW') && (
                <IonButton slot='end' onClick={() => inquiry2(t)} disabled={inprogress}>
                  {'استعلام'}
                </IonButton>
              )}
              {!(t.billTyp === 'TEL_NOW' || t.billType === 'MOBILE_NOW') && (
                <IonToggle
                  disabled={autoInprogress}
                  slot='end'
                  checked={t.auto}
                  onIonChange={(e) => markAuto(t, e.detail.checked)}
                  title='پرداخت اتوماتیک'
                />
              )}
              <IonIcon icon={chevronForward} slot='end' />
            </IonItem>
            <IonItemOptions slot='end' style={{ width: '40px' }}>
              <IonItemOption onClick={() => deleteTemplate(t)} color='danger'>
                <IonIcon icon={trash} />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      <Modal
        showModal={showPayModal}
        toggle={setShowPayModal}
        title={'پرداخت قبض'}
        onClosed={() => setShowPayModal(false)}
        content={
          <BillPaymentWallet
            acceptorCode={acceptorCode}
            amount={amount}
            billId={billIdentifier}
            payId={paymentIdentifier}
            billType={paymentBillType}
            billIdentifier={paymentBillIdentifier}
            onSuccess={() => setShowPayModal(false)}
          />
        }
      />
    </div>
  )
}

export default connect<OwnProps, {}, {}>({
  component: BillPayment,
})
