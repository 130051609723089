import React, { useState, useEffect } from 'react'
import { save } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { IonContent, IonPage, IonItem, IonLabel, IonListHeader } from '@ionic/react'
import { IonInput, IonTextarea, IonIcon, IonButton, IonSpinner, IonRange } from '@ionic/react'
import { ApiService, useToast, Header } from '../../ayria'
import { isDesktop } from '../../utility'

interface OwnProps {}
interface MyClubRegisterTabProps extends OwnProps {}

const MyClubRegisterTab: React.FC<MyClubRegisterTabProps> = () => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const { acceptorCode } = useParams<any>()
  const [name, setName] = useState<any>()
  const [voucher, setVoucher] = useState<any>(50)
  const [discount, setDiscount] = useState<any>(50)
  const [description, setDescription] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const register = async () => {
    setInprogress(true)
    const res: any = await api.userRegisterClub({
      name,
      voucher,
      discount,
      description,
      acceptorCode,
    })
    if (res.ok) {
      toast.success('باشگاه با موفقیت ثبت شد')
      history.push(`/myclubs/${acceptorCode}`)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const isValid = () => {
    return name && description && discount + voucher === 100
  }

  useEffect(() => {
    setVoucher(100 - discount)
  }, [discount]) // eslint-disable-line

  return (
    <IonPage id='my-club-register-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <IonListHeader>
            <IonLabel>{'ثبت باشگاه جدید'}</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel position='stacked'>{'نام باشگاه'}</IonLabel>
            <IonInput value={name} onIonChange={(e) => setName(e.detail.value)} required={true} />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>{'توضیحات باشگاه'}</IonLabel>
            <IonTextarea value={description} onIonChange={(e) => setDescription(e.detail.value)} required={true} />
          </IonItem>
          <IonLabel position='stacked' className='ion-padding-start'>
            {'نسبت بین اسپوند و تخفیف'}
          </IonLabel>
          <IonItem>
            <IonRange
              pin={true}
              min={0}
              max={100}
              value={discount}
              color='secondary'
              onIonChange={(e: any) => setDiscount(e.detail.value)}>
              <IonLabel slot='start'>
                {'اسپوند'} {voucher}
              </IonLabel>
              <IonLabel slot='end'>
                {'تخفیف'} {discount}
              </IonLabel>
            </IonRange>
          </IonItem>
          <br />
          <IonButton disabled={!isValid() || inprogress} onClick={register}>
            {inprogress && <IonSpinner name='crescent' color='dark' />}
            <IonIcon icon={save} slot='start' />
            {'ثبت'}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default MyClubRegisterTab
