import React, { useRef } from 'react'
import { IonPage, IonContent, IonIcon, useIonViewDidEnter } from '@ionic/react'
import { Header } from '../../ayria'
import { logoInstagram } from 'ionicons/icons'
import lotteryBackground from './lottery-bg.png'
import { CLUB_INSTAGRAM } from '../../config'
import './LotteryTab.scss'
import { isDesktop } from '../../utility'

interface LotteryTabProps {}

const LotteryTab: React.FC<LotteryTabProps> = () => {
  const ionContent = useRef() as React.MutableRefObject<HTMLIonContentElement>

  useIonViewDidEnter(() => {
    ionContent.current.scrollToBottom(500)
  })

  return (
    <IonPage id='lottery-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent fullscreen ref={ionContent}>
        <Header />
        <div className='lottery-card'>
          <div className='insta_address ion-text-center'>
            <img src={lotteryBackground} alt='pic' />
            <div className='link'>
              <IonIcon icon={logoInstagram} color='instagram' />
              <a href={`https://www.instagram.com/${CLUB_INSTAGRAM}`} target='_blank' rel='noopener noreferrer'>
                {CLUB_INSTAGRAM}
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default LotteryTab
