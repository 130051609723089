import React, { useEffect } from 'react'
import { connect, setHasKala, setHasWaiter, setHasCashdesk, setHasDeliveryMan } from '../../../data'
import { setOnlineOrderCount, setSettings } from '../../../data'
import ApiService from '../../../api/ApiService'

interface OwnProps {
  acceptorCode: string
}
interface DispatchProps {
  setHasKala: typeof setHasKala
  setHasWaiter: typeof setHasWaiter
  setHasCashdesk: typeof setHasCashdesk
  setHasDeliveryMan: typeof setHasDeliveryMan
  setOnlineOrderCount: typeof setOnlineOrderCount
  setSettings: typeof setSettings
}
interface AcceptorHasFeatureLoaderProps extends OwnProps, DispatchProps {}

const AcceptorHasFeatureLoader: React.FC<AcceptorHasFeatureLoaderProps> = ({
  acceptorCode,
  setHasKala,
  setHasWaiter,
  setHasDeliveryMan,
  setHasCashdesk,
  setOnlineOrderCount,
  setSettings,
}) => {
  const api = new ApiService()

  const getOnlineOrderCount = async () => {
    const res: any = await api.acceptorRolesFactorsOnlineOrderCount(acceptorCode)
    if (res.ok) {
      setOnlineOrderCount(res.data)
    }
  }
  const getDeliveryManCount = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/delivery-man-count`)
    if (res.ok) {
      await setHasDeliveryMan(res.data)
    }
  }
  const getWaiterCount = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/waiter-count`)
    if (res.ok) {
      await setHasWaiter(res.data)
    }
  }
  const getKalaCount = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/kala-count`)
    if (res.ok) {
      await setHasKala(res.data)
    }
  }
  const getCashdeskCount = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/cashdesk-count`)
    if (res.ok) {
      await setHasCashdesk(res.data)
    }
  }
  const getSetting = async () => {
    const res: any = await api.get(`/acceptor/online-setting/${acceptorCode}`)
    if (res.ok) {
      setSettings(res.data)
    }
  }

  useEffect(() => {
    getKalaCount()
    getWaiterCount()
    getDeliveryManCount()
    getCashdeskCount()
    getOnlineOrderCount()
    getSetting()
  }, []) // eslint-disable-line

  return <></>
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setHasKala,
    setHasWaiter,
    setHasCashdesk,
    setHasDeliveryMan,
    setOnlineOrderCount,
    setSettings,
  },
  component: AcceptorHasFeatureLoader,
})
