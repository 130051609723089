import { ShopActions } from './shop.actions'
import { ShopState } from './shop.state'

export function shopReducer(state: ShopState, action: ShopActions): ShopState {
  switch (action.type) {
    case 'set-shop-data':
      return { ...state, ...action.data }
    case 'set-has-delivery-man':
      return { ...state, hasDeliveryMan: action.hasDeliveryMan }
    case 'set-has-waiter':
      return { ...state, hasWaiter: action.hasWaiter }
    case 'set-has-kala':
      return { ...state, hasKala: action.hasKala }
    case 'set-has-cashdesk':
      return { ...state, hasCashdesk: action.hasCashdesk }
    case 'set-send-delivery-status':
      return { ...state, sendDeliveryStatus: action.sendDeliveryStatus }
    case 'set-specify-waiter-status':
      return { ...state, specifyWaiterStatus: action.specifyWaiterStatus }
    case 'set-shift-time-from':
      return { ...state, shiftTimeFrom: action.shiftTimeFrom }
    case 'set-shift-time-to':
      return { ...state, shiftTimeTo: action.shiftTimeTo }
    case 'set-online-order-count':
      return { ...state, onlineOrderCount: action.onlineOrderCount }
    case 'set-current-cashdesk':
      return { ...state, currentCashdesk: action.currentCashdesk }
    case 'set-desk-kala-list':
      return { ...state, deskKalaList: action.deskKalaList }
    case 'add-desk-kala':
      return { ...state, addDeskKala: action.addDeskKala }
    case 'remove-desk-kala':
      return { ...state, removeDeskKala: action.removeDeskKala }
    case 'set-settings':
      return { ...state, settings: action.settings }
    case 'set-acceptor-code':
      return { ...state, acceptorCode: action.acceptorCode }
    case 'set-last-ayria-payment':
      return { ...state, lastAyriaPayment: action.lastAyriaPayment }
    case 'set-desk-factor-list':
      return { ...state, deskFactorList: action.deskFactorList }
    case 'add-desk-factor':
      return { ...state, addDeskFactor: action.addDeskFactor }
    case 'remove-desk-factor':
      return { ...state, removeDeskFactor: action.removeDeskFactor }
    case 'set-score':
      return { ...state, score: action.score }
    case 'set-socket-disconnected':
      return { ...state, socketDisconnected: action.socketDisconected }
    case 'set-my-club-code':
      return { ...state, myClubCode: action.myClubCode }
  }
}
