import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IonPage, IonContent } from '@ionic/react'
import ProfileFormAcceptor from '../../components/modules/profile-form-acceptor/profile-form-acceptor'
import { ApiService, Header } from '../../ayria'
import { isDesktop } from '../../utility'

interface OwnProps {}

interface MyCenterUpdateTabProps extends OwnProps {}

const MyCenterUpdateTab: React.FC<MyCenterUpdateTabProps> = () => {
  const api = new ApiService()
  const { acceptorId } = useParams<any>()
  const [acceptor, setAcceptor] = useState<any>()

  const getAcceptor = async () => {
    const res: any = await api.getAcceptorById(acceptorId)
    setAcceptor(res.ok ? res.data : null)
  }

  useEffect(() => {
    getAcceptor()
  }, []) // eslint-disable-line

  return (
    <IonPage id='my-center-update-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>{acceptor && <ProfileFormAcceptor user={acceptor} />}</div>
      </IonContent>
    </IonPage>
  )
}

export default MyCenterUpdateTab
