import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { bagAdd } from 'ionicons/icons'
import { IonContent, IonPage, IonButton, IonIcon, IonItem, useIonViewWillEnter } from '@ionic/react'
import { IonText, IonCard, IonCardContent, IonListHeader, IonLabel, IonItemSliding } from '@ionic/react'
import { IonItemOptions, IonItemOption } from '@ionic/react'
import { ApiService, connect, setHasSeenRegisterCenter, Header } from '../../ayria'
import { isDesktop } from '../../utility'

interface DispatchProps {
  setHasSeenRegisterCenter: typeof setHasSeenRegisterCenter
}

interface MyCenterListTabProp extends DispatchProps {}

const MyCenterListTab: React.FC<MyCenterListTabProp> = ({ setHasSeenRegisterCenter }) => {
  const api = new ApiService()
  const history = useHistory()
  const [centers, setCenters] = useState([])
  const [total, setTotal] = useState(0)
  const { t } = useTranslation()

  const getCenters = async () => {
    const res: any = await api.myCenters()
    if (res.ok) {
      setCenters(res.data)
      setTotal(res.data.reduce((a: number, b: any) => +a + +b.share, 0))
      if (res.data.length >= 1) {
        markNewFeatureRegisterCenterAsSeen()
      }
    }
  }

  const handleRowClick = (acceptor: any) => {
    if (acceptor.status === 'ACTIVE' && acceptor.code) {
      history.push(`/tabs/center/${acceptor.code}`)
    } else if (acceptor.kargozar) {
      history.push(`/tabs/mycenter/${acceptor.id}?tab=documents`)
    }
  }

  const markNewFeatureRegisterCenterAsSeen = async () => {
    await setHasSeenRegisterCenter(true)
  }

  useIonViewWillEnter(() => {
    getCenters()
  })

  return (
    <IonPage id='my-center-list-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <IonButton routerLink='/tabs/mycenternew'>
            <IonIcon slot='start' icon={bagAdd} />
            {'معرفی فروشگاه جدید'}
          </IonButton>
          <IonListHeader>
            <IonLabel>{'جمع درآمد ماه جاری'}</IonLabel>
            <IonButton color='success'>
              {total.toLocaleString(navigator.language)} {'ریال'}
            </IonButton>
          </IonListHeader>
          {centers.map((c: any) => (
            <IonItemSliding key={c.id}>
              <IonItem className='pointer' onClick={() => handleRowClick(c)}>
                <IonText slot='start'>{c.name}</IonText>
                {c.status === 'ACTIVE' && (
                  <IonText slot='end' color='success'>
                    {c.share?.toLocaleString(navigator.language)} {'ریال'}
                  </IonText>
                )}
                {c.status !== 'ACTIVE' && <IonText slot='end'>{t(`Status ${c.status}`)}</IonText>}
              </IonItem>
              <IonItemOptions slot='end' style={{ width: '40px' }}>
                <IonItemOption>
                  <IonText slot='end'>{t(`Status ${c.status}`)}</IonText>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
          <IonCard>
            <IonCardContent>
              {'با معرفی فروشگاه فروش کالا و خدمات به ما، پیوسته در درآمد آن فروشگاه با ما شریک باشید.'}
              <br />
              {
                'انجام این کار بسیار ساده است، کافی است با فروشگاه مذاکره نمایید و ضمن معرفی ما به ایشان، اطلاعات اولیه فروشگاه را در اینجا ثبت نمایید تا کارشناسان ما ادامه کار رو انجام دهند.'
              }
              <br />
              {
                'با هر فروش فروشگاه به اعضا، شما نیز درآمد کسب می‌نمایید و در پایان ماه این مبلغ به صورت امتیاز به حساب شما واریز می‌گردد.'
              }
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, {}, DispatchProps>({
  mapDispatchToProps: {
    setHasSeenRegisterCenter,
  },
  component: MyCenterListTab,
})
