import React, { useState, useEffect, useRef } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import ApiService from '../../../api/ApiService'
import { useToast, CustomModal } from '../../../ayria'
import './bank-cards.scss'
import ImageGallery from 'react-image-gallery'

declare var document: any

const BankCards = () => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toaster = useToast()
  const imageGalleryRef = useRef<any>()
  const [inprogress, setInprogress] = useState(false)
  const [token, setToken] = useState()
  const [cards, setCards] = useState([
    {
      renderItem: () => (
        <div className='bankCard bankOTHER ' style={{ minHeight: '180px' }}>
          <IonButton disabled={inprogress} onClick={addCard}>
            <IonIcon icon={add} />
            {'افزودن کارت'}
          </IonButton>
        </div>
      ),
    },
  ])
  const [showAddCardModal, setShowAddCardModal] = useState(false)
  const [showActivateCardModal, setShowActivateCardModal] = useState(false)
  const [invoice, setInvoice] = useState<any>({})
  const [missingNumbers, setMissingNumbers] = useState('')
  const [activeCard, setActiveCard] = useState<any>()
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [amount, setAmount] = useState(50000)

  const isMissingNumbersValid = () => {
    return missingNumbers && missingNumbers.length === 13 && !missingNumbers.includes('_')
  }

  const getBankCards = async () => {
    setInprogress(true)
    const cardsRes: any = await api.get('/user/cards')
    if (cardsRes.ok) {
      let c: any = cards
      // for (let a = 0;a<4;a++){
      for (let a = 0; a < cardsRes.data.length; a++) {
        c.push({
          renderItem: () => (
            <div
              className={`bankCard bank${cardsRes.data[a].bank} ion-text-center `}
              style={{ minHeight: '180px' }}
              onClick={() => {
                setActiveCard(cardsRes.data[a])
                setShowActivateCardModal(true)
              }}>
              <img
                alt={cardsRes.data[a].bankImg}
                className={'bankImg right'}
                src={`/assets/images/banks/${cardsRes.data[a].bankImg}-300-${
                  cardsRes.data[a].activated ? 'c' : 'g'
                }.png`}
              />

              <div className={(cardsRes.data[a].deleted ? 'line-through' : '') + ' h3 ltr'}>
                {cardsRes.data[a].mask.replace('********', '******')}
              </div>
            </div>
          ),
        })
      }
      setCards(c)
      // setCards(cardsRes.data)
    }
    setInprogress(false)
  }
  const addCard = async () => {
    setInprogress(true)
    const res: any = await api.get(`/user/cards/add-card-invoice/${amount}`)
    if (res.ok) {
      setInvoice(res.data)
      setShowAddCardModal(true)
    }
    setInprogress(false)
  }
  const activateCardForReal = async () => {
    setInprogress(true)
    const activateRes: any = await api.post('/user/cards/activate-card', {
      mask: activeCard.mask,
      missingNumbers: missingNumbers.replace(/-/g, '').replace(/\s/g, ''),
    })
    if (activateRes.ok) {
      toaster.success(t('label.successfulOperation'))
      // setCards(activateRes.data)
      setShowActivateCardModal(false)
    } else {
      toaster.error(activateRes)
    }
    setInprogress(false)
  }
  const deleteCardForReal = async () => {
    setInprogress(true)
    const res: any = await api.post('/user/cards/delete-card', {
      mask: activeCard.mask,
      missingNumbers: missingNumbers.replace(/-/g, '').replace(/\s/g, ''),
    })
    if (res.ok) {
      toaster.success('کارت شما ظرف مدت ۷۲ ساعت حذف خواهد شد')
      // setCards(res.data)
      setShowActivateCardModal(false)
    } else {
      toaster.error(res)
    }
    setInprogress(false)
  }
  const handleAddCardSubmit = () => {
    if (amount < 50000) {
      toaster.error('مبلغ باید عدد انگلیسی و حداقل 50,000 ریال باشد')
      return
    }
    setInprogress(true)
    api.post('/invoice/extra-info-wallet-deposit', { invoiceType: 'ADD_CARD' }).then((res: any) => {
      if (res.ok) {
        setInvoiceNumber(res.data.invoiceNumber)
        setToken(res.data.token)
        setTimeout(() => {
          document.getElementById('add-card-form').submit()
        }, 300)
      } else {
        toaster.error(res)
        setInprogress(false)
      }
    })
  }
  const isIpgFanava = () => {
    return invoice?.ipgCompany === 'FANAVA'
  }

  const isIpgNovin = () => {
    return invoice?.ipgCompany === 'NOVIN'
  }

  const isIpgSaman = () => {
    return invoice?.ipgCompany === 'SAMAN'
  }

  useEffect(() => {
    getBankCards()
    // eslint-disable-next-line
  }, [])

  return (
    <div id='bank-cards'>
      <h5 className='ion-padding-start'>{'کارت های بانکی من'}</h5>

      {cards && !inprogress && (
        <div className='py-3'>
          <ImageGallery
            // additionalClass='originalClassImageGallery'
            showNav={false}
            autoPlay={false}
            disableSwipe={false}
            infinite={false}
            // disableSwipe={formattedImages.length < 1}
            showFullscreenButton={false}
            showBullets={false}
            showThumbnails={false}
            showPlayButton={false}
            showIndex={true}
            items={cards}
            ref={imageGalleryRef}
          />
        </div>
      )}

      {activeCard && (
        <CustomModal
          title={activeCard.activated ? 'حذف کارت' : 'فعال سازی کارت'}
          showModal={showActivateCardModal}
          toggle={setShowActivateCardModal}
          onClosed={() => setMissingNumbers('')}
          content={
            activeCard && (
              <div className='text-center'>
                <div>{'لطفا به منظور ادامه عملیات، شماره کارت بانکی را کامل کنید'}</div>
                <br />
                {activeCard.mask.substring(activeCard.mask.lastIndexOf('*') + 1, activeCard.mask.length)}
                -
                <NumberFormat
                  format='# # - # # # #'
                  mask='_'
                  placeholder='_ _ - _ _ _ _'
                  onChange={(e) => setMissingNumbers(e.target.value)}
                  className='missing-numbers'
                />
                {activeCard.mask.includes('-') && activeCard.mask.substring(0, activeCard.mask.indexOf('*'))}
                {!activeCard.mask.includes('-') &&
                  activeCard.mask.substring(0, 4) + '-' + activeCard.mask.substring(4, activeCard.mask.indexOf('*'))}
                <br />
                <br />
                {!activeCard.activated && (
                  <IonButton disabled={inprogress || !isMissingNumbersValid()} onClick={activateCardForReal}>
                    فعال سازی
                  </IonButton>
                )}
                {activeCard.activated && (
                  <IonButton
                    color='danger'
                    disabled={inprogress || !isMissingNumbersValid()}
                    onClick={deleteCardForReal}>
                    حذف
                  </IonButton>
                )}
                <IonButton color='light' onClick={() => setShowActivateCardModal(false)}>
                  {t('label.cancel')}
                </IonButton>
              </div>
            )
          }
        />
      )}
      {invoice.actionUrl && (
        <CustomModal
          title='افزودن کارت'
          showModal={showAddCardModal}
          toggle={setShowAddCardModal}
          content={
            <div>
              <span className={'mb-5 text-justify'}>
                {' '}
                به منظور ثبت كارت بانكى ميبايست اطلاعات امنيتى كارت مورد نظر در اختيار دارنده باشد. لذا به منظور تائيد
                اطلاعات، شما بايد از طريق درگاه امن بانكى حداقل مبلغ <span className={'text-style1'}>5000</span> تومان
                پرداخت کنید. این مبلغ به کیف پول شما واریز خواهد شد و قابل خرج در تمام فروشگاه می‌باشد.
              </span>
              <hr />
              <div className='form-group'>
                <label htmlFor='amount'>{t('placeholder.amountRiyal')}</label>
                <NumberFormat
                  value={amount}
                  disabled={true}
                  thousandSeparator={true}
                  isNumericString={true}
                  onValueChange={(values) => {
                    const { value } = values
                    setAmount(+value)
                  }}
                  className='form-control'
                  inputMode='numeric'
                />
              </div>
              <div className='text-left'>
                <form method='post' action={invoice.actionUrl} className='bank-cards-form' id='add-card-form'>
                  {invoice.hiddens.map((hidden: any) => {
                    return <input type='hidden' name={hidden.name} value={hidden.value} key={hidden.name} />
                  })}
                  {isIpgFanava() && (
                    <>
                      <input type='hidden' name='Amount' value={amount} />
                      <input type='hidden' name={invoice?.invoiceNumberKey} value={invoiceNumber} />
                    </>
                  )}
                  {isIpgNovin() && <input type='hidden' name={invoice?.invoiceNumberKey} value={token} />}
                  {isIpgSaman() && <input type='hidden' name='Token' value={token} />}
                  <IonButton color='success' onClick={handleAddCardSubmit}>
                    {t('label.goToPayment')}
                  </IonButton>
                </form>
                <IonButton color='light' onClick={() => setShowAddCardModal(false)}>
                  {t('label.cancel')}
                </IonButton>
              </div>
            </div>
          }
        />
      )}
    </div>
  )
}

export default BankCards
