import React from 'react';
import male from '../../../assets/images/profile/male.jpeg';
import female from '../../../assets/images/profile/female.jpeg';

interface ProfilePictureProps {
  pictureName: string;
  width?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ pictureName, width = '50px' }) => {
  return (
    <>
      {pictureName === 'MALE' && <img alt='male' src={male} width={width} />}
      {pictureName === 'FEMALE' && <img alt='male' src={female} width={width} />}
    </>
  )
}

export default ProfilePicture;
