import React from 'react'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IS_AYRIA, IS_PERSEPOLIS, IS_KARAFARIN } from '../../ayria'

interface LandingMainProps {}

const LandingMain: React.FC<LandingMainProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <>
      <div className='landing__banner-container'>
        <div className='landing__wave'></div>
        <div className='landing__banner landing__banner_UNISEX'></div>
      </div>
      <div className='landing__welcome'>
        <div className='landing__welcome-text' style={{ marginRight: '10px' }}></div>
        <div className='landing__welcome-login-and-signup'>
          <IonButton onClick={() => history.push('/signup')} color='primary' expand='block'>
            {t('login.signup')}
          </IonButton>
          <IonButton onClick={() => history.push('/login')} color='light' expand='block'>
            {t('login.loginTitle')}
          </IonButton>
        </div>
      </div>
      <div className='landing__special_offer ion-padding'>
        {IS_AYRIA && <span>برای مشتریان ویژه اش یه پیشنهاد هیجان انگیز داره</span>}
        {IS_PERSEPOLIS && (
          <span>
            همه با هم این <span className='text-bold'>ارتش سرخ</span> را حمایت می‌کنیم
          </span>
        )}
        {IS_KARAFARIN && <span>همه در کنار هم و برای هم</span>}
      </div>
    </>
  )
}

export default LandingMain
