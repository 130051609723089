import React, { useState, useEffect } from 'react'
import { IonButton, IonSegment, IonSegmentButton } from '@ionic/react'
import { IonSpinner, IonItem, IonLabel } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import Timer from 'react-compound-timer'
import Select from 'react-select'
import { URLSearchParams } from 'whatwg-url'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import Nickname from '../../elements/Nickname/Nickname'
import NumberInput from '../../units/NumberInput/NumberInput'
import { convertToEnglish, ApiService, useToast, WebOTP, AyriaIcon, CustomModal } from '../../../ayria'
import './profile-form.css'
import { isMobile, isDesktop } from '../../../utility'
import BankCards from '../bank-cards/BankCards'

const ProfileForm = ({ user, t }) => {
  const api = new ApiService()
  const toaster = useToast()
  const history = useHistory()
  const [formObject, setFormObject] = useState(user)
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(window.location.search).get('tab')
      ? new URLSearchParams(window.location.search).get('tab')
      : 'personnal'
  )
  const [showBankMoreInfo, setShowBankMoreInfo] = useState(false)
  // eslint-disable-next-line
  const [profileContext, setProfileContext] = useState(ProfileContext)
  const [showNationalCodeValidationModal, setShowNationalCodeValidationModal] = useState(false)
  const [nationalCodeValidationResult, setNationalCodeValidationResult] = useState()
  const [showShebaNumberValidationModal, setShowShebaNumberValidationModal] = useState(false)
  const [shebaNumberValidationResult, setShebaNumberValidationResult] = useState()
  const [showNationalCodeVerificationStep1Modal, setShowNationalCodeVerificationStep1Modal] = useState(false)
  const [showNationalCodeVerificationStep2Modal, setShowNationalCodeVerificationStep2Modal] = useState(false)
  const [
    enableClosingNationalCodeVerificationStep2Modal,
    setEnableClosingNationalCodeVerificationStep2Modal,
  ] = useState(false)
  const [nationalCodeMobileNumber, setNationalCodeMobileNumber] = useState()
  const [nationalCodeVerificationStep1Result, setNationalCodeVerificationStep1Result] = useState()
  const [nationalCodeSmsCode, setNationalCodeSmsCode] = useState()
  const [showShebaNumberConvertorModal, setShowShebaNumberConvertorModal] = useState(false)
  const [ostans, setOstans] = useState([])
  const [defaultOstanLabel, setDefaultOstanLabel] = useState()

  const handleChange = (event) => {
    setFormObject({ ...formObject, [event.target.id]: convertToEnglish(event.target.value) })
  }

  async function handleSubmit() {
    setIsLoading(true)
    const res = await api.put('/account/profile', formObject)
    if (res.ok) {
      toaster.success('بروز رسانی با موفقیت انجام شد')
      setProfileContext((state) => ({ ...state, profile: res.data }))
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }
  async function verifyNationalCode() {
    setIsLoading(true)
    const res = await api.post(`/account/profile/verifyNationalCode`, formObject)
    if (res.ok) {
      if (res.data.verified) {
        setFormObject({ ...formObject, nationalCodeVerified: true })
        setProfileContext((state) => ({ ...state, profile: res.data.profile }))
      } else {
        setNationalCodeValidationResult(res.data.result)
        setShowNationalCodeValidationModal(true)
      }
    } else {
      if (res.status === 417) {
        setNationalCodeMobileNumber(formObject.mobile)
        setShowNationalCodeVerificationStep1Modal(true)
      } else {
        toaster.error(res)
      }
    }
    setIsLoading(false)
  }
  async function verifyShebaNumber() {
    setIsLoading(true)
    const res = await api.post(`/account/profile/verifyShebaNumber`, formObject)
    if (res.ok) {
      if (res.data.verified) {
        setFormObject({ ...formObject, shebaNumberVerified: true })
        setProfileContext((state) => ({ ...state, profile: res.data.profile }))
      } else {
        setShebaNumberValidationResult(res.data.result)
        setShowShebaNumberValidationModal(true)
      }
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }
  /*const getShebaFromBankAccountNumber = async () => {
      setIsLoading(true)
      const res = await api.post(`/account/profile/getShebaFromBankAccountNumber`, formObject)
      if (res.ok) {
        setFormObject({...formObject, bankShebaNumber: res.data.result.IBAN})
        setProfileContext(state => ({...state, profile: res.data.profile}))
      } else {
        toaster.error(res.data && res.data.title ? res.data.title : (t('label.operationFailed', {status: res.status})))
      }
      setIsLoading(false)
    }*/
  async function getShebaFromCardNumber() {
    setIsLoading(true)
    const res = await api.post(`/account/profile/getShebaFromBankCardNumber`, formObject)
    if (res.ok) {
      setFormObject({ ...formObject, bankShebaNumber: res.data.result.IBAN })
      setProfileContext((state) => ({ ...state, profile: res.data.profile }))
    } else {
      toaster.error(res)
    }
    setShowShebaNumberConvertorModal(false)
    setIsLoading(false)
  }
  async function changeShebaNumber() {
    setIsLoading(true)
    const res = await api.post('/account/profile/unverifyShebaNumber')
    if (res.ok) {
      setFormObject({ ...formObject, shebaNumberVerified: false })
      setProfileContext((state) => ({ ...state, profile: res.data }))
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }
  async function nationaCodeVerificationGetTokenStep1() {
    setIsLoading(true)
    setShowNationalCodeVerificationStep1Modal(false)
    const res = await api.post(`/account/profile/verifyNationalCodeGetTokenStep1/${nationalCodeMobileNumber}`)
    if (res.ok) {
      if (res.data.ok) {
        setNationalCodeVerificationStep1Result(res.data)
        setShowNationalCodeVerificationStep2Modal(true)
      } else {
        setShowNationalCodeVerificationStep1Modal(true)
      }
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }
  async function nationalCodeVerificationGetTokenStep2() {
    setIsLoading(true)
    setShowNationalCodeVerificationStep2Modal(false)
    const res = await api.post(`/account/profile/verifyNationalCodeGetTokenStep2`, {
      mobile: nationalCodeMobileNumber,
      otp: nationalCodeSmsCode,
      nid: formObject.nationalCode,
      trackId: nationalCodeVerificationStep1Result.result.trackId,
    })
    if (res.ok) {
      if (res.data.ok) {
        verifyNationalCode()
      } else {
        setShowNationalCodeVerificationStep1Modal(true)
      }
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }
  async function requestMobileReset() {
    setIsLoading(true)
    const res = await api.post(`/account/profile/reset-mobile/init`)
    if (res.ok) {
      toaster.success('لینک تغییر شماره همراه به شما پیامک شد.')
    } else {
      toaster.error(res)
    }
    setIsLoading(false)
  }

  const handleNextTab = () => {
    handleSubmit()
    if (activeTab === 'personnal') {
      setActiveTab('bank')
    } else if (activeTab === 'bank') {
      setActiveTab('contact')
    } else if (activeTab === 'contact') {
      setActiveTab('other')
    }
  }
  const handleClose = () => {
    handleSubmit()
    history.push('/tabs')
  }

  const getOstans = async () => {
    const res = await api.getOstans()
    if (res.ok) {
      res.data.forEach((item, i) => {
        res.data[i].label = item.name
        res.data[i].value = item.id
      })
      if (formObject.ostanId) {
        setDefaultOstanLabel(res.data.find((o) => o.id === formObject.ostanId).name)
      }
      setOstans(res.data)
    }
  }

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
    width: '100%',
  }

  useEffect(() => {
    getOstans()
  }, []) // eslint-disable-line

  return (
    <div>
      <IonSegment value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>
        <IonSegmentButton value='personnal'>{'اطلاعات هویتی'}</IonSegmentButton>
        <IonSegmentButton value='bank'>{'اطلاعات بانکی'}</IonSegmentButton>
        {isDesktop() && <IonSegmentButton value='contact'>{'اطلاعات تماس'}</IonSegmentButton>}
        {isDesktop() && <IonSegmentButton value='other'>{'اطلاعات تکمیلی'}</IonSegmentButton>}
      </IonSegment>
      {isMobile() && (
        <IonSegment value={activeTab} onIonChange={(e) => setActiveTab(e.detail.value)}>
          <IonSegmentButton value='contact'>{'اطلاعات تماس'}</IonSegmentButton>
          <IonSegmentButton value='other'>{'اطلاعات تکمیلی'}</IonSegmentButton>
        </IonSegment>
      )}
      {activeTab === 'personnal' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام</label>
              <input
                id={'firstName'}
                value={formObject.firstName}
                onChange={(e) => handleChange(e)}
                readOnly={formObject.nationalCodeVerified}
                className={formObject.nationalCodeVerified ? 'form-success' : 'form-control'}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام خانوادگی</label>
              <input
                id={'lastName'}
                value={formObject.lastName}
                onChange={(e) => handleChange(e)}
                readOnly={formObject.nationalCodeVerified}
                className={formObject.nationalCodeVerified ? 'form-success' : 'form-control'}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>جنسیت</label>
              <select
                id='gender'
                value={formObject.gender}
                onChange={(e) => handleChange(e)}
                disabled={formObject.nationalCodeVerified}
                className={formObject.nationalCodeVerified ? 'form-success' : 'form-control'}>
                <option></option>
                <option value='MALE'> مرد </option>
                <option value='FEMALE'> زن </option>
              </select>
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>نام پدر </label>
              <input
                id={'fatherName'}
                value={formObject.fatherName}
                onChange={(e) => handleChange(e)}
                readOnly={formObject.nationalCodeVerified}
                className={formObject.nationalCodeVerified ? 'form-success' : 'form-control'}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>شماره شناسنامه </label>
              <input
                id={'identityNumber'}
                value={formObject.identityNumber}
                onChange={(e) => handleChange(e)}
                className='dir-ltr form-control'
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>محل صدور </label>
              <input
                id={'registeredCity'}
                value={formObject.registeredCity}
                onChange={(e) => handleChange(e)}
                className='form-control'
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>تاریخ تولد (شمسی) </label>
              <br />
              <input
                id='birthdayYear'
                value={formObject.birthdayYear}
                onChange={(e) => handleChange(e)}
                style={{ width: '40%', float: 'left' }}
                placeholder='سال 1380'
                readOnly={formObject.nationalCodeVerified}
                className={'dir-ltr ' + (formObject.nationalCodeVerified ? 'form-success' : 'form-control')}
              />
              <input
                id='birthdayMonth'
                value={formObject.birthdayMonth}
                onChange={(e) => handleChange(e)}
                style={{ width: '20%', float: 'left' }}
                placeholder='ماه 1'
                readOnly={formObject.nationalCodeVerified}
                className={'dir-ltr ' + (formObject.nationalCodeVerified ? 'form-success' : 'form-control')}
              />
              <input
                id='birthdayDay'
                value={formObject.birthdayDay}
                onChange={(e) => handleChange(e)}
                style={{ width: '20%', float: 'left' }}
                placeholder='روز 29'
                readOnly={formObject.nationalCodeVerified}
                className={'dir-ltr ' + (formObject.nationalCodeVerified ? 'form-success' : 'form-control')}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>
                کد ملی
                {formObject.nationalCodeVerified && (
                  <AyriaIcon
                    icon={checkmark}
                    slot='start'
                    color={formObject.nationalCodeVerifiedManually ? 'warning' : 'success'}
                  />
                )}
              </label>
              <input
                id={'nationalCode'}
                value={formObject.nationalCode}
                onChange={(e) => handleChange(e)}
                readOnly={formObject.nationalCodeVerified}
                className={'dir-ltr ' + (formObject.nationalCodeVerified ? 'form-success' : 'form-control')}
              />
            </div>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>
                شماره همراه{' '}
                <IonButton color='warning' disabled={isLoading} onClick={requestMobileReset}>
                  {'ارسال درخواست تغییر شماره همراه'}
                </IonButton>
              </label>
              <input
                id={'mobile'}
                value={formObject.mobile}
                onChange={(e) => handleChange(e)}
                readOnly
                className='dir-ltr form-control'
              />
            </div>
          </div>
          <IonItem>
            <IonLabel position='floating'>{t('label.nickname')}</IonLabel>
            <Nickname />
          </IonItem>
        </div>
      )}

      {activeTab === 'bank' && (
        <div className='mt-3'>
          <BankCards />

          <div className='row' style={{ paddingRight: '20px' }}>
            <p style={{ fontWeight: 'bold' }}>
              {'به منظور واریز وجه کیف پول به حساب شما، شماره شبای به نام  خود را ثبت کنید.'}
              <IonButton style={{ marginRight: '50px' }} onClick={(e) => setShowBankMoreInfo(true)}>
                بیشتر بدانید
              </IonButton>
            </p>
          </div>
          <div className='row'></div>
          <div className='row'>
            <div className='form-group col-md-6 col-xs-12 p-0'>
              <label>
                شماره شبا
                {!formObject.nationalCodeVerified && (
                  <span className='border-color-06'>{'ابتدا اطلاعات هویتی خود را تایید نمایید'}</span>
                )}
                {formObject.nationalCodeVerified && formObject.shebaNumberVerified && <AyriaIcon icon={checkmark} />}
              </label>
              <div className='input-group ltr p-0'>
                <NumberFormat
                  onValueChange={(values) => {
                    setFormObject({ ...formObject, bankShebaNumber: 'IR' + convertToEnglish(values.value) })
                  }}
                  format={'IR## ### ### ### ### ### ### ### #'}
                  mask={'_'}
                  value={(formObject.bankShebaNumber || 'IR').substring(2)}
                  inputMode='numeric'
                  prefix={'$'}
                  readOnly={formObject.shebaNumberVerified}
                  className={formObject.shebaNumberVerified ? 'form-success p-0' : 'form-control sheba-number p-0'}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'contact' && (
        <div className={'mt-3'}>
          <div className='form-group'>
            <label>استان</label>
            {ostans?.length > 0 && (
              <Select
                id='ostanId'
                name='ostanId'
                onChange={(item) => setFormObject({ ...formObject, ostanId: item.value })}
                defaultValue={{ value: formObject.ostanId, label: defaultOstanLabel }}
                options={ostans}
                placeholder={'استان'}
                style={styles}
              />
            )}
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>تلفن </label>
              <input
                id={'tel'}
                value={formObject.tel}
                onChange={(e) => handleChange(e)}
                type={'number'}
                min={'0'}
                className='dir-ltr form-control'
              />
            </div>
            <div className={'form-group col-md-8 col-xs-12'}>
              <label>آدرس </label>
              <input
                id={'address'}
                value={formObject.address}
                onChange={(e) => handleChange(e)}
                className='form-control'
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-4 col-xs-12'}>
              <label>تلفن محل کار </label>
              <input
                id={'workTel'}
                value={formObject.workTel}
                onChange={(e) => handleChange(e)}
                type={'number'}
                min={'0'}
                className='dir-ltr form-control'
              />
            </div>
            <div className={'form-group col-md-8 col-xs-12'}>
              <label>آدرس محل کار </label>
              <input
                id={'workAddress'}
                value={formObject.workAddress}
                onChange={(e) => handleChange(e)}
                className='form-control'
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'other' && (
        <div className={'mt-3'}>
          <div className={'row'}>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>میزان تحصیلات</label>
              <select
                id={'educationDegree'}
                value={formObject.educationDegree}
                onChange={(e) => handleChange(e)}
                className={'form-control'}>
                <option></option>
                <option value='ELEMENTARY'> ابتدایی </option>
                <option value='GENERAL'> سیکل </option>
                <option value='DIPLOMA'> دیپلم </option>
                <option value='ASSOCIATE'> فوق دیپلم </option>
                <option value='BACHELOR'> لیسانس </option>
                <option value='MASTER'> فوق لیسانس </option>
                <option value='DOCTORATE'> دکترا </option>
              </select>
            </div>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>شغل</label>
              <input
                id={'jobTitle'}
                value={formObject.jobTitle}
                onChange={(e) => handleChange(e)}
                className='form-control'
              />
            </div>
          </div>
          <div className={'row'}>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>وضعیت تاهل</label>
              <select
                id={'maritalStatus'}
                value={formObject.maritalStatus}
                onChange={(e) => handleChange(e)}
                className={'form-control'}>
                <option></option>
                <option value='SINGLE'> مجرد </option>
                <option value='MARRIED'> متاهل </option>
              </select>
            </div>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label> تعداد فرزندان</label>
              <input
                id={'children'}
                value={formObject.children}
                onChange={(e) => handleChange(e)}
                type={'number'}
                min={'0'}
                className='dir-ltr form-control'
              />
            </div>
          </div>
          <div className='row'>
            <div className={'form-group col-md-6 col-xs-12'}>
              <label>آدرس آیمیل </label>
              <input
                id={'email'}
                value={formObject.email}
                onChange={(e) => handleChange(e)}
                type={'email'}
                className='dir-ltr form-control'
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <hr></hr>
        <div className='form-group text-left'>
          <input type='hidden' value={formObject.langKey} />
          <input type='hidden' value={formObject.imageUrl} />
          {isLoading && <IonSpinner name='crescent' color='dark' />}
          {activeTab === 'bank' && formObject.nationalCodeVerified && formObject.shebaNumberVerified && (
            <IonButton onClick={changeShebaNumber} disabled={isLoading}>
              {'تغییر شماره شبا'}
            </IonButton>
          )}
          {activeTab === 'bank' && formObject.nationalCodeVerified && !formObject.shebaNumberVerified && (
            <IonButton color='success' onClick={verifyShebaNumber} disabled={isLoading}>
              {'تطبیق شماره شبا با اطلاعات هویتی'}
            </IonButton>
          )}
          {activeTab === 'personnal' && (!formObject.nationalCodeVerified || formObject.nationalCodeVerifiedManually) && (
            <IonButton onClick={verifyNationalCode} color='success' disabled={isLoading}>
              {'تایید اطلاعات هویتی'}
            </IonButton>
          )}
          <IonButton disabled={isLoading} onClick={handleSubmit}>
            ذخیره
          </IonButton>
          {activeTab !== 'other' && (
            <IonButton color='light' onClick={handleNextTab}>
              بعدی
            </IonButton>
          )}
          {activeTab === 'other' && (
            <IonButton color='light' onClick={handleClose}>
              بستن
            </IonButton>
          )}
        </div>
      </div>
      <CustomModal
        showModal={showBankMoreInfo}
        toggle={setShowBankMoreInfo}
        title={'اطلاعات بیشتر'}
        content={
          <div>
            <p>
              عدم درج شماره حساب در این قسمت یعنی محرومیت از یک دنیا امکانات خوب و مفید در شبکه پرداخت. سامانه آیریا ضمن
              کسب مجوزات لازم از شبکه بانکی امکانات و ابزار بسیاری را در شبکه پرداخت به منظور سهولت ارائه خدمات برای شما
              بوجود آورده است. که از این جمله امکان واریز پول به شماره همراه شما و همچنین استفاده از کیف پول الکترونیکی
              در فروشگاه طرف قرارداد سامانه می‌باشد. برای این منظور نیاز است تا شما شماره حساب مورد نظر خود را در این
              قسمت ثبت نمایید تا وجوهی که به شماره همراه شما منتقل می‌گردد به این حساب واریز شود. همچنین چنانچه بخواهید
              وجوه داخل کیف پول خود را به حساب بانکی خود واریز نمایید، در حساب ثبت شده در این قسمت کارسازی خواهد شد.
            </p>
          </div>
        }
      />
      <CustomModal
        showModal={showNationalCodeVerificationStep1Modal}
        toggle={setShowNationalCodeVerificationStep1Modal}
        title={'فرم اطلاعات هویتی'}
        content={
          <div>
            <p>
              {'به منظور حفظ و افزایش امنیت حساب کاربری، یکسان بودن اطلاعات هویتی با کد ملی درج شده را استعلام نمایید.'}
            </p>
            <br />
            <div className='form-group'>
              <label>
                {'شماره همراه'}{' '}
                <span className='text-danger'>{'(دقت شود مالکیت این سیم کارت به نام شما ثبت شده باشد)'}</span>
              </label>
              <NumberInput
                onValueChange={(value) => setNationalCodeMobileNumber(value)}
                value={nationalCodeMobileNumber}
              />
            </div>
            <br />
            <div className='text-center'>
              <IonButton onClick={nationaCodeVerificationGetTokenStep1} disabled={isLoading}>
                {'استعلام'}
              </IonButton>
            </div>
          </div>
        }
      />
      <CustomModal
        showModal={showShebaNumberConvertorModal}
        toggle={setShowShebaNumberConvertorModal}
        title={'فرم تبدیل شماره کارت به شماره شبا'}
        content={
          <div>
            <div className='form-group'>
              <label>شماره کارت</label>
              <input
                id='bankCardNumber'
                value={formObject.bankCardNumber}
                onChange={(e) => handleChange(e)}
                type='number'
                className='dir-ltr form-control'
              />
            </div>
            <div className='text-center'>
              <IonButton onClick={getShebaFromCardNumber} disabled={isLoading}>
                {'گرفتن شماره شبا'}
              </IonButton>
            </div>
          </div>
        }
      />
      <CustomModal
        showModal={showNationalCodeVerificationStep2Modal}
        toggle={setShowNationalCodeVerificationStep2Modal}
        title={'تایید کد پیامکی'}
        content={
          <div>
            <p>{'کد پیامک ارسال شده را اینجا وارد نمایید'}</p>
            <div className='form-group'>
              <label>{'کد'}</label>
              <NumberInput onValueChange={(value) => setNationalCodeSmsCode(value)} value={nationalCodeSmsCode} />
              <WebOTP onReceive={(code) => setNationalCodeSmsCode(code)} />
            </div>
            <br />
            {!enableClosingNationalCodeVerificationStep2Modal && (
              <div>
                <Timer
                  initialTime={180000}
                  direction='backward'
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => setEnableClosingNationalCodeVerificationStep2Modal(true),
                    },
                  ]}>
                  لطفا این صفحه را تا " <Timer.Seconds /> : <Timer.Minutes />" دقیقه دیگر باز نگه دارید.
                </Timer>
              </div>
            )}
            <br />
            <div className='text-center'>
              {!enableClosingNationalCodeVerificationStep2Modal && (
                <IonButton
                  onClick={nationalCodeVerificationGetTokenStep2}
                  disabled={isLoading}
                  className='btn btn-theme'>
                  {'ثبت'}
                </IonButton>
              )}
              {enableClosingNationalCodeVerificationStep2Modal && (
                <div>
                  <IonButton onClick={() => setShowNationalCodeVerificationStep2Modal(false)} className='btn btn-theme'>
                    {'بستن'}
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        }
      />
      <CustomModal
        showModal={showNationalCodeValidationModal}
        toggle={setShowNationalCodeValidationModal}
        title={'نتیجه تطبیق کد ملی با مشخصات هویتی'}
        content={
          nationalCodeValidationResult && (
            <>
              <div>
                {
                  'در قسمت‌هایی که با کادر قرمز مشخص شده، موارد درج شده توسط شما با اطلاعات ثبت شده در سازمان ثبت احوال مغایرت دارد.'
                }
                <br />
                {'(عدد انطباق هر مورد باید ۱۰۰ باشد )'}
              </div>
              <table className='table'>
                <thead></thead>
                <tbody>
                  <tr className={nationalCodeValidationResult.firstNameSimilarity !== 100 ? 'border-color-06' : null}>
                    <td>{'نام'}</td>
                    <td>{nationalCodeValidationResult.firstName}</td>
                    <td>
                      {nationalCodeValidationResult.firstNameSimilarity === 100 && (
                        <span className='text-success'>100</span>
                      )}
                      {nationalCodeValidationResult.firstNameSimilarity !== 100 && (
                        <span className='text-danger'>{nationalCodeValidationResult.firstNameSimilarity}</span>
                      )}
                    </td>
                  </tr>
                  <tr className={nationalCodeValidationResult.lastNameSimilarity !== 100 ? 'border-color-06' : null}>
                    <td>{'نام خانوادگی'}</td>
                    <td>{nationalCodeValidationResult.lastName}</td>
                    <td>
                      {nationalCodeValidationResult.lastNameSimilarity === 100 && (
                        <span className='text-success'>100</span>
                      )}
                      {nationalCodeValidationResult.lastNameSimilarity !== 100 && (
                        <span className='text-danger'>{nationalCodeValidationResult.lastNameSimilarity}</span>
                      )}
                    </td>
                  </tr>
                  <tr className={nationalCodeValidationResult.fatherNameSimilarity !== 100 ? 'border-color-06' : null}>
                    <td>{'نام پدر'}</td>
                    <td>{nationalCodeValidationResult.fatherName}</td>
                    <td>
                      {nationalCodeValidationResult.fatherNameSimilarity === 100 && (
                        <span className='text-success'>100</span>
                      )}
                      {nationalCodeValidationResult.fatherNameSimilarity !== 100 && (
                        <span className='text-danger'>{nationalCodeValidationResult.fatherNameSimilarity}</span>
                      )}
                    </td>
                  </tr>
                  <tr className={nationalCodeValidationResult.genderSimilarity !== 100 ? 'border-color-06' : null}>
                    <td>{'جنسیت'}</td>
                    <td>{nationalCodeValidationResult.gender}</td>
                    <td>
                      {nationalCodeValidationResult.genderSimilarity === 100 && (
                        <span className='text-success'>100</span>
                      )}
                      {nationalCodeValidationResult.genderSimilarity !== 100 && (
                        <span className='text-danger'>{nationalCodeValidationResult.genderSimilarity}</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )
        }
      />
      <CustomModal
        showModal={showShebaNumberValidationModal}
        toggle={setShowShebaNumberValidationModal}
        title={'نتیجه تطبیق شماره شبا با مشخصات هویتی'}
        content={
          shebaNumberValidationResult && (
            <table className='table'>
              <thead></thead>
              <tbody>
                <tr
                  className={
                    formObject.firstName !== shebaNumberValidationResult.firstNameExpected ? 'border-color-06' : null
                  }>
                  <td>{'نام'}</td>
                  <td>{formObject.firstName}</td>
                  <td>
                    {formObject.firstName === shebaNumberValidationResult.firstNameExpected && (
                      <AyriaIcon icon={checkmark} />
                    )}
                    {formObject.firstName !== shebaNumberValidationResult.firstNameExpected &&
                      shebaNumberValidationResult.firstNameExpected}
                  </td>
                </tr>
                <tr
                  className={
                    formObject.lastName !== shebaNumberValidationResult.lastNameExpected ? 'border-color-06' : null
                  }>
                  <td>{'نام خانوادگی'}</td>
                  <td>{formObject.lastName}</td>
                  <td>
                    {formObject.lastName === shebaNumberValidationResult.lastNameExpected && (
                      <AyriaIcon icon={checkmark} />
                    )}
                    {formObject.lastName !== shebaNumberValidationResult.lastNameExpected &&
                      shebaNumberValidationResult.lastNameExpected}
                  </td>
                </tr>
              </tbody>
            </table>
          )
        }
      />
    </div>
  )
}

export default withTranslation()(ProfileForm)
