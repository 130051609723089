import React, { useState, useEffect } from 'react'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import CurrencyInput from '../../units/CurrencyInput/CurrencyInput'
import { useToast } from '../../../ayria'

declare var document: any

interface WalletDepositProps {
  amount: string
  targetAmount?: string | number
  acceptorCode?: string
  originalCommand?: string
  originalCommandType?: string
  onSubmit?: () => void
  onChange?: () => void
  onLeave?: Function
  theme?: string
}

const WalletDeposit: React.FC<WalletDepositProps> = ({
  amount,
  acceptorCode,
  originalCommand,
  originalCommandType,
  onChange,
  onSubmit,
  onLeave,
  theme = 'normal',
  targetAmount,
}) => {
  const { t } = useTranslation()
  const toaster = useToast()
  const [invoice, setInvoice] = useState<any>({})
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [token, setToken] = useState('')
  const [inprogress, setInprogress] = useState(false)
  const [dynamicAmount, setDynamicAmount] = useState<string>(amount || '')

  const isAmountInvalid = () => {
    if (amount) {
      return dynamicAmount < amount
    } else {
      return false
    }
  }

  const isIpgFanava = () => {
    return invoice?.ipgCompany === 'FANAVA'
  }

  const isIpgNovin = () => {
    return invoice?.ipgCompany === 'NOVIN'
  }

  const isIpgSaman = () => {
    return invoice?.ipgCompany === 'SAMAN'
  }

  const getWalletDepositInvoice = async () => {
    const invoiceRes: any = await api.post('/wallet/deposit-invoice', null)
    setInvoice(invoiceRes.data)
  }
  const handleSubmit = async () => {
    setInprogress(true)
    const res: any = await api.post('/invoice/extra-info-wallet-deposit', {
      amount: dynamicAmount,
      ipgCompany: invoice.ipgCompany,
      invoiceType: 'DEPOSITE_TO_WALLET',
      acceptorCode,
      originalCommand,
      originalCommandType,
    })
    if (res.ok) {
      setInvoiceNumber(res.data.invoiceNumber)
      setToken(res.data.token)
      if (onLeave) {
        onLeave()
      }
      setTimeout(() => {
        document.getElementById('form').submit()
      }, 300)
    } else {
      toaster.error(res)
      setInprogress(false)
    }
  }
  useEffect(() => {
    getWalletDepositInvoice()
  }, [])

  return (
    <>
      {invoice?.actionUrl && (
        <div style={{ textAlign: 'right' }}>
          <div className={'mb-5'}>
            {targetAmount && (
              <div>
                <span>
                  {t('WalletDeposit.message', {
                    targetAmount: (+targetAmount).toLocaleString(navigator.language),
                    amount:
                      +amount < +targetAmount
                        ? (+amount).toLocaleString(navigator.language)
                        : (+targetAmount).toLocaleString(navigator.language),
                  })}
                </span>
                <br />
                <br />
              </div>
            )}
            <span>
              مبلغ مورد نظر شما برای افزایش موجودی : {(+dynamicAmount).toLocaleString(navigator.language)}{' '}
              {t('label.riyal')}{' '}
            </span>
            <br />
            <br />
            <div className='form-group'>
              <CurrencyInput onValueChange={setDynamicAmount} defaultValue={dynamicAmount} />
            </div>
          </div>
          <div className={'text-left'}>
            <form method='post' action={invoice?.actionUrl} className='bank-cards-form' id='form'>
              {invoice?.hiddens.map((hidden: any, index: number) => {
                return <input type='hidden' name={hidden.name} value={hidden.value} key={hidden.name} />
              })}
              {isIpgFanava() && (
                <>
                  <input type='hidden' name='Amount' value={dynamicAmount} />
                  <input type='hidden' name={invoice?.invoiceNumberKey} value={invoiceNumber} />
                </>
              )}
              {isIpgNovin() && <input type='hidden' name={invoice?.invoiceNumberKey} value={token} />}
              {isIpgSaman() && <input type='hidden' name='Token' value={token} />}
              <IonButton onClick={handleSubmit} disabled={inprogress || isAmountInvalid()}>
                {t('label.goToPayment')}
              </IonButton>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default WalletDeposit
