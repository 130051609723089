import React, { useState, useContext, useRef, useEffect } from 'react'
import { IonButton, IonSpinner, IonToggle, IonItem, IonLabel } from '@ionic/react'
import { IonInput, IonIcon } from '@ionic/react'
import { eye } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CLUB, ApiService, AyriaReCaptcha, useToast, MobileSuggest } from '../../../ayria'
import { connect, LoginContext, CustomModal, Club } from '../../../ayria'
import Rules from './rules.js'
import '../../../App-deprecated.scss'

interface StateProps {
  savedClub?: Club
}
interface SignupFormStepOneProps extends StateProps {}

const SignupForm: React.FC<SignupFormStepOneProps> = ({ savedClub }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const history = useHistory()
  const [dataObject, setDataObject] = useState<any>({})
  const [showRules, setShowRules] = useState(false)
  const [errorList, setErrorList] = useState<any>([])
  const [loginContext, setLoginContext] = useContext<any>(LoginContext) // eslint-disable-line
  const [inprogress, setInprogress] = useState(false)
  const [captchaInprogress, setCaptchaInprogress] = useState(false)
  const [userExists, setUserExists] = useState(false)
  const containerRef = useRef<any>()
  const containerCur = containerRef?.current || { scrollIntoView: () => {} }
  const [captchaToken, setCaptchaToken] = useState()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSignup = async () => {
    setInprogress(true)
    dataObject.login = dataObject.mobile
    dataObject.langKey = 'fa'
    dataObject.captchaToken = captchaToken
    if (savedClub?.code) {
      dataObject.clubCode = savedClub.code
    } else {
      dataObject.clubType = CLUB
    }
    const response: any = await api.post('/register', dataObject)
    if (response.ok) {
      setLoginContext((state: any) => ({ ...state, login: dataObject.login, password: dataObject.password }))
      history.push({
        pathname: 'activate',
        search: `${window.location.search}${window.location.search ? '&' : '?'}mobile=${dataObject.login}`,
      })
    } else {
      const errors = []
      if (
        response?.data?.message === 'error.userexists' ||
        response?.data?.message === 'error.mobileexists' ||
        response?.data?.message === 'error.inactiveuser'
      ) {
        errors.push(
          '<span>این شماره ثبت نام شده است لطفا از گزینه "<a href="/login">ورود به پنل کاربری</a>" استفاده نمایید </span>'
        )
        setTimeout(() => {
          containerCur.scrollIntoView()
        }, 300)
      } else {
        toast.error(response)
      }
      setErrorList(errors)
      setCaptchaToken(undefined)
      setDataObject({ ...dataObject, captchaToken: undefined })
    }
    setInprogress(false)
  }

  const validateForm = () => {
    const errors = []
    if (dataObject.password !== dataObject.confirmPassword) {
      errors.push(t('common_errors.password_not_matching_violation'))
    }
    if (!dataObject.firstName) {
      errors.push(t('common_errors.name_is_required'))
    }
    if (!dataObject.lastName) {
      errors.push(t('common_errors.last_name_is_required'))
    }
    if (!dataObject.mobile) {
      errors.push(t('common_errors.mobile_is_required'))
    }
    if (!dataObject.password) {
      errors.push('رمز عبور الزامی است')
    }
    return errors
  }

  const isValid = () => {
    return (
      !!dataObject.password &&
      !!dataObject.confirmPassword &&
      !!dataObject.firstName &&
      !!dataObject.lastName &&
      !!dataObject.mobile
    )
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const errors = validateForm()
    if (errors.length) {
      setErrorList(errors)
      setTimeout(() => {
        containerCur.scrollIntoView()
      }, 300)
    } else {
      handleSignup()
    }
  }

  const captchaCallback = (token: string) => {
    setCaptchaToken(token)
    setCaptchaInprogress(false)
  }

  const iAmNotARobot = async () => {
    setCaptchaInprogress(true)
    const token = await executeRecaptcha('register')
    setCaptchaToken(token)
    setCaptchaInprogress(false)
  }

  useEffect(() => {}, [captchaToken])

  return (
    <form>
      <div className={'container'} ref={containerRef}>
        <div>
          {errorList.map((element: any) => (
            <div key={element} className={'alert alert-danger'} dangerouslySetInnerHTML={{ __html: element }}></div>
          ))}
        </div>
        <div className={'form-group '} style={{ borderBottom: '1px solid lightgray' }}>
          <label>{t('label.mobile')}</label>
          <MobileSuggest
            value={dataObject.mobile}
            onMobileChange={(value: any) => {
              setDataObject({ ...dataObject, mobile: value })
              setUserExists(false)
            }}
            onUserChange={() => setUserExists(true)}
            style={{ border: 'none' }}
          />
        </div>
        {!userExists && (
          <>
            <IonItem>
              <IonLabel position='floating'>{t('label.name')}</IonLabel>
              <IonInput onIonChange={(e) => setDataObject({ ...dataObject, firstName: e.detail.value })} />
            </IonItem>
            <IonItem>
              <IonLabel position='floating'>{t('label.lastName')}</IonLabel>
              <IonInput onIonChange={(e) => setDataObject({ ...dataObject, lastName: e.detail.value })} />
            </IonItem>
            z
            <IonItem>
              <IonLabel>{t('label.password')}</IonLabel>
              <IonInput
                onIonChange={(e) => setDataObject({ ...dataObject, password: e.detail.value })}
                className='dir-ltr'
                type='password'
              />
            </IonItem>
            <IonItem>
              <IonLabel position='floating'>{t('label.confirmPassword')}</IonLabel>
              <IonInput
                onIonChange={(e) => setDataObject({ ...dataObject, confirmPassword: e.detail.value })}
                className='dir-ltr'
                type='password'
              />
            </IonItem>
            <hr />
            <AyriaReCaptcha action='register' verifyCallback={(token) => captchaCallback(token)} />
            <IonItem>
              <IonLabel htmlFor='rules'>
                {t('label.acceptRule')}
                <IonIcon icon={eye} onClick={() => setShowRules(true)} color='primary' />
              </IonLabel>
              <IonToggle
                id='rules'
                slot='start'
                checked={dataObject.acceptRules}
                onIonChange={(e) => setDataObject({ ...dataObject, acceptRules: e.detail.checked })}
              />
            </IonItem>
            {isValid() && !captchaToken && (
              <IonItem>
                <IonLabel>{'من ربات نیستم'}</IonLabel>
                {!captchaInprogress && <IonToggle slot='start' onIonChange={iAmNotARobot}></IonToggle>}
                {captchaInprogress && <IonSpinner slot='start' />}
              </IonItem>
            )}
            <div className={'text-right pt-3'}>
              <IonButton
                disabled={
                  inprogress ||
                  !isValid() ||
                  !captchaToken ||
                  (dataObject.acceptRules !== undefined ? !dataObject.acceptRules : true)
                }
                onClick={handleSubmit}>
                {inprogress && <IonSpinner name='crescent' color='dark' />}
                {'ثبت نام'}
              </IonButton>
            </div>
          </>
        )}
        {userExists && (
          <div className={'form-group pt-3'}>
            <IonButton onClick={() => history.push('/login')}>{'ورود'}</IonButton>
          </div>
        )}
      </div>
      <CustomModal title={'شرایط و قوانین ثبت نام'} showModal={showRules} toggle={setShowRules} content={<Rules />} />
    </form>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    savedClub: state.user.savedClub,
  }),
  component: SignupForm,
})
