export enum BillType {
  WATER = 'WATER',
  ELECTRICITY = 'ELECTRICITY',
  ELECTRICITY_STANDARD = 'ELECTRICITY_STANDARD',
  GAS = 'GAS',
  GAS_BILL = 'GAS_BILL',
  TEL = 'TEL',
  TEL_NOW = 'TEL_NOW',
  MOBILE = 'MOBILE',
  MOBILE_NOW = 'MOBILE_NOW',
}
