import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Header } from '../../../ayria'

interface OrderedProductManagementProps {}
const OrderedProductManagement: React.FC<OrderedProductManagementProps> = () => {
  return (
    <IonPage id='ordered-product-managment-page'>
      <IonContent fullscreen={true} className='content md-5'>
        <Header title='سفارشات' />
      </IonContent>
    </IonPage>
  )
}

export default OrderedProductManagement
