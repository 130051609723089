import React, { useState, useEffect } from 'react'
import { conformToMask } from 'react-text-mask'
import createNumberMask from './createNumberMask'
import { convertToEnglish } from '../../../utility/number'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  // integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

interface CurrencyInputProps {
  maskOptions?: any
  onValueChange: React.Dispatch<React.SetStateAction<string>>
  defaultValue?: string
  componentDidMount?: Function
  showBorderBottomOnly?: boolean
  id?: string
  placeholder?: string
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  maskOptions,
  onValueChange,
  defaultValue,
  componentDidMount = () => {},
  showBorderBottomOnly = false,
  placeholder = 'مبلغ مورد نظر - ریال',
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })
  const [amount, setAmount] = useState(defaultValue || undefined)
  const maskAmount = (rawAmount = '') => {
    rawAmount = convertToEnglish(rawAmount)
    rawAmount = rawAmount.replace(/\D+/g, '')
    const res = conformToMask(rawAmount, currencyMask(rawAmount), {})
    let value = res.conformedValue.replace('_', '')
    value = convertToEnglish(value) // this is necessary for some Android phone e.g. Samsung G5
    setAmount(value)
    let thousandsSeparator = (1000).toLocaleString(navigator.language).substring(1, 2)
    onValueChange(value.split(defaultMaskOptions.thousandsSeparatorSymbol).join('').split(thousandsSeparator).join(''))
  }

  useEffect(() => {
    componentDidMount()
  }, []) // eslint-disable-line

  return (
    <input
      value={amount || ''}
      onChange={(e) => {
        maskAmount(e.target.value)
      }}
      className='form-control text-left col-12 dir-ltr'
      placeholder={placeholder}
      inputMode='numeric'
      pattern='[0-9]*'
      style={{
        border: showBorderBottomOnly ? 'none' : '',
        borderBottom: showBorderBottomOnly ? '1px solid #cdc6ca' : '',
      }}
      {...inputProps}
    />
  )
}

export default CurrencyInput
