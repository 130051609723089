import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { useToast } from '../../../ayria'

const ScoreWithdraw = ({ amount, onSuccess, acceptorCode }) => {
  const toaster = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [description, setDescription] = useState('')
  const { t } = useTranslation()

  async function handleScoreWithdraw() {
    setInprogress(true)
    const res = await api.post(`/customer/scores/cashout/${acceptorCode ? acceptorCode : '0'}`, { amount, description })
    if (res.ok) {
      onSuccess(res.data)
    } else {
      toaster.error(res)
    }
    setInprogress(false)
  }

  return (
    <div className='text-right'>
      <div className='mb-5'>
        <span> امتیاز مورد نظر شما برای تبدیل به موجودی کیف پول: {amount}</span>
        <br />
        <span>
          مبلغ معادل: {(amount * 100).toLocaleString(navigator.language)} {t('label.riyal')}
        </span>
        <div className='form-group'>
          <label htmlFor='description'>توضیحات</label>
          <input name='description' onChange={(e) => setDescription(e.target.value)} className='form-control' />
        </div>
      </div>
      <button className='btn btn-theme' onClick={handleScoreWithdraw} disabled={inprogress}>
        تبدیل
      </button>
    </div>
  )
}

export default ScoreWithdraw
