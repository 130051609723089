import { IonButton, IonIcon, IonText } from '@ionic/react'
import { location } from 'ionicons/icons'
import React, { FC, useContext, useState } from 'react'
import { LayerContext } from '.'
import { Modal } from '../../../utility'
import LeafletMap from './LeafletMap'
import './PinButton.scss'

interface OwnProps {
  latLng: string
  divider?: string
  title?: string
  address?: string
  distanceTo?: number
}
interface PinButtonProps extends OwnProps {}

const PinButton: FC<PinButtonProps> = ({ latLng, divider = ',', title = 'آدرس تحویل', address, distanceTo }) => {
  const [showShippingGeoModal, setShowShippingGeoModal] = useState(false)
  const { setCenter, setZoom, setPoint } = useContext(LayerContext)

  const showShippingGeoOnMap = () => {
    setShowShippingGeoModal(true)
    setZoom(18)
    setCenter({ lat: latLng.split(divider)[0], lng: latLng.split(divider)[1] })
    setPoint({ lat: latLng.split(divider)[0], lng: latLng.split(divider)[1] })
  }

  return (
    <>
      <IonButton
        size='small'
        fill='clear'
        className='non-printable dir-ltr ion-no-padding'
        onClick={() => showShippingGeoOnMap()}>
        <IonIcon icon={location} slot={!distanceTo ? 'icon-only' : 'start'} style={{ paddingLeft: '2px' }} />
        {!!distanceTo && (
          <>
            <span>{(distanceTo / 1000).toFixed(0)} km</span>
          </>
        )}
      </IonButton>
      <Modal
        id='pin-button-modal'
        showModal={showShippingGeoModal}
        toggle={setShowShippingGeoModal}
        title={title}
        isPlatformTheme={true}
        onClosed={() => setShowShippingGeoModal(false)}
        content={
          <>
            <LeafletMap showPoint={true} />
            {!!address && (
              <>
                <br />
                <IonText>{address}</IonText>
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default PinButton
