import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { IonCard, IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonRow, IonCheckbox } from '@ionic/react'
import { IonCol, IonSpinner, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react'
import { useIonViewWillLeave, useIonViewWillEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { setIsLoggedIn, setUsername, setHasSeenLanding, setMenuEnabled } from '../data/user/user.actions'
import { setLoggedUser, setFailureCount } from '../data/user/user.actions'
import { AyriaReCaptcha, ApiService, MobileIonInput, Auth, Logo, connect } from '../ayria'
import { User, Club, CopyRight, isDesktop } from '../ayria'
import { URLSearchParams } from 'whatwg-url'
import './Login.scss'

declare var localStorage: any

interface OwnProps extends RouteComponentProps {
  isAcceptor?: boolean
}
interface StateProps {
  savedClub?: Club
  theme?: string
  isLoggedIn?: boolean
  failureCount: number
}
interface DispatchProps {
  setHasSeenLanding: typeof setHasSeenLanding
  setIsLoggedIn: typeof setIsLoggedIn
  setUsername: typeof setUsername
  setMenuEnabled: typeof setMenuEnabled
  setLoggedUser: typeof setLoggedUser
  setFailureCount: typeof setFailureCount
}

interface LoginProps extends OwnProps, StateProps, DispatchProps, RouteComponentProps {}

const Login: React.FC<LoginProps> = ({
  setIsLoggedIn,
  setUsername: setUsernameAction,
  setHasSeenLanding,
  setMenuEnabled,
  setLoggedUser,
  savedClub,
  theme,
  isAcceptor = false,
  isLoggedIn = false,
  failureCount,
  history,
}) => {
  const api = new ApiService()
  const auth = new Auth()
  const { t } = useTranslation()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [username, setUsername] = useState(urlSearchParams.get('mobile') || '')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(true)
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [passwordHide, setPasswordHide] = useState(true)
  const [error, setError] = useState('')
  const [inprogress, setInprogress] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
    setPassword('')
  })

  const login = async (e: React.FormEvent) => {
    e.preventDefault()
    setInprogress(true)
    setError('')
    setUsernameError(false)
    setPasswordError(false)
    if (!username) {
      setUsernameError(true)
    }
    if (!password) {
      setPasswordError(true)
    }

    if (username && password) {
      const response: any = await auth.login(username, password, rememberMe, captchaToken)
      setCaptchaToken('')
      if (response === 'OK') {
        await setIsLoggedIn(true)
        await setFailureCount(0)
        await setUsernameAction(username)
        await setHasSeenLanding(true)
        const accountRes: any = await api.get('/account')
        const user: User = accountRes.data
        auth.setLoggedInUser(user)
        await setLoggedUser(user)
        if (localStorage.getItem('REDIRECT_PATH') && !localStorage.getItem('REDIRECT_PATH').includes('start')) {
          history.push(localStorage.getItem('REDIRECT_PATH'), { direction: 'none' })
          localStorage.removeItem('REDIRECT_PATH')
        } else if (isAcceptor) {
          history.push(`/desk${window.location.search}`, { direction: 'none' })
        } else if (user.plan) {
          history.push(`/tabs${window.location.search}`, { direction: 'none' })
        } else {
          history.push(`/plan${window.location.search}`, { direction: 'none' })
        }
      } else if (response?.response?.data?.status === 400 && response?.response?.data?.title === 'Inactive user') {
        history.push(`/activate?mobile=${username}`)
      } else if (response?.response?.data?.title) {
        setError(response?.response?.data?.title)
        if (response?.response?.data?.errorKey === 'invalidcaptcha') {
          setUsername('')
          setPassword('')
        }
      } else {
        if ('Network Error' === response?.message) {
          setError('مشکل در برقراری ارتباط با سرور، اینترنت خود را چک کنید و دوباره تلاش کنید')
        } else if ('Request failed with status code 401' === response?.message) {
          setError('شماره همراه و رمزعبوری که وارد کردید با رکوردهای ما همخوانی ندارد، لطفا دوباره چک و تلاش کنید')
        } else if (!!response?.message) {
          setError(response?.message)
        } else {
          setError('خطای نامشخص')
        }
      }
      if (response !== 'OK' && !!executeRecaptcha) {
        const token: any = await executeRecaptcha('login')
        setCaptchaToken(token)
      }
    }
    setInprogress(false)
  }

  const checkIfAlreadyLogedIn = () => {
    if (isLoggedIn && failureCount < 2) {
      setMenuEnabled(true)
      if (localStorage.getItem('REDIRECT_PATH') && !localStorage.getItem('REDIRECT_PATH').includes('start')) {
        history.push(localStorage.getItem('REDIRECT_PATH'))
        localStorage.removeItem('REDIRECT_PATH')
      } else if (isAcceptor) {
        history.push(`/desk${window.location.search}`)
      } else {
        history.push(`/tabs${window.location.search}`)
      }
    }
  }

  const isMobileValid = () => {
    return !!username && /^(09[0-9]{9})$/.test(username) && username.toString().length === 11
  }

  useEffect(() => {
    checkIfAlreadyLogedIn()
  }, []) // eslint-disable-line

  return (
    <IonPage
      id='login-page'
      className={(isDesktop() ? 'marginHorizontalAuto' : '') + ` ${isAcceptor ? 'platform' : theme}-theme`}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ورود</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='login-logo'>
          <Logo />
          {savedClub?.name && <h3>{savedClub?.name}</h3>}
        </div>
        <form noValidate onSubmit={login}>
          <IonList>
            <IonCard style={{ border: !isMobileValid() ? '1px solid red' : '' }}>
              <IonLabel position='stacked' color='primary'>
                شماره همراه
              </IonLabel>
              <MobileIonInput
                value={username}
                spellCheck={false}
                autocapitalize='off'
                onValueChange={setUsername}
                required={true}
                id='username'
                autoFocus
              />
            </IonCard>

            {!inprogress && usernameError && (
              <IonText color='danger'>
                <p className='ion-padding-start'>شماره همراه را وارد کنید</p>
              </IonText>
            )}

            <IonCard style={{ border: !password ? '1px solid red' : '' }}>
              <IonLabel position='stacked' color='primary'>
                رمزعبور
              </IonLabel>
              <span id='password-container'>
                <IonInput
                  name='password'
                  type={passwordHide ? 'password' : 'text'}
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                  className='dir-ltr ion-text-start'
                  id='password'></IonInput>
              </span>
            </IonCard>

            {!inprogress && passwordError && (
              <IonText color='danger'>
                <p className='ion-padding-start'>رمز عبور را وارد کنید</p>
              </IonText>
            )}

            {!inprogress && error && (
              <IonText color='danger'>
                <p className='ion-padding-start'>{error}</p>
              </IonText>
            )}
            <IonItem>
              <IonLabel>{'نمایش رمز عبور'}</IonLabel>
              <IonCheckbox
                checked={!passwordHide}
                onIonChange={(e) => setPasswordHide(!e.detail.checked)}></IonCheckbox>
            </IonItem>
            <IonItem>
              <IonLabel>{'مرا به خاطر بسپار'}</IonLabel>
              <IonCheckbox checked={rememberMe} onIonChange={(e) => setRememberMe(e.detail.checked)}></IonCheckbox>
            </IonItem>
          </IonList>
          <IonRow>
            <IonCol>
              <AyriaReCaptcha action='login' verifyCallback={setCaptchaToken} />
              <IonButton color='primary' type='submit' disabled={inprogress} expand='block'>
                {inprogress && <IonSpinner />}
                {'ورود'}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color='light'
                routerLink={
                  isAcceptor ? `/acceptors-signup${window.location.search}` : `/signup${window.location.search}`
                }
                expand='block'>
                ثبت نام
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
        <IonRow>
          <IonCol>
            <Link to={`/password-reset${window.location.search}`}>{t('login.forgotPassword')}</Link>
          </IonCol>
        </IonRow>
        <br />
        <br />
        <CopyRight />
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setHasSeenLanding,
    setIsLoggedIn,
    setUsername,
    setMenuEnabled,
    setLoggedUser,
    setFailureCount,
  },
  mapStateToProps: (state) => ({
    savedClub: state.user.savedClub,
    theme: state.user.theme,
    isLoggedIn: state.user.isLoggedIn,
    failureCount: state.user.failureCount,
  }),
  component: withRouter(Login),
})
