import React from 'react'
import { IonSpinner } from '@ionic/react'

const SuspenseSplash = () => {
  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
      <IonSpinner name='crescent' color='dark' />
    </div>
  )
}

export default SuspenseSplash
