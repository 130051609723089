import React, { useState, useContext } from 'react'
import { IonPopover, IonList, IonItem, IonButton } from '@ionic/react'
import { ellipsisVerticalOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import ProfilePicture from '../../units/ProfilePicture/ProfilePicture'
import GivenRate from '../../units/GivenRate/GivenRate'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import api from '../../../api/Api'
import { AyriaIcon, useToast, CustomModal } from '../../../ayria'

interface MerchantProfileCustomerRateProps {
  element: any
  i: number
}

const MerchantProfileCustomerRate: React.FC<MerchantProfileCustomerRateProps> = ({ element, i }) => {
  const [profileContext] = useContext<any>(ProfileContext)
  const [showElement, setShowElement] = useState(true)
  const toaster = useToast()
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [callbackFunction, setCallbackFunction] = useState('')
  const [confirmationText, setConfirmationText] = useState(undefined)
  const [inprogress, setInprogress] = useState(false)
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })

  const isAllowedToDelete = (element: any) => {
    return profileContext.profile.nickname === element.author
  }

  const showConfirm = (callback: string) => {
    setCallbackFunction(callback)
    if (callback === 'deleteComment') {
      setConfirmationText(t('comment_delete_confirmation_message'))
    } else if (callback === 'spamComment') {
      setConfirmationText(t('comment_spam_confirmation_message'))
    } else if (callback === 'inappropriateComment') {
      setConfirmationText(t('comment_inappropriate_confirmation_message'))
    }
    setShowConfirmModal(true)
  }

  const deleteComment = async () => {
    setInprogress(true)
    const res = await api.delete(`/user/comments/delete/${element.id}`)
    if (res.ok) {
      setShowElement(false)
      toaster.success(t('label.successfulOperation'))
    }
    setInprogress(false)
  }

  const spamComment = async () => {
    setInprogress(true)
    const res = await api.delete(`/user/comments/spam/${element.id}`)
    if (res.ok) {
      setShowElement(false)
      toaster.success(t('label.successfulOperation'))
    }
    setInprogress(false)
  }

  const inappropriateComment = async () => {
    setInprogress(true)
    const res = await api.delete(`/user/comments/inappropriate/${element.id}`)
    if (res.ok) {
      setShowElement(false)
      toaster.success(t('label.successfulOperation'))
    }
    setInprogress(false)
  }

  const funcMap = {} as any

  const executeCallback = () => {
    funcMap[callbackFunction]()
    setShowConfirmModal(false)
  }

  funcMap.method = function (name: string, callback: () => void) {
    this[name] = callback
  }
  funcMap.method('deleteComment', () => {
    deleteComment()
  })
  funcMap.method('spamComment', () => {
    spamComment()
  })
  funcMap.method('inappropriateComment', () => {
    inappropriateComment()
  })

  // const [funcMap] = useState({
  // 'deleteComment': deleteComment.bind(this), // TODO FIXME
  // 'spamComment': spamComment.bind(this), // TODO FIXME
  // 'inappropriateComment': inappropriateComment.bind(this) // TODO FIXME
  // } as any);

  return (
    <>
      {showElement && (
        <div key={'element-' + i} className='border-bottom'>
          <div className='mb-2 flex-container'>
            <div className='flex-container'>
              <span style={{ position: 'relative' }}>
                <ProfilePicture pictureName={element.authorPicture} width={'40px'} />
                {element.commentType === 'RATE' && (
                  <GivenRate rate={element.rate} width={'20px'} className='p-1 over-img' />
                )}
              </span>
              <span className='pr-4'>
                {element.author}
                <br />
                <span className='fa-xs text-muted'>{element.createdDate?.replaceAll('-', '/')}</span>
              </span>
            </div>
            <span className='left p-2'>
              <IonButton
                fill='clear'
                onClick={(e) => {
                  e.persist()
                  setShowPopover({ showPopover: true, event: e })
                }}>
                <AyriaIcon icon={ellipsisVerticalOutline} />
              </IonButton>
              <IonPopover
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                showBackdrop={false}>
                <IonList>
                  <IonItem button onClick={(e) => showConfirm('spamComment')}>
                    {t('label.reportAsSpam')}
                  </IonItem>
                  <IonItem button onClick={(e) => showConfirm('inappropriateComment')}>
                    {t('label.reportAsInappropriate')}
                  </IonItem>
                  {isAllowedToDelete(element) && (
                    <IonItem button className='text-danger' onClick={(e) => showConfirm('deleteComment')}>
                      {t('label.delete')}
                    </IonItem>
                  )}
                </IonList>
              </IonPopover>
            </span>
          </div>
          {!!element.title && <div className='text-bold'>{element.title}</div>}
          <p className='pre-wrap'>{element.text}</p>
        </div>
      )}
      <CustomModal
        showModal={showConfirmModal}
        toggle={setShowConfirmModal}
        title={t('label.confirm')}
        content={
          <div className='ion-padding'>
            <p>{confirmationText}</p>
            <div>
              <IonButton className='right' disabled={inprogress} onClick={(e) => executeCallback()}>
                {t('label.yes')}
              </IonButton>
              <IonButton color='light' className='left' onClick={(e) => setShowConfirmModal(false)}>
                {t('label.cancel')}
              </IonButton>
            </div>
          </div>
        }
      />
    </>
  )
}

export default MerchantProfileCustomerRate
