import React, { useEffect, useState, lazy } from 'react'
import { IonPage, IonContent, IonSpinner } from '@ionic/react'
import { useParams } from 'react-router'
import { ApiService, Modal } from '../../ayria'
import '../../App-deprecated.scss'

const AyriaPayment = lazy(() => import('../../components/elements/AyriaPayment/AyriaPayment'))

interface AyriaPaymentPageProps {}

const AyriaPaymentPage: React.FC<AyriaPaymentPageProps> = () => {
  const api = new ApiService()
  const { acceptorCode, trackingNumber } = useParams<any>()
  const [inprogress, setInprogress] = useState<boolean>(false)
  const [item, setItem] = useState<any>()

  const getItem = async () => {
    setInprogress(false)
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/ayria-payment/${trackingNumber}`)
    setItem(res.ok ? res.data : undefined)
    setInprogress(false)
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  return (
    <IonPage id='ayria-payment-page-id'>
      <IonContent className='ion-padding'>
        {!inprogress && item && (
          <Modal
            id='ayriaPaymentModalAcceptor'
            title={'سفارش ' + trackingNumber}
            showModal={true}
            isPlatformTheme={true}
            content={<AyriaPayment payment={item} acceptorCode={acceptorCode} showCashierActions={true} />}
          />
        )}
        {inprogress && <IonSpinner name='crescent' color='dark' />}
        {!inprogress && !item && <div>{trackingNumber}</div>}
      </IonContent>
    </IonPage>
  )
}

export default AyriaPaymentPage
