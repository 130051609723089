import React, { useState, useEffect, useRef } from 'react'
import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { chevronUp } from 'ionicons/icons'

interface ScrollToTopContainerProps {
  props?: any
}

const ScrollToTopContainer: React.FC<ScrollToTopContainerProps> = (props) => {
  const [showButton] = useState(true)
  const scrollingWrapper = useRef(null)

  const onScroll = () => {
    // console.log('onScroll', scrollingWrapper.current.scrollTop, event.target.scrollTop, document.body.scrollTop)
    // if (scrollingWrapper.current.scrollTop > 100 && !showButton) {
    // setShowButton(true)
    // } else if (scrollingWrapper.current.scrollTop < 100 && showButton) {
    // setShowButton(false)
    // }
  }

  const scrollToTop = () => {
    const scrollingWrapperConst = scrollingWrapper.current || ({} as any)
    scrollingWrapperConst.scrollIntoView()
  }

  useEffect(() => {
    const scrollingWrapperConst = scrollingWrapper.current || ({} as any)
    scrollingWrapperConst.addEventListener('scroll', onScroll)
    // window.addEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      {showButton && (
        <div onClick={scrollToTop} className='btn-group-fab'>
          <IonFab slot='fixed' horizontal='center' vertical='bottom'>
            <IonFabButton>
              <IonIcon icon={chevronUp} />
            </IonFabButton>
          </IonFab>
        </div>
      )}
      <div ref={scrollingWrapper}>{props.children}</div>
    </>
  )
}

export default ScrollToTopContainer
