import React, { useRef, useState } from 'react'
import { IonContent, IonFab, IonButton, IonIcon, isPlatform } from '@ionic/react'
import { chevronUp } from 'ionicons/icons'

interface OwnProps {
  onScroll?: Function
  className?: string
}
interface ScrollContentProps extends OwnProps {}

const ScrollContent: React.FC<ScrollContentProps> = ({ onScroll, className, children }) => {
  const scrollContentRef = useRef<HTMLIonContentElement>(null)
  const [showGoToTopButton, setShowGoToTopButton] = useState(false)

  const onScrollInternal = (event: any) => {
    const bottomPosition = event.target.clientHeight + event.detail.scrollTop
    const screenSize = event.target.clientHeight
    setShowGoToTopButton(screenSize - bottomPosition < -10)
    if (!!onScroll) {
      onScroll(event)
    }
  }

  return (
    <IonContent ref={scrollContentRef} scrollEvents={true} onIonScroll={onScrollInternal} className={className}>
      {children}
      <IonFab
        slot='fixed'
        horizontal='center'
        vertical='bottom'
        style={{ left: isPlatform('iphone') ? '43%' : '50%' }}
        onClick={() => scrollContentRef?.current?.scrollToTop(300)}>
        {showGoToTopButton && (
          <IonButton fill='clear' color='primary'>
            <IonIcon icon={chevronUp} />
          </IonButton>
        )}
      </IonFab>
    </IonContent>
  )
}

export default ScrollContent
