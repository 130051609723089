import React from 'react'
import { IonContent, IonLabel, IonPage } from '@ionic/react'
import { Header } from '../ayria'
import VirtualShopQRCode from '../components/units/VirtualShopQRCode'
import { RouteComponentProps, withRouter } from 'react-router'
import { useParams } from 'react-router'
import { isDesktop } from '../utility'

interface VirtualShopQrPageProps extends RouteComponentProps {}

const VirtualShopQrPage: React.FC<VirtualShopQrPageProps> = ({ match }) => {
  const { acceptorReferralCode } = useParams<any>()
  const [acceptorCode] = acceptorReferralCode || match.url.substring(match.url.lastIndexOf('/') + 1)
  return (
    <IonPage id='virtualshopqr-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <hr />
        <div>
          <IonLabel className=' ion-text-center ion-text-justify'>
            <p className='ion-margin ion-padding'>
              با اسکن این QR CODE افراد می توانند لیست کالا و خدمات شما را مشاهده نمایند . چنانچه سرویس فروش آنلاین شما
              فعال باشد از همینجا امکان فروش کالا و خدمات را نیز خواهید داشت.
            </p>
          </IonLabel>
        </div>
        <div className='ion-padding ion-text-center'>
          <br />
          <VirtualShopQRCode acceptorReferralCode={acceptorCode} showLink={true} />
        </div>
      </IonContent>
    </IonPage>
  )
}
export default withRouter(VirtualShopQrPage)
