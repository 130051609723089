import React, { Suspense, useState, useContext, lazy } from 'react'
import { IonBadge, IonButton, IonSpinner } from '@ionic/react'
import { IonRow, IonCol, IonIcon } from '@ionic/react'
import { settingsOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Modal, isDesktop } from '../../../utility'
import AcceptorReports from '../../elements/AcceptorReports/acceptorReports'
import Wallet from '../wallet/wallet'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import WalletPayeeRequest from '../../elements/WalletPayeeRequest/WalletPayeeRequest'
import CashDeskDaily from '../../elements/CashDeskDaily/CashDeskDaily'
import Score from '../score/score'
import { connect } from '../../../data/connect'
import { Definitions } from '..'
import StatBar from '../../../pages/acceptor/StatBar'
import { setAcceptorCode } from '../../../ayria'
import AcceptorHasFeatureLoader from '../../units/acceptor/AcceptorHasFeatureLoader'
import OnlineShopService from '../online-shop-service/online-shop-service'
import BuildingService from '../building-service/BuildingService'
import '../../../App-deprecated.scss'
import '../../../assets/styles/css/Club-theme/dashboard.css'
import './panel.scss'

const AyriaPayment = lazy(() => import('../../elements/AyriaPayment/AyriaPayment'))

declare var document: any
declare var window: any

interface StateProps {
  onlineOrderCount: any
  loggedUser: any
}
interface DispatchProps {
  setAcceptorCode: typeof setAcceptorCode
}

interface PanelProps extends StateProps, DispatchProps {}

const Panel: React.FC<PanelProps> = ({ onlineOrderCount, setAcceptorCode, loggedUser }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [showReports, setShowReports] = useState(true)
  const [showScore, setShowScore] = useState(false)
  const [showWallet, setShowWallet] = useState(false)
  const [showUsers, setShowUsers] = useState(false)
  const [showCashDesk, setShowCashDesk] = useState(false)
  const [showOnlineShop, setShowOnlineShop] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [showWalletPayeeRequestModal, setShowWalletPayeeRequestModal] = useState(false)
  const [ayriaPayment, setAyriaPayment] = useState<any>()
  const [showAyriaPaymentModal, setShowAyriaPaymentModal] = useState(false)
  const [showDefinitionsModal, setShowDefinitionsModal] = useState(false)
  const [desktop, setDesktop] = useState(isDesktop())

  function setTab(e: any, tabName: string) {
    document.getElementById('reports').classList.remove('active')
    document.getElementById('score').classList.remove('active')
    document.getElementById('wallet').classList.remove('active')
    document.getElementById('users').classList.remove('active')
    document.getElementById('apRequests').classList.remove('active')
    document.getElementById('cashDesk').classList.remove('active')
    document.getElementById('onlineShop').classList.remove('active')
    e.target.classList.add('active')
    setShowReports(false)
    setShowScore(false)
    setShowWallet(false)
    setShowUsers(false)
    setShowCashDesk(false)
    setShowOnlineShop(false)
    switch (tabName) {
      case 'reports':
        setShowReports(true)
        break
      case 'score':
        setShowScore(true)
        break
      case 'wallet':
        setShowWallet(true)
        break
      case 'users':
        setShowUsers(true)
        break
      case 'cashDesk':
        setShowCashDesk(true)
        break
      case 'onlineShop':
        setShowOnlineShop(true)
        break
      default:
        break
    }
  }

  const isCenterActive = () => {
    if (
      acceptorContext.current &&
      (acceptorContext.current.status === 'ACTIVE' || acceptorContext.current.status === 'SYSTEM')
    ) {
      return true
    }
    return false
  }

  const isSystem = () => {
    if (acceptorContext.current && acceptorContext.current.status === 'SYSTEM') {
      return true
    }
    return false
  }

  window.addEventListener('resize', () => {
    setDesktop(isDesktop())
  })

  return (
    <Suspense fallback={<IonSpinner color='primary' />}>
      {isCenterActive() && acceptorContext?.current?.code && (
        <AcceptorHasFeatureLoader acceptorCode={acceptorContext?.current?.code} />
      )}
      <div className='platform-theme'>
        {acceptorContext?.all?.length === 0 && (
          <div className={'center text-center'}>
            <Link
              className={'btn bg-success text-white btn-theme-platform btn-sm'}
              to={{ pathname: 'acceptors-request', search: window.location.search }}>
              {t('label.acceptorRequest')}
            </Link>
          </div>
        )}
        {acceptorContext.current && (
          <div>
            {acceptorContext.current.status === 'REQUESTED' && (
              <div>
                <Modal
                  title={t('label.acceptorRequest')}
                  showModal={showModal}
                  toggle={setShowModal}
                  onClosed={() => setShowModal(false)}
                  isPlatformTheme={true}
                  content={
                    <div className={'text-justify rtl right'}>
                      <div>
                        {loggedUser?.firstName} {loggedUser?.lastName}
                      </div>
                      <span>
                        در خواست شما براى ثبت مركز {acceptorContext.current.acceptorName} در دست بررسى مى باشد . در
                        انتظار تماس از سوى كارشناسان ما باشيد.
                      </span>
                    </div>
                  }
                  actionButtons={
                    <>
                      <IonButton color='primary' onClick={() => history.push('/profile-acceptor?tab=documents')}>
                        {'تکمیل مدارک'}
                      </IonButton>
                      <IonButton onClick={() => history.push('/')}>{'بازگشت به کاربری شخصی'}</IonButton>
                    </>
                  }
                />
              </div>
            )}
          </div>
        )}

        {!isCenterActive() && acceptorContext?.current?.status && (
          <span>
            {'وضعیت فروشگاه: '} {t(`Status ${acceptorContext?.current?.status}`)}
          </span>
        )}

        {isCenterActive() && (
          <div>
            {desktop && (
              <div className={'desktop-only container-fluid'}>
                <StatBar />

                <IonRow className='my-3 justify-content-center'>
                  <IonCol size='1' className='cursor-pointer' onClick={() => history.push('/panel-settings')}>
                    <IonRow>
                      <img src='/assets/images/panel/setting-icon.svg' alt='' />
                      <span className='px-2'>تنظیمات</span>
                    </IonRow>
                  </IonCol>
                  <IonCol size='2' className='cursor-pointer' onClick={() => setShowDefinitionsModal(true)}>
                    <IonRow className='justify-content-center'>
                      <img src='/assets/images/panel/services-icon.svg' alt='' />
                      <span className='px-2'>کالا و خدمات</span>
                    </IonRow>
                  </IonCol>
                  <IonCol
                    size='1'
                    className='cursor-pointer'
                    onClick={() => window.open(`/tabs/center/${acceptorContext?.current?.code}`, '_blank')}>
                    <IonRow>
                      <img src='/assets/images/panel/shop-icon.svg' alt='' />
                      <span className='px-2'>فروشگاه</span>
                    </IonRow>
                  </IonCol>
                </IonRow>

                <Wallet type={'acceptor'} acceptorCode={acceptorContext.current.code} />

                {/*<div></div>*/}
                <div className='row mt-2 pr-3'>
                  {/*<div className="col-9">*/}

                  <div
                    className='col-9 px-0 customCard white d-flex flex-column overflow-auto '
                    style={{ maxHeight: 572 }}>
                    <div className='p-4 customCardTitleSeparator'>
                      <span>پنل مدیریتی</span>
                    </div>

                    <div className='  h-100'>
                      <AcceptorReports />
                    </div>
                  </div>
                  {/*</div>*/}

                  <div className='col-3'>
                    <div className='customCard white'>
                      <CashDeskDaily acceptorCode={acceptorContext?.current?.code} switchable={true} />
                    </div>

                    <div className='customCard white mt-2'>
                      <div className='container px-0' id='cashDeskDaily'>
                        <div className='p-4 customCardTitleSeparator d-flex flex-row align-items-center justify-content-between'>
                          <span style={{ color: '#7B7B7B' }}>خدمات پرداخت</span>

                          <IonIcon
                            style={{ color: '#3a4cf0' }}
                            icon={settingsOutline}
                            // onClick={(e) => getBalance()}
                            className='m-0 p-0 pointer non-printable'
                            title={'بروزرسانی'}
                          />
                        </div>

                        <div className='row p-3'>
                          <div className='col-12'>
                            <div className='col-12 d-flex flex-row align-items-center paymentServiceItem'>
                              <img src={'/assets/images/panel/payment-service.svg'} alt='' />
                              <span className='px-3'>پرداخت قبض</span>
                            </div>
                            <div className='col-12 mt-3 d-flex flex-row align-items-center paymentServiceItem'>
                              <img src={'/assets/images/panel/payment-service.svg'} alt='' />

                              <span className='px-3'>پرداخت حقوق</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!desktop && (
              <div className={'mobile-only'}>
                <div className={'ads-holder'}>
                  <img alt='' src={'/assets/platform/home.png'} />
                </div>
                <div className='panel-header display-inline clear-both'>
                  <div id='cashDesk' className={'col-4 box-header'} onClick={(e) => setTab(e, 'cashDesk')}>
                    {' '}
                    {'صندوق'}
                  </div>
                  <div id='score' className={'col-4 box-header'} onClick={(e) => setTab(e, 'score')}>
                    {' '}
                    {'امتیاز'}
                  </div>
                  <div id='wallet' className={'col-4 box-header'} onClick={(e) => setTab(e, 'wallet')}>
                    {'کیف پول'}
                  </div>
                </div>
                <div className='panel-header  display-inline clear-both '>
                  <div id='reports' className={'col-3 box-header active'} onClick={(e) => setTab(e, 'reports')}>
                    {t('label.Reports')}
                  </div>
                  <div
                    id='apRequests'
                    className='col-6  box-header-success box-header'
                    onClick={(e) => setShowWalletPayeeRequestModal(true)}>
                    {'صدور صورتحساب'}
                  </div>
                  <div id='users' className='col-3 box-header align-items-center' onClick={(e) => setTab(e, 'users')}>
                    {'تعاریف'}
                  </div>
                </div>
                <div className={'panel-header box-header display-block clear-both'}>
                  <div id='onlineShop' className={'col-12 onlineshop-section'} onClick={(e) => setTab(e, 'onlineShop')}>
                    {!isSystem() && (
                      <>
                        {' مدیریت فروش آنلاین'}
                        {!!onlineOrderCount?.count && <IonBadge color='danger'>{onlineOrderCount?.count}</IonBadge>}
                      </>
                    )}
                    {isSystem() && <>{'مدیریت ساختمان'}</>}
                  </div>
                </div>
                <div className={'panel-holder'}>
                  {showReports && (
                    <div className={'platform-panel'}>
                      <AcceptorReports />
                    </div>
                  )}
                  {showScore && (
                    <div className={'platform-panel'}>
                      <Score code={acceptorContext.current.code} />
                    </div>
                  )}
                  {showWallet && (
                    <div className={'platform-panel'}>
                      <Wallet type={'acceptor'} acceptorCode={acceptorContext.current.code} />
                    </div>
                  )}
                  {showUsers && <Definitions isUsers={true} />}
                  {showCashDesk && (
                    <div className='platform-panel'>
                      <CashDeskDaily acceptorCode={acceptorContext.current.code} switchable={true} />
                    </div>
                  )}
                  {showOnlineShop && !isSystem() && (
                    <div className='platform-panel'>
                      <OnlineShopService acceptorCode={acceptorContext.current.code} />
                    </div>
                  )}
                  {showOnlineShop && isSystem() && (
                    <div className='platform-panel'>
                      <BuildingService acceptorCode={acceptorContext?.current?.code} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          showModal={showWalletPayeeRequestModal}
          toggle={setShowWalletPayeeRequestModal}
          title={t('issue_factor')}
          isPlatformTheme={true}
          onClosed={() => setShowWalletPayeeRequestModal(false)}
          id='walletPayeeRequestModal'
          content={
            <WalletPayeeRequest
              acceptorCode={acceptorContext?.current?.code}
              showPreviousRequests={false}
              onSuccess={(ap: any, share: boolean) => {
                setShowWalletPayeeRequestModal(false)
                if (share) {
                  setAyriaPayment(ap)
                  setShowAyriaPaymentModal(true)
                }
              }}
            />
          }
        />

        <Modal
          showModal={showAyriaPaymentModal}
          toggle={setShowAyriaPaymentModal}
          onClosed={() => {
            setShowAyriaPaymentModal(false)
            setAyriaPayment(null)
          }}
          title={`صورتحساب ${ayriaPayment?.trackingNumber}`}
          content={<AyriaPayment payment={ayriaPayment} onSuccess={() => {}} />}
          actionButtons={<IonButton onClick={() => setShowAyriaPaymentModal(false)}>{'بستن'}</IonButton>}
        />
        <Modal
          showModal={showDefinitionsModal}
          toggle={setShowDefinitionsModal}
          title={'مدیریت کالا و خدمات'}
          isPlatformTheme={true}
          id='definitions-modal'
          onClosed={() => setShowDefinitionsModal(false)}
          content={<Definitions isUsers={false} />}
        />
      </div>
    </Suspense>
  )

  // return (
  //   <Suspense fallback={<IonSpinner color='primary' />}>
  //     {isCenterActive() && acceptorContext?.current?.code && (
  //       <AcceptorHasFeatureLoader acceptorCode={acceptorContext?.current?.code} />
  //     )}
  //     <div className='platform-theme'>
  //       {acceptorContext?.all?.length === 0 && (
  //         <div className={'center text-center'}>
  //           <Link
  //             className={'btn bg-success text-white btn-theme-platform btn-sm'}
  //             to={{ pathname: 'acceptors-request', search: window.location.search }}>
  //             {t('label.acceptorRequest')}
  //           </Link>
  //         </div>
  //       )}
  //       {acceptorContext.current && (
  //         <div>
  //           {acceptorContext.current.status === 'REQUESTED' && (
  //             <div>
  //               <Modal
  //                 title={t('label.acceptorRequest')}
  //                 showModal={showModal}
  //                 toggle={setShowModal}
  //                 onClosed={() => setShowModal(false)}
  //                 isPlatformTheme={true}
  //                 content={
  //                   <div className={'text-justify rtl right'}>
  //                     <div>
  //                       {loggedUser?.firstName} {loggedUser?.lastName}
  //                     </div>
  //                     <span>
  //                       در خواست شما براى ثبت مركز {acceptorContext.current.acceptorName} در دست بررسى مى باشد . در
  //                       انتظار تماس از سوى كارشناسان ما باشيد.
  //                     </span>
  //                   </div>
  //                 }
  //                 actionButtons={
  //                   <>
  //                     <IonButton color='primary' onClick={() => history.push('/profile-acceptor?tab=documents')}>
  //                       {'تکمیل مدارک'}
  //                     </IonButton>
  //                     <IonButton onClick={() => history.push('/')}>{'بازگشت به کاربری شخصی'}</IonButton>
  //                   </>
  //                 }
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )}
  //
  //       {!isCenterActive() && acceptorContext?.current?.status && (
  //         <span>
  //           {'وضعیت فروشگاه: '} {t(`Status ${acceptorContext?.current?.status}`)}
  //         </span>
  //       )}
  //       {isCenterActive() && (
  //         <div>
  //           {desktop && (
  //             <IonGrid className={'desktop-only'}>
  //               <StatBar />
  //               <IonRow>
  //                 <IonCol size='3'>
  //                   <IonRow>
  //                     <IonCol className='ion-no-padding'>
  //                       <CustomCard
  //                         title={'فروش روز'}
  //                         noMargin={true}
  //                         noTitleMarginBottom={true}
  //                         html={<CashDeskDaily acceptorCode={acceptorContext?.current?.code} switchable={true} />}
  //                       />
  //                     </IonCol>
  //                   </IonRow>
  //                   <IonRow>
  //                     <IonCol className='ion-no-padding' style={{ paddingTop: '5px' }}>
  //                       <CustomCard
  //                         title={'کیف پول'}
  //                         noMargin={true}
  //                         noTitleMarginBottom={true}
  //                         html={<Wallet type={'acceptor'} acceptorCode={acceptorContext.current.code} />}
  //                       />
  //                     </IonCol>
  //                   </IonRow>
  //                   <IonRow>
  //                     <IonCol className='ion-no-padding' style={{ paddingTop: '5px' }}>
  //                       <IonButton
  //                         size='large'
  //                         expand='block'
  //                         fill='outline'
  //                         color='medium'
  //                         onClick={() => history.push(`/payment-services/${acceptorContext?.current?.code}`)}>
  //                         {'خدمات پرداخت'}
  //                       </IonButton>
  //                     </IonCol>
  //                   </IonRow>
  //                   <IonRow>
  //                     <IonCol className='ion-text-center'>
  //                       <IonButton fill='clear' onClick={() => history.push('/panel-settings')}>
  //                         <div className='v-button-holder'>
  //                           <IonIcon icon={cog} className='pointer-events-none' />
  //                           <br />
  //                           {'تنظیمات'}
  //                         </div>
  //                       </IonButton>
  //                       <IonButton fill='clear' onClick={() => setShowDefinitionsModal(true)}>
  //                         <div className='v-button-holder'>
  //                           <IonIcon icon={pricetagsOutline} className='pointer-events-none'></IonIcon>
  //                           <br />
  //                           {'کالا و خدمات'}
  //                         </div>
  //                       </IonButton>
  //                       <IonButton
  //                         fill='clear'
  //                         onClick={() => window.open(`/tabs/center/${acceptorContext?.current?.code}`, '_blank')}>
  //                         <div className='v-button-holder'>
  //                           <IonIcon icon={storefrontOutline} className='pointer-events-none'></IonIcon>
  //                           <br />
  //                           {'فروشگاه'}
  //                         </div>
  //                       </IonButton>
  //                     </IonCol>
  //                   </IonRow>
  //                 </IonCol>
  //                 <IonCol size='9'>
  //                   <CustomCard
  //                     title={'گزارشات'}
  //                     noMargin={true}
  //                     noTitleMarginBottom={true}
  //                     html={<AcceptorReports />}
  //                   />
  //                 </IonCol>
  //               </IonRow>
  //             </IonGrid>
  //           )}
  //
  //           {!desktop && (
  //             <div className={'mobile-only'}>
  //               <div className={'ads-holder'}>
  //                 <img alt='' src={'/assets/platform/home.png'}></img>
  //               </div>
  //               <div className='panel-header display-inline clear-both'>
  //                 <div id='cashDesk' className={'col-4 box-header'} onClick={(e) => setTab(e, 'cashDesk')}>
  //                   {' '}
  //                   {'صندوق'}
  //                 </div>
  //                 <div id='score' className={'col-4 box-header'} onClick={(e) => setTab(e, 'score')}>
  //                   {' '}
  //                   {'امتیاز'}
  //                 </div>
  //                 <div id='wallet' className={'col-4 box-header'} onClick={(e) => setTab(e, 'wallet')}>
  //                   {'کیف پول'}
  //                 </div>
  //               </div>
  //               <div className='panel-header  display-inline clear-both '>
  //                 <div id='reports' className={'col-3 box-header active'} onClick={(e) => setTab(e, 'reports')}>
  //                   {t('label.Reports')}
  //                 </div>
  //                 <div
  //                   id='apRequests'
  //                   className='col-6  box-header-success box-header'
  //                   onClick={(e) => setShowWalletPayeeRequestModal(true)}>
  //                   {'صدور صورتحساب'}
  //                 </div>
  //                 <div id='users' className='col-3 box-header align-items-center' onClick={(e) => setTab(e, 'users')}>
  //                   {'تعاریف'}
  //                 </div>
  //               </div>
  //               <div className={'panel-header box-header display-block clear-both'}>
  //                 <div id='onlineShop' className={'col-12 onlineshop-section'} onClick={(e) => setTab(e, 'onlineShop')}>
  //                   {!isSystem() && (
  //                     <>
  //                       {' مدیریت فروش آنلاین'}
  //                       {!!onlineOrderCount?.count && <IonBadge color='danger'>{onlineOrderCount?.count}</IonBadge>}
  //                     </>
  //                   )}
  //                   {isSystem() && <>{'مدیریت ساختمان'}</>}
  //                 </div>
  //               </div>
  //               <div className={'panel-holder'}>
  //                 {showReports && (
  //                   <div className={'platform-panel'}>
  //                     <AcceptorReports />
  //                   </div>
  //                 )}
  //                 {showScore && (
  //                   <div className={'platform-panel'}>
  //                     <Score acceptorCode={acceptorContext.current.code} isAcceptor={true} />
  //                   </div>
  //                 )}
  //                 {showWallet && (
  //                   <div className={'platform-panel'}>
  //                     <Wallet type={'acceptor'} acceptorCode={acceptorContext.current.code} />
  //                   </div>
  //                 )}
  //                 {showUsers && <Definitions isUsers={true} />}
  //                 {showCashDesk && (
  //                   <div className='platform-panel'>
  //                     <CashDeskDaily acceptorCode={acceptorContext.current.code} switchable={true} />
  //                   </div>
  //                 )}
  //                 {showOnlineShop && !isSystem() && (
  //                   <div className='platform-panel'>
  //                     <OnlineShopService acceptorCode={acceptorContext.current.code} />
  //                   </div>
  //                 )}
  //                 {showOnlineShop && isSystem() && (
  //                   <div className='platform-panel'>
  //                     <BuildingService acceptorCode={acceptorContext?.current?.code} />
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       )}
  //       <Modal
  //         showModal={showWalletPayeeRequestModal}
  //         toggle={setShowWalletPayeeRequestModal}
  //         title={t('issue_factor')}
  //         isPlatformTheme={true}
  //         onClosed={() => setShowWalletPayeeRequestModal(false)}
  //         id='walletPayeeRequestModal'
  //         content={
  //           <WalletPayeeRequest
  //             acceptorCode={acceptorContext?.current?.code}
  //             showPreviousRequests={false}
  //             onSuccess={(ap: any, share: boolean) => {
  //               setShowWalletPayeeRequestModal(false)
  //               if (share) {
  //                 setAyriaPayment(ap)
  //                 setShowAyriaPaymentModal(true)
  //               }
  //             }}
  //           />
  //         }
  //       />
  //
  //       <Modal
  //         showModal={showAyriaPaymentModal}
  //         toggle={setShowAyriaPaymentModal}
  //         onClosed={() => {
  //           setShowAyriaPaymentModal(false)
  //           setAyriaPayment(null)
  //         }}
  //         title={`صورتحساب ${ayriaPayment?.trackingNumber}`}
  //         content={<AyriaPayment payment={ayriaPayment} onSuccess={() => {}} />}
  //         actionButtons={<IonButton onClick={() => setShowAyriaPaymentModal(false)}>{'بستن'}</IonButton>}
  //       />
  //       <Modal
  //         showModal={showDefinitionsModal}
  //         toggle={setShowDefinitionsModal}
  //         title={'مدیریت کالا و خدمات'}
  //         isPlatformTheme={true}
  //         id='definitions-modal'
  //         onClosed={() => setShowDefinitionsModal(false)}
  //         content={<Definitions isUsers={false} />}
  //       />
  //     </div>
  //   </Suspense>
  // )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    onlineOrderCount: state.shop.onlineOrderCount,
    loggedUser: state.user.loggedUser,
  }),
  mapDispatchToProps: {
    setAcceptorCode,
  },
  component: Panel,
})
