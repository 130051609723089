import React, { useEffect } from 'react'
import { URLSearchParams } from 'whatwg-url'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { connect } from '../data/connect'
import { CLUB_HAS_LANDING } from '../config'
import User from '../models/User'
import Club from '../models/Club'
import ApiService from '../api/ApiService'
import { useToast } from '../utility/toaster/useToast'
import { setTheme, setClubCode, setSavedClub, setGiftToken } from '../data/user/user.actions'

declare var document: any

interface StateProps {
  hasSeenLanding: boolean
  loggedUser?: User
  isLoggedIn?: boolean
  savedClub?: Club
  giftToken?: string
}
interface DispatchProps {
  setClubCode: typeof setClubCode
  setTheme: typeof setTheme
  setSavedClub: typeof setSavedClub
  setGiftToken: typeof setGiftToken
}

interface ClubStartPageProps extends StateProps, DispatchProps {}

const ClubStartPage: React.FC<ClubStartPageProps> = ({
  hasSeenLanding,
  loggedUser,
  setClubCode,
  setTheme,
  isLoggedIn,
  setSavedClub,
  savedClub,
  giftToken,
  setGiftToken,
}) => {
  const api = new ApiService()
  const { clubCode } = useParams<any>()
  const toast = useToast()
  const history = useHistory()
  let urlSearchParams = new URLSearchParams(window.location.search)

  const fetchClubCodeInfo = async () => {
    const res: any = await api.publicReferralCode(clubCode)
    if (res.ok && res.data.type === 'CLUB') {
      await setSavedClub(res.data)
      await setClubCode(res.data.code)
      if (res.data.theme) {
        await setTheme(res.data.theme)
      }
      document.title = res.data.name
    }
  }

  const joinClub = async () => {
    const res: any = await api.userClubJoin(clubCode)
    if (res.ok) {
      if (res.status === 201) {
        toast.success(`شما با موفقیت عضو ${res.data.name} شدید`)
      }
    } else {
      toast.error(res)
    }
  }

  const redeemGiftCard = async () => {
    const res: any = await api.delete(`/customer/gift-card/redeem/${giftToken}`)
    if (res.ok) {
      if (res.status === 201) {
        toast.success(
          `مبلغ ${res.data.amount} ریال با موفقیت به ${
            res.data.giftType === 'VOUCHER' ? 'اسپوند شما ' : 'کیف پول شما '
          } ${!!res.data.clubName ? ` در باشگاه ${res.data.clubName} ` : ''} اضافه شد`
        )
      }
      if (res.status === 200) {
        toast.success('این کارت قبلا با موفقیت به حساب کاربری شما اضافه شده است')
      }
      if (res.status === 208) {
        toast.error('این کارت قبلا بوسیله شخص دیگری استفاده شده است!')
      }
    } else {
      toast.error(res)
    }
    setGiftToken(null)
  }

  const resolveReferralCode = () => {
    return urlSearchParams?.get('ref') ? urlSearchParams.get('ref') : clubCode
  }

  const hasNext = () => {
    urlSearchParams = new URLSearchParams(window.location.search)
    return urlSearchParams?.get('next') ? true : false
  }

  const redirectTo = async () => {
    if (clubCode) {
      await fetchClubCodeInfo()
    }
    if (urlSearchParams.get('gc')) {
      await setGiftToken(urlSearchParams.get('gc'))
    }
    if (isLoggedIn) {
      if (clubCode) {
        await joinClub()
      }
      if (hasNext()) {
        const table = urlSearchParams.get('t')
        history.push(`${urlSearchParams.get('next')}${!!table ? `?t=${table}` : ''}`)
      } else {
        history.push(`/tabs?ref=${resolveReferralCode()}`)
      }
    } else {
      if (hasNext()) {
        const table = urlSearchParams.get('t')
        localStorage.setItem(
          'REDIRECT_PATH',
          `/s/${clubCode}?next=${urlSearchParams.get('next')}&ref=${resolveReferralCode()}${
            !!table ? `&t=${table}` : ''
          }`
        )
        history.push(`${urlSearchParams.get('next')}?ref=${resolveReferralCode()}${!!table ? `&t=${table}` : ''}`)
      } else if (!localStorage.getItem('REDIRECT_PATH')) {
        localStorage.setItem('REDIRECT_PATH', `/s/${clubCode}`)
        history.push(`/login?ref=${resolveReferralCode()}`)
      } else {
        if (hasSeenLanding || !CLUB_HAS_LANDING) {
          history.push(`/login?ref=${resolveReferralCode()}`)
        } else {
          history.push(`/start?ref=${resolveReferralCode()}`)
        }
      }
    }
  }

  useEffect(() => {
    redirectTo()
  }, [isLoggedIn]) // eslint-disable-line

  useEffect(() => {
    if (!!giftToken && isLoggedIn) {
      redeemGiftCard()
    }
  }, [isLoggedIn, giftToken]) // eslint-disable-line

  return <></>
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hasSeenLanding: state.user.hasSeenLanding,
    loggedUser: state.user.loggedUser,
    isLoggedIn: state.user.isLoggedIn,
    savedClub: state.user.savedClub,
    giftToken: state.user.giftToken,
  }),
  mapDispatchToProps: {
    setClubCode,
    setTheme,
    setSavedClub,
    setGiftToken,
  },
  component: ClubStartPage,
})
