import React from 'react'
import { IonRow, IonCol, IonText } from '@ionic/react'
import { AcceptorLogo } from '../../../ayria'

interface AyriaPaymentPrintProps {
  item: any
}

const AyriaPaymentPrint: React.FC<AyriaPaymentPrintProps> = ({ item }) => {
  return (
    <>
      <IonRow>
        <IonCol className='ion-text-center'>
          <AcceptorLogo referralCode={item.payeeReferralCode} width='100px' />
          <div style={{ position: 'absolute', left: 0, top: 2 }}>
            <span style={{ fontSize: '11px' }}>{'شماره'}</span>
            <span className='ion-padding-start'>{item.number}</span>
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' className='ion-no-padding ion-text-center border-color-07'>
          <IonText>
            {item.receiveType === 'TABLES'
              ? `میز ${item.tableIdentifier}`
              : item.receiveType === 'PICKUP'
              ? 'حضوری'
              : 'پیک'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <span className='font-11'>{'ش پیگیری '}</span>{' '}
          <span className='ion-padding-start'>{item.trackingNumber}</span>
        </IonCol>
        <IonCol className='ion-text-end'>{item.createdDate.replaceAll('-', '/')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <span className='font-11'>
            {'صندوقدار '} {item.cashierName}
          </span>
        </IonCol>
        <IonCol className='ion-text-end f-100'>{item.createdTime}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol style={{ border: '1px solid' }}>
          <IonRow>
            <IonCol>{'مشتری گرامی'}</IonCol>
            <IonCol>{item.payerName}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <span className='ion-padding-end'>{'آدرس'}</span>{' '}
              <span style={{ fontSize: '90%' }}>{item.deliveryAddress}</span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{'شماره همراه'}</IonCol>
            <IonCol>{item.payerMobile}</IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' style={{ borderBottom: '1px solid', borderRight: '1px solid', borderLeft: '1px solid' }}>
          <IonRow style={{ borderBottom: '1px solid' }}>
            <IonCol>
              <strong>{'نام محصول'}</strong>
            </IonCol>
            <IonCol size='3'>
              <strong>{'تعداد'}</strong>
            </IonCol>
            <IonCol className='ion-text-end'>
              <strong>{'مبلغ'}</strong>(ریال)
            </IonCol>
          </IonRow>
          {item?.kalas?.map((k: any, i: number) => (
            <IonRow key={k.id + ' ' + i}>
              <IonCol>{k.name}</IonCol>
              <IonCol size='2'>{k.count}</IonCol>
              <IonCol className='ion-text-end'>{k.price.toLocaleString(navigator.language)}</IonCol>
            </IonRow>
          ))}
          <IonRow>
            <IonCol className='ion-text-end'>{'جمع'}</IonCol>
            <IonCol className='ion-text-end'>{item?.unitedAmount?.toLocaleString(navigator.language)}</IonCol>
          </IonRow>
          {item?.shippingFee > 0 && (
            <IonRow>
              <IonCol className='ion-text-end'>{'هزینه پیک'}</IonCol>
              <IonCol className='ion-text-end'>{item?.shippingFee.toLocaleString(navigator.language)}</IonCol>
            </IonRow>
          )}
          {item?.tax > 0 && (
            <IonRow>
              <IonCol className='ion-text-end'>{'مالیات بر ارزش افزوده'}</IonCol>
              <IonCol className='ion-text-end'>{item?.tax.toLocaleString(navigator.language)}</IonCol>
            </IonRow>
          )}
          {item?.tip > 0 && (
            <IonRow>
              <IonCol className='ion-text-end'>{'انعام'}</IonCol>
              <IonCol className='ion-text-end'>
                {item.tip.toLocaleString(navigator.language)} {'ریال'} {!!item?.waiter ? `(${item.waiter})` : ''}
              </IonCol>
            </IonRow>
          )}
          <IonRow style={{ borderTop: '1px solid' }}>
            <IonCol className='ion-text-end'>
              <strong>{'قابل پرداخت'}</strong>
            </IonCol>
            <IonCol className='ion-text-end'>
              <strong>
                {item.customerAmountActual.toLocaleString(navigator.language)} {'ریال'}
              </strong>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default AyriaPaymentPrint
