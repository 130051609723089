import React, { Suspense, useEffect, useState } from 'react'
import { IonSpinner } from '@ionic/react'
import './acceptorList.scss'
import AcceptorCard from './AcceptorCard'
import { RouteComponentProps, withRouter, useParams } from 'react-router'
import { ApiService } from '../../ayria'

const narenj = [100496, 100499, 100500, 100501, 100502, 100508]

interface OwnProps extends RouteComponentProps {}

const FoodCourt: React.FC<OwnProps> = ({ history, match }) => {
  const api = new ApiService()
  const { court } = useParams<any>()
  const courtFix = court || match.url.substring(match.url.lastIndexOf('/') + 1)
  const [acceptors, setAcceptors] = useState([])
  const courtMap: any = { narenj: narenj }

  const handleRowClick = (center: any) => {
    history.push(`/tabs/center/${center?.referralCode}`)
  }

  const fetchItems = async () => {
    const res: any = await api.get(
      `/public/acceptors/active?${courtMap[courtFix].map((code: string) => `code.in=${code}`).join('&')}`
    )
    setAcceptors(res.ok ? res.data : [])
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <Suspense fallback={<IonSpinner color='primary' />}>
      <img
        src='/assets/images/narenj.jpg'
        alt=''
        style={{ height: 200, objectFit: 'cover', width: '100%', objectPosition: 'bottom' }}
      />

      <div className='clear-both'>
        <div id='acceptor-list-item'>
          {acceptors.length > 0 &&
            acceptors.map((item: any, index: number) => {
              return (
                <div key={item.code + ' ' + index}>
                  <AcceptorCard onCenterClick={() => handleRowClick(item)} item={item} savedClub={null} />
                </div>
              )
            })}
        </div>
      </div>
    </Suspense>
  )
}

export default withRouter(FoodCourt)
