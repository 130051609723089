import React from "react";
import Lightbox from "./lightbox";
import { useState } from "react";

interface MerchantProfileImagesProps {
  documentPrefixUrl: string;
  images: any[];
  numOfCols?: number;
}

const MerchantProfileImages: React.FC<MerchantProfileImagesProps> = ({ documentPrefixUrl, images, numOfCols = 1 }) => {
    const [showLightBox, setShowLightBox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const handleOnClick = (item: any) => {
        setShowLightBox(true);
        setSelectedImage(item);
    };

    const handleLightBoxClose = () => {
        setShowLightBox(false);
        setSelectedImage(null);
    };
    const formattedImages = images.map(
        element => documentPrefixUrl + element.identifier
    );
    return (
        <div className={"merchant-profile-images"}>
            {formattedImages.map((element: any, i: number) => (
                <div
                    style={{ width: `${100 / numOfCols}%` }}
                    className={"image-item"}
                    key={'element-' + i }
                    onClick={() => handleOnClick(element)}
                >
                    <img
                        key={'image-' + i }
                        width={"100%"}
                        src={element}
                        alt={element.identifier}
                    />
                </div>
            ))}

            <Lightbox
                isOpen={showLightBox}
                firstImage={selectedImage}
                images={formattedImages}
                onClose={handleLightBoxClose}
            />
        </div>
    );
};

export default MerchantProfileImages;
