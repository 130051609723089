import React, { useState } from 'react';

const ScoreContext = React.createContext([{}, () => {}]);

const ScoreProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ScoreContext.Provider value={[state, setState]}>
      {props.children}
    </ScoreContext.Provider>
  );
}

export { ScoreContext, ScoreProvider };
