import { IonButton } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ApiService } from '../../ayria'
import { connect } from '../../data'
import { Club } from '../../models'
import AcceptorCard from './AcceptorCard'
import AcceptorCardMore from './AcceptorCardMore'

interface OwnProps {
  title: string
  department?: string
  sortByNewCenters?: boolean
  sortByRank?: boolean
  sortByRate?: boolean
}
interface StateProps {
  savedClub?: Club
  shippingAddress: any
}
interface AcceptorVitrinProps extends OwnProps, StateProps {}

const AcceptorVitrin: FC<AcceptorVitrinProps> = ({
  savedClub,
  shippingAddress,
  department,
  title,
  sortByNewCenters,
  sortByRank,
  sortByRate,
}) => {
  const api = new ApiService()
  const history = useHistory()
  const [acceptors, setAcceptors] = useState([])

  const getAcceptors = async (page: number, size: number) => {
    // alert(department)
    const res: any = await api.get(
      `/acceptors/active?page=${page}&size=${size}${
        !!department && department !== 'ALL' ? `&department.equals=${department}` : ''
        // : otherDeps.map((d) => `&department.in=${d}`).join('')
      }${!!savedClub?.code ? `&clubCode.equals=${savedClub?.code}` : ''}${
        sortByNewCenters ? '&createdDate.lessThan=2060-06-06T00:00:00Z' : ''
      }${sortByRank ? '&rank.greaterThan=0' : ''}${sortByRate ? '&rate.greaterThan=0' : ''}`
    )
    if (res.ok) {
      setAcceptors(res.data)
    }
  }

  const handleRowClick = (center: any) => {
    history.push(`/tabs/center/${center?.referralCode}`)
  }

  const getSortProperty = () => {
    if (sortByNewCenters) {
      return 'new'
    } else if (sortByRank) {
      return 'rank'
    } else if (sortByRate) {
      return 'rate'
    }
  }

  useEffect(() => {
    getAcceptors(0, 5)
  }, [department, shippingAddress?.id]) // eslint-disable-line

  return (
    <div className='clear-both mt-2'>
      <div className='px-3 py-0 d-flex flex-row align-items-center justify-content-between'>
        <h5 style={{ fontWeight: 'bold' }}>{title}</h5>
        <IonButton
          fill='clear'
          color='medium'
          onClick={() => history.push(`/search/center?sort=${getSortProperty()}&title=${title}`)}>
          بیشتر
        </IonButton>
      </div>

      <div id='acceptor-list-item' className='acceptor-list'>
        {acceptors.length > 0 && (
          <>
            {acceptors.map((item: any, index: number) => {
              return (
                <div className='col-10 p-0' key={item.code + ' ' + index}>
                  <AcceptorCard item={item} savedClub={savedClub} onCenterClick={() => handleRowClick(item)} />
                </div>
              )
            })}
            <AcceptorCardMore link={`/search/center?sort=${getSortProperty()}&title=${title}`} />
          </>
        )}
      </div>
    </div>
  )
}

export default connect<OwnProps, StateProps, {}>({
  component: AcceptorVitrin,
})
