import React from 'react'
import { IonIcon } from '@ionic/react'

interface AyriaIconProps {
  icon: any
  size?: string
  color?: string
  slot?: string
  title?: string
}

const AyriaIcon: React.FC<AyriaIconProps> = ({ icon, size, color, slot, title, ...inputProps }) => {
  return (
    <IonIcon
      icon={icon}
      className='pointer-events-none'
      size={!!size ? size : ''}
      slot={!!slot ? slot : 'icon-only'}
      color={!!color ? color : 'dark'}
      title={title}
      {...inputProps}
    />
  )
}

export default AyriaIcon
