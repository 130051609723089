import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import heart from '../../screens/merchant-profile/icons/1.png'
import broken_heart from '../../screens/merchant-profile/icons/2.png'
import indifference from '../../screens/merchant-profile/icons/3.png'
import heart_gray from '../../screens/merchant-profile/icons/1-gray.png'
import broken_heart_gray from '../../screens/merchant-profile/icons/2-gray.png'
import indifference_gray from '../../screens/merchant-profile/icons/3-gray.png'

interface RateInputProps {
  onRateChange(rate: number): () => {}
}

const RateInput: React.FC<RateInputProps> = ({ onRateChange }) => {
  const [rate, setRate] = useState<number>()
  const { t } = useTranslation()

  const updateRate = (rate: number) => {
    setRate(rate)
    onRateChange(rate)
  }

  return (
    <>
      <span>{t('what_was_your_experiance')}</span>
      <div className='text-center flex-container' style={{ justifyContent: 'center' }}>
        <span className='p-2'>
          {rate !== 3 && (
            <img
              alt='3'
              src={heart_gray}
              className='rating-icon pointer'
              onClick={(e) => updateRate(3)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 3 && (
            <img
              alt='3'
              src={heart}
              className='rating-icon pointer'
              onClick={(e) => updateRate(0)}
              width='80px'
              heigth='80x'
            />
          )}
          <br />
          <span onClick={(e) => updateRate(3)}>{t('good')}</span>
        </span>
        <span className='p-2'>
          {rate !== 2 && (
            <img
              alt='2'
              src={indifference_gray}
              className='rating-icon pointer'
              onClick={(e) => updateRate(2)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 2 && (
            <img
              alt='2'
              src={indifference}
              className='rating-icon pointer'
              onClick={(e) => updateRate(0)}
              width='80px'
              heigth='80x'
            />
          )}
          <br />
          <span onClick={(e) => updateRate(2)}>{t('no_opinion')}</span>
        </span>
        <span className='p-2'>
          {rate !== 1 && (
            <img
              alt='1'
              src={broken_heart_gray}
              className='rating-icon pointer'
              onClick={(e) => updateRate(1)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 1 && (
            <img
              alt='1'
              src={broken_heart}
              className='rating-icon pointer'
              onClick={(e) => updateRate(0)}
              width='80px'
              heigth='80x'
            />
          )}
          <br />
          <span onClick={(e) => updateRate(1)}>{t('bad')}</span>
        </span>
      </div>
    </>
  )
}

export default RateInput
