import React, { useState, useEffect, lazy, FC } from 'react'
import { getSortState } from '../../../utility/jhipster'
import api from '../../../api/Api'
import { toGregorian } from '../../../utility/calendar'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../utility/pagination'
import ItemCount from '../../../utility/item-count'
import { CustomModal, isDesktop, isMobile } from '../../../ayria'
import Score from '../score/score'
import { connect } from '../../../data'
import { IonCol, IonRow } from '@ionic/react'
const DatePicker = lazy(() => import('react-modern-calendar-datepicker'))

interface OwnProps {
  code?: string
  isAcceptor?: boolean
  hideBalance?: boolean
  type?: string
}
interface StateProps {
  acceptorCode?: string
}

interface ScoreEventsProps extends OwnProps, StateProps {}

const ScoreEvents: FC<ScoreEventsProps> = ({
  code,
  isAcceptor = false,
  acceptorCode,
  hideBalance = false,
  type = 'user',
}) => {
  const [scores, setScores] = useState<any>([])
  const [pagination, setPagination] = useState<any>(getSortState(window.location, ITEMS_PER_PAGE, 'id'))
  const [totalItems, setTotalItems] = useState<any>(0)
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)
  const [item, setItem] = useState<any>({})
  const [showModal, setShowModal] = useState(false)

  async function fetchScoreEvents(page: number, size: number, sort: string) {
    try {
      const res: any = await api.get(
        `/customer/scores/${isAcceptor ? acceptorCode : !!code ? code : '0'}${
          sort ? `?page=${page}&size=${size}&sort=${sort}` : ''
        }${fromDate ? `&fromDate=${toGregorian(fromDate)}` : ''}${toDate ? `&toDate=${toGregorian(toDate)}` : ''}`
      )
      if (res.ok) {
        setScores(res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
  }

  const getAllScores = () =>
    fetchScoreEvents(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllScores()

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
    getAllScores()
  }, []) // eslint-disable-line

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [pagination.activePage, pagination.order, pagination.sort, fromDate, toDate, code, acceptorCode])

  const handlePagination = (currentPage: number) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <div>
      {!hideBalance && (
        <>
          <Score code={isAcceptor ? acceptorCode : code} type={type} />
          <br />
        </>
      )}
      <div className='row'>
        <div className='form-group col-6 text-left'>
          <DatePicker
            value={fromDate}
            onChange={setFromDate}
            inputPlaceholder='از تاریخ'
            shouldHighlightWeekends
            locale='fa'
            calendarPopperPosition={'bottom'}
          />
        </div>
        <div className='form-group col-6'>
          <DatePicker
            value={toDate}
            onChange={setToDate}
            inputPlaceholder='تا تاریخ'
            shouldHighlightWeekends
            locale='fa'
            calendarPopperPosition={'bottom'}
          />
        </div>
      </div>
      <div>
        {isDesktop() && (
          <IonRow>
            <IonCol>
              <b>{'توضیحات'}</b>
            </IonCol>
            <IonCol size='2'>
              <b>{'تاریخ'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='1'>
              <b>{'واریز'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='2'>
              <b>{'برداشت'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='1'>
              <b>{'مانده'}</b>
            </IonCol>
          </IonRow>
        )}
        {scores.map((score: any, index: number) => {
          return (
            <div key={score.createdDate + '-' + index}>
              {isMobile() && (
                <div
                  className='flex-container border-bottom pointer'
                  onClick={(e) => {
                    setItem(score)
                    setShowModal(true)
                  }}>
                  <span className='flex-3'>
                    <p className='ellipsis m-0'>
                      {score.sourceDescription ? score.sourceDescription : score.source.toLowerCase()}
                      <br />
                      {score.createdDate}
                    </p>
                  </span>
                  <span className='flex-1 text-left'>
                    {score.amount}
                    {score.type === 'WITHDRAW' && <span className={'text-danger'}>-</span>}
                  </span>
                </div>
              )}
              {isDesktop() && (
                <IonRow
                  key={score.createdDate + '-' + index}
                  style={{ borderBottom: '0.5px solid #cccccc' }}
                  onClick={(e) => {
                    setItem(score)
                    setShowModal(true)
                  }}>
                  <IonCol>{score.sourceDescription ? score.sourceDescription : score.source.toLowerCase()}</IonCol>
                  <IonCol size='2'>{score.createdDate}</IonCol>
                  <IonCol size='1' className='ion-text-end text-success'>
                    {score.type === 'DEPOSIT' ? score.amount : ''}{' '}
                  </IonCol>
                  <IonCol size='2' className='ion-text-end'>
                    {score.type === 'WITHDRAW' ? (
                      <>
                        {score.amount}
                        <span className='text-danger'>-</span>
                      </>
                    ) : (
                      ''
                    )}{' '}
                  </IonCol>
                  <IonCol size='1' className='ion-text-end'>
                    {score.balance}
                  </IonCol>
                </IonRow>
              )}
            </div>
          )
        })}
      </div>
      <div className='row text-center'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
      <CustomModal
        showModal={showModal}
        toggle={setShowModal}
        title={'جزییات امتیاز'}
        content={
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'امتیاز'}</td>
                <td>
                  {item.amount}
                  {item.type === 'WITHDRAW' && <span className={'text-danger'}>-</span>}
                </td>
              </tr>
              <tr>
                <td>{'توضیحات'}</td>
                <td>{item.sourceDescription}</td>
              </tr>
              <tr>
                <td>{'زمان ایجاد'}</td>
                <td className='dir-ltr'>
                  {item.createdDate} {item.createdTime}
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </div>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    acceptorCode: state.shop.acceptorCode,
  }),
  component: ScoreEvents,
})
