import React from 'react'
import { IonItemDivider, IonList, IonLabel, IonText } from '@ionic/react'
import { IonRow, IonCol } from '@ionic/react'
import { useTranslation } from 'react-i18next'

interface DeliveryHoursAllowedProps {
  deliveryHours: any[]
}

const DeliveryHoursAllowed: React.FC<DeliveryHoursAllowedProps> = ({ deliveryHours }) => {
  const { t } = useTranslation()

  return (
    <IonList>
      <IonItemDivider>
        <IonLabel>{'بازهای ثبت سفارش این فروشگاه:'}</IonLabel>
      </IonItemDivider>
      <IonRow>
        <IonCol></IonCol>
        <IonCol>
          <IonLabel>{'از'}</IonLabel>
        </IonCol>
        <IonCol>
          <IonLabel>{'تا'}</IonLabel>
        </IonCol>
      </IonRow>
      {deliveryHours.map((itm) => (
        <IonRow key={itm.dayOfWeek + itm.from}>
          <IonCol className={itm.highlight ? 'text-bold' : ''}>{t(itm.dayOfWeek)}</IonCol>
          <IonCol>
            <IonText className='ion-margin-horizontal'>{itm.from?.substr(0, 5)}</IonText>
          </IonCol>
          <IonCol>
            <IonText className='ion-margin-horizontal'>{itm.to?.substr(0, 5)}</IonText>
          </IonCol>
        </IonRow>
      ))}
    </IonList>
  )
}

export default DeliveryHoursAllowed
