import React, { FC } from 'react'
import { IonAvatar, IonCard, IonCardContent, IonCol, IonIcon, IonRow } from '@ionic/react'
import './ClubMemberCard.scss'
import { basket } from 'ionicons/icons'

interface OwnProps {
  item: any
  onUserClick?: () => void
}
interface ClubMemberCardProps extends OwnProps {}

const ClubMemberCard: FC<ClubMemberCardProps> = ({ item, onUserClick = () => {} }) => {
  return (
    <IonCard id='acceptor-card-component' style={{ margin: '10px', borderRadius: '15px', borderColor: 'whitesmoke' }}>
      <IonCardContent className='ion-no-padding' style={{ borderRadius: '15px' }}>
        <div
          style={{
            height: '160px',
            borderRadius: '15px',
          }}>
          <IonRow>
            <IonCol size='2'>
              <IonAvatar onClick={onUserClick}>
                <img alt='' src={`/assets/images/user_${item.gender === 'FEMALE' ? 'f' : 'm'}.jpeg`}></img>
              </IonAvatar>
            </IonCol>
            <IonCol className='ion-align-self-center'>
              <span className='ion-padding-start'>{item.fullname}</span>
            </IonCol>
            <IonCol size='4' className='ion-align-self-center ion-text-end'>
              <span>{item.mobile}</span>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{item.membershipDate}</IonCol>
            <IonCol></IonCol>
            <IonCol className='ion-text-end'>
              ID: <strong>{item.clubNumber}</strong>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {'اسپوند'} {item.voucher?.toLocaleString(navigator.language)} {' ریال '}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon icon={basket} /> {item.purchaseCount}
            </IonCol>
            <IonCol>
              {' جمع کل '} {(item.purchaseAmount || 0).toLocaleString(navigator.language)} {' ریال '}
            </IonCol>
          </IonRow>
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default ClubMemberCard
