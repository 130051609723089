import React, { useEffect, useState } from 'react'
import { IonPage, IonContent, useIonViewWillLeave, useIonViewWillEnter, IonIcon } from '@ionic/react'
import { IonFab, IonFabButton } from '@ionic/react'
import { reload } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import ApiService from '../../../api/ApiService'
import { Auth, Modal, EventEmitter } from '../../../utility'
import HeaderAcceptor from '../header/header-acceptor'
import Factors from '../../elements/Factors/Factors'
import { setMenuEnabled, setFailureCount, setIsLoggedIn } from '../../../data/user/user.actions'
import { setOnlineOrderCount } from '../../../data/shop/shop.actions'
import { connect, isDesktop, isMobile, CustomCard, CopyRight } from '../../../ayria'
import '../../../App-deprecated.scss'
import { barChartOutline, receiptOutline } from 'ionicons/icons'

interface OwnProps {
  children: React.ReactNode
  pageId: string
  showHeader?: boolean
}

interface StateProps {
  onlineOrderCount?: any
  acceptorCode: string
  failureCount: number
  isLoggedIn?: boolean
  socketDisconnected: boolean
}

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
  setOnlineOrderCount: typeof setOnlineOrderCount
  setFailureCount: typeof setFailureCount
  setIsLoggedIn: typeof setIsLoggedIn
}

interface PageContainerAcceptorProps extends DispatchProps, OwnProps, StateProps {}

const PageContainerAcceptor: React.FC<PageContainerAcceptorProps> = ({
  pageId,
  children,
  setMenuEnabled,
  showHeader = true,
  onlineOrderCount,
  setOnlineOrderCount,
  acceptorCode,
  failureCount,
  setFailureCount,
  isLoggedIn,
  setIsLoggedIn,
  socketDisconnected,
}) => {
  const auth = new Auth()
  const api = new ApiService()
  const history = useHistory()
  const [showOnlineOrders, setShowOnlineOrders] = useState(false)
  const [acceptor, setAcceptor] = useState()
  const [pickupOnly, setPickupOnly] = useState<boolean>()
  const [tableOnly, setTableOnly] = useState<boolean>()
  const [deliveryOnly, setDeliveryOnly] = useState<boolean>()

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  const getAccount = async () => {
    const res: any = await api.get('/account')
    if (res.ok) {
      auth.setLoggedInUser(res.data)
      await setIsLoggedIn(true)
      await setFailureCount(0)
      ReactGA.set({ userId: res.data.login })
    } else {
      await setFailureCount(+failureCount + 1)
      if (failureCount >= 2) {
        await setIsLoggedIn(false)
      }
      setTimeout(() => {
        history.push({ pathname: '/login-acceptor', search: window.location.search })
      }, 500)
    }
  }

  const getOnlineOrderCount = async () => {
    const res: any = await api.acceptorRolesFactorsOnlineOrderCount(acceptorCode + '')
    if (res.ok) {
      setOnlineOrderCount(res.data)
    }
  }
  const getAcceptor = async () => {
    const res: any = await api.get(`/acceptor-roles/current/${acceptorCode}`)
    setAcceptor(res.ok ? res.data : null)
  }

  useEffect(() => {
    getAccount()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!!acceptorCode) {
      getOnlineOrderCount()
      getAcceptor()
    }
  }, [acceptorCode]) // eslint-disable-line

  useEffect(() => {
    EventEmitter?.subscribe('ONLINE_ORDER_COUNT_NEED_UPDATE', () => {
      if (acceptorCode) {
        getOnlineOrderCount()
      }
    })
  }, [EventEmitter, acceptorCode]) // eslint-disable-line

  return (
    <>
      {isLoggedIn && (
        <IonPage id={pageId} className='platform-theme'>
          <IonContent>
            <div className='wrapper'>
              {showHeader && <HeaderAcceptor />}

              {isDesktop() && (
                <div className={'page-content platform-dashboard row '}>
                  <div className='col-1 customSideBar h-100 text-center'>
                    <br />
                    {pageId === 'desk' && (
                      <a href='/panel' style={{ color: '#FFF', textAlign: 'center' }}>
                        <IonIcon icon={barChartOutline} size='large' />
                        <br />
                        <span style={{ fontSize: 10 }}>پنل مدیریتی</span>
                      </a>
                    )}
                    {pageId === 'panel' && (
                      <a href='/desk' style={{ color: '#FFF' }}>
                        <IonIcon icon={receiptOutline} size='large' />
                        <br />
                        <span style={{ fontSize: 10 }}>صدور صورتحساب</span>
                      </a>
                    )}
                  </div>

                  <div className='col-1' />

                  <div className={`container col-11 pr-0 mr-0` + (showHeader ? ' p-container' : '')}>{children}</div>
                </div>
              )}
              {isMobile() && (
                <div className={`container pr-0 mr-0` + (showHeader ? ' p-container' : '')}>{children}</div>
              )}
              <footer>
                <CopyRight />
              </footer>
            </div>
            {socketDisconnected && (
              <IonFab vertical='bottom' horizontal='start' slot='fixed' edge>
                <div className='flex-container'>
                  <CustomCard
                    noMargin={true}
                    noPadding={true}
                    noTitleMarginBottom={true}
                    title={'اتصال لحظه ای'}
                    subtitle={'اتصال لحظه ای برقرار نیست'}
                    cssClass='border-color-06'
                    html={
                      <div
                        className='pointer'
                        onClick={() => {
                          window.location.reload()
                        }}>
                        <IonFabButton color='danger' style={{ margin: 'auto' }}>
                          <IonIcon icon={reload} />
                        </IonFabButton>
                      </div>
                    }
                  />
                </div>
              </IonFab>
            )}
            {isDesktop() && !!onlineOrderCount?.count && acceptorCode && (
              <IonFab vertical='bottom' horizontal='start' slot='fixed' edge>
                <div className='flex-container'>
                  {!!onlineOrderCount?.delivery && (
                    <CustomCard
                      noMargin={true}
                      noPadding={true}
                      noTitleMarginBottom={true}
                      title={'سفارش آنلاین'}
                      subtitle={
                        onlineOrderCount?.delivery === 1 ? 'لطفا به سفارش رسیدگی کنید' : 'لطفا به سفارشات رسیدگی کنید'
                      }
                      html={
                        <div
                          className='pointer'
                          onClick={() => {
                            setTableOnly(false)
                            setPickupOnly(false)
                            setDeliveryOnly(true)
                            setShowOnlineOrders(true)
                          }}>
                          <IonFabButton color='danger' style={{ margin: 'auto' }}>
                            {onlineOrderCount?.delivery}
                          </IonFabButton>
                        </div>
                      }
                    />
                  )}
                  {!!onlineOrderCount?.pickup && (
                    <span className='ion-padding-start'>
                      <CustomCard
                        noMargin={true}
                        noPadding={true}
                        noTitleMarginBottom={true}
                        title={'دریافت حضوری'}
                        subtitle={
                          onlineOrderCount?.pickup === 1 ? 'لطفا به سفارش رسیدگی کنید' : 'لطفا به سفارشات رسیدگی کنید'
                        }
                        html={
                          <div
                            className='pointer'
                            onClick={() => {
                              setPickupOnly(true)
                              setTableOnly(false)
                              setDeliveryOnly(false)
                              setShowOnlineOrders(true)
                            }}>
                            <IonFabButton color='warning' style={{ margin: 'auto' }}>
                              {onlineOrderCount?.pickup}
                            </IonFabButton>
                          </div>
                        }
                      />
                    </span>
                  )}
                  {!!onlineOrderCount?.table && (
                    <span className='ion-padding-start'>
                      <CustomCard
                        noMargin={true}
                        noPadding={true}
                        noTitleMarginBottom={true}
                        title={'سالن'}
                        subtitle={
                          onlineOrderCount?.table === 1 ? 'لطفا به سفارش رسیدگی کنید' : 'لطفا به سفارشات رسیدگی کنید'
                        }
                        html={
                          <div
                            className='pointer'
                            onClick={() => {
                              setTableOnly(true)
                              setPickupOnly(false)
                              setDeliveryOnly(false)
                              setShowOnlineOrders(true)
                            }}>
                            <IonFabButton color='success' style={{ margin: 'auto' }}>
                              {onlineOrderCount?.table}
                            </IonFabButton>
                          </div>
                        }
                      />
                    </span>
                  )}
                </div>
              </IonFab>
            )}
            <Modal
              showModal={showOnlineOrders}
              toggle={setShowOnlineOrders}
              title={'سفارشات'}
              isPlatformTheme={true}
              id={'factors-modal'}
              onClosed={() => setShowOnlineOrders(false)}
              content={
                <Factors
                  acceptor={acceptor}
                  onlineOrderOnly={true}
                  onlineOrderTable={tableOnly}
                  onlineOrderPickup={pickupOnly}
                  onlineOrderDelivery={deliveryOnly}
                />
              }
            />
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    onlineOrderCount: state.shop.onlineOrderCount,
    acceptorCode: state.shop.acceptorCode,
    failureCount: state.user.failureCount,
    isLoggedIn: state.user.isLoggedIn,
    socketDisconnected: state.shop.socketDisconnected,
  }),
  mapDispatchToProps: {
    setMenuEnabled,
    setOnlineOrderCount,
    setFailureCount,
    setIsLoggedIn,
  },
  component: PageContainerAcceptor,
})
