import Route from 'route-parser';

const endpoints = {
    getTransactions: new Route('/transactions'),
    getAllAcceptors: new Route('/acceptors/all'),
    getAccount: new Route('/account'),
    updateAccount: new Route('/account'),
    getProfile: new Route('/account/profile'),
    updateProfile: new Route('/account/profile'),
    passwordResetFinish: new Route('/account/reset-password/finish'),
    passwordReset: new Route('/account/reset-password/init'),
    passwordChange: new Route('/account/change-password'),
    getUser: new Route('/user'),
    login: new Route('/login'),
    getMe: new Route('/users/me'),
    signup: new Route('/register'),
    activateCode: new Route('/activate'),
    reSendActivateCode: new Route('/re-send-activate-code'),
    getPlan: new Route('/account/plan'),
    selectPlan: new Route('/account/plan/select'),
    getCards: new Route('/user/cards'),
    getCardInvoice: new Route('/user/cards/add-card-invoice'),
    calculatePoint: new Route('/point/calculate'),
    getWalletDepositInvoice: new Route('/wallet/deposit-invoice'),
    setWalletDepositExtraInfo: new Route('/invoice/extra-info-wallet-deposit'),
    setInvoiceExtraInfo: new Route('/invoice/extra-info'),
    getScore: new Route('/customer/scores/total/0'),
    getPurchasesForRate: new Route('/customer/purchases/for-rate'),
    getTotals: new Route('/customer/purchases/totals'),
    getInvoices: new Route('/customer/invoices'),
    activateCard: new Route('/user/cards/activate-card'),
    deleteCard: new Route('/user/cards/delete-card'),
    ratePurchase: new Route('/customer/purchases/rate'),
    acceptorRequest: new Route('/acceptor/request'),
    acceptorSetProfile: new Route('/acceptor/send-document'),
    getCurrentAcceptors: new Route('/acceptor/current'),
    getWalletBalance: new Route('/user/wallet-snapshots/current'),
    getUserWithMobile: new Route('/wallet/user-with-mobile'),
    walletTransfer: new Route('/wallet/transfer'),
    walletPurchase: new Route('/wallet/purchase'),
    walletPurchasePreview: new Route('/wallet/purchase/preview'),
    registerOfflinePurchase: new Route('/customer/purchases/register-offline'),
    cardBalance: new Route('/user/cards/balance'),
    cardTransferInit: new Route('/user/cards/transfer/init'),
    cardTransferComplete: new Route('/user/cards/transfer/complete'),
    getBoomOAuthLogin: new Route('/banking/oauth/login'),
    getBoomOAuthToken: new Route('/banking/oauth/token'),
    boomOAuthLogout: new Route('/banking/oauth/logout'),
    ibanNumberInfo: new Route('/banking/iban/info'),
    shebaTransfer: new Route('/banking/sheba/transfer')
};

export default endpoints;
