import { create } from 'apisauce'
import { GEOCODING_BASE_URL } from '../config'

// define the api
const geocodingApi = create({
  baseURL: GEOCODING_BASE_URL,
  timeout: 30000,
})

export default geocodingApi
