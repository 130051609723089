import React, { useState, useEffect, useContext } from 'react'
import { IonItem, IonLabel, IonCheckbox, IonButton, IonText } from '@ionic/react'
import { IonChip } from '@ionic/react'
import { archive } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import WalletDeposit from '../../../elements/WalletDeposit/WalletDeposit'
import WalletBalance from '../../../elements/WalletBalance/WalletBalance'
import { WalletContext } from '../../../structures/contexts/WalletContext'
import { CurrencyIonInput, AcceptorLogo } from '../../../units'
import { IPG_MIN_ACCEPTED_AMOUNT } from '../../../../config'
import { ApiService, connect, setUnpaidFactorCount, setUnpaidPersonCount } from '../../../../ayria'
import { useToast, CustomModal, AyriaIcon } from '../../../../ayria'
import './wallet-response.css'

interface OwnProps {
  trackingNumberProp?: string
  onSuccess: Function
}
interface DispatchProps {
  setUnpaidFactorCount: typeof setUnpaidFactorCount
  setUnpaidPersonCount: typeof setUnpaidPersonCount
}
interface WalletResponseProps extends OwnProps, DispatchProps {}

const WalletResponse: React.FC<WalletResponseProps> = ({
  trackingNumberProp,
  onSuccess,
  setUnpaidFactorCount,
  setUnpaidPersonCount,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const { t } = useTranslation()
  const [wallet, setWallet] = useContext<any>(WalletContext)
  const [inprogress, setInprogress] = useState(true)
  const [item, setItem] = useState<any>({ amount: 0 })
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [neededAmount, setNeededAmount] = useState(0)
  const [originalCommand, setOriginalCommand] = useState<any>()
  const [tip, setTip] = useState('0')
  const [tipActive, setTipActive] = useState(false)
  const { trackingNumber } = useParams<any>()

  const payForReal = async (useScore: boolean) => {
    setInprogress(true)
    const cmd = { trackingNumber: item.trackingNumber, useScore, tip }
    const res: any = await api.post('/user/ayria-payments/pay', cmd)
    if (res.ok) {
      if (res.status === 202) {
        window.location.href = res.headers['x-redirect-url']
      } else {
        toast.success(t('label.successfulOperation'))
        if (onSuccess) {
          onSuccess(res.data)
        } else {
          getItem(item.trackingNumber)
          setShowPaymentMethodModal(false)
        }
      }
    } else {
      if (res.status === 417) {
        const debt = item.customerAmountActual + (tip ? +tip : 0) - wallet.balance
        setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
        setOriginalCommand(JSON.stringify(cmd))
        setShowWalletDepositModal(true)
      } else {
        toast.error(res)
      }
    }
    setInprogress(false)
  }

  const archiveForReal = async () => {
    setInprogress(true)
    const res: any = await api.post(`/user/ayria-payments/archive/${item.trackingNumber}`, {})
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      if (onSuccess) {
        onSuccess(false)
      } else {
        getItem(item.trackingNumber)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const getWalletBalance = async () => {
    setInprogress(true)
    const res: any = await api.post('/user/wallet-snapshots/current', {})
    if (res.ok) {
      setWallet((state: any) => ({ ...state, balance: res.data.balance }))
    }
    setInprogress(false)
  }

  const getItem = async (trackingNumber: any) => {
    setInprogress(true)
    const res: any = await api.get(`/user/ayria-payments/payer-response/${trackingNumber}`)
    if (res.ok) {
      setItem(res.data)
    }
    setInprogress(false)
  }

  const isPayeeCompany = () => {
    if (item.payeeType && item.payeeType === 'ACCEPTOR') {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!tipActive) {
      setTip('0')
    }
  }, [tipActive])

  useEffect(() => {
    getItem(trackingNumberProp ? trackingNumberProp : trackingNumber)
    if (!wallet.balance) {
      getWalletBalance()
    }
  }, []) // eslint-disable-line

  return (
    <div className='ion-padding' style={{ paddingTop: 0, minHeight: '500px' }}>
      {!inprogress && !item.trackingNumber && (
        <>
          {'پرداختی با کد پیگیری '}
          <IonText color='danger'>{trackingNumberProp || trackingNumber}</IonText>
          {' برای شما وجود ندارد.'}
        </>
      )}
      {item.paid && (
        <div className='alert alert-success'>
          {t('ayriaPayment.shortDescriptionPaid', {
            payeeName: item.payeeName,
            amount: item.unitedAmount.toLocaleString(navigator.language),
          })}
        </div>
      )}
      {item.canceled && (
        <div className='alert alert-warning'>
          {t('ayriaPayment.shortDescriptionCanceled', {
            payeeName: item.payeeName,
            amount: item.unitedAmount.toLocaleString(navigator.language),
          })}
        </div>
      )}
      {item?.unitedAmount && (
        <div>
          {isPayeeCompany() && (
            <div className='text-center'>
              <AcceptorLogo referralCode={item.payeeReferralCode} width='100px' />
            </div>
          )}
          <div className='flex-container border-bottom min-height'>
            <span className='flex-1'>{isPayeeCompany() ? 'فروشگاه' : t('ayriaPayment.payeeName')}</span>
            <span className='flex-1' title={item.payeeIdentity}>
              {item.payeeName}
            </span>
          </div>
          {item.kalas?.map((kala: any) => {
            return (
              <div className='flex-container border-bottom min-height' key={kala.name}>
                <span className='flex-1 ion-text-center'>
                  <IonText className='ion-padding-end'>
                    {kala.name} ({kala.count})
                  </IonText>
                </span>
                <span className='flex-1'>
                  {kala.totalPrice.toLocaleString(navigator.language)}
                  {' ریال'}
                </span>
              </div>
            )
          })}
          {isPayeeCompany() && item.shippingFee > 0 && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'هزینه پیک'}</span>
              <span className='flex-1'>
                {item.shippingFee.toLocaleString(navigator.language)} {t('label.riyal')}
              </span>
            </div>
          )}
          <div className='flex-container border-bottom min-height'>
            <span className='flex-1'>{'مبلغ کل'}</span>
            <span className='flex-1'>
              {item.unitedAmount.toLocaleString(navigator.language)} {t('label.riyal')}
            </span>
          </div>
          {isPayeeCompany() && item.tax > 0 && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'مالیات بر اررش افزوده'}</span>
              <span className='flex-1'>
                {item.tax.toLocaleString(navigator.language)} {t('label.riyal')}
              </span>
            </div>
          )}
          <div className='flex-container border-bottom min-height'>
            <span className='flex-1'>
              <strong>{'مبلغ قابل پرداخت'}</strong>
            </span>
            <span className='flex-1'>
              <strong>
                {item.customerAmountActual.toLocaleString(navigator.language)} {t('label.riyal')}
              </strong>
            </span>
          </div>
          {isPayeeCompany() && item.shippingFee > 0 && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'آدرس تحویل'}</span>
              <span className='flex-1'>{item.deliveryAddress}</span>
            </div>
          )}
          {item.description && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{t('ayriaPayment.description')}</span>
              <span className='flex-1'>{item.description}</span>
            </div>
          )}
          {isPayeeCompany() && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'تخفیف نقدی'}</span>
              <span className='flex-1 text-success'>
                {item.discountAmount.toLocaleString(navigator.language)} {t('label.riyal')}
              </span>
            </div>
          )}
          {isPayeeCompany() && item.voucherAmount > 0 && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{`اسپوند شما از این خرید`}</span>
              <span className='flex-1 text-success'>
                {item.voucherAmount.toLocaleString(navigator.language)} {t('label.riyal')}
              </span>
            </div>
          )}
          {isPayeeCompany() && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'امتیاز شما از این پرداخت'}</span>
              <span className='flex-1 text-success'>
                {item.score} <img src='/assets/images/coin.png' alt='score' width='40px' />
              </span>
            </div>
          )}
          {isPayeeCompany() && (
            <div className='flex-container border-bottom min-height'>
              {item.creditAmount !== 0 && <span className='flex-1'>{'میزان افزایش سقف اعتبار'}</span>}
              {item.creditAmount !== 0 && (
                <span className='flex-1 text-success'>
                  {item.creditAmount.toLocaleString(navigator.language)} {t('label.riyal')}
                </span>
              )}
              {!item.creditAmount && <span className='flex-1'>{'طرح کاربری شما فاقد اعتبار می‌باشد'}</span>}
            </div>
          )}
          {isPayeeCompany() && (
            <div className='flex-container border-bottom min-height'>
              {item.insuranceAmount !== 0 && <span className='flex-1'>{'مبلغ واریز بابت بیمه عمر'}</span>}
              {item.insuranceAmount !== 0 && (
                <span className='flex-1 text-success'>
                  {item.insuranceAmount.toLocaleString(navigator.language)} {t('label.riyal')}
                </span>
              )}
              {!item.insuranceAmount && <span className='flex-1'>{'طرح کاربری شما فاقد بیمه می‌باشد'}</span>}
            </div>
          )}
          {item.trackingNumber && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{'شماره پیگیری'}</span>
              <span className='flex-1'>{item.trackingNumber}</span>
            </div>
          )}
          {item.archived && (
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{t('walletRequest.status')}</span>
              <span className='flex-1'>
                <span className='p-1'>
                  <AyriaIcon icon={archive} title={t('walletRequest.archived')} />
                </span>
              </span>
            </div>
          )}
          <br />
          <div className='flex-container'>
            <span className='flex-1 text-right'>
              {!item.paid && !item.canceled && (
                <IonButton onClick={(e) => setShowPaymentMethodModal(true)} disabled={inprogress}>
                  {t('wallet.doPay')}
                </IonButton>
              )}
            </span>
            <span className='flex-1 text-left'>
              {!item.archived && !item.paid && !item.canceled && (
                <IonButton color='danger' onClick={(e) => archiveForReal()} disabled={inprogress}>
                  {t('wallet.doArchive')}
                </IonButton>
              )}
            </span>
          </div>
          <br />
        </div>
      )}
      <CustomModal
        showModal={showPaymentMethodModal}
        toggle={setShowPaymentMethodModal}
        title={t('ayriaPayment.paymentMethod')}
        content={
          <div>
            <WalletBalance showScore={true} />
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{t('ayriaPayment.payeeName')}</span>
              <span className='flex-1'>{item.payeeName}</span>
            </div>
            <div className='flex-container border-bottom min-height'>
              <span className='flex-1'>{t('ayriaPayment.amount')}</span>
              <span className='flex-1'>
                {(item.customerAmountActual + (tip ? +tip : 0)).toLocaleString(navigator.language)} {t('label.riyal')}
              </span>
            </div>
            {item.payeeType === 'ACCEPTOR' && item.department === 'RESTAURANT' && (
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  {'آیا تمایل دارید '}
                  <IonText color='dark'>
                    <strong>{'انعام'}</strong>
                  </IonText>
                  {' بدهید؟'}
                </IonLabel>
                <IonCheckbox slot='start' value={tipActive} onIonChange={(e) => setTipActive(e.detail.checked)} />
              </IonItem>
            )}
            {tipActive && (
              <>
                <IonItem>
                  <IonChip onClick={() => setTip(100000)} color={tip === 100000 ? 'success' : 'dark'}>
                    {(100000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                  <IonChip onClick={() => setTip(200000)} color={tip === 200000 ? 'success' : 'dark'}>
                    {(200000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                  <IonChip onClick={() => setTip(500000)} color={tip === 500000 ? 'success' : 'dark'}>
                    {(500000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                </IonItem>
                <IonItem>
                  <CurrencyIonInput onValueChange={setTip} placeholder={'مبلغ مورد نظر برای انعام (ریال)'} />
                </IonItem>
              </>
            )}
            <div className={'border-full p-2 mt-2'}>
              <div className={'text-center text-bold'}>روش پرداخت</div>
              <div className={'text-center'}>
                {item.payeeType === 'ACCEPTOR' && (
                  <button
                    disabled={inprogress}
                    className='btn btn-score btn-sm'
                    onClick={(e) => {
                      payForReal(true)
                    }}>
                    <div>امتیاز</div>
                  </button>
                )}
                <button
                  disabled={inprogress}
                  className='btn btn-wallet btn-sm'
                  onClick={(e) => {
                    payForReal(false)
                  }}>
                  <div>کیف پول</div>
                </button>
              </div>
            </div>
          </div>
        }
      />
      <CustomModal
        showModal={showWalletDepositModal}
        toggle={setShowWalletDepositModal}
        title={'افزایش موجودی'}
        content={
          <WalletDeposit
            amount={neededAmount + ''}
            originalCommand={originalCommand}
            originalCommandType={'AYRIA_PAYMENT'}
            targetAmount={item?.customerAmountActual + (tip ? +tip : 0)}
          />
        }
      />
    </div>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setUnpaidFactorCount,
    setUnpaidPersonCount,
  },
  component: WalletResponse,
})
