import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cartOutline, cartSharp, informationCircle, star } from 'ionicons/icons'
import { IonBadge, IonIcon, IonRow } from '@ionic/react'
import { setKalaList } from '../../../data/user/user.actions'
import { connect } from '../../../data/connect'
import { useHistory } from 'react-router-dom'

declare var window: any

interface OwnProps {
  newtab: string
  haskala: boolean
  onTabSelect(selectedTab: string): void
  merchant: any
}
interface StateProps {
  kalaList: any[]
}
interface DispatchProps {
  setKalaList: typeof setKalaList
}

interface MerchantProfileTabsProps extends OwnProps, StateProps, DispatchProps {}

const MerchantProfileTabs: React.FC<MerchantProfileTabsProps> = ({
  haskala,
  newtab,
  onTabSelect,
  kalaList,
  setKalaList,
  merchant,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(newtab)
  const [hasKala, setHaskala] = useState(haskala)
  const tabs = [
    { id: 1, name: 'shop' },
    { id: 2, name: 'merchantInfo' },
    { id: 3, name: 'tips' },
  ]

  const handleTabClick = (value: string) => {
    setActiveTab(value)
    onTabSelect(value)
  }

  useEffect(() => {
    setHaskala(hasKala)
    setActiveTab(newtab)
  }, []) // eslint-disable-line

  return (
    <div className={'merchant-profile-tabs'}>
      {tabs.map((tab) => (
        <button
          hidden={!hasKala && tab.id === 1}
          onClick={() => handleTabClick(tab.name)}
          key={tab.id}
          className={`p-2 ${activeTab === tab.name && 'active'}`}>
          <IonIcon
            icon={tab.id === 1 ? cartSharp : tab.id === 2 ? informationCircle : tab.id === 3 ? star : ''}
            size={'medium'}
          />
          {t(`merchantProfile.tabs.${tab.name}`)}
        </button>
      ))}
      <button
        className='basket-shop'
        hidden={true}
        onClick={() => history.push(`/shopping-cart${window.location.search}`)}>
        <IonRow className='ion-align-items-center ion-no-margin'>
          <IonBadge color='danger' className='ion-no-margin'>
            {kalaList.length}
          </IonBadge>
          <IonIcon size='large' color='light' icon={cartOutline} />
        </IonRow>
      </button>
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setKalaList,
  },
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
  }),
  component: MerchantProfileTabs,
})
