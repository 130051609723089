import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from '../../data'
import { CLUB_LOWER_CASE, CDN_BASE_URL } from '../../config'
import { Club } from '../../models'

interface OwnProps {
  useBig?: boolean
  override?: any
}
interface StateProps {
  savedClub?: Club
}
interface SmallLogoProps extends OwnProps, StateProps {}

const SmallLogo: React.FC<SmallLogoProps> = ({ savedClub, useBig = false, override = false }) => {
  const history = useHistory()

  return (
    <span onClick={() => history.push('/tabs')}>
      {override && <img src={`${CDN_BASE_URL}/${override}`} height='45px' alt='' crossOrigin='anonymous' />}
      {!override && savedClub?.smallLogo && (
        <img src={`${CDN_BASE_URL}/${savedClub.smallLogo}`} height='45px' alt='' crossOrigin='anonymous' />
      )}
      {!override && !savedClub?.smallLogo && savedClub?.logo && (
        <img src={`${CDN_BASE_URL}/${savedClub.logo}`} height='45px' alt='' crossOrigin='anonymous' />
      )}
      {!override && !savedClub?.smallLogo && !savedClub?.logo && useBig && (
        <img src={`/assets/${CLUB_LOWER_CASE}/logo.png`} width='100px' height='45px' alt='' />
      )}
      {!override && !savedClub?.smallLogo && !savedClub?.logo && !useBig && (
        <img src={`/assets/${CLUB_LOWER_CASE}/logo-small.png`} height='45px' alt='' />
      )}
    </span>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    savedClub: state.user.savedClub,
  }),
  component: SmallLogo,
})
