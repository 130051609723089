import React, { useEffect, useState } from 'react'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { sync } from 'ionicons/icons'
import { ApiService } from '../../ayria'
import { connect } from '../../data'
import { Modal } from '../../utility'
import VoucherDeposit from './VoucherDeposit'

interface OwnProps {
  code?: string
  type?: string
}

interface StateProps {
  isAuthenticated: boolean
  acceptorCode: string
  myClubCode: string
}

interface DispatchProps {}

interface VoucherBalanceProps extends OwnProps, StateProps, DispatchProps {}

const VoucherBalance: React.FC<VoucherBalanceProps> = ({
  isAuthenticated,
  code,
  type = 'user',
  acceptorCode,
  myClubCode,
}) => {
  const api = new ApiService()
  const [balance, setBalance] = useState()
  const [inprogress, setInprogrss] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const getBalance = async () => {
    setInprogrss(true)
    const res: any = await api.get(`/user/voucher/owner-balance/${!!code ? code : '0'}`)
    if (res.ok) {
      setBalance(res.data)
    }
    setInprogrss(false)
  }

  useEffect(() => {
    if (isAuthenticated) {
      getBalance()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {isAuthenticated && (
        <>
          <div className={'row ltr mb-2 ml-3'} style={{ minHeight: '30px' }}>
            {!inprogress && (
              <IonIcon icon={sync} className='pointer' title={'بروزرسانی'} onClick={getBalance} size='large' />
            )}
            {inprogress && <IonSpinner className='ion-padding' />}
            {type === 'club' && (
              <IonButton className='float-right ion-padding-horizontal' onClick={() => setShowModal(true)}>
                {'افزایش اسپوند'}
              </IonButton>
            )}
          </div>
          <div className={'row'}>
            <span className='col-4 text-center'>{type === 'club' ? 'اسپوند باشگاه' : 'اسپوند جاری'}</span>
            <div className='col-8 left ltr'>
              <span className='ltr left h3'>
                <span className='pl-1'>{(balance || 0).toLocaleString(navigator.language)}</span>
                <span style={{ fontSize: '50%', paddingTop: '12px' }} className='float-left'>
                  {' ریال '}
                </span>
              </span>
            </div>
          </div>
        </>
      )}
      <Modal
        showModal={showModal}
        toggle={setShowModal}
        onClosed={() => setShowModal(false)}
        title={'افزایش اسپوند'}
        content={<VoucherDeposit code={myClubCode} acceptorCode={acceptorCode} onSuccess={() => setShowModal(false)} />}
      />
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state: any) => ({
    isAuthenticated: state.user.isLoggedIn,
    acceptorCode: state.shop.acceptorCode,
    myClubCode: state.shop.myClubCode,
  }),
  component: VoucherBalance,
})
