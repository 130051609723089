import { combineReducers } from './combineReducers'
import { userReducer } from './user/user.reducer'
import { shopReducer } from './shop/shop.reducer'
import { THEME } from '../config'

export const initialState: AppState = {
  user: {
    hasSeenLanding: false,
    theme: THEME,
    darkMode: false,
    isLoggedIn: true,
    loading: false,
    menuEnabled: true,
    walletBalance: 0,
    scoreBalance: 0,
    loggedUser: undefined,
    pinChecked: false,
    referralCode: undefined,
    kalaList: [],
    shippingAddress: undefined,
    activeOrderCount: 0,
    failureCount: 0,
  },
  shop: {
    hasDeliveryMan: false,
    hasWaiter: false,
    hasKala: false,
    sendDeliveryStatus: false,
    specifyWaiterStatus: false,
    shiftTimeFrom: '07:00',
    shiftTimeTo: '23:59',
    onlineOrderCount: 0,
    hasCashdesk: false,
    currentCashdesk: undefined,
    acceptorCode: '',
    settings: undefined,
    lastAyriaPayment: undefined,
    deskFactorList: [],
    deskKalaList: [],
    score: 0,
    socketDisconnected: true,
    myClubCode: '',
  },
}

export const reducers = combineReducers({
  user: userReducer,
  shop: shopReducer,
})

export type AppState = ReturnType<typeof reducers>
