import React, { useState, useEffect } from 'react'
import { IonInput } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../../utility/use-debounce'
import api from '../../../api/Api'

const Nickname = ({ onValidation = (value: any) => {} }) => {
  const [nickname, setNickname] = useState('')
  const [previousNickname, setPreviousNickname] = useState(undefined)
  const debouncedNickname = useDebounce(nickname, 500)
  const [result, setResult] = useState('')
  const { t } = useTranslation()

  const updateNickname = async () => {
    if (!nickname || nickname === previousNickname) {
      return
    }
    const res: any = await api.post(`/account/profile/nickname`, { nickname })
    if (res.ok) {
      setResult('success')
      onValidation(true)
    } else {
      if (res.status === 400) {
        setResult('invalidCharacter')
      } else if (res.status === 412) {
        setResult('duplicated')
      }
      onValidation(false)
    }
  }

  const getNickname = async () => {
    const res: any = await api.get('/account/profile/nickname')
    if (res.ok && res.data) {
      setPreviousNickname(res.data)
      setNickname(res.data)
    }
  }

  useEffect(() => {
    getNickname()
  }, [])
  useEffect(() => {
    updateNickname()
    setResult('')
    onValidation(!!previousNickname)
    // eslint-disable-next-line
  }, [debouncedNickname])

  return (
    <>
      <IonInput
        value={nickname}
        onIonChange={(e) => setNickname(e.detail.value)}
        id='nickname'
        placeholder={t('label.nickname')}
      />
      {result === 'success' && <span className='text-success'>{t('label.available')}!</span>}
      {result === 'invalidCharacter' && <span className='text-danger'>{t('common_errors.invalid_character')}</span>}
      {result === 'duplicated' && <span className='text-danger'>{t('label.duplicated')}!</span>}
    </>
  )
}

export default Nickname
