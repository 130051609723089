import React, { useState, useContext, useEffect, lazy } from 'react'
import ReactGA from 'react-ga'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu } from '@ionic/react'
import { IonMenuToggle, IonToggle, IonBadge } from '@ionic/react'
import { moonOutline, logIn, logOut, person, personAdd, bagAdd, lockClosed, cart, mail } from 'ionicons/icons'
import { card, chevronDown, chevronBack, business, fileTrayFull, bicycle, settings } from 'ionicons/icons'
import { RouteComponentProps, withRouter } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CLUB_SHOW_GOLD, CLUB_HAS_CLUB_NUMBER } from '../../../config'
import ApiService from '../../../api/ApiService'
import { User, Club } from '../../../models'
import { setDarkMode } from '../../../data/user/user.actions'
import { connect } from '../../../data'
import isDesktop from '../../../utility/isDesktop'
import { ProfileContext } from '../../../components/structures/contexts/ProfileContext'
import CustomModal from '../../../components/elements/CustomModal/CustomModal'
import './Menu.scss'
import { NotificationContext } from '../contexts/NotificationContext'
const ReferralLink = lazy(() => import('../../../components/modules/referral-link/referral-link'))

interface Pages {
  title: string
  path: string
  icon: string
  routerDirection?: string
  isNew?: string
}
interface StateProps {
  darkMode: boolean
  isAuthenticated: boolean
  menuEnabled: boolean
  loggedUser?: User
  hasSeenRegisterCenter?: boolean
  hasSeenClubList?: boolean
  savedClub?: Club
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({
  darkMode,
  isAuthenticated,
  setDarkMode,
  menuEnabled,
  loggedUser,
  hasSeenRegisterCenter,
  hasSeenClubList,
  history,
  savedClub,
  match,
}) => {
  const api = new ApiService()
  const location = useLocation()
  const { t } = useTranslation()
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const [isPlanSubMenuOpen, setIsPlanSubMenuOpen] = useState(false)
  const [plan, setPlan] = useState<any>()
  const [notificationContext] = useContext<any>(NotificationContext)

  const routes = {
    loggedInPages: [
      { title: 'اطلاعات کاربری', path: '/tabs/profile', icon: person },
      { title: 'معرفی فروشگاه', path: '/tabs/mycenter', icon: bagAdd, isNew: 'registerCenter' },
      { title: 'تنظیمات', path: '/tabs/settings', icon: settings },
      { title: 'تغییر رمز عبور', path: '/tabs/change-password', icon: lockClosed },
      { title: 'خروج', path: '/logout', icon: logOut },
    ],
    loggedOutPages: [
      { title: 'ورود', path: '/login', icon: logIn },
      { title: 'ثبت نام', path: '/signup', icon: personAdd },
    ],
  }

  const renderlistItems = (list: Pages[]) => {
    return list.filter((route) => !!route.path).map((p) => renderlistItem(p))
  }

  const renderlistItem = (p: Pages) => {
    return (
      <IonMenuToggle key={p.title} auto-hide='false'>
        <IonItem
          detail={false}
          routerLink={p.path}
          routerDirection='none'
          className={location?.pathname?.startsWith(p.path) ? 'selected' : undefined}>
          <IonIcon slot='start' icon={p.icon} />
          <IonLabel>{p.title}</IonLabel>
          {p.isNew === 'registerCenter' && !hasSeenRegisterCenter && <IonBadge color='danger'>{'جدید'}</IonBadge>}
        </IonItem>
      </IonMenuToggle>
    )
  }

  const getProfile = async () => {
    const res: any = await api.get('/account/profile')
    if (res.ok) {
      setProfileContext((state: any) => ({ ...state, profile: res.data }))
    }
  }

  const getPlan = async () => {
    const res: any = await api.get('/account/plan')
    if (res.ok) {
      setPlan(res.data)
    }
  }

  const showClubs = () => {
    return !savedClub || +savedClub?.code === 1013748
  }

  useEffect(() => {
    if (!!isAuthenticated) {
      if (!profileContext.profile) {
        getProfile()
      }
      getPlan()
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    ReactGA.initialize('UA-157620079-1')
    history.listen((location: any) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
      if (process.env.NODE_ENV === 'development') {
        console.log('nav ', JSON.stringify(location), match.params)
      }
    })
  }, []) // eslint-disable-line

  return (
    <IonMenu type='overlay' disabled={!menuEnabled || !isAuthenticated} contentId='main'>
      <IonContent forceOverscroll={false} className={`bg-${plan?.plan}`}>
        <IonList lines='none'>
          <IonListHeader>
            {loggedUser && loggedUser.firstName} {loggedUser && loggedUser.lastName}
          </IonListHeader>
          {(loggedUser?.authorities?.includes('ROLE_ACCEPTOR') ||
            loggedUser?.authorities?.includes('ROLE_ACCEPTOR_MANAGER')) && (
            <IonMenuToggle auto-hide='false'>
              <IonItem href={isDesktop() ? '/desk' : '/panel'} className='pointer'>
                <IonIcon slot='start' icon={business} />
                <IonLabel>{t('profileDropdown.myAcceptors')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
          {renderlistItem(routes.loggedInPages[1])}

          <IonMenuToggle auto-hide='false'>
            <IonItem
              onClick={() => {
                history.push(`/tabs/notifications`)
                // setIsPlanSubMenuOpen(false)
              }}
              className='pointer'>
              <IonIcon slot='start' icon={mail} />
              <IonLabel>پیام ها</IonLabel>
              {notificationContext.badge > 0 && <IonBadge color='danger'>{notificationContext.badge}</IonBadge>}
            </IonItem>
          </IonMenuToggle>

          <IonItem onClick={() => setShowInviteModal(true)} className='pointer'>
            <IonIcon slot='start' icon={personAdd} />
            <IonLabel>دعوت از دوستان</IonLabel>
          </IonItem>
          <IonItem
            onClick={() => setIsPlanSubMenuOpen(!isPlanSubMenuOpen)}
            className='pointer'
            hidden={plan && plan.plan === 'GALAXY'}>
            <IonIcon slot='start' icon={cart} />
            <IonLabel>خرید طرح</IonLabel>
            <IonIcon slot='end' icon={isPlanSubMenuOpen ? chevronDown : chevronBack} />
          </IonItem>
          {isPlanSubMenuOpen &&
            isAuthenticated &&
            plan &&
            plan.avaiablePlans.length &&
            plan.avaiablePlans.map(
              (p: any) =>
                ((p.type === 'GOLD' && CLUB_SHOW_GOLD) || p.type !== 'GOLD') && (
                  <IonItem
                    onClick={() => {
                      setIsPlanSubMenuOpen(false)
                      history.push(`/purchase-plan?plan=${p.type}`)
                    }}
                    className='pr-6 pointer'
                    key={p.type}>
                    <IonLabel>{t('plan.' + p.type)}</IonLabel>
                  </IonItem>
                )
            )}
          {CLUB_HAS_CLUB_NUMBER && (
            <IonItem disabled={true}>
              <IonIcon slot='start' icon={card} />
              <IonLabel>دریافت کارت عضویت</IonLabel>
            </IonItem>
          )}
          {showClubs() && (
            <IonMenuToggle auto-hide='false'>
              <IonItem className='pointer' onClick={() => history.push('/tabs/clubs')}>
                <IonIcon src={'/assets/images/svg/club.svg'} slot='start' />
                <IonLabel>{'باشگاه‌ها'}</IonLabel>
                {!hasSeenClubList && <IonBadge color='danger'>{'جدید'}</IonBadge>}
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>
        <IonList lines='none'>
          {loggedUser?.authorities?.includes('ROLE_ACCEPTOR_SECRETARY') && (
            <IonMenuToggle auto-hide='false'>
              <IonItem onClick={() => history.push('/secretary-sellect')} className='pointer'>
                <IonIcon slot='start' icon={fileTrayFull} />
                <IonLabel>{t('profileDropdown.cashDeskAcceptors')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
          {loggedUser?.authorities?.includes('ROLE_DELIVERY') && (
            <IonMenuToggle auto-hide='false'>
              <IonItem onClick={() => history.push('/delivery-select')} className='pointer'>
                <IonIcon slot='start' icon={bicycle} />
                <IonLabel>{t('profileDropdown.deliveryAcceptors')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>
        <IonList lines='none'>
          {renderlistItem(routes.loggedInPages[0])}
          {renderlistItem(routes.loggedInPages[2])}
          {renderlistItem(routes.loggedInPages[3])}
          <IonItem>
            <IonIcon slot='start' icon={moonOutline} />
            <IonLabel>حالت تاریک</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
          {renderlistItem(routes.loggedInPages[4])}
          {!isAuthenticated && renderlistItems(routes.loggedOutPages)}
          <IonItem>
            <IonLabel>{`نسخه ${process.env.REACT_APP_VERSION}`}</IonLabel>
          </IonItem>
        </IonList>
        <CustomModal
          showModal={showInviteModal}
          toggle={setShowInviteModal}
          title='دعوت از دوستان'
          content={
            <div className={'referralLinkHolder'}>
              {profileContext.profile && (
                <div title={`${t('profile.referralCode')} : ${profileContext.profile.referralCode}`}>
                  <div className={'mb-2 text-justify'}>
                    شما با استفاده از این کد میتونید خیلی راحت هر کسی رو که بخواهید به جمع آیریا دعوت کنید تا هم خودتون
                    و هم کسی که دعوت میکنید کلی امتیاز بگیره ،پس همین الان بفرست برای همه
                  </div>
                  <ReferralLink profile={profileContext.profile} />
                </div>
              )}
            </div>
          }
        />
      </IonContent>
    </IonMenu>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedIn,
    menuEnabled: state.user.menuEnabled,
    loggedUser: state.user.loggedUser,
    hasSeenRegisterCenter: state.user.hasSeenRegisterCenter,
    hasSeenClubList: state.user.hasSeenClubList,
    savedClub: state.user.savedClub,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: withRouter(Menu),
})
