import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import Ayriachart from '../../units/Ayriachart'

const SellChart = () => {
  const { t } = useTranslation()
  const [acceptorContext] = useContext(AcceptorContext)
  const [options, setOptions] = useState({
    chart: { type: 'column' },
    yAxis: {
      title: { text: 'فروش به ریال' },
    },
  })

  const fetchChartData = async () => {
    const res = await api.get(`/acceptor/purchases/${acceptorContext.current.code}/chart`)
    const dataChart = []
    if (res.ok) {
      for (var i = 0; i < res.data.data.length; i++) {
        dataChart.push({ name: t(`weekDay.${res.data.labels[i]}`), y: res.data.data[i] })
      }
      setOptions({
        ...options,
        series: [
          {
            data: dataChart,
          },
        ],
      })
    }
  }

  useEffect(() => {
    fetchChartData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div>
        <Ayriachart options={options} />
      </div>
    </>
  )
}

export default SellChart
