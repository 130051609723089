import React, { useState, useEffect } from 'react'
import { IonRow, IonCol, IonItem, IonText, IonButton, IonIcon, IonLabel } from '@ionic/react'
import { calendarOutline, starOutline, pulseOutline, cartOutline, add } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { connect, setScore, ApiService, EventEmitter, isDesktop } from '../../ayria'

interface OwnProps {
  isPanel?: boolean
}
interface StateProps {
  acceptorCode: string
  score: number
}
interface DispatchProps {
  setScore: typeof setScore
}
interface StatBarProps extends OwnProps, StateProps, DispatchProps {}

const StatBar: React.FC<StatBarProps> = ({ isPanel = true, acceptorCode, score, setScore }) => {
  const api = new ApiService()
  const history = useHistory()
  const [inprogress, setInprogress] = useState(false)
  const [todayCount, setTodayCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const handleNavigate = async () => {
    if (isPanel) {
      window.open('/desk', '_self')
    } else {
      history.push('/panel')
    }
  }
  const getScore = async () => {
    const res: any = await api.get(`/customer/scores/total/${acceptorCode}`)
    if (res.ok) {
      setScore(res.data)
    }
  }
  const getStats = async () => {
    if (!acceptorCode) {
      return
    }
    setInprogress(true)
    const re2: any = await api.get(`/acceptor/stat/today/${acceptorCode}/factor-count`)
    if (re2.ok) {
      setTodayCount(re2.data)
    }
    const re3: any = await api.get(`/acceptor/stat/all/${acceptorCode}/factor-count`)
    if (re3.ok) {
      setTotalCount(re3.data)
    }
    setInprogress(false)
  }

  useEffect(() => {
    getScore()
  }, []) // eslint-disable-line

  useEffect(() => {
    getStats()
    EventEmitter?.subscribe('ONLINE_ORDER_COUNT_NEED_UPDATE', () => {
      setTimeout(() => {
        getStats()
      }, 3000)
    })
    EventEmitter?.subscribe('NEW_PAYEE_REQUEST', () => {
      setTimeout(() => {
        getStats()
      }, 3000)
    })
  }, []) // eslint-disable-line

  return (
    <>
      {isDesktop() ? (
        <IonRow className='mt-1'>
          <IonCol size='12' className='customCard white'>
            <IonRow>
              <IonCol size='3'>
                <div
                  className='cursor-pointer d-flex flex-row justify-content-between px-3 py-3'
                  onClick={() => history.push('/score-events-acceptor')}>
                  <div className='d-flex flex-row'>
                    <IonIcon className='circleIcon green' icon={starOutline} />
                    <div className='d-flex flex-column px-2'>
                      <span>امتیاز</span>
                      <span>{score}</span>
                    </div>
                  </div>

                  <img src='/assets/images/panel/score-chart.svg' alt='' />
                </div>
              </IonCol>
              <IonCol size='3'>
                <div className='d-flex flex-row justify-content-between px-3 py-3 withBorder rightBorder'>
                  <div className='d-flex flex-row'>
                    <IonIcon className='circleIcon red' icon={calendarOutline} />
                    <div className='d-flex flex-column px-2'>
                      <span>سفارشات امروز</span>
                      <span>{todayCount || '0'}</span>
                    </div>
                  </div>

                  <img src='/assets/images/panel/today-orders-chart.svg' alt='' />
                </div>
              </IonCol>
              <IonCol size='3'>
                <div className='d-flex flex-row justify-content-between px-3 py-3 withBorder rightBorder'>
                  <div className='d-flex flex-row'>
                    <IonIcon className='circleIcon blue' icon={cartOutline} />
                    <div className='d-flex flex-column px-2'>
                      <span>کل سفارشات</span>
                      <span>{totalCount || '0'}</span>
                    </div>
                  </div>

                  <img src='/assets/images/panel/total-orders-chart.svg' alt='' />
                </div>
              </IonCol>
              <IonCol size='3'>
                <div className='d-flex flex-row justify-content-between px-3 py-3 withBorder rightBorder'>
                  <div className='d-flex flex-row'>
                    <IonIcon className='circleIcon orange' icon={pulseOutline} />
                    <div className='d-flex flex-column px-2'>
                      <span>وضعیت نسبت به ماه پیش</span>
                      <span>{'0٪'}</span>
                    </div>
                  </div>

                  <img
                    src='/assets/images/panel/stats-chart.svg'
                    className='position-absolute'
                    style={{ left: 20 }}
                    alt=''
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      ) : (
        <>
          <IonRow>
            <IonCol className='ion-no-padding'>
              <div className='ads-holder-desktop'>
                <img alt='' src={'/assets/platform/home-desktop.png'} className='responsive-image' />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='3'>
              <IonButton color={isPanel ? 'success' : 'primary'} size='large' expand='block' onClick={handleNavigate}>
                {isPanel && <IonIcon slot='start' icon={add} style={{ fontSize: '24px' }} />}
                {isPanel && <span>{'صدور صورتحساب'}</span>}
                {!isPanel && <span>{'پنل مدیریتی'}</span>}
              </IonButton>
            </IonCol>
            <IonCol size='9'>
              <IonRow>
                <IonCol>
                  <IonItem className='pointer' onClick={() => history.push('/score-events-acceptor')}>
                    <IonLabel>{'امتیاز'}</IonLabel>
                    <IonText>{score}</IonText>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel>{'تعداد سفارشات امروز'}</IonLabel>
                    {!inprogress && <IonText color='danger'>{todayCount || '0'}</IonText>}
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel>{'تعداد کل سفارشات'}</IonLabel>
                    {!inprogress && <IonText>{totalCount || '0'}</IonText>}
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel>{'وضعیت نسبت به ماه پیش'}</IonLabel>
                    <IonText>{'0٪'}</IonText>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    acceptorCode: state.shop.acceptorCode,
    score: state.shop.score,
  }),
  mapDispatchToProps: {
    setScore,
  },
  component: StatBar,
})
