import mciImage from '../assets/images/bill/mci.png';
import mtnImage from '../assets/images/bill/mtn.png';
import rightelImage from '../assets/images/bill/rightel.png';

const map = {
  "0910" : "MCI",
  "0911" : "MCI",
  "0912" : "MCI",
  "0913" : "MCI",
  "0914" : "MCI",
  "0915" : "MCI",
  "0916" : "MCI",
  "0917" : "MCI",
  "0918" : "MCI",
  "0919" : "MCI",
  "0990" : "MCI",
  "0991" : "MCI",
  "0992" : "MCI",
  "0930" : "MTN",
  "0933" : "MTN",
  "0935" : "MTN",
  "0936" : "MTN",
  "0937" : "MTN",
  "0938" : "MTN",
  "0939" : "MTN",
  "0901" : "MTN",
  "0902" : "MTN",
  "0903" : "MTN",
  "0904" : "MTN",
  "0905" : "MTN",
  "0941" : "MTN",
  "0920" : "RIGHTEL",
  "0921" : "RIGHTEL",
  "0922" : "RIGHTEL"
};

export default function mobileImageResolver(value) {
  if (value.length >= 4) {
    const prefix = map[value.substr(0, 4)]
    if (prefix === 'MCI')
      return mciImage
    else if (prefix === 'MTN')
      return mtnImage
    else if (prefix === 'RIGHTEL')
      return rightelImage
    else
      return undefined
  }
  return undefined
}
