import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { IonContent, IonFooter, IonCard, IonCardContent } from '@ionic/react'
import { IonSpinner, IonPage, IonRow, IonCol, IonButton, IonLabel } from '@ionic/react'
import { useIonViewWillEnter, IonItem, IonTextarea, IonDatetime, IonText } from '@ionic/react'
import * as moment from 'moment'
import WalletResponse from '../wallet/wallet-response/wallet-response'
import { setKalaList, useToast, connect, Header, ApiService, User } from '../../../ayria'
import { Modal, setShippingAddress, MobileIonInput, setActiveOrderCount } from '../../../ayria'

declare var window: any

interface OwnProps {}
interface StateProps {
  shippingAddress: any
  kalaList: any[]
  loggedUser?: User
  isLoggedIn?: boolean
  activeOrderCount?: number
  receiveType?: string
  pickupDelay?: number
  tableIdentifier?: string
}
interface DispatchProps {
  setKalaList: typeof setKalaList
  setShippingAddress: typeof setShippingAddress
  setActiveOrderCount: typeof setActiveOrderCount
}

interface MerchantProfileShoppingFinalStateProps extends OwnProps, StateProps, DispatchProps {}

const MerchantProfileShoppingFinalStatePage: React.FC<MerchantProfileShoppingFinalStateProps> = ({
  shippingAddress,
  kalaList,
  loggedUser,
  setShippingAddress,
  setKalaList,
  isLoggedIn,
  activeOrderCount,
  setActiveOrderCount,
  receiveType,
  pickupDelay,
  tableIdentifier,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  const { merchantCode } = useParams<any>()
  const [acceptor, setAcceptor] = useState<any>()
  const [kalaListFiltered, setKalaListFiltered] = useState(kalaList.filter((k) => k.acceptorCode === +merchantCode))
  const [itemsPrice, setItemsPrice] = useState(kalaListFiltered.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  const [taxPrice, setTaxPrice] = useState(0)
  const [shippingPrice, setShippingPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(itemsPrice + taxPrice + shippingPrice)
  const [inprogress, setInprogress] = useState(false)
  const [description, setDescription] = useState<string>()
  const [ayriaPayment, setAyriaPayment] = useState<any>()
  const [trackingNumber, setTrackingNumber] = useState<string>()
  const [payerMobile, setPayerMobile] = useState<string>('')
  const [showWalletResponseModal, setShowWalletResponseModal] = useState(false)

  const registerOnlineOrder = async () => {
    if (ayriaPayment) {
      setShowWalletResponseModal(true)
      return
    }
    setInprogress(true)
    const cmd = {
      amount: itemsPrice,
      payerMobile: isLoggedIn ? loggedUser?.mobile : payerMobile,
      description,
      acceptorCode: merchantCode,
      paymentMethod: 'WALLET',
      kalas: kalas(),
      onlineOrder: true,
      receiveType,
      pickupDelay,
      tableIdentifier,
      shippingAddressId: shippingAddress?.id,
      shippingLat: !isLoggedIn ? shippingAddress?.latitude : null,
      shippingLng: !isLoggedIn ? shippingAddress?.longitude : null,
      deliveryAddress:
        !isLoggedIn && !!shippingAddress
          ? `${shippingAddress?.address}، پلاک ${shippingAddress?.number}، واحد ${shippingAddress?.part}`
          : null,
    }
    let res: any
    if (isLoggedIn) {
      res = await api.post(`/user/ayria-payments/online-order`, cmd)
    } else {
      res = await api.post(`/public/ayria-payments/online-order`, cmd)
    }
    if (res.ok) {
      setAyriaPayment(res.data)
      setTrackingNumber(res.data.trackingNumber)
      await setKalaList(kalaList.filter((k) => k.acceptorCode !== +merchantCode))
      setDescription('')
      setKalaListFiltered([])
      if (isLoggedIn) {
        setShowWalletResponseModal(true)
      } else {
        window.location = res.data.paymentUrl
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const kalas = () => {
    return kalaListFiltered.reduce((acc, item, index) => {
      acc.push({ count: item.qty, kala: { id: item.id } })
      return acc
    }, [])
  }
  const getShippingFee = async () => {
    let res: any
    if (isLoggedIn) {
      res = await api.shippingAddressesIn(shippingAddress.id, merchantCode)
    } else {
      res = await api.publicAyriaPaymentsAddressIn({
        acceptorCode: merchantCode,
        latitude: shippingAddress.latitude,
        longitude: shippingAddress.longitude,
      })
    }
    if (res.ok) {
      if (res.data.length > 0) {
        setShippingPrice(res.data[0].price)
      }
    } else {
      toast.error(res)
    }
  }
  const formDisabled = () => {
    if (isLoggedIn) {
      return inprogress
    } else {
      return inprogress || !/^(09[0-9]{9})$/.test(payerMobile)
    }
  }

  const getAcceptor = async () => {
    const res: any = await api.publicAcceptorsGetByReferralCode(merchantCode)
    if (res.ok) {
      setAcceptor(res.data)
    }
  }

  useIonViewWillEnter(() => {
    setDescription('')
    setItemsPrice(kalaListFiltered.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  })

  useEffect(() => {
    setTotalPrice(+itemsPrice + +taxPrice + +shippingPrice)
  }, [itemsPrice, shippingPrice, taxPrice]) // eslint-disable-line

  useEffect(() => {
    if (acceptor?.tax) {
      const actualAmountTaxNeeded = ((itemsPrice + (!!shippingPrice ? shippingPrice : 0)) * acceptor.taxDivider) / 100
      setTaxPrice(((actualAmountTaxNeeded * 9) / 100).toFixed(0))
    }
  }, [itemsPrice, shippingPrice, acceptor])

  useEffect(() => {
    if (!!shippingAddress) {
      getShippingFee()
    }
  }, [shippingAddress]) // eslint-disable-line

  useEffect(() => {
    getAcceptor()
  }, []) // eslint-disable-line

  useEffect(() => {
    setItemsPrice(kalaListFiltered.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  }, [kalaListFiltered])

  useEffect(() => {
    setKalaListFiltered(kalaList.filter((k) => k.acceptorCode === +merchantCode))
  }, [kalaList, merchantCode])

  return (
    <IonPage id='shipping-address-page'>
      <IonContent fullscreen={true} className='md-5'>
        <Header title='' />
        <div className='ion-padding'>
          <IonCard className='ion-no-padding ion-no-margin'>
            <IonCardContent>
              {kalaListFiltered.length === 0 && <div>سبد خالی می باشد</div>}
              {kalaListFiltered.map((item: any) => (
                <div key={item.id}>
                  <IonRow>
                    <IonCol>
                      {item.name} ({item.qty})
                    </IonCol>
                    <IonCol className='ion-text-start'>
                      {(item.price * item.qty).toLocaleString(navigator.language)} {'ریال'}
                    </IonCol>
                  </IonRow>
                </div>
              ))}
              {!!shippingPrice && kalaListFiltered.length !== 0 && (
                <>
                  <IonRow>
                    <IonCol>{'هزینه پیک'}</IonCol>
                    <IonCol className='ion-text-start'>
                      {shippingPrice.toLocaleString(navigator.language)} {'ریال'}
                    </IonCol>
                  </IonRow>
                </>
              )}
              {!!taxPrice && kalaListFiltered.length !== 0 && (
                <>
                  <IonRow>
                    <IonCol>{'مالیات بر ارزش افزوده'}</IonCol>
                    <IonCol className='ion-text-start'>
                      {(+taxPrice).toLocaleString(navigator.language)} {'ریال'}
                    </IonCol>
                  </IonRow>
                </>
              )}
              {kalaList.length !== 0 && (
                <>
                  <hr></hr>
                  <IonRow>
                    <IonCol size='6'>
                      <strong>مبلغ قابل پرداخت</strong>
                    </IonCol>
                    <IonCol size='6' className='ion-text-start'>
                      <strong>
                        {totalPrice.toLocaleString(navigator.language)} {'ریال'}
                      </strong>
                    </IonCol>
                  </IonRow>
                  <hr />
                </>
              )}
            </IonCardContent>
          </IonCard>
          <div>
            {kalaListFiltered.length !== 0 && (
              <>
                {receiveType === 'DELIVERY' && (
                  <IonItem>
                    <IonLabel position='stacked'>{'آدرس تحویل سفارش'}</IonLabel>
                    <IonTextarea
                      value={`${shippingAddress?.address}، پلاک ${shippingAddress?.number}، واحد ${shippingAddress?.part}`}
                      className='form-control'
                      disabled={true}></IonTextarea>
                  </IonItem>
                )}
                {receiveType === 'PICKUP' && (
                  <IonItem>
                    <IonLabel position='stacked'>{'زمان تحویل گرفتن حضوری'}</IonLabel>
                    <IonDatetime
                      value={moment().add(pickupDelay, 'minutes').toISOString()}
                      presentation='time'
                      disabled={true}
                    />
                  </IonItem>
                )}
                {receiveType === 'TABLES' && (
                  <IonItem>
                    <IonText position='stacked'>{'میز'} </IonText>
                    <IonButton fill='outline' color='dark'>
                      {tableIdentifier}
                    </IonButton>
                  </IonItem>
                )}
                <IonItem>
                  <IonLabel position='stacked'>{'توضیحات دیگر'}</IonLabel>
                  <IonTextarea
                    value={description}
                    className='form-control'
                    onIonChange={(e) => setDescription(e.detail.value)}
                  />
                </IonItem>
                {!isLoggedIn && (
                  <IonItem>
                    <IonLabel position='stacked'>{'شماره همراه پرداخت کننده'}</IonLabel>
                    <MobileIonInput value={payerMobile} onValueChange={setPayerMobile} />
                  </IonItem>
                )}
              </>
            )}
          </div>
        </div>
      </IonContent>
      {kalaListFiltered.length !== 0 && (
        <IonFooter>
          <IonRow>
            <IonCol size='12'>
              <IonButton expand='full' disabled={formDisabled()} onClick={registerOnlineOrder}>
                {inprogress && <IonSpinner slot='start' />}
                {`مرحله بعد`}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      )}
      <Modal
        showModal={showWalletResponseModal}
        toggle={setShowWalletResponseModal}
        onClosed={() => history.replace(`/wallet-response/${trackingNumber}`)}
        title={t('wallet.doPay')}
        content={
          <WalletResponse
            trackingNumberProp={trackingNumber}
            onSuccess={async () => {
              setShowWalletResponseModal(false)
              await setActiveOrderCount(+activeOrderCount + 1)
              setTimeout(() => {
                toast.success('سفارش با موفقیت پرداخت شد')
                history.push('/tabs/orders')
                setDescription('')
                setAyriaPayment(null)
              }, 300)
            }}
          />
        }
      />
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setKalaList,
    setShippingAddress,
    setActiveOrderCount,
  },
  mapStateToProps: (state) => ({
    shippingAddress: state.user.shippingAddress,
    kalaList: state.user.kalaList,
    loggedUser: state.user.loggedUser,
    isLoggedIn: state.user.isLoggedIn,
    activeOrderCount: state.user.activeOrderCount,
    receiveType: state.user.receiveType,
    pickupDelay: state.user.pickupDelay,
    tableIdentifier: state.user.tableIdentifier,
  }),
  component: MerchantProfileShoppingFinalStatePage,
})
