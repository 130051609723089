import { Storage } from '@capacitor/storage'
import { User, Club, Cashdesk } from '../models'
import { THEME } from '../config'

const IS_LOGGED_IN = 'isLoggedIn'
const HAS_SEEN_LANDING = 'hasSeenLanding'
const HAS_SEEN_REGISTER_CENTER = 'hasSeenRegisterCenter'
const HAS_SEEN_REGISTER_CLUB = 'hasSeenRegisterClub'
const HAS_SEEN_CLUB_LIST = 'hasSeenClubList'
const USERNAME = 'username'
const LOGGED_IN_USER = 'LOGGED_IN_USER'
const SAVED_CLUB = 'savedClub'
const THEME_KEY = 'theme'
const DARK_MODE = 'darkMode'
const REFERRAL_CODE = 'referralCode'
const CLUB_CODE = 'clubCode'
const KALA_LIST = 'kalaList'
const SHIPPING_ADDRESS = 'shippingAddress'
const RECEIVE_TYPE = 'receiveType'
const PICKUP_DELAY = 'pickupDelay'
const GIFT_TOKEN = 'giftToken'
const LAST_SEARCH = 'lastSearch'
const FAILURE_COUNT = 'failureCount'

const SEND_DELIVERY_STATUS = 'sendDeliveryStatus'
const SPECIFY_WAITER_STATUS = 'specifyWaiterStatus'
const SHIFT_TIME_FROM = 'shiftTimeFrom'
const SHIFT_TIME_TO = 'shiftTimeTo'
const CURRENT_CASHDESK = 'currentCashdesk'
const DESK_KALA_LIST = 'deskKalaList'
const DESK_FACTOR_LIST = 'deskFactorList'
const ACCEPTOR_CODE = 'acceptorCode'
const LAST_AYRIA_PAYMENT = 'lastAyriaPayment'
const MY_CLUB_CODE = 'myClubCode'

export const getUserData = async () => {
  await Storage.migrate()
  const response = await Promise.all([
    Storage.get({ key: IS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_LANDING }),
    Storage.get({ key: USERNAME }),
    Storage.get({ key: LOGGED_IN_USER }),
    Storage.get({ key: THEME_KEY }),
    Storage.get({ key: HAS_SEEN_REGISTER_CENTER }),
    Storage.get({ key: HAS_SEEN_REGISTER_CLUB }),
    Storage.get({ key: DARK_MODE }),
    Storage.get({ key: REFERRAL_CODE }),
    Storage.get({ key: CLUB_CODE }),
    Storage.get({ key: SAVED_CLUB }),
    Storage.get({ key: KALA_LIST }),
    Storage.get({ key: SHIPPING_ADDRESS }),
    Storage.get({ key: HAS_SEEN_CLUB_LIST }),
    Storage.get({ key: RECEIVE_TYPE }),
    Storage.get({ key: PICKUP_DELAY }),
    Storage.get({ key: GIFT_TOKEN }),
    Storage.get({ key: LAST_SEARCH }),
    Storage.get({ key: FAILURE_COUNT }),
  ])
  const isLoggedIn = (await response[0].value) === 'true'
  const hasSeenLanding = (await response[1].value) === 'true'
  const username = (await response[2].value) || undefined
  const loggedUser = (await response[3].value) || undefined
  const theme = (await response[4].value) || THEME
  const hasSeenRegisterCenter = (await response[5].value) === 'true'
  const hasSeenRegisterClub = (await response[6].value) === 'true'
  const darkMode = (await response[7].value) === 'true'
  const referralCode = (await response[8].value) || undefined
  const clubCode = (await response[9].value) || undefined
  const savedClub = (await response[10].value) || undefined
  const kalaList = (await response[11].value) || undefined
  const shippingAddress = (await response[12].value) || undefined
  const hasSeenClubList = (await response[13].value) === 'true'
  const receiveType = (await response[14].value) || undefined
  const pickupDelay = (await response[15].value) || undefined
  const giftToken = (await response[16].value) || undefined
  const lastSearch = (await response[17].value) || undefined
  const failureCount = (await response[18].value) || 0
  return {
    isLoggedIn,
    hasSeenLanding,
    username,
    loggedUser: loggedUser ? JSON.parse(loggedUser) : undefined,
    theme,
    hasSeenRegisterCenter,
    hasSeenRegisterClub,
    darkMode,
    referralCode,
    clubCode,
    savedClub: savedClub ? JSON.parse(savedClub) : undefined,
    kalaList: kalaList ? JSON.parse(kalaList) : [],
    shippingAddress: shippingAddress ? JSON.parse(shippingAddress) : undefined,
    hasSeenClubList,
    receiveType,
    pickupDelay,
    giftToken,
    lastSearch,
    failureCount: +failureCount,
  }
}

export const getShopData = async () => {
  await Storage.migrate()
  const response = await Promise.all([
    Storage.get({ key: SEND_DELIVERY_STATUS }),
    Storage.get({ key: SPECIFY_WAITER_STATUS }),
    Storage.get({ key: SHIFT_TIME_FROM }),
    Storage.get({ key: SHIFT_TIME_TO }),
    Storage.get({ key: CURRENT_CASHDESK }),
    Storage.get({ key: DESK_KALA_LIST }),
    Storage.get({ key: ACCEPTOR_CODE }),
    Storage.get({ key: LAST_AYRIA_PAYMENT }),
    Storage.get({ key: DESK_FACTOR_LIST }),
    Storage.get({ key: MY_CLUB_CODE }),
  ])
  const sendDeliveryStatus = (await response[0].value) === 'true'
  const specifyWaiterStatus = (await response[1].value) === 'true'
  const shiftTimeFrom = (await response[2].value) || '07:00'
  const shiftTimeTo = (await response[3].value) || '23:59'
  const currentCashdesk = (await response[4].value) || undefined
  const deskKalaList = (await response[5].value) || undefined
  const acceptorCode = (await response[6].value) || undefined
  const lastAyriaPayment = (await response[7].value) || undefined
  const deskFactorList = (await response[8].value) || undefined
  const myClubCode = (await response[9].value) || undefined
  return {
    sendDeliveryStatus,
    specifyWaiterStatus,
    shiftTimeFrom,
    shiftTimeTo,
    currentCashdesk: currentCashdesk ? JSON.parse(currentCashdesk) : undefined,
    deskKalaList: deskKalaList ? JSON.parse(deskKalaList) : [],
    acceptorCode,
    lastAyriaPayment: lastAyriaPayment ? JSON.parse(lastAyriaPayment) : undefined,
    deskFactorList: deskFactorList ? JSON.parse(deskFactorList) : [],
    myClubCode,
  }
}

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: IS_LOGGED_IN, value: JSON.stringify(isLoggedIn) })
}

export const setHasSeenLandingData = async (hasSeenLanding: boolean) => {
  await Storage.set({ key: HAS_SEEN_LANDING, value: JSON.stringify(hasSeenLanding) })
}

export const setThemeData = async (theme: string) => {
  await Storage.set({ key: THEME_KEY, value: theme })
}

export const setHasSeenRegisterCenterData = async (hasSeenRegisterCenter: boolean) => {
  await Storage.set({ key: HAS_SEEN_REGISTER_CENTER, value: JSON.stringify(hasSeenRegisterCenter) })
}

export const setHasSeenRegisterClubData = async (hasSeenRegisterClub: boolean) => {
  await Storage.set({ key: HAS_SEEN_REGISTER_CLUB, value: JSON.stringify(hasSeenRegisterClub) })
}

export const setHasSeenClubListData = async (hasSeenClubList: boolean) => {
  await Storage.set({ key: HAS_SEEN_CLUB_LIST, value: JSON.stringify(hasSeenClubList) })
}

export const setDarkModeData = async (darkMode: boolean) => {
  await Storage.set({ key: DARK_MODE, value: JSON.stringify(darkMode) })
}

export const setFailureCountData = async (failrueCount: number) => {
  await Storage.set({ key: FAILURE_COUNT, value: failrueCount + '' })
}

export const setListData = async (kalaList: any[], listKey: string) => {
  if (!kalaList) {
    await Storage.remove({ key: listKey })
  } else {
    await Storage.set({ key: listKey, value: JSON.stringify(kalaList) })
  }
  return kalaList
}

export const setKalaListData = async (kalaList: any[]) => {
  return setListData(kalaList, KALA_LIST)
}

export const setDeskKalaListData = async (kalaList: any[]) => {
  return setListData(kalaList, DESK_KALA_LIST)
}

export const setDeskFactorListData = async (factorList: any[]) => {
  return setListData(factorList, DESK_FACTOR_LIST)
}

export const addListData = async (kala: any, listKey: string) => {
  const kalaListRaw = await Storage.get({ key: listKey })
  const kalaList = kalaListRaw?.value ? JSON.parse(kalaListRaw.value) : []
  if (!kalaList.find((k: any) => k.id === kala.id)) {
    kala.qty = kala.qty ? kala.qty : 1
    kalaList.push(kala)
    await setListData(kalaList, listKey)
  } else {
    const exist = kalaList.find((k: any) => k.id === kala.id)
    if (exist.qty > 0) {
      exist.qty = exist.qty + 1
      kalaList.map((x: any) => (x.id === kala.id ? { ...exist } : x))
      await setListData(kalaList, listKey)
    }
  }
  const kalaListRes = await Storage.get({ key: listKey })
  return kalaListRes?.value ? JSON.parse(kalaListRes.value) : []
}

export const addKalaData = async (kala: any) => {
  return addListData(kala, KALA_LIST)
}

export const addDeskKalaListData = async (kala: any) => {
  return addListData(kala, DESK_KALA_LIST)
}

export const addDeskFactorListData = async (factor: any) => {
  return addListData(factor, DESK_FACTOR_LIST)
}

export const removeListData = async (kala: any, listKey: string) => {
  const kalaListRaw = await Storage.get({ key: listKey })
  const kalaList = kalaListRaw?.value ? JSON.parse(kalaListRaw.value) : []
  const exist = kalaList.find((x: any) => x.id === kala.id)
  if (exist && exist.qty === 1) {
    await setListData(
      kalaList.filter((x: any) => x.id !== kala.id),
      listKey
    )
  } else if (exist && exist.qty > 1) {
    exist.qty = exist.qty - 1
    await setListData(
      kalaList.map((x: any) => (x.id === kala.id ? { ...exist } : x)),
      listKey
    )
  }
  const kalaListRes = await Storage.get({ key: listKey })
  return kalaListRes?.value ? JSON.parse(kalaListRes.value) : []
}

export const removeKalaData = async (kala: any) => {
  return removeListData(kala, KALA_LIST)
}

export const removeDeskKalaListData = async (kala: any) => {
  return removeListData(kala, DESK_KALA_LIST)
}

export const removeDeskFactorListData = async (factor: any) => {
  return removeListData(factor, DESK_FACTOR_LIST)
}

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME })
  } else {
    await Storage.set({ key: USERNAME, value: username })
  }
}

export const setLoggedUserData = async (user?: User) => {
  if (!user) {
    await Storage.remove({ key: LOGGED_IN_USER })
  } else {
    await Storage.set({ key: LOGGED_IN_USER, value: JSON.stringify(user) })
  }
}

export const setSavedClubData = async (club?: Club) => {
  if (!club) {
    await Storage.remove({ key: SAVED_CLUB })
  } else {
    await Storage.set({ key: SAVED_CLUB, value: JSON.stringify(club) })
  }
}

export const setReferralCodeData = async (referralCode: string) => {
  await Storage.set({ key: REFERRAL_CODE, value: referralCode })
}

export const setClubCodeData = async (clubCode: string) => {
  await Storage.set({ key: CLUB_CODE, value: clubCode })
}

export const setGiftTokenData = async (giftToken?: string) => {
  if (!!giftToken) {
    await Storage.set({ key: GIFT_TOKEN, value: giftToken })
  } else {
    await Storage.remove({ key: GIFT_TOKEN })
  }
}

export const setLastSearchData = async (lastSearch?: string) => {
  if (!!lastSearch) {
    await Storage.set({ key: LAST_SEARCH, value: lastSearch })
  } else {
    await Storage.remove({ key: LAST_SEARCH })
  }
}

export const setShippingAddressData = async (address?: any) => {
  if (!address) {
    await Storage.remove({ key: SHIPPING_ADDRESS })
  } else {
    await Storage.set({ key: SHIPPING_ADDRESS, value: JSON.stringify(address) })
  }
}

export const setReceiveTypeData = async (receiveType?: string) => {
  if (!receiveType) {
    await Storage.remove({ key: RECEIVE_TYPE })
  } else {
    await Storage.set({ key: RECEIVE_TYPE, value: receiveType })
  }
}

export const setPickupDelayData = async (pickupDelay?: any) => {
  await Storage.set({ key: PICKUP_DELAY, value: pickupDelay })
}

export const setSendDeliveryStatusData = async (sendDeliveryStatus: boolean) => {
  await Storage.set({ key: SEND_DELIVERY_STATUS, value: JSON.stringify(sendDeliveryStatus) })
}

export const setSpecifyWaiterStatusData = async (specifyWaiterStatus: boolean) => {
  await Storage.set({ key: SPECIFY_WAITER_STATUS, value: JSON.stringify(specifyWaiterStatus) })
}

export const setShiftTimeFromData = async (shiftTimeFrom: string) => {
  await Storage.set({ key: SHIFT_TIME_FROM, value: shiftTimeFrom })
}

export const setShiftTimeToData = async (shiftTimeTo: string) => {
  await Storage.set({ key: SHIFT_TIME_TO, value: shiftTimeTo })
}

export const setCurrentCashdeskData = async (currentCashdesk: Cashdesk) => {
  if (!!currentCashdesk) {
    await Storage.set({ key: CURRENT_CASHDESK, value: JSON.stringify(currentCashdesk) })
  } else {
    await Storage.remove({ key: CURRENT_CASHDESK })
  }
}

export const setLastAyriaPaymentData = async (lastAyriaPayment: any) => {
  if (!!lastAyriaPayment) {
    await Storage.set({ key: LAST_AYRIA_PAYMENT, value: JSON.stringify(lastAyriaPayment) })
  } else {
    await Storage.remove({ key: LAST_AYRIA_PAYMENT })
  }
}

export const setAcceptorCodeData = async (acceptorCode: string) => {
  if (!!acceptorCode) {
    await Storage.set({ key: ACCEPTOR_CODE, value: acceptorCode })
  } else {
    await Storage.remove({ key: ACCEPTOR_CODE })
  }
}

export const setMyClubCodeData = async (myClubCode: string) => {
  if (!!myClubCode) {
    await Storage.set({ key: MY_CLUB_CODE, value: myClubCode })
  } else {
    await Storage.remove({ key: MY_CLUB_CODE })
  }
}
