import React, { useState } from 'react'
import './DepartmentSelector.scss'

interface DepartmentSelectorProps {
  onSelectDept: Function
}

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({ onSelectDept }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [deps] = useState<any>([
    'ALL',
    'RESTAURANT',
    'STORE',
    'CLOTHING',
    'CONSULTING',
    'ART',
    'BEAUTY',
    'CULTURE',
    'DIGITAL',
    'FORMALITY',
    'HEALTH',
    'HOME_APPLIANCE',
    'JEWELLERY',
    'SCIENCE',
    'SPORT',
    'TRAVEL',
  ])

  const tapSlideIndex = (id: number) => {
    setSlideIndex(id)
    onSelectDept(id)
  }

  return (
    <div className='scrollMenu p-0 m-2 pb-3'>
      {deps.map((dep: string, idx: number) => (
        <React.Fragment key={`${dep}-${idx}-0`}>
          <img
            alt={''}
            data-src={`/assets/images/deps/DEP_${dep}.png`}
            className={'dep-logo lazyload ml-4'}
            hidden={idx === slideIndex}
            width='58px'
            height='58px'
            onClick={() => {
              tapSlideIndex(idx)
            }}
            key={`${dep}-${idx}-1`}
          />
          <img
            alt={''}
            data-src={`/assets/images/deps/DEP_${dep}_T.png`}
            className={'dep-logo lazyload ml-4'}
            hidden={idx !== slideIndex}
            width='58px'
            height='58px'
            key={`${dep}-${idx}-2`}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

export default DepartmentSelector
