import React, { useState, useEffect, useContext } from 'react'
import { Input, FormGroup, Label, Button, Popover, PopoverBody } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { WalletContext } from '../../structures/contexts/WalletContext'
import { ScoreContext } from '../../structures/contexts/ScoreContext'
import mobileImageResolver from '../../../utility/mobile-image-resolver'
import { convertToEnglish } from '../../../utility/number'
import WalletDeposit from '../WalletDeposit/WalletDeposit'
import { CustomModal } from '../../../ayria'
import WalletBalance from '../WalletBalance/WalletBalance'
import { IPG_MIN_ACCEPTED_AMOUNT } from '../../../config'
import { useToast } from '../../../ayria'

const MobileCharge = ({ t }) => {
  const toaster = useToast()
  const [mobileNumber, setMobileNumber] = useState('')
  const [amount, setAmount] = useState(10000)
  const [result, setResult] = useState()
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState('form')
  const [score, setScore] = useContext(ScoreContext)
  const [wallet, setWallet] = useContext(WalletContext)
  const [mobileImage, setMobileImage] = useState('')
  const [mobileSuggests, setMobileSuggests] = useState([])
  const [showMobileSuggest, setShowMobileSuggest] = useState(false)
  const [useScore, setUseScore] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [neededAmount, setNeededAmount] = useState(0)
  const [originalCommand, setOriginalCommand] = useState()
  async function handleSubmit() {
    setInprogress(true)
    const res = await api.post(`/user/mobilecharge/do`, { mobileNumber, amount, useScore })
    if (res.ok) {
      if (res.data.ok) {
        setResult(res.data)
        setPage('result')
      } else {
        toaster.error(res.data.returnMessage)
      }
    } else {
      if (res.status === 417) {
        const debt = amount - wallet.balance
        setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
        setOriginalCommand(JSON.stringify({ mobileNumber, amount, useScore }))
        setShowWalletDepositModal(true)
      } else {
        toaster.error(res.data && res.data.title ? res.data.title : t('label.operationFailed', { status: res.status }))
      }
    }
    if (useScore) {
      const scoreRes = await api.get(`/customer/scores/total/0`)
      setScore((state) => ({ ...state, balance: scoreRes.data }))
    } else {
      const res = await api.fn.getWalletBalance({ acceptorCode: null })
      setWallet((state) => ({ ...state, balance: res.data.balance }))
    }
    setInprogress(false)
  }
  const resolveMobileImage = (value) => {
    setMobileImage(mobileImageResolver(value))
  }
  const triggerShowMobileSuggest = (value) => {
    if (mobileSuggests.length > 0) {
      setShowMobileSuggest(true)
    }
  }
  const triggerHideMobileSuggest = () => {
    setTimeout(() => {
      setShowMobileSuggest(false)
    }, 300)
  }
  async function getMobileSuggests() {
    const res = await api.get(`/user/mobilecharge/suggest`)
    setMobileSuggests(res.ok ? res.data : [])
  }
  const calculateScoreDiscount = () => {
    if (score.balance * 200 > amount) {
      return amount / 2
    }
    return score.balance * 100
  }
  const hasSuggest = () => {
    if (
      showMobileSuggest &&
      mobileSuggests.length > 0 &&
      mobileSuggests.filter((item) => item.mobile.includes(mobileNumber)).length > 0
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    getMobileSuggests()
  }, [])

  return (
    <>
      {page === 'form' && (
        <div>
          <WalletBalance />
          <FormGroup>
            <Label for='mobile'>
              {'شماره همراه'}{' '}
              {mobileImage && <img alt='' src={mobileImage} className='text-left' width='35px' height='35px' />}
            </Label>
            <Input
              id='mobile'
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(convertToEnglish(e.target.value))
                resolveMobileImage(e.target.value)
              }}
              onFocus={triggerShowMobileSuggest}
              onBlur={triggerHideMobileSuggest}
              className='text-center'
              autoComplete='off'
              type='number'
            />
            <Popover target='mobile' isOpen={showMobileSuggest && hasSuggest()} placement='bottom'>
              <PopoverBody>
                {mobileSuggests
                  .filter((item) => item.mobile.includes(mobileNumber))
                  .map((item, index) => (
                    <div
                      key={item.mobile}
                      onClick={(e) => {
                        setMobileNumber(item.mobile)
                        resolveMobileImage(item.mobile)
                      }}
                      className='text-center'>
                      <span className='padding'>{item.mobile}</span>
                      <img alt='' src={mobileImageResolver(item.mobile)} width='35px' height='35px' />
                      {index + 1 !== mobileSuggests.length && <hr />}
                    </div>
                  ))}
              </PopoverBody>
            </Popover>
          </FormGroup>
          <FormGroup>
            <Label id='amount'>{'مبلغ'}</Label>
            <Input
              id='amount'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className='text-center'
              type='select'>
              <option>10000</option>
              <option>20000</option>
              <option>50000</option>
              <option>100000</option>
            </Input>
          </FormGroup>
          <br />
          <br />
          <div className={'border-full p-2 mt-2'}>
            <div className={'text-center text-bold'}>روش پرداخت</div>
            <div className={'text-center'}>
              <button
                className='btn btn-score btn-sm'
                onClick={(e) => {
                  setPage('confirm')
                  setUseScore(true)
                }}
                disabled={inprogress || !mobileNumber || mobileNumber.length !== 11}>
                <div>امتیاز</div>
              </button>
              <button
                className='btn btn-wallet btn-sm'
                onClick={(e) => {
                  setPage('confirm')
                  setUseScore(false)
                }}
                disabled={inprogress || !mobileNumber || mobileNumber.length !== 11}>
                <div>کیف پول</div>
              </button>
            </div>
          </div>
        </div>
      )}
      {page === 'confirm' && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'مبلغ'}</td>
                <td>
                  {(+amount).toLocaleString(navigator.language)} {t('label.riyal')}
                </td>
              </tr>
              <tr>
                <td>{'شماره همراه'}</td>
                <td>
                  {mobileNumber}
                  <img alt='' src={mobileImageResolver(mobileNumber)} width='35px' height='35px' />
                </td>
              </tr>
              <tr>
                <td>{'روش پرداخت'}</td>
                <td>{useScore ? 'امتیاز' : 'کیف پول'}</td>
              </tr>
              {useScore && (
                <tr>
                  <td>{'تخفیف خرید با امتیاز'}</td>
                  <td>
                    {calculateScoreDiscount().toLocaleString(navigator.language)} {t('label.riyal')}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan='2' className='text-center'>
                  <Button
                    onClick={(e) => {
                      setPage('form')
                    }}>
                    {'برگشت'}
                  </Button>
                  <Button className='btn-theme' onClick={handleSubmit} disabled={inprogress}>
                    {'تایید'}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {page === 'result' && result && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'تاریخ'}</td>
                <td>{result.createdDate}</td>
              </tr>
              <tr>
                <td>{'مبلغ'}</td>
                <td>{(+result.amount).toLocaleString(navigator.language)}</td>
              </tr>
              <tr>
                <td>{'نوع شارژ'}</td>
                <td>{t('mobileChargeType.' + result.chargeType)}</td>
              </tr>
              <tr>
                <td>{'شماره همراه'}</td>
                <td>{result.mobileNumber}</td>
              </tr>
              <tr>
                <td>{'شماره ارجاع'}</td>
                <td>{result.referenceNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <CustomModal
        showModal={showWalletDepositModal}
        toggle={setShowWalletDepositModal}
        title={'افزایش موجودی'}
        content={
          <WalletDeposit
            amount={+neededAmount}
            originalCommand={originalCommand}
            originalCommandType={'MOBILE_CHARGE'}
            targetAmount={amount}
          />
        }
      />
    </>
  )
}

export default withTranslation()(MobileCharge)
