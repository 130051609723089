import React from 'react'
import { IonText } from '@ionic/react'
import { Modal, ModalBody } from 'reactstrap'
import { connect } from '../../../data/connect'
import { SmallLogo } from '../../../ayria'

interface StateProps {
  theme?: string
  loggedUser?: any
}

interface OwnProps {
  showModal: boolean
  title: any
  content: any
  toggle?: React.Dispatch<React.SetStateAction<boolean>>
  isPlatformTheme?: boolean
  onClosed?: () => void
  backdrop?: boolean
  overrideLogo?: any
}

interface CustomModalProps extends OwnProps, StateProps {}

const CustomModal: React.FC<CustomModalProps> = ({
  showModal,
  title,
  content,
  toggle,
  isPlatformTheme = false,
  onClosed = () => {},
  theme,
  loggedUser,
  backdrop = true,
  overrideLogo = false,
}) => {
  const innerToggle = () => {
    if (toggle) {
      toggle(false)
    }
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={innerToggle}
      onClosed={onClosed}
      backdrop={backdrop}
      className={isPlatformTheme ? 'platform-theme' : `${theme}-theme`}>
      <IonText className={'modal-header text-center'}>
        {toggle && (
          <span onClick={innerToggle} className='m-2 pointer' style={{ color: 'white' }}>
            X
          </span>
        )}
        <span className='modal-title pt-2'>{title}</span>
        {isPlatformTheme && <img alt='icon' src={'/assets/share/ayria-platform-a-logo.png'} height='45px'></img>}
        {!isPlatformTheme && <SmallLogo override={overrideLogo} />}
      </IonText>
      <ModalBody>{content}</ModalBody>
    </Modal>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    theme: state.user.theme,
    loggedUser: state.user.loggedUser,
  }),
  component: CustomModal,
})
