import React, { useState, useEffect, lazy } from 'react'
import { add, remove, trash, shareSocial, close, timer, documentText, phonePortrait } from 'ionicons/icons'
import { IonRadioGroup, IonListHeader, IonLabel, IonRadio, IonItem, IonButton, IonInput } from '@ionic/react'
import { IonCard, IonCardSubtitle, IonCardContent, IonCheckbox, IonRow, IonCol } from '@ionic/react'
import { IonIcon, IonFooter, IonToolbar, IonButtons, IonItemDivider } from '@ionic/react'
import { IonSpinner, IonSegment, IonSegmentButton, IonTextarea, IonCardHeader } from '@ionic/react'
import { IonFab, IonText, IonFabList } from '@ionic/react'
import { Share } from '@capacitor/share'
import { WalletRequests } from '../../../components/screens'
import { useTranslation } from 'react-i18next'
import { IPG_MIN_ACCEPTED_AMOUNT, EventEmitter, ApiService, connect } from '../../../ayria'
import { setSendDeliveryStatus, setSpecifyWaiterStatus, CurrencyIonInput } from '../../../ayria'
import { useToast, Modal, KalaSuggest, MobileIonInput, ShippingAddressAcceptorSuggest } from '../../../ayria'
import { FullnameLookup, Cashdesk, addDeskKala, removeDeskKala, setDeskKalaList } from '../../../ayria'
import { addDeskFactor, removeDeskFactor } from '../../../ayria'
import { AcceptorSettings, isDesktop, setLastAyriaPayment, NumberIonInput } from '../../../ayria'
import './WalletPayeeRequest.scss'

const AyriaPayment = lazy(() => import('../../../components/elements/AyriaPayment/AyriaPayment'))

interface OwnProps {
  acceptorCode: string
  onSuccess: Function
  showPreviousRequests?: boolean
  showAddKalaSuggest?: boolean
}
interface StateProps {
  hasWaiter: boolean
  hasKala: boolean
  sendDeliveryStatus: boolean
  specifyWaiterStatus: boolean
  currentCashdesk: Cashdesk
  deskKalaList: any[]
  settings: AcceptorSettings
  deskFactorList: any[]
}
interface DispatchProps {
  setSendDeliveryStatus: typeof setSendDeliveryStatus
  setSpecifyWaiterStatus: typeof setSpecifyWaiterStatus
  setDeskKalaList: typeof setDeskKalaList
  addDeskKala: typeof addDeskKala
  removeDeskKala: typeof removeDeskKala
  setLastAyriaPayment: typeof setLastAyriaPayment
  addDeskFactor: typeof addDeskFactor
  removeDeskFactor: typeof removeDeskFactor
}
interface WalletPayeeRequestProps extends OwnProps, StateProps, DispatchProps {}

const WalletPayeeRequest: React.FC<WalletPayeeRequestProps> = ({
  onSuccess,
  acceptorCode,
  showPreviousRequests = true,
  hasWaiter,
  hasKala,
  sendDeliveryStatus,
  setSendDeliveryStatus,
  specifyWaiterStatus,
  setSpecifyWaiterStatus,
  currentCashdesk,
  deskKalaList,
  setDeskKalaList,
  addDeskKala,
  removeDeskKala,
  settings,
  showAddKalaSuggest = true,
  setLastAyriaPayment,
  addDeskFactor,
  removeDeskFactor,
  deskFactorList,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const [amount, setAmount] = useState<any>(undefined)
  const [payerName, setPayerName] = useState<string>()
  const [description, setDescription] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('WALLET')
  const [shippingAddress, setShippingAddress] = useState<any>()
  const [shippingFee, setShippingFee] = useState()
  const [ayriaPayment, setAyriaPayment] = useState<any>()
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [callbackFunction, setCallbackFunction] = useState('')
  const [confirmationText, setConfirmationText] = useState('')
  const [show, setShow] = useState(true)
  const [currentKala, setCurrentKala] = useState<any>()
  const [kalaPrice, setKalaPrice] = useState<any>()
  const [kalaCount, setKalaCount] = useState<any>(1)
  const [kalaReset, setKalaReset] = useState(false)
  const [terminal, setTerminal] = useState('')
  const [terminals, setTerminals] = useState([])
  const [inprogress, setInprogress] = useState(false)
  const [shippingFeeInprogress, setShippingFeeInprogress] = useState(false)
  const [render, setRender] = useState(false)
  const [noSmsProp, setNoSmsProp] = useState(false)

  const handlePayeeRequestForReal = async (noSms: boolean = false) => {
    setNoSmsProp(noSms)
    if (+amount < IPG_MIN_ACCEPTED_AMOUNT) {
      toast.error(
        t('amount_should_be_at_least_x', { x: IPG_MIN_ACCEPTED_AMOUNT.toLocaleString(navigator.language) + ' ریال' })
      )
      return
    }
    if (!mobileNumber) {
      toast.error(t('NotEmpty', { field: t('payerMobile') }))
      return
    }
    setInprogress(true)
    const res: any = await api.post(`/user/ayria-payments/payee-request`, {
      amount,
      payerMobile: mobileNumber,
      payerName,
      description,
      acceptorCode,
      paymentMethod,
      kalas: deskKalaList.map((i) => ({ kala: i, count: i.qty, price: i.price, description: i.description })),
      terminal,
      noSms,
      shippingAddressId: shippingAddress?.id,
      shippingFee,
      cashdeskIdentifier: currentCashdesk?.identifier,
      receiveType: settings?.delivery && sendDeliveryStatus ? 'DELIVERY' : undefined,
    })
    if (res.ok) {
      toast.success(`صورتحساب ${res.data.trackingNumber} با موفقیت صادر شد`)
      await setLastAyriaPayment(res.data)
      await setDeskKalaList([])
      EventEmitter.dispatch('NEW_PAYEE_REQUEST', res.data)
      if (noSms) {
        try {
          await Share.share({
            text: res.data.paymentText,
          })
        } catch (error) {
          setTimeout(() => {
            toast.error('به اشتراک گذاری با خطا مواجه شد')
          }, 3000)
        }
      }
      if (acceptorCode) {
        setAyriaPayment(res.data)
        setShowReceiptModal(true)
        setShow(false)
      } else {
        onSuccess(res.data, noSms)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getPosTerminals = async () => {
    const res: any = await api.getPosTerminals(acceptorCode || '')
    setTerminals(res.ok ? res.data : [])
  }
  const shippingAddressOnChange = async () => {
    setShippingFeeInprogress(true)
    setShippingFee(null)
    const res: any = await api.shippingAddressesIn(shippingAddress?.id, acceptorCode)
    if (res.ok && res.data.length > 0) {
      setShippingFee(res.data[0].price)
    }
    setShippingFeeInprogress(false)
  }
  const handlePayeeRequest = (noSms: boolean) => {
    if (paymentMethod === 'WALLET') {
      handlePayeeRequestForReal(noSms)
    } else {
      setCallbackFunction(paymentMethod)
      if (paymentMethod === 'CASH') {
        setConfirmationText('آیا می‌خواهید گزینه پرداخت نقدی را ادامه دهید؟')
      } else if (paymentMethod === 'POS') {
        setConfirmationText('آیا می‌خواهید گزینه پرداخت با دستگاه کارتخوان را ادامه دهید؟')
      }
      setShowConfirmModal(true)
    }
  }
  const onReceiptSuccess = (res: any) => {
    setShowReceiptModal(false)
    if (res) {
      onSuccess(ayriaPayment, noSmsProp)
    }
  }
  const executeCallback = () => {
    funcMap[callbackFunction]()
    setShowConfirmModal(false)
  }
  const updateSendDeliveryStatus = async (checked: boolean) => {
    await setSendDeliveryStatus(checked)
    if (checked) {
      await setSpecifyWaiterStatus(false)
    }
  }
  const updateSpecifyWaiterStatus = async (checked: boolean) => {
    await setSpecifyWaiterStatus(checked)
    if (checked) {
      await setSendDeliveryStatus(false)
    }
  }
  const handleKalaOnBlur = (kala: any) => {
    if (currentKala === undefined) {
      setCurrentKala(kala)
    }
  }
  const addKala = async () => {
    await addDeskKala({ ...currentKala, qty: kalaCount, price: currentKala.price ? currentKala.price : kalaPrice })
    setCurrentKala(undefined)
    setKalaCount(1)
    setKalaPrice(undefined)
    setKalaReset(!kalaReset)
    setRender(false)
  }
  const removeKala = async (kala: any) => {
    await setDeskKalaList(deskKalaList.filter((item: any) => item.id !== kala.id))
    setRender(false)
  }
  const handleKalaCountChange = async (kala: any, count: number) => {
    if (!!count && deskKalaList.length > 0) {
      deskKalaList.find((k) => kala.id === k.id).qty = +count
      await setDeskKalaList(deskKalaList)
      setRender(false)
    }
  }
  const handleKalaDescChange = async (kala: any, description: string) => {
    if (deskKalaList.length > 0) {
      deskKalaList.find((k) => kala.id === k.id).description = description
      await setDeskKalaList(deskKalaList)
      setRender(false)
    }
  }
  const increaseKalaCount = () => {
    setKalaCount(+kalaCount + 1)
    setRender(false)
  }
  const decreaseKalaCount = () => {
    setKalaCount(+kalaCount > 1 ? +kalaCount - 1 : 1)
    setRender(false)
  }
  const isFormValid = () => {
    return mobileNumber && amount
  }
  const isMobileValid = () => {
    return !!mobileNumber && /^(09[0-9]{9})$/.test(mobileNumber) && mobileNumber.toString().length === 11
  }
  const onMobileChange = (mobile: string) => {
    setMobileNumber(mobile)
    setPayerName(undefined)
  }
  const showAddress = () => {
    return settings?.delivery && sendDeliveryStatus && /^(09[0-9]{9})$/.test(mobileNumber)
  }
  // eslint-disable-next-line
  const increaseItemCount = (item) => {
    addDeskKala(item)
    setRender(false)
  }
  // eslint-disable-next-line
  const decreaseItemCount = (item) => {
    removeDeskKala(item)
    setRender(false)
  }
  const resetForm = async () => {
    setMobileNumber(undefined)
    setAmount(undefined)
    setDescription(undefined)
    setPaymentMethod('WALLET')
    await setDeskKalaList([])
  }
  const copyForm = async () => {
    await addDeskFactor({
      id: mobileNumber,
      payerName,
      kalas: deskKalaList,
      description,
      paymentMethod,
      shippingAddress,
      shippingFee,
    })
    await resetForm()
  }
  const restoreForm = async (factor: any) => {
    setMobileNumber(factor.id)
    setDescription(factor.description)
    setPaymentMethod(factor.paymentMethod)
    setShippingAddress(factor.shippingAddress)
    setTimeout(() => {
      if (!!factor.payerName) {
        setPayerName(factor.payerName)
      }
      if (!!factor.shippingFee) {
        setShippingFee(factor.shippingFee)
      }
    }, 1000)
    await setDeskKalaList(factor.kalas)
    await removeDeskFactor(factor)
  }

  const funcMap = {} as any
  funcMap.method = function (name: string, cb: () => void) {
    this[name] = cb
  }
  funcMap.method('CASH', () => {
    handlePayeeRequestForReal()
  })
  funcMap.method('POS', () => {
    handlePayeeRequestForReal()
  })

  useEffect(() => {
    if (!!shippingAddress?.id) {
      shippingAddressOnChange()
    }
  }, [shippingAddress?.id]) // eslint-disable-line

  useEffect(() => {
    let total = 0
    // eslint-disable-next-line
    deskKalaList?.map((kala: any) => {
      total += kala.price ? kala.price * kala.qty : 0
    })
    setAmount(total)
    setRender(true)
  }, [deskKalaList, render]) // eslint-disable-line

  useEffect(() => {
    if (acceptorCode) {
      getPosTerminals()
    }
  }, [acceptorCode]) // eslint-disable-line

  return (
    <div className='text-start' id='WalletPayeeRequest'>
      {show && (
        <div className={'mb-3'}>
          <IonFab position='fixed' style={{ marginTop: '-25px' }}>
            <IonButton
              color='danger'
              fill='clear'
              onClick={resetForm}
              size='small'
              className='ion-no-padding'
              title={'شروع مجدد'}>
              <IonIcon icon={close} />
            </IonButton>
          </IonFab>
          <IonFab position='fixed' style={{ marginTop: '-25px', marginLeft: '-25px' }} horizontal='end'>
            <IonButton
              color='primary'
              fill='clear'
              onClick={copyForm}
              size='small'
              title={'ذخیره موقت'}
              disabled={!isMobileValid()}>
              <IonIcon icon={timer} />
            </IonButton>
          </IonFab>
          {deskFactorList?.length > 0 && (
            <IonFab position='fixed' style={{ marginTop: '-25px', marginLeft: '30px' }} horizontal='end' edge={true}>
              <IonButton color='primary' fill='clear' size='small' title={'بازگردانی'}>
                <IonIcon icon={documentText} />
              </IonButton>
              <IonFabList side='bottom' style={{ marginRight: '-110px' }} className='ion-text-left'>
                {deskFactorList?.map((f) => (
                  <IonButton onClick={() => restoreForm(f)} key={f.id} style={{ width: '150px' }}>
                    <IonIcon slot='end' icon={phonePortrait} /> {f.id}
                  </IonButton>
                ))}
              </IonFabList>
            </IonFab>
          )}
          <IonRow>
            <IonCol>
              <IonItem>
                <MobileIonInput value={mobileNumber} onValueChange={onMobileChange} placeholder={'شماره همراه '} />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <FullnameLookup
                  value={payerName}
                  mobile={mobileNumber}
                  onChange={setPayerName}
                  disabled={!mobileNumber}
                />
              </IonItem>
            </IonCol>
            {isDesktop() && settings?.delivery && (
              <IonCol size='4'>
                <IonItem style={{ fontSize: '12px' }}>
                  <IonLabel>{'ارسال با پیک'}</IonLabel>
                  <IonCheckbox
                    slot='start'
                    className='ion-no-margin ion-margin-end'
                    checked={sendDeliveryStatus}
                    onIonChange={(e) => updateSendDeliveryStatus(e.detail.checked)}
                  />
                </IonItem>
              </IonCol>
            )}
          </IonRow>
          {!isDesktop() && settings?.delivery && (
            <IonItem>
              <IonLabel>{'ارسال با پیک'}</IonLabel>
              <IonCheckbox
                slot='start'
                checked={sendDeliveryStatus}
                onIonChange={(e) => updateSendDeliveryStatus(e.detail.checked)}
              />
            </IonItem>
          )}
          {showAddress() && (
            <>
              <ShippingAddressAcceptorSuggest
                defaultValue={shippingAddress}
                acceptorCode={acceptorCode}
                identifier={mobileNumber}
                onChange={() => setShippingAddress(null)}
                onSelect={(shippingAddress) => {
                  setShippingAddress(shippingAddress)
                }}
              />
              {!!shippingAddress?.id && (
                <IonRow>
                  <IonCol size='3' className='ion-align-self-center'>
                    {'هزینه پیک'}
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      {!shippingFeeInprogress && (
                        <CurrencyIonInput
                          defaultValue={shippingFee}
                          onValueChange={(value) => setShippingFee(value)}
                          placeholder={'هزینه پیک'}
                        />
                      )}
                      {!!shippingFeeInprogress && <IonSpinner name='crescent' color='dark' />}
                    </IonItem>
                  </IonCol>
                  <IonCol size='2' className='ion-align-self-center'>
                    {'ریال'}
                  </IonCol>
                </IonRow>
              )}
            </>
          )}
          {acceptorCode && hasKala && (
            <div>
              <IonCardHeader>
                <IonItemDivider className='ion-no-padding'>
                  <IonLabel style={{ margin: 'auto' }}>{'جزییات صورتحساب'}</IonLabel>
                </IonItemDivider>
              </IonCardHeader>
              {showAddKalaSuggest && !isDesktop() && (
                <IonRow>
                  <IonCol size='2' className='ion-no-padding'>
                    <IonButton
                      onClick={addKala}
                      size='small'
                      fill='clear'
                      disabled={!currentKala?.name || (!currentKala?.price && !kalaPrice)}>
                      <IonIcon icon={add} slot='icon-only' />
                    </IonButton>
                  </IonCol>
                  <IonCol className='ion-no-padding'>
                    <KalaSuggest
                      onSelect={(kala: any) => setCurrentKala(kala)}
                      onChange={(value: string) => setCurrentKala(undefined)}
                      onBlur={handleKalaOnBlur}
                      acceptorCode={acceptorCode}
                      reset={kalaReset}
                    />
                  </IonCol>
                  {currentKala?.name && (
                    <>
                      <IonCol className='ion-no-padding ion-text-center'>
                        <IonIcon onClick={increaseKalaCount} icon={add} style={{ fontSize: '24px' }} />
                        <span style={{ paddingLeft: '3px', paddingRight: '3px' }}>{kalaCount}</span>
                        <IonIcon onClick={decreaseKalaCount} icon={remove} style={{ fontSize: '24px' }} />
                      </IonCol>
                      <IonCol className='ion-no-padding ion-text-end'>
                        {!currentKala?.price && (
                          <IonItem>
                            <CurrencyIonInput onValueChange={setKalaPrice} defaultValue={currentKala?.price} />
                          </IonItem>
                        )}
                        {currentKala?.price && <span>{currentKala?.price.toLocaleString(navigator.language)}</span>}
                      </IonCol>
                    </>
                  )}
                </IonRow>
              )}
              {deskKalaList?.map((kala: any, index: number) => {
                return (
                  <IonRow key={kala.id}>
                    <IonCol size='1.5' className='ion-no-padding ion-align-self-center'>
                      <IonButton color='danger' size='small' onClick={() => removeKala(kala)} fill='clear'>
                        <IonIcon icon={close} slot='icon-only' />
                      </IonButton>
                    </IonCol>
                    <IonCol className='ion-align-self-center'>{kala.name}</IonCol>
                    <IonCol size='2' className='ion-text-center'>
                      <IonItem>
                        <IonLabel position='stacked'>{'تعداد'}</IonLabel>
                        <NumberIonInput
                          value={kala.qty}
                          onValueChange={(value: any) => handleKalaCountChange(kala, value)}
                          min={1}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem>
                        <IonLabel position='stacked'>{(+kala?.price)?.toLocaleString(navigator.language)}</IonLabel>
                        <IonInput
                          placeholder={'توضیحات'}
                          onIonChange={(e: any) => handleKalaDescChange(kala, e.detail.value)}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                )
              })}
            </div>
          )}
          <IonItem lines='none'>
            <IonLabel className='ion-text-wrap ion-no-margin'>
              <span style={{ fontSize: '11px' }}>مبلغ مورد نظر شما برای دریافت وجه :</span>
              {render && (
                <strong style={{ fontSize: '20px' }}>{(+amount || 0).toLocaleString(navigator.language)}</strong>
              )}
              {!render && <span>...</span>} {t('label.riyal')}{' '}
            </IonLabel>
          </IonItem>
          {deskKalaList?.length === 0 && (
            <IonItem>
              <CurrencyIonInput onValueChange={setAmount} />
            </IonItem>
          )}
          <IonItem>
            <IonTextarea
              id='desc'
              value={description}
              onIonChange={(e) => setDescription(e.detail.value)}
              placeholder={'توضیحات'}
            />
          </IonItem>
          {acceptorCode && (
            <>
              {!isDesktop() && (
                <IonItem lines='none' style={{ height: '31px' }}>
                  <IonLabel postion='stacked' style={{ fontSize: '12px' }} className='ion-no-margin'>
                    نحوه پرداخت
                  </IonLabel>
                </IonItem>
              )}
              <IonItem lines='none'>
                <IonRow>
                  {isDesktop() && (
                    <IonCol className='flex-container'>
                      <IonText style={{ fontSize: '12px' }}>نحوه پرداخت</IonText>
                    </IonCol>
                  )}
                  <IonCol>
                    <IonSegment value={paymentMethod} onIonChange={(e) => setPaymentMethod(e.detail.value)}>
                      <IonSegmentButton value='WALLET'>کیف پول</IonSegmentButton>
                      <IonSegmentButton value='CASH'>نقد</IonSegmentButton>
                      <IonSegmentButton value='POS'>کارتخوان</IonSegmentButton>
                    </IonSegment>
                  </IonCol>
                </IonRow>
              </IonItem>
            </>
          )}
          {acceptorCode && 'POS' === paymentMethod && terminals?.length > 0 && (
            <IonRadioGroup value={terminal} onIonChange={(e) => setTerminal(e.detail.value)}>
              <IonListHeader>
                <IonLabel>{'انتخاب کارتخوان'}</IonLabel>
                {terminal && (
                  <IonButton fill='clear' color='danger' onClick={() => setTerminal('')}>
                    <IonIcon icon={trash} />
                  </IonButton>
                )}
              </IonListHeader>
              <IonRow>
                {terminals.map((t: any) => (
                  <IonCol key={t.terminal}>
                    <IonItem>
                      <IonLabel>{t.name || t.terminal}</IonLabel>
                      <IonRadio slot='start' value={t.terminal} />
                    </IonItem>
                  </IonCol>
                ))}
              </IonRow>
            </IonRadioGroup>
          )}
          {acceptorCode && hasWaiter && false && (
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>{'انتخاب پیشخدمت'}</IonLabel>
                  <IonCheckbox
                    slot='start'
                    checked={specifyWaiterStatus}
                    onIonChange={(e) => updateSpecifyWaiterStatus(e.detail.checked)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
        </div>
      )}
      {!!show && (
        <IonFooter>
          <IonToolbar>
            <IonButtons>
              <IonButton
                slot='primary'
                fill='solid'
                size='large'
                color='success'
                onClick={() => handlePayeeRequest(false)}
                disabled={inprogress || !isFormValid()}>
                {acceptorCode ? ' صدور صورتحساب' : ' ارسال با پیامک '}
                {inprogress && !noSmsProp && <IonSpinner slot='start' />}
              </IonButton>
              {!isDesktop() && (
                <IonButton
                  slot='secondary'
                  fill='solid'
                  size='large'
                  color='primary'
                  onClick={() => handlePayeeRequest(true)}
                  disabled={inprogress || !isFormValid()}>
                  {!inprogress && <IonIcon icon={shareSocial} slot='start' />}
                  {'اشتراک گذاری'}
                  {inprogress && noSmsProp && <IonSpinner slot='start' />}
                </IonButton>
              )}
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
      {showPreviousRequests && (
        <IonCard style={{ marginLeft: 0, marginRight: 0 }}>
          <IonCardSubtitle>{'صورتحساب ها'}</IonCardSubtitle>
          <IonCardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <WalletRequests acceptorCode={acceptorCode} />
          </IonCardContent>
        </IonCard>
      )}
      <Modal
        showModal={showConfirmModal}
        title={t('label.confirm')}
        isPlatformTheme={acceptorCode ? true : false}
        onClosed={() => setShowConfirmModal(false)}
        content={
          <div>
            <p>{confirmationText}</p>
            <div>
              <IonButton className='right' disabled={inprogress} onClick={executeCallback}>
                {t('label.yes')}
              </IonButton>
              <IonButton color='light' className='left' onClick={() => setShowConfirmModal(false)}>
                {t('label.cancel')}
              </IonButton>
            </div>
          </div>
        }
      />
      {!inprogress && ayriaPayment && (
        <Modal
          showModal={showReceiptModal}
          toggle={setShowReceiptModal}
          isPlatformTheme={acceptorCode ? true : false}
          onClosed={() => onReceiptSuccess(true)}
          title={acceptorCode ? 'درخواست وجه' : 'صورتحساب'}
          id='ayriaPaymentModal'
          content={
            <AyriaPayment
              payment={ayriaPayment}
              acceptorCode={acceptorCode}
              onSuccess={(res) => onReceiptSuccess(res)}
              showCashierActions={true}
              autoConfirm={ayriaPayment.paymentMethod}
            />
          }
        />
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hasWaiter: state.shop.hasWaiter,
    hasKala: state.shop.hasKala,
    sendDeliveryStatus: state.shop.sendDeliveryStatus,
    specifyWaiterStatus: state.shop.specifyWaiterStatus,
    currentCashdesk: state.shop.currentCashdesk,
    deskKalaList: state.shop.deskKalaList,
    settings: state.shop.settings,
    deskFactorList: state.shop.deskFactorList,
  }),
  mapDispatchToProps: {
    setSendDeliveryStatus,
    setSpecifyWaiterStatus,
    setDeskKalaList,
    addDeskKala,
    removeDeskKala,
    setLastAyriaPayment,
    addDeskFactor,
    removeDeskFactor,
  },
  component: WalletPayeeRequest,
})
