import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import WalletEventRefType from '../../units/wallet/WalletEventRefType'
import { ApiService } from '../../../ayria'

interface WalletTransferConfirmProps {
  transfer: any
}

const WalletTransferConfirm: React.FC<WalletTransferConfirmProps> = ({ transfer }) => {
  const { t } = useTranslation()
  const api = new ApiService()
  const [receiver, setReceiver] = useState<any>()

  const unMaskReceiver = async () => {
    const res: any = await api.get(`/user/suggest/mobile/${transfer.mobileNumber}`)
    if (res.ok) {
      setReceiver(res.data)
    }
  }

  useEffect(() => {
    unMaskReceiver()
  }, []) // eslint-disable-line

  return (
    <>
      {transfer && (
        <div>
          <div className='text-center'>
            <WalletEventRefType walletEvent={transfer} width={'40px'} />
          </div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td className='text-right'>مبلغ</td>
                <td>
                  {transfer.amount.toLocaleString(navigator.language)} {t('label.riyal')}
                </td>
              </tr>
              <tr>
                <td className='text-right'>شماره همراه</td>
                <td>{transfer.mobileNumber}</td>
              </tr>
              <tr>
                <td className='text-right'>دریافت کننده</td>
                <td>
                  {receiver?.firstName} {receiver?.lastName}
                </td>
              </tr>
              <tr>
                <td className='text-right'>توضیحات مبدا</td>
                <td>{transfer.senderDescription}</td>
              </tr>
              <tr>
                <td className='text-right'>توضیحات مقصد</td>
                <td>{transfer.receiverDescription}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default WalletTransferConfirm
