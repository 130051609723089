import React from 'react'
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonMenuButton } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { connect } from '../../data/connect'
import { CLUB_HAS_CLUB_NUMBER } from '../../config'
import ScoreBalance from '../../components/units/wallet/ScoreBalance'
import './shared.scss'
import AddressCombo from '../home/AddressCombo'
import AyriaBackButton from '../../components/units/buttons/AyriaBackButton'

interface OwnProps {
  showBackButton?: boolean
  showScoreBalance?: boolean
  title?: string
  titleLink?: string
  buttons?: any[]
  sticky?: boolean
  showAddressCombo?: boolean
}
interface StateProps {
  kalaList: any[]
}

interface HeaderProps extends OwnProps, StateProps {}

const Header: React.FC<HeaderProps> = ({
  showBackButton = true,
  showScoreBalance = false,
  title,
  titleLink,
  buttons,
  sticky = false,
  showAddressCombo = false,
}) => {
  const history = useHistory()

  return (
    <IonHeader className='ion-no-border' style={{ position: sticky ? 'fixed' : 'relative', zIndex: '1999' }}>
      <IonToolbar>
        <IonButtons slot='start' className='non-printable pl-5'>
          {!showBackButton && <IonMenuButton></IonMenuButton>}
          {showBackButton && <AyriaBackButton defaultHref='/tabs/home' />}
        </IonButtons>
        {showScoreBalance && (
          <IonTitle className={CLUB_HAS_CLUB_NUMBER ? 'text-left' : ''}>
            <ScoreBalance showImg={true} usePadding={false} />
          </IonTitle>
        )}
        {title && (
          <IonTitle
            className='ion-text-center px-0 '
            style={{ position: 'absolute', right: 0, left: 0, top: 0, bottom: 0 }}
            onClick={() => (!!titleLink ? history.replace(titleLink) : {})}
            color={titleLink ? 'tertiary' : 'dark'}>
            {title}
          </IonTitle>
        )}

        {showAddressCombo && (!title || title === '') && <AddressCombo />}

        <IonButtons slot='end'>{buttons}</IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
  }),
  component: Header,
})
