import React, { useState, useEffect } from 'react'
import { IonButton } from '@ionic/react'
import { checkmarkCircleOutline, ellipseOutline } from 'ionicons/icons'
import { withTranslation } from 'react-i18next'
import { URLSearchParams } from 'whatwg-url'
import { useHistory } from 'react-router-dom'
import Auth from '../../../utility/auth/Auth'
import { GOLD_PRICE_S, STAR_PRICE_S, GALAXY_PRICE_S } from '../../../config/plan-price'
import { CLUB, CLUB_SHOW_GOLD } from '../../../config'
import { AyriaIcon } from '../../../ayria'
import './plan-select.css'

const PlanSelect = ({ t, isOnlyInfo = false, merchantReferralCode }) => {
  const auth = new Auth()
  const history = useHistory()
  const [selectedPlan, setSelectedPlan] = useState()
  const [referralCode, setReferralCode] = useState(false)

  const handlePlanSelect = (planType) => {
    setSelectedPlan({ type: planType })
    if (isOnlyInfo) {
      history.push(`/signup?plan=${planType}${merchantReferralCode ? `&ref=${merchantReferralCode}` : ''}`)
    } else {
      history.push(`/purchase-plan?plan=${planType}${referralCode ? `&ref=${referralCode}` : ''}`)
    }
  }

  const handleLogOff = (event) => {
    auth.logout()
  }

  useEffect(() => {
    if (window.location.search.indexOf('ref=') > -1) {
      const params = new URLSearchParams(window.location.search)
      setReferralCode(params.get('ref'))
    }
  }, [])

  return (
    <>
      <div className='page-content'>
        {!selectedPlan && (
          <section id='plans' className='pos-r'>
            <div className='container'>
              {!isOnlyInfo && (
                <div className='row text-center'>
                  <div className='col-lg-8 col-md-12 ml-auto mr-auto'>
                    <div className='section-title'>
                      <h6 style={{ marginBottom: 0 }}> طرح ها</h6>
                      <p>
                        {t(`plan.descriptionLine1${CLUB}`)}
                        <br />
                        {t('plan.descriptionLine2')}
                        {t('plan.descriptionLine3')}
                        <br />
                        {!referralCode && t('plan.descriptionLine4')}
                        {referralCode && t('plan.descriptionLine4_2', { referralCode: referralCode })}
                        <br />
                        {t('plan.descriptionLine5')}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className='row align-items-center'>
                <div className={(CLUB_SHOW_GOLD ? 'col-lg-4' : 'col-lg-6') + ' col-md-12'}>
                  <div className='price-table bg-star'>
                    <div className='price-inside'></div>
                    <div className='price-header'>
                      <h3 className='price-title text-white'>ستاره</h3>
                      <br />
                      <div className='price-value'>
                        <h4 className='text-white'>{STAR_PRICE_S} </h4>
                        <h6 className='text-white'>هزار تومن</h6>
                      </div>
                    </div>
                    <br />
                    <div className='price-list text-white'>
                      <ul className='list-unstyled'>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} />
                          <span>خرید و دریافت خدمات از تمام فروشگاه</span>
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} />
                          <span>امکان کسب و خرج امتیاز</span>{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> کیف پول الکترونیکی{' '}
                        </li>
                        <li className='text-red'>
                          <AyriaIcon icon={ellipseOutline} color='danger' /> بیمه عمر و تامین آتیه{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> صندوق اعتبار{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> تخفیف نقدی
                        </li>
                      </ul>
                    </div>
                    <IonButton color='primary' onClick={() => handlePlanSelect('STAR')} data-text='انتخاب '>
                      <span>انتخاب</span>
                    </IonButton>
                  </div>
                </div>
                {CLUB_SHOW_GOLD && (
                  <div className='col-lg-4 col-md-12 md-mt-5'>
                    <div className='price-table bg-gold'>
                      <div className='price-inside'></div>
                      <div className='price-header'>
                        <h2 className='price-title text-white'>طلایی</h2>
                        <br />
                        <div className='price-value'>
                          <h4 className='text-white'>{GOLD_PRICE_S} </h4>
                          <h6 className='text-white'>هزار تومن</h6>
                        </div>
                      </div>
                      <br />
                      <div className='price-list text-white'>
                        <ul className='list-unstyled'>
                          <li className='text-green'>
                            <AyriaIcon icon={checkmarkCircleOutline} />
                            خرید و دریافت خدمات از تمام فروشگاه
                          </li>
                          <li className='text-green'>
                            <AyriaIcon icon={checkmarkCircleOutline} /> امکان کسب و خرج امتیاز{' '}
                          </li>
                          <li className='text-green'>
                            <AyriaIcon icon={checkmarkCircleOutline} /> کیف پول الکترونیکی{' '}
                          </li>
                          <li className='text-red'>
                            {' '}
                            <AyriaIcon icon={ellipseOutline} color='danger' /> بیمه عمر و تامین آتیه{' '}
                          </li>
                          <li className='text-red'>
                            <AyriaIcon icon={ellipseOutline} color='danger' /> صندوق اعتبار{' '}
                          </li>
                          <li className='text-green'>
                            <AyriaIcon icon={checkmarkCircleOutline} /> تخفیف نقدی
                          </li>
                        </ul>
                      </div>
                      <IonButton color='primary' onClick={() => handlePlanSelect('GOLD')} data-text='انتخاب '>
                        <span> انتخاب</span>
                      </IonButton>
                    </div>
                  </div>
                )}
                <div className={(CLUB_SHOW_GOLD ? 'col-lg-4' : 'col-lg-6') + ' col-md-12 md-mt-5'}>
                  <div className='price-table bg-galaxy'>
                    <div className='price-inside'></div>
                    <div className='price-header'>
                      <h3 className='price-title text-white'>کهکشان</h3>
                      <br />
                      <div className='price-value'>
                        <h4 className='text-white'>{GALAXY_PRICE_S} </h4>
                        <h6 className='text-white'>هزار تومن</h6>
                      </div>
                    </div>
                    <br />
                    <div className='price-list text-white'>
                      <ul className='list-unstyled'>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} />
                          خرید و دریافت خدمات از تمام فروشگاه
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> امکان کسب و خرج امتیاز{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> کیف پول الکترونیکی{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} />
                          بیمه عمر و تامین آتیه{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> صندوق اعتبار{' '}
                        </li>
                        <li className='text-green'>
                          <AyriaIcon icon={checkmarkCircleOutline} /> تخفیف نقدی{' '}
                        </li>
                      </ul>
                    </div>
                    <IonButton color='primary' onClick={() => handlePlanSelect('GALAXY')} data-text='انتخاب '>
                      <span> انتخاب</span>
                    </IonButton>
                  </div>
                </div>
              </div>
              {!isOnlyInfo && (
                <>
                  <hr />
                  <a href='/start'>بازگشت به صفحه آغازین</a>
                  <hr />
                  <a href='/login' onClick={handleLogOff}>
                    {t('profileDropdown.logoff')}
                  </a>
                </>
              )}
            </div>
          </section>
        )}
      </div>
    </>
  )
}

export default withTranslation()(PlanSelect)
