import React, { FC, useState, useEffect, lazy } from 'react'
import { IonItemDivider, IonItem, IonLabel, IonButton } from '@ionic/react'
import { ApiService, useToast, Modal } from '../../ayria'
import { setWithTimeout } from '../../utility'
const ClubAcceptorTableQrCode = lazy(() => import('../../components/units/ClubAcceptorTableQrCode'))

interface OwnProps {
  clubCode: number
  acceptorCode: number
}
interface ClubAcceptorTableQrCodeListProps extends OwnProps {}

const ClubAcceptorTableQrCodeList: FC<ClubAcceptorTableQrCodeListProps> = ({ clubCode, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [identifier, setIdentifier] = useState<string>()
  const [showQrModal, setShowQrModal] = useState(false)

  const getItems = async () => {
    const res: any = await api.get(`/public/acceptors/tables/${acceptorCode}`)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    } else {
      toast.error(res)
    }
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      <IonItemDivider>
        <IonLabel>{'لیست میز‌ها'}</IonLabel>
      </IonItemDivider>
      {items.map((itm: any) => {
        return (
          <IonItem
            key={itm.identifier}
            className='pointer'
            onClick={() => {
              setIdentifier(itm.identifier)
              setShowQrModal(true)
            }}>
            <span>
              <ClubAcceptorTableQrCode
                clubCode={clubCode}
                acceptorCode={acceptorCode}
                tableIdentifier={itm.identifier}
                size={60}
              />
            </span>
            <IonLabel>
              {itm.identifier}
              <p>{itm.name}</p>
            </IonLabel>
          </IonItem>
        )
      })}
      <Modal
        showModal={showQrModal}
        toggle={setShowQrModal}
        title={`${identifier} میز QrCode`}
        isPlatformTheme={true}
        onClosed={() => {
          setShowQrModal(false)
          setIdentifier('')
        }}
        content={
          <div className='ion-text-center'>
            <ClubAcceptorTableQrCode
              clubCode={clubCode}
              acceptorCode={acceptorCode}
              tableIdentifier={identifier}
              size={300}
              showLink={true}
              showPrint={true}
            />
          </div>
        }
        actionButtons={<IonButton onClick={() => setShowQrModal(false)}>{'بستن'}</IonButton>}
      />
    </>
  )
}

export default ClubAcceptorTableQrCodeList
