import { IonButton, IonCol, IonGrid, IonItem, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import { ApiService, CurrencyIonInput } from '../../ayria'
import WalletBalance from '../../components/elements/WalletBalance/WalletBalance'
import { useToast } from '../../utility'

interface VoucherDepositProps {
  code: string
  acceptorCode: string
  onSuccess: Function
}

const VoucherDeposit: FC<VoucherDepositProps> = ({ code, onSuccess, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [amount, setAmount] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const isAmountInvalid = () => {
    return !amount || +amount < 15000
  }

  const purchaseVoucherForReal = async (merchantCode?: string) => {
    setInprogress(true)
    const res: any = await api.post(`/wallet/purchase/voucher`, { amount, code, acceptorCode: merchantCode })
    if (res.ok) {
      toast.success('افزایش اسپوند با موفقیت انجام شد')
      onSuccess(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  return (
    <IonGrid>
      <IonItem>
        <CurrencyIonInput onValueChange={(value: any) => setAmount(value)}></CurrencyIonInput>
      </IonItem>
      <br />
      <IonRow>
        <IonCol>
          <WalletBalance
            acceptorCode={code}
            showScore={false}
            showCredit={false}
            showBorder={false}
            balanceText={'موجودی باشگاه:'}
          />
          <div className='p-2 mt-2'>
            <div className='ion-text-center'>
              <IonButton disabled={inprogress || isAmountInvalid()} onClick={() => purchaseVoucherForReal()}>
                {'پرداخت'}
              </IonButton>
            </div>
          </div>
        </IonCol>
        <IonCol>
          <WalletBalance
            acceptorCode={acceptorCode}
            showScore={false}
            showCredit={false}
            showBorder={false}
            balanceText={'موجودی فروشگاه:'}
          />
          <div className='p-2 mt-2'>
            <div className='ion-text-center'>
              <IonButton
                disabled={inprogress || isAmountInvalid()}
                onClick={() => purchaseVoucherForReal(acceptorCode)}>
                {'پرداخت'}
              </IonButton>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default VoucherDeposit
