import React from 'react'
import { CDN_BASE_URL } from '../../config'

interface OwnProps {
  kala: any
  width?: string
}
interface KalaImageProps extends OwnProps {}

const KalaImage: React.FC<KalaImageProps> = ({ kala, width = '100px' }) => {
  return (
    <>
      {kala.photos && kala.photos.length > 0 ? (
        <>
          <img
            src={`${CDN_BASE_URL}/${kala.photos[0]}`}
            alt=''
            width={width}
            crossOrigin='anonymous'
            className={kala.outOfStock ? 'gray-effect' : ''}
          />
          {kala.outOfStock && (
            <img
              src='/assets/images/out-of-stock.png'
              width={70}
              height={70}
              style={{ position: 'absolute', top: 20, right: 10, width: '80px', height: '80px', zIndex: 2 }}
              alt=''
            />
          )}
        </>
      ) : (
        <>
          <img
            src={`https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y&s=100`}
            alt=''
            className={kala.outOfStock ? 'gray-effect' : ''}
          />
          {kala.outOfStock && (
            <img
              src='/assets/images/out-of-stock.png'
              width={70}
              height={70}
              style={{ position: 'absolute', top: 20, right: 10, width: '80px', height: '80px', zIndex: 2 }}
              alt=''
            />
          )}
        </>
      )}
    </>
  )
}

export default KalaImage
