import React, { useState, useEffect, useContext } from 'react'
import { ApiService, AcceptorContext, AyriaPagination, ITEMS_PER_PAGE, ItemCount } from '../../../ayria'
import { getSortState, PurchaseAcceptorReceipt, Modal } from '../../../ayria'
import { isDesktop } from '../../../utility'

interface PurchasesProps {
  mobile: string
}

const Purchases: React.FC<PurchasesProps> = ({ mobile }) => {
  const api = new ApiService()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [purchases, setPurchases] = useState([])
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))
  const [totalItems, setTotalItems] = useState(0)
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [purchaseId, setPurchaseId] = useState(null)

  const getPurchases = async (page: number, size: number, sort: string) => {
    const res: any = await api.get(
      `/acceptor/purchases/${acceptorContext?.current?.code}/per-customer?page=${page}&size=${size}&sort=${sort}&mobile.equals=${mobile}`
    )
    setPurchases(res.ok ? res.data : [])
    setTotalItems(res.ok ? res.headers['x-total-count'] : 0)
  }

  const sortPurchases = () =>
    getPurchases(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)

  const handlePagination = (currentPage: number) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  useEffect(() => {
    pagination.itemsPerPage = ITEMS_PER_PAGE
    pagination.sort = 'purchase.createdDate'
    pagination.order = 'desc'
  }, []) // eslint-disable-line

  useEffect(() => {
    sortPurchases()
  }, [pagination.activePage, pagination.order, pagination.sort, mobile, acceptorContext.currentIndex]) // eslint-disable-line

  useEffect(() => {
    setShowReceiptModal(purchaseId ? true : false)
  }, [purchaseId])

  return (
    <div>
      {purchases?.map((purchase: any) => {
        return (
          <div
            key={purchase.id}
            className='flex-container border-bottom'
            onClick={() => setPurchaseId(purchase.purchaseId)}>
            <span className='flex-2'>
              <p className='ellipsis m-0' style={{ width: isDesktop() ? '500px' : '200px' }}>
                <span className='io-padding-end'>{purchase.createdDatePersian}</span>
              </p>
            </span>
            <span className='flex-1 text-left'>{purchase.totalAmount.toLocaleString(navigator.language)}</span>
          </div>
        )
      })}
      <div className='row text-center'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col text-center'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
      <Modal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        onClosed={() => setPurchaseId(null)}
        title='رسید فروش'
        isPlatformTheme={true}
        content={<PurchaseAcceptorReceipt purchaseId={purchaseId} acceptorCode={acceptorContext.current.code} />}
      />
    </div>
  )
}

export default Purchases
