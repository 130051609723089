import React, { lazy } from 'react'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import 'react-dropzone-uploader/dist/styles.css'
const Dropzone = lazy(() => import('react-dropzone-uploader'))

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const text = files.length > 0 ? 'اضافه کردن فایل های بیشتر' : 'انتخاب فایل'

  return (
    <label style={{ backgroundColor: '#00B9C8', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
      {text}
      <input
        style={{ display: 'none' }}
        type='file'
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles)
          })
        }}
      />
    </label>
  )
}

const SingleUploader = ({
  onFileUploaded,
  maxFiles = 1,
  accept = 'image/*',
  identifier = Math.random() * 100000 + '',
}) => {
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject))
      })
    })
  }

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      onFileUploaded(meta, file, identifier)
    }
  }

  return (
    <Dropzone
      accept={accept}
      maxFiles={maxFiles}
      InputComponent={Input}
      onChangeStatus={handleChangeStatus}
      getFilesFromEvent={getFilesFromEvent}
    />
  )
}
export default SingleUploader
