import React, { useEffect, useState, useContext } from 'react'
import PurchaseAcceptorReceipt from '../../elements/PurchaseAcceptorReceipt/PurchaseAcceptorReceipt'
import { getSortState } from '../../../utility/jhipster'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../utility/pagination'
import ItemCount from '../../../utility/item-count'
import useDebounce from '../../../utility/use-debounce'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import NumberInput from '../../units/NumberInput/NumberInput'
import { ApiService, Modal, RateIcon } from '../../../ayria'
import { IonIcon } from '@ionic/react'
import { eyeOutline } from 'ionicons/icons'

const Rates = ({ isAcceptor }) => {
  const api = new ApiService()
  const [purchases, setPurchases] = useState([])
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [purchaseId, setPurchaseId] = useState()
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))
  const [totalItems, setTotalItems] = useState(0)
  const [searchId, setSearchId] = useState()
  const debouncedSearchId = useDebounce(searchId, 500)
  const [acceptorContext] = useContext(AcceptorContext)

  async function getPurchases(page, size, sort) {
    const res = await api.get(
      `/acceptor/purchases/${acceptorContext.current.code}/list?page=${page}&size=${size}&sort=${sort}${
        debouncedSearchId ? `&id.equals=${debouncedSearchId}` : ''
      }&rate.specified=true`
    )
    if (res.ok) {
      setPurchases(res.data)
      setTotalItems(res.headers['x-total-count'])
    }
  }

  const sortPurchases = () =>
    getPurchases(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
  }, []) // eslint-disable-line

  useEffect(() => {
    sortPurchases()
  }, [pagination.activePage, pagination.order, pagination.sort, debouncedSearchId, acceptorContext.currentIndex]) // eslint-disable-line

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <>
      <div className='col-4'>
        <NumberInput
          onValueChange={setSearchId}
          className={'form-control mb-2 text-center'}
          placeholder={'شماره رسید'}
        />
      </div>

      <div className='col-12 mt-4' style={{ overflowX: 'auto' }}>
        <table className='customTableTwo'>
          <thead>
            <tr>
              <th>نام</th>
              <th>شناسه </th>
              <th>امتیاز</th>
              <th>مبلغ کل سفارش</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {purchases.map((purchase) => {
              return (
                <tr
                  key={purchase.id}
                  onClick={(e) => {
                    setPurchaseId(purchase.id)
                    setShowReceiptModal(true)
                  }}>
                  <td>{purchase.customerName}</td>
                  <td>{purchase.id}</td>
                  <td>{purchase.rate && <RateIcon rate={purchase.rate} />}</td>
                  <td>{purchase.totalAmount.toLocaleString(navigator.language)}</td>
                  <td>
                    <IonIcon icon={eyeOutline} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {/*{purchases.map((purchase) => {*/}
      {/*  return (*/}
      {/*    <div*/}
      {/*      key={purchase.id}*/}
      {/*      className='flex-container border-bottom pointer'*/}
      {/*      onClick={(e) => {*/}
      {/*        setPurchaseId(purchase.id)*/}
      {/*        setShowReceiptModal(true)*/}
      {/*      }}>*/}
      {/*      <span className='flex-3'>*/}
      {/*        <p className='ellipsis m-0' style={{ width: '70vw' }}>*/}
      {/*          {purchase.customerName} / {purchase.totalAmount.toLocaleString(navigator.language)} / {purchase.id}*/}
      {/*          <br />*/}
      {/*          {purchase.purchaseDatePersian}*/}
      {/*        </p>*/}
      {/*      </span>*/}
      {/*      <span className='flex-1 text-left'>{purchase.rate && <RateIcon rate={purchase.rate} />}</span>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*})}*/}
      <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
        <div className='row text-center'>
          <div className='col'>
            <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
          </div>
        </div>

        <AyriaPagination
          activePage={pagination.activePage}
          onSelect={handlePagination}
          maxButtons={5}
          itemsPerPage={pagination.itemsPerPage}
          totalItems={totalItems}
        />
      </div>

      <Modal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        onClosed={() => setShowReceiptModal(false)}
        isPlatformTheme={true}
        title='رسید فروش'
        content={<PurchaseAcceptorReceipt purchaseId={purchaseId} acceptorCode={acceptorContext.current.code} />}
      />
    </>
  )
}

export default Rates
