import React, { useState, useEffect, useContext, lazy } from 'react'
import { arrowUndo, checkmark, ban } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { getSortState } from '../../../../utility/jhipster'
import api from '../../../../api/Api'
import { toGregorian } from '../../../../utility/calendar'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../../utility/pagination'
import ItemCount from '../../../../utility/item-count'
import { AcceptorContext } from '../../../structures/contexts/AcceptorContext'
import { Modal, AyriaIcon } from '../../../../ayria'
import { isDesktop, setWithTimeout } from '../../../../utility'

const AyriaPayment = lazy(() => import('../../../elements/AyriaPayment/AyriaPayment'))

const WalletRequests = ({ acceptorCode }) => {
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [toDate] = useState(null)
  const [item, setItem] = useState({ amount: 0 })
  const [fromDate] = useState(null)
  const [totalItems, setTotalItems] = useState(0)
  const [acceptorContext] = useContext(AcceptorContext)
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))

  const fetchItems = async (page, size, sort) => {
    try {
      const res = await api.get(
        `/user/ayria-payments/payee-requests/${isNaN(acceptorCode) ? '0' : acceptorCode}${
          sort ? `?page=${page}&size=${size}&sort=${sort}` : ''
        }${fromDate ? `&fromDate=${toGregorian(fromDate)}` : ''}${toDate ? `&toDate=${toGregorian(toDate)}` : ''}`
      )
      if (res.ok) {
        setWithTimeout(setItems, res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
  }

  const getAllItems = () =>
    fetchItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllItems()

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [pagination.activePage, pagination.order, pagination.sort, acceptorContext.currentIndex])

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <>
      {isDesktop() ? (
        <div>
          <div className='col-12 mt-4' style={{ overflowX: 'auto' }}>
            <table className='customTableTwo'>
              <thead>
                <tr>
                  <th>توضیحات/تلفن</th>
                  <th>تاریخ</th>
                  <th>مبلغ</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => {
                  return (
                    <tr
                      key={item.createdDate + '-' + index}
                      onClick={(e) => {
                        setItem(item)
                        setShowReceiptModal(true)
                      }}>
                      <td>
                        {' '}
                        {item.payerMobile} {item.description}
                      </td>
                      <td>{item.createdDate}</td>
                      <td>{item.amount.toLocaleString(navigator.language)}</td>
                      <td>
                        {item.paid && !item.refunded && (
                          <span className='p-1'>
                            <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                          </span>
                        )}
                        {item.paid && item.refunded && (
                          <span className='p-1'>
                            <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />
                          </span>
                        )}
                        {item.canceled && (
                          <span className='p-1'>
                            <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {/*{items.map((item, index) => {*/}
          {/*  return (*/}
          {/*    <div*/}
          {/*      key={item.createdDate + '-' + index}*/}
          {/*      className='flex-container border-bottom pointer'*/}
          {/*      onClick={(e) => {*/}
          {/*        setItem(item)*/}
          {/*        setShowReceiptModal(true)*/}
          {/*      }}>*/}
          {/*      <span className='flex-2'>*/}
          {/*        <p className='ellipsis m-0' style={{ width: isDesktop() ? '450px' : '190px' }}>*/}
          {/*          {item.payerMobile} {item.description}*/}
          {/*          <br />*/}
          {/*          {item.createdDate} {item.trackingNumber}*/}
          {/*        </p>*/}
          {/*      </span>*/}
          {/*      <span className='flex-1 text-left'>*/}
          {/*        <span>{item.amount.toLocaleString(navigator.language)}</span>*/}
          {/*        <span style={{ width: '60px', display: 'inline-block' }} className='text-left'>*/}
          {/*          {item.paid && !item.refunded && (*/}
          {/*            <span className='p-1'>*/}
          {/*              <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {item.paid && item.refunded && (*/}
          {/*            <span className='p-1'>*/}
          {/*              <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*          {item.canceled && (*/}
          {/*            <span className='p-1'>*/}
          {/*              <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />*/}
          {/*            </span>*/}
          {/*          )}*/}
          {/*        </span>*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  )*/}
          {/*})}*/}
          {/*<div className='row text-center'>*/}
          {/*  <div className='col'>*/}
          {/*    <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='row text-center'>*/}
          {/*  <div className='col'></div>*/}
          {/*  <div className='col' align='center'>*/}
          {/*    <AyriaPagination*/}
          {/*      activePage={pagination.activePage}*/}
          {/*      onSelect={handlePagination}*/}
          {/*      maxButtons={5}*/}
          {/*      itemsPerPage={pagination.itemsPerPage}*/}
          {/*      totalItems={totalItems}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className='col'></div>*/}
          {/*</div>*/}

          <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
            <div className='row text-center'>
              <div className='col'>
                <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
              </div>
            </div>

            <AyriaPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={totalItems}
            />
          </div>

          <Modal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title={(!acceptorCode ? 'درخواست وجه' : 'صورتحساب') + ' ' + item.trackingNumber}
            isPlatformTheme={!!acceptorCode}
            onClosed={() => setShowReceiptModal(false)}
            id='ayriaPaymentModalAcceptor'
            content={
              <AyriaPayment
                payment={item}
                acceptorCode={acceptorCode}
                onSuccess={() => {
                  setShowReceiptModal(false)
                  sortEvents()
                }}
                showCashierActions={acceptorCode ? true : false}
              />
            }
          />
        </div>
      ) : (
        <div>
          {items.map((item, index) => {
            return (
              <div
                key={item.createdDate + '-' + index}
                className='flex-container border-bottom pointer'
                onClick={(e) => {
                  setItem(item)
                  setShowReceiptModal(true)
                }}>
                <span className='flex-2'>
                  <p className='ellipsis m-0' style={{ width: isDesktop() ? '450px' : '190px' }}>
                    {item.payerMobile} {item.description}
                    <br />
                    {item.createdDate} {item.trackingNumber}
                  </p>
                </span>
                <span className='flex-1 text-left'>
                  <span>{item.amount.toLocaleString(navigator.language)}</span>
                  <span style={{ width: '60px', display: 'inline-block' }} className='text-left'>
                    {item.paid && !item.refunded && (
                      <span className='p-1'>
                        <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                      </span>
                    )}
                    {item.paid && item.refunded && (
                      <span className='p-1'>
                        <AyriaIcon icon={arrowUndo} title={t('walletRequest.refunded')} />
                      </span>
                    )}
                    {item.canceled && (
                      <span className='p-1'>
                        <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                      </span>
                    )}
                  </span>
                </span>
              </div>
            )
          })}
          <div className='row text-center'>
            <div className='col'>
              <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
            </div>
          </div>
          <div className='row text-center'>
            <div className='col'></div>
            <div className='col' align='center'>
              <AyriaPagination
                activePage={pagination.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
            <div className='col'></div>
          </div>
          <Modal
            showModal={showReceiptModal}
            toggle={setShowReceiptModal}
            title={(!acceptorCode ? 'درخواست وجه' : 'صورتحساب') + ' ' + item.trackingNumber}
            isPlatformTheme={!!acceptorCode}
            onClosed={() => setShowReceiptModal(false)}
            id='ayriaPaymentModalAcceptor'
            content={
              <AyriaPayment
                payment={item}
                acceptorCode={acceptorCode}
                onSuccess={() => {
                  setShowReceiptModal(false)
                  sortEvents()
                }}
                showCashierActions={acceptorCode ? true : false}
              />
            }
          />
        </div>
      )}
    </>
  )
}

export default WalletRequests
