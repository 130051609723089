import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import star1 from './icons/star.png'
import star1_gray from './icons/star_gray.png'
import star2 from './icons/star2.png'
import star2_gray from './icons/star2_gray.png'
import star3 from './icons/star3.png'
import star3_gray from './icons/star3_gray.png'

interface QualityRateInputProps {
  onRateChange(rate: number): () => {}
}

const QualityRateInput: React.FC<QualityRateInputProps> = ({ onRateChange }) => {
  const [rate, setRate] = useState<number>()
  const { t } = useTranslation()

  const updateRate = (rate: number) => {
    setRate(rate)
    onRateChange(rate)
  }

  return (
    <>
      <div className='p-2'>{t('qualityRateInput.quality')}:</div>
      <div className='flex-container' style={{ justifyContent: 'center' }}>
        <span className='p-2 ion-text-center'>
          {rate !== 3 && (
            <img
              alt='3'
              src={star3_gray}
              className='rating-icon'
              onClick={(e) => updateRate(3)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 3 && (
            <img alt='3' src={star3} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span className={rate === 3 ? 'text-success' : ''} onClick={(e) => updateRate(3)}>
            {t('qualityRateInput.star3')}
          </span>
        </span>
        <span className='p-2 ion-text-center'>
          {rate !== 2 && (
            <img
              alt='2'
              src={star2_gray}
              className='rating-icon'
              onClick={(e) => updateRate(2)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 2 && (
            <img alt='2' src={star2} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span className={rate === 2 ? 'text-dark' : ''} onClick={(e) => updateRate(2)}>
            {t('qualityRateInput.star2')}
          </span>
        </span>
        <span className='p-2 ion-text-center'>
          {rate !== 1 && (
            <img
              alt='1'
              src={star1_gray}
              className='rating-icon'
              onClick={(e) => updateRate(1)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 1 && (
            <img alt='1' src={star1} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span className={rate === 1 ? 'text-danger' : ''} onClick={(e) => updateRate(1)}>
            {t('qualityRateInput.star1')}
          </span>
        </span>
      </div>
    </>
  )
}

export default QualityRateInput
