import React, { useState, useContext, useEffect } from 'react'
import { IonBadge, IonIcon } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { bagAddOutline, contractOutline, duplicateOutline, logoInstagram } from 'ionicons/icons'
import { pricetagsOutline, settings, storefrontOutline } from 'ionicons/icons'
import { Modal, Factors, AcceptorContext, setOnlineOrderCount, ApiService } from '../../../ayria'
import { connect, KalaDisplayOrder, useToast } from '../../../ayria'
import AcceptorSetting from '../../elements/acceptor/AcceptorSetting'
import { Definitions } from '../'
import './online-shop-service.scss'

interface OwnProps {
  acceptorCode?: string
}
interface StateProps {
  onlineOrderCount: any
}
interface DispatchProps {
  setOnlineOrderCount: typeof setOnlineOrderCount
}
interface OnlineShopServiceProps extends OwnProps, StateProps, DispatchProps {}

const OnlineShopService: React.FC<OnlineShopServiceProps> = ({
  acceptorCode,
  onlineOrderCount,
  setOnlineOrderCount,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [showWalletRequestsModal, setShowWalletRequestsModal] = useState(false)
  const [showDefinitionsModal, setShowDefinitionsModal] = useState(false)
  const [showDefinitionsModal2, setShowDefinitionsModal2] = useState(false)
  const [showKalaGroupReorder, setShowKalaGroupReorder] = useState(false)
  const [showAcceptorSetting, setShowAcceptorSetting] = useState(false)

  const getOnlineOrderCount = async () => {
    const res: any = await api.acceptorRolesFactorsOnlineOrderCount(acceptorContext?.current?.code)
    setOnlineOrderCount(res.ok ? res.data : onlineOrderCount)
  }

  useEffect(() => {
    if (!!acceptorContext?.current?.code) {
      getOnlineOrderCount()
    }
  }, [acceptorContext?.current?.code]) // eslint-disable-line

  return (
    <div className='text-center'>
      <div className={'col-md-12 payment-service-row pointer'} onClick={() => setShowWalletRequestsModal(true)}>
        <IonIcon size='large' icon={bagAddOutline}></IonIcon>
        {!!onlineOrderCount?.count && (
          <IonBadge className='badge-fix-position' color='danger' style={{ marginRight: '0' }}>
            {onlineOrderCount?.count}
          </IonBadge>
        )}
        <div className={'m-2'}>{' سفارشات'}</div>
      </div>

      <div className={'display-inline mt-2'}>
        <div className={'col-md-4 payment-service-row pointer'} onClick={() => setShowDefinitionsModal(true)}>
          <IonIcon size='large' icon={pricetagsOutline} className='pointer-events-none'></IonIcon>
          <div className={'m-2'}>{'مدیریت کالا و خدمات'}</div>
        </div>
        <div className={'col-md-4 payment-service-row mr-2 pointer'} onClick={() => setShowKalaGroupReorder(true)}>
          <IonIcon size='large' icon={duplicateOutline} className='pointer-events-none'></IonIcon>
          <div className={'m-2'}>{'دسته بندی'}</div>
        </div>
        <div className={'col-md-4 payment-service-row mr-2 pointer'} onClick={() => setShowDefinitionsModal2(true)}>
          <IonIcon size='large' icon={contractOutline} className='pointer-events-none'></IonIcon>
          <div className={'m-2 '}>{'کد تخفیف'}</div>
        </div>
      </div>
      <div className={'display-inline mt-2'}>
        <div
          className={'col-md-4 payment-service-row pointer'}
          onClick={() => history.push(`/tabs/center/${acceptorContext?.current?.code}`)}>
          <IonIcon size='large' icon={storefrontOutline} className='pointer-events-none'></IonIcon>
          <div className={'m-2 '}>{'مشاهده فروشگاه'}</div>
        </div>
        <div
          className={'col-md-4 payment-service-row mr-2'}
          onClick={() => toast.warning('این امکان در حال پیاده سازی می‌باشد')}>
          <IonIcon size='large' icon={logoInstagram} className='pointer-events-none'></IonIcon>
          <div className={'m-2 '}>{'اینستاگرام'}</div>
        </div>
        <div className={'col-md-4 payment-service-row mr-2 pointer'} onClick={() => history.push('/panel-settings')}>
          <IonIcon size='large' icon={settings} className='pointer-events-none'></IonIcon>
          <div className={'m-2 '}>{'تنظیمات'}</div>
        </div>
      </div>
      <Modal
        showModal={showWalletRequestsModal}
        toggle={setShowWalletRequestsModal}
        title={'سفارشات'}
        isPlatformTheme={true}
        id={'factors-modal'}
        onClosed={() => setShowWalletRequestsModal(false)}
        content={<Factors acceptor={acceptorContext.current} onlineOrderOnly={true} />}
      />
      <Modal
        showModal={showDefinitionsModal}
        toggle={setShowDefinitionsModal}
        title={'مدیریت کالا و خدمات'}
        isPlatformTheme={true}
        id='definitions-modal'
        onClosed={() => setShowDefinitionsModal(false)}
        content={<Definitions isUsers={false} />}
      />
      <Modal
        showModal={showDefinitionsModal2}
        toggle={setShowDefinitionsModal2}
        title={'تعاریف'}
        isPlatformTheme={true}
        id='definitions-modal'
        onClosed={() => setShowDefinitionsModal2(false)}
        content={<Definitions isUsers={true} />}
      />
      <Modal
        showModal={showKalaGroupReorder}
        toggle={setShowKalaGroupReorder}
        title={'دسته بندی'}
        id='kala-group-reorder-modal'
        isPlatformTheme={true}
        onClosed={() => setShowKalaGroupReorder(false)}
        content={<KalaDisplayOrder acceptorCode={acceptorContext.current.code} />}
      />
      <Modal
        showModal={showAcceptorSetting}
        toggle={setShowAcceptorSetting}
        title={'تنظیمات'}
        isPlatformTheme={true}
        onClosed={() => setShowAcceptorSetting(false)}
        content={<AcceptorSetting />}
      />
    </div>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    onlineOrderCount: state.shop.onlineOrderCount,
  }),
  mapDispatchToProps: {
    setOnlineOrderCount,
  },
  component: OnlineShopService,
})
