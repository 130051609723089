import React, { useState, useEffect } from 'react'
import { IonSpinner, IonItem, IonLabel, IonList, IonItemDivider } from '@ionic/react'
import { IonInput, IonIcon, IonButton, IonTextarea } from '@ionic/react'
import { add, trash } from 'ionicons/icons'
import { MobileIonInput, CurrencyIonInput, useToast, ApiService } from '../../ayria'

interface OwnProps {
  templateId?: number
  onSuccess?: Function
  acceptorCode?: string
}
interface GroupPaymentRequestFormProps extends OwnProps {}

const GroupPaymentRequestForm: React.FC<GroupPaymentRequestFormProps> = ({ templateId, onSuccess, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [name, setName] = useState<string>()
  const [mobile, setMobile] = useState<string>()
  const [mobiles, setMobiles] = useState([])
  const [amounts, setAmounts] = useState({})
  const [description, setDescription] = useState()
  const [saveInprogress, setSaveInprogress] = useState(false)

  const getTemplate = async () => {
    setInprogress(true)
    const res: any = await api.get(
      `/${!!acceptorCode ? 'acceptor' : 'customer'}/group-payments/templates/${templateId}${
        !!acceptorCode ? `/${acceptorCode}` : ''
      }`
    )
    if (res.ok) {
      setName(res.data.name)
      setDescription(res.data.description)
      setMobiles(res.data.members.map((m) => m.mobile))
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const store = async () => {
    setSaveInprogress(true)
    const res: any = await api.post(
      `/${!!acceptorCode ? 'acceptor' : 'customer'}/group-payments/request${!!acceptorCode ? `/${acceptorCode}` : ''}`,
      {
        id: templateId,
        name,
        description,
        members: mobiles.map((m) => ({ mobile: m, amount: amounts[m] })),
      }
    )
    if (res.ok) {
      toast.success(
        hasAnyAmount()
          ? 'درخواست وجه گروهی با موفقیت بروزرسانی و ارسال شد'
          : 'درخواست وجه گروهی با موفقیت بروز رسانی شد'
      )
      if (onSuccess) {
        onSuccess()
      }
    } else {
      toast.error(res)
    }
    setSaveInprogress(false)
  }

  const addMobile = () => {
    const tSet = new Set()
    mobiles.map((m) => tSet.add(m))
    tSet.add(mobile)
    const tArr = []
    tSet.forEach((m) => tArr.push(m))
    setMobiles(tArr)
    setMobile('')
  }
  const addAmount = (amount, mobile) => {
    setAmounts({ ...amounts, [mobile]: amount })
  }
  const removeMobile = (mobile: string) => {
    setMobiles(mobiles.filter((m) => m !== mobile))
  }
  const hasAnyAmount: boolean = () => {
    return mobiles.filter((m) => !!amounts[m]).length !== 0
  }

  useEffect(() => {
    if (!!templateId) {
      getTemplate()
    }
  }, [templateId]) // eslint-disable-line

  return (
    <IonList>
      <IonItem>
        <IonInput
          value={name}
          onIonChange={(e) => setName(e.detail.value)}
          placeholder='نام گروه'
          disabled={saveInprogress}
        />
        {inprogress && <IonSpinner slot='end' />}
      </IonItem>
      <IonItemDivider>
        <IonLabel>{'لیست مخاطبین'}</IonLabel>
      </IonItemDivider>
      {mobiles.map((m) => (
        <div key={m}>
          <IonItem lines='none'>
            <IonLabel color='dark' slot='end'>
              {m}
            </IonLabel>
            <IonButton
              slot='end'
              color='danger'
              fill='clear'
              size='small'
              onClick={() => removeMobile(m)}
              disabled={saveInprogress}>
              <IonIcon icon={trash} slot='icon-only' />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel position='stacked' color={!!amounts[m] ? 'dark' : 'danger'}>
              {'مبلغ درخواستی(ریال)'}
            </IonLabel>
            <CurrencyIonInput
              onValueChange={(value) => addAmount(value, m)}
              placeholder='مبلغ درخواستی به ریال'
              disabled={saveInprogress}
            />
          </IonItem>
        </div>
      ))}
      <IonItem>
        <MobileIonInput
          value={mobile}
          onValueChange={(value) => setMobile(value)}
          disabled={saveInprogress}
          placeholder='شماره همراه مخاطب جدید'
        />
        {mobile?.length === 11 && (
          <IonButton color='primary' slot='start' onClick={addMobile} disabled={saveInprogress}>
            <IonIcon icon={add} />
          </IonButton>
        )}
      </IonItem>
      {mobiles.length > 0 && (
        <>
          {hasAnyAmount() && (
            <IonItem>
              <IonTextarea
                onIonChange={(e) => setDescription(e.detail.value)}
                value={description}
                placeholder={'توضیحات'}
                disabled={saveInprogress}></IonTextarea>
            </IonItem>
          )}
          <IonButton onClick={store} disabled={saveInprogress} className='ion-padding-start ion-margin-top'>
            {saveInprogress && <IonSpinner slot='start' />}
            {hasAnyAmount() ? 'ذخیره و ارسال' : 'ذخیره'}
          </IonButton>
        </>
      )}
    </IonList>
  )
}

export default GroupPaymentRequestForm
