import React, { useState } from 'react';

const AcceptorContext = React.createContext([{}, () => {}])

const AcceptorProvider = (props) => {
  const [state, setState] = useState({})
  return (
    <AcceptorContext.Provider value={[state, setState]}>
      {props.children}
    </AcceptorContext.Provider>
  )
}

export { AcceptorContext, AcceptorProvider };
