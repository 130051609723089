import React, { useState } from 'react'
import { shareSocial } from 'ionicons/icons'
import { IonLabel, IonItem, IonButton, IonSpinner } from '@ionic/react'
import { IonCard, IonCardSubtitle, IonCardContent } from '@ionic/react'
import { IonIcon, IonFooter, IonToolbar, IonButtons, IonTextarea } from '@ionic/react'
import { WalletRequests } from '../../../components/screens'
import { useTranslation } from 'react-i18next'
import { IPG_MIN_ACCEPTED_AMOUNT, EventEmitter, ApiService, connect } from '../../../ayria'
import { CurrencyInput } from '../../../ayria'
import { useToast, MobileSuggest } from '../../../ayria'
import './WalletPayeeRequest.scss'

interface OwnProps {
  onSuccess: () => void
  showPreviousRequests?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface WalletPayeeRequestPersonalProps extends OwnProps, StateProps, DispatchProps {}

const WalletPayeeRequestPersonal: React.FC<WalletPayeeRequestPersonalProps> = ({
  onSuccess,
  showPreviousRequests = true,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const [amount, setAmount] = useState<any>(undefined)
  const [receiver, setReceiver] = useState<any>()
  const [inprogress, setInprogress] = useState(false)
  const [description, setDescription] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [noSmsProp, setNoSmsProp] = useState(false)

  const handlePayeeRequestForReal = async (noSms?: boolean) => {
    setNoSmsProp(noSms)
    if (+amount < IPG_MIN_ACCEPTED_AMOUNT) {
      toast.error(
        t('amount_should_be_at_least_x', { x: IPG_MIN_ACCEPTED_AMOUNT.toLocaleString(navigator.language) + ' ریال' })
      )
      return
    }
    if (!mobileNumber) {
      toast.error(t('NotEmpty', { field: t('payerMobile') }))
      return
    }
    setInprogress(true)
    const res: any = await api.post(`/user/ayria-payments/payee-request`, {
      amount,
      payerMobile: mobileNumber,
      description,
      paymentMethod: 'WALLET',
      noSms,
    })
    if (res.ok) {
      toast.success(`در خواست وجه ${res.data.trackingNumber} با موفقیت ایجاد شد`)
      EventEmitter.dispatch('NEW_PAYEE_REQUEST', res.data)
      onSuccess(res.data, noSms)
    } else {
      toast.error(res)
      setInprogress(false)
    }
  }
  const isFormValid = () => {
    return !!mobileNumber && !!amount
  }

  return (
    <div className='text-start' id='WalletPayeeRequest'>
      <div className={'mb-3'}>
        <div className='form-group'>
          <label>
            مبلغ مورد نظر شما برای دریافت وجه :<strong>{(+amount || 0).toLocaleString(navigator.language)}</strong>{' '}
            {t('label.riyal')}{' '}
          </label>
          <CurrencyInput onValueChange={setAmount} defaultValue={amount} disabled={inprogress} />
        </div>
        <div className='form-group'>
          <label htmlFor='mobile'>{t('label.mobile')}</label>
          <MobileSuggest
            onMobileChange={setMobileNumber}
            onUserChange={setReceiver}
            disabled={inprogress}
            suggestType={'WALLET_PAYEE_REQUEST'}
          />
        </div>
        {receiver && (
          <div>
            <div className='from-group'>
              <label>
                نام و نام خانوادگی: {receiver.firstName} {receiver.lastName}
              </label>
            </div>
          </div>
        )}
        <IonItem>
          <IonLabel position='floating'>{'توضیحات'}</IonLabel>
          <IonTextarea id='desc' onIonChange={(e) => setDescription(e.detail.value)} disabled={inprogress} />
        </IonItem>
      </div>
      <IonFooter>
        <IonToolbar>
          <IonButtons>
            <IonButton
              slot='primary'
              fill='solid'
              size='large'
              color='success'
              onClick={() => handlePayeeRequestForReal(false)}
              disabled={inprogress || !isFormValid()}>
              {' ارسال با پیامک '}
              {!noSmsProp && inprogress && <IonSpinner slot='start' />}
            </IonButton>
            <IonButton
              slot='secondary'
              fill='solid'
              size='large'
              color='primary'
              onClick={() => handlePayeeRequestForReal(true)}
              disabled={inprogress || !isFormValid()}>
              {!inprogress && <IonIcon icon={shareSocial} slot='start' />}
              {'اشتراک گذاری'}
              {!!noSmsProp && inprogress && <IonSpinner slot='start' />}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      {showPreviousRequests && (
        <IonCard style={{ marginLeft: 0, marginRight: 0 }}>
          <IonCardSubtitle>{'درخواست‌های قبل:'}</IonCardSubtitle>
          <IonCardContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <WalletRequests />
          </IonCardContent>
        </IonCard>
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: WalletPayeeRequestPersonal,
})
