import React, { useState, useEffect, useContext } from 'react'
import { IonButton } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { ApiService, AcceptorContext, PurchaseAcceptorReceipt } from '../../../ayria'
import { CustomModal } from '../../../ayria'

interface SellDetailDailyProps {
  acceptorCode: string
  selectedDay: string
}

const SellDetailDaily: React.FC<SellDetailDailyProps> = ({ acceptorCode, selectedDay }) => {
  const api = new ApiService()
  const history = useHistory()
  const [data, setData] = useState<any>([])
  const [total, setTotal] = useState<number>(0)
  const [totalPos, setTotalPos] = useState<number>(0)
  const [totalCash, setTotalCash] = useState<number>(0)
  const [totalWallet, setTotalWallet] = useState<number>(0)
  const [totalReduction, setTotalReduction] = useState<number>(0)
  const [showReceiptModal, setShowReceiptModal] = useState<boolean>(false)
  const [purchaseId, setPurchaseId] = useState<number>()
  const [acceptorContext] = useContext<any>(AcceptorContext)

  const fetchData = async () => {
    const res: any = await api.get(`/acceptor/purchases/${acceptorCode}/in/${selectedDay}`)
    if (res.ok) {
      setData(res.data)
      setTotal(res.data.reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0))
      setTotalReduction(res.data.reduce((a: number, b: any) => +a + +b.reductionAmount, 0))
      setTotalPos(
        res.data
          .filter((item: any) => item.purchaseType === 'POS')
          .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
      )
      setTotalCash(
        res.data
          .filter((item: any) => item.purchaseType === 'CASH')
          .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
      )
      setTotalWallet(
        res.data
          .filter((item: any) => item.purchaseType === 'WALLET')
          .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
      )
    }
  }

  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line

  return (
    <>
      {data && (
        <div>
          {data.map((element: any, index: number) => (
            <div
              key={index}
              className='flex-container border-bottom pb-2 pointer'
              onClick={(e) => {
                setPurchaseId(element.id)
                setShowReceiptModal(true)
              }}>
              <span>
                {element.customerName}
                {element.customerName === 'کاربر ثبت نشده' ? (
                  <span dir='ltr' className='ion-padding'>
                    {' '}
                    ({element.customerMobile}){' '}
                  </span>
                ) : (
                  ''
                )}
              </span>
              <span>{element.acceptorAmountActual.toLocaleString(navigator.language)}</span>
            </div>
          ))}
          <div className='flex-container border-top'>
            <span>{'جمع تخفیف'}</span>
            <span>{totalReduction.toLocaleString(navigator.language)}</span>
          </div>
          <div className='flex-container border-top'>
            <span>{'جمع کارتخوان'}</span>
            <strong className='text-success'>{totalPos.toLocaleString(navigator.language)}</strong>
          </div>
          <div className='flex-container border-top'>
            <span>{'جمع نقدی'}</span>
            <strong className='text-success'>{totalCash.toLocaleString(navigator.language)}</strong>
          </div>
          <div className='flex-container border-top'>
            <span>{'جمع کیف پول'}</span>
            <strong className='text-success'>{totalWallet.toLocaleString(navigator.language)}</strong>
          </div>
          <div className='flex-container border-top'>
            <span>{'جمع کل'}</span>
            <strong className='text-success'>{total.toLocaleString(navigator.language)}</strong>
          </div>
          <div className='border-top text-center'>
            <IonButton onClick={() => history.push(`/cashdesk/${acceptorCode}/${selectedDay}`)}>
              چاپ گزارش صندوق
            </IonButton>
          </div>
        </div>
      )}
      <CustomModal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        title='رسید خرید'
        content={<PurchaseAcceptorReceipt purchaseId={purchaseId} acceptorCode={acceptorContext.current.code} />}
      />
    </>
  )
}

export default SellDetailDaily
