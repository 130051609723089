import React, { FC, useState } from 'react'
import { IonButton, IonFooter, IonItem, IonLabel } from '@ionic/react'
import { IonListHeader, IonRadio, IonRadioGroup, IonSelect, IonSelectOption } from '@ionic/react'
import { ApiService, CurrencyIonInput, NumberIonInput } from '../../ayria'
import { useToast } from '../../utility'
import { useEffect } from 'react'
import ClubGroupSelect from './ClubGroupSelect'

interface MyClubSpecEditProps {
  code: string
  onSuccess?: Function
  onCancel: Function
  clubSpecId?: number
}

const MyClubSpecEdit: FC<MyClubSpecEditProps> = ({ code, onSuccess, onCancel, clubSpecId }) => {
  const api = new ApiService()
  const toast = useToast()
  const [specType, setSpecType] = useState<string>('MEMBERSHIP')
  const [rewardType, setRewardType] = useState<string>('SCORE')
  const [amount, setAmount] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [disableScore, setDisableScore] = useState(false)
  const [disableVoucher, setDisableVoucher] = useState(false)
  const [groupId, setGroupId] = useState<any>()

  const createNewSpec = async () => {
    setInprogress(true)
    const res: any = await api.post('/club-spec/create', { specType, rewardType, amount, clubCode: code, groupId })
    if (res.ok) {
      toast.success('خصوصیت با موفقیت ایجاد شد')
      if (onSuccess) {
        onSuccess(true)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const updateNewSpec = async () => {
    setInprogress(true)
    const res: any = await api.put(`/club-spec/update`, { id: clubSpecId, amount, clubCode: code })
    if (res.ok) {
      toast.success('خصوصیت با موفقیت بروزرسانی شد')
      if (!!onSuccess) {
        onSuccess(true)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const fetchSpec = async () => {
    setInprogress(true)
    const res: any = await api.get(`/club-spec/get/${code}/${clubSpecId}`)
    if (res.ok) {
      setAmount(res.data.amount)
      setSpecType(res.data.specType)
      setRewardType(res.data.rewardType)
      setGroupId(res.data.group?.id)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    if (!clubSpecId) {
      if (specType === 'MEMBERSHIP') {
        setDisableScore(false)
        setDisableVoucher(true)
        setRewardType('SCORE')
        setGroupId(null)
      } else if (specType === 'MONTHLY') {
        setDisableScore(true)
        setDisableVoucher(false)
        setRewardType('VOUCHER')
      } else {
        setDisableScore(true)
        setDisableVoucher(true)
        setRewardType('')
      }
    }
  }, [specType]) // eslint-disable-line

  useEffect(() => {
    if (!!clubSpecId) {
      fetchSpec()
    }
  }, [clubSpecId]) // eslint-disable-line

  return (
    <>
      <IonItem>
        <IonLabel>{'رخداد'}</IonLabel>
        <IonSelect
          disabled={!!clubSpecId}
          value={specType}
          onIonChange={(e) => setSpecType(e.detail.value)}
          okText='انتخاب'
          cancelText='انصراف'>
          <IonSelectOption value='MEMBERSHIP'>{'عضویت'}</IonSelectOption>
          <IonSelectOption value='MONTHLY'>{'ماهانه'}</IonSelectOption>
          <IonSelectOption value='BIRTHDAY' disabled={true}>
            {'تولد'}
          </IonSelectOption>
          <IonSelectOption value='INVITE_FRIEND' disabled={true}>
            {'دعوت دوستان'}
          </IonSelectOption>
          <IonSelectOption value='NEW_PURCHASE' disabled={true}>
            {'خرید'}
          </IonSelectOption>
        </IonSelect>
      </IonItem>
      {!inprogress && specType !== 'MEMBERSHIP' && (
        <ClubGroupSelect clubCode={code} onValueChange={(value: any) => setGroupId(value)} groupId={groupId} />
      )}
      <IonRadioGroup value={rewardType} onIonChange={(e) => setRewardType(e.detail.value)}>
        <IonListHeader>
          <IonLabel>{'نوع پاداش'}</IonLabel>
        </IonListHeader>
        <IonItem>
          <IonLabel>{'امتیاز'}</IonLabel>
          <IonRadio disabled={!!clubSpecId || disableScore} value='SCORE'></IonRadio>
        </IonItem>
        <IonItem>
          <IonLabel>{'اسپوند'}</IonLabel>
          <IonRadio disabled={!!clubSpecId || disableVoucher} value='VOUCHER'></IonRadio>
        </IonItem>
      </IonRadioGroup>
      {rewardType === 'SCORE' && (
        <IonItem>
          <IonLabel>{'مقدار'}</IonLabel>
          <NumberIonInput value={amount} min={1} onValueChange={(value: any) => setAmount(value)} />
        </IonItem>
      )}
      {rewardType === 'VOUCHER' && (
        <IonItem>
          <IonLabel>{'مقدار'}</IonLabel>
          <CurrencyIonInput defaultValue={amount} onValueChange={(value: any) => setAmount(value)}></CurrencyIonInput>
        </IonItem>
      )}
      <IonFooter style={{ position: 'absolute', bottom: 0 }}>
        {!clubSpecId && (
          <IonButton onClick={createNewSpec} disabled={inprogress}>
            {'ایجاد'}
          </IonButton>
        )}
        {!!clubSpecId && (
          <IonButton onClick={updateNewSpec} disabled={inprogress}>
            {'ویرایش'}
          </IonButton>
        )}
        <IonButton color='light' onClick={() => onCancel()}>
          {'انصراف'}
        </IonButton>
      </IonFooter>
    </>
  )
}

export default MyClubSpecEdit
