import { create } from 'apisauce'
import { API_BASE_URL } from '../config'

import { getAccessToken } from '../utility/auth/TokenTools'
import Endpoints from './Url'
import fn from './ApiFunctions'

// define the api
const api = create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: [{ Accept: 'application/json' }],
})

api.addRequestTransform((request) => {
  const accessToken = getAccessToken()
  // const isTokenStillValid = isTokenNotExpired();
  // TODO: IF tokenIsExpired and RequestNewToken
  // expiry grace time is still valid (need implementation)
  // THEN request new Token from AUTH Module - store it and proceed.

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  } else if (accessToken) {
    // TODO: need to AWAIT current response until renewToken return succesfully
    // or cancel with Error if it return with a problem/failure
    // auth.renewToken();
  } else {
    request.headers.Authorization = null
    request.headers['X-Secure'] = false
  }
})

api.route = Endpoints
api.fn = fn

export default api
