import React, { useState, useEffect, useContext } from 'react'
import { IonIcon, IonButton } from '@ionic/react'
import { sync, add, returnUpBack } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CustomModal, Modal } from '../../../ayria'
import WalletDeposit from '../../elements/WalletDeposit/WalletDeposit'
import WalletTransfer from '../../elements/WalletTransfer/WalletTransfer'
import WalletCashout from '../../elements/WalletCashout/WalletCashout'
import WalletCashoutVip from '../../elements/WalletCashout/WalletCashoutVip'
import WalletReceipt from '../../elements/WalletReceipt/WalletReceipt'
import { ApiService, AyriaIcon } from '../../../ayria'
import { WalletContext } from '../../structures/contexts/WalletContext'
import IdentityVerifier from '../../elements/IdentityVerifier/IdentityVerifier'
import Balance from '../../units/Balance'

const Wallet = ({ acceptorCode, type = 'normal' }) => {
  const api = new ApiService()
  const history = useHistory()
  const { t } = useTranslation()
  const [balance, setBalance] = useState()
  const [walletContext, setWalletContext] = useContext(WalletContext) // eslint-disable-line
  const [inprogress, setInprogress] = useState(false)
  const [callbackFunction, setCallbackFunction] = useState()
  const [walletEvent, setWalletEvent] = useState()
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [showWalletTransferModal, setShowWalletTransferModal] = useState(false)
  const [showWalletCashoutModal, setShowWalletCashoutModal] = useState(false)
  const [showWalletCashoutVipModal, setShowWalletCashoutVipModal] = useState(false)
  const [showIdentityVerifierModal, setShowIdentityVerifierModal] = useState(false)
  const [showWalletReceiptModal, setShowWalletReceiptModal] = useState(false)

  const getBalance = async () => {
    setInprogress(true)
    if (type === 'acceptor' && !acceptorCode) {
      setInprogress(false)
      return
    }
    const res = await api.post('/user/wallet-snapshots/current', { acceptorCode: acceptorCode })
    if (res.ok) {
      setBalance(res.data.balance)
      if (!acceptorCode) {
        setWalletContext((state) => ({ ...state, balance: res.data.balance }))
      }
    }
    setInprogress(false)
  }
  const onSuccessWalletTransfer = async (walletEventRes) => {
    setWalletEvent(walletEventRes)
    setShowWalletTransferModal(false)
    setTimeout(() => {
      setShowWalletReceiptModal(true)
    }, 300)
    getBalance()
  }
  const onSuccessWalletCashout = async () => {
    setShowWalletCashoutModal(false)
    setShowWalletCashoutVipModal(false)
    getBalance()
  }
  const doShowIdentityVerifierModal = (callbackFunction) => {
    setCallbackFunction(callbackFunction)
    setShowIdentityVerifierModal(true)
  }
  const funcMap = {}
  const hideShowIdentityVerifierModal = () => {
    setShowIdentityVerifierModal(false)
    if (callbackFunction) {
      funcMap[callbackFunction](true)
    }
  }
  const goToWalletReport = () => {
    if (!!acceptorCode) {
      if (type === 'acceptor') {
        history.push('/wallet-report-acceptor')
      } else if (type === 'club') {
        history.push('/wallet-events-club')
      }
    }
  }

  const isWalletVip = () => {
    return +acceptorCode === 100120 || +acceptorCode === 100512 || +acceptorCode === 100165
  }

  funcMap.method = function (name, callback) {
    this[name] = callback
  }
  funcMap.method('setShowWalletTransferModal', (value) => {
    setShowWalletTransferModal(value)
  })
  funcMap.method('setShowWalletCashoutModal', (value) => {
    setShowWalletCashoutModal(value)
  })
  funcMap.method('setShowWalletCashoutVipModal', (value) => {
    setShowWalletCashoutVipModal(value)
  })

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='container'>
      <div className={'row'}>
        <span className='col-4 text-center'>
          <IonButton
            disabled={type === 'club'}
            onClick={() =>
              history.push(`${!isNaN(acceptorCode) ? '/wallet-identity-acceptor' : '/tabs/wallet-identity'}`)
            }
            size='small'
            fill='outline'>
            {'مشاهده شناسه کیف پول'}
          </IonButton>
        </span>
        <div className='col-8 left ltr'>
          {!inprogress && (
            <IonIcon
              icon={sync}
              className='left mb-2 ml-3 pointer'
              title={'بروزرسانی'}
              onClick={(e) => getBalance()}
              size='large'
            />
          )}
        </div>
      </div>
      <div className={'row' + (type !== 'normal' ? ' pointer' : '')} onClick={goToWalletReport}>
        <span className={'col-4 text-center'}>{t('wallet.balance')}</span>
        <div className={'col-8 left ltr'}>
          <h3 className={'ltr left'}>
            <Balance amount={balance} />
            <span className='text-currency left' style={{ paddingTop: '12px', paddingRight: '2px' }}>
              {t('label.riyal')}
            </span>
          </h3>
        </div>
      </div>
      <hr />
      <div>
        <div className='display-inline-100 text-center font14 mr-0 row'>
          <button
            className='col border-color-04 border-style-1 pointer mr-1'
            onClick={(e) => setShowWalletDepositModal(true)}>
            <AyriaIcon icon={add} color='success' size='large' />
            <br />
            <span>{t('wallet.addBalance')}</span>
          </button>
          {type !== 'club' && (
            <button
              className='col border-color-05 border-style-1 pointer mr-1'
              onClick={(e) => doShowIdentityVerifierModal('setShowWalletTransferModal')}>
              <img
                src='/assets/images/transaction.png'
                alt='transfer'
                className='ion-padding-bottom'
                style={{ paddingTop: '7px' }}
              />
              <br />
              <span>{t('wallet.transferBalance')}</span>
            </button>
          )}
          {type !== 'club' && (
            <button
              className='col border-color-06 border-style-1 pointer mr-1'
              onClick={(e) => doShowIdentityVerifierModal('setShowWalletCashoutModal')}>
              <AyriaIcon icon={returnUpBack} color='danger' size='large' />
              <br />
              <span>{t('wallet.withdrawBalance')}</span>
            </button>
          )}
          {isWalletVip() && (
            <button
              className='col border-color-06 border-style-1 pointer mr-1'
              onClick={(e) => doShowIdentityVerifierModal('setShowWalletCashoutVipModal')}>
              <AyriaIcon icon={returnUpBack} color='danger' size='large' />
              <br />
              <span>{'واریز به حساب VIP'}</span>
            </button>
          )}
        </div>
      </div>
      <Modal
        showModal={showWalletDepositModal}
        toggle={setShowWalletDepositModal}
        onClosed={() => setShowWalletDepositModal(false)}
        title='افزایش موجودی'
        isPlatformTheme={type === 'acceptor'}
        content={<WalletDeposit theme={type} acceptorCode={acceptorCode} />}
      />
      <Modal
        showModal={showWalletTransferModal}
        toggle={setShowWalletTransferModal}
        onClosed={() => setShowWalletTransferModal(false)}
        title='انتقال موجودی'
        contentUsePadding={false}
        isPlatformTheme={type === 'acceptor'}
        content={<WalletTransfer theme={type} acceptorCode={acceptorCode} onSuccess={onSuccessWalletTransfer} />}
      />
      <Modal
        showModal={showWalletCashoutModal}
        toggle={setShowWalletCashoutModal}
        onClosed={() => setShowWalletCashoutModal(false)}
        title={'انتقال به حساب بانکی'}
        isPlatformTheme={type === 'acceptor'}
        content={<WalletCashout theme={type} acceptorCode={acceptorCode} onSuccess={onSuccessWalletCashout} />}
      />
      <Modal
        showModal={showWalletCashoutVipModal}
        toggle={setShowWalletCashoutVipModal}
        onClosed={() => setShowWalletCashoutVipModal(false)}
        title={'واریز به حساب VIP'}
        isPlatformTheme={type === 'acceptor'}
        content={<WalletCashoutVip theme={type} acceptorCode={acceptorCode} onSuccess={onSuccessWalletCashout} />}
      />
      <CustomModal
        showModal={showIdentityVerifierModal}
        toggle={setShowIdentityVerifierModal}
        title={'تایید اطلاعات هویتی'}
        isPlatformTheme={type === 'acceptor'}
        content={<IdentityVerifier onIsOk={(e) => hideShowIdentityVerifierModal()} />}
      />
      <Modal
        showModal={showWalletReceiptModal}
        toggle={setShowWalletReceiptModal}
        onClosed={() => setShowWalletReceiptModal(false)}
        title='رسید تراکنش'
        content={<WalletReceipt walletEvent={walletEvent} />}
      />
    </div>
  )
}

export default Wallet
