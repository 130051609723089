import React, { useState, useContext, useEffect, FC } from 'react'
import { IonIcon, IonSpinner } from '@ionic/react'
import { sync } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { ScoreContext } from '../../structures/contexts/ScoreContext'
import ScoreWithdraw from '../../elements/ScoreWithdraw/ScoreWithdraw'
import CurrencyInput from '../../units/CurrencyInput/CurrencyInput'
import { CLUB_HAS_SCORE_CASHOUT } from '../../../config'
import { useToast } from '../../../ayria'
import { setScoreBalance } from '../../../data/user/user.actions'
import { connect } from '../../../data'
import { Modal } from '../../../utility'

interface OwnProps {
  code?: string
  type?: string
}
interface DispatchProps {
  setScoreBalance: typeof setScoreBalance
}

interface ScoreProps extends OwnProps, DispatchProps {}
const Score: FC<ScoreProps> = ({ code = undefined, setScoreBalance, type = 'user' }) => {
  const { t } = useTranslation()
  const toaster = useToast()
  const [amount, setAmount] = useState(0)
  const [score, setScore] = useState(0)
  const [scoreContext, setScoreContext] = useContext<any>(ScoreContext)
  const [inprogress, setInprogress] = useState(false)
  const [showScoreWithdraw, setShowScoreWithdraw] = useState(false)

  async function updateBalance() {
    setInprogress(true)
    const scoreRes: any = await api.get(`/customer/scores/total/${code ? code : '0'}`)
    if (!code) {
      setScoreContext((state: any) => ({ ...state, balance: scoreRes.data }))
      await setScoreBalance(+scoreRes.data)
    } else {
      setScoreContext((state: any) => ({ ...state, [code]: scoreRes.data }))
    }
    setScore(scoreRes.data)
    setInprogress(false)
  }
  const handleScoreWithdraw = () => {
    if (amount && amount > 0) {
      setShowScoreWithdraw(true)
    } else {
      toaster.error(t('common_errors.please_enter_price_as_english_number'))
    }
  }
  const handleScoreWithdrawOnSuccess = (newBalance: any) => {
    if (code) {
      setScoreContext((state: any) => ({ ...state, [code]: newBalance }))
    } else {
      setScoreContext((state: any) => ({ ...state, balance: newBalance }))
    }
    setScore(newBalance)
    setShowScoreWithdraw(false)
    toaster.success(t('label.successfulOperation'))
  }

  useEffect(() => {
    updateBalance()
    setScore(code ? scoreContext[code] : scoreContext.balance)
    // eslint-disable-next-line
  }, [scoreContext.balance, scoreContext?.code])

  return (
    <div className='container'>
      <div className={'row ltr mb-2 ml-3'} style={{ minHeight: '30px' }}>
        {!inprogress && (
          <IonIcon icon={sync} className='pointer' title={'بروزرسانی'} onClick={updateBalance} size='large' />
        )}
        {inprogress && <IonSpinner className='ion-padding' />}
      </div>
      <div className={'row'}>
        <span className='col-4 text-center'>{type === 'club' ? 'امتیاز باشگاه' : 'امتیاز جاری'}</span>
        <div className='col-8 left ltr'>
          <span className='ltr left h3'>
            <img alt='' style={{ width: '30px' }} src={'/assets/images/coin.png'}></img>
            <span className='pl-1'>{score || 0}</span>
          </span>
        </div>
      </div>
      {!code && <hr />}
      {CLUB_HAS_SCORE_CASHOUT && (!code || code === '100166') && (
        <div>
          <div>
            <CurrencyInput onValueChange={(value: any) => setAmount(value)} placeholder='امتیاز مورد نظر' />
          </div>
          <br />
          <div className='display-inline-100 text-center font14 mr-0 row'>
            <div className='col border-color-06 border-style-1 mr-1 pointer' onClick={handleScoreWithdraw}>
              <img src='/assets/images/omit.png' alt='withdraw' />
              <br />
              <span>{t('score.withdrawBalance')}</span>
            </div>
          </div>
        </div>
      )}
      <Modal
        showModal={showScoreWithdraw}
        toggle={setShowScoreWithdraw}
        title='تبدیل امتیاز به موجودی کیف پول'
        onClosed={() => setShowScoreWithdraw(false)}
        content={<ScoreWithdraw amount={+amount} acceptorCode={code} onSuccess={handleScoreWithdrawOnSuccess} />}
      />
    </div>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setScoreBalance,
  },
  component: Score,
})
