import React, { useEffect, useState } from 'react'
import { IonContent, IonPage, IonIcon, IonButton } from '@ionic/react'
import { IonLabel, IonItem, IonText, IonAvatar, IonBadge } from '@ionic/react'
import { IonThumbnail, IonChip } from '@ionic/react'
import { addCircleSharp, removeCircleSharp, trashSharp, checkmark, chevronBack } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { URLSearchParams } from 'whatwg-url'
import { Header, connect, setKalaList } from '../../../ayria'
import { removeKala, addKala, groupByProp, setTableIdentifier, setReceiveType } from '../../../ayria'
import { AcceptorLogo, KalaImage, ApiService, Modal, DeliveryHoursAllowed } from '../../../ayria'
import ShoppingCartConfirm from './ShoppingCartConfirm'
import '../../../pages/kala/KalaPage.scss'
import './merchantProfile.scss'

declare var window: any

interface StateProps {
  kalaList: any[]
  tableIdentifier?: string
  receiveType?: string
}
interface DispatchProps {
  setKalaList: typeof setKalaList
  addKala: typeof addKala
  removeKala: typeof removeKala
  setTableIdentifier: typeof setTableIdentifier
  setReceiveType: typeof setReceiveType
}
interface OwnProps {}

interface MerchantProfileShoppingBasketProps extends OwnProps, StateProps, DispatchProps {}

const MerchantProfileShoppingBasket: React.FC<MerchantProfileShoppingBasketProps> = ({
  kalaList,
  setKalaList,
  addKala,
  removeKala,
  tableIdentifier,
  setTableIdentifier,
  receiveType,
  setReceiveType,
}) => {
  const api = new ApiService()
  const history = useHistory()
  const [totalPrices, setTotalPrices] = useState<any>([])
  const [render, setRender] = useState(false)
  const [deliveryHours, setDeliveryHours] = useState([])
  const [showDeliveryHoursModal, setShowDeliveryHoursModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [activeAcceptor, setActiveAcceptor] = useState<string>()
  const [order, setOrder] = useState<any>()
  const urlSearchParams = new URLSearchParams(window.location.search)
  let grouped = groupByProp(kalaList, (kala: any) => kala.acceptorName)

  const addItem = async (kala: any) => {
    setRender(false)
    await addKala(kala)
  }

  const removeItem = async (kala: any) => {
    setRender(false)
    await removeKala(kala)
  }
  const emptyCart = (acceptorCode: number) => {
    setRender(false)
    setKalaList(kalaList.filter((k) => k.acceptorCode !== acceptorCode))
  }

  const handleGoNext = async (acceptorCode: string) => {
    setActiveAcceptor(acceptorCode)
    if (receiveType === 'TABLES') {
      setShowConfirmModal(true)
      return
    }
    const res: any = await api.publicDeliveryHoursOkay(acceptorCode)
    if (res.ok && res.status === 204) {
      setShowConfirmModal(true)
    } else {
      setDeliveryHours(res.data)
      setShowDeliveryHoursModal(true)
    }
  }

  const isMultiPrice = (kala: any) => {
    return ('' + kala.id).includes('-')
  }

  const kalaPrice = (kala: any) => {
    if (isMultiPrice(kala)) {
      return kala.prices.find((kp: any) => kp.id === +kala.id.split('-')[1])
    }
  }

  const actualPrice = (kala: any) => {
    if (isMultiPrice(kala)) {
      return kalaPrice(kala).price
    }
    return kala.price
  }

  useEffect(() => {
    grouped = groupByProp(kalaList, (kala: any) => kala.acceptorName) // eslint-disable-line
    Array.from(grouped).map((group: any, idx: number) => {
      totalPrices[idx] = group[1].reduce((a: any, c: any) => a + c.qty * actualPrice(c), 0)
      return totalPrices
    })
    setTotalPrices(totalPrices)
    setRender(true)
  }, [kalaList]) // eslint-disable-line

  useEffect(() => {}, [totalPrices]) // eslint-disable-line

  useEffect(() => {
    if (urlSearchParams.get('t')) {
      setTableIdentifier(urlSearchParams.get('t'))
      setReceiveType('TABLES')
    }
  }, []) // eslint-disable-line

  console.log(grouped)
  return (
    <IonPage id='shopping-basket-page'>
      <IonContent fullscreen={false}>
        <Header title='سبد خرید' />
        <div className='ion-padding'>
          {!!order && (
            <IonChip className='pointer' color='success' onClick={() => history.push('/tabs/orders')}>
              <IonIcon icon={checkmark}></IonIcon>
              <IonLabel>
                {' سفارش '} <span className='text-bold ion-padding'> {order?.trackingNumber} </span>{' '}
                {' با موفقیت پرداخت شد '}
              </IonLabel>
              <IonIcon icon={chevronBack}></IonIcon>
            </IonChip>
          )}

          <div>
            {Array.from(grouped).length === 0 && (
              <div
                style={{ minHeight: '60vh' }}
                className='d-flex flex-column align-items-center justify-content-center  col-12 text-center'>
                <img width={100} src='/assets/images/empty-cart.png' alt='' />
                <br />
                <span>سبد خرید خالی می باشد</span>
              </div>
            )}
            {Array.from(grouped).map((group: any, idx: number) => (
              <div key={group[0]} className='customCard withBorder mt-2'>
                <IonItem className='ion-text-center' lines='none'>
                  <IonAvatar
                    style={{ margin: '0 auto' }}
                    color='primary'
                    className='center-avatar'
                    onClick={() => history.push(`/tabs/center/${group[1][0].acceptorCode}${window.location.search}`)}>
                    <AcceptorLogo referralCode={group[1][0].acceptorCode} width={'80px'} />
                  </IonAvatar>
                  <IonButton
                    size='small'
                    fill='clear'
                    onClick={() => emptyCart(group[1][0].acceptorCode)}
                    style={{ position: 'absolute', left: '0' }}>
                    <IonIcon icon={trashSharp} color='danger' slot='icon-only' />
                  </IonButton>
                </IonItem>
                <hr />
                {group[1]?.map((item: any) => (
                  <div key={item.id}>
                    <IonItem lines='none'>
                      <IonThumbnail style={{ width: '85px', height: '85px' }}>
                        <KalaImage kala={item} width={'150px'} />
                      </IonThumbnail>
                      <IonLabel className='ion-text-wrap ion-padding'>
                        {item.name} {isMultiPrice(item) ? `(${kalaPrice(item)?.name})` : ''}
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonIcon
                        size='large'
                        color='primary'
                        icon={addCircleSharp}
                        onClick={() => addItem(item)}
                        className='pointer'
                      />
                      <IonBadge color='light' style={{ borderRadius: '100%' }}>
                        {item.qty}
                      </IonBadge>
                      <IonIcon
                        size='large'
                        color='primary'
                        icon={removeCircleSharp}
                        onClick={() => removeItem(item)}
                        className='pointer'
                      />
                      <IonText slot='end' className='ion-text-wrap'>
                        {isMultiPrice(item)
                          ? kalaPrice(item)?.price?.toLocaleString(navigator.language)
                          : item?.price?.toLocaleString(navigator.language)}{' '}
                        {'ریال'}
                      </IonText>
                    </IonItem>
                  </div>
                ))}
                <IonItem className='ion-text-wrap' lines='none'>
                  <div className='ion-text-center'>
                    <strong>مبلغ قابل پرداخت</strong>
                    <br />
                    {!!render && (
                      <strong>
                        {totalPrices[idx]?.toLocaleString(navigator.language)} {'ریال'}
                      </strong>
                    )}
                    {!render && <span>...</span>}
                  </div>
                  <IonButton
                    shape='round'
                    onClick={() => handleGoNext(group[1][0].acceptorCode)}
                    expand='full'
                    size='large'
                    slot='end'>
                    {'تکمیل خرید'}
                  </IonButton>
                </IonItem>
              </div>
            ))}
          </div>
        </div>
      </IonContent>

      <Modal
        showModal={showDeliveryHoursModal}
        toggle={setShowDeliveryHoursModal}
        title={'بازهای ثبت سفارش این فروشگاه'}
        onClosed={() => setShowDeliveryHoursModal(false)}
        content={<DeliveryHoursAllowed deliveryHours={deliveryHours} />}
      />
      <Modal
        showModal={showConfirmModal}
        toggle={setShowConfirmModal}
        title={'پرداخت'}
        onClosed={() => setShowConfirmModal(false)}
        acceptorLogo={activeAcceptor}
        contentUsePadding={false}
        content={
          <ShoppingCartConfirm
            acceptorCode={activeAcceptor}
            onSuccess={(ayriaPayment: any) => {
              setOrder(ayriaPayment)
              setShowConfirmModal(false)
              urlSearchParams.delete('t')
              history.replace({
                search: urlSearchParams.toString(),
              })
            }}
          />
        }
      />
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setKalaList,
    addKala,
    removeKala,
    setTableIdentifier,
    setReceiveType,
  },
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
    tableIdentifier: state.user.tableIdentifier,
    receiveType: state.user.receiveType,
  }),
  component: MerchantProfileShoppingBasket,
})
