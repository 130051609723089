import React, { useState } from 'react'
import { IonLabel, IonButton, IonItem, IonIcon, isPlatform } from '@ionic/react'
import { IonList, IonText } from '@ionic/react'
import { shareOutline, add } from 'ionicons/icons'

declare var document: any

interface OwnProps {
  deferredPrompt: any
  showAddToHomeMessageDefault: boolean
}
interface AddToHomeProps extends OwnProps {}

const AddToHome: React.FC<AddToHomeProps> = ({ deferredPrompt, showAddToHomeMessageDefault }) => {
  const [showAddToHomeMessage, setShowAddToHomeMessage] = useState(showAddToHomeMessageDefault)
  const logoElem = document.querySelector('link[sizes]')

  const addToHome = () => {
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        setShowAddToHomeMessage(true)
      }
    })
  }

  return (
    <IonList>
      <IonItem className='ion-text-center'>
        <img src={logoElem.href} alt='logo' width={100} height={100} style={{ margin: '0 auto' }} />
      </IonItem>
      {isPlatform('ios') && (
        <>
          {!showAddToHomeMessage && (
            <>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  {'وب اپلیکیشن'} <IonText color='primary'>{document.title}</IonText>{' '}
                  {'را به صفحه اصلی موبایل خود اضافه کنید.  '}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  {'در نوار پایین دکمه '} <IonIcon icon={shareOutline} color='primary' style={{ fontSize: '30px' }} />{' '}
                  <b>"Share"</b> {'را انتخاب کنید.'}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  {'منوی باز شده را به سمت چپ بکشید و گزینه'}{' '}
                  <IonButton size='small' slot='icon-only' color='dark'>
                    <IonIcon icon={add} />
                  </IonButton>{' '}
                  <b>"Add to home screen"</b> {'را انتخاب کنید'}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel className='ion-text-wrap'>
                  {'در مرحله بعد در قسمت بالا روی'} <b>Add</b> {'کلیک کنید.'}
                </IonLabel>
              </IonItem>
            </>
          )}
        </>
      )}
      {!isPlatform('ios') && (
        <>
          {!showAddToHomeMessage && (
            <IonItem>
              <IonLabel className='ion-text-wrap'>{'برای ادامه لطفا از گزینه نصب برنامه استفاده کنید'}</IonLabel>
              <IonButton onClick={addToHome}>{'نصب برنامه'}</IonButton>
            </IonItem>
          )}
        </>
      )}

      {showAddToHomeMessage && (
        <IonItem>
          <IonLabel className='ion-text-wrap' color='success'>
            {'برای ادامه لطفا از نسخه نصب شده'} <IonText color='primary'>{document.title}</IonText> {'استفاده نمایید.'}
          </IonLabel>
        </IonItem>
      )}
    </IonList>
  )
}

export default AddToHome
