import React, { useState, lazy, useEffect, useContext } from 'react'
import { IonLabel, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react'
import { IonIcon, IonContent, IonFooter } from '@ionic/react'
import { close, chevronDown } from 'ionicons/icons'
import { connect } from '../../data/connect'
import { LayerContext } from '../../pages/shared/map'
import { Geolocation, Position } from '@capacitor/geolocation'
import './AddressCombo.scss'
const ShippingAddressMapSuggest = lazy(() => import('../../components/units/suggest/ShippingAddressMapSuggest'))

interface StateProps {
  shippingAddress: any
}
interface AddressComboProps extends StateProps {}
const AddressCombo: React.FC<AddressComboProps> = ({ shippingAddress }) => {
  const [showModal, setShowModal] = useState(false)
  const { setCurrentLocation } = useContext(LayerContext)

  const getCurrentLocation = async () => {
    try {
      const coordinates: Position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true })
      if (coordinates?.coords?.latitude) {
        setCurrentLocation([coordinates?.coords?.latitude, coordinates?.coords?.longitude])
      }
    } catch (e) {
      console.log('Error on getting current position ', e)
    }
  }

  useEffect(() => {
    getCurrentLocation()
  }, []) // eslint-disable-line

  return (
    <>
      <div className='border-0 p-0' onClick={() => setShowModal(true)}>
        {!shippingAddress?.id ? (
          <IonLabel
            color='danger'
            className='ion-text-center d-flex flex-row align-items-center justify-content-center border-0'>
            {'انتخاب آدرس'}📍
          </IonLabel>
        ) : (
          <IonLabel
            color='dark'
            className='border-0 ion-text-center d-flex flex-row align-items-center justify-content-center'>
            {shippingAddress?.address}
            <IonIcon color='black' icon={chevronDown} slot='icon-only' />
          </IonLabel>
        )}

        {/*{shippingAddress?.id && <IonLabel  className='ion-text-center'>{shippingAddress?.address}</IonLabel>}*/}
      </div>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='addressComboModal'>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{'انتخاب آدرس'}</IonTitle>
            <IonButtons slot='start'>
              <IonButton color='dark' onClick={() => setShowModal(false)}>
                <IonIcon icon={close} slot='icon-only' />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding-top'>
          <ShippingAddressMapSuggest onSelect={() => {}} acceptorCode={100322} />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton disabled={!shippingAddress?.id} onClick={() => setShowModal(false)}>
                {'انتخاب'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    shippingAddress: state.user.shippingAddress,
  }),
  component: AddressCombo,
})
