import React, { FC } from 'react'
import { IonIcon } from '@ionic/react'
import { chevronBackCircle } from 'ionicons/icons'

interface OwnProps {
  link: string
}
interface AcceptorCardProps extends OwnProps {}

const AcceptorCardMore: FC<AcceptorCardProps> = ({ link }) => {
  return (
    <a
      href={link}
      className='d-flex flex-column align-items-center justify-content-center customCard cursor-pointer'
      style={{
        textDecoration: 'none',
        color: '#5d5757',
        margin: 10,
        minHeight: 200,
        minWidth: 200,
        borderRadius: '15px',
        backgroundColor: '#e5e0e0',
      }}
      id='acceptor-card-component'>
      <IonIcon slot='start' size='large' icon={chevronBackCircle} />
      <span>مشاهده همه موارد</span>
    </a>
  )
}

export default AcceptorCardMore
