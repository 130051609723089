import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import Wallet from '../../components/screens/wallet/wallet'
import { Header } from '../../ayria'
import { isDesktop } from '../../utility'
import WalletReport from '../../components/screens/wallet/wallet-report/wallet-report'

interface WalletTabProps {}

const WalletTab: React.FC<WalletTabProps> = () => {
  return (
    <IonPage id='wallet-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <Wallet />
          <br />
          <WalletReport />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default React.memo(WalletTab)
