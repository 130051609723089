import React, { useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

interface OveralScoreProps {
  statistics: any
  fontSize?: string
}

const OveralScore: React.FC<OveralScoreProps> = ({ statistics, fontSize = '25px' }) => {
  const [classIdentifier, setClassIdentifier] = useState<string>()
  const successColor = '#28a745'
  const warningColor = '#ffc107'
  const dangerColor = '#dc3545'

  const getColor = () => {
    if (classIdentifier === 'success') return successColor
    if (classIdentifier === 'warning') return warningColor
    if (classIdentifier === 'danger') return dangerColor
  }

  const calculateClassIdentifier = (rate: number) => {
    if (rate > 0.5) return 'success'
    if (rate < 0.5) return 'warning'
    if (rate < 0.5 && rate > 0) return 'danger'
  }

  useEffect(() => {
    setClassIdentifier(calculateClassIdentifier(statistics?.rate))
  }, [statistics]) // eslint-disable-line

  return (
    <CircularProgressbar
      value={statistics.rate * 100}
      text={`${statistics.rate * 10}`}
      strokeWidth={6}
      styles={{
        path: {
          // Path color
          stroke: getColor(),
        },
        text: {
          // Text color
          fill: getColor(),
          // Text size
          fontSize: fontSize,
        },
      }}
    />
  )
}

export default OveralScore
