import { IonButton, IonChip, IonItem, IonLabel, IonSpinner } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { ApiService } from '../../../ayria'
import { setWithTimeout, useToast } from '../../../utility'

interface AyriaPaymentIpgAttemptsProps {
  acceptorCode: string
  trackingNumber: string
}

const AyriaPaymentIpgAttempts: FC<AyriaPaymentIpgAttemptsProps> = ({ trackingNumber, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [inprogress, setInprogress] = useState(false)
  const [message, setMessage] = useState<string>()

  const getItems = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/ayria-payment/${trackingNumber}/ipg-attempts`)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    } else {
      toast.error(res)
    }
  }

  const confirm = async (shomareMarja: string) => {
    setInprogress(true)
    const res: any = await api.put(`/acceptor-roles/${acceptorCode}/ayria-payment/${trackingNumber}/ipg-confirm`, {
      shomareMarja,
    })
    if (res.ok) {
      setMessage(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      <div className='ion-text-center'>
        {inprogress && <IonSpinner />}
        {!inprogress && !!message && <IonChip>{message}</IonChip>}
      </div>

      {items?.map((itm: any) => (
        <IonItem>
          <IonLabel>
            {`کد وضعیت ${itm.codeVaziatTarakonesh}`}
            <p className='dir-ltr'>{itm.tarikhMahaliTarakonesh}</p>
          </IonLabel>
          <IonButton slot='end' onClick={() => confirm(itm.shomareMarja)} disabled={inprogress}>
            {'استعلام و تایید'}
          </IonButton>
        </IonItem>
      ))}
    </>
  )
}

export default AyriaPaymentIpgAttempts
