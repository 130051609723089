import React, { useState, useEffect } from 'react'
import { IonContent, IonPage, IonItem, IonLabel, IonListHeader } from '@ionic/react'
import { IonInput, IonIcon, IonButton, IonSpinner, IonCheckbox } from '@ionic/react'
import { save } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { ApiService, AyriaSelect, MobileIonInput, useToast, NumberIonInput, Header } from '../../ayria'
import { isDesktop } from '../../utility'
const departments = require('../../data/Categories')

const MyCenterRegisterTab = () => {
  const api = new ApiService()
  const history = useHistory()
  const toast = useToast()
  const [ostans, setOstans] = useState([])
  const [ostan, setOstan] = useState()
  const [acceptorName, setAcceptorName] = useState<any>()
  const [firstName, setFirstName] = useState<any>()
  const [lastName, setLastName] = useState<any>()
  const [mobile, setMobile] = useState<any>('')
  const [discount, setDiscount] = useState<any>(0)
  const [department, setDepartment] = useState<any>()
  const [address, setAddress] = useState<any>()
  const [inprogress, setInprogress] = useState(false)
  const [isOwner, setIsOwner] = useState(false)

  const register = async () => {
    setInprogress(true)
    const res: any = await api.userRegisterAcceptor({
      ostan,
      acceptorName,
      firstName,
      lastName,
      mobile,
      discount,
      department,
      address,
      owner: isOwner,
    })
    if (res.ok) {
      toast.success('فروشگاه با موفقیت ثبت شد')
      history.push('/tabs/mycenter')
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const getOstans = async () => {
    const res: any = await api.getOstans()
    setOstans(res.ok ? res.data : [])
  }

  const getCurrentOstan = async () => {
    const res: any = await api.getCurrentOstan()
    if (res.ok) {
      setOstan(res.data.id)
    }
  }

  const isValid = () => {
    return acceptorName && firstName && lastName && ostan && mobile && department
  }

  useEffect(() => {
    getOstans()
    getCurrentOstan()
  }, []) // eslint-disable-line

  return (
    <IonPage id='my-center-register-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <IonListHeader>
            <IonLabel>{'ثبت فروشگاه جدید'}</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>{'استان'}</IonLabel>
            <AyriaSelect value={ostan} onIonChange={(e) => setOstan(e.detail.value)} items={ostans} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'نام فروشگاه'}</IonLabel>
            <IonInput value={acceptorName} onIonChange={(e) => setAcceptorName(e.detail.value)} required={true} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'نام مالک'}</IonLabel>
            <IonInput value={firstName} onIonChange={(e) => setFirstName(e.detail.value)} required={true} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'نام خانوادگی مالک'}</IonLabel>
            <IonInput value={lastName} onIonChange={(e) => setLastName(e.detail.value)} required={true} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'شماره همراه مالک'}</IonLabel>
            <MobileIonInput value={mobile} onValueChange={setMobile} required={true} id='mobile' />
          </IonItem>
          <IonItem>
            <IonLabel>{'مالک فروشگاه خودم هستم'}</IonLabel>
            <IonCheckbox slot='start' value={isOwner} onIonChange={(e) => setIsOwner(e.detail.checked)}></IonCheckbox>
          </IonItem>
          <IonItem>
            <IonLabel>{'دپارتمان'}</IonLabel>
            <AyriaSelect
              value={department}
              onIonChange={(e) => setDepartment(e.detail.value)}
              items={departments.categories}
              valueProp={'value'}
              labelProp={'label'}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'آدرس'}</IonLabel>
            <IonInput value={address} onIonChange={(e) => setAddress(e.detail.value)} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{'درصد تخفیف'}</IonLabel>
            <NumberIonInput onValueChange={setDiscount} />
          </IonItem>
          <br />
          <IonButton disabled={!isValid() || inprogress} onClick={register}>
            {inprogress && <IonSpinner name='crescent' color='dark' />}
            <IonIcon icon={save} slot='start' />
            {'ثبت'}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default MyCenterRegisterTab
