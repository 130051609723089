import React, { useEffect, useState, useContext } from 'react'
import * as moment from 'moment'
import { location } from 'ionicons/icons'
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent } from '@ionic/react'
import { IonItem, IonButton, IonLabel, IonSpinner, IonText, IonDatetime } from '@ionic/react'
import { IonRow, IonCol, IonTextarea, IonChip, IonCheckbox, IonSelect } from '@ionic/react'
import { IonSegment, IonSegmentButton, IonSelectOption } from '@ionic/react'
import { connect, setShippingAddress, ApiService, useToast, Modal, WalletBalance } from '../../../ayria'
import { CurrencyIonInput, MobileIonInput, IPG_MIN_ACCEPTED_AMOUNT, WalletDeposit } from '../../../ayria'
import { WalletContext, setTableIdentifier, setKalaList } from '../../../ayria'
import { setReceiveType, setPickupDelay, AyriaIcon } from '../../../ayria'
import MerchantProfileShippingAddressPage from './merchantProfileShippingAddress'

interface OwnProps {
  acceptorCode: string
  loggedUser?: any
  onSuccess: Function
}
interface StateProps {
  shippingAddress?: any
  isLoggedIn?: boolean
  receiveType?: string
  pickupDelay?: number
  kalaList: any[]
  tableIdentifier?: string
}
interface DispatchProps {
  setShippingAddress: typeof setShippingAddress
  setTableIdentifier: typeof setTableIdentifier
  setKalaList: typeof setKalaList
  setReceiveType: typeof setReceiveType
  setPickupDelay: typeof setPickupDelay
}
interface ShoppingCartConfirmProps extends OwnProps, StateProps, DispatchProps {}

const ShoppingCartConfirm: React.FC<ShoppingCartConfirmProps> = ({
  acceptorCode,
  shippingAddress,
  setShippingAddress,
  isLoggedIn,
  receiveType,
  pickupDelay,
  kalaList,
  loggedUser,
  tableIdentifier,
  setKalaList,
  setReceiveType,
  setPickupDelay,
  onSuccess,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const [wallet] = useContext<any>(WalletContext)
  const [acceptor, setAcceptor] = useState()
  const [inprogress, setInprogress] = useState(false)
  const [notDeliverable, setNotDeliverable] = useState(true)
  const [checkInprogress, setCheckInprogress] = useState(false)
  const [payInprogress, setPayInprogress] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [kalaListFiltered, setKalaListFiltered] = useState(kalaList.filter((k) => k.acceptorCode === +acceptorCode))
  const [itemsPrice, setItemsPrice] = useState(kalaListFiltered.reduce((a: any, c: any) => a + c.qty * c.price, 0))
  const [taxPrice, setTaxPrice] = useState(0)
  const [shippingPrice, setShippingPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(itemsPrice + taxPrice + shippingPrice)
  const [description, setDescription] = useState<string>()
  const [tipActive, setTipActive] = useState(false)
  const [tip, setTip] = useState(0)
  const [showTipInput, setShowTipInput] = useState(false)
  const [payerMobile, setPayerMobile] = useState<string>('')
  const [ayriaPayment, setAyriaPayment] = useState()
  const [neededAmount, setNeededAmount] = useState(0)
  const [originalCommand, setOriginalCommand] = useState<any>()
  const [defaultReceiveType, setDefaultReceiveType] = useState<string>()
  const [tables, setTables] = useState([])

  const createAyriaPayment = async () => {
    const cmd = {
      amount: itemsPrice,
      payerMobile: isLoggedIn ? loggedUser?.mobile : payerMobile,
      description,
      acceptorCode,
      paymentMethod: 'WALLET',
      kalas: kalas(),
      onlineOrder: true,
      receiveType,
      pickupDelay,
      tableIdentifier,
      shippingAddressId: shippingAddress?.id,
      shippingLat: !isLoggedIn ? shippingAddress?.latitude : null,
      shippingLng: !isLoggedIn ? shippingAddress?.longitude : null,
      deliveryAddress:
        !isLoggedIn && !!shippingAddress
          ? `${shippingAddress?.address}، پلاک ${shippingAddress?.number}، واحد ${shippingAddress?.part}`
          : null,
    }
    let res: any
    if (isLoggedIn) {
      res = await api.post(`/user/ayria-payments/online-order`, cmd)
    } else {
      res = await api.post(`/public/ayria-payments/online-order`, cmd)
    }
    if (res.ok) {
      setAyriaPayment(res.data)
      kalaListFiltered.map((k) => {
        k.trackingNumber = res.data.trackingNumber
        return k
      })
      const other = kalaList.filter((k) => k.acceptorCode !== +acceptorCode)
      kalaListFiltered.map((elem: any) => other.push(elem))
      await setKalaList(other)
      if (isLoggedIn) {
        return res.data
      } else {
        await setKalaList(kalaList.filter((k) => k.acceptorCode !== +acceptorCode))
        window.location = res.data.paymentUrl
      }
    } else {
      toast.error(res)
    }
  }

  const payForReal = async (useScore: boolean) => {
    setPayInprogress(true)
    let trackingNumber
    if (!!ayriaPayment?.trackingNumber) {
      trackingNumber = ayriaPayment.trackingNumber
    } else if (kalaListFiltered.filter((k) => !!k.trackingNumber).length > 0) {
      trackingNumber = kalaListFiltered[0].trackingNumber
    } else {
      const ap = await createAyriaPayment()
      trackingNumber = ap?.trackingNumber
    }
    if (isLoggedIn) {
      const cmd = { trackingNumber, useScore, tip }
      const res: any = await api.post('/user/ayria-payments/pay', cmd)
      if (res.ok) {
        if (res.status === 202) {
          window.location.href = res.headers['x-redirect-url']
        } else {
          toast.success('سفارش با موفقیت پرداخت شد')
          await setKalaList(kalaList.filter((k) => k.acceptorCode !== +acceptorCode))
          await setReceiveType(null)
          await setPickupDelay(null)
          onSuccess(res.data)
        }
      } else {
        if (res.status === 417) {
          const debt = totalPrice - wallet.balance
          setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
          setOriginalCommand(JSON.stringify(cmd))
          setShowWalletDepositModal(true)
        } else {
          toast.error(res)
        }
      }
    }
    setPayInprogress(false)
  }

  const getAcceptor = async () => {
    const res: any = await api.publicAcceptorsGetByReferralCode(acceptorCode)
    if (res.ok) {
      setAcceptor(res.data)
    }
  }
  const loadLastShippingAddress = async () => {
    setInprogress(true)
    if (isLoggedIn) {
      const res: any = await api.post(`/shipping-addresses/last/${acceptorCode}`)
      if (res.ok) {
        await setShippingAddress(res.data)
      }
    }
    setInprogress(false)
  }
  const checkIfAddressIsDeliverablePublic = async () => {
    setCheckInprogress(true)
    const res: any = await api.publicAyriaPaymentsAddressIn({
      acceptorCode,
      latitude: shippingAddress?.latitude,
      longitude: shippingAddress?.longitude,
    })
    if (res.ok) {
      setNotDeliverable(res.data.length === 0)
      if (res.data.length > 0) {
        setShippingPrice(res.data[0].price)
      }
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }
  const getTables = async () => {
    const res: any = await api.get(`/public/acceptors/tables/${acceptorCode}`)
    setTables(res.ok ? res.data : [])
  }

  const isReceiveInvalid = () => {
    if (!receiveType || receiveType === 'DELIVERY') {
      return notDeliverable
    } else if (receiveType === 'PICKUP') {
      return !pickupDelay
    } else if (receiveType === 'TABLES') {
      return !tableIdentifier
    }
    return false
  }
  const shippingPriceActual = () => {
    if (!receiveType || receiveType === 'DELIVERY') {
      return +shippingPrice
    } else {
      return 0
    }
  }
  const formDisabled = () => {
    if (isLoggedIn) {
      return payInprogress
    } else {
      return payInprogress || !/^(09[0-9]{9})$/.test(payerMobile)
    }
  }
  const isDescriptionSectionInvalid = () => {
    if (isLoggedIn) {
      return false
    } else {
      return !/^(09[0-9]{9})$/.test(payerMobile)
    }
  }
  const kalas = () => {
    return kalaListFiltered.reduce((acc, item, index) => {
      acc.push({ count: item.qty, kala: { id: item.id } })
      return acc
    }, [])
  }
  const handleSegmentClick = (segmentValue: string) => {
    setDefaultReceiveType(segmentValue)
    setShowAddressModal(true)
  }
  const isMultiPrice = (kala: any) => {
    return ('' + kala.id).includes('-')
  }

  const kalaPrice = (kala: any) => {
    if (isMultiPrice(kala)) {
      return kala.prices.find((kp) => kp.id === +kala.id.split('-')[1])
    }
  }

  const actualPrice = (kala: any) => {
    if (isMultiPrice(kala)) {
      return kalaPrice(kala).price
    }
    return kala.price
  }

  useEffect(() => {
    if (!tipActive) {
      setTip(0)
    }
  }, [tipActive]) // eslint-disable-line

  useEffect(() => {
    if (acceptor?.tax) {
      const actualAmountTaxNeeded = ((itemsPrice + shippingPriceActual()) * acceptor.taxDivider) / 100
      setTaxPrice(((actualAmountTaxNeeded * 9) / 100).toFixed(0))
    }
  }, [itemsPrice, shippingPrice, acceptor]) // eslint-disable-line

  useEffect(() => {
    setTotalPrice(+itemsPrice + +taxPrice + +tip + shippingPriceActual())
  }, [itemsPrice, shippingPrice, taxPrice, receiveType, tip]) // eslint-disable-line

  useEffect(() => {
    setItemsPrice(kalaListFiltered.reduce((a: any, c: any) => a + c.qty * actualPrice(c), 0))
  }, [kalaListFiltered]) // eslint-disable-line

  useEffect(() => {
    setKalaListFiltered(kalaList.filter((k) => k.acceptorCode === +acceptorCode))
  }, [kalaList, acceptorCode]) // eslint-disable-line

  useEffect(() => {
    if (shippingAddress?.latitude) {
      checkIfAddressIsDeliverablePublic()
    }
  }, [shippingAddress]) // eslint-disable-line

  useEffect(() => {
    getAcceptor()
    getTables()
    loadLastShippingAddress()
  }, []) // eslint-disable-line

  return (
    <>
      <IonCard
        className='ion-no-padding'
        style={{
          border: `1px solid ${isReceiveInvalid() ? 'red' : 'white'}`,
          marginBottom: '10px',
          marginTop: '10px',
        }}>
        <IonCardHeader style={{ paddingTop: 0 }}>
          <IonCardSubtitle>{'روش دریافت سفارش'}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonSegment value={receiveType}>
            {acceptor?.delivery && receiveType !== 'TABLES' && (
              <IonSegmentButton value='DELIVERY' onClick={() => handleSegmentClick('DELIVERY')}>
                <img src='/assets/images/delivery.png' alt='' width='25' height='25' />
                <IonLabel>ارسال با پیک</IonLabel>
              </IonSegmentButton>
            )}
            {acceptor?.pickup && receiveType !== 'TABLES' && (
              <IonSegmentButton value='PICKUP' onClick={() => handleSegmentClick('PICKUP')}>
                <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
                <IonLabel>{'دریافت حضوری'}</IonLabel>
              </IonSegmentButton>
            )}
            {acceptor?.tables && receiveType === 'TABLES' && (
              <IonSegmentButton value='TABLES'>
                <img src='/assets/images/tables.png' alt='' width='25' height='25' />
                <IonLabel>{`داخل سالن`}</IonLabel>
              </IonSegmentButton>
            )}
          </IonSegment>
          <IonItem lines='none'>
            {(!receiveType || receiveType === 'DELIVERY') && (
              <IonLabel>
                <AyriaIcon icon={location} />
                {!!shippingAddress &&
                  `${shippingAddress?.address}، پلاک ${shippingAddress?.number}، واحد ${shippingAddress?.part}`}
                {checkInprogress && <IonSpinner slot='end' className='ion-margin-horizontal' />}
              </IonLabel>
            )}
            {receiveType === 'PICKUP' && (
              <IonDatetime
                value={moment().add(pickupDelay, 'minutes').toISOString()}
                presentation='time'
                disabled={true}
              />
            )}
            {receiveType === 'TABLES' && (
              <>
                <IonLabel className='ion-padding-end'>{'میز'}</IonLabel>
                {tables.length > 0 && (
                  <IonSelect
                    value={tableIdentifier}
                    onIonChange={(e) => setTableIdentifier(e.detail.value)}
                    okText='انتخاب'
                    cancelText='انصراف'
                    style={{ minWidth: '70%' }}
                    disabled={payInprogress}>
                    {tables.map((t) => (
                      <IonSelectOption key={t.identifier} value={t.identifier}>
                        {t.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                )}
              </>
            )}
            {!tableIdentifier && (
              <IonButton
                slot='end'
                fill={isReceiveInvalid() ? 'solid' : 'outline'}
                expand={isReceiveInvalid() ? 'full' : 'block'}
                size={isReceiveInvalid() ? 'large' : 'small'}
                color={isReceiveInvalid() ? 'primary' : 'medium'}
                onClick={() => setShowAddressModal(true)}
                disabled={payInprogress}>
                {'ویرایش'}
              </IonButton>
            )}
          </IonItem>
          {isReceiveInvalid() && !inprogress && !checkInprogress && (
            <IonText color='danger'>{'خارج از محدوده فروشگاه'}</IonText>
          )}
        </IonCardContent>
      </IonCard>

      <IonCard
        className='ion-no-padding'
        style={{
          border: `1px solid ${isDescriptionSectionInvalid() ? 'red' : 'white'}`,
          marginTop: '10px',
          marginBottom: '10px',
        }}>
        <IonCardContent style={{ paddingTop: 0 }}>
          <IonItem>
            <IonTextarea
              value={description}
              onIonChange={(e) => setDescription(e.detail.value)}
              placeholder={'توضیحات اضافی'}
              disabled={payInprogress}
            />
          </IonItem>
          {!isLoggedIn && (
            <IonItem>
              <IonLabel position='stacked'>{'شماره همراه پرداخت کننده'}</IonLabel>
              <MobileIonInput value={payerMobile} onValueChange={setPayerMobile} />
            </IonItem>
          )}
          {isLoggedIn && acceptor?.department === 'RESTAURANT' && (
            <IonItem>
              <IonLabel className='ion-text-wrap'>
                {'آیا تمایل دارید '}
                <IonText color='dark'>
                  <strong>{'انعام'}</strong>
                </IonText>
                {' بدهید؟'}
              </IonLabel>
              <IonCheckbox
                slot='start'
                value={tipActive}
                onIonChange={(e) => setTipActive(e.detail.checked)}
                disabled={payInprogress}
              />
            </IonItem>
          )}
          {isLoggedIn && tipActive && (
            <>
              {!showTipInput && (
                <IonItem>
                  <IonChip
                    onClick={() => {
                      setTip(100000)
                      setShowTipInput(true)
                    }}
                    color={+tip === 100000 ? 'success' : 'dark'}
                    disabled={payInprogress}>
                    {(100000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                  <IonChip
                    onClick={() => {
                      setTip(200000)
                      setShowTipInput(true)
                    }}
                    color={+tip === 200000 ? 'success' : 'dark'}
                    disabled={payInprogress}>
                    {(200000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                  <IonChip
                    onClick={() => {
                      setTip(500000)
                      setShowTipInput(true)
                    }}
                    color={+tip === 500000 ? 'success' : 'dark'}
                    disabled={payInprogress}>
                    {(500000).toLocaleString(navigator.language)} {'ریال'}
                  </IonChip>
                </IonItem>
              )}
              {showTipInput && (
                <IonItem>
                  <CurrencyIonInput
                    defaultValue={tip}
                    onValueChange={setTip}
                    placeholder={'مبلغ مورد نظر برای انعام (ریال)'}
                    disabled={payInprogress}
                  />
                </IonItem>
              )}
            </>
          )}
        </IonCardContent>
      </IonCard>
      <IonCard className='ion-no-padding' style={{ border: '1px solid white', marginTop: '10px' }}>
        <IonCardContent style={{ paddingTop: 0 }}>
          {!!itemsPrice && (
            <IonRow>
              <IonCol>{'مبلغ سفارش'}</IonCol>
              <IonCol className='ion-text-start'>
                {itemsPrice.toLocaleString(navigator.language)} {'ریال'}
              </IonCol>
            </IonRow>
          )}
          {!!shippingPriceActual() && (
            <IonRow>
              <IonCol>{'هزینه پیک'}</IonCol>
              <IonCol className='ion-text-start'>
                {shippingPriceActual().toLocaleString(navigator.language)} {'ریال'}
              </IonCol>
            </IonRow>
          )}
          {!!taxPrice && (
            <IonRow>
              <IonCol>{'مالیات بر ارزش افزوده'}</IonCol>
              <IonCol className='ion-text-start'>
                {(+taxPrice).toLocaleString(navigator.language)} {'ریال'}
              </IonCol>
            </IonRow>
          )}
          {!!totalPrice && (
            <IonRow>
              <IonCol size='6'>
                <strong>مبلغ قابل پرداخت</strong>
              </IonCol>
              <IonCol size='6' className='ion-text-start'>
                <strong>
                  {totalPrice.toLocaleString(navigator.language)} {'ریال'}
                </strong>
              </IonCol>
            </IonRow>
          )}
          {isLoggedIn && (
            <>
              <WalletBalance showScore={true} showBorder={false} showBalanceText={false} />
              <div className={'p-2 mt-2'}>
                <div className={'text-center'}>
                  <button
                    disabled={formDisabled() || isReceiveInvalid()}
                    className='btn btn-score'
                    onClick={(e) => {
                      payForReal(true)
                    }}>
                    <div>امتیاز</div>
                  </button>
                  <button
                    disabled={formDisabled() || isReceiveInvalid()}
                    className='btn btn-wallet'
                    onClick={(e) => {
                      payForReal(false)
                    }}>
                    <div>کیف پول</div>
                  </button>
                </div>
              </div>
            </>
          )}
          {!isLoggedIn && (
            <div className='ion-text-center ion-padding'>
              <IonButton
                color='primary'
                onClick={() => payForReal(false)}
                disabled={formDisabled() || isReceiveInvalid()}>
                {'ورود به درگاه پرداخت'}
              </IonButton>
            </div>
          )}
          {payInprogress && (
            <div className='ion-text-center'>
              <>
                <IonSpinner name='crescent' color='dark' />
                {'لطفا منتظر بمانید...'}
              </>
            </div>
          )}
        </IonCardContent>
      </IonCard>
      <Modal
        id='shopping-confirm-address-modal'
        showModal={showAddressModal}
        toggle={setShowAddressModal}
        title={'روش دریافت سفارش'}
        onClosed={() => setShowAddressModal(false)}
        contentUsePadding={false}
        content={
          <MerchantProfileShippingAddressPage
            acceptorReferralCode={acceptorCode}
            defaultReceiveType={defaultReceiveType}
          />
        }
        actionButtons={
          <>
            <IonButton
              color='primary'
              onClick={() => setShowAddressModal(false)}
              disabled={
                !shippingAddress?.number ||
                !shippingAddress?.part ||
                !shippingAddress?.latitude ||
                !shippingAddress?.longitude
              }>
              {'انتخاب'}
            </IonButton>
          </>
        }
      />
      <Modal
        showModal={showWalletDepositModal}
        toggle={setShowWalletDepositModal}
        onClosed={() => setShowWalletDepositModal(false)}
        title={'افزایش موجودی'}
        content={
          <WalletDeposit
            amount={neededAmount + ''}
            originalCommand={originalCommand}
            originalCommandType={'AYRIA_PAYMENT'}
            targetAmount={totalPrice}
            onLeave={async () => {
              await setKalaList(kalaList.filter((k) => k.acceptorCode !== +acceptorCode))
              await setReceiveType(null)
              await setPickupDelay(null)
            }}
          />
        }
      />
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    shippingAddress: state.user.shippingAddress,
    receiveType: state.user.receiveType,
    pickupDelay: state.user.pickupDelay,
    kalaList: state.user.kalaList,
    loggedUser: state.user.loggedUser,
    tableIdentifier: state.user.tableIdentifier,
  }),
  mapDispatchToProps: {
    setShippingAddress,
    setTableIdentifier,
    setKalaList,
    setReceiveType,
    setPickupDelay,
  },
  component: ShoppingCartConfirm,
})
