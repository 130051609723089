import React, { useState, useEffect } from 'react'
import { IonButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { URLSearchParams } from 'whatwg-url'
import { GOLD_PRICE, STAR_PRICE, GALAXY_PRICE } from '../../../config/plan-price'
import { ApiService, useToast, connect, ReferralCode } from '../../../ayria'
import './purchase-plan.css'
import '../../../App-deprecated.scss'

declare var document: any

interface StateProps {}
interface PurchasePlanProps extends StateProps {}

const PurchasePlan: React.FC<PurchasePlanProps> = () => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [profile, setProfile] = useState<any>()
  const [invoice, setInvoice] = useState<any>()
  const [planobj] = useState({
    type: urlSearchParams.get('plan'),
  })
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [token, setToken] = useState('')
  const [inprogress, setInprogress] = useState(false)
  const [form, setForm] = useState<any>({})

  const getPlanInvoice = async () => {
    const res: any = await api.post('/account/plan/select', { plan: planobj.type })
    if (res.ok) {
      setInvoice(res.data)
    } else {
      toast.error(res)
      handleCancel()
    }
  }
  const getInvoiceNumber = async () => {
    form.ipgCompany = invoice?.ipgCompany
    form.amount = invoice?.hiddens[0].value
    const res: any = await api.post('/invoice/extra-info', form)
    if (res.ok) {
      setInvoiceNumber(res.data.invoiceNumber)
      setToken(res.data.token)
    } else {
      toast.error(res)
    }
  }
  const getCustomerInfo = async () => {
    const profileRes: any = await api.get('/account/profile')
    setProfile(profileRes.ok ? profileRes.data : {})
  }
  const handleCancel = () => {
    history.push('/plan')
  }
  const handleSubmit = async () => {
    setInprogress(true)
    const res: any = await api.post(`/invoice/update-referral-code/${invoiceNumber}`, form)
    if (res.ok) {
      document.getElementById('form').submit()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const isIpgFanava = () => invoice?.ipgCompany === 'FANAVA'
  const isIpgNovin = () => invoice?.ipgCompany === 'NOVIN'

  useEffect(() => {
    getCustomerInfo()
    getPlanInvoice()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (invoice?.ipgCompany) {
      getInvoiceNumber()
    }
  }, [invoice]) // eslint-disable-line

  return (
    <>
      {invoice && (
        <div className='page-content'>
          <section>
            <div className='container'>
              <div>
                <table className='table table-hover border mb-5 col-md-8 col-xs-12 table-responsive'>
                  <tbody>
                    <tr>
                      <td className={'text-right'}>نام و نام خانوادگی</td>
                      <td className={'text-right'}>{`${profile?.firstName} ${profile?.lastName}`}</td>
                    </tr>
                    <tr>
                      <td className={'text-right'}>شماره همراه </td>
                      <td className={'text-right'} colSpan={2}>
                        {profile?.firstName}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={'scroll-x'}>
                  <table className='table table-hover border col-12'>
                    <thead>
                      <tr className={'gridHeader'}>
                        <th scope='col'>کد پیگیری</th>
                        <th className='text-center' scope='col'>
                          محصول
                        </th>
                        <th className='text-center' scope='col'>
                          قیمت
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{invoiceNumber}</td>
                        <td className='text-center'>
                          {planobj?.type === 'GOLD'
                            ? 'طرح طلایی'
                            : planobj?.type === 'STAR'
                            ? 'طرح ستاره '
                            : 'طرح کهکشان'}
                        </td>
                        <td className='text-center'>
                          {planobj?.type === 'GOLD' ? GOLD_PRICE : planobj?.type === 'STAR' ? STAR_PRICE : GALAXY_PRICE}{' '}
                          {'ریال '}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}> مالیات بر ارزش افزوده</td>
                        <td className='text-center'>9%</td>
                      </tr>
                      <tr>
                        <td colSpan={2}> جمع کل</td>
                        <td className='text-center'>
                          {invoice?.hiddens[0].value} {'ریال '}
                        </td>
                      </tr>
                      {invoice?.activateReferral && (
                        <tr>
                          <td colSpan={3}>
                            <ReferralCode
                              callback={(referral: any) => setForm({ ...form, referralCode: referral.code })}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <form method='post' action={invoice.actionUrl} className='purchase-form' id='form'>
                    {invoice.hiddens.map((hidden: any, index: number) => {
                      return <input type='hidden' name={hidden.name} value={hidden.value} key={hidden.name} />
                    })}
                    {isIpgFanava() && <input type='hidden' name={invoice.invoiceNumberKey} value={invoiceNumber} />}
                    {isIpgNovin() && <input type='hidden' name={invoice.invoiceNumberKey} value={token} />}
                    <IonButton disabled={inprogress} onClick={handleSubmit}>
                      {t('label.goToPayment')}
                    </IonButton>
                  </form>
                  <IonButton color='light' onClick={handleCancel}>
                    {t('label.cancel')}
                  </IonButton>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default connect<{}, StateProps, {}>({
  component: PurchasePlan,
})
