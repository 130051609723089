import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Header } from '../ayria'
import Profile from '../components/screens/profile/profile'

const Account = () => {
  return (
    <IonPage id='account'>
      <Header />
      <IonContent>
        <Profile />
      </IonContent>
    </IonPage>
  )
}

export default Account
