import React, { useEffect } from 'react'

declare var window: any

// interface CredentialRequestOptions {
//   otp: OTPOptions
// }
// interface OTPOptions {
//   transport: string[]
// }

interface OwnProps {
  onReceive: Function
}
interface WebOTPProps extends OwnProps {}

const WebOTP: React.FC<WebOTPProps> = ({ onReceive }) => {
  const initWebOPT = () => {
    if ('OTPCredential' in window) {
      let input: any
      setTimeout(() => {
        input = document.querySelector('input[autocomplete="one-time-code"]')
        if (!input) {
          console.log('input[autocomplete="one-time-code"] not found!')
        }
      }, 1000)
      const ac = new AbortController()
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp: any) => {
          onReceive(otp?.code)
          if (input) {
            input.value = otp?.code
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
    } else {
      console.log('No OTPCredential in window')
    }
  }

  useEffect(() => {
    initWebOPT()
  }, []) // eslint-disable-line

  return <></>
}

export default WebOTP
