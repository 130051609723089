import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Header } from '../../../ayria'

interface ProductCategoryManagementProps {}
const ProductCategoryManagement: React.FC<ProductCategoryManagementProps> = () => {
  return (
    <IonPage id='product-category-managment-page'>
      <IonContent fullscreen={true} className='content md-5'>
        <Header title='دسته بندی و صفحات' />
      </IonContent>
    </IonPage>
  )
}

export default ProductCategoryManagement
