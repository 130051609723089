import {
  getUserData,
  setIsLoggedInData,
  setUsernameData,
  setHasSeenLandingData,
  setLoggedUserData,
  setThemeData,
  setHasSeenRegisterCenterData,
  setHasSeenRegisterClubData,
  setHasSeenClubListData,
  setDarkModeData,
  setReferralCodeData,
  setClubCodeData,
  setSavedClubData,
  setKalaListData,
  addKalaData,
  removeKalaData,
  setShippingAddressData,
  setReceiveTypeData,
  setPickupDelayData,
  setGiftTokenData,
  setLastSearchData,
  setFailureCountData,
} from '../dataApi'
import { ActionType } from '../../utility/types'
import { UserState } from './user.state'
import { User, Club } from '../../models'
import React from 'react'

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true))
  const data: any = await getUserData()
  dispatch(setData(data))
  dispatch(setLoading(false))
}

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-user-loading',
    isLoading,
  } as const)

export const setData = (data: Partial<UserState>) =>
  ({
    type: 'set-user-data',
    data,
  } as const)

export const setIsLoggedIn = (isLoggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(isLoggedIn)
  return {
    type: 'set-is-logged-in',
    isLoggedIn,
  } as const
}

export const setUsername = (username?: string) => async (dispatch: React.Dispatch<any>) => {
  await setUsernameData(username)
  return {
    type: 'set-username',
    username,
  } as const
}

export const setHasSeenLanding = (hasSeenLanding: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenLandingData(hasSeenLanding)
  return {
    type: 'set-has-seen-landing',
    hasSeenLanding,
  } as const
}

export const setLoggedUser = (loggedUser?: User) => async (dispatch: React.Dispatch<any>) => {
  await setLoggedUserData(loggedUser)
  return {
    type: 'set-logged-user',
    loggedUser,
  } as const
}

export const setSavedClub = (savedClub?: Club) => async (dispatch: React.Dispatch<any>) => {
  await setSavedClubData(savedClub)
  return {
    type: 'set-saved-club',
    savedClub,
  } as const
}

export const setTheme = (theme: string) => async (dispatch: React.Dispatch<any>) => {
  await setThemeData(theme)
  return {
    type: 'set-theme',
    theme,
  } as const
}

export const setReferralCode = (referralCode: string) => async (dispatch: React.Dispatch<any>) => {
  await setReferralCodeData(referralCode)
  return {
    type: 'set-referral-code',
    referralCode,
  } as const
}

export const setClubCode = (clubCode: string) => async (dispatch: React.Dispatch<any>) => {
  await setClubCodeData(clubCode)
  return {
    type: 'set-club-code',
    clubCode,
  } as const
}

export const setGiftToken = (giftToken?: string) => async (dispatch: React.Dispatch<any>) => {
  await setGiftTokenData(giftToken)
  return {
    type: 'set-gift-token',
    giftToken,
  } as const
}

export const setLastSearch = (lastSearch?: string) => async (dispatch: React.Dispatch<any>) => {
  await setLastSearchData(lastSearch)
  return {
    type: 'set-last-search',
    lastSearch,
  } as const
}

export const setHasSeenRegisterCenter = (hasSeenRegisterCenter: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenRegisterCenterData(hasSeenRegisterCenter)
  return {
    type: 'set-has-seen-register-center',
    hasSeenRegisterCenter,
  } as const
}

export const setHasSeenRegisterClub = (hasSeenRegisterClub: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenRegisterClubData(hasSeenRegisterClub)
  return {
    type: 'set-has-seen-register-club',
    hasSeenRegisterClub,
  } as const
}

export const setHasSeenClubList = (hasSeenClubList: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setHasSeenClubListData(hasSeenClubList)
  return {
    type: 'set-has-seen-club-list',
    hasSeenClubList,
  } as const
}

export const setDarkMode = (darkMode: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setDarkModeData(darkMode)
  return {
    type: 'set-dark-mode',
    darkMode,
  } as const
}

export const setMenuEnabled = (menuEnabled: boolean) =>
  ({
    type: 'set-menu-enabled',
    menuEnabled,
  } as const)

export const setKalaList = (kalaList: any[]) => async (dispatch: React.Dispatch<any>) => {
  await setKalaListData(kalaList)
  return {
    type: 'set-kala-list',
    kalaList,
  } as const
}

export const addKala = (kala: any) => async (dispatch: React.Dispatch<any>) => {
  const kalaList = await addKalaData(kala)
  return {
    type: 'set-kala-list',
    kalaList,
  } as const
}

export const removeKala = (kala: any) => async (dispatch: React.Dispatch<any>) => {
  const kalaList = await removeKalaData(kala)
  return {
    type: 'set-kala-list',
    kalaList,
  } as const
}

export const setWalletBalance = (walletBalance: number) =>
  ({
    type: 'set-wallet-balance',
    walletBalance,
  } as const)

export const setScoreBalance = (scoreBalance: number) =>
  ({
    type: 'set-score-balance',
    scoreBalance,
  } as const)

export const setPinChecked = (pinChecked: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-pin-checked',
    pinChecked,
  } as const
}

export const setUnpaidFactorCount = (unpaidFactorCount: number) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-unpaid-factor-count',
    unpaidFactorCount,
  } as const
}

export const setUnpaidPersonCount = (unpaidPersonCount: number) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-unpaid-person-count',
    unpaidPersonCount,
  } as const
}

export const setShippingAddress = (address?: any) => async (dispatch: React.Dispatch<any>) => {
  await setShippingAddressData(address)
  return {
    type: 'set-shipping-address',
    address,
  } as const
}

export const setActiveOrderCount = (activeOrderCount?: number) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-active-order-count',
    activeOrderCount,
  } as const
}

export const setReceiveType = (receiveType?: string) => async (dispatch: React.Dispatch<any>) => {
  await setReceiveTypeData(receiveType)
  return {
    type: 'set-receive-type',
    receiveType,
  } as const
}

export const setPickupDelay = (pickupDelay?: number) => async (dispatch: React.Dispatch<any>) => {
  await setPickupDelayData(pickupDelay)
  return {
    type: 'set-pickup-delay',
    pickupDelay,
  } as const
}

export const setTableIdentifier = (tableIdentifier?: string) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-table-identifier',
    tableIdentifier,
  } as const
}

export const setFailureCount = (failureCount: number) => async (dispatch: React.Dispatch<any>) => {
  await setFailureCountData(failureCount)
  return {
    type: 'set-failure-count',
    failureCount,
  } as const
}

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setHasSeenLanding>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setMenuEnabled>
  | ActionType<typeof setWalletBalance>
  | ActionType<typeof setLoggedUser>
  | ActionType<typeof setScoreBalance>
  | ActionType<typeof setTheme>
  | ActionType<typeof setPinChecked>
  | ActionType<typeof setHasSeenRegisterCenter>
  | ActionType<typeof setHasSeenRegisterClub>
  | ActionType<typeof setHasSeenClubList>
  | ActionType<typeof setUnpaidFactorCount>
  | ActionType<typeof setUnpaidPersonCount>
  | ActionType<typeof setReferralCode>
  | ActionType<typeof setClubCode>
  | ActionType<typeof setSavedClub>
  | ActionType<typeof setKalaList>
  | ActionType<typeof addKala>
  | ActionType<typeof removeKala>
  | ActionType<typeof setShippingAddress>
  | ActionType<typeof setActiveOrderCount>
  | ActionType<typeof setReceiveType>
  | ActionType<typeof setPickupDelay>
  | ActionType<typeof setTableIdentifier>
  | ActionType<typeof setGiftToken>
  | ActionType<typeof setLastSearch>
  | ActionType<typeof setFailureCount>
