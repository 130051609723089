import React, { useState, useEffect, lazy } from 'react'
import { IonSegment, IonSegmentButton } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import api from '../../../api/Api'
import Auth from '../../../utility/auth/Auth'
const ReactPasswordStrength = lazy(() => import('react-password-strength'))

const ScreenPasswordChange = () => {
  const auth = new Auth()
  const user = auth.getLoggedInUser() || {}
  const history = useHistory()
  const [dataObject, setDataObject] = useState({})
  const [errorList, setErrorList] = useState([])
  const [isProcessSuccesful, setIsProcessSuccesful] = useState(false)
  const { t } = useTranslation()
  const [avatarSrc, setAvatarSrc] = useState()

  const handleOnInputChange = (event) => {
    setDataObject({ ...dataObject, [event.target.id]: event.target.value })
  }
  const changePassword = (event) => {
    setDataObject({ ...dataObject, newPassword: event.password })
  }
  const changeConfirmPassword = (event) => {
    setDataObject({ ...dataObject, newPasswordConfirm: event.password })
  }
  async function handlePasswordReset() {
    setIsProcessSuccesful(false)
    try {
      const response = await api.fn.passwordChange(dataObject)
      if (response.ok) {
        setIsProcessSuccesful(true)
        history.push('/login')
      } else {
        const errors = []
        if (response.data.title === 'Incorrect password') errors.push(t('passwordReset.messages.error2'))
        if (errors.length) {
          setErrorList(errors)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const validateForm = () => {
    const errors = []
    if (!dataObject.currentPassword || !dataObject.newPassword || !dataObject.newPasswordConfirm) {
      errors.push(t('common_errors.all_fields_are_required_violation'))
    }
    if (dataObject.newPassword !== dataObject.newPasswordConfirm) {
      errors.push(t('common_errors.password_not_matching_violation'))
    }
    return errors
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const errors = validateForm()
    if (errors.length) {
      setErrorList(errors)
    } else {
      handlePasswordReset()
    }
  }

  useEffect(() => {
    setAvatarSrc(user.gender === 'MALE' ? '/assets/images/user_m.jpeg' : '/assets/images/user_f.jpeg')
  }, [user.gender])

  return (
    <div className={'row'}>
      <div className={' col-md-3 col-xs-12 mb-3'}>
        <div className={'page'}>
          {' '}
          <div className={'profile-image-container text-center'}>
            <div className={'img-circle profile-image'}>
              {/* the actual size should be bigger than display size for retina screens */}
              <img alt='' style={{ width: '150px' }} src={avatarSrc}></img>
            </div>
            <div className={'profile-name-container'}>
              <span className={'profile-name'}>{`${user.firstName} ${user.lastName}`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={' col-md-9 col-xs-12'}>
        {' '}
        <div className={'page'}>
          <IonSegment value='chpass'>
            <IonSegmentButton value='chpass'>{'تغییر رمز عبور'}</IonSegmentButton>
          </IonSegment>
          <div className={'mt-3'}>
            {' '}
            <form>
              <div>
                {errorList.map((element) => (
                  <div className={'alert alert-danger'}>{element}</div>
                ))}
              </div>

              {isProcessSuccesful && <div className={'alert alert-success'}>{t('passwordReset.messages.success')}</div>}
              <div className={'form-group col-md-6 col-xs-12'}>
                <input
                  type={'password'}
                  id={'currentPassword'}
                  onChange={handleOnInputChange}
                  className={'form-control '}
                  placeholder={t('passwordReset.currentPassword')}
                />
              </div>
              <div className={'form-group col-md-6 col-xs-12'}>
                <ReactPasswordStrength
                  className='rtl'
                  minLength={5}
                  minScore={3}
                  scoreWords={['ضعیف', 'خوب', 'خیلی خوب', 'قوی', 'قوی تر']}
                  tooShortWord={'خیلی ضعیف'}
                  changeCallback={changePassword}
                  inputProps={{
                    id: 'newPassword',
                    name: 'newPassword',
                    autoComplete: 'off',
                    className: '',
                    placeholder: ' رمز عبور جدید',
                  }}
                />
              </div>
              <div className={'form-group col-md-6 col-xs-12'}>
                <ReactPasswordStrength
                  className='rtl'
                  minLength={5}
                  minScore={3}
                  scoreWords={['ضعیف', 'خوب', 'خیلی خوب', 'قوی', 'قوی تر']}
                  tooShortWord={'خیلی ضعیف'}
                  changeCallback={changeConfirmPassword}
                  inputProps={{
                    id: 'newPasswordConfirm',
                    name: 'newPasswordConfirm',
                    autoComplete: 'off',
                    className: '',
                    placeholder: 'تکرار رمز عبور جدید',
                  }}
                />
              </div>
              <div className={'text-left'}>
                <button className={'btn btn-theme  bg-success text-white btn-sm'} onClick={handleSubmit}>
                  {t('label.save')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreenPasswordChange
