import React, { useContext, useEffect, useState, lazy } from 'react'
import { MapContainer, TileLayer, FeatureGroup, LayerGroup, Marker, CircleMarker } from 'react-leaflet'
import { LayerContext } from './LayerContext'
import PointManager from './PointManager'
import AddMarkerButton from './AddMarkerButton'
import './LeafletMap.scss'
const DeliveryAreaSuggest = lazy(() => import('../../../components/units/suggest/DeliveryAreaSuggest'))

interface LeafletMapProps {
  onAreaAdded?: Function
  showDrawTools?: boolean
  showMarkerButton?: boolean
  showPoint?: boolean
  showCurrentLocation?: boolean
  showGeoSearch?: boolean
  acceptorCode?: number
}

const LeafletMap: React.FC<LeafletMapProps> = ({
  onAreaAdded = () => {},
  showDrawTools,
  showMarkerButton = false,
  showPoint = false,
  showCurrentLocation = false,
  showGeoSearch = false,
  acceptorCode,
}) => {
  const { center, zoom, areas, point, currentLocation } = useContext(LayerContext)
  const [reRender, setReRender] = useState(true)

  useEffect(() => {
    setReRender(false)
    setTimeout(() => {
      setReRender(true)
    }, 1000)
  }, []) // eslint-disable-line

  return (
    <>
      {reRender && (
        <>
          <MapContainer id='mapId' center={center} zoom={zoom} tap={false}>
            <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              attribution='&copy; OpenStreetMap contributors'></TileLayer>
            <FeatureGroup>{areas}</FeatureGroup>
            <PointManager
              onAreaAdded={onAreaAdded}
              showDrawTools={showDrawTools}
              showCurrentLocation={showCurrentLocation}
            />
            {showMarkerButton && <AddMarkerButton />}
            {showPoint && <LayerGroup>{point && <Marker position={point} />}</LayerGroup>}
            {showCurrentLocation && (
              <LayerGroup>{currentLocation && <CircleMarker center={currentLocation} />}</LayerGroup>
            )}
          </MapContainer>
          {showGeoSearch && (
            <div style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 999, width: '200px' }}>
              <DeliveryAreaSuggest acceptorCode={acceptorCode} onSelect={() => {}} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default LeafletMap
