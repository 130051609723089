import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cash1 from './icons/cash.png'
import cash1_gray from './icons/cash_gray.png'
import cash2 from './icons/cash2.png'
import cash2_gray from './icons/cash2_gray.png'
import cash3 from './icons/cash3.png'
import cash3_gray from './icons/cash3_gray.png'

interface PriceRateInputProps {
  onRateChange(rate: number): () => {}
}

const PriceRateInput: React.FC<PriceRateInputProps> = ({ onRateChange }) => {
  const [rate, setRate] = useState<number>()
  const { t } = useTranslation()

  const updateRate = (rate: number) => {
    setRate(rate)
    onRateChange(rate)
  }

  return (
    <>
      <div className='p-2'>{t('priceRateInput.price')}:</div>
      <div className='flex-container' style={{ justifyContent: 'center' }}>
        <span className='p-2 ion-text-center'>
          {rate !== 1 && (
            <img
              alt='1'
              src={cash1_gray}
              className='rating-icon'
              onClick={(e) => updateRate(1)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 1 && (
            <img alt='1' src={cash1} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span className={rate === 1 ? 'text-success' : ''} onClick={(e) => updateRate(1)}>
            {t('priceRateInput.cash1')}
          </span>
        </span>
        <span className='p-2 ion-text-center'>
          {rate !== 2 && (
            <img
              alt='2'
              src={cash2_gray}
              className='rating-icon'
              onClick={(e) => updateRate(2)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 2 && (
            <img alt='2' src={cash2} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span onClick={(e) => updateRate(2)}>{t('priceRateInput.cash2')}</span>
        </span>
        <span className='p-2 ion-text-center'>
          {rate !== 3 && (
            <img
              alt='3'
              src={cash3_gray}
              className='rating-icon'
              onClick={(e) => updateRate(3)}
              width='80px'
              heigth='80x'
            />
          )}
          {rate === 3 && (
            <img alt='3' src={cash3} className='rating-icon' onClick={(e) => updateRate(0)} width='80px' heigth='80x' />
          )}
          <br />
          <span className={rate === 3 ? 'text-danger' : ''} onClick={(e) => updateRate(3)}>
            {t('priceRateInput.cash3')}
          </span>
        </span>
      </div>
    </>
  )
}

export default PriceRateInput
