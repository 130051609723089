import React, { lazy } from 'react'
import { IonButton } from '@ionic/react'
import { API_BASE_URL } from '../../config'
import { useToast } from '../../utility/toaster/useToast'
import { CopyToClipboard } from 'react-copy-to-clipboard'
const AyrCode = lazy(() => import('./ayria/AyrCode'))

interface ClubVirtualShopQRCodeProps {
  clubCode: any
  acceptorCode: any
  showLink?: boolean
}

const ClubVirtualShopQRCode: React.FC<ClubVirtualShopQRCodeProps> = ({ clubCode, acceptorCode, showLink = false }) => {
  const toast = useToast()
  const qrcodeValue = `${API_BASE_URL.substr(0, API_BASE_URL.length - 4)}/c/${acceptorCode}/${clubCode}`
  return (
    <div className='ion-text-center'>
      <AyrCode value={qrcodeValue} size={280} logoOpacity={0.9} />
      {showLink && (
        <>
          <br />
          <CopyToClipboard text={qrcodeValue} onCopy={() => toast.info('لینک QrCode کپی شد')}>
            <IonButton fill='clear' color='primary'>
              {'کپی لینک Qr'}
            </IonButton>
          </CopyToClipboard>
        </>
      )}
    </div>
  )
}

export default ClubVirtualShopQRCode
