import React, { useState } from 'react'
import { IonPage } from '@ionic/react'
import { Header } from '../../ayria'
import AcceptorList from './AcceptorList'
import { ScrollContent } from '..'

interface AcceptorListTabProps {}

const AcceptorListTab: React.FC<AcceptorListTabProps> = () => {
  const [scrollTop, setScrollTop] = useState<number>(0)

  const onScroll = (event: any) => {
    setScrollTop(event.detail.scrollTop)
  }

  return (
    <IonPage id='acceptor-list-tab-page'>
      <ScrollContent className='ion-padding' onScroll={onScroll}>
        <Header />
        <AcceptorList scrollTop={scrollTop} />
      </ScrollContent>
    </IonPage>
  )
}

export default AcceptorListTab
