import React, { useState, useEffect, lazy } from 'react'
import { useParams } from 'react-router'
import { IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { ApiService, useToast, User, connect } from '../../ayria'
import { isDesktop, isMobile } from '../../utility'
import { setMenuEnabled } from '../../data'
import MyClubTabMobile from './MyClubTabMobile'
import MyClubTabDesktop from './MyClubTabDesktop'
import '../../App-deprecated.scss'

const Header = lazy(() => import('../shared/Header'))

interface OwnProps {}
interface StateProps {
  loggedUser?: User
}
interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
}
interface MyClubTabProps extends OwnProps, StateProps, DispatchProps {}

const MyClubTab: React.FC<MyClubTabProps> = ({ loggedUser, setMenuEnabled }) => {
  const api = new ApiService()
  const toast = useToast()
  const { code } = useParams<any>()
  const [item, setItem] = useState<any>()

  const getItem = async () => {
    const res: any = await api.getMyClub(code)
    if (res.ok) {
      setItem(res.data)
    } else {
      toast.error(res)
    }
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  return (
    <IonPage id='my-club-tab-page' className={item?.theme ? ` ${item.theme}-theme` : ''}>
      <IonContent>
        <Header />
        {isDesktop() && <MyClubTabDesktop code={code} loggedUser={loggedUser} club={item} />}
        {isMobile() && <MyClubTabMobile code={code} loggedUser={loggedUser} club={item} />}
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
  }),
  mapDispatchToProps: {
    setMenuEnabled,
  },
  component: MyClubTab,
})
