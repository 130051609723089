exports.categories = [
  { value: 'SPORT', label: 'تفریحی و ورزشی' },
  { value: 'CONSULTING', label: 'خدمات و مشاوره' },
  { value: 'DIGITAL', label: 'دیجیتال و لوازم اداری' },
  { value: 'RESTAURANT', label: 'رستوران و کافی شاپ' },
  { value: 'HEALTH', label: 'بهداشت و سلامت ' },
  { value: 'CLOTHING', label: 'پوشاک' },
  { value: 'TRAVEL', label: 'گردشگری' },
  { value: 'FORMALITY', label: 'تشریفات' },
  { value: 'STORE', label: 'فروشگاه حضوری و غیرحضوری' },
  { value: 'SCIENCE', label: 'علمی' },
  { value: 'CULTURE', label: 'فرهنگی' },
  { value: 'ART', label: 'هنر' },
  { value: 'JEWELLERY', label: 'ساعت، جواهر و سنگ قیمتی' },
  { value: 'BEAUTY', label: 'زیبایی و آرایشی' },
  { value: 'HOME_APPLIANCE', label: 'لوازم خانگی' },
]
