import api from './Api'
import geoCondingApi from './GeocodingApi'

const PUBLIC_REFERRAL_CODE = '/public/referral/{code}'
const PUBLIC_ACCEPTORS_DEPS = '/public/acceptors/deps'
const PUBLIC_ACCEPTORS_GETBYREFERRALCODE = '/public/acceptors/getByReferralCode/{referralCode}'
const PUBLIC_AYRIA_PAYMENTS_ADDRESS_IN = '/public/ayria-payments/address/in'
const ACCOUNT_PLAN = '/account/plan'
const USER_GEO_OSTANS = '/user/geo/states'
const USER_GEO_CURRENT_OSTAN = '/user/geo/state'
const USER_REGISTER_ACCEPTOR = '/user/acceptor/register'
const USER_REGISTER_CLUB = '/user/club/register'
const USER_ACCEPTOR_MYCENTERS = '/user/acceptor/myCenters'
const USER_CLUB_UPDATE = '/user/club/update'
const USER_CLUB_UPDATE_LOGO = '/user/club/update-logo'
const USER_CLUB_UPDATE_BANNER = '/user/club/update-banner'
const USER_CLUB_MYCLUBS = '/user/club/myClubs/{acceptorCode}'
const USER_CLUB_GET = '/user/club/get/{code}'
const USER_CLUB_ACCEPTORS = '/user/club/get/{code}/acceptors'
const USER_CLUB_GET_MEMBERS = '/user/club/get/{code}/members'
const USER_CLUB_ACCEPTOR_DISCOUNT_UPDATE = '/user/club/acceptor/discount-update'
const USER_CLUB_ADD_ACCEPTOR = '/user/club/add/{code}/acceptor/{acceptorCode}'
const USER_CLUB_DELETE_ACCEPTOR = '/user/club/delete/{code}/acceptor/{acceptorCode}'
const USER_CLUB_JOIN = '/user/club/join/{code}'
const USER_WALLET_THREAD = '/user/wallet/thread'
const USER_WALLET_SNAPSHOTS_CURRENT = '/user/wallet-snapshots/current'
const USER_VOUCHER_BALANCE = '/user/voucher/balance/{clubCode}'
const CUSTOMER_SCORES_TOTAL = '/customer/scores/total/0'
const PUBLIC_DELIVERY_HOURS_OKAY = '/public/delivery-hours/{acceptorCode}/okay'
const SHIPPING_ADDRESSES_IN = '/shipping-addresses/{id}/in/{acceptorCode}'
const WALLET_PURCHASE = '/wallet/purchase'
const WALLET_PURCHASE_PREVIEW = '/wallet/purchase/preview'
const ACCEPTOR_SEND_DOCUMENT = '/acceptor/send-document'
const ACCEPTOR_UPDATE_STATUS = '/acceptors/status'
const ACCEPTOR_SELECT_BANK = '/acceptor/bank-default/{id}/{paymentToolId}/{checked}'
const ACCEPTOR_NEW_BANK_ACCOUNT = '/acceptor/bank-new/{id}'
const ACCEPTOR_DELETE_BANK_ACCOUNT = '/acceptor/bank-delete/{id}/{paymentToolId}'
const ACCEPTOR_DELETE_DOCUMENT = '/acceptor/delete-document'
const ACCEPTOR_BY_ID = '/acceptor/current/{acceptorId}/id'
const ACCEPTOR_KALA_ORDERED = '/acceptor/kala/{acceptorCode}/ordered'
const ACCEPTOR_KALA_REORDER = '/acceptor/kala/{acceptorCode}/reorder'
const ACCEPTOR_KALA_GROUP_SUGGEST = '/acceptor/kala/group-suggest'
const ACCEPTOR_DELIVERY_MAP_CREATE_PRICE = '/acceptor/delivery-map/create-price'
const ACCEPTOR_DELIVERY_MAP_UPDATE_PRICE = '/acceptor/delivery-map/update-price'
const ACCEPTOR_DELIVERY_MAP_ALL = '/acceptor/delivery-map/all/{acceptorCode}'
const ACCEPTOR_DELIVERY_MAP_DELETE = '/acceptor/delivery-map/delete/{deliveryPriceId}'
const ACCEPTOR_ROLES_GET_TERMINALS = '/acceptor-roles/{acceptorCode}/terminals'
const ACCEPTOR_ROLES_UPDATE_TERMINAL = '/acceptor-roles/{acceptorCode}/terminal/update'
const ACCEPTOR_ROLES_FACTORS_ONLINE_ORDER = '/acceptor-roles/factors/{acceptorCode}/online-order'
const ACCEPTOR_ROLES_AYRIA_PAYMENT_UPDATE_CASHIER =
  '/acceptor-roles/{acceptorCode}/ayria-payment/{trackingNumber}/update-cashier'
const ACCEPTOR_ROLES_AYRIA_PAYMENT_UPDATE_ORDER_DONE =
  '/acceptor-roles/{acceptorCode}/ayria-payment/{trackingNumber}/update-order-done/{orderDone}'

class ApiService {
  async publicReferralCode(code: string) {
    return this.post(PUBLIC_REFERRAL_CODE.replace('{code}', code), {})
  }
  async publicAcceptorsDeps(clubCode?: string) {
    return this.get(`${PUBLIC_ACCEPTORS_DEPS}${clubCode ? `?clubCode=${clubCode}` : ''}`)
  }
  async publicAcceptorsGetByReferralCode(referralCode: string) {
    return this.get(PUBLIC_ACCEPTORS_GETBYREFERRALCODE.replace('{referralCode}', referralCode))
  }
  async publicAyriaPaymentsAddressIn(cmd: any) {
    return this.post(PUBLIC_AYRIA_PAYMENTS_ADDRESS_IN, cmd)
  }

  async accountPlan() {
    return this.get(ACCOUNT_PLAN)
  }

  async userClubUpdateLogo(cmd: any) {
    return this.put(USER_CLUB_UPDATE_LOGO, cmd)
  }
  async userClubUpdateBanner(cmd: any) {
    return this.put(USER_CLUB_UPDATE_BANNER, cmd)
  }
  async userUpdateClub(cmd: any) {
    return this.put(USER_CLUB_UPDATE, cmd)
  }
  async userRegisterClub(cmd: any) {
    return this.post(USER_REGISTER_CLUB, cmd)
  }
  async userRegisterAcceptor(cmd: any) {
    return this.post(USER_REGISTER_ACCEPTOR, cmd)
  }
  async userClubAddAcceptor(code: string, acceptorCode: string) {
    return this.put(USER_CLUB_ADD_ACCEPTOR.replace('{code}', code).replace('{acceptorCode}', acceptorCode), {})
  }
  async userClubDeleteAcceptor(code: string, acceptorCode: string) {
    return this.delete(USER_CLUB_DELETE_ACCEPTOR.replace('{code}', code).replace('{acceptorCode}', acceptorCode))
  }
  async userClubJoin(code: string) {
    return this.post(USER_CLUB_JOIN.replace('{code}', code), {})
  }
  async userWalletThread() {
    return this.get(USER_WALLET_THREAD)
  }
  async userWalletSnapshotsCurrent(cmd: any) {
    return this.post(USER_WALLET_SNAPSHOTS_CURRENT, cmd)
  }
  async userVoucherBalance(clubCode = '0') {
    return this.get(USER_VOUCHER_BALANCE.replace('{clubCode}', clubCode))
  }
  async shippingAddressesIn(id: string, acceptorCode: string) {
    return this.post(SHIPPING_ADDRESSES_IN.replace('{id}', id).replace('{acceptorCode}', acceptorCode), {})
  }
  async customerScoresTotal() {
    return this.get(CUSTOMER_SCORES_TOTAL)
  }
  async publicDeliveryHoursOkay(acceptorCode: string) {
    return this.get(PUBLIC_DELIVERY_HOURS_OKAY.replace('{acceptorCode}', acceptorCode))
  }
  async walletPurchase(cmd: any) {
    return this.post(WALLET_PURCHASE, cmd)
  }
  async walletPurchasePreview(cmd: any) {
    return this.post(WALLET_PURCHASE_PREVIEW, cmd)
  }

  async updateAcceptorStatus(cmd: any) {
    return this.put(ACCEPTOR_UPDATE_STATUS, cmd)
  }
  async registerNewBankAccount(acceptorId: string, paymentToolCommand: any) {
    return this.post(ACCEPTOR_NEW_BANK_ACCOUNT.replace('{id}', acceptorId), paymentToolCommand)
  }
  async deleteAcceptorBank(id: string, paymentToolId: string) {
    return this.delete(ACCEPTOR_DELETE_BANK_ACCOUNT.replace('{id}', id).replace('{paymentToolId}', paymentToolId))
  }

  async selectAcceptorBank(id: string, paymentToolId: string, checked: string) {
    return this.put(
      ACCEPTOR_SELECT_BANK.replace('{id}', id).replace('{paymentToolId}', paymentToolId).replace('{checked}', checked),
      {}
    )
  }

  async getPosTerminals(acceptorCode: string) {
    return this.get(ACCEPTOR_ROLES_GET_TERMINALS.replace('{acceptorCode}', acceptorCode))
  }
  async updateTerminal(acceptorCode: string, cmd: any) {
    return this.put(ACCEPTOR_ROLES_UPDATE_TERMINAL.replace('{acceptorCode}', acceptorCode), cmd)
  }
  async acceptorKalaOrdered(acceptorCode: string) {
    return this.get(ACCEPTOR_KALA_ORDERED.replace('{acceptorCode}', acceptorCode))
  }
  async acceptorKalaReorder(acceptorCode: string, cmd: any) {
    return this.put(ACCEPTOR_KALA_REORDER.replace('{acceptorCode}', acceptorCode), cmd)
  }
  async acceptorKalaGroupSuggest() {
    return this.get(ACCEPTOR_KALA_GROUP_SUGGEST)
  }
  async acceptorRolesFactorsOnlineOrderCount(acceptorCode: string) {
    return this.get(ACCEPTOR_ROLES_FACTORS_ONLINE_ORDER.replace('{acceptorCode}', acceptorCode))
  }
  async acceptorDeliveryMapCreatePrice(cmd: any) {
    return this.post(ACCEPTOR_DELIVERY_MAP_CREATE_PRICE, cmd)
  }
  async acceptorDeliveryMapUpdatePrice(cmd: any) {
    return this.put(ACCEPTOR_DELIVERY_MAP_UPDATE_PRICE, cmd)
  }
  async acceptorDeliveryMapAll(acceptorCode: string) {
    return this.get(ACCEPTOR_DELIVERY_MAP_ALL.replace('{acceptorCode}', acceptorCode))
  }
  async acceptorDeliveryMapDelete(deliveryPriceId: string) {
    return this.delete(ACCEPTOR_DELIVERY_MAP_DELETE.replace('{deliveryPriceId}', deliveryPriceId))
  }
  async acceptorRolesAyriaPaymentUpdateCashier(acceptorCode: string, trackingNumber: string) {
    return this.put(
      ACCEPTOR_ROLES_AYRIA_PAYMENT_UPDATE_CASHIER.replace('{acceptorCode}', acceptorCode).replace(
        '{trackingNumber}',
        trackingNumber
      )
    )
  }
  async acceptorRolesAyriaPaymentUpdateOrderDone(acceptorCode: string, trackingNumber: string, orderDone: string) {
    return this.put(
      ACCEPTOR_ROLES_AYRIA_PAYMENT_UPDATE_ORDER_DONE.replace('{acceptorCode}', acceptorCode)
        .replace('{trackingNumber}', trackingNumber)
        .replace('{orderDone}', orderDone)
    )
  }

  async getAcceptorById(acceptorId: string) {
    return this.get(ACCEPTOR_BY_ID.replace('{acceptorId}', acceptorId))
  }

  async myClubs(acceptorCode: string) {
    return this.get(USER_CLUB_MYCLUBS.replace('{acceptorCode}', acceptorCode))
  }

  async myCenters() {
    return this.get(USER_ACCEPTOR_MYCENTERS)
  }

  async updateClubAcceptorDiscount(discountUpdateCommand: any) {
    return this.put(USER_CLUB_ACCEPTOR_DISCOUNT_UPDATE, discountUpdateCommand)
  }

  async userClubGetMembers(code: string, params: string) {
    return this.get(USER_CLUB_GET_MEMBERS.replace('{code}', code) + params)
  }

  async getMyClubAcceptors(code: string, params: string) {
    return this.get(USER_CLUB_ACCEPTORS.replace('{code}', code) + params)
  }

  async getMyClub(code: string) {
    return this.get(USER_CLUB_GET.replace('{code}', code))
  }

  async getCurrentOstan() {
    return this.get(USER_GEO_CURRENT_OSTAN)
  }

  async getOstans() {
    return this.get(USER_GEO_OSTANS)
  }

  async updateAcceptorProfile(data: any) {
    return this.post(ACCEPTOR_SEND_DOCUMENT, data)
  }

  async deleteAcceptorDocument(documentType: string, acceptorId: number) {
    return this.delete(`${ACCEPTOR_DELETE_DOCUMENT}/${documentType}/${acceptorId}`)
  }

  async geocodingReverse(latlng: any, zoomLevel: any) {
    return geoCondingApi.get(
      `/reverse.php?lat=${latlng?.lat}&lon=${latlng.lng}&format=json&addressdetails=1&countrycodes=ir&accept-language=fa&zoom=18`
    )
  }

  async get(url: string, params?: any, axiosConfig?: any) {
    try {
      return api.get(url, params, axiosConfig)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`ApiService#get(${url}) failure: ${error}`)
      }
      // toast.error(error)
    }
  }

  async post(url: string, entity: any) {
    try {
      return api.post(url, entity)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`ApiService#post(${url}, ${entity}) failure: ${error}`)
      }
      // toast.error(error)
    }
  }

  async put(url: string, entity: any) {
    try {
      return api.put(url, entity)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`ApiService#put(${url}, ${entity}) failure: ${error}`)
      }
      // toast.error(error)
    }
  }

  async delete(url: string) {
    try {
      return api.delete(url)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`ApiService#delete(${url}) failure: ${error}`)
      }
      // toast.error(error)
    }
  }

  setHeader(header: string, value: any) {
    api.setHeader(header, value)
  }
}

export default ApiService
