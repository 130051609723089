import React, { useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import './lightbox.css'

interface LightboxProps {
  isOpen: boolean
  firstImage: any
  images: any[]
  onClose: () => void
}

const Lightbox: React.FC<LightboxProps> = ({ isOpen, firstImage, images, onClose }) => {
  const imageGalleryRef = useRef<any>()
  const formattedImages: any[] = images.map((element: any) => ({ original: element }))
  useEffect(() => {
    const imageGalleryRefConst = imageGalleryRef.current || ({} as any)
    isOpen &&
      imageGalleryRefConst.slideToIndex(
        formattedImages.indexOf(formattedImages.find((element: any) => element.original === firstImage))
      )
  }, [isOpen, firstImage, imageGalleryRef, formattedImages])

  return (
    <>
      {isOpen && (
        <div className={'lightbox'}>
          <div className={'close'} onClick={onClose} />
          <div>
            <ImageGallery
              showNav={false}
              disableSwipe={formattedImages.length < 1}
              showFullscreenButton={false}
              showBullets={false}
              showThumbnails={false}
              showPlayButton={false}
              showIndex={false}
              items={formattedImages}
              ref={imageGalleryRef}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Lightbox
