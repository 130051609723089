import React, { useEffect, useState } from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import PlanSelection from '../components/modules/plan-selection/plan-selection'
import CopyRight from './CopyRight'
import { setLoggedUser, setMenuEnabled } from '../data/user/user.actions'
import { connect } from '../data/connect'
import api from '../api/Api'
import { User } from '../models'
import { Auth, isDesktop } from '../utility'
import { SmallLogo, ReferralCode } from '../ayria'

interface StateProps {
  loggedUser?: User
  theme?: string
}

interface DispatchProps {
  setLoggedUser: typeof setLoggedUser
  setMenuEnabled: typeof setMenuEnabled
}

interface SelectPlanPageProps extends StateProps, DispatchProps {}

const SelectPlanPage: React.FC<SelectPlanPageProps> = ({ setLoggedUser, loggedUser, setMenuEnabled, theme }) => {
  const auth = new Auth()
  const history = useHistory()
  const [referral, setReferral] = useState<any>()

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  const getAccount = async () => {
    const res: any = await api.get('/account')
    if (res.ok && res.data) {
      console.log(res.data)
      const user: User = res.data
      auth.setLoggedInUser(user)
      await setLoggedUser(user)
      if (user && user.plan) {
        history.push('/tabs')
      }
    }
  }

  useEffect(() => {
    if (!(loggedUser && loggedUser.plan)) {
      getAccount()
    }
  }, []) // eslint-disable-line

  return (
    <IonPage
      className={(isDesktop() ? 'marginHorizontalAuto' : '') + ` ${referral?.theme ? referral?.theme : theme}-theme`}>
      <IonContent className='ion-padding' fullscreen>
        <IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonButtons slot='end'>
              <SmallLogo useBig={true} override={referral?.smallLogo} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <PlanSelection isNewMember={true} />
        <ReferralCode callback={(referral: any) => setReferral(referral)} hidden={true} />
        <br />
        <br />
        <CopyRight />
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setLoggedUser,
    setMenuEnabled,
  },
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
    theme: state.user.theme,
  }),
  component: SelectPlanPage,
})
