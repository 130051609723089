import React, { useState, useEffect, useContext } from 'react'
import { IonIcon } from '@ionic/react'
import { cart } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { LayerContext, LeafletMap } from '../shared/map'
import { Modal, AcceptorLogo, connect, ApiService } from '../../ayria'

interface OwnProps {
  payment: any
}

interface StateProps {}

interface OnlineOrderReceiptProps extends OwnProps, StateProps {}

const OnlineOrderReceipt: React.FC<OnlineOrderReceiptProps> = ({ payment }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const { setCenter, setZoom, setPoint } = useContext(LayerContext)
  const [item, setItem] = useState(payment)
  const [showShippingGeoModal, setShowShippingGeoModal] = useState(false)

  const getItem = async () => {
    const res: any = await api.get(`/user/ayria-payments/payer-response/${payment.trackingNumber}`)
    setItem(res.ok ? res.data : payment)
  }

  // eslint-disable-next-line
  const showShippingGeoOnMap = (shippingGeo: string) => {
    setShowShippingGeoModal(true)
    const latLng = shippingGeo.substring(6, shippingGeo.length - 1)
    setZoom(17)
    setCenter({ lat: latLng.split(' ')[0], lng: latLng.split(' ')[1] })
    setPoint({ lat: latLng.split(' ')[0], lng: latLng.split(' ')[1] })
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  return (
    <>
      <div className='printable' id='ayriaPayment'>
        {item.payeeType === 'ACCEPTOR' && (
          <div>
            <div className='text-center'>
              <AcceptorLogo referralCode={item.payeeReferralCode} width='100px' />
              <h3 className='print-only'>{payment.acceptorName}</h3>
            </div>
          </div>
        )}
        <table className='table'>
          <thead></thead>
          <tbody>
            <tr>
              <td>{t('walletRequest.createdDate')}</td>
              <td>
                {item.onlineOrder && (
                  <IonIcon icon={cart} color={!!item.orderDone ? 'dark' : !!item.cashier ? 'warning' : 'danger'} />
                )}
                {item.createdTime} {item.createdDate}
              </td>
            </tr>
            {item.receiveTime && (
              <tr>
                <td>{'زمان تحویل'}</td>
                <td>
                  {item.receiveType === 'PICKUP' && (
                    <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
                  )}
                  {item.receiveTime} {item.receiveDate}
                </td>
              </tr>
            )}
            {item?.kalas?.map((kala: any) => (
              <tr key={kala.name}>
                <td>
                  <span>
                    {kala.name} ({kala.count})
                  </span>
                </td>
                <td className='text-right'>
                  {kala.totalPrice.toLocaleString(navigator.language)} {' ریال'}
                </td>
              </tr>
            ))}
            {item.shippingFee > 0 && (
              <tr>
                <td>{'هزینه پیک'}</td>
                <td>
                  {item.shippingFee.toLocaleString(navigator.language)} {'ریال'}
                </td>
              </tr>
            )}
            {item.tax > 0 && (
              <tr>
                <td>{'مالیات بر ارزش افزوده'}</td>
                <td>
                  {item.tax.toLocaleString(navigator.language)} {'ریال'}
                </td>
              </tr>
            )}
            {item.tip > 0 && (
              <tr>
                <td>{'انعام'}</td>
                <td>
                  {item.tip.toLocaleString(navigator.language)} {'ریال'}
                </td>
              </tr>
            )}
            <tr>
              <td>
                <strong>{'جمع پرداختی'}</strong>
              </td>
              <td>
                <strong>
                  {item.customerAmountActual.toLocaleString(navigator.language)} {'ریال'}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        showModal={showShippingGeoModal}
        toggle={setShowShippingGeoModal}
        title={'آدرس تحویل'}
        isPlatformTheme={true}
        onClosed={() => setShowShippingGeoModal(false)}
        content={<LeafletMap showPoint={true} />}
      />
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({}),
  component: OnlineOrderReceipt,
})
