import { lazy } from 'react'
import CurrencyInput from './CurrencyInput/CurrencyInput'
import CurrencyIonInput from './CurrencyInput/CurrencyIonInput'
import NumberInput from './NumberInput/NumberInput'
import NumberIonInput from './NumberInput/NumberIonInput'
import MobileInput from './NumberInput/MobileInput'
import MobileIonInput from './NumberInput/MobileIonInput'
import AcceptorLogo from './acceptor/AcceptorLogo'
import VirtualShopQRCode from './VirtualShopQRCode'
import ClubVirtualShopQRCode from './ClubVirtualShopQRCode'
import AcceptorTableQrCode from './AcceptorTableQrCode'
import ClubAcceptorTableQrCode from './ClubAcceptorTableQrCode'
import ScoreBalance from './wallet/ScoreBalance'
import RateIcon from './RateIcon'
import AyriaSelect from './AyriaSelect'
import AyriaPaymentOnlineOrderCard from './cards/AyriaPaymentOnlineOrderCard'
import KalaImage from './KalaImage'
import MobileLookup from './suggest/MobileLookup'
import FullnameLookup from './suggest/FullnameLookup'
import AcceptorContact from './acceptor/AcceptorContact'
import AcceptorHasFeatureLoader from './acceptor/AcceptorHasFeatureLoader'
import AcceptorCurrentResolver from './acceptor/AcceptorCurrentResolver'
import CashdeskSelect from './acceptor/CashdeskSelect'
import AyriaIcon from './AyriaIcon'
import DeliveryHoursAllowed from './DeliveryHoursAllowed'
import BillTypeImage from './bill/BillTypeImage'
import AyriaBackButton from './buttons/AyriaBackButton'
const ShippingAddressMapSuggest = lazy(() => import('./suggest/ShippingAddressMapSuggest'))
const Lightbox = lazy(() => import('../screens/merchant-profile/lightbox'))
const AyriaPaymentQRCode = lazy(() => import('./AyriaPaymentQRCode'))
const MobileSuggest = lazy(() => import('./suggest/MobileSuggest'))
const BrandSuggest = lazy(() => import('./suggest/BrandSuggest'))
const KalaSuggest = lazy(() => import('./suggest/KalaSuggest'))
const KalaGroupSuggest = lazy(() => import('./suggest/KalaGroupSuggest'))
const TagSuggest = lazy(() => import('./suggest/TagSuggest'))
//const ShippingAddressSuggest = lazy(() import('./suggest/ShippingAddressSuggest'))
const ShippingAddressAcceptorSuggest = lazy(() => import('./suggest/ShippingAddressAcceptorSuggest'))
const AyriaReCaptcha = lazy(() => import('./AyriaReCaptcha'))
const AyrCode = lazy(() => import('./ayria/AyrCode'))

export {
  MobileLookup,
  FullnameLookup,
  CurrencyInput,
  NumberInput,
  NumberIonInput,
  AcceptorLogo,
  AcceptorContact,
  AyriaPaymentQRCode,
  VirtualShopQRCode,
  ClubVirtualShopQRCode,
  AcceptorTableQrCode,
  ClubAcceptorTableQrCode,
  KalaSuggest,
  KalaGroupSuggest,
  BrandSuggest,
  ScoreBalance,
  TagSuggest,
  //  AyriaCaptcha,
  MobileInput,
  AyriaReCaptcha,
  MobileSuggest,
  //ShippingAddressSuggest,
  ShippingAddressMapSuggest,
  ShippingAddressAcceptorSuggest,
  CurrencyIonInput,
  MobileIonInput,
  Lightbox,
  RateIcon,
  AyriaSelect,
  AyriaPaymentOnlineOrderCard,
  KalaImage,
  AcceptorHasFeatureLoader,
  AcceptorCurrentResolver,
  CashdeskSelect,
  AyriaIcon,
  DeliveryHoursAllowed,
  BillTypeImage,
  AyrCode,
  AyriaBackButton,
}
