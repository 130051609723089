import React, { useEffect, useState } from 'react'
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonButton, IonIcon } from '@ionic/react'
import { IonSpinner, IonRow, IonCol } from '@ionic/react'
import { document } from 'ionicons/icons'
import { saveAs } from 'file-saver'
import DatePicker, { utils } from 'react-modern-calendar-datepicker'
import { ApiService, useToast, toGregorian } from '../../../ayria'
import { setWithTimeout } from '../../../utility'

interface OwnProps {
  acceptorCode: string
}
interface PaymentToolsReportProps extends OwnProps {}

const PaymentToolsReport: React.FC<PaymentToolsReportProps> = ({ acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [terminals, setTerminals] = useState([])
  const [inprogress, setInprogress] = useState<boolean>(false)
  const [dayRange, setDayRange] = useState<any>({
    from: { year: utils('fa').getToday().year, month: utils('fa').getToday().month, day: 1 },
    to: utils('fa').getToday(),
  })

  const fromString = () => {
    return `${dayRange.from.year}-${dayRange.from.month}-${dayRange.from.day}`
  }
  const toString = () => {
    return `${dayRange.to.year}-${dayRange.to.month}-${dayRange.to.day}`
  }
  const fromDate = () => {
    return toGregorian(dayRange.from)
  }
  const toDate = () => {
    return toGregorian(dayRange.to)
  }

  const fetchItems = async () => {
    const res: any = await api.getPosTerminals(acceptorCode)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
    } else {
      toast.error(res)
    }
  }

  const downloadReport = async () => {
    setInprogress(true)
    api.setHeader('Accept', 'application/xls')
    const res: any = await api.get(
      `/acceptor-roles/terminals/${acceptorCode}/${fromDate()}/${toDate()}/xls`,
      { terminals },
      { responseType: 'blob', headers: [{ Accept: 'application/xls' }] }
    )

    if (res.ok) {
      saveAs(res.data, `pos-report-${fromString()}-${toString()}.xls`)
    } else {
      toast.error(res)
    }
    api.setHeader('Accept', 'application/json') // Necessary!
    setInprogress(false)
  }

  useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line

  return (
    <>
      <IonItem className='col-4'>
        <IonItem>{'دستگاه کارتخوان'}</IonItem>
        <IonSelect value={terminals} onIonChange={(e) => setTerminals(e.detail.value)} multiple={true}>
          {items.map((item: any) => (
            <IonSelectOption value={item.terminal} key={item.terminal}>
              {item.name} {item.terminal}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <IonRow>
        <IonCol>
          <IonLabel>{'تاریخ'}</IonLabel>
        </IonCol>
        <IonCol>
          <DatePicker
            value={dayRange}
            onChange={setDayRange}
            inputPlaceholder={'تاریخ'}
            shouldHighlightWeekends
            locale='fa'
            calendarPopperPosition={'bottom'}
          />
        </IonCol>
      </IonRow>
      <IonButton color='success' onClick={downloadReport} disabled={inprogress}>
        {'دانلود فایل اکسل'}
        {!inprogress && <IonIcon icon={document} slot='start' />}
        {inprogress && <IonSpinner name='crescent' color='dark' />}
      </IonButton>
    </>
  )
}

export default PaymentToolsReport
