import React, { Suspense, useEffect, useState, useContext } from 'react'
import { Redirect, Route } from 'react-router'
import { IonReactRouter } from '@ionic/react-router'
import SockJsClient from 'react-stomp'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Push from 'push.js'
import { IonApp, IonRouterOutlet, IonSplitPane, getPlatforms, setupIonicReact } from '@ionic/react'
import { isPlatform } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import browserUpdate from 'browser-update'
import { setIsLoggedIn, setUsername, loadUserData, setLoggedUser, setFailureCount } from './data/user/user.actions'
import { loadShopData, setOnlineOrderCount, setSocketDisconnected } from './data/shop/shop.actions'
import { ToastProvider, useToast } from './utility/toaster/useToast'
import EventEmitter from './utility/event-emitter'
import Auth from './utility/auth/Auth'
import { getAccessToken } from './utility/auth/TokenTools'
import { API_BASE_URL, CLUB_LOWER_CASE } from './config'
import ApiService from './api/ApiService'
import { User } from './models/User'
import { Club } from './models/Club'
import { AppContextProvider } from './data/AppContext'
import { NotificationProvider, NotificationContext } from './components/structures/contexts/NotificationContext'
import { AcceptorProvider } from './components/structures/contexts/AcceptorContext'
import { ScoreProvider } from './components/structures/contexts/ScoreContext'
import { ProfileProvider } from './components/structures/contexts/ProfileContext'
import { LayerContextProvider } from './pages/shared/map/LayerContext'
import { WalletProvider } from './components/structures/contexts/WalletContext'
import { LoginProvider } from './components/structures/contexts/LoginContext'
import { connect } from './data/connect'
import SuspenseSplash from './pages/shared/SuspenseSplash'
import 'lazysizes'
import './App.scss'
import ErrorBoundary from './utility/ErrorBoundary'

import RedirectToLogin from './pages/RedirectToLogin'
import Menu from './components/structures/menu/Menu'
import MainTabs from './pages/MainTabs'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Routes from './pages/routes'
import Account from './pages/Account'
import Landing from './pages/landing/Landing'
import KalaPage from './pages/kala/KalaPage'
import SelectPlanPage from './pages/SelectPlanPage'
import PurchasePlanPage from './pages/PurchasePlanPage'
import MerchantProfilePage from './pages/MerchantProfilePage'
import CashDeskDailyReport from './pages/cashdesk/CashDeskDailyReport'
import MyClubTab from './pages/myclub/MyClubTab'
import VirtualShopQrPage from './pages/VirtualShopQrPage'
import MyClubListTab from './pages/myclub/MyClubListTab'
import MyClubRegisterTab from './pages/myclub/MyClubRegisterTab'
import ClubStartPage from './pages/ClubStartPage'
import AddToHome from './pages/AddToHome'
import HomeOrLanding from './pages/HomeOrLanding'
import AyriaPaymentPage from './pages/payment/AyriaPaymentPage'
import MerchantProfileShoppingBasket from './components/screens/merchant-profile/merchantProfileShoppingBasket'
import ProductManagement from './components/screens/online-shop-service/productManagement'
import ProductCategoryManagement from './components/screens/online-shop-service/productCategoryManagement'
import OrderedProductManagement from './components/screens/online-shop-service/orderedProductManagement'
import MerchantProfileShippingAddress from './components/screens/merchant-profile/merchantProfileShippingAddress'
import MerchantProfileShoppingFinalState from './components/screens/merchant-profile/merchantProfileShoppingFinalState'

declare var document: any
declare var window: any

setupIonicReact()

const App = () => {
  return (
    <ErrorBoundary>
      <AppContextProvider>
        <Suspense fallback={<SuspenseSplash />}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            useRecaptchaNet={true}
            useEnterprise={false}
            scriptProps={{
              async: true,
              defer: false,
              appendTo: 'head',
            }}>
            <ToastProvider value={{ color: 'success', duration: 2000 }}>
              <NotificationProvider>
                <ProfileProvider>
                  <ScoreProvider>
                    <AcceptorProvider>
                      <WalletProvider>
                        <LoginProvider>
                          <LayerContextProvider>
                            <IonicAppConnected />
                          </LayerContextProvider>
                        </LoginProvider>
                      </WalletProvider>
                    </AcceptorProvider>
                  </ScoreProvider>
                </ProfileProvider>
              </NotificationProvider>
            </ToastProvider>
          </GoogleReCaptchaProvider>
        </Suspense>
      </AppContextProvider>
    </ErrorBoundary>
  )
}

interface OwnProps {}
interface StateProps {
  darkMode: boolean
  theme: string
  isAuthenticated: boolean
  loggedUser?: User
  savedClub?: Club
  failureCount: number
}
interface DispatchProps {
  loadUserData: typeof loadUserData
  setIsLoggedIn: typeof setIsLoggedIn
  setUsername: typeof setUsername
  setLoggedUser: typeof setLoggedUser
  loadShopData: typeof loadShopData
  setOnlineOrderCount: typeof setOnlineOrderCount
  setFailureCount: typeof setFailureCount
  setSocketDisconnected: typeof setSocketDisconnected
}

interface IonicAppProps extends OwnProps, StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  theme,
  setIsLoggedIn,
  loadUserData,
  setLoggedUser,
  isAuthenticated,
  loadShopData,
  loggedUser,
  savedClub,
  failureCount,
  setFailureCount,
  setSocketDisconnected,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const auth = new Auth()
  const toast = useToast()
  const [initSockJsClient, setInitSockJsClient] = useState(false)
  const [notificationContext, setNotificationContext] = useContext<any>(NotificationContext)
  const [accessToken, setAccessToken] = useState('')
  const [deferredPrompt, setDeferredPrompt] = useState<any>()
  const [showAddToHomeButton, setShowAddToHomeButton] = useState(false)
  const [showAddToHomeMessage, setShowAddToHomeMessage] = useState(false)
  const logoElem = document.querySelector('link[sizes]')
  const exceptionalPaths = [
    '/login',
    '/signup',
    '/activate',
    '/logout',
    '/acceptors-signup',
    '/acceptor-activate',
    '/start',
    '/plan',
    '/purchase-plan',
    '/allPlan',
    '/landing',
    '/change-password',
    '/password-rest',
    '/password-reset-finish',
    '/change-password-acceptor',
    '/password-rest-acceptor',
    '/password-reset-finish-acceptor',
    '/acceptors-request',
    '/login-acceptor',
    '/wallet-response',
    '/mobile-reset-finish',
    '/buy',
    '/tabs/center',
    '/shopping-cart',
    '/shipping-address',
    '/shipping-final',
    '/kala',
    '/s/',
    '/foodcourt',
  ]

  const showAddToHome = () => {
    return (
      isPlatform('ios') &&
      showAddToHomeButton &&
      window.location.hostname === 'dashboard.ayria.club' &&
      exceptionalPaths.filter((p) => window.location.pathname.includes(p)).length === 0
    )
  }
  const dispatchEvent = (event: string, data?: any) => {
    EventEmitter?.dispatch(event, data)
  }

  const getAccount = async () => {
    api.get('/account').then(
      async (res: any) => {
        if (res.ok && res.data) {
          auth.setLoggedInUser(res.data)
          await setLoggedUser(res.data)
          await setIsLoggedIn(true)
          await setFailureCount(0)
        } else {
          await setFailureCount(+failureCount + 1)
          if (failureCount >= 2) {
            await setIsLoggedIn(false)
          }
        }
      },
      async (error) => {
        await setIsLoggedIn(false)
      }
    )
  }

  const browserUpdateCheck = () => {
    browserUpdate({
      required: { e: 11, f: 32, o: 30, s: 9, c: 43 },
      insecure: true,
      api: 2021.11,
      test: false,
      l: 'fa',
      reminder: 24,
      style: 'top',
      shift_page_down: true,
    })
  }

  useEffect(() => {
    //loadIcons()
    console.log('Debug:', getPlatforms())
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      // Prevent Chrome 67 and ealier from automatically showing the prompt
      e.preventDefault()
      setDeferredPrompt(e)
      setShowAddToHomeButton(true)
    })

    window.addEventListener('appinstalled', () => {
      setShowAddToHomeMessage(true)
    })

    if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
      setShowAddToHomeButton(false)
    } else if (isPlatform('ios')) {
      setTimeout(() => {
        setShowAddToHomeButton(true)
      }, 3000)
    }
  }, [])

  useEffect(() => {
    const token = getAccessToken()
    setAccessToken(token || '')
    if (
      isAuthenticated &&
      loggedUser?.login &&
      token &&
      !initSockJsClient &&
      (loggedUser?.authorities?.includes('ROLE_ACCEPTOR') ||
        loggedUser?.authorities?.includes('ROLE_ACCEPTOR_PARTNER') ||
        loggedUser?.authorities?.includes('ROLE_ACCEPTOR_MANAGER') ||
        loggedUser?.authorities?.includes('ROLE_ACCEPTOR_SECRETARY') ||
        loggedUser?.authorities?.includes('ROLE_DELIVERY') ||
        loggedUser?.authorities?.includes('ROLE_WAITER') ||
        loggedUser?.authorities?.includes('ROLE_CLUB_OWNER') ||
        loggedUser?.authorities?.includes('ROLE_CLUB_MANAGER'))
    ) {
      setInitSockJsClient(true)
      console.log('<<<<<<<<<<NOTIFICATION INTIALIZED>>>>>>>>>>')
    }
  }, [loggedUser, isAuthenticated]) // eslint-disable-line

  useEffect(() => {
    loadUserData()
    loadShopData()
    browserUpdateCheck()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (failureCount <= 2 && exceptionalPaths.filter((p) => window.location.pathname.includes(p)).length === 0) {
      getAccount()
    }
  }, [failureCount]) // eslint-disable-line

  useEffect(() => {
    document.title = savedClub?.name || t(`club.${CLUB_LOWER_CASE}.title`)
  }, [savedClub?.name]) // eslint-disable-line

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : `${theme}-theme`}`}>
      <Suspense fallback={<SuspenseSplash />}>
        <IonReactRouter>
          {!showAddToHome() && (
            <IonSplitPane contentId='main'>
              <Menu />
              <IonRouterOutlet id='main'>
                {/*
                We use IonRoute here to keep the tabs state intact,
                which makes transitions between tabs and non tab pages smooth
              */}
                <Route path='/tabs' component={MainTabs} />
                <Route path='/start' component={Landing} exact={true} />
                <Route path='/account' component={Account} exact={true} />
                <Route path='/plan' component={SelectPlanPage} exact={true} />
                <Route path='/purchase-plan' component={PurchasePlanPage} exact={true} />
                <Route path='/merchant-profile/:merchantId' component={MerchantProfilePage} exact={true} />
                <Route path='/shopping-cart' component={MerchantProfileShoppingBasket} exact={true} />
                <Route path='/shipping-address/:merchantCode' component={MerchantProfileShippingAddress} exact={true} />
                <Route
                  path='/shipping-final/:merchantCode'
                  component={MerchantProfileShoppingFinalState}
                  exact={true}
                />
                <Route path='/product-management' component={ProductManagement} exact={true} />
                <Route path='/product-category' component={ProductCategoryManagement} exact={true} />
                <Route path='/ordered-product' component={OrderedProductManagement} exact={true} />
                <Route path='/kala/:acceptorReferralCode/:kalaId' component={KalaPage} exact={true} />
                {/*<Route path='/kala/:acceptorReferralCode/:kalaId' component={KalaPage} />*/}
                <Route path='/cashdesk/:acceptorCode/:selectedDay' component={CashDeskDailyReport} exact={true} />
                <Route path='/myclub/:code' component={MyClubTab} extact={true} />
                <Route path='/myclubs/:acceptorCode' component={MyClubListTab} exact={true} />
                <Route path='/virtual-shop-qr/:acceptorReferralCode' component={VirtualShopQrPage} />
                <Route path='/newclub/:acceptorCode' component={MyClubRegisterTab} exact={true} />
                <Route path='/apg/:acceptorCode/:trackingNumber' component={AyriaPaymentPage} exact={true} />
                <Route path='/signup' component={Signup} exact={true} />
                <Route path='/login' component={Login} exact={true} />
                <Route
                  path='/logout'
                  exact={true}
                  render={() => {
                    return <RedirectToLogin withLogout={true} />
                  }}
                />
                <Route
                  path='/dashboard'
                  exact={true}
                  render={() => {
                    return <Redirect to={`/tabs${window.location.search}`} />
                  }}
                />
                <Route path='/' component={HomeOrLanding} exact={true} />
                {/* Useful for native packaging<Route
                  path='/'
                  render={() => {
                    return <Redirect to={`/s/${CLUB_CODE}`} />
                  }}
                  exact
                />*/}
                <Route path='/s/:clubCode' component={ClubStartPage} exact={true} />
                <Routes />
              </IonRouterOutlet>
            </IonSplitPane>
          )}
          {initSockJsClient && !!accessToken && isAuthenticated && (
            <SockJsClient
              url={`${API_BASE_URL}/notify`}
              topics={[`/user/${loggedUser?.login}/notify`]}
              autoReconnect={true}
              onConnect={async () => {
                await setSocketDisconnected(false)
                if (window?.location?.pathname?.includes('panel') || window?.location?.pathname?.includes('desk')) {
                  toast.success('اتصال لحظه ای برقرار شد')
                }
                console.log('<<<<<<<<<<NOTIFICATION CONNECTED>>>>>>>>>>')
              }}
              onDisconnect={async () => {
                await setSocketDisconnected(true)
                console.error('<<<<<<<<<<NOTIFICATION DISCONNECTED>>>>>>>>>>')
              }}
              onConnectFailure={async () => {
                await setSocketDisconnected(true)
              }}
              getRetryInterval={(param: any) => {
                console.error(`<<<<<<<<<<NOTIFICATION RETRY ${param}>>>>>>>>>>`)
                return 2000
              }}
              subscribeHeaders={{ Authorization: `Bearer ${accessToken}` }}
              heartbeat={10000}
              debug={false}
              onMessage={(msg: any) => {
                console.log(`<<<<<<<<<<NOTIFICATION ${msg.type} ${msg.body} >>>>>>>>>>`)
                dispatchEvent('MESSAGE_RECEIVED_WITH_SOCKET', msg)
                if ('MESSAGE' === msg.type) {
                  setNotificationContext((state: any) => ({
                    ...state,
                    badge: notificationContext.badge ? notificationContext.badge + 1 : 1,
                  }))
                  dispatchEvent('FACTOR_PAID_STATUS_NEED_UPDATE')
                } else if (msg?.type?.toString()?.startsWith('ONLINE_ORDER')) {
                  dispatchEvent('ONLINE_ORDER_COUNT_NEED_UPDATE')
                  Push.create(`سفارش آنلاین ${msg.id}`, {
                    body: msg.body,
                    icon: logoElem.href,
                    /*image: `${window.location.hostname === 'localhost' ? 'http' : 'https'}://${
                      window.location.host
                    }/assets/share/apgoo.jpeg`,*/
                    requireInteraction: true,
                    onClick: function () {
                      window.focus()
                      window
                        .open(
                          `${window.location.hostname === 'localhost' ? 'http' : 'https'}://${
                            window.location.host
                          }/apg/${msg.acceptorCode}/${msg.id}`,
                          '_blank',
                          'status=no,location=no,toolbar=no,menubar=no,width=500,height=900,left=100,top=100'
                        )
                        .focus()
                    },
                    vibrate: true,
                  })
                }
              }}
            />
          )}
        </IonReactRouter>
        {showAddToHome() && (
          <AddToHome deferredPrompt={deferredPrompt} showAddToHomeMessageDefault={showAddToHomeMessage} />
        )}
      </Suspense>
    </IonApp>
  )
}

export default App

const IonicAppConnected = connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedIn,
    loggedUser: state.user.loggedUser,
    theme: state.user.theme,
    savedClub: state.user.savedClub,
    failureCount: state.user.failureCount,
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    setUsername,
    setLoggedUser,
    loadShopData,
    setOnlineOrderCount,
    setFailureCount,
    setSocketDisconnected,
  },
  component: IonicApp,
})
