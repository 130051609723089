import React from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
// import { SelectChangeEventDetail } from '@ionic/core'

interface AyriaSelectProps {
  value?: any
  onIonChange: (event: CustomEvent<any>) => void
  items: any[]
  valueProp?: string
  labelProp?: string
}

const AyriaSelect: React.FC<AyriaSelectProps> = ({
  value,
  onIonChange,
  items,
  valueProp = 'id',
  labelProp = 'name',
}) => {
  return (
    <IonSelect value={value} onIonChange={onIonChange} cancelText={'انصراف'} okText={'انتخاب'}>
      {items.map((i: any) => (
        <IonSelectOption value={i[valueProp]} key={i[valueProp]}>
          {i[labelProp]}
        </IonSelectOption>
      ))}
    </IonSelect>
  )
}

export default AyriaSelect
