import React, { useState, useContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '../../../utility'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import CurrencyInput from '../../units/CurrencyInput/CurrencyInput'
import WalletBalance from '../WalletBalance/WalletBalance'
import { ApiService } from '../../../ayria'
import { MINIMUM_MANUAL_CASHOUT_AMOUNT } from '../../../config'
import { IonInput, IonItem, IonLabel } from '@ionic/react'

interface WalletCashoutVipProps {
  amount: string
  acceptorCode: string
  onSuccess: Function
  theme: string
}

const WalletCashoutVip: FC<WalletCashoutVipProps> = ({ amount = null, acceptorCode, onSuccess, theme }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const btnClassName = theme === 'acceptor' ? 'btn btn-theme-platform text-white btn-sm' : 'btn btn-theme btn-sm'
  const [inprogress, setInprogress] = useState(false)
  const [dynamicAmount, setDynamicAmount] = useState<any>(amount)
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [sheba, setSheba] = useState<any>(acceptorContext?.current?.shebaCode)
  const [shebaVerified, setShebaVerfied] = useState(false)
  const [accountInfo, setAccountInfo] = useState<any>()

  const handleCashout = async () => {
    setInprogress(true)
    const res: any = await api.post(`/wallet/cashout-vip`, {
      amount: dynamicAmount,
      senderAcceptorCode: acceptorCode,
      sheba,
      receiverFirstName: accountInfo?.accountOwners[0].firstName,
      receiverLastName: accountInfo?.accountOwners[0].lastName,
    })
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      onSuccess()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const lookupSheba = async () => {
    setInprogress(true)
    const res: any = await api.get(`/acceptor-roles/lookup-sheba/${sheba}`)
    if (res.ok) {
      if (res.data.iban === sheba) {
        const data = res.data
        data.accountOwners = JSON.parse(data.accountOwners)
        setAccountInfo(data)
        setShebaVerfied(true)
      } else {
        toast.error('استعلام شبا ناموفق بود')
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const isAmountValid = () => {
    return +dynamicAmount >= MINIMUM_MANUAL_CASHOUT_AMOUNT
  }

  return (
    <div>
      <div>
        <label>
          نام فروشگاه <span className='text-bold'>{acceptorContext?.current?.acceptorName}</span>
        </label>
        <WalletBalance acceptorCode={acceptorCode} showScore={false} />
        <div className='row'>
          <span className='col-8'>مبلغ مورد نظر شمابرای انتقال به شبا : </span>
          <span className={`col-4 ${isAmountValid() ? '' : ' text-danger'}`}>
            {(+dynamicAmount).toLocaleString(navigator.language)} {t('label.riyal')}
          </span>
        </div>
        <br />
        <div className='form-group'>
          <CurrencyInput onValueChange={(value) => setDynamicAmount(+value)} defaultValue={dynamicAmount} />
        </div>
        <IonItem>
          <IonLabel>{'شبای مقصد'}</IonLabel>
          <IonInput
            value={sheba}
            onIonChange={(e) => {
              setSheba(e.detail.value)
              setShebaVerfied(false)
            }}
            placeholder={'IR000000000011111111114444'}
            className='dir-ltr ion-text-start'></IonInput>
        </IonItem>
        <br />
        <button
          className={btnClassName}
          onClick={lookupSheba}
          disabled={inprogress || shebaVerified || sheba?.length !== 26}>
          استعلام
        </button>
        <button className={btnClassName} onClick={handleCashout} disabled={inprogress || !shebaVerified}>
          انجام
        </button>
        {shebaVerified && !!accountInfo && (
          <div className='dir-ltr ion-text-start'>
            <pre className='dir-ltr'>{JSON.stringify(accountInfo, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  )
}

export default WalletCashoutVip
