
const groupBy = (xs: any, prop: string) => {
  let grouped = {} as any;
  for (var i=0; i<xs.length; i++) {
    var p = xs[i][prop];
    if (!grouped[p]) { grouped[p] = []; }
    grouped[p].push(xs[i]);
  }
  return grouped;
}

const groupByIndex = (xs: any, index: number) => {
  let grouped = {} as any;
  for (var i=0; i<xs.length; i++) {
    var p = xs[i][index];
    if (!grouped[p]) { grouped[p] = []; }
    grouped[p].push(xs[i]);
  }
  return grouped;
}


export { groupBy, groupByIndex };
