import React, { useEffect, useState, useContext } from 'react'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import { AyriaPagination, ITEMS_PER_PAGE } from '../../../utility/pagination'
import useDebounce from '../../../utility/use-debounce'
import Purchases from './Purchases'
import { ApiService, ItemCount, Modal, getSortState } from '../../../ayria'
import { IonIcon } from '@ionic/react'
import { eyeOutline } from 'ionicons/icons'

const Customers = ({ isAcceptor }) => {
  const api = new ApiService()
  const [acceptorContext] = useContext(AcceptorContext)
  const [customers, setCustomers] = useState([])
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))
  const [totalItems, setTotalItems] = useState(0)
  const [customerName, setCustomerName] = useState('')
  const debouncedSearch = useDebounce(customerName, 500)
  const [showPurchasesModal, setShowPurchasesModal] = useState(false)
  const [customerMobile, setCustomerMobile] = useState('')

  const getCustomers = async (page, size, sort) => {
    const res = await api.get(
      `/acceptor/purchases/${acceptorContext.current.code}/customers?page=${page}&size=${size}&sort=${sort}${
        debouncedSearch ? fullNameSearchParams() : ''
      }`
    )
    if (res.ok) {
      setCustomers(res.data)
      setTotalItems(res.headers['x-total-count'])
    }
  }

  const fullNameSearchParams = () => {
    const spaceIndex = debouncedSearch.trim().indexOf(' ')
    if (spaceIndex === -1) {
      return `&fullName.contains=${debouncedSearch.trim()}`
    } else {
      return `&firstName.contains=${debouncedSearch
        .trim()
        .substring(0, spaceIndex)}&lastName.contains=${debouncedSearch
        .trim()
        .substring(spaceIndex + 1, debouncedSearch.length)}`
    }
  }

  const sortCustomers = () =>
    getCustomers(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  useEffect(() => {
    pagination.itemsPerPage = ITEMS_PER_PAGE
    pagination.sort = 'totalAmount'
    pagination.order = 'desc'
  }, []) // eslint-disable-line

  useEffect(() => {
    sortCustomers()
  }, [pagination.activePage, pagination.order, pagination.sort, debouncedSearch, acceptorContext.currentIndex]) // eslint-disable-line

  useEffect(() => {
    if (customerMobile) {
      setShowPurchasesModal(true)
    } else {
      setShowPurchasesModal(false)
    }
  }, [customerMobile])

  return (
    <div className='reportHolder-style'>
      <div className='col-4'>
        <input
          onChange={(e) => setCustomerName(e.target.value)}
          placeholder={'نام مشتری'}
          className={'form-control mb-2 text-right'}
        />
      </div>

      <div className='col-12 mt-4' style={{ overflowX: 'auto' }}>
        <table className='customTableTwo'>
          <thead>
            <tr>
              <th>نام</th>
              <th>شماره همراه </th>
              <th>مبلغ کل سفارش</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => {
              return (
                <tr key={index} onClick={() => setCustomerMobile(customer.mobile)}>
                  <td>
                    {customer.firstname} {customer.lastname}
                  </td>
                  <td>{customer.mobile}</td>
                  <td>{customer.totalAmount.toLocaleString(navigator.language)}</td>
                  <td>
                    <IonIcon icon={eyeOutline} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {/*<div key={index} className='flex-container border-bottom'>*/}
      {/*      <span className='flex-2' onClick={() => setCustomerMobile(customer.mobile)}>*/}
      {/*        <p className='ellipsis m-0' style={{ width: isMobile() ? '70vw' : '400px' }}>*/}
      {/*          {customer.firstname} {customer.lastname}*/}
      {/*          <br />*/}
      {/*          {customer.mobile}*/}
      {/*        </p>*/}
      {/*      </span>*/}
      {/*  <span className='flex-1 text-left'>{customer.totalAmount.toLocaleString(navigator.language)}</span>*/}
      {/*</div>*/}

      <div className='col-12 justify-content-end d-flex flex-row align-items-center'>
        <div className='row text-center'>
          <div className='col'>
            <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
          </div>
        </div>

        <AyriaPagination
          activePage={pagination.activePage}
          onSelect={handlePagination}
          maxButtons={5}
          itemsPerPage={pagination.itemsPerPage}
          totalItems={totalItems}
        />
      </div>

      <Modal
        showModal={showPurchasesModal}
        toggle={setShowPurchasesModal}
        isPlatformTheme={true}
        onClosed={() => setCustomerMobile('')}
        content={<Purchases mobile={customerMobile} />}
      />
    </div>
  )
}
export default Customers
