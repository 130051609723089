import React, { useState, useRef, useEffect, lazy } from 'react'
import { IonInput, IonItem, IonLabel, IonButton } from '@ionic/react'
import { withTranslation } from 'react-i18next'
import { ApiService, NumberIonInput, useToast } from '../../../ayria'
const BarcodeScanner = lazy(() => import('../../modules/barcode-scanner/barcode-scanner'))

const BillPaymentGhabzino = ({ t }) => {
  const api = new ApiService()
  const toast = useToast()
  const [billId, setBillId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [bill, setBill] = useState()
  const [description, setDescription] = useState('')
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState('form')
  const [showScanner, setShowScanner] = useState(false)
  const scannerRef = useRef(null)
  async function handleSubmit() {
    setInprogress(true)
    const res = await api.post(`/user/bill/pay/step1`, { billId, paymentId, description })
    if (res.ok) {
      if (res.status === 201) {
        toast.success('این قبض قبلا پرداخت شده است')
        return
      }
      if (res.data.ok) {
        setBill(res.data)
        setPage('step2')
      } else {
        toast.error(res.data.statusDescription)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const onDetected = (result) => {
    setBillId(result.substr(0, 13))
    setPaymentId(result.substr(17, result.length))
    setPage('form')
  }

  useEffect(() => {
    if (page === 'scanner') {
      setTimeout(() => {
        setShowScanner(true)
      }, 1000)
    } else {
      setShowScanner(false)
    }
  }, [page])

  return (
    <div>
      {page === 'form' && (
        <div>
          <IonItem>
            <IonLabel position='floating'>{t('bill.billId')}</IonLabel>
            <NumberIonInput id='billId' value={billId} onValueChange={setBillId} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{t('bill.paymentId')}</IonLabel>
            <NumberIonInput id='paymentId' value={paymentId} onValueChange={setPaymentId} />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>{t('bill.description')}</IonLabel>
            <IonInput id='desc' value={description} onIonChange={(e) => setDescription(e.detail.value)} />
          </IonItem>
          <br />
          <br />
          <div className='text-center'>
            <IonButton onClick={handleSubmit} disabled={inprogress || !billId || !paymentId}>
              {'مرحله بعد'}
            </IonButton>
          </div>
        </div>
      )}
      {page === 'scanner' && (
        <div>
          <div ref={scannerRef} className='text-center viewport dir-ltr' id='interactive'>
            <canvas
              className='drawingBuffer'
              style={{ position: 'absolute', top: '0px', border: '3px solid green' }}
              width='320'
              height='240'
            />
            {showScanner && <BarcodeScanner scannerRef={scannerRef} onDetected={(result) => onDetected(result)} />}
          </div>
        </div>
      )}
      {(page === 'scanner' || page === 'form') && (
        <div>
          <br />
          <br />
          <hr />
          <div className='text-center'>
            {page === 'form' && (
              <IonButton color='tertiary' onClick={(e) => setPage('scanner')}>
                {'بارکد خوان'}
              </IonButton>
            )}
            {page === 'scanner' && (
              <IonButton color='medium' onClick={(e) => setPage('form')}>
                {'برگشت به فرم'}
              </IonButton>
            )}
          </div>
        </div>
      )}
      {page === 'step2' && bill && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'قبض'}</td>
                <td>{bill.billTypeShowName}</td>
              </tr>
              <tr>
                <td>{'شناسه قبض'}</td>
                <td>{bill.billId}</td>
              </tr>
              <tr>
                <td>{'شناسه پرداخت'}</td>
                <td>{bill.paymentId}</td>
              </tr>
              <tr>
                <td>{'مبلغ'}</td>
                <td>{(+bill.amount).toLocaleString(navigator.language)}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className='text-center'>
            <IonButton
              className='btn-theme'
              onClick={(e) => {
                window.location = bill.paymentLink
              }}>
              {'پرداخت با کارت بانکی'}
            </IonButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(BillPaymentGhabzino)
