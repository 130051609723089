import React from 'react'
import MerchantProfileHighlights from './merchantProfileHighlights'
import MerchantProfileKala from './merchantProfileKala'
import MerchantProfileCustomerRatings from './merchantProfileCustomerRatings'

interface MerchantProfileTabContentProps {
  merchant: any
  activeTab: string
  ionContentRef?: any
  scrollTop: number
}

const MerchantProfileTabContent: React.FC<MerchantProfileTabContentProps> = ({
  merchant,
  activeTab,
  ionContentRef,
  scrollTop,
}) => {
  switch (activeTab) {
    case 'shop':
      return <MerchantProfileKala merchant={merchant} ionContentRef={ionContentRef} scrollTop={scrollTop} />
    case 'merchantInfo':
      return <MerchantProfileHighlights merchant={merchant} />
    case 'tips':
      return <MerchantProfileCustomerRatings merchant={merchant} />
    default:
      return null
  }
}

export default MerchantProfileTabContent
