import api from './Api';

const merchantCategories = ['پوشاک','طلا و جواهر','آرایشی و بهداشتی','لوازم خانگی','دیجیتال','گردشگری و تشریفات','علمی و فرهنگی','فروشگاه','تفریحی و ورزشی','بهداشت و سلامت','خدمات و مشاوره','رستوران و کافی شاپ']
const states = ['تهران','اصفهان','خوزستان','خراسان']
const cities = ['تهران']

const signup = (data) => api.post(api.route.signup.spec, data).then(response => response)
const activateCode = (data) => api.get(api.route.activateCode.spec, data).then(response => response)
const reSendActivateCode = (data) => api.post(api.route.reSendActivateCode.spec, data).then(response => response)
const getAccount = () => api.get(api.route.getAccount.spec).then(response => response)
const updateAccount = (data) => api.post(api.route.updateAccount.spec, data).then(response => response)
const getProfile = () => api.get(api.route.getProfile.spec).then(response => response)
const updateProfile = (data) => api.put(api.route.updateProfile.spec, data).then(response => response)
const passwordReset = (data) => api.post(api.route.passwordReset.spec, data).then(response => response)
const passwordChange = (data) => api.post(api.route.passwordChange.spec, data).then(response => response)
const passwordResetFinish = (data) => api.post(api.route.passwordResetFinish.spec, data).then(response => response)
const getAllAcceptors = (data) => api.get(api.route.getAllAcceptors.spec).then(response => response)
const getMerchantCategories = () => {return merchantCategories}
const getStates = () => {return states}
const getCities = () => {return cities}
const getPlan = () => api.get(api.route.getPlan.spec).then(response => response)
const selectPlan = (data) => api.post(api.route.selectPlan.spec, data).then(response => response)
const getCards = () => api.get(api.route.getCards.spec).then(response => response)
const getCardInvoice = () => api.get(api.route.getCardInvoice.spec).then(response => response)
const calculatePoint = (data) => api.post(api.route.calculatePoint.spec, data).then(response => response)
const getWalletDepositInvoice = () => api.post(api.route.getWalletDepositInvoice.spec, null).then(response => response)
const setWalletDepositExtraInfo = (data) => api.post(api.route.setWalletDepositExtraInfo.spec, data).then(response => response)
const setInvoiceExtraInfo = (data) => api.post(api.route.setInvoiceExtraInfo.spec, data).then(response => response)
const getScore = () => api.get(api.route.getScore.spec).then(response => response)
const getPurchasesForRate = () => api.get(api.route.getPurchasesForRate.spec).then(response => response)
const getTotals = () => api.get(api.route.getTotals.spec).then(response => response)
const getInvoices = () => api.get(api.route.getInvoices.spec).then(response => response)
const activateCard = (data) => api.post(api.route.activateCard.spec, data).then(response => response)
const deleteCard = (data) => api.post(api.route.deleteCard.spec, data).then(response => response)
const ratePurchase = (data) => api.post(api.route.ratePurchase.spec, data).then(response => response)
const acceptorRequest = (data) => api.post(api.route.acceptorRequest.spec, data).then(response => response)
const acceptorSetProfile = (data) => api.post(api.route.acceptorSetProfile.spec, data).then(response => response)
const getCurrentAcceptors = () => api.get(api.route.getCurrentAcceptors.spec).then(response => response)
const getWalletBalance = (data) => api.post(api.route.getWalletBalance.spec, data).then(response => response)
const getUserWithMobile = (data) => api.post(api.route.getUserWithMobile.spec, data).then(response => response)
const walletTransfer = (data) => api.post(api.route.walletTransfer.spec, data).then(response => response)
const walletPurchase = (data) => api.post(api.route.walletPurchase.spec, data).then(response => response)
const walletPurchasePreview = (data) => api.post(api.route.walletPurchasePreview.spec, data).then(response => response)
const registerOfflinePurchase = (data) => api.post(api.route.registerOfflinePurchase.spec, data).then(response => response)
const cardBalance = (data) => api.post(api.route.cardBalance.spec, data).then(response => response)
const cardTransferInit = (data) => api.post(api.route.cardTransferInit.spec, data).then(response => response)
const cardTransferComplete = (data) => api.post(api.route.cardTransferComplete.spec, data).then(response => response)
const getBoomOAuthLogin = () => api.get(api.route.getBoomOAuthLogin.spec).then(response => response)
const getBoomOAuthToken = (data) => api.post(api.route.getBoomOAuthToken.spec, data).then(response => response)
const boomOAuthLogout = () => api.delete(api.route.boomOAuthLogout.spec).then(response => response)
const ibanNumberInfo = (data) => api.post(api.route.ibanNumberInfo.spec, data).then(response => response)
const shebaTransfer = (data) => api.post(api.route.shebaTransfer.spec, data).then(response => response)

const apiFunctions = {
    getAccount,
    updateAccount,
    getProfile,
    updateProfile,
    passwordReset,
    passwordChange,
    getAllAcceptors,
    getMerchantCategories,
    getStates,
    getCities,
    signup,
    activateCode,
    getPlan,
    selectPlan,
    getCards,
    getCardInvoice,
    calculatePoint,
    getWalletDepositInvoice,
    setWalletDepositExtraInfo,
    setInvoiceExtraInfo,
    getScore,
    getPurchasesForRate,
    getTotals,
    getInvoices,
    activateCard,
    deleteCard,
    reSendActivateCode,
    ratePurchase,
    acceptorRequest,
    acceptorSetProfile,
    getCurrentAcceptors,
    getWalletBalance,
    getUserWithMobile,
    walletTransfer,
    walletPurchase,
    walletPurchasePreview,
    passwordResetFinish,
    registerOfflinePurchase,
    cardBalance,
    cardTransferInit,
    cardTransferComplete,
    getBoomOAuthLogin,
    getBoomOAuthToken,
    boomOAuthLogout,
    ibanNumberInfo,
    shebaTransfer,
};

export default apiFunctions;
