import React, { useState, useEffect, FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ItemCountProps {
  page: number
  total: number
  itemsPerPage: number
}

const ItemCount: FC<ItemCountProps> = ({ page, total, itemsPerPage }) => {
  const { t } = useTranslation()
  const [first, setFirst] = useState<number>()
  const [second, setSecond] = useState<number>()

  useEffect(() => {
    setFirst((page - 1) * itemsPerPage === 0 ? 1 : (page - 1) * itemsPerPage + 1)
    setSecond(page * itemsPerPage < total ? page * itemsPerPage : total)
  }, [page, total, itemsPerPage])

  return <div className='info jhi-item-count'>{t('global.item-count', { first, second, total })}</div>
}

export default ItemCount
