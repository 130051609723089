import React, { useState, useEffect } from 'react'
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonSpinner } from '@ionic/react'
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { Link } from 'react-router-dom'
import CopyRight from './CopyRight'
import { URLSearchParams } from 'whatwg-url'
import SignupForm from '../components/modules/signup-form/SignupForm'

import { Logo, connect, setMenuEnabled, ApiService, setReferralCode, ReferralCode } from '../ayria'
import './Login.scss'
import { isDesktop } from '../utility'

interface StateProps {
  theme: string
  referralCode?: string
}

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
  setReferralCode: typeof setReferralCode
}

interface SignupProps extends StateProps, DispatchProps {}

const Signup: React.FC<SignupProps> = ({ setMenuEnabled, theme, referralCode, setReferralCode }) => {
  const api = new ApiService()
  const urlSearchParams = new URLSearchParams(window?.location?.search)
  const [logoResolved, setLogoResolved] = useState(false)
  const [referral, setReferral] = useState<any>()

  const fetchReferralCodeInfo = async () => {
    setLogoResolved(false)
    const ref = urlSearchParams?.get('ref') ? urlSearchParams.get('ref') : referralCode
    if (ref) {
      const res: any = await api.publicReferralCode(ref)
      if (res.ok) {
        await setReferralCode(ref)
        setReferral(res.data)
      }
    }
    setLogoResolved(true)
  }

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
  })

  useEffect(() => {
    fetchReferralCodeInfo()
  }, []) // eslint-disable-line

  return (
    <IonPage
      id='signup-page'
      className={(isDesktop() ? 'marginHorizontalAuto' : '') + ` ${referral?.theme ? referral?.theme : theme}-theme`}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>ثبت نام</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='ion-text-center'>
          <br />
          {logoResolved && <Logo override={referral?.logo} />}
          {!logoResolved && <IonSpinner color='primary' />}
        </div>
        <SignupForm />
        <ReferralCode readonly={true} />
        <div className='text-right ion-padding'>
          <Link className='text-right' to={'/login' + window.location.search}>
            قبلا ثبت‌نام کردم
          </Link>
        </div>
        <CopyRight />
      </IonContent>
    </IonPage>
  )
}

export default connect<SignupProps, {}>({
  mapStateToProps: (state) => ({
    theme: state.user.theme,
    referralCode: state.user.referralCode,
  }),
  mapDispatchToProps: {
    setMenuEnabled,
    setReferralCode,
  },
  component: Signup,
})
