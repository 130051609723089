import React, { useState, useContext, useEffect } from 'react'
import { IonSegment, IonSegmentButton, IonLabel, IonText, IonSpinner } from '@ionic/react'
import { useIonViewWillEnter, IonItem, IonDatetime } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import * as moment from 'moment'
import { LayerContext } from '../../../pages/shared/map'
import { ApiService, useToast, ShippingAddressMapSuggest } from '../../../ayria'
import { setShippingAddress, connect, setReceiveType, setPickupDelay } from '../../../ayria'
import { ReceiveType, setTableIdentifier } from '../../../ayria'

declare var window: any

interface OwnProps {
  acceptorReferralCode?: string
  defaultReceiveType?: string
}
interface StateProps {
  shippingAddress: any
  isLoggedIn?: boolean
  tableIdentifier?: string
  receiveType?: string
}
interface DispatchProps {
  setShippingAddress: typeof setShippingAddress
  setReceiveType: typeof setReceiveType
  setPickupDelay: typeof setPickupDelay
  setTableIdentifier: typeof setTableIdentifier
}

interface MerchantProfileShippingAddressProps extends OwnProps, StateProps, DispatchProps {}

const MerchantProfileShippingAddressPage: React.FC<MerchantProfileShippingAddressProps> = ({
  acceptorReferralCode,
  shippingAddress,
  setShippingAddress,
  isLoggedIn,
  receiveType,
  setReceiveType,
  setPickupDelay,
  setTableIdentifier,
  tableIdentifier,
  defaultReceiveType,
}) => {
  const api = new ApiService()
  const toast = useToast()
  const history = useHistory()
  const { merchantCode } = useParams()
  const acceptorCode = merchantCode || acceptorReferralCode
  const [acceptor, setAcceptor] = useState<any>()
  const [segmentValue, setSegmentValue] = useState<ReceiveType>(defaultReceiveType || receiveType || 'DELIVERY')
  const { point, ccenter } = useContext(LayerContext)
  const [notDeliverable, setNotDeliverable] = useState(true)
  const [reRender, setRerender] = useState(false)
  const [checkInprogress, setCheckInprogress] = useState(false)
  const [pickupTime, setPickupTime] = useState()

  const checkIfAddressIsDeliverable = async () => {
    setCheckInprogress(true)
    const res: any = await api.shippingAddressesIn(shippingAddress.id, acceptorCode)
    if (res.ok) {
      setNotDeliverable(res.data.length === 0)
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }
  const checkIfAddressIsDeliverablePublic = async () => {
    setCheckInprogress(true)
    const res: any = await api.publicAyriaPaymentsAddressIn({
      acceptorCode,
      latitude: shippingAddress?.latitude,
      longitude: shippingAddress?.longitude,
    })
    if (res.ok) {
      setNotDeliverable(res.data.length === 0)
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }

  const checkSellOptions = async () => {
    setCheckInprogress(true)
    const res: any = await api.publicAcceptorsGetByReferralCode(acceptorCode)
    if (res.ok) {
      setAcceptor(res.data)
    } else {
      toast.error(res)
    }
    setCheckInprogress(false)
  }

  const handleSegmentValue = async (value: string) => {
    setSegmentValue(value)
    await setReceiveType(value)
    await reset()
  }
  const reset = async (hard = false) => {
    setNotDeliverable(true)
    if (hard) {
      await setShippingAddress(undefined)
    }
  }
  const isNotAnonymousPay = () => {
    return !!acceptor && !acceptor?.anonymousPay
  }
  const pickupTimeIsInvalid = () => {
    return moment(pickupTime).isBefore(moment().add(acceptor?.pickupGap, 'minutes'), 'minutes')
  }
  const minimumAcceptablePickupTime = () => {
    return moment().add(acceptor?.pickupGap, 'minutes').toISOString()
  }

  useIonViewWillEnter(() => {
    reset()
    setRerender(true)
    setTimeout(() => {
      setRerender(false)
    }, 300)
  })

  useEffect(() => {
    setReceiveType(segmentValue)
    if (segmentValue === 'PICKUP' && !pickupTime && !!acceptor?.pickupGap) {
      setPickupTime(moment().add(acceptor?.pickupGap, 'minutes').toISOString())
    }
  }, [segmentValue, acceptor?.pickupGap]) // eslint-disable-line

  useEffect(() => {
    if (isLoggedIn && shippingAddress?.id) {
      checkIfAddressIsDeliverable()
    }
    if (!isLoggedIn && shippingAddress?.latitude) {
      checkIfAddressIsDeliverablePublic()
    }
  }, [shippingAddress]) // eslint-disable-line

  useEffect(() => {
    checkSellOptions()
  }, [acceptorCode]) // eslint-disable-line

  useEffect(() => {
    setPickupDelay(moment(pickupTime).diff(moment(), 'minutes') + 1)
  }, [pickupTime]) // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      if (!isLoggedIn && isNotAnonymousPay()) {
        history.replace(`/login${window.location.search}`)
      }
    }, 1000)
    if (acceptor) {
      if (!acceptor.delivery && acceptor.pickup) {
        setSegmentValue('PICKUP')
      }
    }
  }, [isLoggedIn, acceptor]) // eslint-disable-line

  return (
    <>
      <div className='ion-padding' style={{ paddingTop: '5px' }}>
        <IonSegment onIonChange={(e) => handleSegmentValue(e.detail.value)} value={segmentValue}>
          {acceptor?.delivery && (
            <IonSegmentButton value='DELIVERY'>
              <img src='/assets/images/delivery.png' alt='' width='25' height='25' />
              <IonLabel>ارسال با پیک</IonLabel>
            </IonSegmentButton>
          )}
          {acceptor?.pickup && (
            <IonSegmentButton value='PICKUP'>
              <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
              <IonLabel>{'دریافت حضوری'}</IonLabel>
            </IonSegmentButton>
          )}
          {checkInprogress && <IonSpinner slot='end' />}
        </IonSegment>
        {segmentValue === 'DELIVERY' && acceptor?.delivery && !reRender && (
          <div style={{ marginTop: '10px' }}>
            <ShippingAddressMapSuggest
              onChange={async (value: string) => {
                if (!value) {
                  await reset(true)
                }
              }}
              onSelect={() => {}}
              acceptorCode={acceptorCode}
            />
          </div>
        )}
        {segmentValue === 'DELIVERY' &&
          acceptor?.delivery &&
          ccenter &&
          shippingAddress?.latitude &&
          notDeliverable &&
          !checkInprogress && (
            <IonText color='danger'>{'متاسفانه آدرس انتخاب شده خارج از محدوده برای پیک این فروشگاه می‌باشد.'}</IonText>
          )}
        {segmentValue === 'DELIVERY' &&
          acceptor?.delivery &&
          point &&
          (!shippingAddress?.number || !shippingAddress?.part) && (
            <>
              <IonText color='danger'>{'لطفا شماره پلاک و واحد را وارد نمایید.'}</IonText>
              <br />
            </>
          )}
        {segmentValue === 'PICKUP' && acceptor?.pickup && (
          <>
            <IonItem>
              <IonLabel position='stacked'>{'ساعت مراجعه جهت دریافت سفارش'}</IonLabel>
              <IonDatetime
                value={pickupTime}
                presentation='time'
                doneText={'انتخاب'}
                cancelText={'انصراف'}
                onIonChange={(e) => setPickupTime(e.detail.value!)}
                min={minimumAcceptablePickupTime()}
              />
            </IonItem>
            <br />
            {pickupTimeIsInvalid() && (
              <IonText
                color='danger'
                className='ion-margin'>{`زمان انتخاب شده باید حداقل ${acceptor?.pickupGap} دقیقه بیشتر از زمان جاری باشد.`}</IonText>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setShippingAddress,
    setReceiveType,
    setPickupDelay,
    setTableIdentifier,
  },
  mapStateToProps: (state) => ({
    shippingAddress: state.user.shippingAddress,
    isLoggedIn: state.user.isLoggedIn,
    tableIdentifier: state.user.tableIdentifier,
    receiveType: state.user.receiveType,
  }),
  component: MerchantProfileShippingAddressPage,
})
