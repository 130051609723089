import React, { lazy } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { open, clipboard } from 'ionicons/icons'
import { useToast } from '../../utility/toaster/useToast'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { API_BASE_URL } from '../../config'
const AyrCode = lazy(() => import('./ayria/AyrCode'))

interface AcceptorTableQrCodeProps {
  acceptorCode: string
  tableIdentifier: string
  size: number
  showLink?: boolean
  showPrint?: boolean
}

const AcceptorTableQrCode: React.FC<AcceptorTableQrCodeProps> = ({
  acceptorCode,
  tableIdentifier,
  size,
  showLink = false,
  showPrint = false,
}) => {
  const toast = useToast()
  const qrcodeValue = `${API_BASE_URL.substr(0, API_BASE_URL.length - 4)}/c/${acceptorCode}?t=${tableIdentifier}`

  return (
    <div className='ion-text-center'>
      <AyrCode value={qrcodeValue} size={size} logoOpacity={0.9} />
      {showLink && (
        <>
          <br />
          <CopyToClipboard text={qrcodeValue} onCopy={() => toast.info('لینک QrCode کپی شد')}>
            <IonButton fill='clear' color='primary'>
              <IonIcon icon={clipboard} slot='start' />
              {'کپی لینک Qr'}
            </IonButton>
          </CopyToClipboard>
        </>
      )}
      {showPrint && (
        <IonButton fill='clear'>
          <a href={`${API_BASE_URL}/public/table/${acceptorCode}/${tableIdentifier}`} target='blank'>
            <IonIcon icon={open} />
            {' چاپ استند'}
          </a>
        </IonButton>
      )}
    </div>
  )
}

export default AcceptorTableQrCode
