import React, { useContext, useEffect, useState } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { pin } from 'ionicons/icons'
import { LayerContext } from './LayerContext'
import useAddMarker from './useAddMarker'
import './AddMarkerButton.css'

const AddMarkerButton: React.FC = () => {
  const { activate, setActivate } = useAddMarker(false) // eslint-disable-line
  const { point, ccenter, setPoint } = useContext(LayerContext)
  const [inprogress, setInprogress] = useState(false)

  const handleSelect = () => {
    setInprogress(true)
    setPoint(ccenter)
    setInprogress(false)
  }

  useEffect(() => {}, [point]) // eslint-disable-line

  return (
    <>
      <div className='pin-button' style={{ width: '50px', height: '50px' }}>
        <IonIcon color='danger' icon={pin} slot='icon-only' style={{ width: '50px', height: '50px' }} />
      </div>
      {
        <IonButton
          className='pin-button'
          style={{ bottom: '15px', top: 'unset', left: 'calc(50% - 18px)' }}
          fill='solid'
          size='small'
          expand='full'
          color={!!point ? 'light' : 'primary'}
          onClick={handleSelect}
          disabled={inprogress}>
          {'تایید'}
        </IonButton>
      }
    </>
  )
}

export default AddMarkerButton
