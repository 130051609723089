import jalaali from 'jalaali-js';

export function toGregorian(date) {
  const gDate = jalaali.toGregorian(date.year, date.month, date.day)
  return `${gDate.gy}-${("00"+gDate.gm).substr(-2,2)}-${("00"+gDate.gd).substr(-2,2)}`;
}

export function toJalali(date) {
  const jDate = jalaali.toJalaali(date.year, date.month, date.day)
  return { year: jDate.jy, month: jDate.jm, day: jDate.jd };
}

export function toJalaliFromString(dateString) {
  const date = dateString.split('-')
  return toJalali({year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2])});
}

export function toJalaliStringFromGregorianString(dateString) {
  const date = dateString.split('-')
  const jdate = toJalali({ year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2]) })
  return `${jdate.year}-${jdate.month < 10 ? '0' + jdate.month : jdate.month}-${jdate.day < 10 ? '0' + jdate.day : jdate.day}`;
}
