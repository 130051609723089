import React, { useState, useEffect } from 'react'
import { trash } from 'ionicons/icons'
import { IonItem, IonLabel, IonItemDivider, IonIcon, IonSpinner, IonList, IonBadge } from '@ionic/react'
import { ApiService, useToast, SingleUploader, CDN_BASE_URL } from '../../ayria'

interface OwnProps {
  code: string
}
interface MyClubBannerProps extends OwnProps {}

const MyClubBanner: React.FC<MyClubBannerProps> = ({ code }) => {
  const api = new ApiService()
  const toast = useToast()
  const [item, setItem] = useState<any>()
  const [inprogress, setInprogress] = useState(false)

  const updateBanner = async (meta: any, content: any, identifier: string) => {
    setInprogress(true)
    const res: any = await api.userClubUpdateBanner({
      code,
      content,
      contentType: meta.type,
    })
    if (res.ok) {
      setItem(res.data)
      toast.success('بنر با موفقیت آپلود شد')
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const getItem = async () => {
    const res: any = await api.getMyClub(code)
    if (res.ok) {
      setItem(res.data)
    } else {
      toast.error(res)
    }
  }

  const uploadImage = (meta: any, file: File, identifier: string) => {
    if (meta.size > 2097152) {
      toast.error(`حجم فایل ${meta.name} باید کمتر از ۲ مگابایت باشد`)
      return
    }
    const reader = new FileReader()
    const fileByteArray: any = []
    reader.readAsArrayBuffer(file)
    reader.onloadend = function (evt: any) {
      if (evt.target.readyState === FileReader.DONE) {
        let arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer)
        for (var i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])
        }
        updateBanner(meta, fileByteArray, identifier)
      }
    }
  }

  useEffect(() => {
    getItem()
  }, []) // eslint-disable-line

  return (
    <div id='my-club-banner'>
      <IonList>
        <IonItemDivider>
          <IonLabel>
            {'بارگذاری بنر'}
            {inprogress && <IonSpinner slot='end' color='secondary' />}
          </IonLabel>
        </IonItemDivider>
        <IonItem>
          <IonLabel>
            {'بنر'}
            <p slot='start' style={{ fontSize: '60%' }}>
              {'1200x300'}
            </p>
          </IonLabel>
          {!item?.bannerPath && (
            <div style={{ width: '50%' }}>
              <SingleUploader onFileUploaded={uploadImage} accept={'image/*'} identifier='BANNER' />
            </div>
          )}
          {item?.bannerPath && (
            <div className='main-logo'>
              <img src={`${CDN_BASE_URL}/${item.bannerPath}`} alt='' crossOrigin='anonymous' />
              <IonBadge onClick={() => setItem({ ...item, bannerPath: undefined })} slot='end' color='danger'>
                <IonIcon icon={trash} />
              </IonBadge>
            </div>
          )}
        </IonItem>
      </IonList>
    </div>
  )
}

export default MyClubBanner
