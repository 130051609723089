import React, { FC, useEffect, useState } from 'react'
import { IonBadge, IonButton, IonIcon, IonInput, IonItem, IonItemDivider } from '@ionic/react'
import { IonItemOptions, IonItemSliding, IonLabel, IonSpinner, IonItemOption } from '@ionic/react'
import { trash } from 'ionicons/icons'
import { connect } from '../../data'
import { convertToEnglish, setWithTimeout, useToast } from '../../utility'
import { ApiService } from '../../ayria'

interface OwnProps {
  groupId: string
}
interface StateProps {
  myClubCode: string
}
interface MyClubGroupMemberProps extends OwnProps, StateProps {}

const MyClubGroupMember: FC<MyClubGroupMemberProps> = ({ myClubCode, groupId }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [inprogress, setInprogress] = useState(true)
  const [searchText, setSearchText] = useState<any>()
  const [searchItems, setSearchItems] = useState([])
  const [searchTotal, setSearchTotal] = useState(0)

  const fetchItems = async () => {
    setInprogress(true)
    const res: any = await api.get(
      `/club/club-group-member/list/${myClubCode}/${groupId}?size=5${
        !!searchText ? `&search.contains=${convertToEnglish(searchText)}` : ''
      }`
    )
    if (res.ok) {
      setWithTimeout(setItems, res.data)
      setTotal(res.headers['x-total-count'])
    }
    const res2: any = await api.get(
      `/club/club-group-member/search/member/${myClubCode}?size=3${
        !!searchText ? `&search.contains=${convertToEnglish(searchText)}` : ''
      }`
    )
    if (res2.ok) {
      setSearchItems(res2.data.filter((i: any) => !res.data.map((j: any) => j.clubMemberId).includes(i.id)))
      setSearchTotal(res2.headers['x-total-count'])
    } else {
      toast.error(res2)
    }
    setInprogress(false)
  }

  const addToGroup = async (itm: any) => {
    setInprogress(true)
    const res: any = await api.post('/club/club-group-member/create', {
      clubCode: myClubCode,
      groupId,
      memberId: itm?.id,
    })
    if (res.ok) {
      await fetchItems()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const deleteMember = async (itm: any) => {
    setInprogress(true)
    const res: any = await api.delete(`/club/club-group-member/delete/${myClubCode}/${groupId}/${itm.id}`)
    if (res.ok) {
      toast.success('عضو با موفقیت حذف شد')
      await fetchItems()
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    if (!!myClubCode) {
      fetchItems()
    }
  }, [searchText, myClubCode]) // eslint-disable-line

  return (
    <>
      <IonItem>
        <IonLabel position='floating'>
          {' جستجو '}
          {searchText}
        </IonLabel>
        <IonInput onIonChange={(e) => setSearchText(e.detail.value)} debounce={500} />
        {inprogress && <IonSpinner slot='end' />}
        {!!searchTotal && <IonBadge slot='end'>{searchTotal}</IonBadge>}
      </IonItem>
      {searchItems.slice(0, 3).map((itm: any) => (
        <IonItem key={itm.id}>
          <IonLabel>
            {itm?.member?.firstName} {itm?.member?.lastName}
            <p>{itm?.member?.login}</p>
          </IonLabel>
          <IonButton onClick={() => addToGroup(itm)}>{'اضافه به گروه'}</IonButton>
        </IonItem>
      ))}
      <IonItemDivider>
        <IonLabel>{'اعضای گروه'}</IonLabel>
        {inprogress && <IonSpinner slot='end' />}
        {!!total && <IonBadge slot='end'>{total}</IonBadge>}
      </IonItemDivider>
      {items.map((itm: any) => (
        <IonItemSliding key={itm.id}>
          <IonItem>
            <IonLabel>
              {itm.memberFullname}
              <p>{itm.memberMobile}</p>
            </IonLabel>
          </IonItem>
          <IonItemOptions>
            <IonItemOption
              color='danger'
              style={{ width: '30px' }}
              onClick={() => deleteMember(itm)}
              disabled={inprogress}>
              <IonIcon icon={trash}></IonIcon>
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    myClubCode: state.shop.myClubCode,
  }),
  component: MyClubGroupMember,
})
