import React from 'react'
import { connect } from '../../data'
import { CLUB_LOWER_CASE, CDN_BASE_URL } from '../../config'
import { Club } from '../../models'
import './Banner.scss'
import { isDesktop } from '../../utility'

interface OwnProps {
  override?: any
}
interface StateProps {
  savedClub?: Club
}
interface LogoProps extends OwnProps, StateProps {}

const Banner: React.FC<LogoProps> = ({ savedClub, override = false }) => {
  return (
    <>
      {override && (
        <img
          data-src={`${CDN_BASE_URL}/${override}`}
          alt='banner'
          className='home-banner lazyload'
          crossOrigin='anonymous'
        />
      )}
      {!override && savedClub?.banner && (
        <img
          data-src={`${CDN_BASE_URL}/${savedClub.banner}`}
          alt='banner'
          className='home-banner lazyload'
          crossOrigin='anonymous'
        />
      )}
      {!override && !savedClub?.banner && (
        <img
          data-src={`/assets/${CLUB_LOWER_CASE}/home.png`}
          alt=''
          className='home-banner lazyload'
          width={isDesktop() ? '1024px' : '650px'}
          height={isDesktop() ? '100px' : '100px'}
        />
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    savedClub: state.user.savedClub,
  }),
  component: Banner,
})
