import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PlanSelect from '../../components/screens/plan-select/plan-select'
import { IS_AYRIA, IS_PERSEPOLIS, IS_KARAFARIN } from '../../ayria'

const LandingJoin = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='landing__join'>
      <div className='landing__join-login-and-signup'>
        <div onClick={() => history.push('/signup')} className='signup-button pointer'>
          {t('login.signup')}
        </div>
        <div onClick={() => history.push('/login')} className='login-button pointer'>
          {t('login.loginTitle')}
        </div>
      </div>
      <div className='landing__join-plans'>
        {!IS_KARAFARIN && <PlanSelect isOnlyInfo={true} />}
        {IS_KARAFARIN && (
          <div>
            <img
              src='/assets/ayria/landing/plan-star.png'
              alt='star'
              onClick={() => history.push('/purchase-plan?plan=STAR')}
              className='star-plan ion-padding'
            />
            <img
              src='/assets/ayria/landing/plan-galaxy.png'
              alt='galaxy'
              onClick={() => history.push('/purchase-plan?plan=GALAXY')}
              className='galaxy-plan ion-padding'
            />
          </div>
        )}
      </div>
      <div className='landing__join-footer-message'>
        {IS_AYRIA && (
          <span>
            {
              'همه ی اینها رو هم یکجا با عضویت در باشگاه وفاداری آیریا خواهید داشت. در ضمن چون شما جزو مشتریان ویژه هستید با یه تخفیف ویژه میتونید آیریایی بشید.'
            }
          </span>
        )}
        {IS_PERSEPOLIS && (
          <span>
            {'همه این مزایا برای اینکه هوادار پرسپولیسی با عضویت در این باشگاه هواداری برای شما ایجاد کردیم، پس'}
          </span>
        )}
        {IS_KARAFARIN && <span>{'تمام تلاشمان را برای حفظ سرمایه ارزشمندمان می‌کنیم، پس'}</span>}
      </div>
      <div className='landing__join-footer pointer' onClick={() => history.push('/signup')}>
        <span>
          همین حالا <span className='text-bold'>ثبت نام</span> کن
        </span>
      </div>
    </div>
  )
}

export default LandingJoin
