import React from 'react'
import { IonRow, IonCol, IonText } from '@ionic/react'

interface AyriaPaymentKitchenPrintProps {
  item: any
}

const AyriaPaymentKitchenPrint: React.FC<AyriaPaymentKitchenPrintProps> = ({ item }) => {
  return (
    <>
      <IonRow>
        <IonCol></IonCol>
        <IonCol className='ion-text-end'>
          <span style={{ fontSize: '11px' }}>{'شماره'}</span>
          <span className='ion-padding-start'>{item.number}</span>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' className='ion-no-padding ion-text-center border-color-07'>
          <IonText>
            {item.receiveType === 'TABLES'
              ? `میز ${item.tableIdentifier}`
              : item.receiveType === 'PICKUP'
              ? 'حضوری'
              : 'پیک'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <span className='font-11'>{'ش پیگیری '}</span>{' '}
          <span className='ion-padding-start'>{item.trackingNumber}</span>
        </IonCol>
        <IonCol className='ion-text-end'>{item.createdDate.replaceAll('-', '/')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <span className='font-11'>
            {'صندوقدار '} {item.cashierName}
          </span>
        </IonCol>
        <IonCol className='ion-text-end f-100'>{item.createdTime}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12'>
          <IonRow style={{ borderBottom: '1px solid', borderTop: '1px solid' }}>
            <IonCol>
              <strong>{'نام محصول'}</strong>
            </IonCol>
            <IonCol size='3'>
              <strong>{'تعداد'}</strong>
            </IonCol>
          </IonRow>
          {item?.kalas?.map((k: any) => (
            <IonRow key={k.id}>
              <IonCol>
                {k.name} {!!k.description ? `(${k.description})` : ''}
              </IonCol>
              <IonCol size='2'>{k.count}</IonCol>
            </IonRow>
          ))}
        </IonCol>
      </IonRow>
      {!!item?.description && (
        <IonRow style={{ borderTop: '1px solid' }}>
          <IonCol>
            <span className='ion-padding-end'>{'توضیحات'}</span> {item.description}
          </IonCol>
        </IonRow>
      )}
    </>
  )
}

export default AyriaPaymentKitchenPrint
