import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import ScoreEvents from '../components/screens/score-events/score-events'
import { Header } from '../ayria'
import { isDesktop } from '../utility'

interface ScoreTabProps {}

const ScoreTab: React.FC<ScoreTabProps> = () => {
  return (
    <IonPage id='score-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <ScoreEvents />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default React.memo(ScoreTab)
