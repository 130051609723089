import React, { useContext, useEffect, useState } from 'react'
import { LayerContext } from '../../../pages/shared/map'
import LeafletMap from '../../../pages/shared/map/LeafletMap'

interface MerchantProfileMapProps {
  merchant: any
}

const MerchantProfileMap: React.FC<MerchantProfileMapProps> = ({ merchant }) => {
  const { setCenter, setZoom, setPoint } = useContext(LayerContext)
  const [lat, setLat] = useState<any>()
  const [lng, setLng] = useState<any>()

  let coordinates = merchant.contacts.find((element: any) => element.contactType === 'COORDINATES')

  if (!!coordinates && !lat && !lng) {
    const latLng = coordinates.value.split(',')
    setLat(Number(latLng[0]))
    setLng(Number(latLng[1]))
  }

  useEffect(() => {
    if (!!lat && !!lng) {
      setCenter({ lat, lng })
      setPoint({ lat, lng })
      setZoom(18)
    }
  }, [lat, lng]) // eslint-disable-line

  return (
    <div id='merchant-profile-map-container'>
      {!!lat && (
        <div style={{ height: '300px', width: '100%' }}>
          <LeafletMap showPoint={true} />
        </div>
      )}
    </div>
  )
}

export default MerchantProfileMap
