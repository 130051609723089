import { ActionType } from '../../utility/types'
import { ShopState } from './shop.state'
import { AcceptorSettings } from '../../models'
import {
  getShopData,
  setSendDeliveryStatusData,
  setSpecifyWaiterStatusData,
  setShiftTimeToData,
  setShiftTimeFromData,
  setCurrentCashdeskData,
  setDeskKalaListData,
  addDeskKalaListData,
  removeDeskKalaListData,
  setAcceptorCodeData,
  setLastAyriaPaymentData,
  setDeskFactorListData,
  addDeskFactorListData,
  removeDeskFactorListData,
  setMyClubCodeData,
} from '../dataApi'
import React from 'react'

export const loadShopData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getShopData()
  dispatch(setData(data))
}

export const setData = (data: Partial<ShopState>) =>
  ({
    type: 'set-shop-data',
    data,
  } as const)

export const setHasDeliveryMan = (hasDeliveryMan: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-has-delivery-man',
    hasDeliveryMan,
  } as const
}

export const setHasWaiter = (hasWaiter: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-has-waiter',
    hasWaiter,
  } as const
}

export const setHasKala = (hasKala: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-has-kala',
    hasKala,
  } as const
}

export const setHasCashdesk = (hasCashdesk: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-has-cashdesk',
    hasCashdesk,
  } as const
}

export const setOnlineOrderCount = (onlineOrderCount: any) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-online-order-count',
    onlineOrderCount,
  } as const
}

export const setSendDeliveryStatus = (sendDeliveryStatus: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setSendDeliveryStatusData(sendDeliveryStatus)
  return {
    type: 'set-send-delivery-status',
    sendDeliveryStatus,
  } as const
}

export const setSpecifyWaiterStatus = (specifyWaiterStatus: boolean) => async (dispatch: React.Dispatch<any>) => {
  await setSpecifyWaiterStatusData(specifyWaiterStatus)
  return {
    type: 'set-specify-waiter-status',
    specifyWaiterStatus,
  } as const
}

export const setShiftTimeFrom = (shiftTimeFrom: string) => async (dispatch: React.Dispatch<any>) => {
  await setShiftTimeFromData(shiftTimeFrom)
  return {
    type: 'set-shift-time-from',
    shiftTimeFrom,
  } as const
}

export const setShiftTimeTo = (shiftTimeTo: string) => async (dispatch: React.Dispatch<any>) => {
  await setShiftTimeToData(shiftTimeTo)
  return {
    type: 'set-shift-time-to',
    shiftTimeTo,
  } as const
}

export const setCurrentCashdesk = (currentCashdesk: Cashdesk) => async (dispatch: React.Dispatch<any>) => {
  await setCurrentCashdeskData(currentCashdesk)
  return {
    type: 'set-current-cashdesk',
    currentCashdesk,
  } as const
}

export const setLastAyriaPayment = (lastAyriaPayment: any) => async (dispatch: React.Dispatch<any>) => {
  await setLastAyriaPaymentData(lastAyriaPayment)
  return {
    type: 'set-last-ayria-payment',
    lastAyriaPayment,
  } as const
}

export const setDeskKalaList = (kalaList: any[]) => async (dispatch: React.Dispatch<any>) => {
  const deskKalaList = await setDeskKalaListData(kalaList)
  return {
    type: 'set-desk-kala-list',
    deskKalaList,
  } as const
}

export const addDeskKala = (kala: any) => async (dispatch: React.Dispatch<any>) => {
  const deskKalaList = await addDeskKalaListData(kala)
  return {
    type: 'set-desk-kala-list',
    deskKalaList,
  } as const
}

export const removeDeskKala = (kala: any) => async (dispatch: React.Dispatch<any>) => {
  const deskKalaList = await removeDeskKalaListData(kala)
  return {
    type: 'set-desk-kala-list',
    deskKalaList,
  } as const
}

export const setDeskFactorList = (factorList: any[]) => async (dispatch: React.Dispatch<any>) => {
  const deskFactorList = await setDeskFactorListData(factorList)
  return {
    type: 'set-desk-factor-list',
    deskFactorList,
  } as const
}

export const addDeskFactor = (factor: any) => async (dispatch: React.Dispatch<any>) => {
  const deskFactorList = await addDeskFactorListData(factor)
  return {
    type: 'set-desk-factor-list',
    deskFactorList,
  } as const
}

export const removeDeskFactor = (factor: any) => async (dispatch: React.Dispatch<any>) => {
  const deskFactorList = await removeDeskFactorListData(factor)
  return {
    type: 'set-desk-factor-list',
    deskFactorList,
  } as const
}

export const setSettings = (settings: AcceptorSettings) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-settings',
    settings,
  } as const
}

export const setAcceptorCode = (acceptorCode: string) => async (dispatch: React.Dispatch<any>) => {
  await setAcceptorCodeData(acceptorCode)
  return {
    type: 'set-acceptor-code',
    acceptorCode,
  } as const
}

export const setMyClubCode = (myClubCode: string) => async (dispatch: React.Dispatch<any>) => {
  await setMyClubCodeData(myClubCode)
  return {
    type: 'set-my-club-code',
    myClubCode,
  } as const
}

export const setScore = (score: number) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-score',
    score,
  } as const
}

export const setSocketDisconnected = (socketDisconected: boolean) => async (dispatch: React.Dispatch<any>) => {
  return {
    type: 'set-socket-disconnected',
    socketDisconected,
  } as const
}

export type ShopActions =
  | ActionType<typeof setData>
  | ActionType<typeof setHasDeliveryMan>
  | ActionType<typeof setHasWaiter>
  | ActionType<typeof setSendDeliveryStatus>
  | ActionType<typeof setSpecifyWaiterStatus>
  | ActionType<typeof setShiftTimeFrom>
  | ActionType<typeof setShiftTimeTo>
  | ActionType<typeof setHasKala>
  | ActionType<typeof setHasCashdesk>
  | ActionType<typeof setOnlineOrderCount>
  | ActionType<typeof setCurrentCashdesk>
  | ActionType<typeof setDeskKalaList>
  | ActionType<typeof addDeskKala>
  | ActionType<typeof removeDeskKala>
  | ActionType<typeof setSettings>
  | ActionType<typeof setAcceptorCode>
  | ActionType<typeof setLastAyriaPayment>
  | ActionType<typeof setDeskKalaList>
  | ActionType<typeof addDeskFactor>
  | ActionType<typeof removeDeskFactor>
  | ActionType<typeof setScore>
  | ActionType<typeof setSocketDisconnected>
  | ActionType<typeof setMyClubCode>
