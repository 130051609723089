import React, { useState, useEffect } from 'react'
import { IonInput } from '@ionic/react'
import ApiService from '../../../api/ApiService'
import useDebounce from '../../../utility/use-debounce'

interface OwnProps {
  mobile: string
  value?: string
  onChange: (fullname: string) => void
  disabled?: boolean
}
interface FullnameLookupProps extends OwnProps {}

const FullnameLookup: React.FC<FullnameLookupProps> = ({
  mobile,
  value = '',
  onChange,
  disabled = false,
  ...inputProps
}) => {
  const api = new ApiService()
  const [fullname, setFullname] = useState(value)
  const dfullname = useDebounce(fullname, 2000)
  const [isUser, setIsUser] = useState(true)

  const onChangeInternal = async (newName: string) => {
    setFullname(newName)
    onChange(newName)
  }

  const updateFullname = async (dFullname: string) => {
    if (mobile?.length === 11) {
      const res: any = await api.put(`/mobile-suggests/update`, {
        mobile,
        fullname: dFullname,
      })
      if (res.ok) {
        setFullname(res.data.fullname)
        setIsUser(res.data.user)
      }
    }
  }

  const fetchFullname = async () => {
    const res: any = await api.get(`/mobile-suggests/suggest/${mobile}`)
    if (res.ok && !!res.data.fullname) {
      setFullname(res.data.fullname)
      setIsUser(res.data.user)
      onChange(res.data.fullname)
    }
  }

  useEffect(() => {
    if (mobile?.length === 11) {
      fetchFullname()
    }
  }, [mobile]) // eslint-disable-line

  useEffect(() => {
    onChangeInternal(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (!!dfullname) {
      updateFullname(dfullname)
    }
  }, [dfullname]) // eslint-disable-line

  return (
    <IonInput
      value={fullname}
      onIonChange={(e: any) => onChangeInternal(e.detail.value)}
      placeholder={'نام و نام خانوادگی'}
      debounce={500}
      disabled={disabled}
      color={isUser ? 'tertiary' : 'dark'}
      {...inputProps}
    />
  )
}

export default FullnameLookup
