import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import WalletEventRefType from '../../units/wallet/WalletEventRefType'

interface WalletReceiptProps {
  walletEvent: any
}
const WalletReceipt: FC<WalletReceiptProps> = ({ walletEvent }) => {
  const { t } = useTranslation()
  return (
    <>
      {walletEvent && (
        <div>
          <div className='text-center'>
            <WalletEventRefType walletEvent={walletEvent} width={'40px'} />
          </div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td className='text-right'>مبلغ</td>
                <td>
                  {walletEvent.amount.toLocaleString(navigator.language)} {t('label.riyal')}
                </td>
              </tr>
              <tr>
                <td className='text-right'>تاریخ و زمان</td>
                <td>
                  {walletEvent.createdTime} {walletEvent.createdAt}
                </td>
              </tr>
              <tr>
                <td className='text-right'>توضیحات</td>
                <td>{walletEvent.description}</td>
              </tr>
              <tr>
                <td className='text-right'>توضیحات عملیات</td>
                <td>{walletEvent.actionDescription}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default WalletReceipt
