import React, { useState, useEffect } from 'react'
import { IonItem, IonAvatar, IonText } from '@ionic/react'
import { IonLabel } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import WalletResponse from '../../screens/wallet/wallet-response/wallet-response'
import { ApiService, AyriaPaymentPayerReceipt, CustomModal, AcceptorLogo, setWithTimeout } from '../../../ayria'

const WalletPayerResponse = ({ receiverType = '' }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState({ amount: 0 })
  const [inprogress, setInprogress] = useState(false)
  const [ayriaPayment, setAyriaPayment] = useState()
  const [showAyriaPaymentPayerReceiptModal, setShowAyriaPaymentPayerReceiptModal] = useState(false)

  const getItems = async () => {
    setInprogress(true)
    const res = await api.get(`/user/ayria-payments/payer-responses${receiverType ? '/' + receiverType : ''}`)
    if (res.ok) {
      setWithTimeout(setItems, res.data)
      if (res.data.length > 0) {
        setTotal(res.data.map((item) => item.unitedAmount).reduce((prev, next) => prev + next))
      } else {
        setTotal(0)
      }
    }
    setInprogress(false)
  }

  const handleOnSuccess = async (ayriaPaymentRes) => {
    await setShowModal(false)
    setAyriaPayment(ayriaPaymentRes)
    if (ayriaPaymentRes) {
      setTimeout(() => {
        setShowAyriaPaymentPayerReceiptModal(true)
      }, 300)
    }
    await getItems()
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      {!inprogress && (
        <div style={{ minHeight: '300px' }}>
          <h5>
            {receiverType === 'company' ? 'صورتحسابهای  قابل پرداخت: ' : 'جمع پرداختی ها:‌ '}{' '}
            {total.toLocaleString(navigator.language)} {t('label.riyal')}
          </h5>
          <br />
          {items.map((item) => (
            <IonItem
              onClick={(e) => {
                setItem(item)
                setShowModal(true)
              }}
              key={item.trackingNumber}>
              {item.payeeType === 'ACCEPTOR' && (
                <IonAvatar slot='start' color='primary'>
                  <AcceptorLogo referralCode={item.payeeReferralCode} />
                </IonAvatar>
              )}
              <IonLabel>
                <IonText>
                  <span className='text-muted'>{item.payeeType === 'ACCEPTOR' ? 'صورتحساب ' : 'درخواست وجه '}</span>
                  <IonText title={item.payeeIdentity}>{item.payeeName}</IonText>
                </IonText>
                <IonText>
                  <h2 className='ion-text-left'>
                    {item.unitedAmount.toLocaleString(navigator.language)} {t('label.riyal')}
                    <span className='float-right text-muted'>{item.createdDate}</span>
                  </h2>
                </IonText>
              </IonLabel>
            </IonItem>
          ))}
          <CustomModal
            showModal={showModal}
            toggle={setShowModal}
            title={t('wallet.doPay')}
            content={<WalletResponse trackingNumberProp={item.trackingNumber} onSuccess={handleOnSuccess} />}
          />
          <CustomModal
            showModal={showAyriaPaymentPayerReceiptModal}
            toggle={setShowAyriaPaymentPayerReceiptModal}
            title={receiverType === 'company' ? 'رسید صورتحساب' : 'رسید درخواست وجه'}
            content={<AyriaPaymentPayerReceipt ayriaPayment={ayriaPayment} />}
          />
        </div>
      )}
    </>
  )
}

export default WalletPayerResponse
