import { lazy } from 'react'
import Header from './shared/Header'
import ScrollContent from './shared/ScrollContent'
import Logo from './shared/Logo'
import SmallLogo from './shared/SmallLogo'
import WebOTP from './shared/WebOTP'
import Banner from './shared/Banner'
import CopyRight from './CopyRight'
const ReferralCode = lazy(() => import('./shared/ReferralCode'))

export { Header, ScrollContent, Logo, SmallLogo, ReferralCode, WebOTP, Banner, CopyRight }
