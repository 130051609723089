import React from 'react'
import CurrencyInput from '../CurrencyInput/CurrencyInput'

const MobileInput = ({ onValueChange, showBorderBottomOnly = false, ...inputProps }) => {
  const onValueChangeFormatted = (value) => {
    if (value && !value?.startsWith('0')) {
      onValueChange('0' + value)
    } else {
      onValueChange(value)
    }
  }

  return (
    <CurrencyInput
      maskOptions={{ includeThousandsSeparator: false, allowLeadingZeroes: true, allowNegative: false }}
      placeholder={'شماره همراه'}
      onValueChange={onValueChangeFormatted}
      showBorderBottomOnly={showBorderBottomOnly}
      {...inputProps}
    />
  )
}

export default MobileInput
