import React, { useState, useEffect, useContext } from 'react'
import { Input, FormGroup, Label, Button, Popover } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { WalletContext } from '../../structures/contexts/WalletContext'
import { ScoreContext } from '../../structures/contexts/ScoreContext'
import mobileImageResolver from '../../../utility/mobile-image-resolver'
import { convertToEnglish } from '../../../utility/number'
import WalletDeposit from '../WalletDeposit/WalletDeposit'
import { CustomModal } from '../../../ayria'
import WalletBalance from '../WalletBalance/WalletBalance'
import { IPG_MIN_ACCEPTED_AMOUNT } from '../../../config'
import { useToast } from '../../../ayria'

const InternetCharge = ({ t }) => {
  const toaster = useToast()
  const [mobileNumber, setMobileNumber] = useState('')
  const [chargeType, setChargeType] = useState(0)
  const [result, setResult] = useState()
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState('form')
  const [score, setScore] = useContext(ScoreContext)
  const [wallet, setWallet] = useContext(WalletContext)
  const [mobileImage, setMobileImage] = useState('')
  const [mobileSuggests, setMobileSuggests] = useState([])
  const [showMobileSuggest, setShowMobileSuggest] = useState(false)
  const [packages, setPackages] = useState([])
  const [duration, setDuration] = useState(undefined)
  const [internetPackage, setInternetPackage] = useState({})
  const [additionalData, setAdditionalData] = useState()
  const [useScore, setUseScore] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [neededAmount, setNeededAmount] = useState(0)
  const [originalCommand, setOriginalCommand] = useState()
  async function handleSubmit() {
    setInprogress(true)
    const res = await api.post(`/user/internetcharge/do`, { mobileNumber, useScore, additionalData, internetPackage })
    if (res.ok) {
      if (res.data.ok) {
        setResult(res.data)
        setPage('result')
      } else {
        toaster.error(res.data.returnMessage)
      }
    } else {
      if (res.status === 417) {
        const debt = internetPackage.aff_amount - wallet.balance
        setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
        setOriginalCommand(JSON.stringify({ mobileNumber, useScore, additionalData, internetPackage }))
        setShowWalletDepositModal(true)
      } else {
        toaster.error(res)
      }
    }
    if (useScore) {
      const scoreRes = await api.get(`/customer/scores/total/0`)
      setScore((state) => ({ ...state, balance: scoreRes.data }))
    } else {
      const res = await api.fn.getWalletBalance({ acceptorCode: null })
      setWallet((state) => ({ ...state, balance: res.data.balance }))
    }
    setInprogress(false)
  }
  const resolveMobileImage = (value) => {
    setMobileImage(mobileImageResolver(value))
  }
  const triggerShowMobileSuggest = (value) => {
    if (mobileSuggests.length > 0) {
      setShowMobileSuggest(true)
    }
  }
  const triggerHideMobileSuggest = () => {
    setTimeout(() => {
      setShowMobileSuggest(false)
    }, 300)
  }
  async function listPackages() {
    setInprogress(true)
    const res = await api.post(`/user/internetcharge/packages`, { mobileNumber, chargeType })
    if (res.ok) {
      setPackages(res.data.packages)
      setAdditionalData(res.data.additionalData)
      setPage('packages')
    } else {
      toaster.error(res)
    }
    setInprogress(false)
  }
  async function getMobileSuggests() {
    const res = await api.get(`/user/internetcharge/suggest`)
    setMobileSuggests(res.ok ? res.data : [])
  }
  const calculateScoreDiscount = (amount) => {
    if (score.balance * 200 > amount) {
      return amount / 2
    }
    return score.balance * 100
  }
  const hasSuggest = () => {
    if (
      showMobileSuggest &&
      mobileSuggests.length > 0 &&
      mobileSuggests.filter((item) => item.mobile.includes(mobileNumber)).length > 0
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    getMobileSuggests()
  }, [])

  return (
    <>
      {page === 'form' && (
        <div>
          <WalletBalance acceptorCode={null} />
          <FormGroup>
            <Label for='mobile'>
              {'شماره همراه'}{' '}
              {mobileImage && <img alt='' src={mobileImage} className='text-left' width='35px' height='35px' />}
            </Label>
            <Input
              id='mobile'
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(convertToEnglish(e.target.value))
                resolveMobileImage(e.target.value)
              }}
              onFocus={triggerShowMobileSuggest}
              onBlur={triggerHideMobileSuggest}
              className='text-center'
              autoComplete='off'
              type='number'
            />
            <Popover target='mobile' isOpen={showMobileSuggest && hasSuggest()} placement='bottom'>
              {mobileSuggests
                .filter((item) => item.mobile.includes(mobileNumber))
                .map((item, index) => (
                  <div
                    key={item.mobile}
                    onClick={(e) => {
                      setMobileNumber(item.mobile)
                      resolveMobileImage(item.mobile)
                    }}
                    className='text-center'>
                    <span className='padding'>{item.mobile}</span>
                    <img alt='' src={mobileImageResolver(item.mobile)} width='35px' height='35px' className='padding' />
                    {index + 1 !== mobileSuggests.length && <hr />}
                  </div>
                ))}
            </Popover>
          </FormGroup>
          <FormGroup>
            <Label id='chargeType'>{'نوع بسته'}</Label>
            <Input
              id='chargeType'
              value={chargeType}
              onChange={(e) => setChargeType(e.target.value)}
              className='text-center'
              type='select'>
              <option value='0'>{'سیم کارت دائمی'}</option>
              <option value='1'>{'سیم کارت اعتباری'}</option>
              <option value='2'>{'TD-LTE اعتباری'}</option>
              <option value='3'>{'سیم کارت دیتا'}</option>
              <option value='4'>{'TD-LTE دائمی'}</option>
            </Input>
          </FormGroup>
          <br />
          <br />
          <div className='text-center'>
            <Button
              className='btn-theme'
              onClick={listPackages}
              disabled={inprogress || !mobileNumber || mobileNumber.length !== 11}>
              {'مرحله بعد'}
            </Button>
          </div>
        </div>
      )}
      {page === 'packages' && packages && (
        <div>
          <FormGroup>
            <Label id='duration'>{'مدت'}</Label>
            <Input
              id='duration'
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className='text-center'
              type='select'>
              <option value={undefined}>{''}</option>
              <option>{'روزانه'}</option>
              <option>{'هفتگی'}</option>
              <option>{'یک ماهه'}</option>
              <option>{'دو ماهه'}</option>
              <option>{'سه ماهه'}</option>
              <option>{'چهار ماهه'}</option>
              <option>{'شش ماهه'}</option>
              <option>{'یکساله'}</option>
            </Input>
          </FormGroup>
          {packages
            .filter((pk) => {
              if (duration) {
                return pk.caption === duration
              }
              if (!pk.org_amount) {
                return false
              }
              return true
            })
            .map((pk) => (
              <table
                className='table border-full'
                key={pk.ch_type}
                onClick={(e) => {
                  setInternetPackage(pk)
                  setPage('confirm')
                }}>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>{pk.trafic}</td>
                    <td className='text-left'>
                      {(pk.org_amount || 0).toLocaleString(navigator.language)} {t('label.riyal')}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='2'>{pk.title}</td>
                  </tr>
                  <tr>
                    <td colSpan='2'>
                      {'مبلغ با احتساب ۹ درصد مالیات '} {(pk.aff_amount || 0).toLocaleString(navigator.language)}{' '}
                      {t('label.riyal')}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
        </div>
      )}
      {page === 'confirm' && internetPackage && (
        <div>
          <WalletBalance />
          <div className='text-center'>
            <div>{'پکیج اینترنت'}</div>
            <div>
              {'شماره همراه: '} {mobileNumber}
            </div>
            <div>
              {internetPackage.trafic} {internetPackage.caption}
            </div>
            <div>{internetPackage.title}</div>
            <div>
              {'مبلغ با احتساب ۹ درصد مالیات '} {internetPackage.aff_amount.toLocaleString(navigator.language)}{' '}
              {t('label.riyal')}
            </div>
            <div className='text-warning'>
              {'مبلغ: '} {internetPackage.org_amount.toLocaleString(navigator.language)} {t('label.riyal')}
            </div>
            <div>{'۹٪ بابت مالیات بر ارزش افزوده به مبلغ اضافه خواهد شد.'}</div>
          </div>
          <br />
          <br />
          <div className={'border-full p-2 mt-2'}>
            <div className={'text-center text-bold'}>روش پرداخت</div>
            <div className={'text-center'}>
              <button
                className='btn btn-score btn-sm'
                onClick={(e) => {
                  setPage('confirm2')
                  setUseScore(true)
                }}
                disabled={inprogress}>
                <div>امتیاز</div>
              </button>
              <button
                className='btn btn-wallet btn-sm'
                onClick={(e) => {
                  setPage('confirm2')
                  setUseScore(false)
                }}
                disabled={inprogress}>
                <div>کیف پول</div>
              </button>
            </div>
          </div>
        </div>
      )}
      {page === 'confirm2' && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'مبلغ'}</td>
                <td>
                  {internetPackage.aff_amount.toLocaleString(navigator.language)} {t('label.riyal')}
                </td>
              </tr>
              <tr>
                <td>{'شماره همراه'}</td>
                <td>
                  {mobileNumber}
                  <img alt='' src={mobileImageResolver(mobileNumber)} width='35px' height='35px' />
                </td>
              </tr>
              <tr>
                <td>{'بسته'}</td>
                <td>{internetPackage.title}</td>
              </tr>
              <tr>
                <td>{'روش پرداخت'}</td>
                <td>{useScore ? 'امتیاز' : 'کیف پول'}</td>
              </tr>
              {useScore && (
                <tr>
                  <td>{'تخفیف خرید با امتیاز'}</td>
                  <td>
                    {calculateScoreDiscount(internetPackage.aff_amount).toLocaleString(navigator.language)}{' '}
                    {t('label.riyal')}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan='2' className='text-center'>
                  <Button
                    onClick={(e) => {
                      setPage('confirm')
                    }}>
                    {'برگشت'}
                  </Button>
                  <Button className='btn-theme' onClick={handleSubmit} disabled={inprogress}>
                    {'تایید'}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {page === 'result' && result && (
        <div>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td>{'تاریخ'}</td>
                <td>{result.createdDate}</td>
              </tr>
              <tr>
                <td>{'مبلغ'}</td>
                <td>{(+result.orgAmount).toLocaleString(navigator.language)}</td>
              </tr>
              <tr>
                <td>{'شماره همراه'}</td>
                <td>{result.mobileNumber}</td>
              </tr>
              <tr>
                <td>{'شماره ارجاع'}</td>
                <td>{result.referenceNumber}</td>
              </tr>
              <tr>
                <td>{'عنوان'}</td>
                <td>{result.caption}</td>
              </tr>
              <tr>
                <td>{'توضیحات'}</td>
                <td>{result.title}</td>
              </tr>
              <tr>
                <td>{'حجم'}</td>
                <td>{result.traffic}</td>
              </tr>
              <tr>
                <td>{'اعتبار'}</td>
                <td>{result.expiry}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <CustomModal
        showModal={showWalletDepositModal}
        toggle={setShowWalletDepositModal}
        title={'افزایش موجودی'}
        content={
          <WalletDeposit
            amount={+neededAmount}
            originalCommand={originalCommand}
            originalCommandType={'INTERNET_CHARGE'}
            targetAmount={internetPackage.aff_amount}
          />
        }
      />
    </>
  )
}

export default withTranslation()(InternetCharge)
