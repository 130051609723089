import React, { useState, useEffect, useContext } from 'react'
import { IonButton } from '@ionic/react'
import { checkmark, ban, archive } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { getSortState } from '../../../../utility/jhipster'
import { ITEMS_PER_PAGE, AyriaPagination } from '../../../../utility/pagination'
import { ItemCount, setWithTimeout } from '../../../../utility'
import WalletResponse from '../../../screens/wallet/wallet-response/wallet-response'
import { AcceptorContext, ApiService, AyriaIcon, CustomModal } from '../../../../ayria'
import { isDesktop } from '../../../../utility'

const WalletResponses = ({ isAcceptor }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState(getSortState(window.location, ITEMS_PER_PAGE))
  const [totalItems, setTotalItems] = useState(0)
  const [item, setItem] = useState({ amount: 0 })
  const [acceptorContext] = useContext(AcceptorContext)
  const [showReceiptModal, setShowReceiptModal] = useState(false)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false)

  async function fetchItems(page, size, sort, t) {
    if (isAcceptor) {
      return
    }
    try {
      const res = await api.get(
        `/user/ayria-payments/payer-responses/all${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`
      )
      if (res.ok) {
        setWithTimeout(setItems, res.data)
        setTotalItems(res.headers['x-total-count'])
      }
    } catch (err) {}
  }

  const getAllItems = () =>
    fetchItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllItems()

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
  }, []) // eslint-disable-line

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [pagination.activePage, pagination.order, pagination.sort, acceptorContext.currentIndex])

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div
            key={item.createdDate + '-' + index}
            className='flex-container border-bottom pointer'
            onClick={(e) => {
              setItem(item)
              setShowReceiptModal(true)
            }}>
            <span>
              <p className='ellipsis m-0' style={{ width: isDesktop() ? '500px' : '190px' }}>
                {item.payeeName} {item.description}
                <br />
                {item.createdDate}
              </p>
            </span>
            <span>
              <span>{item?.unitedAmount?.toLocaleString(navigator.language)}</span>
              <span style={{ width: '60px', display: 'inline-block' }} className='text-left'>
                {item.paid && (
                  <span className='p-1'>
                    <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                  </span>
                )}
                {item.canceled && (
                  <span className='p-1'>
                    <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                  </span>
                )}
                {item.archived && (
                  <span className='p-1'>
                    <AyriaIcon icon={archive} title={t('walletRequest.archived')} />
                  </span>
                )}
              </span>
            </span>
          </div>
        )
      })}
      <div className='row text-center'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col'></div>
        <div className='col' align='center'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
        <div className='col'></div>
      </div>
      <CustomModal
        showModal={showReceiptModal}
        toggle={setShowReceiptModal}
        title={`پرداخت وجه ${item.trackingNumber}`}
        content={
          <div>
            <table className='table'>
              <thead></thead>
              <tbody>
                <tr>
                  <td>{t('walletRequest.receiver')}</td>
                  <td>
                    <span title={item.payeeIdentity}>{item.payeeName}</span>
                  </td>
                </tr>
                <tr>
                  <td>{t('walletRequest.amount')}</td>
                  <td>{item?.unitedAmount?.toLocaleString(navigator.language)}</td>
                </tr>
                <tr>
                  <td>{t('label.description')}</td>
                  <td>{item.description}</td>
                </tr>
                <tr>
                  <td>{t('walletRequest.createdDate')}</td>
                  <td>
                    {item.createdTime} {item.createdDate}
                  </td>
                </tr>
                <tr>
                  <td>{t('walletRequest.status')}</td>
                  <td>
                    {item.paid && (
                      <span className='p-1'>
                        <AyriaIcon icon={checkmark} title={t('walletRequest.paid')} />
                      </span>
                    )}
                    {item.canceled && (
                      <span className='p-1'>
                        <AyriaIcon icon={ban} title={t('walletRequest.canceled')} />
                      </span>
                    )}
                    {item.archived && (
                      <span className='p-1'>
                        <AyriaIcon icon={archive} title={t('walletRequest.archived')} />
                      </span>
                    )}
                    {!item.paid && !item.canceled && (
                      <IonButton onClick={(e) => setShowChangeStatusModal(true)}>{'تغییر وضعیت'}</IonButton>
                    )}
                  </td>
                </tr>
                {item.canceled && item.cancelDescription && (
                  <tr>
                    <td>{'توضیحات لغو'}</td>
                    <td>{item.cancelDescription}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        }
      />
      <CustomModal
        showModal={showChangeStatusModal}
        toggle={setShowChangeStatusModal}
        title={t('wallet.doPay')}
        content={
          <WalletResponse
            trackingNumberProp={item.trackingNumber}
            onSuccess={(e) => {
              setShowChangeStatusModal(false)
              setShowReceiptModal(false)
            }}
          />
        }
      />
    </div>
  )
}

export default WalletResponses
