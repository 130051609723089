import React, { useState, useEffect, useContext, lazy } from 'react'
import { CustomModal, ApiService, AyriaPagination, getSortState, ItemCount, isDesktop } from '../../../ayria'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import { utils } from 'react-modern-calendar-datepicker'
import SellDetailDaily from './SellDetailDaily'
const DatePicker = lazy(() => import('react-modern-calendar-datepicker'))

const SellChartDailyTable = () => {
  const api = new ApiService()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [data, setData] = useState<any>([])
  const [selectedDayJalali, setSelectedDayJalali] = useState<string>('')
  const [selectedDay, setSelectedDay] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [pagination, setPagination] = useState(getSortState(window.location, isDesktop() ? 10 : 50))
  const [totalItems, setTotalItems] = useState(0)
  const [dayRange, setDayRange] = useState<any>({
    from: { year: utils('fa').getToday().year, month: utils('fa').getToday().month, day: 1 },
    to: { year: utils('fa').getToday().year, month: utils('fa').getToday().month, day: 'x' },
  })
  const fromString = () => {
    return `${dayRange.from.year}-${dayRange.from.month}-${dayRange.from.day}`
  }
  const toString = () => {
    return `${dayRange.to.year}-${dayRange.to.month}-${dayRange.to.day}`
  }

  const fetchChartData = async (page, size, sort) => {
    const res: any = await api.get(
      `/acceptor/purchases/${acceptorContext.current.code}/daily?page=${page}&size=${size}${
        sort ? `&sort=${sort}` : ''
      }${dayRange.to ? `&fromDate=${fromString()}&toDate=${toString()}` : ''}`
    )
    if (res.ok) {
      const to = res.data.to.split('-')
      if (dayRange.to.day !== parseInt(to[2])) {
        const from = res.data.from.split('-')
        setDayRange({
          from: { year: parseInt(from[0]), month: parseInt(from[1]), day: parseInt(from[2]) },
          to: { year: parseInt(to[0]), month: parseInt(to[1]), day: parseInt(to[2]) },
        })
      }
      setData(res.data)
      setTotalItems(res.headers['x-total-count'])
    }
  }

  const handlePagination = (currentPage) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  useEffect(() => {
    if (dayRange.from && dayRange.to) {
      fetchChartData(pagination.activePage - 1, pagination.itemsPerPage, undefined)
    }
  }, [dayRange.from, dayRange.to, pagination.activePage]) // eslint-disable-line

  return (
    <>
      <div className='p-2 mr-0 ion-text-center'>
        <DatePicker value={dayRange} onChange={setDayRange} locale='fa' calendarPopperPosition={'bottom'} />
      </div>
      {data && data.labels && (
        <div style={{ minHeight: '300px' }}>
          {data.labels.map((name: string, index: number) => (
            <div
              key={index}
              className='flex-container border-bottom pointer pb-2'
              onClick={(e) => {
                setSelectedDayJalali(name)
                setSelectedDay(data.dates[index])
                setShowModal(true)
              }}>
              <span className='flex-1'>{name}</span>
              <span className='flex-2 text-left'>{data.data[index].toLocaleString(navigator.language)}</span>
            </div>
          ))}
        </div>
      )}
      <div className='row text-center mt-2'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col'></div>
        <div className='col' align='center'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
        <div className='col'></div>
      </div>
      <CustomModal
        showModal={showModal}
        toggle={setShowModal}
        title={[
          <span key={1}>جزییات خرید در </span>,
          <span key={2} className='bidi-embed'>
            {selectedDayJalali}
          </span>,
        ]}
        isPlatformTheme={true}
        content={<SellDetailDaily selectedDay={selectedDay} acceptorCode={acceptorContext.current.code} />}
      />
    </>
  )
}

export default SellChartDailyTable
