import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Header } from '../ayria'
import Notifications from '../components/screens/notifications/notifications'
import { isDesktop } from '../utility'

interface NotificationTabProps {}

const NotificationsTab: React.FC<NotificationTabProps> = () => {
  return (
    <IonPage id='notification-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header showBackButton={false} />
        <div className='ion-padding'>
          <Notifications />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default React.memo(NotificationsTab)
