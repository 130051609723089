import React, { useState, useRef } from 'react'
import { IonContent, IonPage, useIonViewWillEnter, IonSpinner } from '@ionic/react'
import MerchantProfile from '../components/screens/merchant-profile/merchantProfile'

interface MerchantProfilePageProps {}

const MerchantProfilePage: React.FC<MerchantProfilePageProps> = () => {
  const [rerender, setRerender] = useState(false)
  const scrollContentRef = useRef<HTMLIonContentElement>(null)
  const [scrollTop, setScrollTop] = useState(0)

  useIonViewWillEnter(() => {
    setRerender(false)
    setTimeout(() => {
      setRerender(true)
    }, 300)
  })

  const ionScroll = (event: any) => {
    setScrollTop(event.detail.scrollTop)
  }

  return (
    <IonPage id='merchant-profile-page'>
      <IonContent ref={scrollContentRef} scrollEvents={true} onIonScroll={ionScroll}>
        {rerender && <MerchantProfile ionContentRef={scrollContentRef} scrollTop={scrollTop} />}
        {!rerender && <IonSpinner name='crescent' color='dark' />}
      </IonContent>
    </IonPage>
  )
}

export default React.memo(MerchantProfilePage)
