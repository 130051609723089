import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../../api/Api'
import { useToast } from '../../../ayria'

const PurchaseAcceptorRefund = ({ purchaseId, acceptorCode, isCashier, onCancel, onSuccess }) => {
  const toaster = useToast()
  const [purchase, setPurchase] = useState()
  const [inprogress, setInprogress] = useState(false)
  const [refundReason, setRefundReason] = useState('USER_MISTAKE')
  const { t } = useTranslation()

  async function getPurchase() {
    const res = await api.get(
      (isCashier ? '/secretary-acceptor/purchase/' : '/acceptor/purchases/') + `${purchaseId}/${acceptorCode}/get`
    )
    if (res.ok) {
      setPurchase(res.data)
    } else {
      toaster.error(res)
    }
  }
  async function refund() {
    setInprogress(true)
    const res = await api.post(isCashier ? '/secretary-acceptor/purchase/refund' : '/acceptor/purchases/refund', {
      purchaseId,
      acceptorCode,
      refundReason,
    })
    if (res.ok) {
      onSuccess()
      toaster.success(t('label.successfulOperation'))
    } else {
      toaster.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    getPurchase()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {purchase && (
        <div>
          <p>آیا از عودت فروش زیر اطمینان دارید؟</p>
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr>
                <td className='text-right'>نام مشتری</td>
                <td>{purchase.customerName}</td>
              </tr>
              <tr>
                <td className='text-right'>تاریخ و زمان</td>
                <td>
                  {purchase.purchaseTime} {purchase.purchaseDatePersian}
                </td>
              </tr>
              <tr>
                <td className='text-right'>مبلغ</td>
                <td>{purchase.totalAmount.toLocaleString(navigator.language)} ریال </td>
              </tr>
              <tr>
                <td className='text-right'>مبلغ دریافتی</td>
                <td>{purchase.acceptorAmount.toLocaleString(navigator.language)} ریال</td>
              </tr>
              <tr>
                <td className='text-right'>نام فروشگاه</td>
                <td>{purchase.acceptorName}</td>
              </tr>
              <tr>
                <td className='text-right'>شماره پیگیری</td>
                <td>{purchase.id}</td>
              </tr>
              <tr>
                <td colSpan='2'>
                  <label htmlFor='USER_MISTAKE'>
                    <input
                      id='USER_MISTAKE'
                      type='radio'
                      name='reason'
                      value='USER_MISTAKE'
                      onChange={(e) => setRefundReason(e.target.value)}
                      checked={refundReason === 'USER_MISTAKE'}
                    />
                    {t('refundReason.USER_MISTAKE')}{' '}
                  </label>
                  <br />
                  <label htmlFor='POOR_QUALITY'>
                    <input
                      id='POOR_QUALITY'
                      type='radio'
                      name='reason'
                      value='POOR_QUALITY'
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                    {t('refundReason.POOR_QUALITY')}{' '}
                  </label>
                  <br />
                  <label htmlFor='FOUND_BETTER_ALTERNATIVE'>
                    <input
                      id='FOUND_BETTER_ALTERNATIVE'
                      type='radio'
                      name='reason'
                      value='FOUND_BETTER_ALTERNATIVE'
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                    {t('refundReason.FOUND_BETTER_ALTERNATIVE')}{' '}
                  </label>
                  <br />
                  <label htmlFor='LATE_DELIVERY'>
                    <input
                      id='LATE_DELIVERY'
                      type='radio'
                      name='reason'
                      value='LATE_DELIVERY'
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                    {t('refundReason.LATE_DELIVERY')}{' '}
                  </label>
                  <br />
                  <label htmlFor='CHANGED_MY_MIND'>
                    <input
                      id='CHANGED_MY_MIND'
                      type='radio'
                      name='reason'
                      value='CHANGED_MY_MIND'
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                    {t('refundReason.CHANGED_MY_MIND')}{' '}
                  </label>
                  <br />
                  <label htmlFor='I_NEED_ITS_MONEY'>
                    <input
                      id='I_NEED_ITS_MONEY'
                      type='radio'
                      name='reason'
                      value='I_NEED_ITS_MONEY'
                      onChange={(e) => setRefundReason(e.target.value)}
                    />
                    {t('refundReason.I_NEED_ITS_MONEY')}{' '}
                  </label>
                  <br />
                </td>
              </tr>
              <tr>
                <td colSpan='2' className='text-center'>
                  <button
                    className='btn btn-danger'
                    onClick={(e) => refund()}
                    disabled={inprogress || !purchase.refundable}>
                    تایید عودت
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default PurchaseAcceptorRefund
